import Dots from './../Opportunity/assets/Dots.png';
import Gear from './../Opportunity/assets/Gear.png';
import 'primereact/resources/primereact.min.css';
import './Kpi.css';
import { Divider } from '@mui/material';
import Select from './Utility/Select';
import BarCharts from './Utility/BarChart';
import { dataOverView, kpiReportData } from './Utility/KPIData';
import KpiDeliverable from './Utility/Kpideliverable';

const KPI = () => {

  return (
    <section className="oppor-section">
      <div
        className="oppor-row"
        style={{ flexDirection: 'column', alignItems: 'start' }}
      >
        <div className="oppor-col">
          <h3 className="fstxtPro">Finster Web Redesign</h3>
          <img className="icon-sm" src={Dots} alt="dots" />
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              background: 'inherit',
              border: 'none',
              outline: 'none',
              flexDirection: 'column',
            }}
          >
            <button
              style={{
                background: 'inherit',
                border: 'none',
                outline: 'none',
              }}
              onClick={() => {
              }}
              id="filter"
            >
              <img id="filter" className="icon-sm" src={Gear} alt="gear" />
            </button>
          </div>
        </div>
        <span className="kpi-company kpi-text">FINSTER RELOCATION</span>
      </div>
      <div
        className="main-container"
        style={{
          background: '#fff',
          borderRadius: '10px',
          padding: '21px',
          width: '100%',
        }}
      >
        <div className="kpi-top-container">
          <div className="widget widget-1">
            <p className="widget-title kpi-text">DELIVERABLES COMPLETED</p>
            <span className="kpi-text">
              {' '}
              0<i>/</i>6{' '}
            </span>
          </div>
          <div className="widget widget-2">
            <p className="widget-title kpi-text">
              DELIVERABLES OVER ALLOCATED TIME
            </p>
            <span className="kpi-text">
              {' '}
              3<i>/</i>6{' '}
            </span>
          </div>
          <div className="widget widget-3">
            <p className="widget-title kpi-text">TOTAL TIME CONSUMED</p>
            <span className="kpi-text">
              {' '}
              40<i>/</i>180 hours
            </span>
          </div>
          <div className="widget widget-4">
            <p className="widget-title kpi-text">PROJECT COMPLETION</p>
            <span className="kpi-text"> 15%</span>
          </div>
          <div style={{ width: '105px' }}></div>

          <Divider
            flexItem
            orientation="vertical"
            style={{ backgroundColor: '#333' }}
          />
          <div style={{ width: '105px' }}></div>
          <div className="widget widget-5">
            <p className="widget-title kpi-text">TIMELINE</p>
            <span className="kpi-text">
              {' '}
              2 <i>/</i>15 Months
            </span>
          </div>
        </div>
        <div className="kpi-middle-container">
          <div className="kpi-middle">
            <p className="middle-title kpi-text">
              Project Deliverables Data Overview
            </p>
            <div className="kpi-middle-bottom">
              <div className="kpi-middle-widget">
                <p className="kpi-text">Filter</p>
                <div className="inner-widget">
                  <p className="kpi-text inner-project">Project</p>
                  <p
                    className="kpi-text inner-text"
                    style={{
                      background: '#F8F9FA',
                      borderRadius: '0px 6px 6px 0px',
                    }}
                  >
                    Deliverable
                  </p>
                </div>
              </div>
              <div className="kpi-middle-widget">
                <p className="kpi-text">Project</p>
                <p className="kpi-text inner-text">Finister Web Design</p>
              </div>
              <div className="kpi-middle-widget">
                <p className="kpi-text">Deliverables</p>
                <Select items={['Project']} />
              </div>
              <div className="kpi-middle-widget">
                <p className="kpi-text">Date</p>
                <Select items={['Project']} />
              </div>
            </div>
            <hr />
            <div className="kpi-barchart">
              <p className="kpi-barchart-title kpi-text">Data Overview</p>
              <BarCharts data={dataOverView()} isUnit={true} isLegend={true} />
            </div>
            <div className="kpi-barchart">
              <div className="report-top">
                <select
                  className="kpi-report kpi-filter kpi-text"
                  style={{ margin: '0', padding: '5px' }}
                >
                  <option value="Finster Web Redesign">
                    Finster Web Redesign
                  </option>
                </select>
                <select
                  className="kpi-report kpi-date-filter kpi-text"
                  style={{ margin: '0', padding: '5px' }}
                >
                  <option value="all">All Date</option>
                </select>
              </div>
              <BarCharts data={kpiReportData()} isLegend={false} />
            </div>
            <div className="kpi-barchart">
              <KpiDeliverable />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default KPI;
