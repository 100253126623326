import React from 'react'
import CoursesHeader from './CoursesHeader'
import './addChapter.css'
import NetNetUCourseView from './NetNetUCourseView'

const AddChapter = () => {


  // Now you can use nextRouteData in your component
  return (
    <>
    <div className="courseWrapper">
      <CoursesHeader />
      <NetNetUCourseView />
    </div>
    </>
  )
}

export default AddChapter
