const NetNetLogo = () => (
  <svg id="Layer_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 750 184.19">
    <defs>
      <style
        dangerouslySetInnerHTML={{
          __html:
            '.cls-1{fill:#fff;}.cls-2{fill:none;stroke:#fff;stroke-miterlimit:10;stroke-width:9px;}',
        }}
      />
    </defs>
    <g id="Logo_White">
      <g>
        <g>
          <path
            id="t"
            className="cls-1"
            d="M688.04,34.66l26.45-4.26v27.52h31.78v22.01h-31.78v32.84c0,5.56,.98,10,2.93,13.31,1.95,3.31,5.89,4.97,11.81,4.97,2.84,0,5.77-.27,8.79-.8s5.77-1.27,8.25-2.22l3.73,20.59c-3.2,1.3-6.75,2.43-10.65,3.37-3.91,.95-8.7,1.42-14.38,1.42-7.22,0-13.2-.98-17.93-2.93-4.73-1.95-8.52-4.67-11.36-8.17-2.84-3.49-4.82-7.72-5.95-12.69-1.13-4.97-1.69-10.47-1.69-16.51V34.66Z"
          />
          <path
            id="e"
            className="cls-1"
            d="M580.82,105.32c0-8.28,1.27-15.53,3.82-21.75,2.54-6.21,5.89-11.39,10.03-15.53s8.9-7.28,14.29-9.41c5.38-2.13,10.92-3.2,16.6-3.2,13.25,0,23.73,4.06,31.42,12.16,7.69,8.11,11.54,20.03,11.54,35.77,0,1.54-.06,3.23-.18,5.06-.12,1.84-.24,3.46-.35,4.88h-60c.59,5.44,3.13,9.76,7.63,12.96,4.5,3.19,10.53,4.79,18.11,4.79,4.85,0,9.61-.44,14.29-1.33,4.67-.89,8.49-1.98,11.45-3.28l3.55,21.48c-1.42,.71-3.31,1.42-5.68,2.13-2.37,.71-5,1.33-7.9,1.86-2.9,.53-6.01,.98-9.32,1.33-3.31,.35-6.63,.53-9.94,.53-8.4,0-15.71-1.24-21.92-3.73-6.21-2.49-11.36-5.89-15.44-10.21-4.08-4.32-7.1-9.44-9.05-15.36-1.95-5.92-2.93-12.31-2.93-19.17Zm62.13-10.12c-.12-2.25-.5-4.44-1.15-6.57-.65-2.13-1.66-4.02-3.02-5.68-1.36-1.66-3.08-3.02-5.15-4.08-2.07-1.07-4.65-1.6-7.72-1.6s-5.5,.5-7.63,1.51c-2.13,1.01-3.91,2.34-5.33,3.99-1.42,1.66-2.52,3.58-3.28,5.77-.77,2.19-1.33,4.41-1.69,6.66h34.97Z"
          />
          <path
            id="n"
            className="cls-1"
            d="M480.7,61.12c4.5-1.3,10.3-2.51,17.4-3.64,7.1-1.12,14.56-1.69,22.37-1.69s14.53,1.04,19.79,3.11c5.26,2.07,9.44,5,12.52,8.79,3.08,3.79,5.26,8.28,6.57,13.49,1.3,5.21,1.95,11.01,1.95,17.4v52.72h-26.45v-49.53c0-8.52-1.13-14.56-3.37-18.11-2.25-3.55-6.45-5.33-12.6-5.33-1.89,0-3.91,.09-6.04,.27-2.13,.18-4.02,.39-5.68,.62v72.07h-26.45V61.12Z"
          />
        </g>
        <g id="Net_Net_Symbol">
          <g>
            <rect
              className="cls-2"
              x="287.41"
              y="4.5"
              width="175.19"
              height="175.19"
              rx="30.71"
              ry="30.71"
            />
            <g>
              <path
                className="cls-1"
                d="M349.22,34.69c-4.02-4.02-9.78-6.33-15.81-6.33-6.03,0-11.79,2.31-15.81,6.33-8.71,8.72-8.71,22.9,0,31.62l83.18,83.18c4.21,4.21,9.82,6.53,15.81,6.53s11.6-2.32,15.81-6.53c4.21-4.21,6.53-9.82,6.53-15.81s-2.32-11.6-6.53-15.81l-83.18-83.18Zm80.17,98.99c0,3.44-1.32,6.66-3.73,9.06-2.29,2.29-5.59,3.6-9.06,3.6s-6.77-1.31-9.06-3.6l-83.19-83.18c-4.99-5-4.99-13.13,0-18.12,2.41-2.41,5.63-3.73,9.07-3.73s6.65,1.32,9.06,3.73l83.18,83.18c2.41,2.41,3.73,5.62,3.73,9.06Z"
              />
              <path
                className="cls-1"
                d="M416.25,73.52c12.51,0,22.68-10.17,22.68-22.68s-10.18-22.68-22.68-22.68-22.68,10.17-22.68,22.68,10.17,22.68,22.68,22.68Zm0-35.82c7.24,0,13.14,5.89,13.14,13.14s-5.9,13.14-13.14,13.14-13.13-5.89-13.13-13.14,5.89-13.14,13.13-13.14Z"
              />
              <path
                className="cls-1"
                d="M333.75,110.66c-12.51,0-22.68,10.17-22.68,22.68s10.17,22.68,22.68,22.68,22.68-10.17,22.68-22.68-10.17-22.68-22.68-22.68Zm13.14,22.68c0,7.24-5.9,13.14-13.14,13.14s-13.13-5.89-13.13-13.14,5.89-13.13,13.13-13.13,13.14,5.89,13.14,13.13Z"
              />
            </g>
          </g>
        </g>
        <g>
          <path
            id="t-2"
            className="cls-1"
            d="M207.34,34.66l26.45-4.26v27.52h31.78v22.01h-31.78v32.84c0,5.56,.98,10,2.93,13.31,1.95,3.31,5.89,4.97,11.81,4.97,2.84,0,5.77-.27,8.79-.8s5.77-1.27,8.25-2.22l3.73,20.59c-3.2,1.3-6.75,2.43-10.65,3.37-3.91,.95-8.7,1.42-14.38,1.42-7.22,0-13.2-.98-17.93-2.93-4.73-1.95-8.52-4.67-11.36-8.17-2.84-3.49-4.82-7.72-5.95-12.69-1.13-4.97-1.69-10.47-1.69-16.51V34.66Z"
          />
          <path
            id="e-2"
            className="cls-1"
            d="M100.12,105.32c0-8.28,1.27-15.53,3.82-21.75,2.54-6.21,5.89-11.39,10.03-15.53,4.14-4.14,8.9-7.28,14.29-9.41,5.38-2.13,10.92-3.2,16.6-3.2,13.25,0,23.73,4.06,31.42,12.16,7.69,8.11,11.54,20.03,11.54,35.77,0,1.54-.06,3.23-.18,5.06-.12,1.84-.24,3.46-.35,4.88h-60c.59,5.44,3.13,9.76,7.63,12.96,4.5,3.19,10.53,4.79,18.11,4.79,4.85,0,9.61-.44,14.29-1.33,4.67-.89,8.49-1.98,11.45-3.28l3.55,21.48c-1.42,.71-3.31,1.42-5.68,2.13-2.37,.71-5,1.33-7.9,1.86-2.9,.53-6.01,.98-9.32,1.33-3.31,.35-6.63,.53-9.94,.53-8.4,0-15.71-1.24-21.92-3.73-6.21-2.49-11.36-5.89-15.44-10.21-4.08-4.32-7.1-9.44-9.05-15.36-1.95-5.92-2.93-12.31-2.93-19.17Zm62.13-10.12c-.12-2.25-.5-4.44-1.15-6.57-.65-2.13-1.66-4.02-3.02-5.68-1.36-1.66-3.08-3.02-5.15-4.08-2.07-1.07-4.65-1.6-7.72-1.6s-5.5,.5-7.63,1.51c-2.13,1.01-3.91,2.34-5.33,3.99-1.42,1.66-2.52,3.58-3.28,5.77-.77,2.19-1.33,4.41-1.69,6.66h34.97Z"
          />
          <path
            id="n-2"
            className="cls-1"
            d="M0,61.12c4.5-1.3,10.3-2.51,17.4-3.64,7.1-1.12,14.56-1.69,22.37-1.69s14.53,1.04,19.79,3.11c5.26,2.07,9.44,5,12.52,8.79,3.08,3.79,5.26,8.28,6.57,13.49,1.3,5.21,1.95,11.01,1.95,17.4v52.72h-26.45v-49.53c0-8.52-1.13-14.56-3.37-18.11-2.25-3.55-6.45-5.33-12.6-5.33-1.89,0-3.91,.09-6.04,.27-2.13,.18-4.02,.39-5.68,.62v72.07H0V61.12Z"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default NetNetLogo;
