import { Grid, Box, Typography } from '@mui/material';
import EastIcon from '@mui/icons-material/East';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import { useNavigate } from 'react-router-dom';
import { ENDPOINT } from '../Opportunity/Redux/oppor';
import logoSm from './assets/logo_sm.png';
import addToList from './assets/addToList.png';
import GradingIcon from '@mui/icons-material/Grading';
import Capture from '../Me/assets/Capture.PNG'
import ModalMui from '@mui/material/Modal';
import { IoIosClose } from "react-icons/io";

const Scripture = ({ codex, setMegaLoading }) => {
  const access_token = useSelector((state) => state.auth.user.access);
  const navigate = useNavigate();
  const [showDet, setShowDet] = useState(false);

  const [imageSize, setImageSize] = useState({ width: '350px', height: '200px' });
  const [iFrame, setiFrame] = useState({ width: '350px', height: '195px' });

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1500) {
        setImageSize({ width: '350px', height: '200px' });
        setiFrame({ width: '350px', height: '195px' })
      } else if (window.innerWidth >= 1100) {
        setImageSize({ width: '220px', height: '180px' });
        setiFrame({ width: '220px', height: '175px' })
      } else {
        setImageSize({ width: '180px', height: '150px' });
        setiFrame({ width: '180px', height: '150px' })
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const [isOn, setIsOn] = useState(false)
  const [videoLink, setVideoLink] = useState()

  return (
    <>
      <ModalMui
        open={isOn}
        onClose={() => setIsOn(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box align='center' sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '76%',
          height: '78%',
          color: "#EBEAEA",
          bgcolor: '#5B5A5A',
          boxShadow: 24,
        }}>
          <p style={{
            fontSize: '19px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            width: '100%',
            height: '3%',
          }}>
            <span style={{ flexGrow: 1 }}>{videoLink?.title}</span>
            <IoIosClose onClick={() => setIsOn(false)} style={{ cursor:"pointer",  fontSize:"3rem", paddingRight:"12px"}} />
          </p>
          <iframe
            style={{ borderRadius:"9px", width: '100%', height: '97%', zIndex: 9999 }}
            src={videoLink?.videoLink}
          />

        </Box>
      </ModalMui>

      <Grid
        container
        direction="row"
        alignItems="center"
        height='241px'
        width="100%"
        overflow="auto"
        sx={{
          border: '1px solid #D9D9D9',
          padding: { xl: '1.2rem 2rem', lg: '0.8rem 1.2rem', md: '0.5rem .8rem', sm: '.3rem .6rem' },
          transition: 'background-color 0.3 ease',
          "&:hover": {
            backgroundColor: "#DCDCDC"
          }

        }}
      >
        <Grid
          item

          sx={{
            position: 'relative',
            cursor: 'pointer',
            borderRadius: "12px",
            outline: "none"
          }}
        >
          {
            codex?.chapters && codex?.chapters[0]?.lessons[0]?.video ?
              <div
                style={{
                  outline: "none",
                  width: imageSize.width,
                  height: imageSize.height
                }}
              >
                <iframe
                  src={codex?.chapters[0]?.lessons[0]?.video}
                  // onClick={() => }
                  frameBorder="8"
                  style={{
                    borderRadius: '10px',
                    width: iFrame.width,
                    height: iFrame.height
                  }}
                ></iframe>
              </div>
              : <img style={{
                borderRadius: '10px',
                width: imageSize.width,
                height: imageSize.height
              }} src={Capture} alt="thumbnail" />
          }
          <PlayCircleIcon
            sx={{
              fontSize: 50,
              color: '#711fff',
              position: 'absolute',
              top: '50%',
              left: { xl: '50%', lg: '50%', md: '50%', sm: '50%' },
              transform: 'translate(-50%, -50%)',
              zIndex: 999,
            }}
            onClick={() => {
              setIsOn(true)
              setVideoLink({ videoLink: codex?.chapters[0]?.lessons[0]?.video, title: codex?.title })
            }}
          />
        </Grid>
        <Grid sx={{
          width: {
            lg: "17rem", xl: "34rem",
            '@media screen and (max-width: 2038px)': {
              width: '24rem',
            },
            '@media screen and (max-width: 1903px)': {
              width: '17rem',
            },
            '@media screen and (max-width: 964px)': {
              width: '15rem',
            },
          },
        }} item>
          <article
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              marginLeft: "11px",
              height: "12.5rem",
              padding: "9px"
            }}
          >
            <Typography style={{ fontWeight: 300, fontSize: "13px" }} >
              {codex.type ? codex.type.toUpperCase : 'Course'}
            </Typography>
            <Typography style={{ fontSize: "14px", fontWeight: 600 }} className="fstxtPro fs-sm-txt-pro-os600">
              {codex.title}
            </Typography>
            <Typography sx={{ height: "30%", width: "70%", fontSize: { xl: '16px', lg: '13px', md: '11px', sm: '10px' } }} className="specialTxT" style={{ cursor: 'pointer' }} onClick={() => {
              navigate(`/Course_Detail/${codex.id}/`);
            }}>
              {codex.short_description}
            </Typography>
            <Typography
              sx={{ cursor: "pointer", fontSize: { xl: '14px', lg: '12px' } }}
              className="date m-0000 clr-v-500"
              onClick={() => showDet === false ? setShowDet(true) : setShowDet(false)}
            >
              Learn More
              <EastIcon sx={{ fontSize: { xl: '23px', lg: '18px', md: '17px', sm: '16px' }, marginLeft: '5px' }} className="date m-0000 clr-v-500" />
            </Typography>
            <img style={{ marginTop: "10px" }} src={logoSm} alt="logo" />
            <Typography sx={{ marginTop: "4px", fontWeight: 'bold', fontSize: { xl: '12px', lg: '10px', md: '9px', sm: '8px' } }} className="date m-0000">
              By  {codex.author}
            </Typography>
          </article>
        </Grid>
        <Grid sx={{
          width: { sm: "10rem", md: "16rem", lg: "17rem", xl: '33rem' }, marginRight: { sm: "1rem", md: "3rem", lg: "4rem", xl: "5rem" },
          '@media screen and (max-width: 1739px)': {
            width: '19rem',
          },
        }} >
          <article
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: "center",

              padding: "3px",
              gap: '0.5rem',

            }}
          >
            <Typography sx={{ fontSize: { xl: '13px', lg: '12px', md: '11px', sm: '10px' } }} className="lightTxt">
              Your Course Progress Based on Your Last Exit
            </Typography>
            <div
              className="progress-barContainers"
              style={{
                display: 'flex',
              }}
            >
              {codex.chapters.map((chapter, index) => (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '100%',
                  }}
                >
                  <div
                    className="NetNetU-progress-bar pb-nnu-nn-bg-300-g-800"
                    style={{
                      borderRadius:
                        index === 0 ? '8px 0 0 8px' : index === codex.chapters.length - 1 ? '0 8px 8px 0' : '0',
                      borderLeft: index === 0 ? '0' : '2px solid #ccc',
                      borderRight: index === codex.chapters.length - 1 ? '0' : '2px solid #ccc',
                    }}
                  />
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <p className="lightTxt m-0000">CH:</p>
                    <p className="lightTxt m-0000">{index + 1}</p>
                  </div>
                </Box>
              ))}
            </div>
          </article>
        </Grid>
        <Grid sx={{
          width: '12rem',
          '@media screen and (max-width: 964px)': {
            width: '9rem',
          },
        }} item >
          <article
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '0.5rem',
            }}
          >
            <div>
              <button
                className="btn-nnu-nn-bg-300-g-spTxt"
                onClick={async () => {
                  setMegaLoading(true);
                  const res = await fetch(
                    `${ENDPOINT}/api/netnetu/updatecourse/${codex.id}/`,
                    {
                      method: 'PUT',
                      headers: {
                        'Content-Type': 'application/json',
                        Authorization: `JWT ${access_token}`,
                      },
                      body: JSON.stringify({
                        is_saved: !codex.is_saved,
                        title: codex.title,
                      }),
                    },
                  );
                  const data = await res.json();
                  if (!res.ok) {
                    setMegaLoading(false);
                  }
                  setTimeout(() => {
                    setMegaLoading(false);
                  }, 1000);

                }}
              >
                {codex.is_saved ? <GradingIcon sx={{ color: '#05abab' }} /> : 'Add to My List'}
                {!codex.is_saved && <img src={addToList} alt="addToList" />}
              </button>
            </div>
            <div>
              <p className="txt-g-800">
                {codex.completed
                  ? `Completed On ${codex.culmination_date}`
                  : `${codex.est_time}:00`}
                {' '}
              </p>
            </div>
          </article>
        </Grid>
      </Grid>
      {showDet && (
        <Grid container direction="column" alignItems="center">
          <Grid
            container
            direction="row"
            height="11rem"
            sx={{

              border: '1px solid #D9D9D9',
            }}
          >
            <Grid
              item
              container
              display="flex"
              direction="column"
              alignItems="center"
              textAlign="start"

              justifyContent="flex-start"
              sx={{
                width: "50%",
                padding: '2rem 0',
              }}
            >
              <h3 className="fs-txt-esm-os500-clr-v-500">OVERVIEW</h3>
              <p className="fs-txt-esm-os-600-clr-g-300">
                {codex?.short_description ? codex?.short_description : 'No Overview'}
              </p>
            </Grid>
            <Grid
              item
              container
              direction="column"
              alignItems="flex-start"
              sx={{
                width: "50%",
                backgroundColor: '#f6f6f6',
                padding: '2rem',
              }}
            >
              {codex.chapters.map((chapter, index) => (
                <p className="specialTxT">
                  Chapter
                  {' '}
                  {index + 1}
                  :
                  {' '}
                  {chapter.title}
                </p>
              ))}
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default Scripture;
