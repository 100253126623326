const initalConfig = [
    {
        title: 'Time Report',
        bg: '#711fff',
        desc: `Time reports lets you know where everyone's effort is going. Filter on jobs, tasks, people, etc. Break it all down and export for billing.`,
        param:'time',
    },
    {
        title:'Estimate vs Actual Report',
        bg: '#310085',
        desc:'You Estimated It... Now, How Did You Perform On The Estimate? The Estimate Vs Acutual Report Is The Best Tool For Analyzing Your Success In Delivering In Budget ',
        param:'est',
    },
    {
        title:'Quick  Tasks Reports',
        bg:'#cbaeff',
        desc:`You Easily Get Quick Task Information That Can Be Analyzed And Exported For billing.`,
        param:'tasks'
    },
    {
        title:'Team Report',
        bg:'#8bc7fe',
        desc:'Hows Your Team Doing.. Are They Individually Delivering As Expected This Report Will Help You Analyze That',
        param:'team'
    },
    {
        title:'Sales Report',
        bg:'#6232c9',
        desc:`Nothing Ever Happens Until A Sale Is Made. Take A Look At The Past And Upcoming Sales Performance.`,
        param:'sales',
    }
];
export default initalConfig;