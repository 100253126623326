import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import moment from 'moment';
import { useSelector } from 'react-redux';

function formatMoneyString(numberString) {
  if (typeof numberString !== 'string') {
    return '$0';
  }
  if (!/^\d+(\.\d{1,2})?$/.test(numberString)) {
    return '$0';
  }

  const num = parseFloat(numberString).toFixed(2);

  const parts = num.split('.');
  const integerPart = parts[0];
  const decimalPart = parts[1];

  const formattedIntegerPart = integerPart.replace(
    /\B(?=(\d{3})+(?!\d))/g,
    ','
  );
  const formattedMoneyString = `$${formattedIntegerPart}.${decimalPart}`;

  return formattedMoneyString;
}

function getDurationFromHours(hours) {
  const duration = moment.duration(hours, 'hours');

  const months = Math.floor(duration.asMonths());
  duration.subtract(moment.duration(months, 'months'));

  const weeks = Math.floor(duration.asWeeks());
  duration.subtract(moment.duration(weeks, 'weeks'));

  const days = Math.floor(duration.asDays());

  return {
    months,
    weeks,
    days,
  };
}

function calculateDurationFromDil(deliverables) {
  if (!deliverables || !Array.isArray(deliverables) || !deliverables.length) {
    return { days: 0, weeks: 0, months: 0 };
  }
  let startDate = new Date(deliverables[0].deliverable_startdate);
  let endDate = new Date(deliverables[0].deliverable_enddate);

  for (let i = 1; i < deliverables.length; i++) {
    const start = new Date(deliverables[i].deliverable_startdate);
    const end = new Date(deliverables[i].deliverable_enddate);
    if (start < startDate) {
      startDate = start;
    }
    if (end > endDate) {
      endDate = end;
    }
  }

  const days = Math.floor((endDate - startDate) / (1000 * 60 * 60 * 24));
  const weeks = Math.floor(days / 7);
  const months = Math.floor(days / 30);
  
  return { days, weeks, months };
}

const NetTable = ({ formData, GI }) => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell align="left">
            <div className="fstxt3">Gross Income</div>
          </TableCell>
          <TableCell align="right">
            <div className="fstxt3">
              {formatMoneyString(GI ? GI.toString() : '$0')}
            </div>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow
          sx={{
            height: '90px',
            backgroundColor: '#711fff',
          }}
        >
          <TableCell align="left">
            <Typography
              sx={{
                fontWeight: '600',
                fontSize: '1.1rem',
                fontFamily: 'Open Sans',
                color: '#fff',
              }}
            >
              Net Income
            </Typography>
          </TableCell>
          <TableCell align="right">
            <Typography
              sx={{
                fontWeight: '600',
                fontSize: '1.3rem',
                fontFamily: 'Open Sans',
                color: '#fff',
              }}
            >
              {formatMoneyString(GI ? GI.toString() : '$0')}
            </Typography>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export { formatMoneyString, getDurationFromHours };

const MarginTable = ({ formData }) => {
  return (
    <Table>
      <TableHead>
        <TableRow
          sx={{
            height: '90px',
            backgroundColor: '#a16bff',
            borderTopLeftRadius: '8px',
            borderTopRightRadius: '8px',
          }}
        >
          <TableCell align="left">
            <Typography
              sx={{
                fontWeight: '600',
                fontSize: '1.1rem',
                fontFamily: 'Open Sans',
                color: '#fff',
              }}
            >
              Margins
            </Typography>
          </TableCell>
          <TableCell align="right">
            <Typography
              sx={{
                fontWeight: '600',
                fontSize: '1.3rem',
                fontFamily: 'Open Sans',
                color: '#fff',
                textAlign: 'right',
              }}
            >
              {formatMoneyString(
                formData.budget ? parseFloat(formData?.budget).toLocaleString('en-US', {maximumFractionDigits: 0}) : '0'
              )}
            </Typography>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell align="left">
            <div className="fstxt">Margins On Collaborators</div>
          </TableCell>
          <TableCell align="right">
            <div className="fstxt" style={{ textAlign: 'right' }}>
              $0
            </div>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="left">
            <div className="fstxt">Margins On Hard Costs</div>
          </TableCell>
          <TableCell align="right">
            <div className="fstxt" style={{ textAlign: 'right' }}>
              $0
            </div>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

const TeamKpi = ({ formData, dileverable }) => {
  const subSx = { fontSize: '0.9rem', color: '#626574' };
  const totalHours = formData.deliverable
  ? formData.deliverable.reduce((acc, curr) => {
      if (
        curr &&
        curr.deliverable_worktype &&
        Array.isArray(curr.deliverable_worktype)
      ) {
        curr.deliverable_worktype.forEach((workType) => {
          if (workType && workType.hours) {
            const hoursAsNumber = Number(workType.hours);
            if (!isNaN(hoursAsNumber)) {
              acc += hoursAsNumber;
            }
          }
        });
      }
      return acc;
    }, 0)
  : null;

  const totalDuration = calculateDurationFromDil(dileverable);
  const avg = (totalDuration.months / formData.budget) * 12;

  const cashFlow = formatMoneyString(avg.toString());

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        width: '100%',
        borderRadius: '8px',
        padding: '0.44rem 0.88rem',
        border: '1px solid #a9a9a9',
        gap: '1rem',
        backgroundColor: '#fafafc',
      }}
    >
      <h5 className="fstxt3" style={{ marginTop: '1rem' }}>
        Your Team KPI&apos;s
      </h5>
      <Box
        sx={{
          display: 'flex',
          width: '95%',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '1rem',
            width: '50%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: '#f1f3f5',
              borderRadius: '8px',
              flexDirection: 'column',
              padding: '0.44rem 0.88rem',
              width: '100%',
            }}
          >
            <div className="fstxt3">{totalHours ? totalHours : '0'}</div>
            <Typography sx={subSx}>Total hours</Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: '#f2ebff',
              borderRadius: '8px',
              padding: '0.44rem 0.88rem',
              flexDirection: 'column',
              width: '100%',
            }}
          >
            <div className="fstxt3">{cashFlow ? cashFlow : '0'}</div>
            <Typography sx={subSx}>Average Cashflow per Month</Typography>
          </Box>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '1rem',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              gap: '1rem',
              backgroundColor: '#fff',
              borderRadius: '8px',
            }}
          >
            <Box
              sx={{
                padding: '0.44rem 0.88rem',
                borderRight: '1px solid #a9aaaa',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
              }}
            >
              <div className="fstxt3">
                {totalDuration.months ? totalDuration.months : '0'}
              </div>
              <Typography sx={subSx}>months</Typography>
            </Box>
            <Box
              sx={{
                padding: '0.88rem',
                borderRight: '1px solid #a9aaaa',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
              }}
            >
              <div className="fstxt3">
                {totalDuration.weeks ? totalDuration.weeks : '0'}
              </div>
              <Typography sx={subSx}>weeks</Typography>
            </Box>
            <Box
              sx={{
                padding: '0.88rem',
                borderRight: '1px solid #a9aaaa',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
              }}
            >
              <div className="fstxt3">
                {totalDuration.days ? totalDuration.days : '0'}
              </div>
              <Typography sx={subSx}>days</Typography>
            </Box>
          </Box>

          <Box
            sx={{
              backgroundColor: '#fff',
              borderRadius: '8px',
              textAlign: 'center',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '0.88rem 1.44rem',
              flexDirection: 'column',
            }}
          >
            <div className="fstxt3">{formatMoneyString('556')}/hr</div>
            <Typography sx={subSx}>Blended Rate on Net Income</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const WorkTypeLOE = ({ formData, newData , tarr}) => {
  function getTotalHoursByWorkType(workTypes, deliverables) {
    const hoursByName = {};
  
    deliverables.forEach((deliverable) => {
      if (
        deliverable &&
        deliverable.deliverable_worktype &&
        Array.isArray(deliverable.deliverable_worktype)
      ) {
        deliverable.deliverable_worktype.forEach((workType) => {
          if (workType && workType.name) {
            const hours = Number(workType.hours);
            const workTypeName = workType.name;
  
            if (!hoursByName[workTypeName]) {
              hoursByName[workTypeName] = 0;
            }
  
            hoursByName[workTypeName] += hours;
          }
        });
      }
    });
  
    const result = Object.entries(hoursByName).map(([name, hours]) => ({
      name,
      hours,
    }));
  
    return result;
  }

  const userWorkTypes = useSelector((state) => state.Misc.workTypes);
  const dileverableWorkTypes = tarr.collaborator_deliverable ? tarr.collaborator_deliverable : [];

  const LOE =
    userWorkTypes && userWorkTypes.length
      ? getTotalHoursByWorkType(tarr.collaborator_worktype, dileverableWorkTypes)
      : [];
  const NetHours = LOE.reduce((acc, { hours }) => acc + hours, 0);

  return (
    <Table>
      <TableHead>
        <TableRow
          sx={{
            background: '#e0e0df',
            borderRadius: '8px',
          }}
        >
          <TableCell align="left">
            <div className="fstxt" style={{ textAlign: 'left' }}>
              Team LOE Hours by Work Type
            </div>
          </TableCell>
          <TableCell align="left">
            <div className="fstxt" style={{ textAlign: 'left' }}>
              Total
            </div>
          </TableCell>
          <TableCell align="right">
            <div className="fstxt" style={{ textAlign: 'right' }}>
              %
            </div>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {LOE.map((item, i) => (
          <TableRow key={i}>
            <TableCell align="left">{item.name}</TableCell>
            <TableCell align="left">{Number(item.hours)}</TableCell>
            <TableCell align="right">
              {Math.round((Number(item.hours) / NetHours) * 100)}%
            </TableCell>
          </TableRow>
        ))}
        <TableRow sx={{ backgroundColor: '#a8d6ff' }}>
          <TableCell align="left">{'  '}</TableCell>
          <TableCell align="left">{NetHours}</TableCell>
          <TableCell align="right">{'100%'}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

const ColabFinalStep = ({ formData, setFormDat, newData, total, GI , tarr, dileverable}) => {
  return (
    <Grid
      container
      justifyContent="space-between"
      sx={{ gap: '2rem', height: '700px' }}
    >
      <Grid item container alignItems="center" justifyContent="space-between">
        <h3 className="fstxtPro">Your Projected Net Net</h3>
        <Box
          sx={{
            height: '2px',
            width: { xl: '74%', lg: '60%' },
            backgroundColor: '#adadad',
          }}
        />
      </Grid>
      <Grid item container direction="column" sx={{ gap: '2rem' }} xl={5}>
        <Grid item sx={{ backgroundColor: '#fff', borderRadius: '8px' }}>
          <NetTable formData={formData} GI={GI} />
        </Grid>

        <Grid item sx={{ backgroundColor: '#fff', borderRadius: '8px' }}>
          <MarginTable formData={formData} />
        </Grid>
      </Grid>
      <Grid item container direction="column" sx={{ gap: '2rem' }} xl={5}>
        <TeamKpi formData={formData} dileverable={dileverable} />
        <WorkTypeLOE formData={formData} newData={newData} tarr={tarr} />
      </Grid>
    </Grid>
  );
};

export default ColabFinalStep;
