import * as React from "react";
import "./sideBarCustom.css";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import iconImage from "./assets/iconFile";
import { Link } from "react-router-dom";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ReceiptIcon from "@mui/icons-material/Receipt";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import ViewColumnIcon from "@mui/icons-material/ViewColumn";
import ViewStreamIcon from "@mui/icons-material/ViewStream";
import TableRowsIcon from "@mui/icons-material/TableRows";
import VideoSettingsIcon from '@mui/icons-material/VideoSettings';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';

import logo from "./assets/logo.png";

const tools = [
  { name: "Dashboard", icon: iconImage.dashboardIcon, path: "/SuperAdmin/" },
  { name: "Customers", icon: <ViewModuleIcon />, path: "/SuperAdmin/Tools/Customers" },
  { name: "Discount Engine", icon: <ShoppingCartIcon />, path: "/SuperAdmin/Tools/DiscountEngine" },
  {
    name: "Notification and Alerts",
    icon: <ReportProblemIcon />,
    path: "/SuperAdmin/Tools/NotificationsandAlerts",
  },
  { name: "Help Tickets", icon: <InfoOutlinedIcon />, path: "/SuperAdmin/Tools/HelpTickets" },
];
const netNetUs = [
  { name: "Courses", icon: <ReceiptIcon />, path: "/SuperAdmin/NetNetU/Courses" },
  { name: "Lessons", icon: iconImage.BookIcon , path: "/SuperAdmin/NetNetU/Lessons"},
  { name: "Authors", icon: iconImage.editIcon, path: "/SuperAdmin/NetNetU/Authors" },
  { name: "Categories", icon: <ViewColumnIcon />, path: "/SuperAdmin/NetNetU/Categories" },
  { name: "Create Help Videos", icon: <OndemandVideoIcon />, path: "/SuperAdmin/NetNetU/create-help-videos" },
  { name: "Help Videos Selection", icon: <VideoSettingsIcon />, path: "/SuperAdmin/NetNetU/help-videos-selection" },
];
const templates = [
  { name: "Templates", icon: <ViewStreamIcon />, path: "/SuperAdmin/Templates/Templates" },
  { name: "Template Categories", icon: <TableRowsIcon />, path: "/SuperAdmin/Templates/TemplateCategories" },
];
const reports = [
  {
    name: "Account Reports",
    icon: iconImage.acountReportIcon, path: "/SuperAdmin/Reports/AccountReports"
  },
];

const systemAdmin = [
  { name: "Users", icon: iconImage.userIcon, path: "/SuperAdmin/SystemAdmin/Users" },
  { name: "Log Files", icon: iconImage.logFilesIcon, path: "/SuperAdmin/SystemAdmin/LogFiles" },
];
export default function SideBarDrawer() {
  const [state, setState] = React.useState({
    left: true,
  });

  const list = (anchor) => (
    <Box
      sx={{
        minWidthidth: 274,
        paddingBottom: '20px',
        backgroundColor: "white",
      }}
    >
      <Link to="/SuperAdmin/">
        <img src={logo} alt="logo" />
      </Link>
      <Box
        sx={{
          height: "266px",
          paddingX: "10px",
          marginBottom: "16px",
        }}
      >
        <Typography
          sx={{
            height: "16px",
            fontFamily: "Open Sans",
            paddingX: "17px",
            fontWeight: "700",
            color: "#676B6B",
            fontSize: "12px",
            lineHeight: "15.6px",
          }}
        >
          TOOLS
        </Typography>

        <List>
          {tools.map((tool, index) => (
            <ListItem
              sx={{
                height: "48px",
                width: "260px",
                transition: "backgroundColor 0.3s ease-in-out",
                ":hover": {
                  borderRadius: "10px",
                  backgroundColor: "#F2F2F2",
                },
              }}
              key={index}
              disablePadding
            >
              <ListItemButton
                sx={{
                  width: "100%",
                  height: "43px",
                  padding: "8px 16px 8px 16px",
                }}
                component={Link}
                to={tool.path}
              >
                <ListItemIcon
                  sx={{
                    height: "24px",
                    width: "24px",
                  }}
                >
                  {tool.icon}
                </ListItemIcon>
                <ListItemText
                  sx={{
                    width: "172px",
                    height: "19px",
                    fontFamily: "Open Sans",
                    fontSize: "14px",
                    fontWeight: "400",
                    lineHeight: "19px",
                    textAlign: "left",
                    color: "#03071E",
                  }}
                  primary={tool.name}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>

      <Box
        sx={{
          height: "318px",
          paddingX: "10px",
          marginY: "10px",
          marginBottom: "16px",
        }}
      >
        <Typography
          sx={{
            height: "16px",
            fontFamily: "Open Sans",
            paddingX: "17px",
            fontWeight: "700",
            color: "#676B6B",
            fontSize: "12px",
            lineHeight: "15.6px",
          }}
        >
          NET NET U
        </Typography>
        <List>
          {netNetUs.map((netNetU, index) => (
            <ListItem
              sx={{
                borderRadius: "10px",
                height: "48px",
                width: "260px",
                transition: "backgroundColor 0.3s ease-in-out",
                ":hover": {
                  borderRadius: "10px",
                  backgroundColor: "#F2F2F2",
                },
              }}
              key={index}
              disablePadding
            >
              <ListItemButton
                sx={{
                  width: "100%",
                  height: "43px",
                  padding: "8px 16px 8px 16px",
                }}
                component={Link}
                to={netNetU.path}
              >
                <ListItemIcon
                  sx={{
                    maxHeight: "24px",
                    maxWidth: "24px",
                  }}
                >
                  {netNetU.icon}
                </ListItemIcon>
                <ListItemText
                  sx={{
                    width: "172px",
                    height: "19px",
                    fontFamily: "Open Sans",
                    fontSize: "14px",
                    fontWeight: "400",
                    lineHeight: "19px",
                    textAlign: "left",
                    color: "#03071E",
                  }}
                  primary={netNetU.name}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>

      <Box sx={{ height: "122px", paddingX: "10px", marginY: "10px" }}>
        <Typography
          sx={{
            height: "16px",
            fontFamily: "Open Sans",
            paddingX: "17px",
            fontWeight: "700",
            color: "#676B6B",
            fontSize: "12px",
            lineHeight: "15.6px",
          }}
        >
          TEMPLATES
        </Typography>

        <List>
          {templates.map((template, index) => (
            <ListItem
              sx={{
                borderRadius: "10px",
                height: "48px",
                width: "260px",
                transition: "background-color 0.3s ease-in-out",
                ":hover": {
                  borderRadius: "10px",
                  backgroundColor: "#F2F2F2",
                },
              }}
              key={index}
              disablePadding
            >
              <ListItemButton
                sx={{
                  width: "100%",
                  height: "43px",
                  padding: "8px 16px 8px 16px",
                }}
                component={Link}
                to={template?.path}
              >
                <ListItemIcon
                  sx={{
                    height: "24px",
                    width: "24px",
                  }}
                >
                  {template.icon}
                </ListItemIcon>
                <ListItemText
                  sx={{
                    width: "172px",
                    height: "19px",
                    fontFamily: "Open Sans",
                    fontSize: "14px",
                    fontWeight: "400",
                    lineHeight: "19px",
                    textAlign: "left",
                    color: "#03071E",
                  }}
                  primary={template.name}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>

      <Box
        sx={{
          height: "74px",
          paddingX: "10px",
          marginY: "10px",
          marginBottom: "16px",
        }}
      >
        <Typography
          sx={{
            height: "16px",
            fontFamily: "Open Sans",
            paddingX: "17px",
            fontWeight: "700",
            color: "#676B6B",
            fontSize: "12px",
            lineHeight: "15.6px",
          }}
        >
          REPORTS
        </Typography>
        <List>
          {reports.map((report, index) => (
            <ListItem
              sx={{
                borderRadius: "10px",
                height: "48px",
                width: "260px",
                transition: "background-color 0.3s ease-in-out",
                ":hover": {
                  borderRadius: "10px",
                  backgroundColor: "#F2F2F2",
                },
              }}
              key={index}
              disablePadding
            >
              <ListItemButton
                sx={{
                  width: "100%",
                  height: "43px",
                  padding: "8px 16px 8px 16px",
                }}
                component={Link}
                to={report?.path}
              >
                <ListItemIcon
                  sx={{
                    height: "24px",
                    width: "24px",
                  }}
                >
                  {report.icon}
                </ListItemIcon>
                <ListItemText
                  sx={{
                    width: "172px",
                    height: "19px",
                    fontFamily: "Open Sans",
                    fontSize: "14px",
                    fontWeight: "400",
                    lineHeight: "19px",
                    textAlign: "left",
                    color: "#03071E",
                  }}
                  primary={report.name}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>

      <Box
        sx={{
          height: "122px",
          paddingX: "10px",
          marginY: "10px",
          marginBottom: "16px",
        }}
      >
        <Typography
          sx={{
            height: "16px",
            fontFamily: "Open Sans",
            paddingX: "17px",
            fontWeight: "700",
            color: "#676B6B",
            fontSize: "12px",
            lineHeight: "15.6px",
          }}
        >
          SYSTEM ADMIN
        </Typography>
        <List>
          {systemAdmin.map((sysAdmin, index) => (
            <ListItem
              sx={{
                borderRadius: "10px",
                height: "48px",
                width: "260px",
                transition: "background-color 0.3s ease-in-out",
                ":hover": {
                  borderRadius: "10px",
                  backgroundColor: "#F2F2F2",
                },
              }}
              key={index}
              disablePadding
            >
              <ListItemButton
                sx={{
                  width: "100%",
                  height: "43px",
                  padding: "8px 16px 8px 16px",
                }}
                component={Link}
                to={sysAdmin?.path}
              >
                <ListItemIcon
                  sx={{
                    height: "24px",
                    width: "24px",
                  }}
                >
                  {sysAdmin.icon}
                </ListItemIcon>
                <ListItemText
                  sx={{
                    width: "172px",
                    height: "19px",
                    fontFamily: "Open Sans",
                    fontSize: "14px",
                    fontWeight: "400",
                    lineHeight: "19px",
                    textAlign: "left",
                    color: "#03071E",
                  }}
                  primary={sysAdmin.name}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
    </Box>
  );

  return (
    <>
      <React.Fragment key={"left"}>
        <Drawer
          sx={{
            width: 274,
            flexShrink: 0,
            maxHeight: "914px",
          }}
          variant="permanent"
          anchor={"left"}
          open={state["left"]}
        >
          {list("left")}
        </Drawer>
      </React.Fragment>
    </>
  );
}
