import { useState } from "react"

const Select = ({items}) => {
    const [deliverable, setDeliverable] = useState('');
    const handler = (e) => {
        setDeliverable(e.target.value)
    }
    return <select value={deliverable} onChange={handler} className='kpi-drop-down inner-text kpi-text'>
       <option value="all" selected>All</option>
       {items.map((item)=><option value={item}>{item}</option>)}
    </select>
};

export default Select;