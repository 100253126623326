import React, { useState, useEffect, useCallback, useRef, } from "react";
import { Box, Button, Grid, Popover, Typography } from "@mui/material";
import { useSelector } from 'react-redux';
import 'primereact/resources/primereact.min.css';
import './Estimate.css'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  ResponsiveContainer,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Legend,
  Bar,
  Cell,
  Tooltip,
} from 'recharts';
import { getColor } from '../../pages/KPI/Utility/helper';
import { legened } from '../../pages/KPI/Utility/Legend';
import { BsFillCircleFill } from "react-icons/bs";
import { CalendarMonthOutlined, KeyboardArrowUp } from "@mui/icons-material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateRangeCalendar } from "@mui/x-date-pickers-pro";
import ExpandMoreSharpIcon from '@mui/icons-material/ExpandMoreSharp';
import dayjs from 'dayjs';
import { HiMiniUserCircle } from "react-icons/hi2";
import { IoCloseSharp } from "react-icons/io5";
import { IoIosCloseCircle, IoMdArrowDropup, IoMdArrowDropdown } from "react-icons/io";
import { FaAngleDown } from "react-icons/fa6";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { FaStar } from "react-icons/fa";
import { ENDPOINT } from "../Opportunity/Redux/oppor";

const TooltipComponent = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    const data = payload[0].payload; // Get the data associated with the hovered bar
    let persent = 0; // Default value if data or data.percent is not defined

    if (data && data.persent) {
      persent = parseFloat(data.persent.replace('%', ''));    
    }
    return (
      <div className="custom-tooltip">
        <div style={{display:'flex',justifyContent:'space-between',alignItems:'center',backgroundColor:persent > 100 ? 'rgb(255, 0, 0,0.2)': persent > 80 ? 'rgb(255, 165, 0,0.2)':'rgb(60, 179, 113,0.2)',borderRadius:'6px',padding:'5px'}}>
          <div style={{lineHeight:-2}}>
        <p style={{lineHeight:-2,fontWeight:'bold'}}>{`${data.elapsedTime} / ${data.estimitedTime}`}<br/>hour</p>
          </div>
          <div style={{borderLeft:persent > 100 ? '1px solid rgb(255, 0, 0)': persent > 80 ? '1px solid rgb(255, 165, 0)':'1px solid rgb(60, 179, 113)',height:'30px',marginLeft:'10px',marginRight:'10px'}} />
          <div>
        <p style={{fontWeight:'bold'}}>{`${data.persent}`}</p>
          </div>
        </div>
      </div>
    );
  }
  return null;
};


const Bar_chart = ({data, isUnit = false, isLegend = false })=>{
  return(
    
    <ResponsiveContainer width="100%" height={450} className="bar-chart">
        <BarChart
          barGap={-55}
          width={500}
          height={400}
          data={data}
          margin={{ top: 25 }}
        >
          <CartesianGrid vertical={false} strokeDasharray="3 3" />
          <XAxis dataKey="title" allowDataOverflow={false} />
          {isUnit ? (
            <YAxis
              unit="Hours"
              axisLine={false}
              dataKey="estimitedTime"
              padding={{ top: 10, left: 10, right: 10 }}
            />
          ) : (
            <YAxis axisLine={false} />
          )}
          <Tooltip content={<TooltipComponent/>} cursor={false} />
          {isLegend ? <Legend align="left" content={legened} /> : null}

          <Bar
            dataKey="estimitedTime"
            barSize={60}
            fill="rgba(94, 230, 144, 0.4)"
          >
          </Bar>

          <Bar dataKey="elapsedTime" barSize={50} fill="#5ee690" maxBarSize={60}>
            {data.map((each, index) => {
              return (
                <Cell
                  key={`cell-${index}`}
                  fill={getColor(each.elapsedTime, each.estimitedTime)}
                />
              );
            })}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
  )
}

const Estimatelegened = () => {
  return (
    <>
      <Box>
        <ul className="legend">
          <li style={{ fontWeight: 900 }} >Key Indicators</li>
          <li style={{ fontWeight: 900 }} ><BsFillCircleFill style={{ color: '#5ee690' }} /> Actual Hours (0-80%) of Estimated Hour</li>
          <li style={{ fontWeight: 900 }}> <BsFillCircleFill style={{ color: '#dffae9' }} /> Remaining/Estimated Hours</li>
          <li style={{ fontWeight: 900 }} > <BsFillCircleFill style={{ color: '#dc241c' }} /> Over Estimated Hours</li>
          <li style={{ fontWeight: 900 }}> <BsFillCircleFill style={{ color: '#f59825' }} /> Reached 81% of Estimated Hours</li>
        </ul>
      </Box>
    </>
  )
}

const DeliverableAssestMents = ({ data, isUnit = false, isLegend = false, calculateHours=false }) => {

  let displayDaTA = [];

  if (data[0]?.updatedDiliverables?.length > 0) {
    const updatedData = data[0]?.updatedDiliverables.map((item) => ({
      ...item,
      total_hours: item?.total_hours === null ? 0 : item?.total_hours,
    }));
    displayDaTA = updatedData;
  } else {
    const newData = data[0]?.deliverable_project?.map((items, index) => ({
      ...items,
      total_hours: items?.total_hours === null ? 0 : items?.total_hours,
    }));
    displayDaTA = newData;
  }

  let total_hour = 0
  let total_estimated = 0

  let realDeliverable = data[0]?.deliverable_project.map((v,i)=>{
    let totalHours = 0;
    let persent = 0+'%'
    v.deliverable_worktype.forEach(item => {
      if (item.hasOwnProperty('hours')) {
        totalHours += item.hours;
      }
    });

     let totalTrackedTimeInHours = 0;
     v.task_deliverable.map((val,i)=>{

       val.time_tracking.length > 0 && val.time_tracking.forEach((time) => {
         if (time.tracked_time_hours) {
           // Splitting the time string and converting hours, minutes, and seconds into numbers
           const [hours, minutes, seconds] = time.tracked_time_hours.split(':').map(Number);
           // Calculating total time in hours
           const totalTimeInHours = hours + (minutes / 60) + seconds / 3600;
           totalTrackedTimeInHours += totalTimeInHours;
         }
       });
     })

     totalTrackedTimeInHours = Math.round(totalTrackedTimeInHours)

     total_estimated = total_estimated + totalHours
     total_hour = total_hour + totalTrackedTimeInHours
     persent = Math.round(totalTrackedTimeInHours / totalHours * 100) + ' %'
    return ({
        head_name: v?.deliverable_name ?? "",
        persent:persent === 'NaN %'? 0 +' %' : persent,
        title: v.deliverable_name,
  estimitedTime: Number(totalHours),
  elapsedTime: totalTrackedTimeInHours,
  })
  })

  return (
    <>
    {
      calculateHours &&
                      <p style={{ marginLeft: "21px", fontSize: "14px", fontWeight: 1000, letterSpacing: "1px", marginTop: "6px" }} >TOTAL HOURS: {total_hour} TOTAL/ {total_estimated} ESTIMATED</p>
    }
        <Bar_chart data={realDeliverable} />
    </>
  );
  ;
}

const WorkTypeBarCharts = ({ filteredProject, data, isUnit = false, isLegend = false }) => {

  const newData = data?.map((items, index) => ({
    ...items,
    hours: items.hours === null ? 0 : items.hours
  }))

  const calculateTotalAssigned = (worktype) => {
    let totalDuration = 0;

    if (Array.isArray(worktype) && worktype.length > 0) {
      totalDuration = worktype

        .reduce((acc, item) => {
          const hours = Number(item.hours);
          return acc + hours;

        }, 0);
    }

    return totalDuration;
  }

  const realWork = newData.map((wt) => {
    let persent = '';
    const assignedCalc = Array.isArray(filteredProject.task_deliverable) && filteredProject.task_deliverable.length ? filteredProject.task_deliverable.filter((who) => who.task_worktype.some((mo) => mo.name === wt.name)) : []
    let totalAssigned = Array.isArray(assignedCalc) && assignedCalc.length ?
      assignedCalc.reduce(
        (acc, tsk) =>
          acc + calculateTotalAssigned(tsk.task_worktype),
        0
      ) : 0

      persent = Math.round(totalAssigned / wt.hours * 100) + ' %'

    return {
      head_name: wt?.deliverable_name ?? "",
      persent:persent === 'NaN %'? 0 +' %' : persent,
      title: wt.name,
      estimitedTime: Number(wt.hours),
      elapsedTime: totalAssigned,
    };
  });

  return (
    <>
    <Bar_chart data={realWork} />
    </>
  );
};

const BarCharts = ({ data, task, myTeam, updatedTeam, isUnit = false, isLegend = false }) => {

  let realuser =  myTeam.members.map((user)=>{
    let userName = null;
    let estimated_hours = 0;
    let totalTrackedTimeInHours = 0;
    let persent = '';
    data.map((deliv)=>{
        deliv.task_deliverable.length > 0 && deliv.task_deliverable.map((task)=>{
            if (task.assignee_task.length > 0 && task.assignee_task[0].assignee_user === user.id) {
                task.time_tracking.length > 0 && task.time_tracking.forEach((time) => {
                    if (time.tracked_time_hours) {
                        const [hours, minutes, seconds] = time.tracked_time_hours.split(':').map(Number);
                        const totalTimeInHours = hours + (minutes / 60) + seconds / 3600;
                        totalTrackedTimeInHours += totalTimeInHours;
                    }
                });

                userName = user.first_name;
                estimated_hours += task.estimated_hours;

                return true;
            }
            return false;
        });
    });

    // Only return the object if userName is not null
    if (userName !== null) {
      totalTrackedTimeInHours = Math.round(totalTrackedTimeInHours)
      persent = Math.round(totalTrackedTimeInHours / estimated_hours * 100) + ' %'

        return {
            head_name: user?.deliverable_name ?? "",
            persent:persent === 'NaN %'? 0 +' %' : persent,
            title: userName,
            estimitedTime: Number(estimated_hours),
            elapsedTime: totalTrackedTimeInHours,
        };
    } else {
        return null; // Return null if userName is null
    }
}).filter(user => user !== null); // Filter out objects where userName is still null

  return (
    <>
  <Bar_chart data={realuser} />
    </>
  );
};


const TaskCharts = ({ data, task, isUnit = false, isLegend = false, filteredProject }) => {

  if (!task) {

    var RealData = data?.map((item) => {
      return item?.task_deliverable
    })
  } else {
    var RealData = task?.map((item) => {
      return item.taskofDilverables
    })
  }

  const flatenedArray = RealData?.flat()

  const WithEstimateModification = flatenedArray?.map((item) => ({
    ...item,
    estimated_hours: item.estimated_hours === null ? 0 : item.estimated_hours
  }))

  let final = WithEstimateModification.map((v, i) => {
    let totalTrackedTimeInHours = 0;
    let persent = '';
    v.time_tracking.length > 0 && v.time_tracking.forEach((time) => {
      if (time.tracked_time_hours) {
        // Splitting the time string and converting hours, minutes, and seconds into numbers
        const [hours, minutes, seconds] = time.tracked_time_hours.split(':').map(Number);
        // Calculating total time in hours
        const totalTimeInHours = hours + (minutes / 60) + seconds / 3600;
        totalTrackedTimeInHours += totalTimeInHours;
      }
    });

    totalTrackedTimeInHours = Math.round(totalTrackedTimeInHours)
    persent = Math.round(totalTrackedTimeInHours / v.estimated_hours * 100) + ' %'

    return {
      head_name: v?.deliverable_name ?? "",
      persent:persent === 'NaN %'? 0 +' %' : persent,
      title: v.task_name,
      estimitedTime: Number(v.estimated_hours),
      elapsedTime: totalTrackedTimeInHours,
    };

  })

  return (
    <>
     <Bar_chart data={final} />
    </>
  );
};

const Estimate = ({ isLegend = false }) => {

  const [myTeam, setMyTeam] = useState(null)
  const allTasks = useSelector((state) => state.projects.tasks);
  const [myArr, setMyArr] = useState([])
  const clients = useSelector((state) => state.Contacts.companies)
  const access_token = useSelector((state) => state.auth.user.access);

  const apiInitialProject = async ()=>{
    const response = await fetch(` ${ENDPOINT}/api/project/list/`, {
      method: 'GET',
      headers: {
        Authorization: `JWT ${access_token}`,
      },
    });
    const data = await response.json();
    if (!response.ok) {
    } else {
      const pro = data.sort((a, b) => {
        // Assuming 'created_at' is a property in your project objects
        const dateA = new Date(a.created_at);
        const dateB = new Date(b.created_at);
        // Sort in descending order (newest created first)
        return dateB - dateA;
      });

      setMyArr(pro)
  
      return pro
    }
  };
  
  const [filterClientPopop, setFilterClientPopop] = useState(false)
  const [AllProjects, setAllProjects] = useState()

  useEffect(()=>{
    apiInitialProject()
  },[])
  useEffect(() => {
    setAllProjects(myArr)
  }, [myArr]);

  const [day, setDay] = useState('This Week')

  const buttonNames = [day, 'Billable', 'Export As'];
  const [clientValue, setClientValue] = useState()

  const handleClientListSelect = (v, i) => {

    let arry = clientValue?.find((val) => val.name === v.name)

    if (arry) {
      let arry = clientValue?.filter((flt) => flt.name !== v.name)
      setClientValue(arry)
    }
    else {
      setClientValue([v])
    }
  }

  const [deliverableBreakdown, setDeliverbleBreakDown] = useState(false)
  const [workTypeDeliverable, setWorktypeDeliverable] = useState(false)
  const [teamMembers, setTeamMembers] = useState(false)
  const [taskBreakDown, setTaskBreakDown] = useState(false)

  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverIndex, setPopoverIndex] = useState(null);

  const handleClick = (event, index) => {
    setAnchorEl(event.currentTarget);
    setPopoverIndex(index);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setPopoverIndex(null);
  };

  const [dateRange, setDateRange] = useState([
    dayjs().startOf('week'),
    dayjs().endOf('week'),
  ]);

  const handleDateRange = (v) => {
    setDateRange(v)
  }

  useEffect(() => {
    const filteredProjects = AllProjects?.filter((project) => {
      const projectStartDate = new Date(project?.created_at);
      const projectEndDate = new Date(project?.finish_date);
      return (
        (projectStartDate >= dateRange[0] && projectStartDate < addDays(dateRange[1], 1)) ||
        (projectEndDate >= dateRange[0] && projectEndDate < addDays(dateRange[1], 1))
      );
    });

    if (!filteredProjects?.length > 0) {
      setAllProjects(myArr)

    } else {
      setAllProjects(filteredProjects)
    }
  }, [dateRange, myArr])

  const addDays = (date, days) => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  };

  const handleClickDay = (v, i) => {
    setDay(v)
    if (v === 'Today') {
      const today = dayjs();
      setDateRange([today,])
    }
    else if (v === 'Yesterday') {
      const today = dayjs();
      setDateRange([today.add(1, 'day'),])
    }
    else if (v === 'This Week') {
      const today = dayjs();
      setDateRange([today.startOf('week'), today.endOf('week')])
    }
    else if (v === 'Last Week') {
      const today = dayjs();
      const prevWeek = today.subtract(7, 'day');
      setDateRange([prevWeek.startOf('week'), prevWeek.endOf('week')
    ]);

    }
    else if (v === 'This Month') {
      const today = dayjs();
      setDateRange([today.startOf('month'), today.endOf('month')])
    }
    else if (v === 'Last Month') {
      const today = dayjs();
      const startOfNextMonth = today.startOf('month').subtract(1, 'month');
      setDateRange([startOfNextMonth, startOfNextMonth.endOf('month')]);

    }
    else if (v === 'This Year') {
      const today = dayjs();
      setDateRange([today.startOf('year'), today.endOf('year')])

    }
    else if (v === 'Last Year') {
      const today = dayjs();
      setDateRange([today.startOf('year').subtract(1, 'year'), today.endOf('year').subtract(1, 'year')])
    }
  }

  const [billable, setBillable] = useState()
  const handleClickBillable = (v, i) => {

    if (v === "Billable") {
      setBillable(true)
    } else if (v === "Non Billable") {
      setBillable(false)
    } else {
      setBillable(null)
    }
  }

  const printDocument = () => {
    const input = document.getElementById('div-to-print');
    const pdf = new jsPDF({
      orientation: 'portrait',
      unit: 'mm',
      format: 'a4',
    });
    const marginLeft = 10;
    const marginTop = 10;

    const usableWidth = pdf.internal.pageSize.width - 2 * marginLeft;
    const usableHeight = pdf.internal.pageSize.height - 2 * marginTop;

    html2canvas(input, { scale: 2 })
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');

        const aspectRatio = canvas.width / canvas.height;
        let imgWidth, imgHeight;

        if (aspectRatio > 1) {
          imgWidth = usableWidth;
          imgHeight = usableWidth / aspectRatio;
        } else {
          imgHeight = usableHeight;
          imgWidth = usableHeight * aspectRatio;
        }

        pdf.addImage(imgData, 'PNG', marginLeft, marginTop, imgWidth, imgHeight);
        pdf.save('Estimate Report');
      });
  };


  const downloadCSV = () => {

    const table = document.getElementById("div-to-print");

    // Extract rows from the table
    const rows = Array.from(table.getElementsByTagName('tr'));

    const csvData = rows.map(row => {
      const cells = Array.from(row.getElementsByTagName('td'));
      return cells.map(cell => cell.innerText.trim());
    });

    const csvContent = csvData.map(row => row.join(',')).join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv' });

    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'data.csv';
    link.click();
  };

  const [exportAs, setExportAs] = useState(0)
  const handleClickExportAs = (v, i) => {
    if (v === "PDF") {
      if (filteredProject.length > 0) {
        printDocument()
      } else {
      }
    } else if (v === "CSV") {
      if (filteredProject.length > 0) {
        downloadCSV()
      } else {
      }
    }
    setExportAs(i)
    handleClose()
  }

  const [filterProjectPopop, setFilterProjectPopop] = useState(false)

  const [projectValue, setProjectValue] = useState([])

  const [filteredProject, setFilteredProject] = useState([])

  const handleProjectListSelect = (v, i) => {

apiInitialProjectDetail(v)

  }

  let combineproject = []
  Array.isArray(allTasks) && allTasks.filter((val, ind) => {

    const project_name = Array.isArray(myArr) && myArr.length && val.task_project ? myArr.find((item) => item.id === val?.task_project)?.title : ''
    if (project_name) {
      let find = combineproject.length > 0 && combineproject.find((fnd) => fnd.name === val.task_name)
      if (find) {
      }
      else {
        combineproject.push({ name: val.task_name, project: project_name })
      }
    }
  })

  const [params, setParams] = useState({
    value: '',
    data: [],
    projects: [],
    finalFilterArray: []
  });

  const [deliverableValue, setDeliverableValue] = useState([{ name: 'Admin', discription: 'Dillas Tx, Usa', img: '' }])

  const [filterDeliverablePopop, setFilterDeliverablePopop] = useState(false)

  const [filterWorktypePopop, setFilterWorktypePopop] = useState(false)

  const [worktypeValue, setWorktypeValue] = useState([])
  const [resultValueWorktype, setResultValueWorktype] = useState([])

  useEffect(() => {
    setParams({ ...params, finalFilterArray: params.finalFilterArray = resultValueWorktype })
  }, [resultValueWorktype])

  const MergedWorkTypes = [];
  filteredProject[0]?.updatedDiliverables?.forEach((item) => {
    if (Array.isArray(item.deliverable_worktype)) {
      item.deliverable_worktype.forEach((worktype) => {
        // Check if the worktype object is not already in MergedWorkTypes
        const exists = MergedWorkTypes.some((existingWorktype) => existingWorktype.name === worktype.name);
        if (!exists) {
          // If not, add the entire worktype object to MergedWorkTypes
          MergedWorkTypes.push(worktype);
        }
      });
    }
  });

  // this is filter People

  const [filterPeoplePopop, setFilterPeoplePopop] = useState(false)
  const [selectedDiliverables, setSelectedDiliverables] = useState([])
  const [taskOfDiliverables, settaskOfDiliverables] = useState([])
  const [selectedWorktypes, setselectedWorktypes] = useState([])

  const [Deliverableaccordian, setDeliverableAccordian] = useState('')

  const handleAccordianOpen = (v, i) => {
    setDeliverableAccordian(v.deliverable_name)
    setSelectedDiliverables([...selectedDiliverables, v])
    if (Deliverableaccordian === v.deliverable_name) {
      setDeliverableAccordian("")
    }
  }

  const handleSelectTask = (item, i) => {
    // Check if the task is already in taskOfDiliverables
    const isTaskSelected = taskOfDiliverables.some((task) => task.task_name === item.task_name);
    if (!isTaskSelected) {
      // If the task is not selected, add it to the taskOfDiliverables array
      settaskOfDiliverables([...taskOfDiliverables, { ...item, index: i }]);
    } else {
      // If the task is already selected, remove it from the taskOfDiliverables array
      settaskOfDiliverables(taskOfDiliverables.filter((task) => task.task_name !== item.task_name));
    }
  };

  const handleWorktypeListSelect = (v, i) => {

    const exists = selectedWorktypes.some((existingWorktype) => existingWorktype.name === v.name);

    if (!exists) {
      // If not, add the entire worktype object to selectedWorktypes
      setselectedWorktypes([...selectedWorktypes, v]);
    }
  }

  const [selectedPeople, setSelectedPeople] = useState([])

  const handlePeopleListSelect = (v, i) => {
    setSelectedPeople([...selectedPeople, v])
    selectedPeople.map((data) => {
      if (data.task_name === v.task_name) {
        settaskOfDiliverables([])
      }
    })
  }

  useEffect(() => {
    const clientIds = clientValue?.map((client) => client.id);

    if (clientIds?.length > 0) {
      // Filter projects based on client IDs
      const projects = AllProjects?.filter((project) => clientIds?.includes(project.company));

      if (projects?.length > 0) {
        setAllProjects(projects);
      } else {
        // If no projects match, set a default value (myArr in this case)
        setAllProjects(myArr);
      }
    } else {
      // Handle the case where there are no clients
      setAllProjects(myArr);
    }
  }, [clientValue])


  useEffect(() => {
    const updatedFilteredProject = filteredProject.map((item, index) => ({
      ...item,

      selectedTeamMembers: selectedPeople,
      updatedDiliverables: selectedDiliverables.map((deliverableItem) => ({
        ...deliverableItem,
        updatedWorktypes: selectedWorktypes,
        taskofDilverables: taskOfDiliverables,
      })),
    }))
    setFilteredProject(updatedFilteredProject)

  }, [selectedDiliverables, taskOfDiliverables, AllProjects, dateRange])

  const handleClientPopopToggle = useCallback(() => {
    setFilterClientPopop(!filterClientPopop);
    setFilterPeoplePopop(false); // Close the People popover when Worktype is opened
    setFilterWorktypePopop(false);
    setFilterDeliverablePopop(false);
    setFilterProjectPopop(false)
  }, [filterClientPopop, setFilterClientPopop, setFilterPeoplePopop]);

  const handlePeoplePopopToggle = useCallback(() => {
    setFilterPeoplePopop(!filterPeoplePopop);
    setFilterWorktypePopop(false); // Close the People popover when Worktype is opened
    setFilterClientPopop(false);
    setFilterDeliverablePopop(false);
    setFilterProjectPopop(false)
  }, [filterPeoplePopop, setFilterPeoplePopop, setFilterPeoplePopop]);

  const handleDeliverablePopopToggle = useCallback(() => {
    setFilterDeliverablePopop(!filterDeliverablePopop);
    setFilterPeoplePopop(false); // Close the People popover when Worktype is opened
    setFilterClientPopop(false);
    setFilterWorktypePopop(false);
    setFilterProjectPopop(false)
  }, [filterDeliverablePopop, setFilterDeliverablePopop, setFilterPeoplePopop]);

  const handleProjectPopopToggle = useCallback(() => {
    setFilterProjectPopop(!filterProjectPopop);
    setFilterPeoplePopop(false); // Close the People popover when Worktype is opened
    setFilterClientPopop(false);
    setFilterDeliverablePopop(false);
    setFilterWorktypePopop(false)
  }, [filterProjectPopop, setFilterProjectPopop, setFilterPeoplePopop]);

  const handleWorktypePopopToggle = useCallback(() => {
    setFilterWorktypePopop(!filterWorktypePopop);
    setFilterPeoplePopop(false); // Close the People popover when Worktype is opened
    setFilterClientPopop(false);
    setFilterDeliverablePopop(false);
    setFilterProjectPopop(false)
  }, [filterWorktypePopop, setFilterWorktypePopop, setFilterPeoplePopop]);

  const clientRef = useRef()
  const projectRef = useRef()
  const deliverableRef = useRef()
  const worktypeRef = useRef()
  const peopleRef = useRef()

  useEffect(() => {
    // Function to handle clicks outside of the popovers
    function handleClickOutside(event) {
      if (clientRef.current && !clientRef.current.contains(event.target)) {
        setFilterClientPopop(false);
      }
      if (projectRef.current && !projectRef.current.contains(event.target)) {
        setFilterProjectPopop(false);
      }
      if (deliverableRef.current && !deliverableRef.current.contains(event.target)) {
        setFilterDeliverablePopop(false);
      }
      if (worktypeRef.current && !worktypeRef.current.contains(event.target)) {
        setFilterWorktypePopop(false);
      }
      if (peopleRef.current && !peopleRef.current.contains(event.target)) {
        setFilterPeoplePopop(false);
      }
    }

    // Add event listener when component mounts
    document.addEventListener("mousedown", handleClickOutside);

    // Remove event listener when component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const apiInitialProjectDetail = async (v)=>{
    
    try{
      const res = await fetch(`${ENDPOINT}/api/project/detail/${v?.unique_id}/`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `JWT ${access_token}`,
        },
      })
      const data = await res.json();
      if(res.ok){

        setFilteredProject([data])
        setMyTeam(data.project_subteam[0])
        console.error(data.project_subteam[0],'api sucess')
      }else {

      }

    } catch(error){
      console.error(error, 'api error')
    } 
  }
  if(filteredProject.length > 0){
    apiInitialProjectDetail()
  }

  return (
    <>
      <Grid
        sx={{
          backgroundColor: 'inherit',
          padding: '1rem 0rem',
          borderBottom: '1px solid #e2e2e2',

        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: '2.9rem',
            width: '95%',
            backgroundColor: '#F1F3F5',
            padding: '1rem 1rem',
            margin: '0 auto',
            borderRadius: '8px',
          }}
        >

          <div>
            {buttonNames.map((name, index) => (
              <>
                <Button
                  variant="contained"
                  aria-describedby={index}
                  onClick={(event) => handleClick(event, index)}
                  startIcon={index === 0 && <CalendarMonthOutlined />}
                  endIcon={<ExpandMoreSharpIcon style={{ width: '18px', height: '18px', fontWeight: 200, color: '#03071E' }} />}
                  sx={{
                    mr: 4, backgroundColor: '#E0E0DF', color: 'black', textTransform: 'inherit', boxShadow: 'inherit', fontWeight: 400, fontSize: '14px',
                    '&:hover': { backgroundColor: '#E0E0DF', color: 'black', boxShadow: 'inherit' }
                  }}
           
                >
                  {
                    index === 1 ?
                      name
                      :
                      name
                  }
                </Button>
                <Popover
                  id={index}
                  open={popoverIndex === index}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  sx={{
                    '& .MuiPaper-root': {
                      boxShadow: 'inherit', border: '1px solid #E0E0DF', borderRadius: 2
                    }
                  }}

                >
                  {
                    index === 0 &&
                    <>
                      <Box align='center' sx={{ width: 'auto', height: 'auto', px: '30px', display: { xs: 'block', md: 'flex' } }}>
                        <Box align='left' sx={{ backgroundColor: '#F8F9FA', width: { xs: '100%', md: '200px' }, p: 1, pb: 3, my: 4, borderRadius: 2 }}>
                          {
                            ['Today', 'Yesterday', 'This Week', 'Last Week', 'This Month', 'Last Month', 'This Year', 'Last Year'].map((v, i) => {
                              return (
                                <Typography key={i} onClick={() => handleClickDay(v, i)} sx={day === v ?
                                  { fontSize: '16px', fontWeight: 400, cursor: 'pointer', color: '#711FFF', my: 3, ml: 2 }
                                  :
                                  { fontSize: '16px', fontWeight: 400, cursor: 'pointer', color: '#03071E', my: 3, ml: 2 }
                                }>{v}</Typography>
                              )
                            })
                          }

                        </Box>
                        <Box sx={{ mx: 4, my: 4 }}>

                          <LocalizationProvider dateAdapter={AdapterDayjs} >
                            <DateRangeCalendar
                              value={dateRange}
                              onChange={(newValue) => handleDateRange(newValue)}
                              sx={{
                                '& .css-10wpov9-MuiTypography-root ': {
                                  fontWeight: '700 !important'
                                },
                                '& .css-cyzddl-MuiPickersSlideTransition-root-MuiDayCalendar-slideTransition:not(.MuiDateRangeCalendar-dayDragging) .MuiDateRangePickerDay-dayOutsideRangeInterval': {
                                  color: '#03071E !important',
                                  fontSize: '16px !important',
                                  fontWeight: 500,
                                },
                                '& .css-1gbl7yn-MuiDateRangePickerDay-root': {
                                  backgroundColor: '#310085 !important',
                                  color: 'white !important',
                                  borderRadius: '0px !important'
                                },
                                '& .css-1e841vg-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day.Mui-selected': {
                                  backgroundColor: '#711FFF !important',
                                  color: "white !important",
                                  borderRadius: '0px !important',
                                  fontSize: '16px !important',
                                  fontWeight: 500
                                },
                                '& .css-1ckjanr-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day.Mui-selected': {
                                  backgroundColor: '#711FFF !important',
                                  color: "white !important",
                                  borderRadius: '0px !important',
                                  fontSize: '16px !important',
                                  fontWeight: 500
                                },
                                '& .css-1a4q4r2-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day.Mui-selected': {
                                  backgroundColor: '#711FFF !important',
                                  color: "white !important",
                                  borderRadius: '0px !important',
                                  fontSize: '16px !important',
                                  fontWeight: 500
                                },
                                '& .css-2ko3hu-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day': {
                                  backgroundColor: '#310085 !important',
                                  color: "white !important",
                                  opacity: 'inherit !important',
                                  borderRadius: '0px',
                                  border: 'none !important',
                                  fontSize: '16px !important',
                                  fontWeight: 500
                                },
                                '& .css-1ku4sqv-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day': {
                                  backgroundColor: '#711FFF !important',
                                  color: "white !important",
                                  borderRadius: '0px !important',
                                  fontSize: '16px !important',
                                  fontWeight: 500
                                },
                                '& .css-ahwqre-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day:not(.Mui-selected)': {
                                  backgroundColor: '#310085 !important',
                                  color: "#e569db !important",
                                  borderRadius: '0px !important',
                                  fontSize: '16px !important',
                                  fontWeight: 500
                                },
                                '& .css-jef1b6-MuiDateRangeCalendar-container:not(:last-of-type)': {
                                  borderRight: 'none !important'
                                },
                                '& .css-3wduhr-Mu.iDateRangeCalendar-root': {
                                  flexDirection: 'column !important',
                                },


                                '& .css-grqin-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day': {
                                  color: 'inherit',
                                  fontSize: '16px !important',
                                  opacity: 'inherit',
                                  fontWeight: 500
                                },
                                '& .css-1kex3oi-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day:not(.Mui-selected)': {
                                  backgroundColor: 'inherit',
                                  color: "black !important",
                                  borderRadius: '50%',
                                  border: "1px solid gray",
                                  fontSize: '16px',
                                  fontWeight: 500
                                },
                                '& .MuiTypography-subtitle1': {
                                  // backgroundColor:'#310085 !important',
                                  color: " #03071E !important",
                                  // borderRadius:'0px',
                                  fontSize: '16px',
                                  fontWeight: 700
                                },
                                '& .MuiDayCalendar-weekDayLabel': {
                                  color: " #03071ECC !important",
                                  fontSize: '16px',
                                  fontWeight: 400
                                },
                                '& .MuiSvgIcon-fontSizeInherit': {
                                  backgroundColor: 'black',
                                  color: 'white',
                                  borderRadius: '50%'
                                },
                                '& .MuiPickersDay-today': {
                                  backgroundColor: '#711FFF',
                                  color: 'white',
                                  borderRadius: '0px',
                                  fontSize: '16px',
                                  fontWeight: 500

                                },
                                '& .css-grqin-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day.Mui-selected': {
                                  backgroundColor: '#711FFF',
                                  color: 'white',
                                  borderRadius: '0px',
                                  fontSize: '16px',
                                  fontWeight: 500
                                },
                                '& .css-gtjfra-MuiDateRangePickerDay-root': {
                                  backgroundColor: '#310085',
                                  color: 'white !important',
                                  borderRadius: '0px',
                                  fontSize: '16px',
                                  fontWeight: 500
                                },
                                '& .css-1i2r8k1-MuiDateRangePickerDay-root': {
                                  backgroundColor: '#310085',
                                  color: 'white !important',
                                  borderRadius: '0px',
                                  fontSize: '16px',
                                  fontWeight: 500
                                },
                                '& .MuiDateRangePickerDay-notSelectedDate': {
                                },
                              }}
                            />
                          </LocalizationProvider>

                          <Box sx={{ display: 'flex', my: 4 }}>
                            <Box sx={{ display: 'flex', mr: 4 }}>
                              <Box sx={{ width: "20px", height: "20px", backgroundColor: "#711FFF", borderRadius: 2, mr: 2 }}></Box>
                              <Typography sx={{ fontWeight: 400, fontSize: '14px' }}>Start Date and End Date</Typography>
                            </Box>
                            <Box sx={{ display: 'flex' }}>
                              <Box sx={{ width: "20px", height: "20px", backgroundColor: "#310085", borderRadius: 2, mr: 2 }}></Box>
                              <Typography sx={{ fontWeight: 400, fontSize: '14px' }}>Date Range</Typography>
                            </Box>
                          </Box>

                        </Box>
                        <Box align='' my={3} sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "" }} >
                          <Box align='left' mt={4}>
                            <Box align='left'>
                              <Typography sx={{ color: '#03071E99', fontSize: '16px', fontWeight: 400 }}>Start Date</Typography>
                              <Typography sx={{ color: '#03071E', fontSize: '16px', fontWeight: 400 }}>{dateRange[0] && dateRange[0].format().slice(0, 10)}</Typography>
                            </Box>
                            <Box sx={{ mt: 2 }}>
                              <Typography sx={{ color: '#03071E99', fontSize: '16px', fontWeight: 400 }}>End Date</Typography>
                              <Typography sx={{ color: '#03071E', fontSize: '16px', fontWeight: 400 }}>{dateRange[1] && dateRange[1].format().slice(0, 10)}</Typography>
                            </Box>
                          </Box>


                          <Box align='right'>
                            <Button variant='text' sx={{ textDecoration: 'inherit', color: 'black', width: { xs: 'auto', lg: '100px' }, mr: 1 }} onClick={handleClose}>Cancel</Button>
                            <Button variant='contained' sx={{
                              textDecoration: 'inherit', backgroundColor: "#711FFF", width: { xs: 'auto', lg: '100px' }, mr: 1,
                              '&:hover': {
                                backgroundColor: "#711FFF"
                              }
                            }}>Save</Button>
                          </Box>

                        </Box>

                      </Box>
                    </>
                  }
                  {
                    index === 1 &&
                    <Box sx={{ pb: 2, width: '150px', textAlign: 'left' }}>
                      {
                        ['Billable', 'Non Billable', 'Both'].map((v, i) => {
                          return (
                            <Typography key={i} fullWidth onClick={() => handleClickBillable(v, i)} sx={billable === i ?
                              { cursor: "pointer", color: "white", backgroundColor: '#711FFF', borderRadius: 1, textTransform: 'inherit', fontSize: '14px', m: 2, px: 1, '&:hover': { backgroundColor: '#E0E0DF' } }
                              :
                              { cursor: "pointer", color: "black", borderRadius: 1, textTransform: 'inherit', fontSize: '14px', m: 2, px: 1, '&:hover': { backgroundColor: '#E0E0DF' } }}>{v}</Typography>
                          )
                        })
                      }
                    </Box>
                  }
                  {
                    index === 2 &&
                    <Box sx={{ pb: 2, width: '150px', textAlign: 'left' }}>
                      {
                        ['PDF'].map((v, i) => {
                          return (
                            <Typography key={i} fullWidth onClick={() => handleClickExportAs(v, i)} sx={exportAs === i ?
                              { color: "white", backgroundColor: '#711FFF', borderRadius: 1, textTransform: 'inherit', fontSize: '14px', m: 2, px: 1, '&:hover': { backgroundColor: '#E0E0DF' } }
                              :
                              { color: "black", borderRadius: 1, textTransform: 'inherit', fontSize: '14px', m: 2, px: 1, '&:hover': { backgroundColor: '#E0E0DF' } }}>{v}</Typography>
                          )
                        })
                      }
                    </Box>
                  }
                </Popover>
              </>
            ))}
          </div>

        </Box>

        {/* filter Starting From Here .............................................................................................................................................*/}

        <Box sx={{ mx: 3, mb: 1, display: 'flex', flexWrap: 'wrap' }}>

          {/* this is client Filter */}
          <Box sx={{
            width: '230px', mt: 2, mx: 1, borderBottom: clientValue?.length > 0 ? '2px solid #711FFF' : '2px solid lightgray', position: 'relative',
            '&:hover': { borderBottom: '2px solid #711FFF' },
          }}
            ref={clientRef}
          >
            <Typography sx={{ color: clientValue?.length > 0 ? '#711FFF' : '#00000099', fontWeight: '400' }}>Filter By Client</Typography>

            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Box className='customScrollBar' sx={{ display: 'flex', overflow: 'auto', width: '180px', height: '35px' }}
              >
                {
                  clientValue?.length > 0 ?
                    clientValue.map((v, i) => {
                      return (
                        <Box sx={{ backgroundColor: '#D2B8FF', borderRadius: '20px', px: 0.5, mb: 0.5, display: 'flex', alignItems: 'center', mr: 0.5, zIndex: 999 }}>
                          {false ?
                            <img src='https://www.google.com/url?sa=i&url=https%3A%2F%2Fstock.adobe.com%2Fsearch%2Fimages%3Fk%3Dadmin%2Bicon&psig=AOvVaw0nbHsIp7Zj0mSMlKD_DR2M&ust=1701256844633000&source=images&cd=vfe&ved=0CBIQjRxqFwoTCNiX0eLJ5oIDFQAAAAAdAAAAABAE' alt='im' style={{ marginRight: '5px' }} />
                            :
                            <HiMiniUserCircle style={{ fontSize: '24px' }} />
                          }
                          <Typography sx={{ width: '60px', color: 'black', overflow: 'hidden', whiteSpace: 'nowrap' }}>{v.name}</Typography>
                          <IoIosCloseCircle onClick={() => { setClientValue(clientValue?.filter((flt) => flt.name !== v.name)) }}
                            style={{ marginLeft: '5px', color: '#711FFF', cursor: 'pointer', fontSize: '15px', zIndex: 999 }} />
                        </Box>
                      )
                    })
                    :
                    <Typography sx={{ fontSize: '16px', fontWeight: '400', width: '100%', color: '#000000DE', mt: 1 }} onClick={handleClientPopopToggle}>Select</Typography>
                }
              </Box>

              <Box>
                {
                  clientValue?.length > 0 &&
                  <IoCloseSharp style={{ marginRight: '10px', cursor: 'pointer', color: '#0000008F' }} onClick={() => setClientValue([])} />
                }
                {
                  clientValue?.length > 0 ?
                    <IoMdArrowDropup style={{ cursor: 'pointer', fontSize: '24px', color: '#0000008F' }} onClick={handleClientPopopToggle} />
                    :
                    <IoMdArrowDropdown style={{ cursor: 'pointer', fontSize: '24px', color: '#0000008F' }} onClick={handleClientPopopToggle} />
                }
              </Box>
            </Box>

            <Box className='customScrollBar' sx={
              !filterClientPopop ?
                { display: 'none' }
                :
                {
                  position: 'absolute', p: 2, backgroundColor: 'white', zIndex: 10, mt: 0.5, width: '350px', height: '310px', overflow: 'auto',
                  boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px'
                }}>
              {
                clients?.length > 0 && clients?.map((v, i) => {

                  let flt = clientValue?.find((val) => val.name === v.name)

                  return (
                    <Box key={i} onClick={() => handleClientListSelect(v, i)} sx={{
                      display: 'flex', width: '98%', height: '65px', alignItems: 'center', borderRadius: 2, px: 3, py: 1, my: 1.5, cursor: 'pointer',
                      boxShadow: '2px 2px 6px 0px #D9D9D9', backgroundColor: flt ? '#9152FF' : 'inherit', color: 'white'
                    }}>
                      <HiMiniUserCircle style={{ fontSize: '34px', marginRight: '10px', width: '42px', height: '42px', color: 'lightgray' }} />
                      <Box sx={{ textTransform: 'uppercase' }}>
                        <Typography sx={{ color: flt ? 'white' : '#023FE3', fontSize: '12px', fontWeight: 600 }} >{v.name}</Typography>
                        <Typography sx={{ color: flt ? 'white' : '#8C8E8E', fontSize: '12px' }}>{v.industry}</Typography>
                      </Box>
                    </Box>
                  )
                })
              }
            </Box>
          </Box>

          {/* this is Project Filter */}
          <Box sx={{
            width: '230px', mt: 2, mx: 1, borderBottom: myArr.length > 0 ? '2px solid #711FFF' : '2px solid lightgray', position: 'relative',
            '&:hover': { borderBottom: '2px solid #711FFF' },
          }}
            ref={projectRef}
          >

            <Typography sx={{ color: myArr.length > 0 ? '#711FFF' : '#00000099', fontWeight: '400' }}>Filter By Job</Typography>

            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Box className='customScrollBar' sx={{ display: 'flex', overflow: 'auto', width: '180px', height: '35px' }}>
                {
                  filteredProject.length > 0 ?
                    filteredProject.map((v, i) => {
                      return (
                        <Box sx={{ backgroundColor: '#2196F3', borderRadius: '20px', px: 0.5, mb: 0.5, display: 'flex', alignItems: 'center', mr: 0.5 }}>

                          <Typography sx={{ marginLeft: '5px', color: 'white', width: '60px', overflow: 'hidden', whiteSpace: 'nowrap' }}>{v?.title}</Typography>
                          <IoIosCloseCircle onClick={() => setFilteredProject([])}
                            style={{ marginLeft: '5px', color: '#86c4f7', cursor: 'pointer', fontSize: '15px' }} />
                        </Box>
                      )
                    })
                    :
                    <Typography sx={{ fontSize: '16px', fontWeight: '400', width: '100%', color: '#000000DE', mt: 1 }} onClick={handleProjectPopopToggle}>Select</Typography>
                }
              </Box>
              <Box>
                {
                  projectValue.length > 0 &&
                  <IoCloseSharp style={{ marginRight: '10px', cursor: 'pointer', color: '#0000008F' }} onClick={() => {setProjectValue([])}} />
                }
                {
                  projectValue.length > 0 ?
                    <IoMdArrowDropup style={{ cursor: 'pointer', fontSize: '24px', color: '#0000008F' }} onClick={handleProjectPopopToggle} />
                    :
                    <IoMdArrowDropdown style={{ cursor: 'pointer', fontSize: '24px', color: '#0000008F' }} onClick={handleProjectPopopToggle} />
                }                    </Box>
            </Box>

            <Box className='customScrollBar' sx={
              !filterProjectPopop ?
                { display: 'none' }
                :
                {
                  position: 'absolute', p: 2, borderRadius: '6px', backgroundColor: 'white', zIndex: 10, mt: 0.5, width: '250px', height: '400px', overflow: 'auto',
                  boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px',
                  border: '1px solid var(--black-black-20, rgba(3, 7, 30, 0.20))'
                }}>
              <Typography align='center' sx={{ backgroundColor: '#F8F9FA', borderRadius: '6px', fontSize: '14px', py: 1.5 }}> <span style={{ color: '#5600EB', fontSize: '14px' }}>Project</span> | Inactive</Typography>
              {
                AllProjects?.map((v, i) => {

                  const company = clients.find(item => item.id === v.company)

                  let flt = projectValue.find((val) => val.name === v.name)

                  return (
                    <Box key={i} onClick={() => handleProjectListSelect(v, i)} sx={{
                      display: 'flex', width: '100%', height: '65px', alignItems: 'center', borderRadius: 2, py: 1, my: 1.5, mt: 1, cursor: 'pointer',
                    }}>
                      <Box sx={{ textTransform: 'uppercase' }}>
                        <Typography sx={{ color: '#03071E', fontSize: '14px', fontWeight: 600 }} >{v?.title}</Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center', ml: 1, }}>
                          {
                            v?.project_type?.length > 0 &&
                            <svg style={{ marginRight: '10px' }} width="9" height="7" viewBox="0 0 9 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path id="Vector 4" d="M1 0V6H9" stroke="black" />
                            </svg>
                          }
                          <Typography sx={{ color: flt ? '#5600EB' : '#0B88F9', fontSize: '12px', '&:hover': { color: '#5600EB' } }}>{company?.name}</Typography>
                        </Box>
                      </Box>
                    </Box>
                  )
                })
              }
            </Box>
          </Box>

          {/* this is deliverable/task Filter */}
          <Box sx={{
            width: '230px', mt: 2, mx: 1, borderBottom: filteredProject.length > 0 ? '2px solid #711FFF' : '2px solid lightgray', position: 'relative',
            '&:hover': { borderBottom: '2px solid #711FFF' },
          }}
            ref={deliverableRef}
          >
            <Typography sx={{ color: filteredProject.length > 0 ? '#711FFF' : '#00000099', fontWeight: '400' }}>Filter By Deliverable/Task</Typography>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Box className='customScrollBar' sx={{ display: 'flex', overflow: 'auto', width: '180px', height: '35px' }}>
                {
                  selectedDiliverables?.length > 0 ?
                    selectedDiliverables?.map((v, i) => {
                      return (
                        <Box sx={{ backgroundColor: '#00000014', borderRadius: '20px', px: 0.5, mb: 0.5, display: 'flex', alignItems: 'center', mr: 0.5 }}>

                          <Typography sx={{ marginLeft: '5px', width: '60px', color: 'black', overflow: 'hidden', whiteSpace: 'nowrap' }}>{v?.deliverable_name}</Typography>
                          <IoIosCloseCircle onClick={() => setSelectedDiliverables([])}
                            style={{ marginLeft: '5px', color: '#c2c3c4', cursor: 'pointer', fontSize: '15px' }} />
                        </Box>
                      )
                    })
                    :
                    <Typography sx={{ fontSize: '16px', fontWeight: '400', width: '100%', color: '#000000DE', mt: 1 }} onClick={handleDeliverablePopopToggle}>Select</Typography>
                }
              </Box>

              <Box>
                {
                  deliverableValue.length > 0 &&
                  <IoCloseSharp style={{ marginRight: '10px', cursor: 'pointer', color: '#0000008F' }} onClick={() => setDeliverableValue([])} />
                }
                {
                  deliverableValue.length > 0 ?
                    <IoMdArrowDropdown style={{ cursor: 'pointer', fontSize: '24px', color: '#0000008F' }} onClick={handleDeliverablePopopToggle} />
                    :
                    <IoMdArrowDropup style={{ cursor: 'pointer', fontSize: '24px', color: '#0000008F' }} onClick={handleDeliverablePopopToggle} />
                }                    </Box>
            </Box>

            <Box className='customScrollBar' sx={
              !filterDeliverablePopop ?
                { display: 'none' }
                :
                {
                  position: 'absolute', py: 2, backgroundColor: 'white', zIndex: 10, mt: 0.5, borderRadius: '6px', width: 'auto', height: '400px', overflow: 'auto',
                  boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px',
                  border: '1px solid var(--black-black-20, rgba(3, 7, 30, 0.20))', left: { xs: '-60px', md: '0px' }
                }}>
              <Typography sx={{ textTransform: 'uppercase', fontSize: '10px', color: '#A3A9AB', mx: 1 }}>Deliverable</Typography>
              {

                filteredProject[0]?.deliverable_project?.length > 0 ? (
                  filteredProject[0]?.deliverable_project?.map((v, i) => {
                    return (
                      <Box key={i}>
                        <Box sx={{
                          display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 2, cursor: 'pointer', maxWidth: '250px',
                          '&:hover': { backgroundColor: '#D2B8FF' }
                        }}>
                          <Typography onClick={() => handleAccordianOpen(v, i)} sx={{ fontWeight: "bold" }}>{v?.deliverable_name}</Typography>
                          <FaAngleDown style={{ marginLeft: '20px' }} />
                        </Box>
                        <Box sx={Deliverableaccordian === v?.deliverable_name ?
                          { display: 'block' }
                          :
                          { display: 'none' }
                        }>
                          {v?.task_deliverable?.length > 0 ? (
                            v.task_deliverable?.filter(item => item.billable === billable)
                              .map((item, index) => {

                                return (
                                  <>
                                    <Box key={index} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', border: '1px solid lightgray', backgroundColor: '#F8F9FA', px: 3, py: 1, width: '300px' }}>
                                      <Typography sx={{ fontWeight: 'bold' }}>{item?.task_name}</Typography>
                                      <FaStar onClick={() => handleSelectTask(item, index)} style={{
                                        cursor: 'pointer', color: taskOfDiliverables?.find((task) => task?.index === index)
                                          ? '#FFD700'
                                          : '#E0E0DF',
                                        fontSize: '16px'
                                      }} />
                                    </Box>
                                  </>
                                )
                              })
                          ) : ("")
                          }
                        </Box>
                      </Box>
                    )
                  })
                ) : ("")
              }
            </Box>
          </Box>

          {/* this is WorkType Filter */}
          <Box sx={{
            width: '230px', mt: 2, mx: 1, borderBottom: worktypeValue.length > 0 ? '2px solid #711FFF' : '2px solid lightgray', position: 'relative',
            '&:hover': { borderBottom: '2px solid #711FFF' },
          }}
            ref={worktypeRef}
          >
            <Typography sx={{ color: worktypeValue.length > 0 ? '#711FFF' : '#00000099', fontWeight: '400' }}>Filter By Worktype</Typography>

            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Box className='customScrollBar' sx={{ display: 'flex', overflow: 'auto', width: '180px', height: '35px' }}>
                {
                  selectedWorktypes.length > 0 ?
                    selectedWorktypes?.map((v, i) => {
                      return (
                        <Box key={i} sx={{ backgroundColor: '#00000014', borderRadius: '20px', px: 0.5, mb: 0.5, display: 'flex', alignItems: 'center', mr: 0.5 }}>
                          <Typography sx={{ marginLeft: '5px', width: '60px', color: 'black', overflow: 'hidden', whiteSpace: 'nowrap' }}>{v.name}</Typography>
                          <IoIosCloseCircle
                            onClick={() => {
                              // Remove the clicked worktype from selectedWorktypes
                              const updatedWorktypes = selectedWorktypes.filter((flt) => flt !== v);
                              setselectedWorktypes(updatedWorktypes);
                            }}
                            style={{ marginLeft: '5px', color: '#c2c3c4', cursor: 'pointer', fontSize: '15px' }} />
                        </Box>
                      )
                    })
                    :
                    <Typography sx={{ fontSize: '16px', fontWeight: '400', width: '100%', color: '#000000DE', mt: 1 }} onClick={handleWorktypePopopToggle}>Select</Typography>
                }
              </Box>

              <Box>
                {
                  worktypeValue.length > 0 &&
                  <IoCloseSharp style={{ marginRight: '10px', cursor: 'pointer', color: '#0000008F' }} onClick={() => {
                    setWorktypeValue([])
                    setResultValueWorktype([])
                  }} />
                }
                {
                  worktypeValue.length > 0 ?
                    <IoMdArrowDropup style={{ cursor: 'pointer', fontSize: '24px', color: '#0000008F' }} onClick={handleWorktypePopopToggle} />
                    :
                    <IoMdArrowDropdown style={{ cursor: 'pointer', fontSize: '24px', color: '#0000008F' }} onClick={handleWorktypePopopToggle} />
                }                    </Box>
            </Box>

            <Box className='customScrollBar' sx={
              !filterWorktypePopop ?
                { display: 'none' }
                :
                {
                  position: 'absolute', py: 2, backgroundColor: 'white', zIndex: 10, mt: 0.5, borderRadius: '6px', width: '250px', height: '400px', overflow: 'auto',
                  boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px',
                  border: '1px solid var(--black-black-20, rgba(3, 7, 30, 0.20))'
                }}>
              {
                MergedWorkTypes && MergedWorkTypes?.map((item, j) => {
                  return (
                    <Box key={j} onClick={() => handleWorktypeListSelect(item, j)} sx={{
                      display: 'flex', width: '100%', height: '22px', alignItems: 'center', borderRadius: 2, px: 3, py: 3, my: 1.5, cursor: 'pointer',
                      backgroundColor: item ? '#9152FF' : 'inherit', color: 'white'
                    }}>
                      <Box sx={{ textTransform: 'uppercase' }}>
                        <Typography sx={{ color: item ? 'white' : 'black', fontSize: '14px', fontWeight: 400 }} >{item?.name}</Typography>
                      </Box>
                    </Box>
                  )
                })
              }
            </Box>

          </Box>

          {/* this is People Filter */}
          <Box sx={{
            width: '230px', mt: 2, mx: 1, borderBottom: selectedPeople?.length > 0 ? '2px solid #711FFF' : '2px solid lightgray', position: 'relative',
            '&:hover': { borderBottom: '2px solid #711FFF' },
          }}
            ref={peopleRef}
          >
            <Typography sx={{ color: selectedPeople?.length > 0 ? '#711FFF' : '#00000099', fontWeight: '400' }}>Filter By People</Typography>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Box className='customScrollBar' sx={{ display: 'flex', overflow: 'auto', width: '180px', height: '35px' }}>
                {
                  selectedPeople?.length > 0 ?
                    selectedPeople?.map((v, i) => {
                      return (
                        <Box sx={{ backgroundColor: '#00000014', borderRadius: '20px', px: 0.5, mb: 0.5, display: 'flex', alignItems: 'center', mr: 0.5 }}>
                          {false ?
                            <img src='https://www.google.com/url?sa=i&url=https%3A%2F%2Fstock.adobe.com%2Fsearch%2Fimages%3Fk%3Dadmin%2Bicon&psig=AOvVaw0nbHsIp7Zj0mSMlKD_DR2M&ust=1701256844633000&source=images&cd=vfe&ved=0CBIQjRxqFwoTCNiX0eLJ5oIDFQAAAAAdAAAAABAE' alt='im' style={{ marginRight: '5px' }} />
                            :
                            <HiMiniUserCircle style={{ fontSize: '24px' }} />
                          }
                          <Typography sx={{ width: '60px', color: 'black', overflow: 'hidden', whiteSpace: 'nowrap' }}>{v.first_name}</Typography>
                          <IoIosCloseCircle onClick={() => setSelectedPeople(selectedPeople?.filter((flt) => flt.name !== v.name))}
                            style={{ marginLeft: '5px', color: '#c2c3c4', cursor: 'pointer', fontSize: '15px' }} />
                        </Box>
                      )
                    })
                    :
                    <Typography sx={{ fontSize: '16px', fontWeight: '400', width: "100%", color: '#000000DE', mt: 1 }} onClick={handlePeoplePopopToggle}>Select</Typography>
                }
              </Box>

              <Box>
                {
                  selectedPeople?.length > 0 &&
                  <IoCloseSharp style={{ marginRight: '10px', cursor: 'pointer', color: '#0000008F' }} onClick={() => setSelectedPeople([])} />
                }
                {
                  selectedPeople?.length > 0 ?
                    <IoMdArrowDropup style={{ cursor: 'pointer', fontSize: '24px', color: '#0000008F' }} onClick={handlePeoplePopopToggle} />
                    :
                    <IoMdArrowDropdown style={{ cursor: 'pointer', fontSize: '24px', color: '#0000008F' }} onClick={handlePeoplePopopToggle} />
                }                    </Box>
            </Box>

            <Box className='customScrollBar' sx={
              !filterPeoplePopop ?
                { display: 'none' }
                :
                {
                  position: 'absolute', p: 2, backgroundColor: 'white', zIndex: 10, mt: 0.5, width: '350px', height: '310px', overflow: 'auto',
                  boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px'
                }}>
              {
                filteredProject[0]?.project_subteam.filter((item) => item.first_name !== "First Name")?.map((v, i) => {
                  return (
                    v?.members?.map((item, index) => {

                      let flt = selectedPeople?.find((val) => val?.name === v?.name)
                      return (
                        <Box key={i} onClick={() => handlePeopleListSelect(item, index)} sx={{
                          display: 'flex', width: '98%', height: '65px', alignItems: 'center', borderRadius: 2, px: 3, py: 1, my: 1.5, cursor: 'pointer',
                          boxShadow: '2px 2px 6px 0px #D9D9D9', backgroundColor: flt ? '#9152FF' : 'inherit', color: 'white'
                        }}>
                          <HiMiniUserCircle style={{ fontSize: '34px', marginRight: '10px', width: '42px', height: '42px', color: 'lightgray' }} />
                          <Box sx={{ textTransform: 'uppercase' }}>
                            <Typography sx={{ color: flt ? 'white' : 'black', fontSize: '12px', fontWeight: 600 }} >{item?.first_name}</Typography>
                            <Typography sx={{ color: flt ? 'white' : '#711FFF', fontSize: '12px' }}>{item?.work_category}</Typography>
                          </Box>
                        </Box>
                      )
                    })
                  )

                })
              }
            </Box>
          </Box>
        </Box>
      </Grid>

      { filteredProject.length < 1 ? (
        <Box
          sx={{
            p: '0.14rem 1rem',
            borderRadius: '8px',
            display: 'flex',
            flexDirection: "column",
            alignItems: 'center',
            justifyContent: 'center',
            marginLeft: 'auto',
            marginRight: '2rem',
            height: '36rem',
          }}
        >
          <Typography color={"#5600EB"} fontSize={"18px"} fontWeight={700} align="center" lineHeight={"27px"} >
            Select a Job First to see the results
          </Typography>
          <Typography color={"#03071E"} fontSize={"14px"} fontWeight={400} align="center" lineHeight={"22px"} >
            All the information on Estimate vs Actual Job Reporting would be shown here.
          </Typography>
        </Box>
      ) : (

        <div id="div-to-print">

          <Box
            className="main-container"
            style={{
              background: '#fff',
              borderRadius: '10px',
              padding: '21px',
            }}
          >
            <Box className="kpi-middle-container">
              <div className="kpi-middle">
                <p style={{ color: "black", fontSize: "18px", fontWeight: 600, }} className="middle-title kpi-text">
                  ESTIMATED VS ACTUAL DATA
                </p>
                <Estimatelegened />
                <div className="kpi-barchart">
                  <DeliverableAssestMents data={filteredProject} isUnit={true} isLegend={true} calculateHours={true}/>
                </div>
              </div>
            </Box>
            <Box width={"100%"} padding={"0px 15px"}  >
              <Typography color={"black"} fontSize={"16px"} fontWeight={500} >
                INDIVIDUAL DELIVERABLES REPORTS
              </Typography>
              <Box display={"flex"} flexDirection={"column"} pt={2} width={"100%"} >
                <Box display="flex" flexDirection="row" justifyContent={"center"} alignItems={"center"} paddingTop={3} >
                  <Typography fontSize={"16px"} width="100%" color={"#310085"} fontWeight={800} sx={{
                    '@media screen and (max-width: 1776px)': {
                      fontSize: '14px',
                    }
                  }} > DELIVERABLES BREAK DOWN </Typography>
                  <span style={{ fontWeight: 100, border: "1px solid #D3D3D3", width: "490rem", height: 0, justifyContent: "center", textAlign: "center", alignItems: "center", marginTop: 10 }} ></span>
                  {deliverableBreakdown === false ? <KeyboardArrowDownIcon sx={{ fontSize: "44px", fontWeight: "222px", }} onClick={() => setDeliverbleBreakDown(true)} style={{ cursor: "pointer", marginLeft: "2rem", display: "flex", justifyContent: "center", alignItems: "center" }} /> : <KeyboardArrowUp sx={{ fontSize: "44px" }} onClick={() => setDeliverbleBreakDown(false)} style={{ cursor: "pointer", marginLeft: "2rem", display: "flex", justifyContent: "center", alignItems: "center" }} />}
                </Box>
                {deliverableBreakdown === true ? (
                  <>
                    <Box className={"kpi-middle-container"} >
                      <Estimatelegened />
                      <div className="kpi-barchart" style={{ marginTop: "21px" }} >
                      <p style={{ marginLeft: "21px", fontSize: "14px", fontWeight: 1000, letterSpacing: "1px", marginTop: "6px" }} >DELIVERABLES ASSESSMENT</p>
                        <DeliverableAssestMents data={filteredProject} isUnit={true} isLegend={true} />
                      </div>
                    </Box>

                  </>
                ) : ("")}
                <Box display="flex" flexDirection="row" justifyContent={"center"} alignItems={"center"} paddingTop={3} >
                  <Typography fontSize={"16px"} width="100%" color={"#310085"} fontWeight={800} sx={{
                    '@media screen and (max-width: 1776px)': {
                      fontSize: '14px',

                    }
                  }} > WORK TYPE BY DELIVERABLES BREAK DOWN </Typography>
                  <span style={{ border: "1px solid #D3D3D3", width: "290rem", height: 0, justifyContent: "center", textAlign: "center", alignItems: "center", marginTop: 10 }} ></span>
                  {workTypeDeliverable === false ? <KeyboardArrowDownIcon sx={{ fontSize: "44px" }} onClick={() => setWorktypeDeliverable(true)} style={{ cursor: "pointer", marginLeft: "2rem", display: "flex", justifyContent: "center", alignItems: "center" }} /> : <KeyboardArrowUp sx={{ fontSize: "44px" }} onClick={() => setWorktypeDeliverable(false)} style={{ cursor: "pointer", marginLeft: "2rem", display: "flex", justifyContent: "center", alignItems: "center" }} />}
                </Box>
                {workTypeDeliverable === true ? (
                  <>
                    <Box className={"kpi-middle-container"} >
                      {filteredProject[0]?.updatedDiliverables?.length > 0 ? (
                        <>
                          {filteredProject[0].updatedDiliverables.map((item, ind) => {
                            return (
                              <>
                                {/* <Estimatelegened /> */}
                                <div className="kpi-barchart" style={{ marginTop: "21px" }} >
                                  <p style={{ marginLeft: "21px", fontSize: "14px", fontWeight: 1000, letterSpacing: "1px" }} >{item?.deliverable_name}</p>
                                  <WorkTypeBarCharts filteredProject={filteredProject[0]?.deliverable_project[ind]} data={item?.updatedWorktypes?.length ? item?.updatedWorktypes : item?.deliverable_worktype} isUnit={true} isLegend={true} />
                                </div>
                              </>
                            )
                          })}
                        </>
                      ) : (
                        <>
                            {/* <Estimatelegened /> */}
                          {filteredProject[0].deliverable_project && filteredProject[0].deliverable_project?.map((item, ind) => {
                            return (
                              <>
                                <div className="kpi-barchart" style={{ marginTop: "21px" }} >
                                  <p style={{ marginLeft: "21px", fontSize: "14px", fontWeight: 1000, letterSpacing: "1px" }} >{item?.deliverable_name}</p>
                                  <WorkTypeBarCharts filteredProject={filteredProject[0]?.deliverable_project[ind]} data={item?.deliverable_worktype} isUnit={true} isLegend={true} />

                                </div>
                              </>
                            )
                          })}
                        </>
                      )}
                    </Box>
                  </>
                ) : ("")}
                <Box display="flex" flexDirection="row" justifyContent={"center"} alignItems={"center"} paddingTop={3}>
                  <Typography fontSize={"16px"} width="100%" color={"#310085"} fontWeight={800} sx={{
                    '@media screen and (max-width: 1776px)': {
                      fontSize: '14px',
                    }
                  }} > TEAM MEMBERS BREAK DOWN</Typography>
                  <span style={{ border: "1px solid #D3D3D3", width: "490rem", height: 0, justifyContent: "center", textAlign: "center", alignItems: "center", marginTop: 10 }} ></span>
                  {teamMembers === false ? <KeyboardArrowDownIcon sx={{ fontSize: "44px" }} onClick={() => setTeamMembers(true)} style={{ cursor: "pointer", marginLeft: "2rem", display: "flex", justifyContent: "center", alignItems: "center" }} /> : <KeyboardArrowUpIcon sx={{ fontSize: "44px" }} onClick={() => setTeamMembers(false)} style={{ cursor: "pointer", marginLeft: "2rem", display: "flex", justifyContent: "center", alignItems: "center" }} />}
                </Box>
                {teamMembers === true ? (
                  <>
                    <Box className={"kpi-middle-container"} >

                      {filteredProject[0]?.selectedTeamMembers?.length > 0 ? (
                        <>
                          {/* <Estimatelegened /> */}
                          <div className="kpi-barchart" style={{ marginTop: "21px" }} >
                            <p style={{ marginLeft: "21px", marginTop: "10px", fontSize: "14px", fontWeight: 1000, letterSpacing: "1px" }} >TEAM ASSESTMENTSS </p>
                            <BarCharts data={filteredProject[0].deliverable_project} updatedTeam={filteredProject[0].selectedTeamMembers} myTeam={filteredProject[0].selectedTeamMembers} tasks={filteredProject[0].updatedDiliverables} isUnit={true} isLegend={true} />
                          </div>
                        </>
                      ) : (
                        <>
                          {/* <Estimatelegened /> */}
                          <div className="kpi-barchart" style={{ marginTop: "21px" }} >
                            <p style={{ marginLeft: "21px", marginTop: "10px", fontSize: "14px", fontWeight: 1000, letterSpacing: "1px" }} >TEAM ASSESTMENTSS </p>
                            <BarCharts myTeam={myTeam} data={filteredProject[0].deliverable_project} tasks={filteredProject[0].updatedDiliverables} isUnit={true} isLegend={true} />
                          </div>
                        </>
                      )}


                    </Box>
                  </>
                ) : ("")}
                <Box display="flex" flexDirection="row" justifyContent={"center"} alignItems={"center"} paddingTop={3} >
                  <Typography fontSize={"16px"} width="100%" color={"#310085"} fontWeight={800} sx={{
                    '@media screen and (max-width: 1776px)': {
                      fontSize: '14px',
                    }
                  }} > TASK BREAK DOWN</Typography>
                  <span style={{ border: "1px solid #D3D3D3", width: "445rem", height: 0, justifyContent: "center", textAlign: "center", alignItems: "center", marginTop: 10 }} ></span>
                  {taskBreakDown === false ? <KeyboardArrowDownIcon sx={{ fontSize: "44px" }} onClick={() => setTaskBreakDown(true)} style={{ cursor: "pointer", marginLeft: "2rem", display: "flex", justifyContent: "center", alignItems: "center" }} /> : <KeyboardArrowUpIcon sx={{ fontSize: "44px" }} onClick={() => setTaskBreakDown(false)} style={{ cursor: "pointer", marginLeft: "2rem", display: "flex", justifyContent: "center", alignItems: "center" }} size={128} />}
                </Box>
                {taskBreakDown === true ? (
                  <>
                    <Box className={"kpi-middle-container"} >

                      {filteredProject[0]?.updatedDiliverables?.length > 0 ? (
                        <>
                          <Estimatelegened />
                          <div className="kpi-barchart" style={{ marginTop: "21px" }} >
                            <p style={{ marginLeft: "21px", marginTop: "10px", fontSize: "14px", fontWeight: 1000, letterSpacing: "1px" }} >DELIVERABLES ASSESTMENTSS </p>
                            <TaskCharts data={filteredProject[0].deliverable_project} filteredProject={filteredProject} task={filteredProject[0].updatedDiliverables} isUnit={true} isLegend={true} />
                          </div>
                        </>
                      ) : (
                        <>
                          <Estimatelegened />
                          <div className="kpi-barchart" style={{ marginTop: "21px" }} >
                            <p style={{ marginLeft: "21px", marginTop: "10px", fontSize: "14px", fontWeight: 1000, letterSpacing: "1px" }} >DELIVERABLES ASSESTMENTSS </p>
                            <TaskCharts data={filteredProject[0].deliverable_project} filteredProject={filteredProject} isUnit={true} isLegend={true} />
                          </div>
                        </>
                      )}

                    </Box>
                  </>
                ) : ("")}
              </Box>
            </Box>
          </Box>
        </div>
      )}
    </>
  );
};

export default Estimate