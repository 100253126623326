import { createAsyncThunk } from '@reduxjs/toolkit';
import { ENDPOINT } from '../Opportunity/Redux/oppor';
const GET_PERSONS = 'netnet/Redux/GET_PERSONS';
const GET_COMPANIES = 'netnet/Redux/GET_COMPANIES';
const IMPORTED_CONTACT = 'netnet/Redux/IMPORTED_CONTACT';
const CREATE_COMPANIES = 'netnet/Redux/CREATE_COMPANIES';
const CREATE_PEOPLE = 'netnet/Redux/CREATE_PEOPLE';
const GET_EMAILS = 'netnet/Redux/GET_EMAILS';
const IMPORT = 'netnet/Redux/IMPORT';
const COMPLETE = 'netnet/Redux/COMPLETE';
const PROGRESS = 'netnet/Redux/Progress';
const CLEAR_RECORD = 'netnet/Redux/CLEAR_RECORD';


export const reqImport = (payload) => {
  return {
    type: IMPORT,
    payload,
  };
};

export const clearRecord = (payload) => {
  return {
    type: CLEAR_RECORD,
    payload,
  };
};

export const resImport = (payload) => {
  return {
    type: PROGRESS,
    payload,
  };
};

export const reqComplete = (payload) => {
  return {
    type: COMPLETE,
    payload,
  };
};

export const getPersons = createAsyncThunk(
  GET_PERSONS,
  async (meta, thunkAPI) => {
    const response = await fetch(`${ENDPOINT}/api/contact/list/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `JWT ${meta.access_token}`,
      },
    });
    const data = await response.json();
    if (!response.ok) {
      return thunkAPI.rejectWithValue(data);
    }
    return data;
  }
);

export const getCompanies = createAsyncThunk(
  GET_COMPANIES,
  async (meta, thunkAPI) => {
    const response = await fetch(`${ENDPOINT}/api/contact/company/list/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `JWT ${meta.access_token}`,
      },
    });
    const data = await response.json();
    if (!response.ok) {
      return thunkAPI.rejectWithValue(data);
    }
    return data;
  }
);

export const ImportContacts = (payload) => ({
  type: IMPORTED_CONTACT,
  payload,
});

export const CreateCompanies = createAsyncThunk(
  CREATE_COMPANIES,
  async (meta, thunkAPI) => {
    const response = await fetch(`${ENDPOINT}/api/contact/company/create/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `JWT ${meta.access_token}`,
      },
      body: JSON.stringify(meta.data),
    });
    const data = await response.json();
    if (!response.ok) {
      return thunkAPI.rejectWithValue(data);
    }
    return data;
  }
);

export const CreatePeopleContact = createAsyncThunk(
  CREATE_PEOPLE,
  async (meta, thunkAPI) => {
    const response = await fetch(`${ENDPOINT}/api/contact/create/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `JWT ${meta.access_token}`,
      },
      body: JSON.stringify(meta.data),
    });
    const data = await response.json();
    if (!response.ok) {
      return thunkAPI.rejectWithValue(data);
    }
    return data;
  }
);

export const getEmails = createAsyncThunk(
  GET_COMPANIES,
  async (meta, thunkAPI) => {
    const response = await fetch(`${ENDPOINT}/api/contact/company/list/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `JWT ${meta.access_token}`,
      },
    });
    const data = await response.json();
    if (!response.ok) {
      return thunkAPI.rejectWithValue(data);
    }
    return data;
  }
);

const initialState = {
  persons: [],
  companies: [],
  imported: [],
  emails: [],
  importing: false,
  loading: false,
  target: null,
  error: null,
};

const contactReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${GET_PERSONS}/pending`:
      return {
        ...state,
        loading: true,
      };
    case `${GET_PERSONS}/fulfilled`:
      return {
        ...state,
        loading: false,
        persons: action.payload,
      };
    case `${GET_PERSONS}/rejected`:
      return {
        ...state,
        loading: false,
        error: action.payload,
        persons: [],
      };
    case `${GET_COMPANIES}/pending`:
      return {
        ...state,
        loading: true,
      };
    case `${GET_COMPANIES}/fulfilled`:
      return {
        ...state,
        loading: false,
        companies: action.payload,
      };
    case `${GET_COMPANIES}/rejected`:
      return {
        ...state,
        loading: false,
        error: action.payload,
        companies: [],
      };

    case IMPORTED_CONTACT:
      return {
        ...state,
        imported: action.payload,
      };

    case `${GET_EMAILS}/pending`:
      return {
        ...state,
        loading: true,
      };
    case `${GET_EMAILS}/fulfilled`:
      return {
        ...state,
        loading: false,
        emails: action.payload,
      };
    case `${GET_EMAILS}/rejected`:
      return {
        ...state,
        loading: false,
        error: action.payload,
        emails: [],
      };

    case IMPORT:
      return {
        ...state,
        importing: true,
      };

    case PROGRESS:
      return {
        ...state,
        target: action.payload,
      };

    case COMPLETE:
      return {
        ...state,
        importing: false,
      };
    case CLEAR_RECORD:
      return {
        ...state,
        target: null,
      };
    default:
      return state;
  }
};

export default contactReducer;
