import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Gear from '../assets/Gear.png';
import Dots from '../assets/Dots.png';
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { ENDPOINT,} from '../../Opportunity/Redux/oppor';
import Box from '@mui/material/Box';
import Bounce from 'react-reveal/Bounce';
import { useLocation } from 'react-router-dom';
import Company from './Tabs/Company';
import YourPlan from './Tabs/YourPlan';
import Activity from './Tabs/Activity';
import BillingHistory from './Tabs/BillingHistory';
import Terms from './Tabs/Terms';
import Payment from './Tabs/InvoiceInformation';


const AccountBilling = ({ screenHeight }) => {

  const access_token = useSelector((state) => state.auth.user.access);

  // this is alert modal

  const [showAlert, setShowAlert] = useState()
  const [msg, setMsg] = useState('')
  const [heading, setHeading] = useState(true)
  const funcAlert = (mes, head) => {
    setMsg(mes)
    setHeading(head)
    setShowAlert(true)
  }

  const [team_plan, setTeam_Plan] = useState()

  const apiFunc = async () => {

    const response = await fetch(`${ENDPOINT}/api/user/team/`, {
      method: 'GET',
      headers: {
        Authorization: `JWT ${access_token}`,
        'Content-Type': 'application/json',
      },
    });
    const data = await response.json();
    if (response.ok) {
      setTeam_Plan(data)
    }

  }

  //////////////////////////////////////////////////////////////////

  const [screen, setScreen] = useState("company")

  const location = useLocation()

  useEffect(() => {


    if (location?.state?.type === true) {
      setScreen("yourplan")
    }
    if (location?.state?.type === false) {
      setScreen("payment")
    }

    apiFunc()
  }, [])


  return (
    <Bounce left>
      <section className="oppor-section">
        <div className="oppor-row">
          <div className="oppor-col">
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <h3 className="fstxtPro">Account & Billing</h3>
            </div>
            <img className="icon-sm" src={Dots} alt="dots" />
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                background: 'inherit',
                border: 'none',
                outline: 'none',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <button
                style={{
                  background: 'inherit',
                  border: 'none',
                  outline: 'none',
                }}
                id="filter"
              >
                <img id="filter" className="icon-sm" src={Gear} alt="gear" />
              </button>
            </div>
          </div>
        </div>
        {
          showAlert &&
          <AlertShowComponent setShowAlert={setShowAlert} msg={msg} setMsg={setMsg} heading={heading} setHeading={setHeading} />
        }
        <div
          style={{
            overflowY: "hidden",
            maxHeight: screenHeight - 155, background: "white", borderRadius: "8px", border: "1px"
          }}
          className="tw-text-gray-600 tw-bg-white tw-rounded tw-border tw-body-font">
          <div style={{ height: screenHeight - 0, paddingBottom: "32px" }} className="px-4 pb-12">
            <Box sx={{ margin: "25px 0px 0px 0px", display: "flex", width: "100%", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
              <form style={{ marginBottom: "8px", width: "100%", textAlign: "center", boxShadow: "0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)", borderRadius: "10px", background: "white", display: "flex" }} className="tw-bg-white lg:tw-mb-0 lg:tw-flex">
                <Box sx={{ display: "flex", textAlign: "center", boxShadow: "0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)", alignItems: "center", borderRadius: "10px", width: "100%", position: "relative" }}>
                  <input
                    onClick={() => {
                      setScreen("company")
                    }
                    }
                    style={{ height: "0px", width: "0px", overflow: "hidden", position: "absolute" }}
                    className=""
                    type="radio"
                    id="company"
                    value=""
                  />
                  <label
                    htmlFor="company"
                    style={{ width: "100%" }}
                    className={`hoverLabel fsIcon line ${screen === "company" &&
                      "labelClick"
                      } `}
                  >
                    COMPANY INFORMATION
                  </label>
                  <input
                    onClick={() =>
                      setScreen("yourplan")
                    }
                    style={{ height: "0px", width: "0px", overflow: "hidden", position: "absolute" }}
                    type="radio"
                    className=""
                    id="yourplan"
                    value=""
                    checked
                  />
                  <label
                    htmlFor="yourplan"
                    style={{ width: "50%" }}
                    className={`hoverLabel fsIcon line lineLeft ${screen === "yourplan" &&
                      "labelClick"
                      }`}
                  >
                    YOUR PLAN
                  </label>
                  <input
                    style={{ height: "0px", width: "0px", overflow: "hidden", position: "absolute" }}
                    onClick={() => setScreen("activity")}
                    className=""
                    type="radio"
                    id="activity"
                    value=""
                    checked
                  />
                  <label
                    htmlFor="activity"
                    style={{ width: "50%" }}

                    className={`hoverLabel fsIcon line lineLeft ${screen === "activity" && "labelClick"
                      }`}
                  >
                    ACTIVITY
                  </label>
                  <input
                    onClick={() => setScreen("billinghistory")}
                    style={{ height: "0px", width: "0px", overflow: "hidden", position: "absolute" }}

                    className="tw-h-[0px] tw-w-[0px]  tw-overflow-hidden tw-absolute"
                    type="radio"
                    id="billinghistory"
                    value=""
                    checked
                  />
                  <label
                    htmlFor="billinghistory"
                    style={{ width: "80%" }}

                    className={`hoverLabel fsIcon line lineLeft ${screen === "billinghistory" && "labelClick"
                      }`}
                  >

                    BILLING HISTORY
                  </label>
                  <input
                    onClick={() => setScreen("payment")}
                    style={{ height: "0px", width: "0px", overflow: "hidden", position: "absolute" }}

                    className=""
                    type="radio"
                    id="payment"
                    value=""
                    checked
                  />
                  <label
                    htmlFor="payment"
                    style={{ width: "100%" }}

                    className={`hoverLabel fsIcon line lineLeft ${screen === "payment" && "labelClick"}`}
                  >

                    PAYMENT INFORMATION
                  </label>
                  <input
                    onClick={() => setScreen("terms")}
                    style={{ height: "0px", width: "0px", overflow: "hidden", position: "absolute" }}

                    className=""
                    type="radio"
                    id="terms"
                    value=""
                    checked
                  />
                  <label
                    htmlFor="terms"
                    style={{ width: "100%" }}

                    className={`hoverLabel fsIcon lineLeft ${screen === "terms" && "labelClick"}`}
                  >

                    TERMS & CONDITIONS
                  </label>

                </Box>
              </form>

            </Box>
            <Box sx={{ overflowY: 'scroll', height: `${screenHeight - 200}px` }}>
              {screen === "company" && <Company funcAlert={funcAlert} />}
              {screen === "yourplan" && <YourPlan funcAlert={funcAlert} apiFunc={apiFunc} team_plan={team_plan} />}
              {screen === "activity" && <Activity funcAlert={funcAlert} screenHeight={screenHeight} />}
              {screen === "billinghistory" && <BillingHistory funcAlert={funcAlert} screenHeight={screenHeight} />}
              {screen === "payment" && <Payment  funcAlert={funcAlert} apiFunc={apiFunc} team_plan={team_plan} />}
              {screen === "terms" && <Terms funcAlert={funcAlert} screenHeight={screenHeight} />}
            </Box>

          </div>
        </div>

      </section>
    </Bounce>
  );
};

export default AccountBilling;

export { Terms }


const AlertShowComponent = ({ showAlert, setShowAlert, msg, setMsg, heading, setHeading }) => {

  const handleCancel = () => {
    setShowAlert(false)
    setMsg('')
    setHeading(true)
  }


  return (
    <Box
      sx={{
        width: { xs: '90%', sm: '75%', md: '60%' },
        height: { xs: '200px', sm: '250px', lg: '300px' },
        padding: '2rem',
        borderRadius: '8px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 999
      }}
    >
      <Box
        sx={{
          width: '98%',
          height: '98%',
          backgroundColor: 'rgba(255,255,255)',
          border: '1px solid #d9d9d9',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          borderRadius: '8px',
          padding: '0.68rem 1rem',
          justifyContent: 'space-around',
          boxShadow: '2px 2px 6px 0px #D9D9D9',
        }}
      >
        <p
          style={{
            fontSize: '1.1rem',
            fontWeight: '500',
            fontFamily: 'Open Sans, sans-serif',
            color: heading ? '#db8000' : 'red',
            alignItems: 'center'
          }}
        >
          <InfoOutlinedIcon SX={{ color: heading ? '#db8000' : 'red', fontSize: '22px', marginRight: '10px' }} /> {heading ? 'Update Your Information' : 'ERROR'}
        </p>
        <p style={{
          color: '#232125',
          letterSpacing: '-0.3px',
          textAlign: 'center',
          fontSize: '1rem',
          fontFamily: '\'Open sans\', sans-serif',
          fontWeight: '500'
        }}>
          {msg}
        </p>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'end',
            alignItems: 'right',
            width: '100%',
            margin: '0 auto',
            pr: 3,
          }}
        >
          <button className='payBtns px-4 py-2 rounded tw-text-[#711FFF] hover:tw-bg-[#711FFF] hover:tw-text-white tw-border tw-border-[#711FFF] tw-rounded' onClick={handleCancel}>Cancel</button>
        </Box>
      </Box>
    </Box>

  )
}