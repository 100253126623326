import {
  Button,
  Grid,
  Paper,
  Popover,
  Table,
  TableContainer,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useState, useEffect, useCallback, useRef } from "react";
import { useSelector } from "react-redux";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateRangeCalendar } from "@mui/x-date-pickers-pro";
import dayjs from "dayjs";
import {
  CalendarMonthOutlined,
} from "@mui/icons-material";
import ExpandMoreSharpIcon from "@mui/icons-material/ExpandMoreSharp";
import { HiMiniUserCircle } from "react-icons/hi2";
import {
  IoIosCloseCircle,
  IoMdArrowDropdown,
  IoMdArrowDropup,
} from "react-icons/io";
import { IoCloseSharp } from "react-icons/io5";
import { FaAngleDown, FaStar } from "react-icons/fa6";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { ENDPOINT } from "../Opportunity/Redux/oppor";
import BarCharts from "../../Componenets/Reports/Time/BarChart";
import TimeTableHeader from "../../Componenets/Reports/Time/TimeTableHeader";
import DefaultGroupTableBody from "../../Componenets/Reports/Time/DefaultGroupTableBody";
import WorkTypeGroupTableBody from "../../Componenets/Reports/Time/WorkTypeGroupTableBody";
import TeamMemberGroupTableBody from "../../Componenets/Reports/Time/TeamMemberGroupTableBody";
import { PreLoading } from "../Contacts/People/EditContact";

const Time = () => {
  const buttonNames = ["This Month", "Group By", "Billable", "Export As"];
  const workTypes = useSelector((state) => state.Misc.workTypes);
  const access_token = useSelector((state) => state.auth.user.access);
  const companies = useSelector((state) => state.Contacts.companies);
  const allTasks = useSelector((state) => state.projects.tasks);
  const [day, setDay] = useState("This Week");
  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverIndex, setPopoverIndex] = useState(null);
  const userTeam = useSelector((state) => state.userTeam.team.member);
  const [myArr, setMyArr] = useState([]);
  const [groupBy, setGroupBy] = useState(0);
  const [billableName, setBillableName] = useState("Billable");
  const [groupName, setGroupName] = useState("none");
  const [selectedDiliverables, setSelectedDiliverables] = useState([]);
  const [taskOfDiliverables, settaskOfDiliverables] = useState([]);
  const [selectedWorktypes, setselectedWorktypes] = useState([]);
  const [Deliverableaccordian, setDeliverableAccordian] = useState("");
  const [deliverableValue, setDeliverableValue] = useState([
    { name: "Admin", discription: "Dillas Tx, Usa", img: "" },
  ]);
  const [allDeliverables, setAllDeliverables] = useState();
  const [exportAs, setExportAs] = useState(0);
  const clientRef = useRef();
  const projectRef = useRef();
  const deliverableRef = useRef();
  const worktypeRef = useRef();
  const peopleRef = useRef();

  const [filteredProject, setFilteredProject] = useState([]);
  const [worktypeValue, setWorktypeValue] = useState([]);
  const [filterProjectPopop, setFilterProjectPopop] = useState(false);
  const [projectValue, setProjectValue] = useState([]);
  const [filterDeliverablePopop, setFilterDeliverablePopop] = useState(false);
  const [filterWorktypePopop, setFilterWorktypePopop] = useState(false);
  const [filterPeoplePopop, setFilterPeoplePopop] = useState(false);
  const [selectedPeople, setSelectedPeople] = useState([]);
  const [AllProjects, setAllProjects] = useState();
  const [billable, setBillable] = useState(false);
  const [filtering, setFiltering] = useState({
    people: [],
    client: [],
    project: [],
    tasks: [],
    deliverable: [],
    worktype: [],
  });
  const [filterClientPopop, setFilterClientPopop] = useState(false);
  const [clientValue, setClientValue] = useState();
  const [Projects, setProjects] = useState(null);
  const [dateRange, setDateRange] = useState([
    dayjs().startOf("week"),
    dayjs().endOf("week"),
  ]);

  useEffect(() => {
    apiInitialProject();
  }, []);

  useEffect(() => {
    const deliverables = myArr.reduce((acc, item) => {
      return acc.concat(item?.deliverable_project || []);
    }, []);
    setAllDeliverables(deliverables);
  }, [myArr]);

  const apiInitialProject = async () => {
    try {
      const response = await fetch(` ${ENDPOINT}/api/project/list/`, {
        method: "GET",
        headers: {
          Authorization: `JWT ${access_token}`,
        },
      });
      const data = await response.json();
      if (!response.ok) {
      } else {
        const pro = data.sort((a, b) => {
          const dateA = new Date(a.created_at);
          const dateB = new Date(b.created_at);
          return dateB - dateA;
        });
    
        setMyArr(pro);
      }
    } catch (error) {
      console.error("api error", error);
    }
    
    try {
      
      const response = await fetch(` ${ENDPOINT}/api/project/list/reports/`, {
        method: 'GET',
        headers: {
          Authorization: `JWT ${access_token}`,
        },
      });
      const data = await response.json();
      if (!response.ok) {
      } else {
        const pro = data.sort((a, b) => {
          // Assuming 'created_at' is a property in your project objects
          const dateA = new Date(a?.created_at);
          const dateB = new Date(b?.created_at);    
          // Sort in descending order (newest created first)
          return dateB - dateA;
        });
                
        setProjects(pro)
      }
    } catch (error) {
      console.error('api error', error)
    }
  };

  const handleClick = (event, index) => {
    setAnchorEl(event.currentTarget);
    setPopoverIndex(index);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setPopoverIndex(null);
  };

  const handleClickGroupBy = (v, i) => {
    setGroupBy(i);
    setGroupName(v);
    handleClose();
  };

  const handleClickBillable = (v, i) => {
    setBillableName(v);

    if (v === "Billable") {
      setBillable(true);
    } else if (v === "Non Billable") {
      setBillable(false);
    } else {
      setBillable(null);
    }
    handleClose();
  };

  function getFormattedTimestamp() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const seconds = String(now.getSeconds()).padStart(2, "0");

    return `${year}-${month}-${day}_${hours}-${minutes}-${seconds}`;
  }

  const printDocument = () => {
    const input = document.getElementById("div-to-print");
    const pdf = new jsPDF({
      orientation: "portrait",
      unit: "mm",
      format: "a4",
    });

    const marginLeft = 10;
    const marginTop = 10;

    const usableWidth = pdf.internal.pageSize.width - 2 * marginLeft;
    const usableHeight = pdf.internal.pageSize.height - 2 * marginTop;

    html2canvas(input, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");

      const aspectRatio = canvas.width / canvas.height;
      let imgWidth, imgHeight;

      if (aspectRatio > 1) {
        imgWidth = usableWidth;
        imgHeight = usableWidth / aspectRatio;
      } else {
        imgHeight = usableHeight;
        imgWidth = usableHeight * aspectRatio;
      }

      pdf.addImage(imgData, "PNG", marginLeft, marginTop, imgWidth, imgHeight);
      const timestamp = getFormattedTimestamp();

      pdf.save(`Time_Document_Report_${timestamp}.pdf`);
    });
  };

    const downloadCSV = () => {

      let data = tasksToBeRendered.length > 0 && tasksToBeRendered.map((task)=>{

        let taskType = task?.task_type ?? ''
        let taskProject = task?.task_project ?? ''
        let taskName = task?.task_name ?? ''
        let taskDescription = task?.description ? task?.description.replace(/<\/?[^>]+(>|$)/g, "") : ''
        let taskWorktype = task?.task_worktype[0]?.name ?? ''
        let taskMember = task?.time_tracking[0]?.created_by?.first_name + task?.time_tracking[0]?.created_by?.last_name ?? ''
        let billable = task?.billable ? 'Yes' : 'No'
        let startDate = new Date(task?.time_tracking[0]?.time_tracking_start).toISOString().split('T')[0] ?? ''
        let startTime = new Date(task?.time_tracking[0]?.time_tracking_start).toISOString().split('T')[1].split('Z')[0].split(':').slice(0, 2).join(':') ?? ''
        let endDate = new Date(task?.time_tracking[0]?.time_tracking_stop).toISOString().split('T')[0] ?? ''
        let endTime = new Date(task?.time_tracking[0]?.time_tracking_stop).toISOString().split('T')[1].split('Z')[0].split(':').slice(0, 2).join(':') ?? ''
        let note =  task.time_tracking[0]?.note ? task.time_tracking[0]?.note.replace(/<\/?[^>]+(>|$)/g, "") : ''
        let time =  task.time_tracking[0]?.tracked_time ?? ''
        let project_name = Array.isArray(Projects) && Projects.length && task?.task_project ? Projects.find((item) => item?.id === task?.task_project)?.title : '';
        let clients =  Array.isArray(companies) && companies?.length && task?.task_project ? companies.find((fnd)=>Projects.find(som=>som?.id=== task?.task_project)?.company === fnd?.id )?.name : '';

        function timeToDecimal(time) {
         // Split the time into hours, minutes, and seconds
         const [hours, minutes, seconds] = time.split(':').map(Number);
         
         // Calculate the total hours as a decimal, including minutes and seconds as fractions
         const totalHours = hours + (minutes / 60) + (seconds / 3600);
         
         // Format to two decimal places
         return totalHours.toFixed(2);
       }
       const decimalTime = timeToDecimal(time);
 
         return (
           [taskType+', '+taskProject+', '+project_name+', '+clients+', '+taskName+','+taskDescription+', '+note+', '+taskWorktype+', '+taskMember+', '+billable+', '+startDate+', '+startTime+', '+endDate+', '+endTime+', '+time+', '+decimalTime+', '+' '+', ']
         )
       }).sort((a, b) => {
        if (a.date === "No Time Tracked") return 1;
        if (b.date === "No Time Tracked") return -1;
        return new Date(a.date) - new Date(b.date);
      });
      
    const headers = ["Type","Job#","Job Name", "Client Name", "Task Name","Task Description", "Time Entry Notes", "Work Type", "Team Member", "Billable", "Start Date","Start Time","End Date", "End Time", "Total Time (H)", "Total Time (decimal)","Rate (USD)", "Total"];

    const csvContent = [headers.join(","), ...data.map(e => e.join(","))].join("\n");
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'data.csv';
    link.click();
  };

  const downloadXLS = () => {

    let data = tasksToBeRendered.length > 0 && tasksToBeRendered.map((task)=>{

      let taskType = task?.task_type ?? ''
      let taskProject = task?.task_project ?? ''
      let taskName = task?.task_name ?? ''
      let taskDescription = task?.description ? task?.description.replace(/<\/?[^>]+(>|$)/g, "") : ''
      let taskWorktype = task?.task_worktype[0]?.name ?? ''
      let taskMember = task?.time_tracking[0]?.created_by?.first_name + task?.time_tracking[0]?.created_by?.last_name ?? ''
      let billable = task?.billable ? 'Yes' : 'No'
      let startDate = new Date(task?.time_tracking[0]?.time_tracking_start).toISOString().split('T')[0] ?? ''
      let startTime = new Date(task?.time_tracking[0]?.time_tracking_start).toISOString().split('T')[1].split('Z')[0].split(':').slice(0, 2).join(':') ?? ''
      let endDate = new Date(task?.time_tracking[0]?.time_tracking_stop).toISOString().split('T')[0] ?? ''
      let endTime = new Date(task?.time_tracking[0]?.time_tracking_stop).toISOString().split('T')[1].split('Z')[0].split(':').slice(0, 2).join(':') ?? ''
      let note =  task.time_tracking[0]?.note ? task.time_tracking[0]?.note.replace(/<\/?[^>]+(>|$)/g, "") : ''
      let time =  task.time_tracking[0]?.tracked_time ?? ''
      let project_name = Array.isArray(Projects) && Projects.length && task?.task_project ? Projects.find((item) => item?.id === task?.task_project)?.title : '';
      let clients =  Array.isArray(companies) && companies?.length && task?.task_project ? companies.find((fnd)=>Projects.find(som=>som?.id=== task?.task_project)?.company === fnd?.id )?.name : '';

      function timeToDecimal(time) {
       // Split the time into hours, minutes, and seconds
       const [hours, minutes, seconds] = time.split(':').map(Number);
       
       // Calculate the total hours as a decimal, including minutes and seconds as fractions
       const totalHours = hours + (minutes / 60) + (seconds / 3600);
       
       // Format to two decimal places
       return totalHours.toFixed(2);
     }
     const decimalTime = timeToDecimal(time);

       return (
         [taskType,taskProject,project_name,clients,taskName,taskDescription,note,taskWorktype,taskMember,billable,startDate,startTime,endDate,endTime,time,decimalTime,'','']
       )
     }).sort((a, b) => {
      if (a.date === "No Time Tracked") return 1;
      if (b.date === "No Time Tracked") return -1;
      return new Date(a.date) - new Date(b.date);
    });
    
  const headers = ["Type","Job#","Job Name", "Client Name", "Task Name","Task Description", "Time Entry Notes", "Work Type", "Team Member", "Billable", "Start Date","Start Time","End Date", "End Time", "Total Time (H)", "Total Time (decimal)","Rate (USD)", "Total"];

    const worksheet = XLSX.utils.aoa_to_sheet([
      headers,
      ...data,
    ]);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sales");

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });

    saveAs(blob, "Sales");
  };

  const handleClickExportAs = (v, i) => {
    if (v === "PDF") {
      printDocument();
    }

    if (v === "CSV") {
      downloadCSV();
    }

    if (v === "XLS") {
      downloadXLS();
    }

    setExportAs(i);
    handleClose();
  };

  const handleDateRange = (v) => {
    setDateRange(v);
  };

  const handleClickDay = (v, i) => {
    setDay(v);
    if (v === "Today") {
      const today = dayjs();
      setDateRange([today]);
    } else if (v === "Yesterday") {
      const today = dayjs();
      setDateRange([today.add(1, "day")]);
    } else if (v === "This Week") {
      const today = dayjs();
      setDateRange([today.startOf("week"), today.endOf("week")]);
    } else if (v === "Last Week") {
      const today = dayjs();
      const prevWeek = today.subtract(7, "day");
      setDateRange([prevWeek.startOf("week"), prevWeek.endOf("week")]);
    } else if (v === "This Month") {
      const today = dayjs();
      setDateRange([today.startOf("month"), today.endOf("month")]);
    } else if (v === "Last Month") {
      const today = dayjs();
      const startOfNextMonth = today.startOf("month").subtract(1, "month");
      setDateRange([startOfNextMonth, startOfNextMonth.endOf("month")]);
    } else if (v === "This Year") {
      const today = dayjs();
      setDateRange([today.startOf("year"), today.endOf("year")]);
    } else if (v === "Last Year") {
      const today = dayjs();
      setDateRange([
        today.startOf("year").subtract(1, "year"),
        today.endOf("year").subtract(1, "year"),
      ]);
    }
  };

  const firstfilteredTasks = allTasks.filter((task) => {
    const start =
      Array.isArray(task.time_tracking) &&
      task.time_tracking.length &&
      task.time_tracking[task.time_tracking.length - 1].time_tracking_start;
    const end =
      Array.isArray(task.time_tracking) &&
      task.time_tracking.length &&
      task.time_tracking[task.time_tracking.length - 1].time_tracking_stop;

    const startDateTime = new Date(start);
    const endDateTime = new Date(end);

    const timeDifference = endDateTime - startDateTime;

    return timeDifference > 0;
  });
  const filteredTasks = [];

  firstfilteredTasks.forEach((parent) => {
    parent.time_tracking.forEach((timeTrack) => {
      const { time_tracking_start, time_tracking_stop } = timeTrack;
      if (new Date(time_tracking_start) < new Date(time_tracking_stop)) {
        const clonedParent = { ...parent };
        clonedParent.time_tracking = [timeTrack];
        filteredTasks.push(clonedParent);
      }
    });
  });

  const handleClientListSelect = (v, i) => {
    setClientValue(v);
    const selectedClientIds = v?.id;
    setFiltering({
      ...filtering,
      client: [selectedClientIds],
    });
  };

  const handleProjectListSelect = (v, i) => {
    setFilteredProject([v]);
    const selectedProjectIds = v?.id;

    setFiltering({
      ...filtering,
      project: [selectedProjectIds],
    });
  };

  const handleDeliverableOpen = (v, i) => {
    setSelectedDiliverables([...selectedDiliverables, v]);

    const selectedDeliverablesID = v.id;

    setFiltering((prevFiltering) => {
      return {
        ...prevFiltering,
        deliverable: [...prevFiltering.deliverable, selectedDeliverablesID],
      };
    });
  };

  const handleAccordianOpen = (v, i) => {
    setDeliverableAccordian(v.deliverable_name);
    if (Deliverableaccordian === v.deliverable_name) {
      setDeliverableAccordian("");
    }
  };

  const handleSelectTask = (item, i) => {
    const isTaskSelected = taskOfDiliverables.some(
      (task) => task.id === item.id
    );

    setFiltering((prevFiltering) => {
      return {
        ...filtering,
        tasks: isTaskSelected
          ? prevFiltering.tasks.filter((taskId) => taskId !== selectedTaskID)
          : [...prevFiltering.tasks, selectedTaskID],
      };
    });

    if (!isTaskSelected) {
      settaskOfDiliverables([...taskOfDiliverables, { ...item, index: i }]);
    } else {
      settaskOfDiliverables(
        taskOfDiliverables.filter((task) => task.id !== item.id)
      );
    }

    const selectedTaskID = item?.id;
  };

  const handleWorktypeListSelect = (v, i) => {
    const exists = selectedWorktypes?.some(
      (existingWorktype) => existingWorktype?.name === v?.name
    );

    if (!exists) {
      setselectedWorktypes([...selectedWorktypes, v]);

      const NewWorktypes = v?.name;

      setFiltering((prevFiltering) => {
        return {
          ...prevFiltering,
          worktype: [...prevFiltering?.worktype, NewWorktypes],
        };
      });
    }
  };

  const handlePeopleListSelect = (v, i) => {
    setSelectedPeople([...selectedPeople, v]);
    selectedPeople.map((data) => {
      if (data.task_name === v?.task_name) {
        settaskOfDiliverables([]);
      }
    });

    const selectedPerson = v?.id;
    setFiltering((prevFiltering) => {
      return {
        ...prevFiltering,
        people: [...prevFiltering?.people, selectedPerson],
      };
    });
  };

  const FilteredByClient =
    Array.isArray(filtering?.client) && filtering?.client?.length
      ? filteredTasks.filter((item) =>
          filtering.client.some((ele) => item?.company === ele)
        )
      : filteredTasks;

  const filterByProject =
    Array.isArray(filtering?.project) && filtering?.project.length
      ? FilteredByClient.filter((item) =>
          filtering.project.some((ele) => item?.task_project === ele)
        )
      : FilteredByClient;

  const filterByDeliverables =
    Array.isArray(filtering?.deliverable) && filtering?.deliverable.length
      ? filterByProject.filter((item) =>
          filtering.deliverable.some((ele) => item?.task_deliverable === ele)
        )
      : filterByProject;

  const filterByTask =
    Array.isArray(filtering?.tasks) && filtering?.tasks.length
      ? filterByDeliverables.filter((item) =>
          filtering.tasks.some((ele) => item?.id === ele)
        )
      : filterByDeliverables;

  const filterByWorktype =
    Array.isArray(filtering?.worktype) && filtering?.worktype.length
      ? filterByTask.filter((item) =>
          filtering.worktype.some((ele) => item?.task_worktype[0]?.name === ele)
        )
      : filterByTask;

  const filterByPerson =
    Array.isArray(filtering?.people) && filtering?.people.length
      ? filterByWorktype.filter((item) =>
          filtering.people.some((ele) => item?.created_by?.id === ele)
        )
      : filterByWorktype;

  const Tasks =
    Array.isArray(filtering?.tasks) && filtering?.tasks?.length
      ? filterByTask
      : Array.isArray(filtering?.client) && filtering?.client?.length
      ? FilteredByClient
      : Array.isArray(filtering?.project) && filtering?.project?.length
      ? filterByProject
      : Array.isArray(filtering?.deliverable) && filtering?.deliverable?.length
      ? filterByDeliverables
      : Array.isArray(filtering.worktype) && filtering?.worktype?.length
      ? filterByWorktype
      : Array.isArray(filtering?.people) && filtering?.people.length
      ? filterByPerson
      : Array.isArray(filtering?.tasks) && filtering?.tasks?.length
      ? filteredTasks
      : filteredTasks;


  const filteringTask = Tasks?.filter((item) => {
        let date = item.time_tracking[0].date? item.time_tracking[0].date : item.time_tracking[0].time_tracking_start
        let d = new Date(date)
        let startDate = new Date(dateRange[0])
        let endDate = new Date(dateRange[1])
        return d>= startDate && d<= endDate
  })

  const withBillableValue = filteringTask.filter((item) => {
    return billable === null || item.billable === billable;
  });

  const tasksToBeRendered = withBillableValue.map((item) => ({
    ...item,
    time_tracking: item?.time_tracking.filter((ele) => ele?.time_tracking_stop),
  })).sort((a, b) => {
      const aDate = a.time_tracking[0]?.date;
      const bDate = b.time_tracking[0]?.date;
      
    if (aDate && bDate) {
      // Both dates exist, compare them
      return new Date(aDate) - new Date(bDate);
    } else if (aDate) {
      // Only a has a date, it comes first
      return -1;
    } else if (bDate) {
      // Only b has a date, it comes first
      return 1;
    } else {
      // Both dates are null, compare by created_by
      return a?.time_tracking[0]?.time_tracking_start.localeCompare(b?.time_tracking[0]?.time_tracking_start);
    }
  }).reverse();
  
  useEffect(() => {
    setAllProjects(myArr);
  }, [myArr]);

  const MergedWorkTypes = [];
  workTypes?.forEach((item) => {
    const exists = MergedWorkTypes.some(
      (existingWorktype) => existingWorktype.name === item.name
    );

    if (!exists) {
      MergedWorkTypes.push(item);
    }
  });

  const handleRemoveClient = (clientIdToRemove, name) => {
    setClientValue(clientValue !== name);
    setFiltering((prevFiltering) => {
      const updatedClient = prevFiltering.client.filter(
        (clientId) => clientId !== clientIdToRemove
      );

      return {
        ...prevFiltering,
        client: updatedClient,
      };
    });
  };

  const handleRemoveProject = (id) => {
    setFilteredProject([]);
    setFiltering((prevFiltering) => {
      const updatedProjects = (prevFiltering.project = []);

      return {
        ...prevFiltering,
        project: updatedProjects,
      };
    });
  };

  const handleRemoveDeliverable = () => {
    setSelectedDiliverables([]);
    setFiltering((prevFiltering) => {
      const updatedDeliverables = (prevFiltering.deliverable = []);
      const updatedTasks = (prevFiltering.deliverable = []);

      return {
        ...prevFiltering,
        deliverable: updatedDeliverables,
        tasks: updatedTasks,
      };
    });
  };

  const UpdatedworktypeTasks = MergedWorkTypes.map((worktype) => {
    const tasksWithWorktype = tasksToBeRendered.filter(
      (task) => task.task_worktype[0]?.name === worktype.name
    );

    return {
      ...worktype,
      tasks: tasksWithWorktype,
    };
  });

  const UserTeamFind =
    Array.isArray(userTeam) &&
    userTeam.map((item) => {
      const tasksWithAssignee = tasksToBeRendered.filter((ele) =>
        ele?.time_tracking.some((int) => int?.created_by?.id === item.id)
      );
      return {
        ...item,
        tasks: tasksWithAssignee,
      };
    });
  const Team = Array.isArray(UserTeamFind) && UserTeamFind.filter((item) => item.first_name !== "First Name");

  const handleClientPopopToggle = useCallback(() => {
    setFilterClientPopop(!filterClientPopop);
    setFilterPeoplePopop(false);
    setFilterWorktypePopop(false);
    setFilterDeliverablePopop(false);
    setFilterProjectPopop(false);
  }, [filterClientPopop, setFilterClientPopop, setFilterPeoplePopop]);

  const handlePeoplePopopToggle = useCallback(() => {
    setFilterPeoplePopop(!filterPeoplePopop);
    setFilterWorktypePopop(false);
    setFilterClientPopop(false);
    setFilterDeliverablePopop(false);
    setFilterProjectPopop(false);
  }, [filterPeoplePopop, setFilterPeoplePopop, setFilterPeoplePopop]);

  const handleDeliverablePopopToggle = useCallback(() => {
    setFilterDeliverablePopop(!filterDeliverablePopop);
    setFilterPeoplePopop(false);
    setFilterClientPopop(false);
    setFilterWorktypePopop(false);
    setFilterProjectPopop(false);
  }, [filterDeliverablePopop, setFilterDeliverablePopop, setFilterPeoplePopop]);

  const handleProjectPopopToggle = useCallback(() => {
    setFilterProjectPopop(!filterProjectPopop);
    setFilterPeoplePopop(false);
    setFilterClientPopop(false);
    setFilterDeliverablePopop(false);
    setFilterWorktypePopop(false);
  }, [filterProjectPopop, setFilterProjectPopop, setFilterPeoplePopop]);

  const handleWorktypePopopToggle = useCallback(() => {
    setFilterWorktypePopop(!filterWorktypePopop);
    setFilterPeoplePopop(false);
    setFilterClientPopop(false);
    setFilterDeliverablePopop(false);
    setFilterProjectPopop(false);
  }, [filterWorktypePopop, setFilterWorktypePopop, setFilterPeoplePopop]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (clientRef.current && !clientRef.current.contains(event.target)) {
        setFilterClientPopop(false);
      }
      if (projectRef.current && !projectRef.current.contains(event.target)) {
        setFilterProjectPopop(false);
      }
      if (
        deliverableRef.current &&
        !deliverableRef.current.contains(event.target)
      ) {
        setFilterDeliverablePopop(false);
      }
      if (worktypeRef.current && !worktypeRef.current.contains(event.target)) {
        setFilterWorktypePopop(false);
      }
      if (peopleRef.current && !peopleRef.current.contains(event.target)) {
        setFilterPeoplePopop(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const totalHoursAndMinutes = (data) => {
    let totalTimeTracking = [];
    for (let i = 0; i < data.length; i++) {
      const timeTracking = data[i].time_tracking;
      totalTimeTracking.push(data[i].time_tracking[0]);
    }

    let totalHours = 0;
    let totalMinutes = 0;

    totalTimeTracking?.forEach((entry) => {
      if (entry.tracked_time) {
        const [hours, minutes, seconds] = entry.tracked_time
          .split(":")
          .map(Number);
        totalHours += hours;
        totalMinutes += minutes;
      }
    });

    totalHours += Math.floor(totalMinutes / 60);
    totalMinutes = totalMinutes % 60;

    return { totalHours, totalMinutes };
  };

  return (
    Array.isArray(allTasks) && allTasks?.length > 0 ?
    <>
      <Grid
        sx={{
          backgroundColor: "inherit",
          padding: "1rem 0rem",
          borderBottom: "1px solid #e2e2e2",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
            gap: "2.9rem",
            width: "95%",
            backgroundColor: "#F1F3F5",
            padding: "1rem 1rem",
            margin: "0 auto",
            borderRadius: "8px",
          }}
        >
          <div>
            {buttonNames.map((name, index) => (
              <>
                <Button
                  variant="contained"
                  aria-describedby={index}
                  onClick={(event) => handleClick(event, index)}
                  startIcon={index === 0 && <CalendarMonthOutlined />}
                  endIcon={
                    <ExpandMoreSharpIcon
                      style={{
                        width: "18px",
                        height: "18px",
                        fontWeight: 200,
                        color: "#03071E",
                      }}
                    />
                  }
                  style={{
                    marginRight: 10,
                    backgroundColor: "#E0E0DF",
                    color: "black",
                    textTransform: "inherit",
                    boxShadow: "inherit",
                    fontWeight: 400,
                    fontSize: "14px",
                    "&:hover": {
                      backgroundColor: "#E0E0DF",
                      color: "black",
                      boxShadow: "inherit",
                    },
                  }}
                >
                  {index === 1
                    ? name + " " + (groupName === "none" ? "" : groupName)
                    : index === 0
                    ? day
                    : index === 2
                    ? billableName
                    : name}
                </Button>
                <Popover
                  id={index}
                  open={popoverIndex === index}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  sx={{
                    "& .MuiPaper-root": {
                      boxShadow: "inherit",
                      border: "1px solid #E0E0DF",
                      borderRadius: 2,
                    },
                  }}
                >
                  {index === 0 && (
                    <>
                      <Box
                        align="center"
                        sx={{
                          width: "auto",
                          height: "auto",
                          px: "30px",
                          display: { xs: "block", md: "flex" },
                        }}
                      >
                        <Box
                          align="left"
                          sx={{
                            backgroundColor: "#F8F9FA",
                            width: { xs: "100%", md: "200px" },
                            p: 1,
                            pb: 3,
                            my: 4,
                            borderRadius: 2,
                          }}
                        >
                          {[
                            "Today",
                            "Yesterday",
                            "This Week",
                            "Last Week",
                            "This Month",
                            "Last Month",
                            "This Year",
                            "Last Year",
                          ].map((v, i) => {
                            return (
                              <Typography
                                key={i}
                                onClick={() => handleClickDay(v, i)}
                                sx={
                                  day === v
                                    ? {
                                        fontSize: "16px",
                                        fontWeight: 400,
                                        cursor: "pointer",
                                        color: "#711FFF",
                                        my: 3,
                                        ml: 2,
                                      }
                                    : {
                                        fontSize: "16px",
                                        fontWeight: 400,
                                        cursor: "pointer",
                                        color: "#03071E",
                                        my: 3,
                                        ml: 2,
                                      }
                                }
                              >
                                {v}
                              </Typography>
                            );
                          })}
                        </Box>
                        <Box sx={{ mx: 4, my: 4 }}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateRangeCalendar
                              value={dateRange}
                              onChange={(newValue) => handleDateRange(newValue)}
                              sx={{
                                "& .css-10wpov9-MuiTypography-root ": {
                                  fontWeight: "700 !important",
                                },
                                "& .css-cyzddl-MuiPickersSlideTransition-root-MuiDayCalendar-slideTransition:not(.MuiDateRangeCalendar-dayDragging) .MuiDateRangePickerDay-dayOutsideRangeInterval":
                                  {
                                    color: "#03071E !important",
                                    fontSize: "16px !important",
                                    fontWeight: 500,
                                  },
                                "& .css-1gbl7yn-MuiDateRangePickerDay-root": {
                                  backgroundColor: "#310085 !important",
                                  color: "white !important",
                                  borderRadius: "0px !important",
                                },
                                "& .css-1e841vg-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day.Mui-selected":
                                  {
                                    backgroundColor: "#711FFF !important",
                                    color: "white !important",
                                    borderRadius: "0px !important",
                                    fontSize: "16px !important",
                                    fontWeight: 500,
                                  },
                                "& .css-1ckjanr-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day.Mui-selected":
                                  {
                                    backgroundColor: "#711FFF !important",
                                    color: "white !important",
                                    borderRadius: "0px !important",
                                    fontSize: "16px !important",
                                    fontWeight: 500,
                                  },
                                "& .css-1a4q4r2-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day.Mui-selected":
                                  {
                                    backgroundColor: "#711FFF !important",
                                    color: "white !important",
                                    borderRadius: "0px !important",
                                    fontSize: "16px !important",
                                    fontWeight: 500,
                                  },
                                "& .css-2ko3hu-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day":
                                  {
                                    backgroundColor: "#310085 !important",
                                    color: "white !important",
                                    opacity: "inherit !important",
                                    borderRadius: "0px",
                                    border: "none !important",
                                    fontSize: "16px !important",
                                    fontWeight: 500,
                                  },
                                "& .css-1ku4sqv-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day":
                                  {
                                    backgroundColor: "#711FFF !important",
                                    color: "white !important",
                                    borderRadius: "0px !important",
                                    fontSize: "16px !important",
                                    fontWeight: 500,
                                  },
                                "& .css-ahwqre-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day:not(.Mui-selected)":
                                  {
                                    backgroundColor: "#310085 !important",
                                    color: "#e569db !important",
                                    borderRadius: "0px !important",
                                    fontSize: "16px !important",
                                    fontWeight: 500,
                                  },
                                "& .css-jef1b6-MuiDateRangeCalendar-container:not(:last-of-type)":
                                  {
                                    borderRight: "none !important",
                                  },
                                "& .css-3wduhr-Mu.iDateRangeCalendar-root": {
                                  flexDirection: "column !important",
                                },

                                "& .css-grqin-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day":
                                  {
                                    color: "inherit",
                                    fontSize: "16px !important",
                                    opacity: "inherit",
                                    fontWeight: 500,
                                  },
                                "& .css-1kex3oi-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day:not(.Mui-selected)":
                                  {
                                    backgroundColor: "inherit",
                                    color: "black !important",
                                    borderRadius: "50%",
                                    border: "1px solid gray",
                                    fontSize: "16px",
                                    fontWeight: 500,
                                  },
                                "& .MuiTypography-subtitle1": {
                                  color: " #03071E !important",
                                  fontSize: "16px",
                                  fontWeight: 700,
                                },
                                "& .MuiDayCalendar-weekDayLabel": {
                                  color: " #03071ECC !important",
                                  fontSize: "16px",
                                  fontWeight: 400,
                                },
                                "& .MuiSvgIcon-fontSizeInherit": {
                                  backgroundColor: "black",
                                  color: "white",
                                  borderRadius: "50%",
                                },
                                "& .MuiPickersDay-today": {
                                  backgroundColor: "#711FFF",
                                  color: "white",
                                  borderRadius: "0px",
                                  fontSize: "16px",
                                  fontWeight: 500,
                                },
                                "& .css-grqin-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day.Mui-selected":
                                  {
                                    backgroundColor: "#711FFF",
                                    color: "white",
                                    borderRadius: "0px",
                                    fontSize: "16px",
                                    fontWeight: 500,
                                  },
                                "& .css-gtjfra-MuiDateRangePickerDay-root": {
                                  backgroundColor: "#310085",
                                  color: "white !important",
                                  borderRadius: "0px",
                                  fontSize: "16px",
                                  fontWeight: 500,
                                },
                                "& .css-1i2r8k1-MuiDateRangePickerDay-root": {
                                  backgroundColor: "#310085",
                                  color: "white !important",
                                  borderRadius: "0px",
                                  fontSize: "16px",
                                  fontWeight: 500,
                                },
                                "& .MuiDateRangePickerDay-notSelectedDate": {},
                              }}
                            />
                          </LocalizationProvider>

                          <Box sx={{ display: "flex", my: 4 }}>
                            <Box sx={{ display: "flex", mr: 4 }}>
                              <Box
                                sx={{
                                  width: "20px",
                                  height: "20px",
                                  backgroundColor: "#711FFF",
                                  borderRadius: 2,
                                  mr: 2,
                                }}
                              ></Box>
                              <Typography
                                sx={{ fontWeight: 400, fontSize: "14px" }}
                              >
                                Start Date and End Date
                              </Typography>
                            </Box>
                            <Box sx={{ display: "flex" }}>
                              <Box
                                sx={{
                                  width: "20px",
                                  height: "20px",
                                  backgroundColor: "#310085",
                                  borderRadius: 2,
                                  mr: 2,
                                }}
                              ></Box>
                              <Typography
                                sx={{ fontWeight: 400, fontSize: "14px" }}
                              >
                                Date Range
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                        <Box
                          align=""
                          my={3}
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            alignItems: "",
                          }}
                        >
                          <Box align="left" mt={4}>
                            <Box align="left">
                              <Typography
                                sx={{
                                  color: "#03071E99",
                                  fontSize: "16px",
                                  fontWeight: 400,
                                }}
                              >
                                Start Date
                              </Typography>
                              <Typography
                                sx={{
                                  color: "#03071E",
                                  fontSize: "16px",
                                  fontWeight: 400,
                                }}
                              >
                                {dateRange[0] &&
                                  dateRange[0].format().slice(0, 10)}
                              </Typography>
                            </Box>
                            <Box sx={{ mt: 2 }}>
                              <Typography
                                sx={{
                                  color: "#03071E99",
                                  fontSize: "16px",
                                  fontWeight: 400,
                                }}
                              >
                                End Date
                              </Typography>
                              <Typography
                                sx={{
                                  color: "#03071E",
                                  fontSize: "16px",
                                  fontWeight: 400,
                                }}
                              >
                                {dateRange[1] &&
                                  dateRange[1].format().slice(0, 10)}
                              </Typography>
                            </Box>
                          </Box>

                          <Box align="right">
                            <Button
                              variant="text"
                              sx={{
                                textDecoration: "inherit",
                                color: "black",
                                width: { xs: "auto", lg: "100px" },
                                mr: 1,
                              }}
                              onClick={handleClose}
                            >
                              Cancel
                            </Button>
                            <Button
                              onClick={handleClose}
                              variant="contained"
                              sx={{
                                textDecoration: "inherit",
                                backgroundColor: "#711FFF",
                                width: { xs: "auto", lg: "100px" },
                                mr: 1,
                                "&:hover": {
                                  backgroundColor: "#711FFF",
                                },
                              }}
                            >
                              Save
                            </Button>
                          </Box>
                        </Box>
                      </Box>
                    </>
                  )}
                  {index === 1 && (
                    <Box sx={{ pb: 2, width: "150px", textAlign: "left" }}>
                      {["none", "Team Members", "Work Type"].map((v, i) => {
                        return (
                          <Typography
                            key={i}
                            fullWidth
                            onClick={() => handleClickGroupBy(v, i)}
                            sx={
                              groupBy === i
                                ? {
                                    color: "white",
                                    backgroundColor: "#711FFF",
                                    borderRadius: 1,
                                    textTransform: "inherit",
                                    fontSize: "14px",
                                    m: 2,
                                    px: 1,
                                    "&:hover": {
                                      backgroundColor: "#E0E0DF",
                                      cursor: "pointer",
                                    },
                                  }
                                : {
                                    color: "black",
                                    borderRadius: 1,
                                    textTransform: "inherit",
                                    fontSize: "14px",
                                    m: 2,
                                    px: 1,
                                    "&:hover": {
                                      backgroundColor: "#E0E0DF",
                                      cursor: "pointer",
                                    },
                                  }
                            }
                          >
                            {v}
                          </Typography>
                        );
                      })}
                    </Box>
                  )}
                  {index === 2 && (
                    <Box sx={{ pb: 2, width: "150px", textAlign: "left" }}>
                      {["Billable", "Non Billable", "Both"].map((v, i) => {
                        return (
                          <Typography
                            key={i}
                            fullWidth
                            onClick={() => handleClickBillable(v, i)}
                            sx={
                              billable === i
                                ? {
                                    cursor: "pointer",
                                    color: "white",
                                    backgroundColor: "#711FFF",
                                    borderRadius: 1,
                                    textTransform: "inherit",
                                    fontSize: "14px",
                                    m: 2,
                                    px: 1,
                                    "&:hover": { backgroundColor: "#E0E0DF" },
                                  }
                                : {
                                    cursor: "pointer",
                                    color: "black",
                                    borderRadius: 1,
                                    textTransform: "inherit",
                                    fontSize: "14px",
                                    m: 2,
                                    px: 1,
                                    "&:hover": { backgroundColor: "#E0E0DF" },
                                  }
                            }
                          >
                            {v}
                          </Typography>
                        );
                      })}
                    </Box>
                  )}
                  {index === 3 && (
                    <Box sx={{ pb: 2, width: "150px", textAlign: "left" }}>
                      {["PDF", "XLS", "CSV"].map((v, i) => {
                        return (
                          <Typography
                            key={i}
                            fullWidth
                            onClick={() => handleClickExportAs(v, i)}
                            sx={
                              exportAs === i
                                ? {
                                    color: "white",
                                    backgroundColor: "#711FFF",
                                    borderRadius: 1,
                                    textTransform: "inherit",
                                    fontSize: "14px",
                                    m: 2,
                                    px: 1,
                                    "&:hover": { backgroundColor: "#E0E0DF" },
                                  }
                                : {
                                    color: "black",
                                    borderRadius: 1,
                                    textTransform: "inherit",
                                    fontSize: "14px",
                                    m: 2,
                                    px: 1,
                                    "&:hover": { backgroundColor: "#E0E0DF" },
                                  }
                            }
                          >
                            {v}
                          </Typography>
                        );
                      })}
                    </Box>
                  )}
                </Popover>
              </>
            ))}
          </div>
        </Box>

        <Box sx={{ mx: 3, mb: 1, display: "flex", flexWrap: "wrap" }}>
          <Box
            sx={{
              width: "230px",
              mt: 2,
              mx: 1,
              borderBottom:
                clientValue?.length > 0
                  ? "2px solid #711FFF"
                  : "2px solid lightgray",
              position: "relative",
              "&:hover": { borderBottom: "2px solid #711FFF" },
            }}
            ref={clientRef}
          >
            <Typography
              sx={{
                color: clientValue?.length > 0 ? "#711FFF" : "#00000099",
                fontWeight: "400",
              }}
            >
              Filter By Client
            </Typography>

            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box
                className="customScrollBar"
                sx={{
                  display: "flex",
                  overflow: "auto",
                  width: "180px",
                  height: "35px",
                }}
              >
                {clientValue ? (
                  <Box
                    sx={{
                      backgroundColor: "#D2B8FF",
                      borderRadius: "20px",
                      px: 0.5,
                      mb: 0.5,
                      display: "flex",
                      alignItems: "center",
                      mr: 0.5,
                      zIndex: 999,
                    }}
                  >
                    {false ? (
                      <img
                        src="https://www.google.com/url?sa=i&url=https%3A%2F%2Fstock.adobe.com%2Fsearch%2Fimages%3Fk%3Dadmin%2Bicon&psig=AOvVaw0nbHsIp7Zj0mSMlKD_DR2M&ust=1701256844633000&source=images&cd=vfe&ved=0CBIQjRxqFwoTCNiX0eLJ5oIDFQAAAAAdAAAAABAE"
                        alt="im"
                        style={{ marginRight: "5px" }}
                      />
                    ) : (
                      <HiMiniUserCircle style={{ fontSize: "24px" }} />
                    )}
                    <Typography
                      sx={{
                        width: "60px",
                        color: "black",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {clientValue?.name}
                    </Typography>
                    <IoIosCloseCircle
                      onClick={() =>
                        handleRemoveClient(clientValue.id, clientValue)
                      }
                      style={{
                        marginLeft: "5px",
                        color: "#711FFF",
                        cursor: "pointer",
                        fontSize: "15px",
                        zIndex: 999,
                      }}
                    />
                  </Box>
                ) : (
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "400",
                      width: "100%",
                      color: "#000000DE",
                      mt: 1,
                    }}
                    onClick={handleClientPopopToggle}
                  >
                    Select
                  </Typography>
                )}
              </Box>

              <Box>
                {clientValue?.length > 0 && (
                  <IoCloseSharp
                    style={{
                      marginRight: "10px",
                      cursor: "pointer",
                      color: "#0000008F",
                    }}
                    onClick={() => setClientValue([])}
                  />
                )}
                {clientValue?.length > 0 ? (
                  <IoMdArrowDropup
                    style={{
                      cursor: "pointer",
                      fontSize: "24px",
                      color: "#0000008F",
                    }}
                    onClick={handleClientPopopToggle}
                  />
                ) : (
                  <IoMdArrowDropdown
                    style={{
                      cursor: "pointer",
                      fontSize: "24px",
                      color: "#0000008F",
                    }}
                    onClick={handleClientPopopToggle}
                  />
                )}
              </Box>
            </Box>

            <Box
              className="customScrollBar"
              sx={
                !filterClientPopop
                  ? { display: "none" }
                  : {
                      position: "absolute",
                      p: 2,
                      backgroundColor: "white",
                      zIndex: 10,
                      mt: 0.5,
                      width: "350px",
                      height: "310px",
                      overflow: "auto",
                      boxShadow:
                        "rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px",
                    }
              }
            >
              {companies?.length > 0 &&
                companies?.map((v, i) => {
                  let flt = clientValue?.name === v?.name;

                  return (
                    <Box
                      key={i}
                      onClick={() => handleClientListSelect(v, i)}
                      sx={{
                        display: "flex",
                        width: "98%",
                        height: "65px",
                        alignItems: "center",
                        borderRadius: 2,
                        px: 3,
                        py: 1,
                        my: 1.5,
                        cursor: "pointer",
                        boxShadow: "2px 2px 6px 0px #D9D9D9",
                        backgroundColor: flt ? "#9152FF" : "inherit",
                        color: "white",
                      }}
                    >
                      <HiMiniUserCircle
                        style={{
                          fontSize: "34px",
                          marginRight: "10px",
                          width: "42px",
                          height: "42px",
                          color: "lightgray",
                        }}
                      />
                      <Box sx={{ textTransform: "uppercase" }}>
                        <Typography
                          sx={{
                            color: flt ? "white" : "#023FE3",
                            fontSize: "12px",
                            fontWeight: 600,
                          }}
                        >
                          {v.name}
                        </Typography>
                        <Typography
                          sx={{
                            color: flt ? "white" : "#8C8E8E",
                            fontSize: "12px",
                          }}
                        >
                          {v.industry}
                        </Typography>
                      </Box>
                    </Box>
                  );
                })}
            </Box>
          </Box>
          <Box
            sx={{
              width: "230px",
              mt: 2,
              mx: 1,
              borderBottom:
                myArr.length > 0 ? "2px solid #711FFF" : "2px solid lightgray",
              position: "relative",
              "&:hover": { borderBottom: "2px solid #711FFF" },
            }}
            ref={projectRef}
          >
            <Typography
              sx={{
                color: myArr.length > 0 ? "#711FFF" : "#00000099",
                fontWeight: "400",
              }}
            >
              Filter By Job
            </Typography>

            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box
                className="customScrollBar"
                sx={{
                  display: "flex",
                  overflow: "auto",
                  width: "180px",
                  height: "35px",
                }}
              >
                {filteredProject.length > 0 ? (
                  filteredProject.map((v, i) => {
                    return (
                      <Box
                        sx={{
                          backgroundColor: "#2196F3",
                          borderRadius: "20px",
                          px: 0.5,
                          mb: 0.5,
                          display: "flex",
                          alignItems: "center",
                          mr: 0.5,
                        }}
                      >
                        <Typography
                          sx={{
                            marginLeft: "5px",
                            color: "white",
                            width: "60px",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {v?.title}
                        </Typography>
                        <IoIosCloseCircle
                          onClick={() => handleRemoveProject(v.id)}
                          style={{
                            marginLeft: "5px",
                            color: "#86c4f7",
                            cursor: "pointer",
                            fontSize: "15px",
                          }}
                        />
                      </Box>
                    );
                  })
                ) : (
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "400",
                      width: "100%",
                      color: "#000000DE",
                      mt: 1,
                    }}
                    onClick={handleProjectPopopToggle}
                  >
                    Select
                  </Typography>
                )}
              </Box>

              <Box>
                {projectValue.length > 0 && (
                  <IoCloseSharp
                    style={{
                      marginRight: "10px",
                      cursor: "pointer",
                      color: "#0000008F",
                    }}
                    onClick={() => setProjectValue([])}
                  />
                )}
                {projectValue.length > 0 ? (
                  <IoMdArrowDropup
                    style={{
                      cursor: "pointer",
                      fontSize: "24px",
                      color: "#0000008F",
                    }}
                    onClick={handleProjectPopopToggle}
                  />
                ) : (
                  <IoMdArrowDropdown
                    style={{
                      cursor: "pointer",
                      fontSize: "24px",
                      color: "#0000008F",
                    }}
                    onClick={handleProjectPopopToggle}
                  />
                )}{" "}
              </Box>
            </Box>

            <Box
              className="customScrollBar"
              sx={
                !filterProjectPopop
                  ? { display: "none" }
                  : {
                      position: "absolute",
                      p: 2,
                      borderRadius: "6px",
                      backgroundColor: "white",
                      zIndex: 10,
                      mt: 0.5,
                      width: "250px",
                      height: "400px",
                      overflow: "auto",
                      boxShadow:
                        "rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px",
                      border:
                        "1px solid var(--black-black-20, rgba(3, 7, 30, 0.20))",
                    }
              }
            >
              <Typography
                align="center"
                sx={{
                  backgroundColor: "#F8F9FA",
                  borderRadius: "6px",
                  fontSize: "14px",
                  py: 1.5,
                }}
              >
                {" "}
                <span style={{ color: "#5600EB", fontSize: "14px" }}>
                  Project
                </span>{" "}
                | Inactive
              </Typography>
              {AllProjects?.map((v, i) => {
                const company = companies.find((item) => item.id === v.company);

                let flt = projectValue.find((val) => val.name === v.name);

                return (
                  <Box
                    key={i}
                    onClick={() => handleProjectListSelect(v, i)}
                    sx={{
                      display: "flex",
                      width: "100%",
                      height: "65px",
                      alignItems: "center",
                      borderRadius: 2,
                      py: 1,
                      my: 1.5,
                      mt: 1,
                      cursor: "pointer",
                    }}
                  >
                    <Box sx={{ textTransform: "uppercase" }}>
                      <Typography
                        sx={{
                          color: "#03071E",
                          fontSize: "14px",
                          fontWeight: 600,
                        }}
                      >
                        {v?.title}
                      </Typography>
                      <Box
                        sx={{ display: "flex", alignItems: "center", ml: 1 }}
                      >
                        {v?.company && (
                          <svg
                            style={{ marginRight: "10px" }}
                            width="9"
                            height="7"
                            viewBox="0 0 9 7"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path id="Vector 4" d="M1 0V6H9" stroke="black" />
                          </svg>
                        )}
                        <Typography
                          sx={{
                            color: flt ? "#5600EB" : "#0B88F9",
                            fontSize: "12px",
                            "&:hover": { color: "#5600EB" },
                          }}
                        >
                          {company?.name}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                );
              })}
            </Box>
          </Box>

          <Box
            sx={{
              width: "230px",
              mt: 2,
              mx: 1,
              borderBottom:
                filteredProject.length > 0
                  ? "2px solid #711FFF"
                  : "2px solid lightgray",
              position: "relative",
              "&:hover": { borderBottom: "2px solid #711FFF" },
            }}
            ref={deliverableRef}
          >
            <Typography
              sx={{
                color: filteredProject.length > 0 ? "#711FFF" : "#00000099",
                fontWeight: "400",
              }}
            >
              Filter By Task
            </Typography>

            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box
                className="customScrollBar"
                sx={{
                  display: "flex",
                  overflow: "auto",
                  width: "180px",
                  height: "35px",
                }}
              >
                {selectedDiliverables?.length > 0 ? (
                  selectedDiliverables?.map((v, i) => {
                    return (
                      <Box
                        sx={{
                          backgroundColor: "#00000014",
                          borderRadius: "20px",
                          px: 0.5,
                          mb: 0.5,
                          display: "flex",
                          alignItems: "center",
                          mr: 0.5,
                        }}
                      >
                        <Typography
                          sx={{
                            marginLeft: "5px",
                            width: "60px",
                            color: "black",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {v?.deliverable_name}
                        </Typography>
                        <IoIosCloseCircle
                          onClick={() => handleRemoveDeliverable(v)}
                          style={{
                            marginLeft: "5px",
                            color: "#c2c3c4",
                            cursor: "pointer",
                            fontSize: "15px",
                          }}
                        />
                      </Box>
                    );
                  })
                ) : (
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "400",
                      width: "100%",
                      color: "#000000DE",
                      mt: 1,
                    }}
                    onClick={handleDeliverablePopopToggle}
                  >
                    Select
                  </Typography>
                )}
              </Box>

              <Box>
                {deliverableValue.length > 0 && (
                  <IoCloseSharp
                    style={{
                      marginRight: "10px",
                      cursor: "pointer",
                      color: "#0000008F",
                    }}
                    onClick={() => setDeliverableValue([])}
                  />
                )}
                {deliverableValue.length > 0 ? (
                  <IoMdArrowDropdown
                    style={{
                      cursor: "pointer",
                      fontSize: "24px",
                      color: "#0000008F",
                    }}
                    onClick={handleDeliverablePopopToggle}
                  />
                ) : (
                  <IoMdArrowDropup
                    style={{
                      cursor: "pointer",
                      fontSize: "24px",
                      color: "#0000008F",
                    }}
                    onClick={handleDeliverablePopopToggle}
                  />
                )}{" "}
              </Box>
            </Box>

            <Box
              className="customScrollBar"
              sx={
                !filterDeliverablePopop
                  ? { display: "none" }
                  : {
                      position: "absolute",
                      py: 2,
                      backgroundColor: "white",
                      zIndex: 10,
                      mt: 0.5,
                      borderRadius: "6px",
                      width: "auto",
                      height: "400px",
                      overflow: "auto",
                      boxShadow:
                        "rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px",
                      border:
                        "1px solid var(--black-black-20, rgba(3, 7, 30, 0.20))",
                      left: { xs: "-60px", md: "0px" },
                    }
              }
            >
              <Typography
                sx={{
                  textTransform: "uppercase",
                  fontSize: "10px",
                  color: "#A3A9AB",
                  mx: 1,
                }}
              >
                Deliverable
              </Typography>
              {allDeliverables?.length > 0
                ? allDeliverables?.map((v, i) => {
                    return (
                      <Box key={i}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            p: 2,
                            cursor: "pointer",
                            width: "250px",
                            "&:hover": { backgroundColor: "#D2B8FF" },
                          }}
                        >
                          <Box
                            onClick={() => handleDeliverableOpen(v, i)}
                            sx={{ width: "100%" }}
                          >
                            <Typography sx={{ fontWeight: "bold" }}>
                              {v.deliverable_name}
                            </Typography>
                          </Box>

                          <FaAngleDown
                            onClick={() => handleAccordianOpen(v, i)}
                            style={{ marginLeft: "20px" }}
                          />
                        </Box>
                        <Box
                          sx={
                            Deliverableaccordian === v?.deliverable_name
                              ? { display: "block" }
                              : { display: "none" }
                          }
                        >
                          {v?.task_deliverable?.length > 0
                            ? v.task_deliverable
                                ?.filter((item) => item.billable === billable)
                                .map((item, index) => {
                                  return (
                                    <>
                                      <Box
                                        key={index}
                                        sx={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          alignItems: "center",
                                          border: "1px solid lightgray",
                                          backgroundColor: "#F8F9FA",
                                          px: 3,
                                          py: 1,
                                          width: "250px",
                                        }}
                                      >
                                        <Typography sx={{ fontWeight: "bold" }}>
                                          {item?.task_name}
                                        </Typography>
                                        <FaStar
                                          onClick={() =>
                                            handleSelectTask(item, index)
                                          }
                                          style={{
                                            cursor: "pointer",
                                            color: taskOfDiliverables?.find(
                                              (task) => task?.id === item.id
                                            )
                                              ? "#FFD700"
                                              : "#E0E0DF",
                                            fontSize: "16px",
                                          }}
                                        />
                                      </Box>
                                    </>
                                  );
                                })
                            : ""}
                        </Box>
                      </Box>
                    );
                  })
                : ""}
            </Box>
          </Box>
          <Box
            sx={{
              width: "230px",
              mt: 2,
              mx: 1,
              borderBottom:
                worktypeValue.length > 0
                  ? "2px solid #711FFF"
                  : "2px solid lightgray",
              position: "relative",
              "&:hover": { borderBottom: "2px solid #711FFF" },
            }}
            ref={worktypeRef}
          >
            <Typography
              sx={{
                color: worktypeValue.length > 0 ? "#711FFF" : "#00000099",
                fontWeight: "400",
              }}
            >
              Filter By Worktype
            </Typography>

            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box
                className="customScrollBar"
                sx={{
                  display: "flex",
                  overflow: "auto",
                  width: "180px",
                  height: "35px",
                }}
              >
                {selectedWorktypes.length > 0 ? (
                  selectedWorktypes?.map((v, i) => {
                    return (
                      <Box
                        key={i}
                        sx={{
                          backgroundColor: "#00000014",
                          borderRadius: "20px",
                          px: 0.5,
                          mb: 0.5,
                          display: "flex",
                          alignItems: "center",
                          mr: 0.5,
                        }}
                      >
                        <Typography
                          sx={{
                            marginLeft: "5px",
                            width: "60px",
                            color: "black",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {v.name}
                        </Typography>
                        <IoIosCloseCircle
                          onClick={() => {
                            const updatedWorktypes = selectedWorktypes.filter(
                              (flt) => flt !== v
                            );
                            setselectedWorktypes(updatedWorktypes);
                            setFiltering((prevFiltering) => {
                              return {
                                ...prevFiltering,
                                worktype: prevFiltering?.worktype.filter(
                                  (wt) => wt !== v.name
                                ),
                              };
                            });
                          }}
                          style={{
                            marginLeft: "5px",
                            color: "#c2c3c4",
                            cursor: "pointer",
                            fontSize: "15px",
                          }}
                        />
                      </Box>
                    );
                  })
                ) : (
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "400",
                      width: "100%",
                      color: "#000000DE",
                      mt: 1,
                    }}
                    onClick={handleWorktypePopopToggle}
                  >
                    Select
                  </Typography>
                )}
              </Box>
              <Box>
                {worktypeValue.length > 0 && (
                  <IoCloseSharp
                    style={{
                      marginRight: "10px",
                      cursor: "pointer",
                      color: "#0000008F",
                    }}
                    onClick={() => {
                      setWorktypeValue([]);
                      setResultValueWorktype([]);
                    }}
                  />
                )}
                {worktypeValue.length > 0 ? (
                  <IoMdArrowDropup
                    style={{
                      cursor: "pointer",
                      fontSize: "24px",
                      color: "#0000008F",
                    }}
                    onClick={handleWorktypePopopToggle}
                  />
                ) : (
                  <IoMdArrowDropdown
                    style={{
                      cursor: "pointer",
                      fontSize: "24px",
                      color: "#0000008F",
                    }}
                    onClick={handleWorktypePopopToggle}
                  />
                )}{" "}
              </Box>
            </Box>
            <Box
              className="customScrollBar"
              sx={
                !filterWorktypePopop
                  ? { display: "none" }
                  : {
                      position: "absolute",
                      py: 2,
                      backgroundColor: "white",
                      zIndex: 10,
                      mt: 0.5,
                      borderRadius: "6px",
                      width: "250px",
                      height: "400px",
                      overflow: "auto",
                      boxShadow:
                        "rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px",
                      border:
                        "1px solid var(--black-black-20, rgba(3, 7, 30, 0.20))",
                    }
              }
            >
              {MergedWorkTypes &&
                MergedWorkTypes?.map((item, j) => {
                  const flt = selectedWorktypes.find((workt) => workt === item);

                  return (
                    <Box
                      key={j}
                      onClick={() => handleWorktypeListSelect(item, j)}
                      sx={{
                        display: "flex",
                        width: "100%",
                        height: "22px",
                        alignItems: "center",
                        borderRadius: 2,
                        px: 3,
                        py: 3,
                        my: 1.5,
                        cursor: "pointer",
                        backgroundColor: flt ? "#9152FF" : "inherit",
                        color: "white",
                      }}
                    >
                      <Box sx={{ textTransform: "uppercase" }}>
                        <Typography
                          sx={{
                            color: flt ? "white" : "black",
                            fontSize: "14px",
                            fontWeight: 400,
                          }}
                        >
                          {item?.name}
                        </Typography>
                      </Box>
                    </Box>
                  );
                })}
            </Box>
          </Box>

          {/* this is People Filter */}
          <Box
            sx={{
              width: "230px",
              mt: 2,
              mx: 1,
              borderBottom:
                selectedPeople?.length > 0
                  ? "2px solid #711FFF"
                  : "2px solid lightgray",
              position: "relative",
              "&:hover": { borderBottom: "2px solid #711FFF" },
            }}
            ref={peopleRef}
          >
            <Typography
              sx={{
                color: selectedPeople?.length > 0 ? "#711FFF" : "#00000099",
                fontWeight: "400",
              }}
            >
              Filter By People
            </Typography>

            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box
                className="customScrollBar"
                sx={{
                  display: "flex",
                  overflow: "auto",
                  width: "180px",
                  height: "35px",
                }}
              >
                {selectedPeople?.length > 0 ? (
                  selectedPeople?.map((v, i) => {
                    return (
                      <Box
                        sx={{
                          backgroundColor: "#00000014",
                          borderRadius: "20px",
                          px: 0.5,
                          mb: 0.5,
                          display: "flex",
                          alignItems: "center",
                          mr: 0.5,
                        }}
                      >
                        {false ? (
                          <img
                            src="https://www.google.com/url?sa=i&url=https%3A%2F%2Fstock.adobe.com%2Fsearch%2Fimages%3Fk%3Dadmin%2Bicon&psig=AOvVaw0nbHsIp7Zj0mSMlKD_DR2M&ust=1701256844633000&source=images&cd=vfe&ved=0CBIQjRxqFwoTCNiX0eLJ5oIDFQAAAAAdAAAAABAE"
                            alt="im"
                            style={{ marginRight: "5px" }}
                          />
                        ) : (
                          <HiMiniUserCircle style={{ fontSize: "24px" }} />
                        )}
                        <Typography
                          sx={{
                            width: "60px",
                            color: "black",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {v.first_name}
                        </Typography>
                        <IoIosCloseCircle
                          onClick={() => {
                            const updatedPeople = selectedPeople?.filter(
                              (flt) => flt.name !== v.name
                            );
                            setSelectedPeople(updatedPeople);

                            setFiltering((prevFiltering) => {
                              return {
                                ...prevFiltering,
                                people: prevFiltering?.people.filter(
                                  (person) => person !== v.id
                                ),
                              };
                            });
                          }}
                          style={{
                            marginLeft: "5px",
                            color: "#c2c3c4",
                            cursor: "pointer",
                            fontSize: "15px",
                          }}
                        />
                      </Box>
                    );
                  })
                ) : (
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "400",
                      width: "100%",
                      color: "#000000DE",
                      mt: 1,
                    }}
                    onClick={handlePeoplePopopToggle}
                  >
                    Select
                  </Typography>
                )}
              </Box>

              <Box>
                {selectedPeople?.length > 0 && (
                  <IoCloseSharp
                    style={{
                      marginRight: "10px",
                      cursor: "pointer",
                      color: "#0000008F",
                    }}
                    onClick={() => setSelectedPeople([])}
                  />
                )}
                {selectedPeople?.length > 0 ? (
                  <IoMdArrowDropup
                    style={{
                      cursor: "pointer",
                      fontSize: "24px",
                      color: "#0000008F",
                    }}
                    onClick={handlePeoplePopopToggle}
                  />
                ) : (
                  <IoMdArrowDropdown
                    style={{
                      cursor: "pointer",
                      fontSize: "24px",
                      color: "#0000008F",
                    }}
                    onClick={handlePeoplePopopToggle}
                  />
                )}{" "}
              </Box>
            </Box>

            <Box
              className="customScrollBar"
              sx={
                !filterPeoplePopop
                  ? { display: "none" }
                  : {
                      position: "absolute",
                      p: 2,
                      backgroundColor: "white",
                      zIndex: 10,
                      mt: 0.5,
                      width: "350px",
                      height: "310px",
                      overflow: "auto",
                      boxShadow:
                        "rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px",
                    }
              }
            >
              {Team?.map((item, index) => {
                return (
                  <Box
                    key={index}
                    onClick={() => handlePeopleListSelect(item, index)}
                    sx={{
                      display: "flex",
                      width: "98%",
                      height: "65px",
                      alignItems: "center",
                      borderRadius: 2,
                      px: 3,
                      py: 1,
                      my: 1.5,
                      cursor: "pointer",
                      boxShadow: "2px 2px 6px 0px #D9D9D9",
                      backgroundColor: "inherit",
                      color: "white",
                    }}
                  >
                    <HiMiniUserCircle
                      style={{
                        fontSize: "34px",
                        marginRight: "10px",
                        width: "42px",
                        height: "42px",
                        color: "lightgray",
                      }}
                    />
                    <Box sx={{ textTransform: "uppercase" }}>
                      <Typography
                        sx={{
                          color: "black",
                          fontSize: "12px",
                          fontWeight: 600,
                        }}
                      >
                        {item?.first_name}
                      </Typography>
                      <Typography sx={{ color: "#711FFF", fontSize: "12px" }}>
                        {item?.work_category}
                      </Typography>
                    </Box>
                  </Box>
                );
              })}
            </Box>
          </Box>
        </Box>
      </Grid>

      <div id="div-to-print">
        <Box
          sx={{
            p: "0.14rem 1rem",
            display: "flex",
            flexDirection: "column",
            m: "1rem 0",
          }}
        >
          <Typography
            sx={{
              color: "#310085",
              fontWeight: "600",
              fontSize: "1rem",
              textTransform: "uppercase",
              m: "0 1rem",
            }}
          >
            Data Overview
          </Typography>
          <BarCharts apiData={tasksToBeRendered}  />

          <div id="div-to-csv">
            {groupBy === 0 ? (
              <>
              {[''].map(()=>{
                                    const totalDuration = totalHoursAndMinutes(tasksToBeRendered);

                return (
                  <Box>
               <Box
                          sx={{
                            display: "flex",
                            direction: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                            my: 1.5,
                            mx: 2,
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "flex-start",
                            }}
                          >
                         
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              direction: "row",
                              justifyContent: "flex-end",
                              alignItems: "flex-end",
                            }}
                          >
                            <Box
                              sx={{
                                cursor: "pointer",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                background: "#F1F3F5",
                                borderRadius: "10px",
                                padding: "8px 19px",
                              }}
                            >
                              <p
                                style={{
                                  background: "#d2b8ff",
                                  borderRadius: "50%",
                                  padding: "3px 5px",
                                  marginRight: "6px",
                                  fontSize: "12px",
                                }}
                              >
                                {" "}
                                {tasksToBeRendered.length}{" "}
                              </p>
                              <Typography
                                sx={{
                                  color: "#310085",
                                  fontSize: "13px",
                                  fontWeight: 600,
                                }}
                              >
                                {" "}
                                TASKS{" "}
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                cursor: "pointer",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                background: "#F1F3F5",
                                borderRadius: "10px",
                                padding: "8px 19px",
                                marginLeft: "14px",
                              }}
                            >
                              <p
                                style={{
                                  padding: "3px 5px",
                                  marginRight: "6px",
                                  fontSize: "14px",
                                  fontWeight: 400,
                                }}
                              >
                                {" "}
                                Total Duration{" "}
                              </p>
                              <Typography
                                sx={{
                                  background: "#D2B8FF",
                                  color: "#310085",
                                  fontSize: "13px",
                                  borderRadius: "6px",
                                  fontWeight: 600,
                                  padding: "2px 10px",
                                }}
                              >
                                {" "}
                                {totalDuration.totalHours +
                                  "h" +
                                  ":" +
                                  totalDuration.totalMinutes +
                                  "m"}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                <TableContainer
                  component={Paper}
                  sx={{
                    width: "100%",
                    border: "1px solid #adadad",
                    borderRadius: "8px",
                    m: "1rem 0",
                  }}
                >
                  <Table>
                    <TimeTableHeader name={true} />
                    <DefaultGroupTableBody
                      tasksToBeRendered={tasksToBeRendered}
                      Projects={Projects}
                    />
                  </Table>
                </TableContainer>
                  </Box>
                )
              })
            }
              </>
            ) : (
              ""
            )}
            {groupBy === 1 ? (
              <>
                <Typography
                  sx={{ fontSize: "17px", fontWeight: 900, p: 1 }}
                >
                  Team Members{" "}
                </Typography>

                {Team?.length > 0 &&
                  Team?.map((item, i) => {
                    const totalDuration = totalHoursAndMinutes(item?.tasks);
                    return (
                      <Box
                        key={i}
                        sx={{
                          backgroundColor: "#F8F9FA",
                          p: 1,
                          borderRadius: 2,
                          mb: 10,
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            direction: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                            my: 1.5,
                            mx: 2,
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "flex-start",
                            }}
                          >
                            <HiMiniUserCircle
                              style={{ fontSize: "24px", mr: 2 }}
                            />
                            <Typography sx={{ fontSize: "16px" }}>
                              {item?.first_name}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              direction: "row",
                              justifyContent: "flex-end",
                              alignItems: "flex-end",
                            }}
                          >
                            <Box
                              sx={{
                                cursor: "pointer",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                background: "#F1F3F5",
                                borderRadius: "10px",
                                padding: "8px 19px",
                              }}
                            >
                              <p
                                style={{
                                  background: "#d2b8ff",
                                  borderRadius: "50%",
                                  padding: "3px 5px",
                                  marginRight: "6px",
                                  fontSize: "12px",
                                }}
                              >
                                {" "}
                                {item.tasks.length}{" "}
                              </p>
                              <Typography
                                sx={{
                                  color: "#310085",
                                  fontSize: "13px",
                                  fontWeight: 600,
                                }}
                              >
                                {" "}
                                TASKS{" "}
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                cursor: "pointer",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                background: "#F1F3F5",
                                borderRadius: "10px",
                                padding: "8px 19px",
                                marginLeft: "14px",
                              }}
                            >
                              <p
                                style={{
                                  padding: "3px 5px",
                                  marginRight: "6px",
                                  fontSize: "14px",
                                  fontWeight: 400,
                                }}
                              >
                                {" "}
                                Total Duration{" "}
                              </p>
                              <Typography
                                sx={{
                                  background: "#D2B8FF",
                                  color: "#310085",
                                  fontSize: "13px",
                                  borderRadius: "6px",
                                  fontWeight: 600,
                                  padding: "2px 10px",
                                }}
                              >
                                {" "}
                                {totalDuration.totalHours +
                                  "h" +
                                  ":" +
                                  totalDuration.totalMinutes +
                                  "m"}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                        <TableContainer
                          component={Paper}
                          sx={{
                            width: "100%",
                            border: "1px solid #adadad",
                            borderRadius: "8px",
                            m: "1rem 0",
                          }}
                        >
                          <Table>
                            <TimeTableHeader />
                            <TeamMemberGroupTableBody item={item} Projects={Projects} />
                          </Table>
                        </TableContainer>
                      </Box>
                    );
                  })}
              </>
            ) : (
              ""
            )}
            {groupBy === 2 ? (
              <>
                <Typography sx={{ fontSize: "17px", fontWeight: 900, p: 1 }}>
                  WorkTypes{" "}
                </Typography>

                {UpdatedworktypeTasks?.length > 0 &&
                  UpdatedworktypeTasks?.map((item, i) => {
                    const totalDuration = totalHoursAndMinutes(item.tasks);
                    return (
                      <Box
                        key={i}
                        sx={{
                          backgroundColor: "#F8F9FA",
                          p: 1,
                          borderRadius: 2,
                          mb: 10,
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            direction: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                            my: 1.5,
                            mx: 2,
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "flex-start",
                            }}
                          >
                            <Typography sx={{ fontSize: "16px" }}>
                              {item?.name}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              direction: "row",
                              justifyContent: "flex-end",
                              alignItems: "flex-end",
                            }}
                          >
                            <Box
                              sx={{
                                cursor: "pointer",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                background: "#F1F3F5",
                                borderRadius: "10px",
                                padding: "8px 19px",
                              }}
                            >
                              <p
                                style={{
                                  background: "#d2b8ff",
                                  borderRadius: "50%",
                                  padding: "3px 5px",
                                  marginRight: "6px",
                                  fontSize: "12px",
                                }}
                              >
                                {" "}
                                {item.tasks.length}{" "}
                              </p>
                              <Typography
                                sx={{
                                  color: "#310085",
                                  fontSize: "13px",
                                  fontWeight: 600,
                                }}
                              >
                                {" "}
                                TASKS{" "}
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                cursor: "pointer",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                background: "#F1F3F5",
                                borderRadius: "10px",
                                padding: "8px 19px",
                                marginLeft: "14px",
                              }}
                            >
                              <p
                                style={{
                                  padding: "3px 5px",
                                  marginRight: "6px",
                                  fontSize: "14px",
                                  fontWeight: 400,
                                }}
                              >
                                {" "}
                                Total Duration{" "}
                              </p>
                              <Typography
                                sx={{
                                  background: "#D2B8FF",
                                  color: "#310085",
                                  fontSize: "13px",
                                  borderRadius: "6px",
                                  fontWeight: 600,
                                  padding: "2px 10px",
                                }}
                              >
                                {" "}
                                {totalDuration.totalHours +
                                  "h" +
                                  ":" +
                                  totalDuration.totalMinutes +
                                  "m"}                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                        <TableContainer
                          component={Paper}
                          sx={{
                            width: "100%",
                            border: "1px solid #adadad",
                            borderRadius: "8px",
                            m: "1rem 0",
                          }}
                        >
                          <Table>
                            <TimeTableHeader worktype={true} />
                            <WorkTypeGroupTableBody item={item} Projects={Projects} />
                          </Table>
                        </TableContainer>
                      </Box>
                    );
                  })}
              </>
            ) : (
              ""
            )}
          </div>
        </Box>
      </div>
    </>
    :
    <PreLoading/>
  );
};

export default Time;
