import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Grid, Box, TextField, Alert, Modal, Typography } from '@mui/material';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../Assets/MobileLogo.png';
import createAccountsvg from '../Assets/CreateAccountSvg.svg';
import { ENDPOINT } from '../../../pages/Opportunity/Redux/oppor';
import { registerNewUser } from '../Redux/auth';
import { Header } from '../Login/Auth';
import { PreLoader1 } from '../../Misc';
import { Terms } from '../../../pages/User/Accountbilling/AccountBilling';

function RegistrationForm() {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [re_password, setRePassword] = useState();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [condition, setConditions] = useState(false);
  const [isExceptions, setIsExceptions] = useState(false);
  const [exceptions, setExceptions] = useState();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const handleFirstName = (e) => {
    setFirstName(e.target.value);
  };
  const handleLastName = (e) => {
    setLastName(e.target.value);
  };
  const handlePassword = (e) => {
    setPassword(e.target.value);
  };
  const handleEmail = (e) => {
    setEmail(e.target.value);
  };
  const handleCondition = (e) => {
    if (e.target.checked) {
      setConditions(true);
      setExceptions('');
      setIsExceptions(false);
    } else {
      setConditions(false);
    }
  };
  const handleRePassword = (e) => {
    setRePassword(e.target.value);
  };

  const sx = {
    width: '100%',
    margin: '0.70rem 0',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#ADADAD',
      },
      '&:hover fieldset': {
        borderColor: '#711fff',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#5a0bdf',
      },

      '&.Mui-error fieldset': {
        borderColor: 'red',
      },

      '&.Mui-disabled fieldset': {
        borderColor: 'grey',
      },
    },
    '& .MuiFormLabel-root': {
      color: '#03071e66',
    },
    '& .MuiFormLabel-root.Mui-focused': {
      color: '#5a0bdf',
    },
    '& .MuiFormLabel-root.Mui-error': {
      color: 'red',
    },
    '& .css-1d3z3hw-MuiOutlinedInput-notchedOutline': {
      top: '-1px',
    },
  };
  const sendSubmition = async (e) => {
    e.preventDefault();
    if (email && firstName && lastName && password && re_password !== '') {
      if (condition) {
        if (password === re_password) {
          setLoading(true);
          setIsExceptions(false);
          setExceptions('');
          const newUser = {
            first_name: firstName,
            last_name: lastName,
            email,
            username: email,
            password,
            re_password,
          };
          const response = await fetch(`${ENDPOINT}/auth/users/`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(newUser),
          });
          const data = await response.json();
          if (!response.ok) {
            setLoading(false);
            setIsExceptions(true);
            Object.keys(data).forEach((key) => {
              setExceptions(data[key]);
            });
          } else {
            dispatch(registerNewUser(data, newUser));
            navigate('/check');
          }
        } else {
          setIsExceptions(true);
          setExceptions('Passwords do not match');
        }
      } else {
        setIsExceptions(true);
        setExceptions('You must agree to Terms and Conditions');
      }
    } else {
      setIsExceptions(true);
      setExceptions('All fields are required');
      setPassword('0');
    }
  };

  //  this is modal terms
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: '95%', md: '70%' },
    bgcolor: 'background.paper',
    borderRadius: 2,
    boxShadow: 24,
    p: 4,
    height: '100vh',
    overflowY: 'auto',
  };

  const [openTerms, setOpenTerms] = useState(false);
  const handleOpenTerms = () => setOpenTerms(true);
  const handleCloseTerms = () => setOpenTerms(false);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: { lg: 'flex-start', md: 'flex-start', xs: 'center' },
          width: { lg: '60%', md: '70%', xs: '100%' },
          padding: '0 1.5rem',
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            sendSubmition(e);
          }
        }}
      >
        <TextField
          type="text"
          variant="outlined"
          label="First Name"
          id="FirstName"
          onChange={handleFirstName}
          onInvalid={(e) => {
            e.target.setCustomValidity('Name Cannot Contain Numbers');
          }}
          sx={sx}
          onInput={(e) => {
            e.target.setCustomValidity('');
            e.target.reportValidity();
            setExceptions('');
            setIsExceptions(false);
          }}
          pattern="[A-Za-z ]+"
        />

        <TextField
          type="text"
          variant="outlined"
          sx={sx}
          label="Last Name"
          id="LastName"
          onChange={handleLastName}
          onInvalid={(e) => {
            e.target.setCustomValidity('Name Cannot Contain Numbers');
          }}
          onInput={(e) => {
            e.target.setCustomValidity('');
            e.target.reportValidity();
            setExceptions('');
            setIsExceptions(false);
          }}
          pattern="[A-Za-z ]+"
        />

        <TextField
          type="email"
          variant="outlined"
          sx={sx}
          label="Email Address"
          id="EmailAddress"
          onChange={handleEmail}
          onInvalid={(e) => {
            e.target.setCustomValidity('Please enter a valid Email Address');
          }}
          onInput={(e) => {
            e.target.setCustomValidity('');
            e.target.reportValidity();
            setExceptions('');
            setIsExceptions(false);
          }}
        />

        <TextField
          type="password"
          variant="outlined"
          sx={sx}
          label="Password"
          id="Password"
          onChange={handlePassword}
          onInvalid={(e) => {
            e.target.setCustomValidity(
              'Password must be at least 8 characters and contain symbols or numbers',
            );
          }}
          onInput={(e) => {
            e.target.setCustomValidity('');
            e.target.reportValidity();
            setExceptions('');
            setIsExceptions(false);
          }}
          minLength="8"
        />

        <TextField
          type="password"
          variant="outlined"
          sx={sx}
          label="Repeat Password"
          id="RepeatPassword"
          onChange={handleRePassword}
          onInvalid={(e) => {
            e.target.setCustomValidity('Passwords do not match');
          }}
          onInput={(e) => {
            e.target.setCustomValidity('');
            if (password !== re_password) {
              e.target.reportValidity();
            }
            setExceptions('');
            setIsExceptions(false);
          }}
        />

        <article className="dFlex m1">
          <input type="checkbox" onChange={handleCondition} />{' '}
          <span className="fstxt" style={{ marginLeft: '4px' }}>
            I agree to
            <Typography
              sx={{
                color: '#711FFF',
                cursor: 'pointer',
                display: 'inline-block',
                ml: 1,
                '&:hover': { textDecoration: 'underline' },
              }}
              onClick={handleOpenTerms}
            >
              Terms and Conditions
            </Typography>
          </span>
        </article>
        {isExceptions && (
          <Alert severity="error" sx={{ width: '100%' }}>
            {exceptions}
          </Alert>
        )}
        <button
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          type="submit"
          className="btn inline"
          onClick={sendSubmition}
        >
          {loading ? (
            <Box sx={{ width: '40px', height: '40px' }}>
              <PreLoader1 />
            </Box>
          ) : (
            'SIGN UP'
          )}{' '}
        </button>
      </Box>
      <div>
        <Modal
          open={openTerms}
          onClose={handleCloseTerms}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Terms />
          </Box>
        </Modal>
      </div>
    </>
  );
}

function Signup() {
  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const handleResize = () => {
    setScreenSize(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <Grid
      container
      component="main"
      sx={{ height: screenSize < 900 ? '78vh' : '100vh' }}
    >
      {screenSize >= 900 && (
        <Grid
          item
          xs={0}
          sm={0}
          md={7}
          lg={7}
          xl={7}
          xxl={7}
          sx={{
            backgroundColor: '#47d1fc',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            overflow: 'hidden',
          }}
        >
          <Box sx={{ width: { xs: '70%', lg: '60%', xl: '50%', xxl: '50%' } }}>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <img src={logo} width="100%" height="auto" alt="Mobile Logo" />
            </Box>
            <Box sx={{ my: 3 }}>
              <Typography
                sx={{
                  fontSize: '20px',
                  lineHeight: '26px',
                  fontWeight: '400',
                  fontFamily: 'open Sans',
                  color: 'white',
                }}
              >
                Putting Profits Into Your Workflow Tools!
              </Typography>
              <Typography
                sx={{
                  fontSize: '20px',
                  lineHeight: '26px',
                  fontWeight: '400',
                  fontFamily: 'open Sans',
                  color: 'white',
                  mt: 1,
                }}
              >
                - opportunities & sales
              </Typography>
              <Typography
                sx={{
                  fontSize: '20px',
                  lineHeight: '26px',
                  fontWeight: '400',
                  fontFamily: 'open Sans',
                  color: 'white',
                  mt: 1,
                }}
              >
                - project, retainer & task management
              </Typography>
              <Typography
                sx={{
                  fontSize: '20px',
                  lineHeight: '26px',
                  fontWeight: '400',
                  fontFamily: 'open Sans',
                  color: 'white',
                  mt: 1,
                }}
              >
                - time tracking
              </Typography>
              <Typography
                sx={{
                  fontSize: '20px',
                  lineHeight: '26px',
                  fontWeight: '400',
                  fontFamily: 'open Sans',
                  color: 'white',
                  mt: 1,
                }}
              >
                - team communications
              </Typography>
            </Box>
            <Box>
              <img
                src={createAccountsvg}
                alt="Login SVG"
                width="100%"
                height="auto"
                style={{
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                }}
              />
            </Box>
          </Box>
        </Grid>
      )}

      {screenSize < 900 && <Header />}
      <Grid
        item
        container
        xs={12}
        sm={12}
        md={5}
        lg={5}
        xl={5}
        sx={{
          gap: '0.45rem',
          padding: '0.45rem 0',
          justifyContent: 'center',
          position: 'relative',
        }}
        direction="column"
        alignItems="center"
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            width: { lg: '60%', md: '70%', xs: '100%' },
            padding: '0 1.5rem',
          }}
        >
          <Link exact to="/Login">
            <ArrowBackIcon
              sx={{
                position: 'absolute',
                top: { xl: '10%', lg: '7%', md: '7%', sm: '3%', xs: '3%' },
                left: { xl: '12%', lg: '12%', md: '10%', sm: '2%', sx: '2%' },
                color: '#711fff',
                cursor: 'pointer',
              }}
            />
          </Link>
          <p className="sub-heading">Create Account</p>

          <p
            className="fstxt"
            style={{
              marginBottom: '0px',
            }}
          >
            Welcome to Net Net
          </p>
        </Box>
        <RegistrationForm />
        <p className="fstxt">
          Already have Account?{' '}
          <Link className="link" exact to="/login">
            Sign In
          </Link>
        </p>
      </Grid>
    </Grid>
  );
}

export default Signup;
