import { React, useEffect, useState, useRef, createRef } from 'react';
import { useScreenshot } from 'use-react-screenshot';
import {
  Box,
  Divider,
  Grid,
  List,
  ListItem,
} from '@mui/material';
import { Gantt, ViewMode } from 'gantt-task-react';
import { PreLoading } from '../../Contacts/People/EditContact';
import 'gantt-task-react/dist/index.css';
import moment from 'moment';

const CustomGantt = ({ data, setData, view, setView }) => {
  if (!data) {
    return 'No Tasks defined';
  }

  function getStartEndDateForProject(tasks, projectId) {
    const projectTasks = tasks.filter((t) => t.project === projectId);
    let { start } = projectTasks[0];
    let { end } = projectTasks[0];
    for (let i = 0; i < projectTasks.length; i++) {
      const task = projectTasks[i];
      if (start.getTime() > task.start.getTime()) {
        start = task.start;
      }
      if (end.getTime() < task.end.getTime()) {
        end = task.end;
      }
    }
    return [start, end];
  }
  const [tasks, setTasks] = useState(data);

  useEffect(() => {
    setData(tasks);
  }, [tasks]);
  const [isChecked, setIsChecked] = useState(true);
  let columnWidth = 90;
  if (view === ViewMode.Month) {
    columnWidth = 360;
  } else if (view === ViewMode.Week) {
    columnWidth = 260;
  }
  const handleTaskChange = (task) => {
    let newTasks = tasks.map((t) => (t.id === task.id ? task : t));
    if (task.project) {
      const [start, end] = getStartEndDateForProject(newTasks, task.project);
      const project =
        newTasks[newTasks.findIndex((t) => t.id === task.project)];
      if (
        project.start.getTime() !== start.getTime() ||
        project.end.getTime() !== end.getTime()
      ) {
        const changedProject = { ...project, start, end };
        newTasks = newTasks.map((t) =>
          t.id === task.project ? changedProject : t
        );
      }
    }

    setTasks((_) => {
      const isDependent = newTasks.some((entite) =>
        entite.dependencies.includes(task.id)
      );
      if (!isDependent) {
        return newTasks;
      }
      const parentElement = newTasks.find((it) =>
        it.dependencies.includes(task.id)
      );
      const date = task.end;
      const index = newTasks.indexOf(parentElement);
      newTasks[index].start = moment(date).add(2, 'days').toDate();
      newTasks[index].end = moment(date).add(9, 'days').toDate();

      return newTasks;
    });
  };
  const handleTaskDelete = (task) => {
    const conf = window.confirm(`Are you sure about ${task.name} ?`);
    if (conf) {
      setTasks(tasks.filter((t) => t.id !== task.id));
    }
    return conf;
  };
  const handleProgressChange = async (task) => {
    setTasks(tasks.map((t) => (t.id === task.id ? task : t)));
  };
  const handleDblClick = (task) => {
    console.log(`On Double Click event Id:${task.id}`);
  };
  const handleSelect = (task, isSelected) => {
    console.log(`${task.name} has ${isSelected ? 'selected' : 'unselected'}`);
  };
  const handleExpanderClick = (task) => {
    setTasks(tasks.map((t) => (t.id === task.id ? task : t)));
  };

  return (
    <Gantt
      tasks={tasks}
      viewMode={view}
      onDateChange={handleTaskChange}
      onDelete={handleTaskDelete}
      onProgressChange={handleProgressChange}
      onDoubleClick={handleDblClick}
      onSelect={handleSelect}
      onExpanderClick={handleExpanderClick}
      ganttHeight={800}
      columnWidth={columnWidth}
      rowHeight={92}
      barCornerRadius={8}
      barFill={60}
      fontFamily="Open Sans"
      fontSize="1rem"
      arrowColor="#00E6B8"
 
      TaskListHeader={() => (
        <Box
          sx={{
            backgroundColor: '#f1f3f5',
            height: '50px',
            fontFamily: 'Open Sans',
            fontSize: '1.1rem',
            fontWeight: '600',
            border: '1px solid #d9d9d9',
            borderTopLeftRadius: '8px',
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            borderBottom: '0.5px solid #000',
            padding: '21.5px 27px 21.5px 30px',
          }}
        >
          Deliverables
        </Box>
      )}
      TaskListTable={({
        tasks,
      }) => (
        <List
          sx={{
            fontFamily: 'Open Sans',
            fontSize: '1.1rem',
            fontWeight: '400',
            width: '100%',
            paddingTop: 0,
            padding: 0,
          }}
        >
          {tasks.map((item, i) => (
            <>
              <ListItem
                key={i + 64}
                sx={{
                  width: '400px',
                  height: '91px',
                  padding: '21.5px 27px 21.5px 30px',
                  backgroundColor: '#fff',
                  '&:nth-of-type(even)': {
                    backgroundColor: '#f1f3f5',
                  },
                }}
              >
                {item.name}
              </ListItem>
              <Divider />
            </>
          ))}
        </List>
      )}
    />
  );
};

const FourthStep = ({
  formData,
  setFormData,
  temp,
  setTemp,
  setPic,
  view,
  setView,
  dileverable,
}) => {
  const [tasks, setTasks] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const gridRef = useRef(null);

  useEffect(() => {
    const { est_finish_date, est_start } = formData;
    const data = dileverable.map((item, index) => {
      const start = item.deliverable_startdate
        ? moment(item.deliverable_startdate).toDate()
        : moment(formData.est_startdate.split('T')[0]).toDate();
      const end = item.deliverable_enddate
        ? moment(item.deliverable_enddate).toDate()
        : moment(start).add(7, 'days').toDate();
        const dependency = dileverable.find((ob)=> ob.id === item.deliverable_dependency)
      
      return {
        id: item.deliverable_name,
        name: item.deliverable_name,
        start,
        end,
        styles: {
          backgroundColor: '#711fff',
          backgroundSelectedColor: '#711fff',
          progressColor: '#711fff',
          progressSelectedColor: '#711fff',
        },
        progress: 0,
        dependencies: item.deliverable_dependency && dependency ? dependency.deliverable_name.split(',') : [],
        type: 'task',
        project: formData.name,
        displayOrder: index + 1,
        dentifier: item.id,
      };
    });

    setTasks(
      data.sort((a, b) => a.dependencies.length - b.dependencies.length)
    );
  }, []);

  useEffect(() => {
    const requiredFields = ['id', 'name', 'start', 'progress', 'displayOrder'];
    const hasRequiredFields = tasks.every((t) =>
      requiredFields.every((field) => typeof t[field] !== 'undefined')
    );
    if (hasRequiredFields) {
      setTimeout(() => {
        setIsLoading(false);
      }, 3000);
      setTemp(tasks);
    } else {
      console.log('Tasks do not have Required Fields', tasks);
    }
  }, [tasks]);

  return !isLoading ? (
    <>
      <Grid
        className="gantt-target"
        ref={gridRef}
        item
        container
        direction="column"
        xl={12}
      >
        <CustomGantt
          data={tasks}
          setData={setTasks}
          view={view}
          setView={setView}
        />
      </Grid>
    </>
  ) : (
    <PreLoading />
  );
};
export default FourthStep;
