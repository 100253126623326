import React from 'react';
import { Box } from '@mui/material';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import Grid from '@mui/material/Grid';

const VideoThumbnail = ({ thumbnail, title, subTitle }) => (
  <Grid direction="column">
    <Grid
      item
      container
      direction="column"
      style={{
        position: 'relative',
      }}
    >
      <img src={thumbnail} alt={thumbnail} height='200px' width='400px' />
      <PlayCircleIcon
        sx={{
          fontSize: 50,
          color: '#711fff',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '32px',
          height: '32px',
        }}
      />
    </Grid>
    <Grid
      item
      container
      direction="column"
      style={{
        backgroundColor: '#FFFFFF',
        padding: '0.5rem',
      }}
    >
      <h4
        style={{
          fontFamily: 'Open Sans',
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: '12px',
          lineHeight: '12px',
        }}
      >
        {subTitle}
      </h4>
      <h3
        style={{
          fontFamily: 'Open Sans',
          fontStyle: 'normal',
          fontWeight: 500,
          fontSize: '0.8rem',
        }}
      >
        {title}
      </h3>
    </Grid>
  </Grid>
);

export default VideoThumbnail;
