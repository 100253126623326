import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { divide, subtract } from 'mathjs';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { sumPriceToCustomer } from '../../Opportunity/estSteps/FifthStep';
import Activate from './_assets/Activate.png';
import Pending from './_assets/Pending.png';
import { ENDPOINT } from '../../Opportunity/Redux/oppor';
import { useDispatch } from 'react-redux';
import { getProjects } from '../proRedux';
import PreLoader from '../../../Componenets/Misc';

function calculateTotalMargin(items) {
  const totalMargin = items.reduce((sum, item) => {
    const margin = Number(item.price_to_customer) - Number(item.our_price);
    return sum + margin;
  }, 0);

  return totalMargin;
}

function formatMoneyString(numberString) {
  if (typeof numberString !== 'string') {
    return '$0';
  }
  if (!/^\d+(\.\d{1,2})?$/.test(numberString)) {
    return '$0';
  }

  const num = parseFloat(numberString).toFixed(2);

  const parts = num.split('.');
  const integerPart = parts[0];
  const decimalPart = parts[1];

  const formattedIntegerPart = integerPart.replace(
    /\B(?=(\d{3})+(?!\d))/g,
    ','
  );
  const formattedMoneyString = `$${formattedIntegerPart}.${decimalPart}`;

  return formattedMoneyString;
}

function calculateDurationFromDil(deliverables) {
  if (!deliverables || !Array.isArray(deliverables) || !deliverables.length) {
    return { days: 0, weeks: 0, months: 0 };
  }

  let startDate = new Date(deliverables[0].deliverable_startdate);
  let endDate = new Date(deliverables[0].deliverable_enddate);

  for (let i = 1; i < deliverables.length; i++) {
    const start = new Date(deliverables[i].deliverable_startdate);
    const end = new Date(deliverables[i].deliverable_enddate);
    if (start < startDate) {
      startDate = start;
    }
    if (end > endDate) {
      endDate = end;
    }
  }

  const days = Math.floor((endDate - startDate) / (1000 * 60 * 60 * 24));
  const weeks = Math.floor(days / 7);
  const months = Math.floor(days / 30);

  return { days, weeks, months };
}

function getDurationFromHours(hours) {
  if (!Number.isInteger(hours) || hours < 0) {
    throw new Error('Input must be a non-negative integer');
  }

  const duration = moment.duration(hours, 'hours');

  const months = Math.floor(duration.asMonths());
  duration.subtract(moment.duration(months, 'months'));

  const weeks = Math.floor(duration.asWeeks());
  duration.subtract(moment.duration(weeks, 'weeks'));

  const days = Math.floor(duration.asDays());

  return {
    months,
    weeks,
    days,
  };
}
const calculateTotalPrice = (array) => {
  return array.reduce((accumulator, item) => {
    return accumulator + Number(item.price_to_customer);
  }, 0);
};


export const ActiveProjectAlert = ({ setSureDelete, currentTarget, SetAlertShow, AlertMessage, setAlertMessage }) => {
  const navigate = useNavigate();
  
  let typeMessage = AlertMessage.length>0 && AlertMessage?.includes('Plan')
  
  const handleCompletion = async () => {

    SetAlertShow(false)
    setAlertMessage('')
    navigate('/Account_Billing', {state : {type: typeMessage}})
   
  };

  return (
    <section
      className="loading"
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100%',
        backgroundColor: 'rgba(255,255,255,0.5)',
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 999,
      }}
    >
      <Box
        sx={{
          width: '850px',
          height: '320px',
          background: 'rgba(241, 243, 245, 0.50)',
          padding: '2rem',
          borderRadius: '8px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            width: '95%',
            height: '95%',
            backgroundColor: 'rgba(255,255,255)',
            border: '1px solid #d9d9d9',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            borderRadius: '8px',
            padding: '0.68rem 1rem',
            justifyContent: 'space-around',
            boxShadow: '2px 2px 6px 0px #D9D9D9',
            gap: '10px'
          }}
        >
          <p
            style={{
              fontSize: '0.9rem',
              fontWeight: '600',
              fontFamily: 'Open Sans, sans-serif',
              color: '#023fe3',
            }}
          >
            CREATE PROJECT 

          </p>
          <p className="fstxt" style={{ width: '80%', textAlign: 'center', fontWeight: '400', }}>
            {AlertMessage}
          </p>
      
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-around',
              alignItems: 'center',
              width: '100%',
              margin: '0 auto'
            }}
          >
            <button
              className="cancel-btn"
              onClick={() => {
                SetAlertShow(false)
                setAlertMessage('') 
              }}
            >
              Cancel
            </button>
            <button
              style={{
                fontFamily: '\'Neue Haas Display Pro\', \'Open Sans\', sans-serif',
                fontWeight: '500',
                padding: '1rem 0.5rem',
                backgroundColor: '#023fe3',
                borderRadius: '5px',
                color: 'var(--white-100)',
                border: 'none',
                cursor: 'pointer',
                textAlign: 'center',
                fontSize: '1rem',
                padding: '0rem 2rem',
                height: '38px'
              }}
              onClick={handleCompletion}
            >
               Add {typeMessage ?  'Subscription' : 'Payment' }
            </button>
          </Box>
        </Box>
      </Box>
    </section>
  );
};



const NetTable = ({
  formData,
  GI,
  estimate_hardcost,
  dileverable,
  setErrorState,
}) => {
  const [sub, setSub] = useState(0);

  useEffect(() => {
    if (
      estimate_hardcost &&
      Array.isArray(estimate_hardcost) &&
      estimate_hardcost.length
    ) {
      setSub(sumPriceToCustomer(estimate_hardcost));
    }
  }, [estimate_hardcost]);

  const [gross, setGross] = useState(GI);

  useEffect(() => {
    if (sub) {
      setGross(subtract(Number(GI), Number(sub)));
    }
  }, [sub, GI]);

  const navigate = useNavigate();
  const token = useSelector((state) => state.auth.user.access);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false)
  const [pendLoading, setPendLoading] = useState(false)

  /////////////////////////////////////////////////////////////////////////

  const [AlertShow, SetAlertShow] = useState(false)
  const [AlertMessage, setAlertMessage] = useState('')

  return (
    <>
    {
     AlertShow &&
    <ActiveProjectAlert SetAlertShow={SetAlertShow} AlertMessage={AlertMessage} setAlertMessage={setAlertMessage} />
    }
    <Grid
      container
      sx={{ padding: '1.44rem 1.88rem', gap: '1.5rem' }}
      direction="column"
      alignItems="flex-start"
    >
      <Box
        sx={{
          backgroundColor: loading ? '#711fff' : '#023fe3',
          transition:'ease-out',
          textAlign: 'center',
          width: '100%',

          borderRadius: '8px',
          padding:  loading ? '10px 1.88rem':'2.44rem ',
          display: 'flex',
          gap: '1rem',
          alignItems: 'center',
          color: '#fff',
          fontWeight: '600',
          fontFamily: 'Open Sans',
          justifyContent: 'center',
          cursor: 'pointer',
          fontSize: '1.5rem',
        }}
        onClick={async (e) => {
          setLoading(true)
          const shall = {...formData}
          delete shall.deliverable_project

          const response =  await fetch(
            `${ENDPOINT}/api/project/update/${formData.id}/`,
            {
              method: 'PUT',
              headers: {
                Authorization: `JWT ${token}`,
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
              
                status: 'active',
                creation_wizard_completed : true,
              }),
            }
          );
          const data = await response.json();
          if (!response.ok) {
            setErrorState({
              error: true,
              message: data && data?.title && data?.title['Title Error'] ? data.title['Title Error'] : 'Could not Activate Your Project.',
            });
            setLoading(false)



            if (data?.title?.['Title Error']) {
              for (let i in data.title) {
                let msg = data[i]
                setErrorState({
                  error: true,
                  message: data && data?.title ? data?.title?.[i] : 'Could not Activate Your Project.',
                });
              }
            }
            else if (data?.['create project']){
    
              for (let i in data) {
                let msg = data[i]
                setAlertMessage(msg)
              }
              SetAlertShow(true)
            }
            else {
              for (let i in data) {
    
                let msg = data[i]
                setErrorState({
                  error: true,
                  message: data && msg ? msg : 'Could not Activate Your Project.',
                });
              } }
          } else {
            dispatch(getProjects({ access_token: token }));
            navigate('/Projects');
            setLoading(false)
          }
        }}
      >
        {!loading ? <><img src={Activate} alt="Activate" />
          ACTIVATE JOB</> : <Box sx={{ width: '80px', height: '80px', margin: '0rem 0.1rem', }}> <PreLoader /> </Box>}
      </Box>

      <Box
        sx={{
          backgroundColor: pendLoading ? '#711fff' : '#9152FF',
          textAlign: 'center',
          width: '100%',
          borderRadius: '8px',
          padding:  pendLoading ? '10px 1.88rem':'2.44rem  1.88rem',
          display: 'flex',
          gap: '1rem',
          alignItems: 'center',
          color: '#fff',
          fontWeight: '600',
          fontFamily: 'Open Sans',
          justifyContent: 'center',
          cursor: 'pointer',
          fontSize: '1.5rem',
        }}
        onClick={async (e) => {
          setPendLoading(true)
          const response = await fetch(
            `${ENDPOINT}/api/project/update/${formData.id}/`,
            {
              method: 'PUT',
              headers: {
                Authorization: `JWT ${token}`,
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
              
                status: 'inactive',
                creation_wizard_completed : true,
              }),
            }
          );
          const data = await response.json();
        
          if (!response.ok) {
            setErrorState({
              error: true,
              message: data && data?.title && data?.title['Title Error'] ? data.title['Title Error'] : 'Could not Activate Your Project.',
            });
            setPendLoading(false)

           
          } else {
            dispatch(getProjects({ access_token: token }));
            navigate('/Projects');
            setPendLoading(false)
          }
        }}
      >
       { !pendLoading ? <><img src={Pending} alt="Activate" />
        SEND TO PENDING </>: <Box sx={{ width: '80px', height: '80px', margin: '0rem 0.1rem', }}> <PreLoader /> </Box>}
      </Box>
    </Grid>
    </>
  );
};

export { formatMoneyString, getDurationFromHours };


const TeamKpi = ({ formData, dileverable, GI }) => {
  const subSx = { fontSize: '0.9rem', color: '#626574' };
  const totalHours = formData.dileverable
    ? formData.dileverable.reduce((acc, curr) => {
      if (
        curr &&
        curr.deliverable_worktype &&
        Array.isArray(curr.deliverable_worktype)
      ) {
        curr.deliverable_worktype.forEach((workType) => {
          if (workType && workType.hours) {
            const hoursAsNumber = Number(workType.hours);
            if (!isNaN(hoursAsNumber)) {
              acc += hoursAsNumber;
            }
          }
        });
      }
      return acc;
    }, 0)
    : null;
  const totalDuration = calculateDurationFromDil(dileverable);
  const avg = Math.round(divide(Number(GI), Number(totalDuration.months)));
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        width: '100%',
        borderRadius: '8px',
        padding: '0.44rem 0.88rem',
        border: '1px solid #a9a9a9',
        gap: '1rem',
        backgroundColor: '#fafafc',
      }}
    >
      <h5 className="fstxt3" style={{ marginTop: '1rem' }}>
        Your Team KPI&apos;s
      </h5>
      <Box
        sx={{
          display: 'flex',
          width: '95%',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '1rem',
            width: '50%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: '#f1f3f5',
              borderRadius: '8px',
              flexDirection: 'column',
              padding: '0.44rem 0.88rem',
              width: '100%',
            }}
          >
            <div className="fstxt3">{totalHours ? totalHours : '0'}</div>
            <Typography sx={subSx}>Total hours</Typography>
          </Box>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '1rem',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              gap: '1rem',
              backgroundColor: '#fff',
              borderRadius: '8px',
            }}
          >
            <Box
              sx={{
                padding: '0.44rem 0.88rem',
                borderRight: '1px solid #a9aaaa',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
              }}
            >
              <div className="fstxt3">
                {totalDuration.months ? totalDuration.months : '0'}
              </div>
              <Typography sx={subSx}>months</Typography>
            </Box>
            <Box
              sx={{
                padding: '0.88rem',
                borderRight: '1px solid #a9aaaa',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
              }}
            >
              <div className="fstxt3">
                {totalDuration.weeks ? totalDuration.weeks : '0'}
              </div>
              <Typography sx={subSx}>weeks</Typography>
            </Box>
            <Box
              sx={{
                padding: '0.88rem',
                borderRight: '1px solid #a9aaaa',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
              }}
            >
              <div className="fstxt3">
                {totalDuration.days ? totalDuration.days : '0'}
              </div>
              <Typography sx={subSx}>days</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const WorkTypeLOE = ({ formData }) => {
  function getTotalHoursByWorkType(workTypes, deliverables) {
    const hoursByWorkType = {};
    workTypes.forEach((workType) => {
      hoursByWorkType[workType.id] = { name: workType.name, hours: 0 };
    });

    deliverables.forEach((deliverable) => {
      if (
        deliverable &&
        deliverable.deliverable_worktype &&
        Array.isArray(deliverable.deliverable_worktype)
      ) {
    
        deliverable.deliverable_worktype.forEach((workType) => {

          if (workType && workType.id) {
            const hours = Number(workType.hours);
            const workTypeId = Number(workType.id);

            if (hoursByWorkType[workTypeId] !== undefined) {
              hoursByWorkType[workTypeId].hours += hours;
            }
          }
        });
      }
    });

    const result = Object.values(hoursByWorkType).map(({ name, hours }) => ({
      name,
      hours,
    }));

    return result;
  }

  const userWorkTypes = useSelector((state) => state.Misc.workTypes);
  const dileverableWorkTypes = formData.dileverable ? formData.dileverable : [];

  const LOE =
    userWorkTypes && userWorkTypes.length
      ? getTotalHoursByWorkType(userWorkTypes, dileverableWorkTypes)
      : [];
  const NetHours = LOE.reduce((acc, { hours }) => acc + hours, 0);

  return (
    <Table>
      <TableHead>
        <TableRow
          sx={{
            background: '#e0e0df',
            borderRadius: '8px',
          }}
        >
          <TableCell align="left">
            <div className="fstxt" style={{ textAlign: 'left' }}>
              Team LOE Hours by Work Type
            </div>
          </TableCell>
          <TableCell align="left">
            <div className="fstxt" style={{ textAlign: 'left' }}>
              Total
            </div>
          </TableCell>
          <TableCell align="right">
            <div className="fstxt" style={{ textAlign: 'right' }}>
              %
            </div>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {LOE.map((item, i) => {
          if (!Math.round((Number(item.hours) / NetHours) * 100)) {
            return;
          }
          return (
            <TableRow key={i}>
              <TableCell align="left">{item.name}</TableCell>
              <TableCell align="left">{Number(item.hours)}</TableCell>
              <TableCell align="right">
                {Math.round((Number(item.hours) / NetHours) * 100)}%
              </TableCell>
            </TableRow>
          );
        })}
        <TableRow sx={{ backgroundColor: '#a8d6ff' }}>
          <TableCell align="left">{'  '}</TableCell>
          <TableCell align="left">{NetHours}</TableCell>
          <TableCell align="right">{'100%'}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

const PFifth = ({
  formData,
  setFormData,
  dileverable,
  GI,
  estimate_hardcost,
  setEstHardCost,
  setGI,
  setErrorState,
}) => {
  useEffect(() => {
    if (Array.isArray(estimate_hardcost) && estimate_hardcost.length && GI) {
      const netPrice = calculateTotalPrice(estimate_hardcost);
      setGI(Number(GI) + Number(netPrice));
    }
  }, [estimate_hardcost]);

  return (
    <Grid
      container
      justifyContent="space-between"
      sx={{ gap: '2rem', height: '700px' }}
    >
      <Grid item container alignItems="center" justifyContent="space-between">
        <h3 className="fstxtPro">Your Projected Net Net</h3>
        <Box
          sx={{
            height: '2px',
            width: { xl: '74%', lg: '60%' },
            backgroundColor: '#adadad',
          }}
        />
      </Grid>
      <Grid
        item
        container
        direction="column"
        sx={{ gap: '2rem' }}
        xl={5}
        lg={5}
        md={5}
      >
        <Grid item sx={{ backgroundColor: '#fff', borderRadius: '8px' }}>
          <NetTable
            formData={formData}
            GI={GI}
            estimate_hardcost={estimate_hardcost}
            dileverable={dileverable}
            setErrorState={setErrorState}
          />
        </Grid>
      </Grid>
      <Grid
        item
        container
        direction="column"
        sx={{ gap: '2rem' }}
        xl={5}
        lg={6}
        md={6.5}
      >
        <TeamKpi formData={formData} dileverable={dileverable} GI={GI} />
        <WorkTypeLOE formData={formData} />
      </Grid>
    </Grid>
  );
};

export default PFifth;
