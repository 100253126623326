import Gear from './assets/Gear.png';
import Dots from './assets/Dots.png';

const MySchedule = () => {
  return (
    <section className="oppor-section">
      <div className="oppor-row">
        <div className="oppor-col">
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <h3 className="fstxtPro">My Schedule</h3>
          </div>
          <img className="icon-sm" src={Dots} alt="dots" />
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              background: 'inherit',
              border: 'none',
              outline: 'none',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <button
              style={{
                background: 'inherit',
                border: 'none',
                outline: 'none',
              }}
              id="filter"
            >
              <img id="filter" className="icon-sm" src={Gear} alt="gear" />
            </button>
          </div>
        </div>
      </div>
      <section className="myCenter">
        <article className="box-txt">
          <h3 className="txtCenter">My Schedule</h3>
          <p className="txtCenter">Coming Soon!</p>
        </article>
      </section>
    </section>
  );
};

export default MySchedule;
