import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TaskBar from './TaskBar';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                    Melashu
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

const KpiDeliverable = () => {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div>
            <div className='kpi-deliverable-container'>
                <h2 className='kpi-title'>Deliverable Tasks Data Overview</h2>
                <div className='kpi-middle-deliverable'>
                    <p className='kpi-deliverable-filter kpi-text'>Filter</p>
                    <Box sx={{ width: '100%' }}>
                        <Box sx={{}}>
                            <Tabs value={value} onChange={handleChange} style={{ background: '#E9ECEF', borderRadius: '8px' }} aria-label="basic tabs example">
                                <Tab label="Deliverbales" className='kpi-text' sx={{ color: '#000', fontSize: '14px' }} />
                                <Tab label="Task" className='kpi-text' sx={{ color: '#000', fontSize: '14px' }} />
                            </Tabs>
                        </Box>
                    </Box>
                </div>
            </div>
            <div style={{ marginTop: '40px' }}>
                <TabPanel value={value} index={0}>
                    Deliverables
                </TabPanel>
                <TaskBar value={value} index={1} />
            </div>
        </div>
    );
}

export default KpiDeliverable;