import { createAsyncThunk } from '@reduxjs/toolkit';
import { ENDPOINT } from '../../../Opportunity/Redux/oppor';

const GET_DISCOUNTS = 'netnet/Redux/GET_DISCOUNTS';

export const getDiscounts = createAsyncThunk(
  GET_DISCOUNTS,

  async (meta, thunkAPI) => {
    const response = await fetch(`${ENDPOINT}/api/superadmin/discount/list/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `JWT ${meta.access_token}`,
      },
    });
    const data = await response.json();
    if (!response.ok) {
      return thunkAPI.rejectWithValue(data);
    }
    return data;
  },
);

const initialState = {
  discounts: [],
  loading: false,
  error: null,
};

const discountReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${GET_DISCOUNTS}/pending`:
      return {
        ...state,
        loading: true,
      };
    case `${GET_DISCOUNTS}/fulfilled`:
      return {
        ...state,
        loading: false,
        discounts: action.payload,
      };
    case `${GET_DISCOUNTS}/rejected`:
      return {
        ...state,
        loading: false,
        error: action.payload,
        discounts: [],
      };
    default:
      return state;
  }
};

export default discountReducer;
