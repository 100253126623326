import React,{useState, useEffect} from 'react'


function ChatMob() {
    const [screenSize, setScreenSize] = useState(window.innerWidth);
    const handleResize = () => {
      setScreenSize(window.innerWidth);
    };
    useEffect(() => {
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);
    return (
        <div style={{display:'flex',alignItems:'center',textAlign:'center' ,justifyContent:'center', height:'100vh', backgroundColor:'white', width:screenSize}}>
            <h6 >Chat for Mobile Version is Coming very Soon....!</h6>
        </div>
    )
}

export default ChatMob;