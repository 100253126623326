import { createAsyncThunk } from '@reduxjs/toolkit';
import { ENDPOINT } from '../../../pages/Opportunity/Redux/oppor';

const REGISTER_USER = 'netnet/Redux/REGISTER_USER';
const LOGIN = 'netnet/Redux/LOGIN';
const LOGOUT = 'netnet/Redux/LOGOUT';
const myEndPoint = `${ENDPOINT}/auth/jwt/create/`;
const SET_USER = 'netnet/Redux/SET_USER';
const SET_EMAIL = 'netnet/Redux/SET_EMAIL';

const initialState = {
  user: {},
  status: 'idle',
  logginSuccess: false,
  error: 'erro',
  prevEmail: null,
  newUser: null,
  registrationSucess: false,
  registrationError: '',
};

export const setUser = (user) => ({
  type: SET_USER,
  payload: user,
});

export const userLogin = (user) => async (dispatch) => {
  const response = await fetch(myEndPoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(user),
  });
  const data = await response.json();
  if (!response.ok) {
    // eslint-disable-next-line
    console.log('rejected', data);
  } else {
    dispatch(setUser(data));
  }
};

export const setMyEmail = (give) => ({
  type: SET_EMAIL,
  payload: give,
});

export const checkAuth = createAsyncThunk(LOGIN, async (user, thunkAPI) => {
  if (localStorage.getItem('token')) {
    const response = await fetch('http://localhost:8000/api/test/', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
    return response.json();
  }
  return thunkAPI.rejectWithValue('No token');
});

export const registerNewUser = (data, newUser) => ({
  type: REGISTER_USER,
  payload: {
    newUser,
    data,
  },
});

export const logout = () => ({
  type: LOGOUT,
});

// eslint-disable-next-line
const authenticateReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${LOGIN}/pending`:
      return {
        ...state,
        status: 'loading',
      };
    case `${LOGIN}/fulfilled`:
      return {
        ...state,
        status: 'success',
        user: action.payload,
        logginSuccess: true,
      };
    case `${LOGIN}/rejected`:
      return {
        ...state,
        status: 'failed',
        error: action.payload,
        logginSuccess: false,
      };
    case `${REGISTER_USER}/pending`:
      return {
        ...state,
        status: 'loading',
      };
    case REGISTER_USER:
      return {
        ...state,
        status: 'success',
        newUser: action.payload,
        registrationSucess: true,
      };
    case `${REGISTER_USER}/rejected`:
      return {
        ...state,
        status: 'failed',
        newUser: {},
        registrationError: action.payload,
      };
    case LOGOUT:
      return {
        error: 'erro',
        prevEmail: null,
        newUser: null,
        user: {},
        status: 'LOGOUT',
        logginSuccess: false,
      };
    case SET_USER:
      return {
        ...state,
        user: action.payload,
        logginSuccess: true,
      };
    case SET_EMAIL:
      return {
        ...state,
        prevEmail: action.payload,
      };

    default:
      return state;
  }
};

export default authenticateReducer;
