import ContactDetail from './ContactDetail'
import "./customerDetail.css"
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'

const CustomerDetail = () => {
    const { id } = useParams();
    const customers = useSelector((state) => state.superAdmin.customersData);
    const customerId = parseInt(id);
    const customer = customers.find((c) => c.team_owner.id === customerId);

    if (!customer) {
      return <div>Loading...</div>;
    }
  return (
    <>
   
    <div className=''>
      <ContactDetail customer={customer} />
    </div>
    </>
  )
}

export default CustomerDetail
