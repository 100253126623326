import React, { useState } from 'react'
import arrow1 from './assets/arrow-1.svg'
import logo from "./assets/NetNetSymbol.svg";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import ReactPlayer from 'react-player';

const CoursesList = ({ courses }) => {

  const [hoveredVideo, setHoveredVideo] = useState(null);

  const handleMouseEnter = (videoId) => {
    setHoveredVideo(videoId);
  };

  const handleMouseLeave = () => {
    setHoveredVideo(null);
  };

  const [chapterList, setChapterList] = useState([
    { Chapter: 1, title: "Net Net on the Whole" },
    { Chapter: 2, title: "In to the Contact Zone" },
    { Chapter: 3, title: "Estimates and Opportunities" },
    { Summarizing: "Summarizing", },
  ]);

  const [overviewStates, setOverviewStates] = useState({});
  const [collapse, setCollapse] = useState(true)

  const handleOverview = (videoId) => {
    setCollapse(true);
    setOverviewStates((prevState) => ({
      ...prevState,
      [videoId]: !prevState[videoId],
    }));
  };

  return (
    <>
      {Array.isArray(courses) && courses?.map((video) => (
        <>
          <div key={video?.id} className="box">
            <div className="innerBox">
              <div className="videoWrapper"
                onMouseEnter={() => handleMouseEnter(video?.id)}
                onMouseLeave={handleMouseLeave}>

                {hoveredVideo === video?.id ? (
                  <ReactPlayer
                    url={video.url}
                    playing={true}
                    controls={true}
                    width={260}
                    volume={0}
                    height="auto"
                  />
                ) : (
                  <img
                    src={video.thumbnail}
                    style={{ zIndex: "99", width: "260px", height: "142px" }}
                    alt={`thumbnail-${video.id}`}
                  />
                )}
              </div>
              <div className="vInfoWrapper3">
                <div className="lesson3" style={{ display: "flex" }}>
                  <p>LESSON |</p> <span>{video?.level}</span>
                </div>
                <div className="lessonTitle3">
                  <b>{video?.title}</b>
                </div>
                <div className="lessonDescripton3">
                  <div>{video?.description}</div>
                </div>

                <div
                  className="more3"
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => handleOverview(video?.id)}
                >
                  <div className="learn_more3">LEARN MORE</div>
                  <img src={arrow1} alt="learn_more" />
                </div>

                <div className="logoAndAuthor">
                  <img src={logo} alt="logo" />
                  <div className="author">
                    BY <span>{video?.author}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {collapse && overviewStates[video?.id] && (
            <div className="overview">
              <DialogContent className='colFlex' style={{
                display: "flex",
                padding: 0
              }}>
                <div className="overviewLeft">
                  {" "}
                  <h4>OVERVIEW</h4> <p>{video?.description}</p>
                </div>
                <div className="overviewRight">
                  {Array.isArray(chapterList) && chapterList.map((chapters, index) => (
                    <ul key={index} className="chapterListStyle">
                      {chapters.Chapter && (
                        <li>
                          Chapter {chapters?.Chapter}: {chapters?.title}{" "}
                        </li>
                      )}
                      {chapters?.Summarizing && <li>{chapters?.Summarizing}</li>}
                    </ul>
                  ))}
                </div>
              </DialogContent>
              <DialogActions className='collapseButton' sx={{ padding: 0 }}>
                <Button
                  fullWidth
                  variant="filled"
                  sx={{
                    backgroundColor: "#F4F4F4",
                    textTransform: "capitalize",
                    height: "31px",
                    padding: "10px",
                    justifyContent: "center",
                    borderRadius: "0px 0px 5px 5px",
                    border: "1px solid #D9D9D9",
                  }}
                  onClick={() => setCollapse(false)}
                >
                  Collapse
                </Button>
              </DialogActions>
            </div>
          )}
        </>
      ))}
    </>
  );
}

export default CoursesList
