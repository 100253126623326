import React from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../../../node_modules/bootstrap/dist/js/bootstrap.bundle.min';
import './InnerApp.css';
import SideNav from '../../Componenets/SideNav/SideNav';
import TopNav from '../../Componenets/TopNav/TopNav';
import Opportunity from '../../pages/Opportunity/Opportunity';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState, useRef } from 'react';
import { userData } from './userData';
import Project from '../../pages/Projects/Projects';
import People from '../../pages/Contacts/People/People';
import NewPerson from '../../pages/Contacts/People/NewPeople';
import Companies from '../../pages/Contacts/Companies/Companies';
import NewCompany from '../../pages/Contacts/Companies/NewCompany';
import Import from '../../pages/Contacts/importSteps/Import';
import QuickTasks from '../../pages/Projects/QuickTasks';
import Stream from '../../pages/Stream/Stream';
import NewEstimate from '../../pages/Opportunity/NewEstimate';
import NetNetU from '../../pages/NetNetU/NeNetU';
import AllLesson from '../../pages/NetNetU/AllLesson';
import AllCourse from '../../pages/NetNetU/AllCourse';
import CourseDetail from '../../pages/NetNetU/CourseDetail';
import LessonDetail from '../../pages/NetNetU/LessonDetail';
import ListPeople from './newPeople';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import AllTask from './allTask';
import Button from '@mui/material/Button';
import {
  getMessages,
  getTeam,
  GetFav,
  New_channels,
} from '../../pages/Stream/streamRedux';
import { ENDPOINT, getEstimates, getTimeTracking } from '../../pages/Opportunity/Redux/oppor';
import PersonDet from '../../pages/Contacts/People/PersonDet';
import { getUserTeam } from '../../pages/Team/team';
import { getProjects, getQuickTask } from '../../pages/Projects/proRedux';
import { getWorkType } from './workTypes';
import EditContent, { PreLoading } from '../../pages/Contacts/People/EditContact';
import CompanyDet from '../../pages/Contacts/Companies/CompanyDet';
import EditCompany from '../../pages/Contacts/Companies/EditCompany';
import { getOpportunity } from '../../pages/Opportunity/Redux/oppor';
import { logout, setMyEmail } from '../../Componenets/Auth/Redux/auth';
import Dashboard from '../../pages/Me/Dashboard/Dashboard';
import { Alert, Grid, Tooltip, Select } from '@mui/material';
import MyTasks from '../../pages/Me/MyTasks';
import MyTime from '../../pages/Me/MyTime';
import TaskDetails from './TaskDetails';
import WorkTypes from '../../pages/User/WorkTypes';
import TeamMembers from '../../pages/User/TeamMembers';
import Collab from '../../pages/User/Collab';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import {
  getCompanies,
  getPersons,
  reqComplete,
} from '../../pages/Contacts/ContactRedux';
import FifthStep from '../../pages/Contacts/importSteps/FifthStep';
import EstDetail from '../../pages/Opportunity/estDetails/EstDetail';
import KPI from '../../pages/KPI/Kpi';
import ColabEst from '../../pages/Opportunity/ColabEstimate/ColabEst';
import ProjectDet from '../../pages/Projects/ProjectDet/ProjectDet';
import NewProject from '../../pages/Projects/NewProject/NewProject';
import Reports from '../../pages/Reports/Reports';
import Template from '../../pages/User/Templates';
import BottomNavigation from './BottomNavigation';
import NetNetUMob from './NetNetUMob';
import Sales from './Sales';
import ChatMob from './ChatMob';
import Timer from './Timer';
import PersonOutlineRoundedIcon from '@mui/icons-material/PersonOutlineRounded';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import TextField from '@mui/material/TextField';
import { NetNetCheckBox, PreLoader1 } from '../../Componenets/Misc';
import Autocomplete from '@mui/material/Autocomplete';
import Welcome from '../../pages/Me/Welcome/Welcome';
import SuperAdmin from '../../pages/SuperAdmin/SuperAdmin';
import uuid from 'react-uuid';
import AccPersonalization from '../../pages/User/AccPersonalization';
import MyProfile from '../../pages/User/MyProfile';
import AccountBilling from '../../pages/User/Accountbilling/AccountBilling';
import MySchedule from '../../pages/User/My_Schedule';
import { getNotifications, getWorkTypes } from '../../pages/User/redux';
import NotFound from '../NotFound';
import packageFile from '../../../package.json'
import CacheBuster from 'react-cache-buster'
import Test from '../../pages/Test';
import DeletePng from '../../pages/Contacts/assets/DeleteIcon.png'



function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ width: '100%', textAlign: 'center' }}>
      <Box sx={{ position: 'relative', display: 'inline-flex' }}>
        <CircularProgress variant="determinate" {...props} />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography variant="caption" component="div" color="text.secondary">
            {`${Math.round(props.value)}%`}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};

const Importing = ({ isImportingStill }) => {
  const navigate = useNavigate();
  const access_token = useSelector((state) => state.auth.user.access);
  const [width, setWidth] = useState('25%');
  const dispatch = useDispatch();
  const target = useSelector((state) => state.Contacts.target);
  const [realTarget, setRealTarget] = useState({});
  const [progress, setProgress] = useState(0);
  useEffect(() => {
    if (target && 'import_view_response' in target) {
      setRealTarget(target.import_view_response);
      setProgress(90);
    }
  }, [target]);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => {
        const newProgress = prevProgress > 80 && !isImportingStill ? prevProgress + 10 : prevProgress + 0;
        return newProgress >= 100 && !isImportingStill ? 100 : newProgress;
      });
    }, 2800);
    return () => {
      clearInterval(timer);
    };
  }, [isImportingStill]);
  useEffect(() => {
    if (progress === 100 && !isImportingStill) {
      dispatch(getPersons({ access_token }));
      dispatch(getCompanies({ access_token }));
      setTimeout(() => {
        navigate(`/import/complete`);
        dispatch(reqComplete(false));
      }, 3000);
    }
  }, [progress]);

  return (
    <Grid
      sx={{
        width: width,
        height: '120px',
        border: '0.5px solid #d9d9d9',
        backgroundColor: 'rgba(255,255,255,0.95)',
        position: 'fixed',
        top: '15%',
        right: 15,
        zIndex: 9999,
        alignItems: 'center',
        borderRight: '10px solid #711fff',
        transition: 'all 0.5s ease-in-out',
        cursor: 'pointer',
      }}
      onClick={() =>
        setWidth((prev) => {
          if (prev) {
            return 0;
          } else {
            return '25%';
          }
        })
      }
    >
      {width ? (
        <Box
          sx={{
            width: '100%',
            padding: '1rem 1.44rem',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgressWithLabel value={progress} />
          <>
            {progress < 100 ? (
              <>
                <p className="fstxt">IMPORTING CONTACTS</p>
                <p>We'll let you know once Its Done...</p>
              </>
            ) : progress >= 100 ? (
              <>
                <p className="fstxt">Almost There</p>
              </>
            ) : null}
          </>
        </Box>
      ) : null}{' '}
    </Grid>
  );
};

import { useMemo } from 'react';

const NewPersonalize = ({ completedPersonalize, setCompletedPersonalize }) => {
  const [formData, setFormData] = useState({
    company_name: '',
    company_domain: [{
      domain_name: ''
    }],
    work_category: '',
    invitation: [],
    skip_company: false,
    skip_domain: false,
    type_of_business: ''
  });

  const access_token = useSelector((state) => state.auth.user.access);
  const [activeStep, setActiveStep] = React.useState(1);



  const [teamMemberFiledLength, setTeamMemberFiledLength] = useState([1, 2, 3]);

  const initialTeamMembers = useMemo(() => {
    return Array.from({ length: teamMemberFiledLength.length }, () => ({
      email: '',
      role: '',
      identity: '',
    }));
  }, [teamMemberFiledLength]);

  const [teamMember, setTeamMember] = useState(initialTeamMembers);

  const addTeamMemberField = () => {
    setTeamMemberFiledLength(prevLength => [...prevLength, prevLength.length + 1]);
    setTeamMember(prevMembers => [...prevMembers, {
      email: '',
      role: '',
      identity: '',
    }]);
  };


  const handleMemberEmailChange = (v, i) => {
    setTeamMember(prevMembers => {
      return prevMembers.map((member, index) => {
        if (index === i) {
          return {
            ...member,
            email: v.target.value
          };
        }
        return member;
      });
    });
  };

  const handleMemberAccessLevelChange = (value, index) => {
    const newTeamMembers = [...teamMember];
    newTeamMembers[index].role = value;
    newTeamMembers[index].identity = uuid()
    setTeamMember(newTeamMembers);
  };


  const [teamEmail, setTeamEmail] = useState('');
  const [newTeam, setNewTeam] = useState([])

  const handleNext = async (e) => {

    if (activeStep < 3) {
      // Check if the team member's email is valid
      const isValidTeamEmail = !teamEmail || teamEmail.match(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/);


      if (!isValidTeamEmail) {
        setError(true); // Set error state to true
        setErrorMessage('Invalid team member email address'); // Set error message
        return; // Prevent proceeding to the next step
      }

      if (activeStep === 1 && !formData.skip_company || !formData.skip_domain) {
        if (formData.company_domain[0].domain_name.length > 1) {
          if (formData?.company_name.length > 0) {
            setLoading(true)
            const response = await fetch(
              `${ENDPOINT}/api/user/userpersonalization/`,
              {
                method: 'PUT',
                headers: {
                  Authorization: `JWT ${access_token}`,
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({

                  company_name: formData.company_name.length > 1 ? formData.company_name : '',
                  company_domain: formData.company_domain.map((domain) => ({
                    domain_name: domain.domain_name ? (domain.domain_name.startsWith('https') ? domain.domain_name : `https://${domain.domain_name}`) : '',
                  })),
                  company_type: formData.work_category.length > 1 ? formData.work_category : ''
                }),
              }
            );
            const data = await response?.json();
            if (!response.ok) {
              setLoading(false);
              setError(true);
              for (const key in data) {
                setErrorMessage(data[key]);
              }
            }
            else {
              setActiveStep(activeStep + 1);
              setLoading(false)
            }
          }
          else {
            setError(true);
            setErrorMessage('Please Provide Company Name First');
          }
        }
        else {
          if (formData.company_name.length > 0) {
            setLoading(true)
            const response = await fetch(
              `${ENDPOINT}/api/user/userpersonalization/`,
              {
                method: 'PUT',
                headers: {
                  Authorization: `JWT ${access_token}`,
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  company_name: formData.company_name ? formData.company_name : '',
                  company_type: formData.work_category ? formData.work_category : ''
                }),
              }
            );
            const data = await response?.json();
            if (!response.ok) {
              setLoading(false);
              setError(true);
              for (const key in data) {
                setErrorMessage(data[key]);
              }
            }
            else {
              setActiveStep(activeStep + 1);
              setLoading(false)
            }
          }
          else {
            setLoading(true)
            const response = await fetch(
              `${ENDPOINT}/api/user/userpersonalization/`,
              {
                method: 'PUT',
                headers: {
                  Authorization: `JWT ${access_token}`,
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  company_type: formData.work_category ? formData.work_category : ''
                }),
              }
            );
            const data = await response?.json();
            if (!response.ok) {
              setLoading(false);
              setError(true);
              for (const key in data) {
                setErrorMessage(data[key]);
              }
            }
            else {
              setActiveStep(activeStep + 1);
              setLoading(false)
            }
          }
        }
      }

      else if (activeStep === 2 && Array.isArray(newTeam) && newTeam.length) {
        setLoading(true)
        const response = await fetch(
          `${ENDPOINT}/api/user/userpersonalization/`,
          {
            method: 'PUT',
            headers: {
              Authorization: `JWT ${access_token}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              invitation: newTeam.map((per) => ({
                email: per.email ? per.email : '',
                role: per.role ? per.role : '',
              })),
            }),
          }
        );
        const data = await response?.json();
        if (!response.ok) {
          setLoading(false);
          setError(true);
          for (const key in data) {
            setErrorMessage(data[key]);
          }
        }
        else {
          setNewTeam([])
          setActiveStep(activeStep + 1)
          setLoading(false)
        }
      }
      else {
        setActiveStep(activeStep + 1)
      }
    }
  };



  function isValidEmail(email) {
    // Regular expression for basic email validation
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailRegex.test(email);
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
    setError(false)
    setErrorMessage('')
  };



  const types = [
    'Advertising (Branding, Design, PR, Marketing, etc.)',
    'Consulting Services',
    'Digital Promotions (PPC, SEO, Marketing Automation)',
    'Custom Software & App Developers',
    'IT Services',
    'Photography',
    'Video & Animation Production',
    'Web Design',
    'Other Professional Services',
  ];
  const levels = [
    'Owner',

    'Admin',
    'Lead',
    'Member',
  ];

  const [work, setWork] = useState([
    {
      dent: `NetWt${1}`,
      name: '',
      user_rate: '',
    },
    {
      dent: `NetWt${2}`,
      name: '',
      user_rate: '',
    },
    {
      dent: `NetWt${3}`,
      name: '',
      user_rate: '',
    },
  ]);

  const dispatch = useDispatch();
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError(false);
        setErrorMessage('');
      }, 3000);
    }
  }, [error]);

  const currentUser = useSelector((state) => state.userData.userData);
  const navigate = useNavigate();


  const handleFinish = async (e) => {
    if (work.slice(0, 2).some(item => !item.name || !item.user_rate)) {
      setError(true);
      setErrorMessage('At least three worktypes are required');
    }
    else {
      setLoading(true);
      e.preventDefault();

      const userUpdateData = new FormData();

      // Add fields to the formData object
      userUpdateData.append('profile_updated', 1);
      userUpdateData.append('personalization_updated', 1);
      userUpdateData.append('email', currentUser?.email);
      userUpdateData.append('first_name', currentUser?.first_name);
      userUpdateData.append('last_name', currentUser?.last_name);
      userUpdateData.append('password', 'P@ssword123');
      userUpdateData.append('username', currentUser?.username);
      userUpdateData.append('skiped_welcome', 0);

      const res3 = await fetch(
        `${ENDPOINT}/api/user/update/${currentUser.id}/`,
        {
          method: 'PUT',
          headers: {
            Authorization: `JWT ${access_token}`,
          },
          body: userUpdateData,
        }
      );

      if (!res3.ok) {
        const data3 = await res3.json();
        setError(true);
        setErrorMessage(data3?.detail ? data3?.detail : 'Something Went Wrong Updating your profile');
        setLoading(false)
      } else {
        if (Array.isArray(work) && work.length > 0) {
          // Assuming this code is inside an async function or an effect hook

          try {
            for (const ele of work) {
              const res2 = await fetch(
                `${ENDPOINT}/api/opportunity/worktype/create/`,
                {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                    Authorization: `JWT ${access_token}`,
                  },
                  body: JSON.stringify(ele),
                }
              );
              const data2 = await res2.json();
              if (!res2.ok) {
                setLoading(false);
                setError(true);
                for (const key in data2) {
                  setErrorMessage(data2[key]);
                }
                // If any iteration fails, exit the loop
                return;
              }
            }

            // The else block will be executed only if the loop completes without any issues
            setCompletedPersonalize(true);
            setError(false);
            setErrorMessage('');
            setLoading(false);
            dispatch();
            navigate('/Welcome');
          } catch (error) {
            // Handle any unexpected errors outside the loop
          }
        } else {
        }
      }
    }
  };


  const workRef = useRef(null);

  useEffect(() => {
    // Scroll to the newly added worktype when the work array changes
    if (workRef.current) {
      workRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'end', // Scroll to the end of the container
      });
    }
  }, [work]);

  const extractVideoId = (url) => {
    const match = url?.match(/(?:\?v=|\/embed\/|\/\d\d\/|\/vi\/|\/v\/|https:\/\/www.youtube.com\/watch\?v=|https:\/\/youtu.be\/)([^#\&\?]*).*/);
    return (match && match[1].length === 11) ? match[1] : false;
  };

  // Function to construct the thumbnail URL
  const constructThumbnailUrl = (videoId, quality = 'maxresdefault') => {
    return `https://img.youtube.com/vi/${videoId}/${quality}.jpg`;
  };

  
  const [isOn, setIsOn] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
 


  const [isHovered, setIsHovered] = useState(false);


  return (
    <Grid
      sx={{
        position: 'fixed',
        top: '0px',
        left: '0px',
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0,0,0,0.6)',
        alignItems: 'center',
        zIndex: 9999,
      }}
    >
      <Box
        sx={{
          width: { xl: '75%', lg: '78%', md: '80%', xs: '95%' },
          height: { xs: '90%', sm: '90%', md: '80%', lg: '80%' },
          backgroundColor: '#F2EBFF',
          borderRadius: '30px',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          p: 3,
        }}
      >
        <Box sx={{ display: "flex", justifyContent: 'center' }}>
          <Box
            sx={{
              width: '70%',
              display: 'flex',
              justifyContent: 'space-around',
              alignItems: 'center',

              marginBottom: { xl: '2rem', lg: '1rem', md: '1rem' },
              mb: 2,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                color: activeStep === 1 ? '#8640fe' : 'gray',
                fontSize: { xs: '8px', sm: '12px' },
                fontWeight: '600',
              }}
            >
              <Box
                sx={{
                  backgroundColor: activeStep === 1 ? '#8640fe' : '#dbdbdb',
                  padding: '10px 10px',
                  borderRadius: '8px',
                  color: activeStep === 1 ? '#fff' : 'gray',
                  marginBottom: '10px',
                }}
              >
                <PersonOutlineRoundedIcon
                  sx={{ height: '30px', width: '30px' }}
                />
              </Box>
              PROFILE
            </Box>

            <Box
              sx={{
                width: { xl: '35%', lg: '30%', md: '30%' },
                height: '1px',
                backgroundColor: '#000',
              }}
            />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                color: activeStep === 2 ? '#8640fe' : 'gray',
                fontSize: { xs: '8px', sm: '12px' },
                fontWeight: '600',
              }}
            >
              <Box
                sx={{
                  backgroundColor: activeStep === 2 ? '#8640fe' : '#dbdbdb',
                  padding: '10px 10px',
                  borderRadius: '8px',
                  color: activeStep === 2 ? '#fff' : 'gray',
                  marginBottom: '10px',
                }}
              >
                <GroupsOutlinedIcon sx={{ height: '30px', width: '30px' }} />
              </Box>
              TEAM
            </Box>
            <Box
              sx={{
                width: { xl: '35%', lg: '30%', md: '30%' },
                height: '1px',
                backgroundColor: '#000',
              }}
            />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                color: activeStep === 3 ? '#8640fe' : 'gray',
                fontSize: { xs: '8px', sm: '12px' },
                fontWeight: '600',
              }}
            >
              <Box
                sx={{
                  backgroundColor: activeStep === 3 ? '#8640fe' : '#dbdbdb',
                  padding: '10px 10px',
                  borderRadius: '8px',
                  color: activeStep === 3 ? '#fff' : 'gray',
                  marginBottom: '10px',
                }}
              >
                <WorkOutlineOutlinedIcon sx={{ height: '30px', width: '30px' }} />
              </Box>
              WORKTYPES
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            backgroundColor: '#fff',
            boxShadow: '2px 2px 20px rgba(0,0,0,0.2)',
            height: '80%',
            overflow: { xs: 'auto', md: "hidden" },
            borderRadius: '10px',
          }}
        >
          {activeStep === 1 && (
            <Box sx={{ p: 2 }}>
              <Box sx={{}}>
                <Typography
                  sx={{
                    fontSize: { xl: '25px', lg: '22px', md: '20px' },
                    letterSpacing: 2,
                    textAlign: 'center',
                  }}
                >
                  Step 1 : Profile
                </Typography>
                <Typography
                  sx={{
                    color: '#36e0b3',
                    fontSize: { xl: '15px', lg: '14px', sm: '12px', xs: '10px' },
                    fontWeight: '600',
                    textAlign: 'center',
                  }}
                >
                  Awesome Job {currentUser?.first_name ?? ''} let's get a
                  bit more information to finish setting up your Net Net Account
                </Typography>
              </Box>
              <Grid container spacing={0} sx={{ mt: 2 }} >
                <Grid item xs={12} md={6} sx={{ overflow: { md: 'scroll' }, scrollbarWidth: 'none', height: { md: '45vh' }, borderRight: { xs: 'none', md: "2px solid #E0E0DF" } }}>
                  <Box sx={{ width: "100%", p: 2, }}>
                    <Box sx={{ position: 'relative' }}>
                      <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                        <iframe
                          src='https://videos.hellonetnet.com/watch/m61IhS2t'
                          frameBorder="8"
                          className='player'
                          style={{
                            // borderRadius: '10px',
                            width: '100%',
                            height: '30vh',
                            borderRadius: '10px 10px 0px 0px',
                            '@media only screen and (max-device-width : 640px)': {
                            }
                          }}
                          onClick={() => {
                            if (isOn == true) {
                              setIsOn(false)
                              setIsHovered(false)
                            }
                            else {
                              setIsOn(true)
                            }
                          }}
                        >
                        </iframe>
                      </Box>
                      <Box sx={{ display: { xs: 'none', sm: 'block', md: 'none' } }}>
                        <iframe
                          src='https://videos.hellonetnet.com/watch/m61IhS2t'
                          frameBorder="8"
                          className='player'
                          style={{
                            width: '100%',
                            height: '50vh',
                            borderRadius: '10px 10px 0px 0px',
                            '@media only screen and (max-device-width : 640px)': {
                            }
                          }}
                          onClick={() => {
                            if (isOn == true) {
                              setIsOn(false)
                              setIsHovered(false)
                            }
                            else {
                              setIsOn(true)
                            }
                          }}
                        >
                        </iframe>
                      </Box>
                      <Box sx={{ display: { xs: 'none', md: 'block', lg: 'none' } }}>
                        <iframe
                          src='https://videos.hellonetnet.com/watch/m61IhS2t'
                          frameBorder="8"
                          className='player'
                          style={{
                            width: '100%',
                            height: '100%',
                            borderRadius: '10px 10px 0px 0px',
                            '@media only screen and (max-device-width : 640px)': {

                            }
                          }}
                          onClick={() => {
                            if (isOn == true) {
                              setIsOn(false)
                              setIsHovered(false)
                            }
                            else {
                              setIsOn(true)
                            }
                          }}
                        >
                        </iframe>
                      </Box>
                      <Box sx={{ display: { xs: 'none', lg: 'block', xl: 'none' } }}>
                        <iframe
                          src='https://videos.hellonetnet.com/watch/m61IhS2t'
                          frameBorder="8"
                          className='player'
                          style={{
                            // borderRadius: '10px',
                            width: '100%',
                            height: '100%',
                            borderRadius: '10px 10px 0px 0px',
                            '@media only screen and (max-device-width : 640px)': {

                            }
                          }}
                          onClick={() => {
                            if (isOn == true) {
                              setIsOn(false)
                              setIsHovered(false)
                            }
                            else {
                              setIsOn(true)
                            }
                          }}
                        >
                        </iframe>
                      </Box>
                      <Box sx={{ display: { xs: 'none', xl: 'block' } }}>
                        <iframe
                          src='https://videos.hellonetnet.com/watch/m61IhS2t'
                          frameBorder="8"
                          className='player'
                          style={{
                            width: '100%',
                            height: '27vh',
                            borderRadius: '10px 10px 0px 0px',
                            '@media only screen and (max-device-width : 640px)': {
                            }
                          }}
                          onClick={() => {
                            if (isOn == true) {
                              setIsOn(false)
                              setIsHovered(false)
                            }
                            else {
                              setIsOn(true)
                            }
                          }}
                        >
                        </iframe>
                      </Box>
                      <Box align='center' sx={{ display: "flex", justifyContent: "center" }}>
                        <Typography sx={{ width: { xs: '100%', md: '240px', xl: '410px' }, fontSize: { xs: '10px', sm: '14px', md: '11px', xl: '14px' }, py: 0.3, backgroundColor: '#f4f0f5', borderEndStartRadius: '10px', borderEndEndRadius: '10px', textAlign: 'center', }} >
                          Need help with the profile step? Watch this!
                        </Typography>
                      </Box>
                      <Box sx={{
                        position: 'absolute',
                        left: '50%',
                        top: '45%',
                        transform: 'translate(-50%, -50%)',
                        zIndex: 9,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer'
                      }}
                        onClick={() => {
                          if (isOn == true) {
                            setIsOn(false)
                            setIsHovered(false)
                          }
                          else {
                            setIsOn(true)
                          }
                        }}
                      >
                        <PlayArrowRoundedIcon
                          sx={{
                            color: '#fff',
                            height: '110px',
                            width: '110px',
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            if (isOn == true) {
                              setIsOn(false)
                              setIsHovered(false)
                            }
                            else {
                              setIsOn(true)
                            }
                          }}
                        />
                      </Box>
                    </Box>
                  </Box>
                  {
                    isOn && <Box sx={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100vh', backgroundColor: 'rgba(0,0,0,0.6)', zIndex: 999999999, dispay: 'flex', alignItems: 'center', flexDirection: 'row' }}>
                      <Box sx={{ width: '70%', margin: '0 auto', height: '90%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', }}>
                        <Box sx={{ width: '100%', height: '30px', backgroundColor: 'gray', color: '#fff', display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '0px 5px' }}> <p> </p> <p style={{ marginTop: '0px' }}>Need help with the profile step? Watch this!</p> <CloseIcon sx={{ color: '#fff', cursor: 'pointer' }} onClick={() => {
                          setIsOn(false)
                        }} /> </Box>
                        <iframe
                          src='https://videos.hellonetnet.com/watch/m61IhS2t'
                          frameBorder="8"
                          style={{
                            width: '100%',
                            height: '80%',
                          }}
                        >
                        </iframe>

                      </Box>
                    </Box>
                  }
                  <Box sx={{ p: 2, display: { xs: 'block', md: 'flex' }, justifyContent: 'center' }}>
                    <Box sx={{ width: { md: '75%' } }}>
                      <Typography
                        sx={{
                          color: '#711FFF',
                          fontSize: { xl: '18px', lg: '16px', sm: '14px', xs: '12px' },
                          fontWeight: '800',
                          textAlign: 'center',
                          pb: 0.5,
                        }}
                      >
                        Pro Tips
                      </Typography>
                      <Typography
                        sx={{
                          color: '#36e0b3',
                          fontSize: { xl: '15px', lg: '13px', md: '12px', xs: '10px' },
                          fontWeight: '600',
                          textAlign: 'left',
                          lineHeight: 1.2,
                        }}
                      >
                        You may fall into multiple industries.  That’s okay.  Just use the one you most closely match with.
                      </Typography>
                      <Typography
                        sx={{
                          color: '#36e0b3',
                          fontSize: { xl: '15px', lg: '14px', sm: '12px', xs: '10px' },
                          fontWeight: '600',
                          textAlign: 'left',
                          lineHeight: 1.2,
                          mt: 2
                        }}
                      >
                        Adding web addresses, particularly the ones you use for your email helps us know who might potentially be on your team.  So if you use starwars.com and someone else signs up with an email @starwars.com, we can flag it and ask you to invite them to join your team.
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}
                  sx={{ overflow: { md: "scroll" }, scrollbarWidth: 'none', height: { md: "35vh" } }}
                >
                  <Box sx={{
                    width: "100%", px: 2, overflow: "hidden",
                  }}>
                    <Box sx={{
                    }}>
                      <Grid container spacing={0} >
                        <Grid item xs={12} sm={12}

                        >
                          <Typography
                            sx={{
                              color: '#711FFF',
                              fontSize: { xl: '15px', lg: '13px', md: '12px', xs: '10px' },
                              fontWeight: '500',
                              mb: 1,
                            }}
                          >
                            Company Name
                          </Typography>
                          <Box
                            sx={{ p: '7px', borderRadius: '8px', border: '1.5px solid #d9dadb', width: '100%', mt: 1 }}
                          >
                            <TextField
                              fullWidth
                              variant="outlined"

                              placeholder='Acme Rockets Marketing'
                              value={formData.company_name}
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  company_name: e.target.value,
                                })
                              }
                              sx={{
                                fontSize: '14px',
                                border: 'none !important',
                                background: '#f5f7fa',
                                borderRadius: '8px !important',
                                '& .MuiInputBase-input': { borderRadius: '8px !important', p: '7px 10px 7px 10px !important' },
                                '& .MuiOutlinedInput-root': {
                                  background: '#f5f7fa',
                                  border: 'none',
                                  borderRadius: '8px !important',
                                  '& fieldset': {
                                    border: "none",
                                    p: 0,
                                    borderRadius: '8px !important',
                                  },
                                  '&:hover fieldset': {
                                    border: "none",
                                    p: 0,
                                    borderRadius: '8px !important',

                                  },
                                  '&.Mui-focused fieldset': {
                                    border: "none",
                                    p: 0,
                                    borderRadius: '8px !important',

                                  },
                                  '&.Mui-error fieldset': {
                                    border: "none",
                                    p: 0,
                                    borderRadius: '8px !important',

                                  },
                                },
                                '& .Mui-focused fieldset': {
                                  border: "none",
                                  p: 0,
                                  borderRadius: '8px !important',

                                },
                                '& input::placeholder': {
                                  fontSize: {
                                    xl: '1rem',
                                    lg: '0.9rem',
                                    md: '0.85rem',
                                  },
                                },
                              }}
                              disabled={formData.skip_company}
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <Box sx={{ display: 'flex', alignItems: "center", mt: 2, }}>
                            <NetNetCheckBox
                              checked={formData.skip_company}
                              onChange={(e) => {

                                if (e.target.checked) {
                                  setFormData({
                                    ...formData,
                                    skip_company: true,
                                    company_name: '',
                                    work_category: '',
                                  });
                                } else {
                                  setFormData({
                                    ...formData,
                                    skip_company: false,
                                    company_name: '',
                                    work_category: '',
                                  });
                                }
                              }}
                            />
                            <Typography
                              sx={{
                                color: 'GrayText',
                                ml: 1,
                                fontSize: { xl: '14px', lg: '12px', md: '11.5px', xs: '10px' },
                              }}
                            >
                              I do not have a Company
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                      <Box
                        sx={{ borderRadius: '8px', width: { xs: "100%", sm: '100%' }, mt: 2 }}
                      >
                        <Typography
                          sx={{
                            color: '#711FFF',
                            fontSize: { xl: '1rem', lg: '0.9rem', md: '0.85rem', xs: '10px' },
                            fontWeight: '500',
                            mb: '8px !important',
                          }}
                        >
                          Industry <span style={{ color: '#711FFF', fontSize: '9px', marginLeft: '4px' }}>(Please choose the closest match)</span>
                        </Typography>
                        <Autocomplete
                          fullWidth
                          id="country"
                          variant='standard'
                          includeInputInList
                          placeholder='What Choice most Describes your Company'
                          disabled={formData.skip_company}
                          value={formData.work_category}
                          onChange={(_, value) => {
                            setFormData({
                              ...formData,
                              work_category: value,
                            });
                          }}
                          options={types}
                          getOptionLabel={(option) => option}
                          sx={{
                            zIndex: 99999999,
                            border: 'none !important',
                            borderRadius: '8px !important',
                            '& .MuiAutocomplete-input': {
                              padding: '5px 0px 5px 0px !important',
                              fontSize: '14px',
                            },

                            '& .css-jx3fhb-MuiInputBase-root-MuiInput-root:before': {
                              borderBottomColor: '#711fff !important', // Change focus color
                            },
                            '& .css-15ibws3-MuiAutocomplete-root .MuiAutocomplete-input:focus': {
                              borderBottomColor: '#711fff !important', // Change focus color
                            },
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="standard"

                              placeholder='What Choice most Describes your Company'

                              color="secondary"
                              InputProps={{
                                ...params.InputProps,

                              }}
                            />
                          )}
                        />
                      </Box>
                      <Grid container spacing={0} sx={{ mt: 3 }}>
                        <Grid item xs={12} sm={12}>
                          <Box sx={{}}>
                            <Typography
                              sx={{
                                color: '#711FFF',
                                fontSize: { xl: '1rem', lg: '0.9rem', md: '0.85rem', xs: '10px' },
                                fontWeight: '500',
                              }}
                            >
                              {' '}
                              Web Address(es)
                            </Typography>
                            <Box className='scrollShow'
                            >
                              {Array.isArray(formData.company_domain) && formData.company_domain.length
                                ? formData.company_domain.map((item, index) => {
                                  return (
                                    <Box
                                      sx={{
                                        display: 'flex',
                                        width: '100%',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                      }}
                                    >
                                      <Box sx={{ p: '7px', borderRadius: '8px', border: '1.5px solid #d9dadb', width: '100%', mt: 1, display: 'flex', alignItems: 'center' }}
                                      >
                                        <TextField
                                          fullWidth
                                          variant="outlined"

                                          placeholder="https://yourdomain.com"
                                          value={formData?.company_domain[index]?.domain_name}
                                          type="text"
                                          sx={{
                                            fontSize: '14px',
                                            border: 'none !important',

                                            borderRadius: '8px !important',

                                            '& .MuiInputBase-input': { borderRadius: '8px !important', p: '8px 10px 8px 10px !important' },
                                            '& .MuiOutlinedInput-root': {
                                              background: '#f5f7fa',
                                              border: 'none',
                                              borderRadius: '8px !important',

                                              '& fieldset': {

                                                border: "none",
                                                p: 0,
                                                borderRadius: '8px !important',
                                              },
                                              '&:hover fieldset': {

                                                border: "none",
                                                p: 0,
                                                borderRadius: '8px !important',

                                              },
                                              '&.Mui-focused fieldset': {

                                                border: "none",
                                                p: 0,
                                                borderRadius: '8px !important',

                                              },
                                              '&.Mui-error fieldset': {

                                                border: "none",
                                                p: 0,
                                                borderRadius: '8px !important',

                                              },
                                            },
                                            '& .Mui-focused fieldset': {

                                              border: "none",
                                              p: 0,
                                              borderRadius: '8px !important',

                                            },
                                            '& input::placeholder': {
                                              fontSize: {
                                                xl: '1rem',
                                                lg: '0.9rem',
                                                md: '0.85rem',
                                              },
                                            },
                                          }}
                                          style={{

                                            backgroundColor: '#ededed',
                                          }}
                                          onChange={(e) => {
                                            const updatedCompanyDomain = [...formData.company_domain];
                                            updatedCompanyDomain[index] = {
                                              domain_name: e.target.value,
                                            };
                                            setFormData({
                                              ...formData,
                                              company_domain: updatedCompanyDomain,
                                            });
                                          }}
                                          disabled={formData?.skip_domain}
                                        />
                                        {formData?.company_domain?.length > 1 &&
                                          <img src={DeletePng} className="icon-sm" style={{}}

                                            onClick={() => {
                                              const updatedArray = formData?.company_domain?.filter(
                                                (_, i) => i !== index
                                              );
                                              setFormData({
                                                ...formData,
                                                company_domain: updatedArray
                                              });
                                            }}
                                            sx={{
                                              // marginRight: '8px',
                                              cursor: 'pointer',
                                            }}
                                          />
                                        }
                                      </Box>
                                    </Box>
                                  );
                                })
                                : null}
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} sx={{ mt: 1.5 }}>
                          <Typography
                            sx={{
                              my: '20px !important',
                              cursor: 'pointer',
                              transition: '0.3s ease-in-out',
                              display: "inline",
                              marginTop: '10px',
                              borderRadius: '10px',
                              fontSize: { xl: '15px', lg: '12px', md: '11.5px' },
                              '&:hover': {

                              },
                              color: '#711FFF',
                            }}
                            onClick={() => {

                              if (!formData.skip_domain) {

                                setFormData({
                                  ...formData,
                                  company_domain: [...formData.company_domain, {
                                    domain_name: ''
                                  }]
                                })
                              }
                            }}

                          >+ web adress</Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} sx={{ mt: 1 }}>
                          <Box sx={{ display: 'flex', alignItems: 'center', }} >
                            <NetNetCheckBox
                              checked={formData.skip_domain}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setFormData({
                                    ...formData,
                                    skip_domain: true,
                                    company_domain: [{
                                      domain_name: ''
                                    }],
                                  });
                                } else {
                                  setFormData({
                                    ...formData,
                                    skip_domain: false,

                                  });
                                }
                              }}
                            />
                            <Typography
                              sx={{
                                color: 'GrayText',
                                ml: 1,
                                fontSize: {
                                  xl: '14px',
                                  lg: '12px',
                                  md: '11.5px',
                                },
                              }}
                            >
                              I do not have a web adress
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box sx={{
                      width: '100%', px: 2, display: { md: 'none' }

                    }}>
                      {error && <Alert severity="info">{errorMessage}</Alert>}
                      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', pt: 1.8 }}>
                        {activeStep > 1 && (
                          <Button
                            color="inherit"
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            sx={{ mr: 1 }}
                          >
                            Back
                          </Button>
                        )}
                        <button
                          className="create-btn"
                          onClick={(e) => {

                            handleNext(e);
                            // }
                          }}
                        >
                          {activeStep === 3 ? 'Finish' : 'Next'}
                        </button>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          )}
          {activeStep === 1 &&
            <Box sx={{
              width: { md: '42%', md: '43%', xl: '45%' }, display: { xs: 'none', md: 'block' },
              position: 'absolute',
              top: { xs: '81%', md: '82%', xl: '85%' },
              left: { md: '73%', md: '72.5%', lg: '74%' },
              transform: 'translate(-50%, -50%)',

            }}>
              <div style={{ height: '40px' }}>
                {error && <Alert severity="info">{errorMessage}</Alert>}

              </div>
              <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', pt: 1.8 }}>
                {activeStep > 1 && (
                  <Button
                    color="inherit"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                  >
                    Back
                  </Button>
                )}
                <button
                  className="create-btn"
                  onClick={(e) => {
                    handleNext(e);
                  }}
                >
                  {activeStep === 3 ? 'Finish' : 'Next'}
                </button>
              </Box>
            </Box>
          }
          {activeStep === 2 && (
            <Box sx={{ p: 2, overflow: 'auto' }}>
              <Typography
                sx={{
                  fontSize: { xl: '25px', lg: '22px', md: '20px' },
                  letterSpacing: 2,
                  textAlign: 'center',
                }}
              >
                Step 2 : Team
              </Typography>
              <Typography
                sx={{
                  color: '#36e0b3',
                  fontSize: { xl: '15px', lg: '13px', md: '12px', sm: '12px', xs: '10px' },
                  fontWeight: '600',
                  textAlign: 'center',
                  mb: 2,
                }}
              >
                want to invite some of your team {currentUser?.first_name ?? ''}
              </Typography>
              <Grid container spacing={0} sx={{ mt: 2 }}>
                <Grid item xs={12} md={6} sx={{ overflow: { md: 'scroll' }, scrollbarWidth: 'none', height: { md: '45vh' }, borderRight: { xs: 'none', md: "2px solid #E0E0DF" } }}>
                  <Box sx={{ p: 2, pt: 0 }}>
                    <Box sx={{ mt: 2 }}>
                      <Box sx={{ position: 'relative' }}>
                        <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                          <iframe
                            src='https://videos.hellonetnet.com/watch/b7zZfa0z'
                            frameBorder="8"
                            style={{
                              width: '100%',
                              height: '30vh',
                              borderRadius: '10px 10px 0px 0px',
                            }}
                          >
                          </iframe>
                        </Box>
                        <Box sx={{ display: { xs: 'none', sm: 'block', md: 'none' } }}>
                          <iframe
                            src='https://videos.hellonetnet.com/watch/b7zZfa0z'
                            frameBorder="8"
                            style={{
                              width: '100%',
                              height: '50vh',
                              borderRadius: '10px 10px 0px 0px',
                            }}
                          >
                          </iframe>
                        </Box>
                        <Box sx={{ display: { xs: 'none', md: 'block', lg: 'none' } }}>
                          <iframe
                            src='https://videos.hellonetnet.com/watch/b7zZfa0z'
                            frameBorder="8"
                            style={{
                              width: '100%',
                              height: '100%',
                              borderRadius: '10px 10px 0px 0px',
                            }}
                          >
                          </iframe>
                        </Box>
                        <Box sx={{ display: { xs: 'none', sm: 'none', lg: 'block', xl: 'none' } }}>
                          <iframe
                            src='https://videos.hellonetnet.com/watch/b7zZfa0z'
                            frameBorder="8"
                            style={{
                              width: '100%',
                              height: '100%',
                              borderRadius: '10px 10px 0px 0px',
                            }}
                          >
                          </iframe>
                        </Box>
                        <Box sx={{ display: { xs: 'none', xl: 'block' } }}>
                          <iframe
                            src='https://videos.hellonetnet.com/watch/b7zZfa0z'
                            frameBorder="8"
                            style={{
                              width: '100%',
                              height: '27vh',
                              borderRadius: '10px 10px 0px 0px',
                            }}
                          >
                          </iframe>
                        </Box>
                        <Box sx={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}>
                          <PlayArrowRoundedIcon
                            sx={{
                              color: '#fff',
                              height: '110px',
                              width: '110px',
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              if (isOn == true) {
                                setIsOn(false)
                                setIsHovered(false)
                              }
                              else {
                                setIsOn(true)
                              }
                            }}
                          />
                        </Box>
                      </Box>
                      {
                        isOn && <Box sx={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100vh', backgroundColor: 'rgba(0,0,0,0.6)', zIndex: 999999999, dispay: 'flex', alignItems: 'center', flexDirection: 'row' }}>
                          <Box sx={{ width: '70%', margin: '0 auto', height: '90%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', }}>
                            <Box sx={{ width: '100%', height: '30px', backgroundColor: 'gray', color: '#fff', display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '0px 5px' }}> <p> </p> <p style={{ marginTop: '0px' }}>Need help with the team step? Watch this!</p> <CloseIcon sx={{ color: '#fff', cursor: 'pointer' }} onClick={() => {
                              setIsOn(false)
                            }} />
                            </Box>
                            <iframe
                              src='https://videos.hellonetnet.com/watch/b7zZfa0z'
                              style={{
                                width: '100%',
                                height: '80%',
                              }}
                            >
                            </iframe>

                          </Box>
                        </Box>
                      }
                      <Box sx={{ display: 'flex', justifyContent: 'center' }}>

                        <Typography sx={{ width: { xs: '100%', md: '240px', xl: '410px' }, fontSize: { xs: '10px', sm: '14px', md: '11px', xl: '14px' }, py: 0.3, textAlign: 'center', backgroundColor: '#f4f0f5', borderEndStartRadius: '10px', borderEndEndRadius: '10px', }}>
                          Need help with the team step? Watch this!
                        </Typography>
                      </Box>

                    </Box>
                    <Box sx={{ py: 2, display: { xs: 'block', md: 'flex' }, justifyContent: 'center' }}>
                      <Box sx={{ width: { md: '75%' } }}>
                        <Typography
                          sx={{
                            color: '#711FFF',
                            fontSize: { xl: '18px', lg: '16px', sm: '14px', xs: '12px' },
                            fontWeight: '800',
                            textAlign: 'center',
                            pb: 0.5,
                          }}
                        >
                          Access Levels
                        </Typography>
                        <Typography
                          sx={{
                            color: '#36e0b3',
                            fontSize: { xl: '15px', lg: '13px', md: '12px', xs: '10px' },
                            fontWeight: '600',
                            textAlign: 'left',
                            lineHeight: 1.2,

                          }}
                        >
                          Member = A regular, non managing team member who works on their own stuff
                        </Typography>
                        <Typography
                          sx={{
                            color: '#36e0b3',
                            fontSize: { xl: '15px', lg: '14px', sm: '12px', xs: '10px' },
                            fontWeight: '600',
                            textAlign: 'left',
                            lineHeight: 1.2,
                            mt: 2
                          }}
                        >
                          Lead = Member + manages their projects, retainers, tasks and assigns work to others on the team
                        </Typography>
                        <Typography
                          sx={{
                            color: '#36e0b3',
                            fontSize: { xl: '15px', lg: '14px', sm: '12px', xs: '10px' },
                            fontWeight: '600',
                            textAlign: 'left',
                            lineHeight: 1.2,
                            mt: 2
                          }}
                        >
                          Admin = Lead + controls account-wide settings & invitations
                        </Typography>
                        <Typography
                          sx={{
                            color: '#36e0b3',
                            fontSize: { xl: '15px', lg: '14px', sm: '12px', xs: '10px' },
                            fontWeight: '600',
                            textAlign: 'left',
                            lineHeight: 1.2,
                            mt: 2
                          }}
                        >
                          Owner = Admin + Controls billing.
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} sx={{ overflow: { md: "scroll" }, scrollbarWidth: 'none', height: { md: "35vh" } }}>
                  <Box sx={{ display: "flex", flexDirection: 'column', justifyContent: 'space-between', px: 2, minHeight: { md: '410px' } }}>
                    <Box>
                      <Typography
                        sx={{
                          color: '#711FFF',
                          fontSize: { xl: '15px', lg: '13px', md: '12px', xs: '10px' },
                          fontWeight: '500',
                          mb: 2
                        }}
                      >
                        {' '}
                        you can add some or all of them now or any time later.
                      </Typography>

                      {/* ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}

                      <Box sx={{}}>

                        {
                          Array.isArray(teamMemberFiledLength) && teamMemberFiledLength.map((v, i) => {
                            return (
                              <Box sx={{ borderRadius: 2, p: '8px', borderRadius: '8px', border: '1.5px solid #d9dadb', display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2 }}>
                                <Box sx={{ width: '70%' }}>
                                  <input
                                    placeholder="leia.organa@starwars.com"
                                    type="email"
                                    required
                                    style={{
                                      width: '100%',
                                      borderRadius: '8px',

                                      background: '#f5f7fa',
                                      border: 'none',
                                      outline: 'none',
                                      padding: '9px 10px 9px 10px',
                                      // pt:2,
                                    }}
                                    value={teamMember[i].email}
                                    onChange={(e) => handleMemberEmailChange(e, i)}
                                  />
                                </Box>
                                <Box sx={{ width: '30%', ml: 2 }}>
                                  <Autocomplete
                                    fullWidth
                                    size='small'
                                    id="country"
                                    variant='standard'
                                    includeInputInList
                                    value={teamMember[i].role}
                                    onChange={(_, value) => handleMemberAccessLevelChange(value, i)}
                                    options={levels}
                                    getOptionLabel={(option) => option}
                                    sx={{
                                      border: 'none !important',
                                      borderRadius: '8px !important',
                                      '& .MuiAutocomplete-input': {
                                        padding: '5px 0px 5px 0px !important',

                                      },

                                      '& .css-jx3fhb-MuiInputBase-root-MuiInput-root:before': {
                                        borderBottomColor: '#711fff !important', // Change focus color
                                      },
                                      '& .css-15ibws3-MuiAutocomplete-root .MuiAutocomplete-input:focus': {
                                        borderBottomColor: '#711fff !important', // Change focus color
                                      },
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}

                                        placeholder='Access Level'
                                        size='small'
                                        variant="standard"

                                        color="secondary"
                                        sx={{

                                        }}
                                        InputProps={{
                                          ...params.InputProps,

                                        }}
                                      />
                                    )}
                                  />
                                </Box>
                              </Box>
                            )
                          })
                        }
                      </Box>
                      <Box sx={{ mt: 1 }}>
                        <Typography sx={{ color: '#711fff' }} onClick={addTeamMemberField}>+ Team Member</Typography>
                      </Box>
                      {/* /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}

                      {error && <Alert severity='error'>{errorMessage}</Alert>}
                    </Box>
                    <Box sx={{ display: { xs: 'flex', md: 'none' }, justifyContent: 'space-between', mt: 2 }}>
                      <Box sx={{}}>
                        {activeStep > 1 && (
                          <Button
                            onClick={handleBack}
                            style={{
                              mr: 1,
                              backgroundColor: '#fff',
                              width: '90px',
                              border: '2px solid #711fff',
                              borderRadius: '5px',
                              color: "#711fff",
                              '&:hover': {
                                backgroundColor: '#711fff',
                                color: '#fff',
                              },
                            }}
                          >
                            Back
                          </Button>
                        )}
                      </Box>
                      <Box>
                        <Tooltip
                          title={!teamEmail ? 'Please add some team members' : ''}
                        >
                          <button
                            className="create-btn"
                            onClick={async (e) => {
                              if (activeStep === 2) {
                                let error = true
                                teamMember.forEach(member => {
                                  if (member.email !== '') {
                                    const isValidTeamEmail = member.email.match(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/)
                                    if (!isValidTeamEmail) {
                                      setError(true)
                                      setErrorMessage('Invalid email for member: ' + member.email);
                                      error = false
                                      return
                                    }
                                  }
                                  if (member.email === '' && (member.role === 'Owner' || member.role === 'Admin' || member.role === 'Lead' || member.role === 'Member')) {

                                    setError(true)
                                    setErrorMessage('Email is missing for this Access level: ' + member.role)
                                    error = false
                                  }
                                  if (member.email && member.email.trim() !== '' && (member.role === null || member.role === '')) {
                                    setError(true);
                                    setErrorMessage('Access level is missing for this email: ' + member.email);
                                    error = false
                                    return
                                  }
                                });
                                if (error) {
                                  const filteredTeamMember = teamMember.filter(member =>
                                    member.email && member.email.trim() !== '' &&
                                    member.role && member.role.trim() !== ''
                                  );

                                  setFormData({
                                    ...formData,
                                    invitation: filteredTeamMember,
                                  });

                                  const response = await fetch(
                                    `${ENDPOINT}/api/user/userpersonalization/`,
                                    {
                                      method: 'PUT',
                                      headers: {
                                        Authorization: `JWT ${access_token}`,
                                        'Content-Type': 'application/json',
                                      },
                                      body: JSON.stringify({
                                        invitation: filteredTeamMember
                                      }),
                                    }
                                  );
                                  const data = await response?.json();
                                  if (!response.ok) {
                                    setLoading(false);
                                    setError(true);

                                    for (const key in data) {
                                      setErrorMessage(data[key]);
                                    }
                                  }
                                  else {
                                    // setNewTeam([])
                                    setActiveStep(activeStep + 1)
                                    setLoading(false);
                                  }
                                  handleNext(e);

                                }
                              }
                            }}
                          >
                            {loading ? <Box sx={{ width: '30px', height: '30px' }}><PreLoader1 /></Box> : (activeStep === 3 ? 'Finish' : 'Next')}
                          </button>
                        </Tooltip>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          )}
          {
            activeStep === 2 &&
            <Box sx={{
              width: { md: '42%', md: '43%', xl: '45%' }, display: { xs: 'none', md: 'block' },
              position: 'absolute',
              top: { xs: '81%', md: '82%', xl: '85%' },
              left: { md: '73%', md: '72.5%', lg: '74%' },
              transform: 'translate(-50%, -50%)',
            }}>
              <div style={{ height: '40px' }}>
                {error && <Alert severity='error'>{errorMessage}</Alert>}
              </div>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                <Box>
                  {activeStep > 1 && (
                    <Button
                      onClick={handleBack}
                      style={{
                        mr: 1,
                        backgroundColor: '#fff',
                        width: '90px',
                        border: '2px solid #711fff',
                        borderRadius: '5px',
                        color: "#711fff",
                        '&:hover': {
                          backgroundColor: '#711fff',
                          color: '#fff',
                        },
                      }}
                    >
                      Back
                    </Button>
                  )}
                </Box>
                <Box>
                  <Tooltip
                    title={!teamEmail ? 'Please add some team members' : ''}
                  >
                    <button
                      className="create-btn"
                      onClick={async (e) => {
                        if (activeStep === 2) {
                          let error = true
                          teamMember.forEach(member => {
                            if (member.email !== '') {
                              const isValidTeamEmail = member.email.match(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/)
                              if (!isValidTeamEmail) {
                                setError(true)
                                setErrorMessage('Invalid email for member: ' + member.email);
                                error = false
                                return
                              }
                            }
                            if (member.email === '' && (member.role === 'Owner' || member.role === 'Admin' || member.role === 'Lead' || member.role === 'Member')) {
                              setError(true)
                              setErrorMessage('Email is missing for this Access level: ' + member.role)
                              error = false
                            }
                            if (member.email && member.email.trim() !== '' && (member.role === null || member.role === '')) {
                              setError(true);
                              setErrorMessage('Access level is missing for this email: ' + member.email);
                              error = false
                              return
                            }
                          });
                          if (error) {
                            const filteredTeamMember = teamMember.filter(member =>
                              member.email && member.email.trim() !== '' &&
                              member.role && member.role.trim() !== ''
                            );
                            setFormData({
                              ...formData,
                              invitation: filteredTeamMember,
                            });
                            const response = await fetch(
                              `${ENDPOINT}/api/user/userpersonalization/`,
                              {
                                method: 'PUT',
                                headers: {
                                  Authorization: `JWT ${access_token}`,
                                  'Content-Type': 'application/json',
                                },
                                body: JSON.stringify({
                                  invitation: filteredTeamMember,
                                  company_name: formData.company_name,
                                }),
                              }
                            );
                            const data = await response?.json();
                            if (!response.ok) {
                              setLoading(false);
                              setError(true);
                              for (const key in data) {
                                setErrorMessage(data[key]);
                              }
                            }
                            else {
                              setActiveStep(activeStep + 1)
                              setLoading(false);
                            }
                            handleNext(e);
                          }
                        }
                      }}
                    >
                      {loading ? <Box sx={{ width: '30px', height: '30px' }}><PreLoader1 /></Box> : (activeStep === 3 ? 'Finish' : 'Next')}
                    </button>
                  </Tooltip>
                </Box>
              </Box>
            </Box>
          }
          {activeStep === 3 && (
            <Box sx={{ p: 2 }}>
              <Typography
                sx={{
                  fontSize: { xl: '25px', lg: '22px', md: '20px' },
                  letterSpacing: 2,
                  textAlign: 'center',
                }}
              >
                Step 3 : Work Types
              </Typography>
              <Typography
                sx={{
                  color: '#36e0b3',
                  fontSize: { xl: '15px', lg: '13px', md: '12px', sm: '12px', xs: '10px' },
                  fontWeight: '600',
                  textAlign: 'center',
                }}
              >
                Sweet {currentUser?.first_name ?? ''} Final Step is SUPER IMPORTANT. we need you to tell us the "Work Types" that your business offers.
              </Typography>
              <Grid container spacing={0} sx={{ mt: 2 }}>
                <Grid item xs={12} md={6} sx={{ overflow: { md: 'scroll' }, scrollbarWidth: 'none', height: { md: '45vh' }, borderRight: { xs: 'none', md: "2px solid #E0E0DF" } }}>
                  <Box sx={{ p: 2, pt: 0 }}>

                    <Box sx={{ position: 'relative', mt: 1, }}>
                      <Box sx={{ display: { xs: 'block', sm: 'none' } }} >
                        <iframe
                          src='https://videos.hellonetnet.com/watch/eR3Klv7P'
                          className='iframe'
                          frameBorder="8"
                          style={{
                            width: '100%',
                            height: "30vh",
                            borderRadius: '10px 10px 0px 0px',
                          }}
                        >
                        </iframe>
                      </Box>
                      <Box sx={{ display: { xs: 'none', sm: 'block', md: 'none' } }} >
                        <iframe
                          src='https://videos.hellonetnet.com/watch/eR3Klv7P'
                          className='iframe'
                          frameBorder="8"
                          style={{
                            width: '100%',
                            height: "50vh",
                            borderRadius: '10px 10px 0px 0px',
                          }}
                        >
                        </iframe>
                      </Box>
                      <Box sx={{ display: { xs: 'none', md: 'block', lg: 'none' } }} >
                        <iframe
                          src='https://videos.hellonetnet.com/watch/eR3Klv7P'
                          className='iframe'
                          frameBorder="8"
                          style={{
                            width: '100%',
                            height: "100%",
                            borderRadius: '10px 10px 0px 0px',
                          }}
                        >
                        </iframe>
                      </Box>
                      <Box sx={{ display: { xs: 'none', sm: 'none', lg: 'block', xl: "none" } }} >
                        <iframe
                          src='https://videos.hellonetnet.com/watch/eR3Klv7P'
                          className='iframe'
                          frameBorder="8"
                          style={{
                            width: '100%',
                            height: "100%",
                            borderRadius: '10px 10px 0px 0px',
                          }}
                        >
                        </iframe>
                      </Box>
                      <Box sx={{ display: { xs: 'none', xl: 'block' } }} >
                        <iframe
                          src='https://videos.hellonetnet.com/watch/eR3Klv7P'
                          className='iframe'
                          frameBorder="8"
                          style={{
                            width: '100%',
                            height: "27vh",
                            borderRadius: '10px 10px 0px 0px',
                          }}
                        >
                        </iframe>
                      </Box>

                      <Box sx={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}>
                        <PlayArrowRoundedIcon
                          sx={{
                            color: '#fff',
                            height: '110px',
                            width: '110px',
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            if (isOn == true) {
                              setIsOn(false)
                              setIsHovered(false)
                            }
                            else {
                              setIsOn(true)
                            }
                          }}
                        />
                      </Box>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>

                      <Typography sx={{ width: { xs: '100%', md: '240px', xl: '410px' }, fontSize: { xs: '10px', sm: '14px', md: '11px', xl: '14px' }, py: 0.3, textAlign: 'center', backgroundColor: '#f4f0f5', borderEndStartRadius: '10px', borderEndEndRadius: '10px', }}>
                        Need help with Work Types step? Watch this?!
                      </Typography>
                    </Box>
                    {
                      isOn && <Box sx={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100vh', backgroundColor: 'rgba(0,0,0,0.6)', zIndex: 999999999, dispay: 'flex', alignItems: 'center', flexDirection: 'row' }}>
                        <Box sx={{ width: '70%', margin: '0 auto', height: '90%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', }}>
                          <Box sx={{ width: '100%', height: '30px', backgroundColor: 'gray', color: '#fff', display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '0px 5px' }}> <p> </p> <p style={{ marginTop: '0px' }}>Need help with Work Types step? Watch this?!</p> <CloseIcon sx={{ color: '#fff', cursor: 'pointer' }} onClick={() => {
                            setIsOn(false)

                          }} /> </Box>
                          <iframe
                            src='https://videos.hellonetnet.com/watch/eR3Klv7P'

                            frameBorder="8"
                            style={{
                              width: '100%',
                              height: '80%',
                            }}
                          >
                          </iframe>
                        </Box>
                      </Box>
                    }

                  </Box>

                  <Box sx={{ p: 2, display: { xs: 'block', md: 'flex' }, justifyContent: 'center' }}>
                    <Box sx={{ width: { md: '75%' } }}>
                      <Typography
                        sx={{
                          color: '#711FFF',
                          fontSize: { xl: '18px', lg: '16px', sm: '14px', xs: '12px' },
                          fontWeight: '800',
                          textAlign: 'center',
                          pb: 0.5,
                        }}
                      >
                        Pro Tips
                      </Typography>
                      <Typography
                        sx={{
                          color: '#36e0b3',
                          fontSize: { xl: '15px', lg: '13px', md: '12px', xs: '10px' },
                          fontWeight: '600',
                          textAlign: 'left',
                          lineHeight: 1.2,

                        }}
                      >
                        Regardless of the base rate you set for each work type, you will price things any way you want!  This is just for quick calculations and can be modified at any time.
                      </Typography>
                      <Typography
                        sx={{
                          color: '#36e0b3',
                          fontSize: { xl: '15px', lg: '14px', sm: '12px', xs: '10px' },
                          fontWeight: '600',
                          textAlign: 'left',
                          lineHeight: 1.2,
                          mt: 2
                        }}
                      >
                        Internal work types can have a $0 base rate.
                      </Typography>
                      <Typography
                        sx={{
                          color: '#36e0b3',
                          fontSize: { xl: '15px', lg: '14px', sm: '12px', xs: '10px' },
                          fontWeight: '600',
                          textAlign: 'left',
                          lineHeight: 1.2,
                          mt: 2
                        }}
                      >
                        If you charge for team time, just enter their name and rate.
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} sx={{ overflow: { md: "scroll" }, scrollbarWidth: 'none', height: { md: "35vh" } }}>
                  <Box sx={{ px: 2, display: 'flex', flexDirection: "column", justifyContent: 'space-between', minHeight: { md: '410px' } }}>
                    <Box>
                      <Typography
                        sx={{
                          color: '#711FFF',
                          fontSize: { xl: '15px', lg: '13px', md: '12px', xs: '10px' },
                          fontWeight: '500',
                          marginBottom: '10px',
                          mt: { xs: 2, md: 0 }
                        }}
                      >
                        please add at least 3 Work Types
                      </Typography>

                      <Box sx={{ my: 2 }}>
                        {work.map((item, index) => {
                          return (
                            <Box
                              ref={index === work.length - 1 ? workRef : null}
                              sx={{
                                borderRadius: '8px',
                                border: '1.5px solid #d9dadb',
                                marginTop: '8px',
                                marginBottom: '5px',
                                padding: '7px 7px',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                              }}
                            >
                              <Box
                                sx={{
                                  width: '95%',
                                  display: 'flex',
                                  flexDirection: { xs: 'column', sm: 'row' },
                                  flexWrap: 'wrap',
                                  gap: '10px',
                                }}
                              >
                                <Box
                                  sx={{
                                    width: { xs: "95%", sm: '50%' },

                                    padding: '10px 0px',

                                    background: '#f5f7fa',
                                    borderRadius: '8px',
                                    fontSize: {
                                      xl: '14px',
                                      lg: '12px',
                                      md: '11.5px',
                                    },
                                  }}
                                >
                                  <input
                                    value={item.name}
                                    style={{
                                      width: '80%',
                                      border: 'none',
                                      background: 'none',
                                      outline: 'none',
                                      paddingLeft: '10px',

                                    }}
                                    onChange={(e) => {
                                      setWork((prev) => {
                                        const shallowCopy = prev.map((mm) => ({
                                          ...mm,
                                        }));

                                        shallowCopy[index].name = e.target.value;
                                        return shallowCopy;
                                      });
                                    }}
                                    placeholder={
                                      index === 0 ? 'Programing' :
                                        index === 1 ? 'Graphic Design' :
                                          index === 2 ? 'IT Support' :
                                            'Work Type'
                                    }
                                  />
                                </Box>
                                <Box
                                  sx={{
                                    display: 'flex',
                                    width: { xs: "95%", sm: '45%' },
                                    background: '#f5f7fa',
                                    borderRadius: '8px',
                                    textAlign: 'center',
                                    border: '0.5px solid #d9dadb',
                                    alignItems: 'center',
                                  }}
                                >
                                  <Box
                                    sx={{
                                      width: '50%',
                                      padding: '10px 0px',
                                      fontSize: {
                                        xl: '14px',
                                        lg: '12px',
                                        md: '11.5px',
                                      },
                                    }}
                                  >
                                    <input
                                      value={item.user_rate}
                                      onChange={(e) => {
                                        setWork((prev) => {
                                          const shallowCopy = prev.map((mm) => ({
                                            ...mm,
                                          }));
                                          const index = shallowCopy.findIndex(
                                            (ent) => ent.dent === item.dent
                                          );
                                          shallowCopy[index].user_rate =
                                            e.target.value;
                                          return shallowCopy;
                                        });
                                      }}
                                      style={{
                                        width: '100%',
                                        border: 'none',
                                        background: 'none',
                                        outline: 'none',
                                        textAlign: 'center !important',
                                        textAlign: 'center',

                                      }}
                                      type="number"
                                      placeholder={
                                        index === 0 ? "$ 150" :
                                          index === 1 ? "$ 125" :
                                            index === 2 ? "$ 100" :
                                              "$ 150"

                                      }
                                      min="1"
                                    />
                                  </Box>
                                  <Box
                                    sx={{
                                      width: '50%',
                                      backgroundColor: '#F2EBFF',
                                      py: 1,
                                      borderStartEndRadius: '5px',
                                      borderEndEndRadius: '5px',
                                      fontSize: {
                                        xl: '14px',
                                        lg: '12px',
                                        md: '11.5px',
                                      },
                                    }}
                                  >
                                    <p style={{ color: '#000' }}>USD / Hour</p>
                                  </Box>
                                </Box>
                              </Box>
                              <img src={DeletePng} className="icon-sm" style={{}}

                                onClick={() => {
                                  const updatedArray = work.filter(
                                    (_, i) => i !== index
                                  );
                                  setWork(updatedArray);
                                }}
                                sx={{
                                  marginRight: '8px',
                                  color: '#000',
                                  cursor: 'pointer',
                                }}
                              />
                            </Box>
                          );
                        })}
                      </Box>


                      <Typography sx={{ cursor: 'pointer', color: '#711FFF', borderRadius: 1, display: 'inline', my: 2, marginTop: "20px !important", fontSize: { xl: '14px', lg: '12px', md: '11.5px' }, }}
                        onClick={() => {
                          setWork((prev) => {
                            if (Array.isArray(prev) && prev.length) {
                              return [
                                ...prev.map((item) => {
                                  return {
                                    ...item,
                                  };
                                }),
                                {
                                  dent: `NetWt${Number(
                                    prev[prev.length - 1].dent.match(/\d+/)[0]
                                  ) + 1
                                    }`,
                                  name: '',
                                  user_rate: '',
                                },
                              ];
                            } else {
                              return [
                                {
                                  dent: `NetWt${1}`,
                                  name: '',
                                  user_rate: '',
                                },
                              ];
                            }
                          });
                        }}
                      >
                        + Work Type
                      </Typography>
                    </Box>
                    <Box sx={{ display: { xs: 'flex', md: 'none' }, justifyContent: 'space-between', mt: 2 }}>
                      <Box>
                        {activeStep > 1 && (
                          <Button
                            onClick={handleBack}
                            style={{
                              mr: 1,
                              backgroundColor: '#fff',
                              width: '100px',
                              border: '2px solid #711fff',
                              borderRadius: '5px',
                              color: '#711fff',
                              '&:hover': {
                                backgroundColor: '#711fff',
                                color: '#fff',
                              },
                            }}
                          >
                            Back
                          </Button>
                        )}
                      </Box>
                      <Box>
                        <button className="create-btn" onClick={handleFinish}>
                          {loading ? (
                            <Box sx={{ width: '30px', height: '30px', margin: '0rem 0.1rem' }}><PreLoader1 /></Box>
                          ) : (
                            'Finish Onboarding'
                          )}{' '}
                        </button>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          )}
          {
            activeStep === 3 &&
            <Box sx={{
              width: { md: '42%', md: '43%', xl: '45%' }, display: { xs: 'none', md: 'block' },
              position: 'absolute',
              top: { xs: '81%', md: '82%', xl: '85%' },
              left: { md: '73%', md: '72.5%', lg: '74%' },
              transform: 'translate(-50%, -50%)',
            }}>
              <div style={{ height: '40px' }}>
                {error && <Alert style={{ marginTop: '10px' }} severity="info">{errorMessage}</Alert>}
              </div>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                <Box>
                  {activeStep > 1 && (
                    <Button
                      onClick={handleBack}
                      style={{
                        mr: 1,
                        backgroundColor: '#fff',
                        width: '100px',
                        border: '2px solid #711fff',
                        borderRadius: '5px',
                        color: '#711fff',
                        '&:hover': {
                          backgroundColor: '#711fff',
                          color: '#fff',
                        },
                      }}
                    >
                      Back
                    </Button>
                  )}


                </Box>

                <Box>
                  <button className="create-btn" onClick={handleFinish}>
                    {loading ? (
                      <Box sx={{ width: '30px', height: '30px', margin: '0rem 0.1rem' }}><PreLoader1 /></Box>
                    ) : (
                      'Finish Onboarding'
                    )}{' '}
                  </button>

                </Box>

              </Box>
            </Box>
          }
        </Box>
      </Box>
    </Grid>
  );
};

export default function InnerApp() {
  const currentUser = useSelector((state) => state.userData.userData);
  const access = useSelector((state) => state.auth.user.access);
  const isImporting = useSelector(
    (state) => state.Contacts?.importing ?? false
  );

  const [isImportingStill, setIsImporting] = useState(false);
  const access_token = access;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const checkInvites = async () => {
    const response = await fetch(`${ENDPOINT}/api/user/userdetail/`, {
      method: 'GET',
      headers: {
        Authorization: `JWT ${access}`,
        'Content-Type': 'application/json',
      },
    });
    const data = await response.json();
    if (response.ok) {
      dispatch(userData(data));
    } else {

      dispatch(logout());
      navigate('/Login');
    }
  };

  useEffect(() => {
    checkInvites();
  }, []);

  const [showModal, setShowModal] = useState(false);
  const [runAllRequest, setRunAllRequest] = useState(false)
  useEffect(() => {
    dispatch(getWorkType({ access_token }));
    dispatch(getWorkTypes({ access_token }));
    dispatch(getTimeTracking({ access_token }))
    dispatch(getMessages({ access_token }));
    dispatch(getTeam({ access_token }));
    dispatch(getUserTeam({ access_token }));
    dispatch(getQuickTask({ access_token }));
    dispatch(getProjects({ access_token }));
    dispatch(getEstimates({ access_token }));
    dispatch(getOpportunity({ access_token }));
    dispatch(GetFav(true));
    dispatch(getPersons({ access_token }))
    dispatch(getCompanies({ access_token }))
    dispatch(New_channels({ access_token }))
  }, []);
  const [currentProject, setCurrentProject] = useState()
  const [localProjectState, setLocalProjectState] = useState([]);
  const [currentProDils, setCurrentDil] = useState()


  const [show, setShow] = useState(false);

  useEffect(() => {
    const myInterval = setInterval(() => {
      dispatch(getNotifications({ access_token }));

    }, 3000);
    return () => clearInterval(myInterval);
  }, []);

  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const handleResize = () => {
    setScreenSize(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const [completedPersonalize, setCompletedPersonalize] = useState(false);
  const [estimateMsg, setEstimateMessage] = useState([])
  const [showNav, setShowNav] = useState(false);
  const sideNavData = [
    {
      name: 'Me',
      loc: '/',
    },
    {
      name: 'Chat',
      loc: '/chat',
    },
    {
      name: 'Project & Tasks',
      loc: '/Tasks',
    },
    {
      name: 'Contacts',
      loc: '/People',
    },
    {
      name: 'Sales',
      loc: '/opportunities',
    },
    {
      name: 'Reports',
      loc: '/dashboard',
    },
    {
      name: 'NetNet U',
      loc: '/NetNet_U',
    },
  ];

  const [detailTask, setDetailTask] = useState();
  const [projectDetail, setProjectDetail] = useState(false)
  const [currentTarget, setCurrentTarget] = useState({
    id: 1,
    message_in_channel: [],
    name: '',
    created: '2022-12-06T05:51:05.958013Z',
    created_by: 1,
    project: [],
    team: [
      {
        id: 1,
        member: [
          {
            id: 1,
            first_name: 'NaN',
          },
        ],
      },
    ],
  });
  const [currentPage, setCurrentPage] = useState('');

  const url = window.location.href;
  useEffect(() => {
    const page = url.split('/')[3];
    setCurrentPage(page);
  }, [url]);

  const [page, setPage] = useState('allconversation');
  const [selected, setSelected] = useState();
  const [see, setSee] = useState(true);
  const [isOn, setIsOn] = useState(false)


  const [state, setState] = React.useState({
    bottom: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const [showDrop, setShowDrop] = useState(false);
  const [converToEst, setConvertToEst] = useState()
  const [settings, setSettings] = useState(false)

  const [isReady, setIsReady] = useState(false)

  useEffect(() => {
    if (currentUser) {
      setTimeout(() => {
        setIsReady(true)
      }, 2000);
    }
  }, [currentUser])

  const [html, setHtml] = useState('')

  const [screenHeight, setScreenHeight] = useState(window.innerHeight);

  const handleResize2 = () => {
    setScreenHeight(window.innerHeight); // Use window.innerHeight to get the correct height
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize2);
    return () => {
      window.removeEventListener('resize', handleResize2);
    };
  }, []);

  const [proLoading, setProLoading] = useState(false)
  const [currentTargetOfEstimate, setCurrentTargetOfEstiimate] = useState()
  const [estimateProLoading, setEstimateProLoading] = useState(false)


  const { version } = packageFile
  const isProduction = process.env.NODE_ENV === "production"


  return (

    <CacheBuster
      currentVersion={version}
      isEnabled={isProduction}
      isVerboseMode={false}
      metaFileDirectory={'.'}
    >
      {currentUser && isReady ? <>
        <div
          container
          className="inner-app"
          direction="column"
          sx={{
            overflow: screenSize > 1300 ? 'hidden' : 'auto',
            // overflowX: 'auto',
            height: '100%',
          }}
        >
          <Grid item className="top-nav" xl={12} lg={12}>
            {screenSize > 600 && currentPage !== 'SuperAdmin' && <TopNav toggleDrawer2={toggleDrawer} state2={state} setState2={setState} setShowModal={setShowModal} setShowDrop={setShowDrop} />}
          </Grid>

          {isImporting && <Importing isImportingStill={isImportingStill} />}

          <div className="app-body">
            <div className="row">
              {currentPage !== 'SuperAdmin' && <div className='custom-col-first p-0'>
                <SideNav
                  setPage={setPage}
                  setChatcurrentTarget={setCurrentTarget}
                  isOn={isOn}
                  setEstimateMessage={setEstimateMessage}
                  setCurrentProject={setCurrentProject}
                  currentProject={currentProject}
                  setLocalProjectState={setLocalProjectState}
                  localProjectState={localProjectState}
                  setCurrentDil={setCurrentDil}
                  currentProDils={currentProDils}
                  setProLoading={setProLoading}
                  estimateProLoading={estimateProLoading}
                  setEstimateProLoading={setEstimateProLoading}
                  setCurrentTargetOfEstiimate={setCurrentTargetOfEstiimate}
                  currentTargetOfEstimate={currentTargetOfEstimate}
                />
              </div>}
              <div
                className={`${currentPage === 'SuperAdmin'
                    ? 'super-col-second page-col ps-4'
                    : `custom-col-second page-col ${screenSize < 1199 ? 'ps-2' : 'ps-0'}`
                  }`}
              >

                <Routes>
                  <Route exact path="/" element={currentUser?.skiped_welcome || screenSize < 700 ?
                    // <Dashboard />
                    <Welcome screenHeight={screenHeight} screenSize={screenSize} isOn={isOn} setIsOn={setIsOn} />
                    : <Welcome screenHeight={screenHeight} screenSize={screenSize} isOn={isOn} setIsOn={setIsOn} />} />
                  <Route exact path="/My_Tasks" element={<MyTasks screenHeight={screenHeight} />} />
                  {currentUser?.is_superadmin && <Route exact path="/SuperAdmin/*" element={<SuperAdmin />} />}
                  <Route
                    exact
                    path="/Opportunities"
                    element={screenSize < 600 ? <Sales /> : <Opportunity setConvertToEst={setConvertToEst} setShowDrop={setShowDrop} showDrop={showDrop} showModal={showModal} setShowModal={setShowModal} screenHeight={screenHeight} />}
                  />
                  <Route
                    exact
                    path="/My_Time"
                    element={
                      <MyTime selected={selected} setSelected={setSelected} screenHeight={screenHeight} />
                    }
                  />
                  <Route exact path="/Projects" element={<Project setProjectDetail={setProjectDetail} projectDetail={projectDetail} setSettings={setSettings} setShowModal={setShowModal} showModal={showModal} screenHeight={screenHeight} />} />
                  <Route
                    exact
                    path="/People"
                    element={screenSize < 600 ? <ListPeople /> : <People screenHeight={screenHeight} />}
                  />
                  {screenSize < 600 && <Route path="/Timer" element={<Timer />} />}

                  <Route path="/kpi" element={<KPI />} />
                  <Route
                    exact
                    path="/Tasks"
                    element={
                      screenSize < 600 ? (
                        <AllTask
                          setDetailTask={setDetailTask}
                          detailTask={detailTask}
                        />
                      ) : (
                        <QuickTasks toggleDrawer={toggleDrawer} state={state} setState={setState} screenHeight={screenHeight} />
                      )
                    }
                  />
                  <Route
                    exact
                    path="/Tasks/TaskDetails"
                    element={
                      screenSize < 600 ? (
                        <TaskDetails detailTask={detailTask} />
                      ) : (
                        <QuickTasks toggleDrawer={toggleDrawer} state={state} setState={setState} screenHeight={screenHeight} />
                      )
                    }
                  />
                  <Route exact path="/Company" element={<Companies screenHeight={screenHeight} />} />
                  <Route exact path="/Dashboard" element={<Dashboard screenHeight={screenHeight} />} />
                  <Route exact path="/New_Person" element={<NewPerson />} />
                  <Route exact path="/New_Company" element={<NewCompany />} />
                  <Route exact path="/Welcome" element={screenSize < 700 ?
                    //  <Dashboard /> 
                    <Welcome screenHeight={screenHeight} screenSize={screenSize} isOn={isOn} setIsOn={setIsOn} />
                    : <Welcome screenHeight={screenHeight} screenSize={screenSize} isOn={isOn} setIsOn={setIsOn} />} />
                  <Route
                    exact
                    path="/Chat"
                    element={
                      screenSize < 600 ? (
                        <ChatMob />
                      ) : (
                        <Stream
                          setPage={setPage}
                          page={page}
                          setCurrentTarget={setCurrentTarget}
                          currentTarget={currentTarget}
                          setEstimateMessage={setEstimateMessage}
                          estimateMsg={estimateMsg}
                          screenHeight={screenHeight}
                          setScreenHeight={setScreenHeight}
                          setCurrentProject={setCurrentProject}
                          currentProject={currentProject}
                          setLocalProjectState={setLocalProjectState}
                          localProjectState={localProjectState}
                          setCurrentDil={setCurrentDil}
                          currentProDils={currentProDils}
                          setProLoading={setProLoading}
                          proLoading={proLoading}
                          estimateProLoading={estimateProLoading}
                          setEstimateProLoading={setEstimateProLoading}
                          setCurrentTargetOfEstiimate={setCurrentTargetOfEstiimate}
                          currentTargetOfEstimate={currentTargetOfEstimate}
                        />
                      )
                    }
                  />
                  <Route exact path='/test' element={<Test hash={hashValues1} placeHolder={"Type Here.."} name={"myEditor"} peopleMention={peopleMention} setHtml={setHtml} html={html} />} />
                  <Route exact path="/Upload" element={<Import isImportingStill={isImportingStill} setIsImporting={setIsImporting} screenHeight={screenHeight} />} />
                  <Route exact path="/import/complete" element={<FifthStep />} />
                  <Route exact path="/My_Worktypes" element={<WorkTypes screenHeight={screenHeight} />} />
                  <Route exact path="/Collaborators" element={<Collab screenHeight={screenHeight} />} />
                  <Route exact path="/Team" element={<TeamMembers screenHeight={screenHeight} />} />
                  <Route
                    exact
                    path="/New_Project_Estimate"
                    element={<NewEstimate converToEst={converToEst} />}
                  />
                  <Route exact path="/New_Project" element={<NewProject projectDetail={projectDetail} setProjectDetail={setProjectDetail} screenHeight={screenHeight} />} />
                  <Route
                    exact
                    path="/NetNet_U"
                    element={screenSize < 600 ? <NetNetUMob screenHeight={screenHeight} /> : <NetNetU screenHeight={screenHeight} />}
                  />
                  <Route exact path="/All_Lessons" element={<AllLesson />} />
                  <Route exact path="/All_Courses" element={<AllCourse />} />
                  <Route
                    exact
                    path="/Course_Detail/:courseId"
                    element={<CourseDetail />}
                  />
                  <Route
                    exact
                    path="/Lesson_Detail/:courseId/:chapterId/:lessonId"
                    element={<LessonDetail />}
                  />
                  <Route
                    exact
                    path="/Person_Detail/:id"
                    element={<PersonDet />}
                  />
                  <Route
                    exact
                    path="/Company_Detail/:id"
                    element={<CompanyDet />}
                  />
                  <Route
                    exact
                    path="/Edit_Contact/:id"
                    element={<EditContent screenHeight={screenHeight} />}
                  />
                  <Route
                    exact
                    path="/Edit_Company/:id"
                    element={<EditCompany screenHeight={screenHeight} />}
                  />
                  <Route
                    exact
                    path="/Estimate_Detail/:id"
                    element={<EstDetail />}
                  />
                  <Route exact path="/Reports" element={<Reports screenHeight={screenHeight} />} />
                  <Route
                    exact
                    path="/Account_Personalization"
                    element={<AccPersonalization />}
                  />

                  <Route exact path="/My_Schedule" element={<MySchedule />} />

                  <Route
                    exact
                    path="/Account_Billing"
                    element={<AccountBilling screenHeight={screenHeight} />}
                  />

                  <Route exact path="/My_Profile" element={<MyProfile screenHeight={screenHeight} setRunAllRequest={setRunAllRequest} runAllRequest={runAllRequest} />} />

                  <Route
                    exact
                    path="/Collaboration/:unique_id"
                    element={<ColabEst />}
                  />
                  <Route
                    exact
                    path="/Project_Detail/:unique_id"
                    element={<ProjectDet settings={settings} setSettings={setSettings} screenHeight={screenHeight} />}
                  />
                  <Route exact path="/Templates" element={<Template screenHeight={screenHeight} />} />
                  <Route path="*" element={<NotFound />} />
                </Routes>
                {screenSize < 600 && (
                  <BottomNavigation setShowNav={setShowNav} showNav={showNav} />
                )}
                {showNav && screenSize < 600 && (
                  <Box>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                        backgroundColor: 'rgba(0,0,0,0.7)',
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        right: 0,
                        height: '100vh',
                        zIndex: 9999999999,
                      }}
                    >
                      <CloseIcon
                        onClick={() => setShowNav(false)}
                        sx={{
                          position: 'absolute',
                          top: '30%',
                          right: '20%',
                          color: '#fff',
                        }}
                      />
                      <Box
                        sx={{
                          color: '#fff',
                          fontSize: '20px',
                          textAlign: 'center',
                          zIndex: 9999999999,
                        }}
                      >
                        {sideNavData.map((item, index) => {
                          return (
                            <p
                              key={index}
                              onClick={() => {
                                navigate(item.loc);
                                setShowNav(false);
                              }}
                            >
                              {item.name}
                            </p>
                          );
                        })}
                        <p>{currentUser.first_name}</p>
                      </Box>
                    </Box>
                  </Box>
                )}
              </div>
            </div>
          </div>
        </div>

        {
          !currentUser?.profile_updated &&
          !completedPersonalize &&
          (
            <NewPersonalize
              setSee={setSee}
              see={see}
              setCompletedPersonalize={setCompletedPersonalize}
            />
          )}
      </>
        :
        <PreLoading />
      }
    </CacheBuster>
  )
}


const hashValues1 = [
  { id: 1, display: 'Rehan' },
  { id: 2, display: 'Keyboard' },
];

const peopleMention = [
  { id: 1, display: 'Herry' },
  { id: 2, display: 'HP' },
];
