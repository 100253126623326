import TextField from '@mui/material/TextField';
import { styled } from '@mui/system';
import PropTypes from 'prop-types';
import React from 'react';

const styles = (theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      borderColor: '#9c27b0 !important',
      height: '64px',
    },
  },

  cssFocused: {},

  notchedOutline: {
    borderWidth: '0.5px',
    borderColor: 'white !important',
  },
});

class NetField extends React.Component {
  state = {
    name: 'InputMode',
  };

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  render() {
    const {
      classes,
      value,
      onChange,
      style,
      color,
      label,
      disabled,
      onKeyPress,
      InputProps,
      helperText,
      type,
      error,
    } = this.props;

    return (
      <form className={classes.container} noValidate autoComplete="off">
        <TextField
          id="standard-name"
          label={label}
          className={classes.textField}
          value={value}
          onChange={onChange}
          margin="normal"
          color={color}
          disabled={disabled}
          variant="outlined"
          style={style}
          type={type}
          error={error}
          helperText={helperText}
          onKeyPress={onKeyPress}
          InputLabelProps={{
            classes: {
              root: classes.cssLabel,
              focused: classes.cssFocused,
            },
          }}
          InputProps={{
            classes: {
              root: classes.cssOutlinedInput,
              focused: classes.cssFocused,
              notchedOutline: classes.notchedOutline,
            },
          }}
        />
      </form>
    );
  }
}

NetField.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default styled(styles)(NetField);

export const NetnetField = ({
  onChange,
  value,
  label,
  className,
  type,
  onKeyPress,
  helperText,
  id,
}) => (
  <TextField
    id={id}
    label={label}
    value={value}
    type={type}
    onChange={onChange}
    variant="outlined"
    color="secondary"
    className={className}
    onKeyPress={onKeyPress}
    fullWidth
    sx={{

      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: '#f2ebff',
          padding: '10.6px 14px',
        },
        '&:hover fieldset': {
          borderColor: '#711fff',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#5a0bdf',
        },

        '&.Mui-error fieldset': {
          borderColor: 'red',
        },
        '&.Mui-disabled fieldset': {
          borderColor: 'grey',
        },
      },
      '& .MuiFormLabel-root': {
        color: '#03071e66',
      },
      '& .MuiFormLabel-root.Mui-focused': {
        color: '#5a0bdf',
      },
      '& .MuiFormLabel-root.Mui-error': {
        color: 'red',
      },
    }}
    InputProps={{
      style: { width: '100%' },
    }}
  />
);

export const NetnetField3000 = ({
  onChange,
  value,
  label,
  className,
  type,
  onKeyPress,
  helperText,
}) => (
  <TextField
    label={label}
    value={value}
    type={type}
    onChange={onChange}
    variant="outlined"
    color="secondary"
    className={className}
    onKeyPress={onKeyPress}
    sx={{
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: '#711fff',
        },
        '&:hover fieldset': {
          borderColor: '#711fff',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#5a0bdf',
        },

        '&.Mui-error fieldset': {
          borderColor: 'red',
        },

        '&.Mui-disabled fieldset': {
          borderColor: 'grey',
        },
      },
      '& .MuiFormLabel-root': {
        color: '#03071e66',
      },
      '& .MuiFormLabel-root.Mui-focused': {
        color: '#5a0bdf',
      },
      '& .MuiFormLabel-root.Mui-error': {
        color: 'red',
      },
    }}
    InputProps={{
      style: { width: 300 },
    }}
  />
);

const greyColor = '#f1f3f5';

export const NetnetField2 = ({
  onChange,
  value,
  label,
  className,
  type,
  onKeyPress,
  placeholder,
  id,
}) => (
  <TextField
    id={id}
    label={label}
    placeholder={placeholder}
    value={value}
    type={type}
    onChange={onChange}
    variant="outlined"
    color="secondary"
    className={className}
    onKeyPress={onKeyPress}
    InputLabelProps={{
      style: {
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        backgroundColor: 'white',
        width: '100%',
      },
    }}
    sx={{
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: '#ADADAD',
        },
        '&:hover fieldset': {
          borderColor: '#711fff',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#5a0bdf',
        },

        '&.Mui-error fieldset': {
          borderColor: 'red',
        },

        '&.Mui-disabled fieldset': {
          borderColor: 'grey',
        },
      },
      '& .MuiFormLabel-root': {
        color: '#03071e66',
      },
      '& .MuiFormLabel-root.Mui-focused': {
        color: '#5a0bdf',
      },
      '& .MuiFormLabel-root.Mui-error': {
        color: 'red',
      },
    }}
    InputProps={{
      style: {
        lineHeight: 'normal',
        backgroundColor: 'white',
        width: '100%',
      },
    }}
  />
);

export const GreyField = ({
  onChange,
  value,
  label,
  className,
  type,
  onKeyPress,
}) => (
  <TextField
    label={label}
    value={value}
    type={type}
    onChange={onChange}
    variant="outlined"
    color="secondary"
    className={className}
    onKeyPress={onKeyPress}
    sx={{
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: greyColor,
        },
        '&:hover fieldset': {
          borderColor: '#711fff',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#5a0bdf',
        },

        '&.Mui-error fieldset': {
          borderColor: 'red',
        },

        '&.Mui-disabled fieldset': {
          borderColor: 'grey',
        },
      },
      '& .MuiFormLabel-root': {
        color: '#03071e66',
      },
      '& .MuiFormLabel-root.Mui-focused': {
        color: '#5a0bdf',
      },
      '& .MuiFormLabel-root.Mui-error': {
        color: 'red',
      },
    }}
    InputProps={{
      style: { width: 500 },
    }}
  />
);

const anotherGreyColor = '#4B5255';

export const GreyField2 = ({
  onChange,
  value,
  label,
  className,
  type,
  onKeyPress,
}) => (
  <TextField
    label={label}
    value={value}
    type={type}
    onChange={onChange}
    variant="outlined"
    color="secondary"
    className={className}
    onKeyPress={onKeyPress}
    sx={{
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: anotherGreyColor,
        },
        '&:hover fieldset': {
          borderColor: '#711fff',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#5a0bdf',
        },

        '&.Mui-error fieldset': {
          borderColor: 'red',
        },

        '&.Mui-disabled fieldset': {
          borderColor: 'grey',
        },
      },
      '& .MuiFormLabel-root': {
        color: '#03071e66',
      },
      '& .MuiFormLabel-root.Mui-focused': {
        color: '#5a0bdf',
      },
      '& .MuiFormLabel-root.Mui-error': {
        color: 'red',
      },
    }}
    InputProps={{
      style: { width: 100 },
    }}
  />
);
