const iconImage = {
    acountReportIcon: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 29"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.45 12.73L20 4.86V18.55H0V0.55H2V13.09L7.5 3.55L14 7.33L18.24 0L19.97 1L14.74 10.05L8.23 6.3L2.31 16.55H4.57L8.96 8.99L15.45 12.73Z"
          fill="#4B5255"
        />
      </svg>
    ),
  
    editIcon: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 29"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20.7475 2.49378L21.2901 3.03639C22.0378 3.785 21.9247 5.11208 21.0353 6.00049L9.84571 17.1901L6.22037 18.5163C5.76513 18.6837 5.32185 18.4666 5.23172 18.0335C5.20126 17.8763 5.21561 17.7138 5.27311 17.5644L6.62503 13.9078L17.7834 2.74853C18.6727 1.86013 19.9998 1.74517 20.7475 2.49378ZM9.35737 3.60658C9.47814 3.60658 9.59773 3.63037 9.70931 3.67659C9.82089 3.72281 9.92227 3.79055 10.0077 3.87595C10.0931 3.96135 10.1608 4.06273 10.207 4.17431C10.2533 4.28589 10.277 4.40548 10.277 4.52625C10.277 4.64703 10.2533 4.76662 10.207 4.8782C10.1608 4.98978 10.0931 5.09116 10.0077 5.17656C9.92227 5.26196 9.82089 5.3297 9.70931 5.37592C9.59773 5.42214 9.47814 5.44592 9.35737 5.44592H5.67868C5.19086 5.44592 4.72302 5.63971 4.37807 5.98465C4.03313 6.3296 3.83934 6.79744 3.83934 7.28527V18.3213C3.83934 18.8091 4.03313 19.277 4.37807 19.6219C4.72302 19.9669 5.19086 20.1607 5.67868 20.1607H16.7147C17.2026 20.1607 17.6704 19.9669 18.0153 19.6219C18.3603 19.277 18.5541 18.8091 18.5541 18.3213V14.6426C18.5541 14.3987 18.651 14.1648 18.8234 13.9923C18.9959 13.8199 19.2298 13.723 19.4737 13.723C19.7177 13.723 19.9516 13.8199 20.1241 13.9923C20.2965 14.1648 20.3934 14.3987 20.3934 14.6426V18.3213C20.3934 19.297 20.0058 20.2327 19.316 20.9225C18.6261 21.6124 17.6904 22 16.7147 22H5.67868C4.70304 22 3.76735 21.6124 3.07746 20.9225C2.38757 20.2327 2 19.297 2 18.3213V7.28527C2 6.30962 2.38757 5.37393 3.07746 4.68404C3.76735 3.99416 4.70304 3.60658 5.67868 3.60658H9.35737Z"
          fill="#4B5255"
        />
      </svg>
    ),
  
    BookIcon: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 29"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18.2727 2.90909H16.3636V9.11364L13.5 6.96591L10.6364 9.11364V2.90909H4.90909V20.0909H18.2727V2.90909ZM12.5455 2.90909V5.29545L13.5 4.57955L14.4545 5.29545V2.90909H12.5455ZM20.1818 22H3V1H20.1818V22Z"
          fill="#4B5255"
        />
      </svg>
    ),
    logFilesIcon: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 29"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2 2H23V23H2V2ZM3.90909 10.273V21.0909H21.0909V10.273H3.90909ZM21.0909 8.36395V3.90909H3.90909V8.36395H21.0909ZM5.81818 4.86364H7.73109V6.77655H5.81818V4.86364ZM6.77273 12.5H18.2273V14.4091H6.77273V12.5ZM6.77273 16.3182H12.5V18.2273H6.77273V16.3182Z"
          fill="#676B6B"
        />
      </svg>
    ),
  
    userIcon: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 29"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 12C14.21 12 16 10.21 16 8C16 5.79 14.21 4 12 4C9.79 4 8 5.79 8 8C8 10.21 9.79 12 12 12ZM12 14C9.33 14 4 15.34 4 18V20H20V18C20 15.34 14.67 14 12 14Z"
          fill="black"
          fill-opacity="0.56"
        />
      </svg>
    ),
    dashboardIcon: (<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11 21H5C3.9 21 3 20.1 3 19V5C3 3.9 3.9 3 5 3H11V21ZM13 21H19C20.1 21 21 20.1 21 19V12H13V21ZM21 10V5C21 3.9 20.1 3 19 3H13V10H21Z" fill="black" fill-opacity="0.56"/>
    </svg>),

    logoIcon: <svg width="132" height="33" viewBox="0 0 132 33" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M121.096 6.30811L125.751 5.55835V10.4019H131.344V14.2756H125.751V20.0555C125.751 21.034 125.923 21.8155 126.267 22.398C126.61 22.9806 127.303 23.2727 128.345 23.2727C128.845 23.2727 129.361 23.2252 129.892 23.1319C130.424 23.0387 130.908 22.9084 131.344 22.7412L132.001 26.3651C131.437 26.5939 130.813 26.7927 130.126 26.9582C129.438 27.1254 128.595 27.2081 127.595 27.2081C126.325 27.2081 125.272 27.0356 124.44 26.6924C123.607 26.3492 122.94 25.8705 122.44 25.2545C121.941 24.6403 121.592 23.8958 121.393 23.0211C121.194 22.1463 121.096 21.1783 121.096 20.1153V6.30811Z" fill="#4300B8"/>
    <path d="M102.225 18.7444C102.225 17.2871 102.448 16.0111 102.897 14.9164C103.344 13.8234 103.934 12.9117 104.662 12.1831C105.391 11.4545 106.229 10.9018 107.177 10.5269C108.124 10.1521 109.099 9.96375 110.099 9.96375C112.431 9.96375 114.275 10.6783 115.629 12.1039C116.982 13.5313 117.66 15.6292 117.66 18.3994C117.66 18.6705 117.649 18.9679 117.628 19.29C117.607 19.6138 117.586 19.8989 117.567 20.1489H107.007C107.11 21.1063 107.557 21.8666 108.349 22.4298C109.141 22.9913 110.203 23.2729 111.537 23.2729C112.39 23.2729 113.228 23.1954 114.052 23.0388C114.874 22.8821 115.546 22.6903 116.067 22.4615L116.692 26.242C116.442 26.3669 116.109 26.4919 115.692 26.6169C115.275 26.7418 114.812 26.8509 114.302 26.9442C113.791 27.0375 113.244 27.1167 112.661 27.1783C112.079 27.2399 111.495 27.2716 110.912 27.2716C109.434 27.2716 108.147 27.0533 107.054 26.6151C105.961 26.1769 105.055 25.5785 104.337 24.8181C103.619 24.0578 103.087 23.1567 102.744 22.1148C102.401 21.0729 102.228 19.9482 102.228 18.7409L102.225 18.7444ZM113.159 16.9633C113.138 16.5673 113.071 16.1818 112.957 15.8069C112.843 15.4321 112.665 15.0994 112.426 14.8073C112.186 14.5151 111.883 14.2757 111.519 14.0892C111.155 13.9009 110.701 13.8076 110.16 13.8076C109.62 13.8076 109.192 13.8956 108.818 14.0733C108.443 14.2511 108.129 14.4852 107.879 14.7756C107.63 15.0677 107.436 15.4057 107.302 15.7911C107.167 16.1765 107.068 16.5673 107.005 16.9633H113.159Z" fill="#4300B8"/>
    <path d="M84.6035 10.9651C85.3955 10.7363 86.4163 10.5233 87.6659 10.3244C88.9155 10.1273 90.2285 10.027 91.603 10.027C92.9776 10.027 94.1603 10.21 95.0861 10.5743C96.0118 10.9387 96.7475 11.4543 97.2896 12.1214C97.8317 12.7884 98.2153 13.5787 98.4459 14.4956C98.6747 15.4126 98.7891 16.4334 98.7891 17.558V26.8367H94.1339V18.1195C94.1339 16.6199 93.935 15.5569 93.5408 14.9321C93.1448 14.3073 92.4056 13.994 91.3232 13.994C90.9906 13.994 90.635 14.0099 90.2602 14.0415C89.8853 14.0732 89.5526 14.1102 89.2605 14.1507V26.835H84.6053V10.9651H84.6035Z" fill="#4300B8"/>
    <path d="M76.0125 0.999756H55.9889C53.0039 0.999756 50.584 3.41964 50.584 6.40472V26.4282C50.584 29.4133 53.0039 31.8332 55.9889 31.8332H76.0125C78.9975 31.8332 81.4174 29.4133 81.4174 26.4282V6.40472C81.4174 3.41964 78.9975 0.999756 76.0125 0.999756Z" stroke="#4300B8" stroke-width="1.584" stroke-miterlimit="10"/>
    <path d="M61.4629 6.3133C60.7554 5.60578 59.7416 5.19922 58.6803 5.19922C57.619 5.19922 56.6053 5.60578 55.8978 6.3133C54.3648 7.84802 54.3648 10.3437 55.8978 11.8784L70.5374 26.5181C71.2784 27.2591 72.2658 27.6674 73.32 27.6674C74.3742 27.6674 75.3616 27.2591 76.1026 26.5181C76.8435 25.7771 77.2518 24.7898 77.2518 23.7355C77.2518 22.6813 76.8435 21.6939 76.1026 20.953L61.4629 6.3133ZM75.5728 23.7355C75.5728 24.341 75.3405 24.9077 74.9163 25.3301C74.5133 25.7331 73.9325 25.9637 73.3218 25.9637C72.711 25.9637 72.1302 25.7331 71.7272 25.3301L57.0858 10.6904C56.2075 9.81042 56.2075 8.37954 57.0858 7.5013C57.5099 7.07714 58.0766 6.84482 58.6821 6.84482C59.2875 6.84482 59.8525 7.07714 60.2766 7.5013L74.9163 22.141C75.3405 22.5651 75.5728 23.1301 75.5728 23.7355Z" fill="#4300B8"/>
    <path d="M73.2602 13.1472C75.462 13.1472 77.2519 11.3573 77.2519 9.1555C77.2519 6.95374 75.4602 5.16382 73.2602 5.16382C71.0602 5.16382 69.2686 6.95374 69.2686 9.1555C69.2686 11.3573 71.0585 13.1472 73.2602 13.1472ZM73.2602 6.84286C74.5345 6.84286 75.5729 7.8795 75.5729 9.1555C75.5729 10.4315 74.5345 11.4681 73.2602 11.4681C71.986 11.4681 70.9494 10.4315 70.9494 9.1555C70.9494 7.8795 71.986 6.84286 73.2602 6.84286Z" fill="#4300B8"/>
    <path d="M58.7407 19.684C56.5389 19.684 54.749 21.4739 54.749 23.6756C54.749 25.8774 56.5389 27.6673 58.7407 27.6673C60.9425 27.6673 62.7324 25.8774 62.7324 23.6756C62.7324 21.4739 60.9425 19.684 58.7407 19.684ZM61.0533 23.6756C61.0533 24.9499 60.0149 25.9883 58.7407 25.9883C57.4665 25.9883 56.4298 24.9516 56.4298 23.6756C56.4298 22.3996 57.4665 21.3648 58.7407 21.3648C60.0149 21.3648 61.0533 22.4014 61.0533 23.6756Z" fill="#4300B8"/>
    <path d="M36.4922 6.30811L41.1474 5.55835V10.4019H46.7407V14.2756H41.1474V20.0555C41.1474 21.034 41.3199 21.8155 41.6631 22.398C42.0063 22.9806 42.6997 23.2727 43.7416 23.2727C44.2415 23.2727 44.7571 23.2252 45.2887 23.1319C45.8202 23.0387 46.3042 22.9084 46.7407 22.7412L47.3971 26.3651C46.8339 26.5939 46.2091 26.7927 45.5227 26.9582C44.8346 27.1254 43.9915 27.2081 42.9919 27.2081C41.7211 27.2081 40.6687 27.0356 39.8362 26.6924C39.0037 26.3492 38.3367 25.8705 37.8368 25.2545C37.337 24.6403 36.9885 23.8958 36.7896 23.0211C36.5907 22.1463 36.4922 21.1783 36.4922 20.1153V6.30811Z" fill="#4300B8"/>
    <path d="M17.6211 18.7444C17.6211 17.2871 17.8446 16.0111 18.2934 14.9164C18.7405 13.8234 19.3301 12.9117 20.0587 12.1831C20.7873 11.4545 21.6251 10.9018 22.5737 10.5269C23.5206 10.1521 24.4957 9.96375 25.4953 9.96375C27.8273 9.96375 29.6718 10.6783 31.0253 12.1039C32.3787 13.5313 33.0563 15.6292 33.0563 18.3994C33.0563 18.6705 33.0457 18.9679 33.0246 19.29C33.0035 19.6138 32.9824 19.8989 32.963 20.1489H22.403C22.5069 21.1063 22.9539 21.8666 23.7459 22.4298C24.5379 22.9913 25.5992 23.2729 26.9333 23.2729C27.7869 23.2729 28.6246 23.1954 29.4483 23.0388C30.2702 22.8821 30.9425 22.6903 31.4635 22.4615L32.0883 26.242C31.8384 26.3669 31.5057 26.4919 31.0886 26.6169C30.6715 26.7418 30.2086 26.8509 29.6982 26.9442C29.1878 27.0375 28.6405 27.1167 28.0579 27.1783C27.4753 27.2399 26.891 27.2716 26.3085 27.2716C24.8301 27.2716 23.5435 27.0533 22.4505 26.6151C21.3576 26.1769 20.4512 25.5785 19.7331 24.8181C19.015 24.0578 18.4835 23.1567 18.1403 22.1148C17.7971 21.0729 17.6246 19.9482 17.6246 18.7409L17.6211 18.7444ZM28.556 16.9633C28.5349 16.5673 28.468 16.1818 28.3536 15.8069C28.2392 15.4321 28.0614 15.0994 27.8221 14.8073C27.5827 14.5151 27.28 14.2757 26.9157 14.0892C26.5513 13.9009 26.0973 13.8076 25.5569 13.8076C25.0166 13.8076 24.5889 13.8956 24.2141 14.0733C23.8392 14.2511 23.5259 14.4852 23.276 14.7756C23.0261 15.0677 22.8325 15.4057 22.6987 15.7911C22.5632 16.1765 22.4646 16.5673 22.4013 16.9633H28.556Z" fill="#4300B8"/>
    <path d="M0 10.9651C0.792 10.7363 1.8128 10.5233 3.0624 10.3244C4.312 10.1273 5.62496 10.027 6.99952 10.027C8.37408 10.027 9.5568 10.21 10.4826 10.5743C11.4083 10.9387 12.144 11.4543 12.6861 12.1214C13.2282 12.7884 13.6118 13.5787 13.8424 14.4956C14.0712 15.4126 14.1856 16.4334 14.1856 17.558V26.8367H9.5304V18.1195C9.5304 16.6199 9.33152 15.5569 8.93728 14.9321C8.54128 14.3073 7.80208 13.994 6.71968 13.994C6.38704 13.994 6.03152 14.0099 5.65664 14.0415C5.28176 14.0732 4.94912 14.1102 4.65696 14.1507V26.835H0V10.9651Z" fill="#4300B8"/>
    </svg>
    

    

    
  };
  
  
  export default iconImage;