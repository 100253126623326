import React, { useState} from 'react';
import { useSelector } from 'react-redux';
import Gear from './assets/Gear.png';
import Dots from './assets/Dots.png';
import helpVid from '../Opportunity/assets/Section_Help-idle.svg'
import Bounce from 'react-reveal/Bounce';
import QuickTasks from './QuickTask';
import Estimate from './Estimate';
import Time from './Time';
import Team from './Team';
import Sales from './Sales';
import InitalizeParam from './InitailzeParam';
import ParamControl from './ParamControl';
import ReportScreenHelp from './ReportScreenHelp';
import ReportSubScreenHelp from './ReportSubScreenHelp';

const Reports = ({ screenHeight }) => {

  const [params, setParams] = useState({
    value: '',
    data: [],
    projects: [],
    finalFilterArray: []
  });

  const allTasks = useSelector((state) => state.projects.tasks);
  const Projects = useSelector(state => state.projects.projects)

  let combineproject = []
  Array.isArray(allTasks) && allTasks.filter((val, ind) => {

    const project_name = Array.isArray(Projects) && Projects.length && val.task_project ? Projects.find((item) => item.id === val?.task_project)?.title : ''
    if (project_name) {
      let find = combineproject.length > 0 && combineproject.find((fnd) => fnd.name === val.task_name)
      if (find) {
      }
      else {
        combineproject.push({ name: val.task_name, project: project_name })
      }
    }
  })

  // this is Worktype People

  const combineWorktype = []
  Array.isArray(params.data.chartData) && params.data.chartData.map((val, ind) => {
    Array.isArray(val?.task_worktype) && val?.task_worktype.map((v, i) => {
      let find = combineWorktype.find((val) => val === v.name)
      if (find) {
      }
      else {
        combineWorktype.push(v.name)
      }
    })
  })

  // this is filter People

  const [show, setShow] = useState(false)

  return (
    <Bounce left>
      <section className="oppor-section5">
        <div className="oppor-row">
          <div className="oppor-col">
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <h3 className="fstxtPro">Reports</h3>
            </div>
            <img className="icon-sm" src={Dots} alt="dots" />
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                background: 'inherit',
                border: 'none',
                outline: 'none',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <button
                style={{
                  background: 'inherit',
                  border: 'none',
                  outline: 'none',
                }}
                id="filter"
              >
                <img id="filter" className="icon-sm" src={Gear} alt="gear" />
              </button>

            </div>
            <img src={helpVid} onClick={() => { setShow(true) }} style={{ color: '#a6a6a6', marginLeft: '10px', width: '28px', height: '28px', cursor: 'pointer' }} />
          </div>
          {!params.value ? null
            : (
              <>
                <ParamControl params={params} setParams={setParams} />
              </>)}
        </div>
        <section

          style={{
            backgroundColor: '#fff',
            border: '1px solid #ccc',
            borderRadius: '8px',
            padding: '0.1rem 0.1rem',
            minHeight: screenHeight - 155,
            maxHeight: screenHeight - 155,
            overflow: 'auto',
          }}
        >
          {true ? (
            <>
              {!params.value && (<InitalizeParam screenHeight={screenHeight} params={params} setParams={setParams} />)}
              {params.value === 'time' && (
                <Time params={params} setParams={setParams} />
              )}
              {params.value === 'quick_task' && (<QuickTasks params={params} setParams={setParams} />)}
              {params.value === 'estimate' && (<Estimate params={params} setParams={setParams} />)}
              {params.value === 'sales' && (<Sales params={params} setParams={setParams} />)}
              {params.value === 'team' && (<Team params={params} setParams={setParams} />)}
            </>
          ) : (
            <article className="box-txt">
              <h3 className="txtCenter">No Reports yet</h3>
              <p className="txtCenter">All Your Reports Would Appear Here!</p>
            </article>
          )}

          {params.value === "" ? (<ReportScreenHelp show={show} setShow={setShow} />) : <ReportSubScreenHelp show={show} setShow={setShow} params={params.value} />}

        </section>
      </section>
    </Bounce>
  );
};

export default Reports;
