import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Grid,
  Typography,
  Tooltip,
  TextField,
  TableContainer,
  Menu,
  MenuItem,
} from '@mui/material';
import { Box } from '@mui/system';
import moment from 'moment';
import { useEffect, useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { ENDPOINT } from '../../Opportunity/Redux/oppor';
import DilChange from './DilChange';
import MultiplierChange, { sumPriceToCustomer } from './MultiplierChange';
import NetChange from './NetChange';
import Dots from '../assets/Dots.png';
import EditIcon from '@mui/icons-material/Edit';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import ControlPointDuplicateIcon from '@mui/icons-material/ControlPointDuplicate';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

const CHead = () => {
  return (
    <TableHead>
      <TableRow
        sx={{
          height: '82px',
          color: '#03071E',
          backgroundColor: '#E4F2FF',
          borderTopLeftRadius: '8px',
          borderTopRightRadius: '8px',
          border: '',
          borderBottom: 'none',
        }}
      >
        <TableCell align="left">Name</TableCell>
        <TableCell align="left">Date</TableCell>
        <TableCell align="right"> </TableCell>
      </TableRow>
    </TableHead>
  );
};

const Changes = ({
  setChangeMode,
  newChange,
  setNewChange,
  formData,
  setFormData,
  unique_id,
  project_change,
  setProjectChange,
  dileverable,
  setDeliverable,
  setIsDetOpen,
  setIsTyping
}) => {
  useEffect(() => {
    setChangeMode(true);
    setIsTyping(false)
  }, []);
  const [init, setInit] = useState(1);

  const [altData, setAltData] = useState({
    change_order_title: '',
    change_reason: '',
    changed_deliverable: '',
    new_deliverable_worktype: [],
    project_change: unique_id,
  });

  const [estimate_hardcost, setEstHardCost] = useState([]);
  const [tracker, setTracker] = useState();

  const dispatch = useDispatch();
  const access_token = useSelector((state) => state.auth.user.access);
  const [loading, setLoading] = useState(false);
  const [menuIconEl, setMenuIconEl] = useState(false);
  const [GI, setGI] = useState();
  const [totalCost, setTotalCost] = useState();

  const worktype = useSelector((state) => state.Misc.workTypes);

  const sumUserPrices = (deliverable) => {
    if (!deliverable || !Array.isArray(deliverable) || !deliverable.length) {
      return 0;
    }

    return deliverable.reduce((acc, item) => {
      const userPrice = Number(item.user_price);
      if (!isNaN(userPrice)) {
        acc += userPrice;
      }
      return acc;
    }, 0);
  };

  useEffect(() => {
    let cost = 0;
    const haveHardCost =
      Array.isArray(estimate_hardcost) && estimate_hardcost.length;
    if (!tracker) {
      if (dileverable && Array.isArray(dileverable) && dileverable.length) {
        const hasAllUserPriceDefined = dileverable.every((item) => {
          return Number(item.user_price) > 0;
        });

        if (!hasAllUserPriceDefined) {
          const result = dileverable.reduce((acc, curr) => {
            curr.deliverable_worktype.forEach((item) => {
              if (acc[item.id]) {
                acc[item.id].hours += Number(item.hours);
              } else {
                acc[item.id] = { id: item.id, hours: Number(item.hours) };
              }
            });
            return acc;
          }, {});

          const finalResult = Object.values(result);
          finalResult.forEach((item) => {
            const matchingType = worktype.find((type) => type.id === item.id);
            if (matchingType) {
              cost += item.hours * matchingType.user_rate;
            }
          });
          const HardAccum = haveHardCost
            ? sumPriceToCustomer(estimate_hardcost)
            : false;
          setGI(HardAccum ? Number(cost) + Number(HardAccum) : cost);
          setTotalCost(HardAccum ? Number(cost) + Number(HardAccum) : cost);
        } else {
          const HardAccum2 = haveHardCost
            ? sumPriceToCustomer(estimate_hardcost)
            : false;
          const rawCost = sumUserPrices(dileverable);
          setGI(HardAccum2 ? Number(rawCost) + Number(HardAccum2) : rawCost);
          setTotalCost(
            HardAccum2 ? Number(rawCost) + Number(HardAccum2) : rawCost
          );
        }
      } else {
      }
    } else {
      const HardAccum3 = haveHardCost
        ? sumPriceToCustomer(estimate_hardcost)
        : false;
      setGI(HardAccum3 ? Number(tracker) + Number(HardAccum3) : tracker);
      setTotalCost(HardAccum3 ? Number(tracker) + Number(HardAccum3) : tracker);
    }
  }, [dileverable, formData]);



  const [newState, setNewState] = useState()



  const UpdateRequest = async () => {
    if (Array.isArray(dileverable) && !dileverable.length) {
      setError(true)
      setErrorMessage('Atleast one Deliverable is Required to go ahead')
      return;
    }
    const shallowCopy = {
      ...formData,
    };

    delete shallowCopy.work_type;
    delete shallowCopy.estimate_hardcost;

    const res = await fetch(
      `${ENDPOINT}/api/project/update/${formData.id}/`,
      {
        method: 'PUT',
        headers: {
          Authorization: `JWT ${access_token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...shallowCopy,
          project_deliverable: dileverable?.map(
            (item) => {
              delete item.task_deliverable
              return {
                ...item,
                changed: item.changed ? true : false,
                deliver_id: item.id,
                change_reason: item.change_reason ? item.change_reason : '',
                deliverable_worktype: item.deliverable_worktype.map(
                  (wtt) => ({
                    ...wtt,
                    hours: wtt.hours ? Number(wtt.hours) : null,
                    new_hours: wtt.new_hours ? Number(wtt.new_hours) : 0
                  })
                ),
              };

            }
          ),

        }),
      }
    );

    const data = await res.json();
    if (!res.ok) {
      return;
    }

    else {
      const resGet = await fetch(`${ENDPOINT}/api/project/detail/${formData?.unique_id}/`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `JWT ${access_token}`,
        },
      });
      const dataGet = await resGet.json();
      if (!resGet.ok) {
        return;
      }
      setFormData(dataGet)
      if (
        dataGet &&
        Array.isArray(dataGet.deliverable_project) &&
        dataGet.deliverable_project.length
      ) {

        setDeliverable(dataGet.deliverable_project);
      }


    }
  }


  return (
    <>
      {init === 1 && (
        <>
          <Grid container sx={{ width: '100%', padding: '2rem' }}>
            <TableContainer
              sx={{ borderRadius: '8px', border: '1px solid #E0E0DF' }}
            >
              <Table>
                <CHead />
                <TableBody>
                  {Array.isArray(project_change) && project_change.length ? (
                    project_change.map((item) => {
                      return (
                        <>
                          <TableRow
                            sx={{ backgroundColor: '#F8F9FA', height: '82px', }}
                          >
                            <TableCell sx={{ cursor: 'pointer' }} onClick={() => {
                              setNewState(item)
                              setInit(2);
                              setIsTyping(true)
                            }}>
                              {item?.change_order_title ?? ''}
                            </TableCell>
                            <TableCell>
                              {moment(item.created_at).format('MMM DD YYYY')}
                            </TableCell>
                            <TableCell align="right">
                              <button
                                className="noBorder"
                                style={{ cursor: 'pointer' }}
                                onClick={(e) => setMenuIconEl(e.currentTarget)}
                              >
                                <img
                                  src={Dots}
                                  alt="button"
                                  aria-label="Click to engage"
                                />
                              </button>
                              <Menu
                                id="company-icon-menu"
                                sx={{
                                  width: 'auto',
                                  '& .MuiMenu-paper': {
                                    border: '1px solid #03071E33',
                                    borderRadius: '10px',
                                    width: 'auto'
                                  },
                                  '& .MuiMenu-list': {
                                    margin: '5px 5px 5px 5px',
                                    overflowY: 'hidden',
                                    maxHeight: '300px',
                                    width: 'auto',

                                  },

                                }}
                                anchorEl={menuIconEl}
                                open={!!menuIconEl}
                                onClose={() => setMenuIconEl(null)}
                                autoFocus={false}
                              >
                                <MenuItem
                                  sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '1rem',
                                    width: '100%',
                                  }}
                                >
                                  <EditIcon sx={{ fill: '#676b6b' }} /> Edit
                                </MenuItem>

                                <MenuItem
                                  sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '1rem',
                                    width: '100%',
                                  }}
                                >
                                  <AssignmentTurnedInIcon
                                    sx={{ fill: '#5fcea8' }}
                                  />{' '}
                                  Approve
                                </MenuItem>

                                <MenuItem
                                  sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '1rem',
                                    width: '100%',
                                  }}
                                >
                                  <ControlPointDuplicateIcon
                                    sx={{ fill: '#676b6b' }}
                                  />{' '}
                                  Duplicate
                                </MenuItem>

                                <MenuItem
                                  sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '1rem',
                                    width: '100%',
                                  }}
                                >
                                  <DeleteOutlineIcon sx={{ fill: '#e55b55' }} />{' '}
                                  Delete
                                </MenuItem>
                              </Menu>
                            </TableCell>
                          </TableRow>
                        </>
                      );
                    })
                  ) : (
                    <TableRow
                      sx={{ backgroundColor: '#F8F9FA', height: '82px' }}
                    >
                      <TableCell align="center">No Changes Yet</TableCell>
                      <TableCell align="center"> </TableCell>
                      <TableCell align="center"> </TableCell>
                    </TableRow>
                  )}{' '}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Offcanvas
            backdrop
            placement="bottom"
            show={newChange}
            onHide={() => setNewChange(false)}
          >
            <Offcanvas.Header>
              <button className="close-modal"></button>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Typography
                sx={{
                  fontSize: '1.8rem',
                  fontWeight: '600',
                  fontFamily: 'Open Sans',
                  m: '1rem',
                }}
              >
                Make a New Change Order
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  width: '40%',
                  borderRadius: '8px',
                  backgroundColor: '#f8f9fb',
                  m: '1rem',
                  p: '1rem',
                  justifyContent: 'space-between',
                }}
              >
                <Typography className="fstxt">Change Order Title</Typography>
                <div
                  style={{
                    display: 'flex',
                    gap: '1rem',
                    alignItems: 'center',
                    width: '100%',
                    justifyContent: 'space-between',
                  }}
                >
                  <TextField
                    placeholder={'Name your change'}
                    value={altData.change_order_title}
                    onChange={(e) =>
                      setAltData({
                        ...altData,
                        change_order_title: e.target.value,
                      })
                    }
                    InputLabelProps={{
                      style: {
                        alignItems: 'center',
                        justifyContent: 'center',
                        textAlign: 'center',
                        backgroundColor: 'white',
                        width: '100%',
                      },
                    }}
                    sx={{
                      width: '100%',
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: '#ADADAD',
                        },
                        '&:hover fieldset': {
                          borderColor: '#711fff',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#5a0bdf',
                        },

                        '&.Mui-error fieldset': {
                          borderColor: 'red',
                        },

                        '&.Mui-disabled fieldset': {
                          borderColor: 'grey',
                        },
                      },
                      '& .MuiFormLabel-root': {
                        color: '#03071e66',
                      },
                      '& .MuiFormLabel-root.Mui-focused': {
                        color: '#5a0bdf',
                      },
                      '& .MuiFormLabel-root.Mui-error': {
                        color: 'red',
                      },
                    }}
                    InputProps={{
                      style: {
                        lineHeight: 'normal',
                        backgroundColor: 'white',
                        width: '100%',
                      },
                    }}
                  />
                  <Tooltip
                    title={
                      altData.change_order_title ? '' : 'Please enter Title'
                    }
                  >
                    <button
                      className="create-btn"
                      onClick={() => {
                        if (!altData.change_order_title) {
                          return;
                        }
                        setInit(2);
                        setNewChange(false);
                        setIsTyping(true)
                      }}
                    >
                      Go
                    </button>
                  </Tooltip>
                </div>
              </Box>
            </Offcanvas.Body>
          </Offcanvas>
        </>
      )}

      {init === 2 && (
        <>
          <section className="container-psU-p5">
            <DilChange
              formData={{
                ...formData,
                worktype: dileverable[0].deliverable_worktype,
              }}
              setFormData={setFormData}
              steps={init}
              dileverable={dileverable}
              setDeliverable={setDeliverable}
              altData={altData}
              setAltData={setAltData}
              newState={newState}
              setNewState={setNewState}
              setIsDetOpen={setIsDetOpen}
            />
          </section>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              padding: '1rem 2.2rem',
              backgroundColor: '#fff',
              height: '80px',
              width: '100%',
            }}
          >
            <button className="cancel-btn"> </button>
            <Box>
              <button
                className="create-btn"
                onClick={() => {
                  setDeliverable((prev) => {
                    const shallowCopy = [...prev.map((item) => ({ ...item }))];
                    const alt = shallowCopy.map((dil) => ({
                      ...dil,
                      total_hours: dil.total_loe,
                    }));
                    return [...alt];
                  });
                  setInit(3);
                }}
              >
                Next
              </button>
            </Box>
          </Box>
        </>
      )}

      {init === 3 && (
        <>
          <section className="container-psU-p5">
            <MultiplierChange
              formData={formData}
              dileverable={dileverable}
              setDeliverable={setDeliverable}
              estimate_hardcost={estimate_hardcost}
              setEstHardCost={setEstHardCost}
              setTracker={setTracker}
            />
          </section>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              padding: '1rem 2.2rem',
              backgroundColor: '#fff',
              height: '80px',
              width: '100%',
            }}
          >
            <button className="cancel-btn"> </button>
            <Box>
              <button
                className="create-btn"
                onClick={() => setInit((prev) => (prev === 3 ? 4 : 1))}
              >
                Next
              </button>
            </Box>
          </Box>
        </>
      )}

      {init === 4 && (
        <>
          <NetChange
            formData={formData}
            setFormData={setFormData}
            estimate_hardcost={estimate_hardcost}
            setEstHardCost={setEstHardCost}
            dileverable={dileverable}
            setDeliverable={setDeliverable}
            GI={GI}
            setGI={setGI}
          />
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              padding: '1rem 2.2rem',
              backgroundColor: '#fff',
              height: '80px',
              width: '100%',
            }}
          >
            <button className="cancel-btn"> </button>
            <Box>
              <button
                className="create-btn"
                onClick={async (e) => {
                  e.preventDefault();
                  setLoading(true);
                  const response = await fetch(
                    `${ENDPOINT}/api/project/changeorder/create/`,
                    {
                      method: 'POST',
                      headers: {
                        Authorization: `JWT ${access_token}`,
                        'Content-Type': 'application/json',
                      },
                      body: JSON.stringify({
                        change_order_title: altData.change_order_title,
                        project_change: formData.id,
                        change_reason: 'change Reason',
                      }),
                    }
                  );

                  const data = await response.json();
                  if (!response.ok) {
                    setLoading(false);
                  } else {
                    UpdateRequest()
                    
                    setIsTyping(false)
                    setTimeout(() => {
                      setInit(1);
                    }, 3000);
                  }
                }}
              >
                {loading ? <i className="bx bx-loader-alt bx-spin" /> : 'Next'}{' '}
              </button>
            </Box>
          </Box>
        </>
      )}
    </>
  );
};

export default Changes;
