import React from "react";
import Icons from '../assets/index'

const ContactDetail = ({ customer }) => {
  const {
    company_type,
    company_website,
    compnay_phone,
    compnay_address,
    contact_info,
  } = customer;

  // Convert the last_login string to a Date object
  const lastUpdate = contact_info?.last_login
    ? new Date(contact_info.last_login)
    : null;


  // Format the last login date as "20 Nov 2023"
  const formattedLastUpdate = lastUpdate
    ? `${lastUpdate.getDate()} ${new Intl.DateTimeFormat("en-US", {
        month: "short",
      }).format(lastUpdate)} ${lastUpdate.getFullYear()}`
    : "None";


  return (
    <div
      className="mobileViewContainer"
      style={{
        width: "97.2%",
        margin: "1.38%"
      }}
    >
      <div
        style={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          gap: "30px",
          textAlign: "left",
          fontSize: "18px",
          color: "#fff",
          fontFamily: "'Open Sans'",
          height: "90vh",
          overflowY: "auto"
        }}
      >
        <div
          className="socialSection mobileView"
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            gap: "2%",
            width: "100%",
          }}
        >
          <div
            className="blakishSection"
            style={{
              borderRadius: "6px",
              backgroundColor: "#1e0052",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              padding: "24px",
              gap: "25px",
              flex: 1,
              width: "58%"
            }}
          >
            <div
              style={{
                position: "relative",
                lineHeight: "25px",
                fontWeight: "600",
              }}
            >
              {company_type}
            </div>
            <div
              style={{
                alignSelf: "stretch",
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                fontSize: "16px",
              }}
            >
              <div
                style={{
                  flex: "1",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  gap: "10px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    gap: "23px",
                  }}
                >
                  <div
                    style={{
                      borderRadius: "34px",
                      backgroundColor: "rgba(255, 255, 255, 0.2)",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "10px",
                    }}
                  >
                    <img
                      style={{
                        position: "relative",
                        width: "20px",
                        height: "20px",
                      }}
                      alt=""
                      src={Icons.Send_email}
                    />
                  </div>
                  <div style={{ position: "relative", lineHeight: "100%" }}>
                    {company_website}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    gap: "23px",
                  }}
                >
                  <div
                    style={{
                      borderRadius: "34px",
                      backgroundColor: "rgba(255, 255, 255, 0.2)",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "10px",
                    }}
                  >
                    <img
                      style={{
                        position: "relative",
                        width: "20px",
                        height: "20px",
                      }}
                      alt=""
                      src={Icons.call}
                    />
                  </div>
                  <div style={{ position: "relative", lineHeight: "100%" }}>
                    {compnay_phone}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    gap: "23px",
                  }}
                >
                  <div
                    style={{
                      borderRadius: "34px",
                      backgroundColor: "rgba(255, 255, 255, 0.2)",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "10px",
                      gap: "10px",
                    }}
                  >
                    <div
                      style={{
                        position: "relative",
                        width: "20px",
                        height: "20px",
                        display: "none",
                      }}
                    />
                    <img
                      style={{
                        position: "relative",
                        width: "22px",
                        height: "23px",
                      }}
                      alt=""
                      src={Icons.vector}
                    />
                  </div>
                  <div
                    style={{
                      position: "relative",
                      lineHeight: "100%",
                      width: "220px",
                    }}
                  >
                    <p style={{ lineHeight: "150%" }}>{compnay_address}</p>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                justifyContent: "space-between",
                fontSize: "20px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  gap: "12px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    gap: "14px",
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      lineHeight: "130%",
                      fontWeight: "600",
                    }}
                  >
                    Projects
                  </div>
                  <img
                    style={{
                      position: "relative",
                      width: "16px",
                      height: "16px",
                    }}
                    alt=""
                    src={Icons.information_circle}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    gap: "45px",
                    fontSize: "16px",
                  }}
                >
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  gap: "12px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    gap: "14px",
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      lineHeight: "130%",
                      fontWeight: "600",
                    }}
                  >
                    Usage
                  </div>
                  <img
                    style={{
                      position: "relative",
                      width: "16px",
                      height: "16px",
                    }}
                    alt=""
                    src={Icons.information_circle}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    gap: "45px",
                    fontSize: "16px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "flex-start",
                    }}
                  >
                    <b style={{ position: "relative", lineHeight: "100%" }}>
                    {customer.number_of_deliverables}
                    </b>
                    <div
                      style={{
                        position: "relative",
                        fontSize: "14px",
                        lineHeight: "22px",
                      }}
                    >
                      Deliverables
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "flex-start",
                    }}
                  >
                    <b style={{ position: "relative", lineHeight: "100%" }}>
                      {/* {calculateTotalTasks(projects)} */}
                      {customer.number_of_quicktasks}
                    </b>
                    <div
                      style={{
                        position: "relative",
                        fontSize: "14px",
                        lineHeight: "22px",
                      }}
                    >
                      Tasks
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
          className="primaryInfo"

            style={{
              borderRadius: "6px",
              backgroundColor: "#fff",
              boxShadow: "2px 2px 6px #d9d9d9",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              padding: "20px 30px",
              gap: "24px",
              fontSize: "16px",
              color: "rgba(3, 7, 30, 0.8)",
              flex: 1,
              width: "40%",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: "14px",
                fontSize: "20px",
                color: "#03071e",
              }}
            >
              <div
                style={{
                  position: "relative",
                  lineHeight: "130%",
                  fontWeight: "600",
                }}
              >
                Primary Contact Information
              </div>
              <img
                style={{ position: "relative", width: "16px", height: "16px" }}
                alt=""
                src={Icons.information_circle}
              />
            </div>
            <div
              style={{
                width: "420px",
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                justifyContent: "flex-start",
              }}
            >
              <div
                style={{
                  flex: "1",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  padding: "3px 0px",
                }}
              >
                <div
                  style={{
                    position: "relative",
                    lineHeight: "100%",
                    display: "inline-block",
                    width: "186px",
                    flexShrink: "0",
                  }}
                >
                  Last Update
                </div>
                <div
                  style={{
                    flex: "1",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    gap: "20px",
                    color: "#03071e",
                  }}
                >
                  <div style={{ position: "relative", lineHeight: "100%" }}>
                    {formattedLastUpdate}
                  </div>
                  <img
                    style={{
                      position: "relative",
                      width: "6px",
                      height: "3px",
                      opacity: "0",
                    }}
                    alt=""
                    src={Icons.vector}
                  />
                </div>
              </div>
            </div>
            <div
              style={{
                width: "420px",
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                justifyContent: "flex-start",
              }}
            >
            </div>
            <div
              style={{
                width: "420px",
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                justifyContent: "flex-start",
              }}
            >
              <div
                style={{
                  flex: "1",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  padding: "3px 0px",
                }}
              >
                <div
                  style={{
                    position: "relative",
                    lineHeight: "100%",
                    display: "inline-block",
                    width: "186px",
                    flexShrink: "0",
                  }}
                >{`No of People `}</div>
                <div
                  style={{
                    flex: "1",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    gap: "20px",
                    color: "#03071e",
                  }}
                >
                  {/* // numb of People */}
                  <div style={{ position: "relative", lineHeight: "100%" }}>
                    {customer.member.length}
                  </div>
                  <img
                    style={{
                      position: "relative",
                      width: "6px",
                      height: "3px",
                      opacity: "0",
                    }}
                    alt=""
                    src={Icons.vector}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactDetail;
