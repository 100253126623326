import { createAsyncThunk } from '@reduxjs/toolkit';
import { ENDPOINT } from '../../pages/Opportunity/Redux/oppor';

const GET_WORKTYPE = 'netnet/Redux/GET_WORKTYPE';

const workEndpoint = `${ENDPOINT}/api/opportunity/worktype/list/`;

export const getWorkType = createAsyncThunk(
  GET_WORKTYPE,
  async (meta, thunkAPI) => {
    const req = await fetch(workEndpoint, {
      method: 'GET',
      headers: {
        Authorization: `JWT ${meta.access_token}`,
        'Content-Type': 'application/json',
      },
    });

    const data = await req.json();

    if (!req.ok) {
      thunkAPI.rejectWithValue(data);
    }
    return data;
  },
);

const initialState = {
  category: [],
  loading: false,
  error: null,
};

const WorkReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${GET_WORKTYPE}/pending`:
      return {
        ...state,
        loading: true,
      };
    case `${GET_WORKTYPE}/rejected`:
      return {
        ...state,
        error: action.payload,
        category: [],
      };
    case `${GET_WORKTYPE}/fulfilled`:
      return {
        ...state,
        category: action.payload,
      };
    default:
      return state;
  }
};

export default WorkReducer;
