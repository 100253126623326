import TextField from '@mui/material/TextField';
import { stateToHTML } from 'draft-js-export-html';
import MUIRichTextEditor from 'mui-rte';
import { EditorState } from 'draft-js';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import React, { useState, useEffect } from 'react';
import { Alert, Avatar, Grid, Typography, Tooltip } from '@mui/material';
import { useSelector } from 'react-redux';
import { Box } from '@mui/system';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { ENDPOINT } from '../Redux/oppor';
import { useDispatch } from 'react-redux';
import { getWorkTypes } from '../../User/redux';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import LockPersonIcon from '@mui/icons-material/LockPerson';
import VisibilityIcon from '@mui/icons-material/Visibility';

const WorkTypes = ({
  formData,
  setFormData,
  setCurrentScope,
  setSelectedWorkType,
  newData,
  setNewData,
}) => {
  const [searchQuery, setSearchQuery] = useState('');
  const access_token = useSelector((state) => state.auth.user.access);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getWorkTypes({
        access_token,
      })
    );
  }, []);

  const [tempData, setTempData] = useState('');

  const [newOpen, setNewOpen] = useState(1);
  const work = useSelector((state) => state.Misc.workTypes);
  const cate = Array.isArray(work) && work.length ? work : false;
  const [tempArr, setTempArr] = useState([]);
  const filteredList = cate
    ? cate.filter((item) =>
        item.name.toLowerCase().startsWith(searchQuery.toLowerCase())
      )
    : null;
  const listToRender = searchQuery ? filteredList : cate;

  return (
    <Grid
      item
      container
      direction="column"
      alignItems="flex-start"
      sx={{
        padding: '1rem 0.56rem',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          alignItems: 'center',
        }}
      >
        <h3 className="grey-txt" style={{ fontSize: '13px' }}>
          {' '}
          Please Select The Work Types For this Estimate.
        </h3>
        <InfoOutlinedIcon sx={{ color: '#4dfad2', fontSize: '15px' }} />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
          padding: '1.56rem 1rem',
          backgroundColor: '#fff',
          border: '1px solid #d9d9d9',
          borderRadius: '8px',
          margin: '1rem 0',
          gap: '1rem',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '1rem',
            width: '100%',
          }}
        >
          <Box
            sx={{
              backgroundColor: '#8feeb2',
              padding: '1rem 1.5rem',
              borderRadius: '8px',
              color: '#000',
              width: '100%',
              fontFamily: 'Open Sans',
              fontSize: '1.1rem',
            }}
          >
            {tempData || '*SELECT WORKTYPE(s)'}
          </Box>
          <TextField
            id="outlined-basic"
            placeholder="Search Work Type Catagory"
            variant="standard"
            color="secondary"
            style={{ width: '95%', margin: '1rem 0' }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {' '}
                  <SearchIcon />{' '}
                </InputAdornment>
              ),
            }}
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          {newOpen === 1 && (
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                gap: '0.58rem',
                marginTop: '1rem',
                maxHeight: { xl: '310px', lg: '275px', md: '245px' },
                overflowY: 'auto',
              }}
            >
              {listToRender ? (
                listToRender.map((item) => (
                  <Box>
                    <Box
                      className="fullTabV2"
                      sx={{
                        fontWeight: '400',
                        fontFamily: 'Open Sans',
                        padding: { xl: '1rem 2rem', lg: '1rem 1rem' },
                        display: 'flex',
                        justifyContent: 'space-between',
                        ...(tempArr.find(
                          (ele) => Number(ele?.id) === Number(item.id)
                        )
                          ? {
                              backgroundColor: '#9152ff',
                              color: '#fff',
                              fontWeight: '600',
                            }
                          : {}),
                      }}
                      onClick={() =>
                        setTempArr((prev) => {
                          if (prev.length) {
                            if (prev.find((obj) => obj.id === item.id)) {
                              return prev.filter((obj) => obj.id !== item.id);
                            } else {
                              return [
                                ...prev,
                                { id: item.id, user_rate: item.user_rate },
                              ];
                            }
                          } else {
                            return [{ id: item.id, user_rate: item.user_rate }];
                          }
                        })
                      }
                    >
                      <p>{item.name}</p>
                      <Box
                        sx={{
                          width: '40%',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Typography
                          sx={{
                            backgroundColor: '#fafafa',
                            padding: {
                              xl: '8px 8px',
                              lg: '9px 8px',
                              md: '9px 8px',
                              sm: '9.5px 8px',
                            },
                            borderStartStartRadius: '10px',
                            borderEndStartRadius: '10px',
                            color: 'GrayText',
                            fontSize: {
                              xl: '15px',
                              lg: '13px',
                              md: '12px',
                              sm: '10px',
                            },
                          }}
                        >
                          $ {item.user_rate}
                        </Typography>
                        <Typography
                          sx={{
                            marginTop: '0px',
                            backgroundColor: '#ededed',
                            padding: '8px 8px',
                            borderStartEndRadius: '10px',
                            borderEndEndRadius: '10px',
                            color: 'gray',
                            fontSize: {
                              xl: '15px',
                              lg: '13px',
                              md: '12px',
                              sm: '10px',
                            },
                          }}
                        >
                          USD/Hour
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                ))
              ) : (
                <Alert severity="info">
                  You do not any worktypes please add your own worktypes.
                </Alert>
              )}
            </Box>
          )}

          <Box
            sx={{
              marginLeft: 'auto',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <button
              className="create-btn"
              onClick={(e) => {
                setNewData({
                  ...newData,
                  collaborator_worktype: tempArr,
                });

                if (tempArr) {
                  const myData = tempArr.map((item) => Number(item.id));
                  const dataaa = cate.filter((item) =>
                    myData.includes(Number(item.id))
                  );
                  setSelectedWorkType(dataaa);
                  setCurrentScope(null);
                }
              }}
            >
              Save
            </button>
          </Box>
        </Box>
      </Box>
    </Grid>
  );
};

const ColabFirstStep = ({
  formData,
  setFormData,
  newData,
  setNewData,
  setSelectedWorkType,
  selectedWorkType,
  dileverable,
  setDeliverable,
}) => {
  const currentUser = useSelector((state) => state.userData.userData);

  useEffect(() => {
    if (
      formData &&
      Array.isArray(formData.collaborator) &&
      formData.collaborator.length
    ) {
      const target = formData.collaborator.find(
        (ele) => Number(ele.collab_detail) === Number(currentUser.id)
      );
      if (
        target &&
        Array.isArray(target.collaborator_deliverable) &&
        target.collaborator_deliverable.length
      ) {
        setSelectedWorkType(
          target.collaborator_deliverable[0].deliverable_worktype
        );
      }
    }
  }, [formData]);

  const [selectedTeam, setSelectedTeam] = useState([]);
  const [selected, setSelected] = useState(1);
  const [currentScope, setCurrentScope] = useState(null);
  const [description, setDiscription] = useState('');

  const Description = ({ setDiscription }) => {
    const [html, setHtml] = useState('');

    const [editor, setEditor] = useState(() => {
      EditorState.createEmpty().getCurrentContent();
    });
    const [fieldEmpty, setFieldEmpty] = useState(editor);
    const fieldText = (e) => {
      setEditor(e);
      setHtml(stateToHTML(e.getCurrentContent()));
    };

    const myTheme = createTheme({});
    const [desc, setDesc] = useState([]);

    useEffect(() => {
      if (
        selectedWorkType &&
        Array.isArray(selectedWorkType) &&
        selectedWorkType.length
      ) {
        setNewData({
          ...newData,
          collaborator_worktype: selectedWorkType,
        });
      }
    }, [selectedWorkType]);

    return (
      <Grid
        item
        container
        direction="column"
        alignItems="flex-start"
        sx={{
          padding: '1rem 0.56rem',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            alignItems: 'center',
          }}
        >
          <h3 className="grey-txt" style={{ fontSize: '13px' }}>
            Please Add Your descriptions
          </h3>
          <InfoOutlinedIcon sx={{ color: '#4dfad2', fontSize: '15px' }} />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            width: '100%',
            padding: '1.56rem 1rem',
            backgroundColor: '#fff',
            border: '1px solid #d9d9d9',
            borderRadius: '8px',
            margin: '1rem 0',
            gap: '1rem',
            maxHeight: { xl: '310px', lg: '275px', md: '245px' },
            overflowY: 'auto',
          }}
        >
          <Box
            sx={{
              backgroundColor: '#f8f9fa',
              borderRadius: '8px',
              fontFamily: 'Open Sans',
              color: '#000',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: '0.8rem',
              padding: '1.56rem 1rem',
              width: '100%',
            }}
            className="Editor-para2"
          >
            <p className="baseTypography2" style={{ fontWeight: 'bold' }}>
              *Description
            </p>
            <ThemeProvider theme={myTheme}>
              <MUIRichTextEditor
                label="Enter Description"
                inlineToolbar={false}
                onChange={fieldText}
                value={fieldEmpty}
                controls={['bold', 'italic', 'underline', 'strikethrough']}
                customControls={[
                  {
                    name: 'stateLine',
                    icon: '|',
                    type: 'inline',
                  },
                  {
                    name: 'stateLine2',
                    icon: '|',
                    type: 'inline',
                  },
                ]}
              />
            </ThemeProvider>
          </Box>
          {desc.map((index) => {
            <Box
              key={index}
              sx={{
                backgroundColor: '#f8f9fa',
                borderRadius: '8px',
                fontFamily: 'Open Sans',
                color: '#000',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: '0.8rem',
                padding: '1.56rem 1rem',
                width: '100%',
              }}
              className="Editor-para2"
            >
              <p className="baseTypography2">Description</p>
              <ThemeProvider theme={myTheme}>
                <MUIRichTextEditor
                  label="Enter Description"
                  inlineToolbar={false}
                  controls={['bold', 'italic', 'underline', 'strikethrough']}
                  customControls={[
                    {
                      name: 'stateLine',
                      icon: '|',
                      type: 'inline',
                    },
                    {
                      name: 'stateLine2',
                      icon: '|',
                      type: 'inline',
                    },
                  ]}
                />
              </ThemeProvider>
            </Box>;
          })}
          <Box
            sx={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}
          >
            <button className="cancel-btn">Reset</button>
            <button
              className="create-btn"
              onClick={() => {
                setCurrentScope('team');
                setDiscription(html);
              }}
            >
              Save
            </button>
          </Box>
        </Box>
      </Grid>
    );
  };

  const TeamList = ({ item, setMembers, members }) => {
    const [state, setState] = useState(true);

    const [showTeam, setShowTeam] = useState(true);

    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          jusitfyContent: 'space-between',
          padding: '1rem 1rem',
          cursor: 'pointer',
          backgroundColor: members.includes(item.email) ? '#9152ff' : 'white',
        }}
        className="fullTabV2"
        onClick={() => {
          if (showTeam === true) {
            setMembers([...members, item.email]);
            setShowTeam(false);
          } else {
            setMembers(members.filter((param) => param !== item.email));
            setShowTeam(true);
          }
        }}
      >
        <div className="dFlex" style={{ gap: '0.5rem', alignItems: 'center' }}>
          <Avatar src={`${ENDPOINT}${item.avatar}`} alt="Contact" />
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              color: members.includes(item.email) ? 'white' : 'blue',
            }}
          >
            <p
              className="company-name"
              style={{
                color: members.includes(item.email) ? 'white' : 'blue',
              }}
            >
              {item.first_name}
            </p>

            <p className="state">{item.title}</p>
            <p className="state">{item.company ? item.company.name : ''}</p>
          </Box>
        </div>
        {state ? (
          <LockPersonIcon
            sx={{ color: '#af8dfc', cursor: 'pointer' }}
            onClick={() => setState(!state)}
          />
        ) : (
          <VisibilityIcon
            sx={{ color: '#7323fa', cursor: 'pointer' }}
            onClick={() => setState(!state)}
          />
        )}
      </Box>
    );
  };

  const TeamMembers = ({ setSelectedTeam }) => {
    const currentTeam = useSelector((state) => state.userTeam.team);
    const [members, setMembers] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const currentTeamFiltered = currentTeam.member
      ? currentTeam.member.filter((item) =>
          item.first_name.toLowerCase().startsWith(searchQuery.toLowerCase())
        )
      : null;
    const [visibleColab, setVisibleColab] = useState(true);
    return (
      <Grid
        item
        container
        direction="column"
        alignItems="flex-start"
        sx={{
          padding: '1rem 0.56rem',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            alignItems: 'center',
          }}
        >
          <h3 className="grey-txt" style={{ fontSize: '13px' }}>
            Please Select Your Team for this Estimate
          </h3>
          <InfoOutlinedIcon sx={{ color: '#4dfad2', fontSize: '15px' }} />
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
            padding: '1.56rem 1rem',
            backgroundColor: '#fff',
            border: '1px solid #d9d9d9',
            borderRadius: '8px',
            margin: '1rem 0',
            gap: '1rem',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <Box
              sx={{
                backgroundColor: '#e9ecef',
                padding: '1rem 1.5rem',
                borderRadius: '8px',
                color: '#000',
                width: '100%',
                fontFamily: 'Open Sans',
                fontSize: '1.1rem',
              }}
            >
              Select Your Team
            </Box>
            <TextField
              id="outlined-basic"
              placeholder="Search Team"
              variant="standard"
              color="secondary"
              style={{ width: '100%', margin: '1rem 0' }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {' '}
                    <SearchIcon />{' '}
                  </InputAdornment>
                ),
              }}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'end',
                gap: '10px',
              }}
            >
              <p style={{ fontWeight: 'bold' }}>
                DECIDE VISIBILITY TO SPONSOR{' '}
              </p>
              {visibleColab ? (
                <LockPersonIcon
                  sx={{ color: '#af8dfc', cursor: 'pointer' }}
                  onClick={() => setVisibleColab(!visibleColab)}
                />
              ) : (
                <VisibilityIcon
                  sx={{ color: '#7323fa', cursor: 'pointer' }}
                  onClick={() => setVisibleColab(!visibleColab)}
                />
              )}
            </Box>
            <Box
              sx={{
                width: '100%',
                maxHeight: { xl: '200px', lg: '175px', md: '245px' },
                overflowY: 'auto',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: '1rem',
                marginTop: '1rem',
              }}
            >
              {currentTeamFiltered && currentTeamFiltered
                ? currentTeamFiltered.map((item) => (
                    <TeamList
                      item={item}
                      setMembers={setMembers}
                      members={members}
                    />
                  ))
                : 'You dont have any Team'}
            </Box>
            <Box
              sx={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}
            >
              <button className="cancel-btn">Reset</button>
              <button
                className="create-btn"
                onClick={() => {
                  setFormData({
                    ...formData,
                    members,
                  });
                  // ;
                  if (members) {
                    const filtered = currentTeam.member.filter((item) =>
                      members.includes(item.email)
                    );
                    setSelectedTeam(filtered);
                    setCurrentScope('types');
                  }
                }}
              >
                Save
              </button>
            </Box>
          </Box>
        </Box>
      </Grid>
    );
  };

  const FormStack = ({
    description,
    selectedTeam,
    dileverable,
    setDeliverable,
  }) => {
    return (
      <Grid
        direction="column"
        alignItems="flex-start"
        container
        item
        xs={12}
        md={5.75}
        lg={5.73}
        xl={5.52}
        sx={{
          gap: '1rem',
          marginTop: '1rem',
        }}
      >
        <Box
          sx={{
            backgroundColor: '#D2B8FF',
            borderRadius: '8px',
            padding: {
              xl: '14px 23px',
              lg: '10px 18px',
              md: '8px 15px',
              sm: '6px 12px',
            },
            position: 'relative',
            border: '1px solid #d9d9d9',
            borderRadius: '8px',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <div>
            <h3 className="sub-title2">Description</h3>
            {description ? (
              <p dangerouslySetInnerHTML={{ __html: description }}></p>
            ) : (
              <p className="fstxt">Add one or more descriptions</p>
            )}
          </div>
          <ArrowForwardIosIcon
            sx={{
              color: '#03071e99',
              cursor: 'pointer',
              '&:hover': {
                transform: 'scale(1.1)',
              },
            }}
            onClick={() => setCurrentScope('description')}
          />
        </Box>
        <Box
          sx={{
            backgroundColor: '#E9ECEF',
            borderRadius: '8px',
            padding: {
              xl: '14px 23px',
              lg: '10px 18px',
              md: '8px 15px',
              sm: '6px 12px',
            },
            position: 'relative',
            border: '1px solid #d9d9d9',
            borderRadius: '8px',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <div>
            <h3 className="sub-title2">Team Members</h3>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                flexWrap: 'wrap',
                gap: '10px',
              }}
            >
              {formData.subteam_estimate &&
              Array.isArray(formData.subteam_estimate[0]?.members) &&
              formData.subteam_estimate[0].members.length ? (
                formData.subteam_estimate[0].members.map((item) => {
                  return (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap: '15px',
                        backgroundColor: '#F2EBFF',
                        borderRadius: '10px',
                        padding: '1rem 1rem',
                      }}
                    >
                      <Avatar />
                      <Box>
                        <p style={{ fontWeight: 'bold' }}>{item.first_name}</p>
                        <p style={{ color: 'blueviolet' }}>
                          {item.work_category}
                        </p>
                      </Box>
                    </div>
                  );
                })
              ) : (
                <p className="fstxt">Choose Your team Memebers.</p>
              )}
            </div>
          </div>
          <ArrowForwardIosIcon
            sx={{
              color: '#03071e99',
              cursor: 'pointer',
              '&:hover': {
                transform: 'scale(1.1)',
              },
            }}
            onClick={() => setCurrentScope('team')}
          />
        </Box>

        <Box
          sx={{
            backgroundColor: '#5FCEA899',
            borderRadius: '8px',
            padding: {
              xl: '14px 23px',
              lg: '10px 18px',
              md: '8px 15px',
              sm: '6px 12px',
            },
            position: 'relative',
            border: '1px solid #d9d9d9',
            borderRadius: '8px',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <div>
            <h3 className="sub-title2">Work Types</h3>
            {selectedWorkType ? (
              selectedWorkType.map((item) => {
                return (
                  <button
                    className="create-btn"
                    style={{ marginRight: '5px', marginTop: '5px' }}
                  >
                    {item.name}
                  </button>
                );
              })
            ) : (
              <p className="fstxt">Choose or Add your Work types</p>
            )}
          </div>
          <Tooltip
            title={
              Array.isArray(dileverable) && dileverable.length
                ? 'You can not change the worktypes now'
                : ''
            }
          >
            <ArrowForwardIosIcon
              sx={{
                color: '#03071e99',
                cursor: 'pointer',
                '&:hover': {
                  transform: 'scale(1.1)',
                },
              }}
              onClick={() => {
                if (Array.isArray(dileverable) && dileverable.length) {
                  return;
                }
                setCurrentScope('types');
              }}
            />
          </Tooltip>
        </Box>
      </Grid>
    );
  };

  return (
    <Grid container sx={{ height: '500px' }}>
      <Grid
        container
        alignItems="flex-end"
        sx={{
          padding: '1rem 0.8rem',
          backgroundColor: '#FBF8FF',
          border: '1px solid #d9d9d9',
          borderRadius: '8px',
          width: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '0.5rem',

            width: '49%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: { xl: '93.8%', lg: '97%', md: '97.5%', sm: '97%' },
              alignItems: 'center',
            }}
          >
            <h3 className="sub-title2">Estimate Details</h3>
            <InfoOutlinedIcon sx={{ color: '#4dfad2', fontSize: '15px' }} />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '10px',
              width: { xl: '93.8%', lg: '97%', md: '97.5%', sm: '97%' },
            }}
          >
            <span className="fstxt">Enter your Estimate Type</span>
            <TextField
              sx={{ width: { xl: '100%', lg: '100%', md: '100%', sm: '100%' } }}
              placeholder="Enter Estimate"
              value={formData.title}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  title: e.target.value,
                })
              }
              disabled={true}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            padding: {
              xl: '1rem 1rem .1rem 4rem',
              lg: '1rem .8rem .1rem 1.8rem',
              md: '.8rem .7rem .1rem 1.4rem',
              sm: '.3rem .3rem .1rem .7rem',
            },
            borderLeft: '1px solid #000',
            gap: '1rem',
            width: { xl: '48%', lg: '45%', md: '45%', sm: '44%' },
          }}
        >
          <span>Select Your Estimate Type</span>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: { xl: '1rem', lg: '1rem', md: '1rem', sm: '.5rem' },
            }}
          >
            <button
              className={selected === 1 ? 'estaBtn slctd' : 'estaBtn'}
              onClick={() => setSelected(1)}
            >
              Project
            </button>
            <button
              className={selected === 2 ? 'estaBtn slctd' : 'estaBtn'}
              onClick={() => setSelected(2)}
            >
              Retainer
            </button>
          </Box>
        </Box>
      </Grid>
      <Grid item container direction="row" justifyContent="space-between">
        <FormStack
          description={description}
          selectedTeam={selectedTeam}
          dileverable={dileverable}
          setDeliverable={setDeliverable}
        />
        <Grid direction="column" alignItems="flex-start" xs={6} md={6} lg={6}>
          <Grid
            item
            container
            direction="column"
            alignItems="flex-start"
            sx={{
              gap: '1rem',
            }}
          >
            {currentScope === 'description' && (
              <Description setDiscription={setDiscription} />
            )}
            {currentScope === 'team' && (
              <TeamMembers setSelectedTeam={setSelectedTeam} />
            )}
            {currentScope === 'types' && (
              <WorkTypes
                formData={formData}
                setFormData={setFormData}
                setCurrentScope={setCurrentScope}
                setSelectedWorkType={setSelectedWorkType}
                newData={newData}
                setNewData={setNewData}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ColabFirstStep;
