import * as React from 'react';
import NetNetLogo from '../../Componenets/Logo';


function Header (){
  const LogoDiv = {
    bgDiv: {
      width : '100%',
      height: '40px',
      backgroundColor:'#1c04bd',
      display:'flex',
      justifyContent:'center',
      alignItems:"center"},

    netNetDiv : {
      width : '91px',
      height:'30px',
      backgroundColor:'none',
      margin : '0 auto',
    },
   }

    return(
      <div style={LogoDiv.bgDiv}>
        <div style={LogoDiv.netNetDiv}>
              <NetNetLogo />
        </div>
      </div>
    )
}

export default Header