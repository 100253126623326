import youTubeLogo from '../../assets/youTubeLogo.svg'
import TextField from "@mui/material/TextField";

const YouTubeLink2 = ({formData, setFormData}) => {
  return (
    <>
      <div
        style={{
          alignSelf: "stretch",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
          gap: "10px",
          textAlign: "center",
          fontSize: "12px",
        }}
      >
        <img
          style={{ position: "relative", width: "35.71px", height: "25px" }}
          alt=""
          src={youTubeLogo}
        />
        <div
          style={{
            position: "relative",
            lineHeight: "100%",
            fontWeight: "600",
          }}
        >
          Enter Your Course YouTube LInk
        </div>
      </div>
      <div
        style={{
          alignSelf: "stretch",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          color: "#03071e",
        }}
      >
        <div
          style={{
            alignSelf: "stretch",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            gap: "4.5px",
          }}
        >
          <div
            style={{
              position: "relative",
              lineHeight: "14px",
              fontWeight: "600",
            }}
          >
            YouTube URL
          </div>
          <TextField
            id="standard-number"
            placeholder="Enter Your Link"
            InputLabelProps={{
              shrink: true,
            }}
            value={formData.youtube_link}
            onChange={(e) =>
              setFormData({ ...formData, youtube_link: e.target.value })
            }
            variant="standard"
            fullWidth
          />
        </div>
      </div>
      <div
        style={{
          position: "relative",
          fontSize: "14px",
          letterSpacing: "-0.3px",
          lineHeight: "19px",
          textAlign: "center",
        }}
      >
        OR
      </div>
      <div
        style={{
          alignSelf: "stretch",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          color: "#03071e",
        }}
      >
        <div
          style={{
            alignSelf: "stretch",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            gap: "4.5px",
          }}
        >
          <div
            style={{
              position: "relative",
              lineHeight: "14px",
              fontWeight: "600",
            }}
          >
            Youtube Video ID
          </div>

          <TextField
            id="standard-number"
            placeholder="Enter Your Video ID"
            InputLabelProps={{
              shrink: true,
            }}
            value={formData.youtube_id}
            onChange={(e) =>
              setFormData({ ...formData, youtube_id: e.target.value })
            }
            variant="standard"
            fullWidth
          />
        </div>
      </div>
      <div
        style={{
          alignSelf: "stretch",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          color: "rgba(0, 0, 0, 0.6)",
          fontFamily: "Roboto",
        }}
      >
        <div
          style={{
            alignSelf: "stretch",
            borderRadius: "4px 4px 0px 0px",
            backgroundColor: "rgba(0, 0, 0, 0.06)",
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            position: "relative",
          }}
        >
          <div
            style={{
              alignSelf: "stretch",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              padding: "6px 12px 4px",
              gap: "2px",
              zIndex: "0",
            }}
          >
            <div
              style={{
                position: "relative",
                letterSpacing: "0.15px",
                lineHeight: "12px",
              }}
            >
              Enter Druation of your Video
            </div>
            <div
              style={{
                alignSelf: "stretch",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
                fontSize: "14px",
                color: "rgba(0, 0, 0, 0.87)",
                fontFamily: "'Open Sans'",
              }}
            >
              <TextField
                id="filled-helperText"
                variant="standard"
                placeholder="HH:MM:SS"
                fullWidth
                value={formData.duration}
                onChange={(e) =>
                  setFormData({ ...formData, duration: e.target.value })
                }
              />
            </div>
          </div>
        </div>
        <div
          style={{
            alignSelf: "stretch",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            padding: "3px 14px 0px",
          }}
        >
          <div
            style={{
              alignSelf: "stretch",
              position: "relative",
              letterSpacing: "0.4px",
              lineHeight: "166%",
            }}
          >
            e.g HH:MM:SS
          </div>
        </div>
      </div>
    </>
  );
};

export default YouTubeLink2;

