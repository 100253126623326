import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { DataGridPro } from '@mui/x-data-grid-pro';
import Drawer from '@mui/material/Drawer';
import {
  Avatar,
  Typography,
  Tooltip,
  Switch,
} from '@mui/material';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useNavigate } from 'react-router-dom';
import Dots from './assets/Dots.png';
import { ENDPOINT } from '../Opportunity/Redux/oppor';
import { getQuickTask } from './proRedux';
import { NetKanbanPrograss, NetProgress } from '../../Componenets/ProgressBar';
import CreateTask from './CreateTask';
import { NetNetCheckBox, DashedField } from '../../Componenets/Misc';
import Gear from './assets/Gear.png';
import ListIco from './assets/ListIco.png';
import AvatarGroup from '@mui/material/AvatarGroup';
import Bounce from 'react-reveal/Bounce';
import {
  FindIcon,
  TaskDetail,
} from './_module';
import Board from 'react-trello';
import kanBanIco from './assets/KanBanIco.png';
import moment from 'moment/moment';
import helpVid from '../Opportunity/assets/Section_Help-idle.svg'
import SubdirectoryArrowRightRoundedIcon from '@mui/icons-material/SubdirectoryArrowRightRounded';
import { getWorkTypes } from '../User/redux';
import QuickHelpVideos from './ProjectDet/QuickHelpVideos';


const CustomCard = ({
  title,
  description,
  label,
  otherText,
  assignee,
  taskType,
  netTotal,
  fullTotal,
  total_tracked,
  eta,
  status,
  checked,
  task_project,
}) => {

  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const handleResize = () => {
    setScreenSize(window.innerWidth);
  };

  const [cardClicked, setCardClicked] = useState(checked);
  const handleCardClick = () => {
    setCardClicked(!cardClicked);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  return (
    <div style={{
      backgroundColor: '#f9f9f9',
      borderRadius: '8px',
      padding: '10px 10px',
      cursor: 'pointer',
      margin: "16px",
      width:
        screenSize >= 1750
          ? '360px'
          : screenSize >= 1650 && screenSize <= 1749
            ? '320px'
            : screenSize >= 1300
              ? '240px'
              : screenSize < 1300
                ? '190px'
                : 'auto',
       }} >
      <div
        onClick={handleCardClick}
      >
        {title && (
          <div
            style={{
              backgroundColor: taskType === "quick_task" ? '#e0e0e0' : "#e0b4fa33",
              borderStartStartRadius: '8px',
              borderStartEndRadius: '8px',
              padding: '14px 16px 10px 14px',
              fontWeight: 700,
              fontSize: '17px',
            }}
          >
            <p>{title}</p>
            {task_project !== undefined && (

              <Box sx={{ color: 'black', fontSize: '13px', marginRight: "5px", fontWeight: 550 }}>
                <SubdirectoryArrowRightRoundedIcon sx={{ fontSize: '17px' }} /> {task_project.toUpperCase()}
              </Box>
            )}
          </div>
        )}
      </div>

      {cardClicked && (
        <div style={{
          transition: 'max-height 0.3s ease-in-out',
          maxHeight: description || otherText || label || status || assignee ? '1000px' : '0',
          overflow: 'hidden',
        }}>
          {description && (
            <div style={{
              padding: '14px 16px 10px 14px',
              fontWeight: 500,
              backgroundColor: "#f2f2f2",
              maxHeight: '1000px',
              overflow: 'hidden',
            }}>
              <p style={{ fontSize: "16px", }} dangerouslySetInnerHTML={{ __html: description }}></p>
            </div>
          )}
          {otherText && (
            <div
              style={{
                backgroundColor: '#e7deff',
                color: '#711fff',
                padding: '4px 8px 4px 8px',
                borderRadius: '8px',
                marginTop: '14px',
                fontWeight: 600,
                fontSize: '0.98rem',
                maxHeight: '1000px',
                overflow: 'hidden',
              }}
            >
              <p>{otherText}</p>
            </div>
          )}
          {label && (
            <div
              style={{
                padding: '4px 8px 4px 8px',
                backgroundColor: '#e0dede',
                width: '70%',
                borderRadius: '8px',
                marginTop: '10px',
                color: '#dc143c',
                maxHeight: '1000px',
                overflow: 'hidden',
              }}
            >
              <span style={{ fontSize: '0.92rem', fontWeight: 600 }}>
                {' '}
                {label}
              </span>
            </div>
          )}

          {status && (
            <div
              style={{
                fontWeight: 'bold',
                width: '100%',
                borderRadius: '8px',
                marginTop: '10px',
                maxHeight: '1000px',
                overflow: 'hidden',
              }}
            >
              <Tooltip title="Represents the total duration">
                <div className="td">
                  <NetKanbanPrograss
                    status={status}
                    obt={
                      total_tracked
                    }
                    eta={
                      fullTotal ? fullTotal : netTotal ? netTotal : eta
                    }
                  />
                </div>
              </Tooltip>
            </div>
          )}


          {assignee ? (
            <Box
              sx={{
                cursor: 'pointer',
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "flex-end",
                maxHeight: '1000px',
                overflow: 'hidden',
              }}
            >
              <AvatarGroup
                max={3}
                sx={{
                  justifyContent: 'flex-end',
                  textAlign: 'center',
                  paddingLeft: '15px',
                  '& .css-sxh3gq-MuiAvatar-root-MuiAvatarGroup-avatar': {
                    height: '19px',
                    width: '19px',
                    fontSize: '14px',
                  },
                  '& .css-rfuczr': {
                    height: '19px',
                    width: '19px',
                    fontSize: '14px',
                  },
                  '& .css-1llrwy8': {
                    height: '19px',
                    width: '19px',
                    fontSize: '14px',
                  },
                }}
              >
                {Array.isArray(assignee) && assignee.length
                  ? assignee?.filter((item) => item?.first_name !== "First Name" && item?.first_name !== "First Name ").map((item, index) => {
                    return (
                      <>
                        <Avatar
                          sx={{ height: '19px', width: '19px' }}
                          alt={item?.first_name}
                          src={
                            item?.avatar
                              ? item?.avatar
                              : 'static/images/avatar/1.jpg'
                          }
                        />
                      </>
                    );
                  })
                  : null}

              </AvatarGroup>
            </Box>
          ) : null}

        </div>
      )}
    </div>
  );
};

const CustomLaneHeader = ({ title, realData }) => {
  const tasksLength = realData.find(lane => lane.title === title)?.cards.length || 0;

  return (
    <div
      style={{
        backgroundColor:
          title === 'Backlog'
            ? '#e0b4fa'
            : title === 'Need More Work'
              ? '#f0e4b9'
              : title === 'Inprogress'
                ? '#79d0ed'
                : title === 'Stuck'
                  ? '#ffbd85'
                  : title === 'Completed'
                    ? '#56d6a0'
                    : title === 'Submitted for Review'
                      ? '#e7deff'
                      : title === 'Archive'
                        ? '#51b7c9'
                        : null,
        padding: '8px 23px 8px 23px',
        borderStartStartRadius: '8px',
        borderStartEndRadius: '8px',
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderBottom:
          title === 'Backlog'
            ? '4px solid #65099c'
            : title === 'Need More Work'
              ? '4px solid #c49a02'
              : title === 'Inprogress'
                ? '4px solid #0000FF'
                : title === 'Stuck'
                  ? '4px solid #bb5805'
                  : title === 'Completed'
                    ? '4px solid #075433'
                    : title === 'Submitted for Review'
                      ? '4px solid #711fff'
                      : title === 'Archive'
                        ? '2px solid #51b7c9'
                        : null,
      }}
    >
      <h5>{title}</h5>
      <div style={{ width: '30px', height: '30px', borderRadius: '50%', fontSize: '14px', padding: '3px', background: '#fff', color: 'black', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {tasksLength}
      </div>
    </div>
  );
};

const darkTypography = {
  color: '#310085',
  textTransform: 'uppercase',
  fontWeight: '600',
  fontFamily: 'Open Sans',
  fontSize: '1.2rem',
};
export { darkTypography };

const PrevBox = ({
  args,
  setArgs,
  timeParams,
  setTimeParams,
  projects,
  setProjects,
  member,
  searchQuery,
  setSearchQuery,
  setKanBanShow,
  kanBanShow,
  myArr,
  setSelectedPeople,
  selectedPeople,
  archivedTasks,
  handleChange,
  checked
}) => (

  <Box
    sx={{
      backgroundColor: '#f2f2f2',
      borderRadius: '8px 0px 0 0',
      borderTopRightRadius: '8px',
      height: '70px',
      width: '100%',
      border: '1px solid rgba(224, 224, 224, 1)',
      borderBottom: '1.5px solid  #d9d9d9',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '0.5rem 1rem',

    }}
  >
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        width: { xxl: '36%', xl: '45%', lg: '49%', md: '60%' },
        gap: '1rem',
      }}
    >
      <FormControl
        sx={{
          width: { xl: '400px', lg: '450px', md: '800px' },
        }}
      >
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          sx={{
            fontWeight: '600',
            textAlign: 'center',
            '& .MuiSelect-select:focus': {
              backgroundColor: '#711fff',
              color: '#fff',
              outline: '1px solid #711fff',
              border: '1px solid #711fff',
            },
            '& .MuiSelect-select:hover': {
              backgroundColor: '#711fff',
              color: '#fff',
              outline: '1px solid #711fff',
              border: '1px solid #711fff',
            },
            '& .MuiSelect-select': {
              backgroundColor: '#711fff',
              color: '#fff',
              outline: '1px solid #711fff',
              border: '1px solid #711fff',
              padding: {
                xl: '9px 32px 10px 10px',
                lg: '7px 32px 10px 10px',
                md: '6px 30px 8px 10px',
              },
            },
            '& .MuiSelect-icon': {
              color: '#fff',
            },
            '& .MuiSelect-selectMenu': {
              color: '#fff',
            },
          }}
          value={args}
          onChange={(e) => {
            setArgs(e.target.value);
            if (e.target.value === 'archive') {
              setProjects(archivedTasks);
            }
            else {
              setProjects((_) => {
                const filteredMyArr =
                  Array.isArray(myArr) && myArr.length
                    ? myArr.filter((item) => item.task_type === e.target.value)
                    : [];
                const myArr2 =
                  e.target.value &&
                    e.target.value !== 'all' &&
                    e.target.value !== 'all2'
                    ? filteredMyArr
                    : myArr;

                return myArr2.map((filtered) => Object.assign({}, filtered));
              });
            }
          }}
        >
          <MenuItem
            sx={{
              fontWeight: '500',
              fontSize: {
                xl: '15px',
                lg: '13px',
                md: '12px',
                sm: '11px',
              },
            }}
            value="all2"
          >
            All Tasks
          </MenuItem>
          <MenuItem
            sx={{
              fontWeight: '500',
              fontSize: {
                xl: '15px',
                lg: '13px',
                md: '12px',
                sm: '11px',
              },
            }}
            value="quick_task"
          >
            Quick Tasks
          </MenuItem>
          <MenuItem
            sx={{
              fontWeight: '500',
              fontSize: {
                xl: '15px',
                lg: '13px',
                md: '12px',
                sm: '11px',
              },
            }}
            value="project_task"
          >
            Job Tasks
          </MenuItem>
          <MenuItem
            sx={{
              fontWeight: '500',
              fontSize: {
                xl: '15px',
                lg: '13px',
                md: '12px',
                sm: '11px',
              },
            }}
            value="archive"
          >
            Archived Tasks
          </MenuItem>
        </Select>
      </FormControl>

      <FormControl
        sx={{
          width: { xl: '400px', lg: '450px', md: '800px' },
        }}
      >
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={timeParams}
          onChange={(e) => {
            setTimeParams(e.target.value);
            setArgs('all2');
            setProjects((_) => {
              const shallow_copy = myArr.map((item) => Object.assign({}, item));

              if (e.target.value === 'due_soon') {
                const dueSoon = shallow_copy
                  .filter((tsk) => tsk.due_date !== null)
                  .filter((obj) => {
                    const finishData = obj.due_date;
                    return moment(finishData).isBefore(moment().add(7, 'days'));
                  });
                return dueSoon;
              }
              if (e.target.value === 'month') {
                const dueMonth = shallow_copy
                  .filter((et) => et.due_date !== null)
                  .filter((ent) => {
                    const finishDate = ent.due_date;
                    return moment(finishDate).isSame(moment(), 'month');
                  });
                return dueMonth;
              }
              if (e.target.value === 'six_months') {
                const semester = shallow_copy
                  .filter((iin) => iin.due_date !== null)
                  .filter((nnn) => {
                    const culmination = nnn.due_date;
                    return moment(culmination).isAfter(
                      moment().add(4, 'months'),
                      'month'
                    );
                  });
                return semester;
              }
              return myArr.map((pro) => Object.assign({}, pro));
            });
          }}
          sx={{
            fontWeight: '600',
            textAlign: 'center',
            '& .MuiSelect-select:focus': {
              backgroundColor: '#D2B8FF',
              color: '#000',
              outline: '0.5px solid #A0A2AA',
              border: '0.5px solid #A0A2AA',
            },
            '& .MuiSelect-select:hover': {
              backgroundColor: '#D2B8FF',
              color: '#000',
              outline: '0.5px solid #A0A2AA',
              border: '0.5px solid #A0A2AA',
            },
            '& .MuiSelect-select': {
              backgroundColor: '#fff',
              color: '#000',
              outline: '0.5px solid #A0A2AA',
              border: '0.5px solid #A0A2AA',
              transition: 'all 0.3s ease-in-out',
              padding: {
                xl: '9px 32px 10px 10px',
                lg: '7px 32px 10px 10px',
                md: '6px 30px 8px 10px',
              },
            },
            '& .MuiSelect-icon': {
              color: '#000',
            },
            '& .MuiSelect-selectMenu': {
              color: '#000',
            },
          }}
          color="secondary"
        >
          <MenuItem
            sx={{
              fontWeight: '500',
              fontSize: {
                xl: '15px',
                lg: '13px',
                md: '12px',
                sm: '11px',
              },
            }}
            value="due_soon"
          >
            Due Soon
          </MenuItem>
          <MenuItem
            sx={{
              fontWeight: '500',
              fontSize: {
                xl: '15px',
                lg: '13px',
                md: '12px',
                sm: '11px',
              },
            }}
            value="month"
          >
            Due This Month
          </MenuItem>
          <MenuItem
            sx={{
              fontWeight: '500',
              fontSize: {
                xl: '15px',
                lg: '13px',
                md: '12px',
                sm: '11px',
              },
            }}
            value="next_month"
          >
            Due Next 30 Days
          </MenuItem>
          <MenuItem
            sx={{
              fontSize: {
                xl: '15px',
                lg: '13px',
                md: '12px',
                sm: '11px',
              },
            }}
            value="six_months"
          >
            Due in Next 6 Month
          </MenuItem>
          <MenuItem
            sx={{
              fontWeight: '500',
              fontSize: {
                xl: '15px',
                lg: '13px',
                md: '12px',
                sm: '11px',
              },
            }}
            value="all"
          >
            All Tasks
          </MenuItem>
        </Select>
      </FormControl>
      <FormControl
        sx={{
          width: { xl: '400px', lg: '450px', md: '800px' },
        }}
      >
        <Select
          labelId="demo-simple-select-label"
          value={'people'}
          onChange={(e) => console.log(e.target.value)}
          sx={{
            fontWeight: '500',
            textAlign: 'center',
            padding: '0px',

            '& .MuiSelect-select:focus': {
              backgroundColor: '#D2B8FF',
              color: '#000',
              outline: '0.5px solid #A0A2AA',
              border: '0.5px solid #A0A2AA',
            },
            '& .MuiSelect-select:hover': {
              backgroundColor: '#D2B8FF',
              color: '#000',
              outline: '0.5px solid #A0A2AA',
              border: '0.5px solid #A0A2AA',
            },
            '& .MuiSelect-select': {
              backgroundColor: '#fff',
              color: '#000',
              outline: '0.5px solid #A0A2AA',
              border: '0.5px solid #A0A2AA',
              transition: 'all 0.3s ease-in-out',
              padding: {
                xl: '7px 32px 10px 10px',
                lg: '7px 32px 7px 10px',
                md: '5px 32px 5px 10px',
              },
            },
            '& .MuiSelect-icon': {
              color: '#000',
            },
            '& .css-6hp17o-MuiList-root-MuiMenu-list': { width: '100%' },

            '& .MuiSelect-selectMenu': {
              color: '#000',
            },
          }}
          color="secondary"
        >
          <MenuItem
            value="people"
            sx={{
              width: '100%',
              paddingLeft: '1rem',
              paddingRight: '0',
              paddingTop: '0',
              paddingBottom: '0',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                width: '100%',
              }}
            >
              <Typography style={{ fontWeight: 'bold' }}>People</Typography>
              <AvatarGroup
                max={2}
                sx={{
                  justifyContent: 'flex-end',
                  textAlign: 'center',
                  paddingLeft: '15px',
                  '& .css-sxh3gq-MuiAvatar-root-MuiAvatarGroup-avatar': {
                    height: '19px',
                    width: '19px',
                    fontSize: '14px',
                  },
                  '& .css-rfuczr': {
                    height: '19px',
                    width: '19px',
                    fontSize: '14px',
                  },
                  '& .css-1llrwy8': {
                    height: '19px',
                    width: '19px',
                    fontSize: '14px',
                  },
                }}
              >
                {Array.isArray(member) && member.length
                  ? member.filter((item) => item.first_name !== "First Name" && item.first_name !== "First Name ").map((item, index) => {
                    return (
                      <>
                        <Avatar
                          sx={{ height: '19px', width: '19px' }}
                          alt={item.first_name}
                          src={
                            item.avatar
                              ? item.avatar
                              : 'static/images/avatar/1.jpg'
                          }
                        />
                      </>
                    );
                  })
                  : null}
              </AvatarGroup>
            </Box>
          </MenuItem>
          {Array.isArray(member) && member.length
            ? member.filter((item) => item.first_name !== "First Name").map((item, index) => {
              return (
                <>
                  <MenuItem
                    value={item.first_name}
                    key={index + Math.random()}
                    sx={{
                      width: '100%',
                      paddingLeft: '0',
                      paddingRight: '0',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '5px',
                        width: '100%',
                      }}
                    >
                      <NetNetCheckBox
                        checked={selectedPeople.includes(item.id)}
                        onChange={(e) => {
                          setArgs('all2');
                          // setTimeParams('all');

                          if (!selectedPeople.includes(item.id)) {
                            setSelectedPeople([
                              ...selectedPeople,
                              item.id,
                            ]);
                          } else {
                            const filteredPeople = selectedPeople.filter(
                              (person) => person !== item.id
                            );
                            setSelectedPeople(filteredPeople);
                          }
                        }}
                      />
                      <Avatar
                        alt={item.first_name}
                        src={
                          item.avatar
                            ? item.avatar
                            : 'static/images/avatar/1.jpg'
                        }
                        sx={{
                          height: { xl: '19px', lg: '19px', md: '16px' },
                          width: { xl: '19px', lg: '19px', md: '16px' },
                        }}
                      />
                      <Typography
                        sx={{
                          fontWeight: '500',
                          fontSize: {
                            xl: '15px',
                            lg: '13px',
                            md: '12px',
                            sm: '11px',
                          },
                        }}
                      >
                        {item.first_name}
                      </Typography>
                    </Box>
                  </MenuItem>
                </>
              );
            })
            : 'No Team'}
        </Select>
      </FormControl>
      {kanBanShow && (
        <Box sx={{ fontSize: "16px", display: "flex", direction: "row", alignItems: "center", textAlign: "center" }} >
          <Switch

            checked={checked}
            onChange={handleChange}
            inputProps={{ 'aria-label': 'controlled' }}
          />
          <Typography sx={{ color: "#03071E", fontSize: "15px", fontWeight: 400, width: "8rem", lineHeight: "19px" }} > Expend All Tasks </Typography>
        </Box>
      )}
    </Box>
    <Box
      sx={{
        width: { xxl: '20%', xl: '20%', lg: '35%', md: '30%' },
        display: 'flex',
        alignItems: 'center',
        gap: '1rem',
        justifyContent: 'flex-end',


      }}
    >
      <FindIcon
        setSearchQuery={setSearchQuery}
        searchQuery={searchQuery}
        myArr={myArr}
        projects={projects}
        setProjects={setProjects}
      />
      <img
        src={ListIco}
        onClick={() => setKanBanShow(false)}
        style={{ cursor: 'pointer' }}
      />
      <img
        src={kanBanIco}
        onClick={() => setKanBanShow(true)}
        style={{
          cursor: 'pointer',
        }}
      />
    </Box>
  </Box>
);

const calculateTotalDurationObtained = (timeTrackingArray) => {
  let totalDuration = 0;

  if (Array.isArray(timeTrackingArray) && timeTrackingArray.length > 0) {
    timeTrackingArray.forEach(item => {
      // Parse the tracked_time_hours string into hours, minutes, and seconds
      let [hours, minutes, seconds] = item?.tracked_time?.split(':').map(Number);

      // Convert hours, minutes, and seconds into total hours
      let totalSeconds = hours * 3600 + minutes * 60 + seconds;
      let totalHoursForItem = totalSeconds / 3600;

      // Add to the total duration accumulator
      totalDuration += totalHoursForItem;
    });
  }
  return totalDuration;
};

const QuickTasks = ({ toggleDrawer, setState, state, screenHeight }) => {
  const dispatch = useDispatch();
  const [currentTarget, setCurrentTarget] = useState({});
  const token = useSelector((state) => state.auth.user.access);
  const [params, setParams] = useState();
  const currentCompanies = useSelector((state) => state.Contacts.companies);
  const Team = useSelector((state) => state.userTeam.team);
  const [args, setArgs] = useState('quick_task');
  const [timeParams, setTimeParams] = useState('due_soon');
  const realProjects = useSelector(item => item.projects.projects)
  const [selectedPeople, setSelectedPeople] = useState([]);
  const [show, setShow] = useState(false);
  const [kanbanShow, setKanbanShow] = useState(false);
  const [counter, setCounter] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const myArr2 = useSelector((state) => state.projects.tasks)
  const myArr = myArr2.filter(item => item.status !== 'archive')
  const archivedTasks = useSelector((state) => state.projects.tasks).filter(item => item.status === 'archive')
  const [projects, setProjects] = useState([]);
  const [filterOption, setFilterOption] = useState(false);


  useEffect(() => {
    if (args === 'all2' && timeParams === 'all' && !searchQuery) {
      dispatch(
        getQuickTask({
          access_token: token,
        })
      );
    }
    getWorkTypes({ access_token: token })

  }, []);

  useEffect(() => {
    if (args === 'all2' && timeParams === 'all' && !searchQuery) {
      dispatch(
        getQuickTask({
          access_token: token,
        })
      );
    }
    dispatch(
      getQuickTask({
        access_token: token,
      })
    );
  }, [show, params, currentTarget, counter]);



  const months = {
    '01': 'Jan',
    '02': 'Feb',
    '03': 'Mar',
    '04': 'Apr',
    '05': 'May',
    '06': 'Jun',
    '07': 'Jul',
    '08': 'Aug',
    '09': 'Sep',
    10: 'Oct',
    11: 'Nov',
    12: 'Dec',
  };

  const peopleFilter =
    Array.isArray(selectedPeople) && selectedPeople.length
      ? myArr.filter((item) =>
        item.assignee_task.some((task) =>
          selectedPeople.includes(task.assignee_user)
        )
      )
      : myArr;

  const filteredSearch = myArr.filter((item) => item?.task_name?.toLowerCase().includes(searchQuery.toLowerCase()))

  useEffect(() => {
    if (args !== 'archive') {
      setProjects(filteredSearch)
    }
  }, [searchQuery])

  useEffect(() => {
    setProjects(peopleFilter);
  }, [selectedPeople]);

  document.addEventListener('click', (e) => {
    if (e.target.id != 'filter') {
      setFilterOption(false);
    }
  });



  useEffect(() => {
    if (args !== 'archive') {
      if (args === "project_task") {
        const filteredPro = myArr.filter((item) => item.task_type === 'project_task')
        setProjects(filteredPro)
      } else {
        const fileterd = myArr.filter((item) => item.task_type === "quick_task")
        setProjects(args === 'quick_task' ? fileterd : myArr);
      }

    }
  }, [myArr2]);

  const columns = [
    {
      field: 'task_name',
      headerName: 'Task name',
      align: 'left',
      flex: 1.5,
      renderHeader: (_) => (
        <div className="tableHeader" style={{ padding: '0 0 0 1rem' }}>
          Task Name
        </div>
      ),
      renderCell: (params) => {
        const project_name = params.row.type !== null ? realProjects.find(item => item.id === params.row.task_project)?.title : ""
        return (
          <div
            className="td"
            style={{ padding: '0 0 0 1rem', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', position: 'relative', height: '100%', justifyContent: "center", width: '100%' }}
            onClick={() => {
              setCurrentTarget(params.row);
              setParams(params.row);
              setShow(true);
            }}
          >
            <Tooltip title={params.value}>{params.value}</Tooltip>
            {params.row.type !== "quick_task" ? <Tooltip title={project_name}><Box sx={{ color: '#711FFF', position: 'absolute', top: '50px', left: '15px', fontSize: '13px' }}><SubdirectoryArrowRightRoundedIcon sx={{ fontSize: '17px' }} /> {project_name?.toUpperCase()}</Box></Tooltip> : ""}
          </div>
        )
      }
    },
    {
      field: 'company',
      headerName: 'Company Name',
      // width: 240,
      flex: 1.5,
      renderHeader: (_) => <div className="tableHeader">Company Name</div>,
      renderCell: (params) => {
        let taskCompany = currentCompanies.find((comp) => Number(comp.id) === Number(params.value))?.name
        let projectCompany = currentCompanies.find(
          (comp) => Number(comp.id) === Number((realProjects.find(item => item.id === params.row.task_project)?.company))
        )?.name


        return (
          <div
            className="td"

            onClick={() => {
              setCurrentTarget(params.row);
              setParams(params.row);
              setShow(true);
            }}
          >
            {params.value ?
              taskCompany
              :
              projectCompany ??
              '-------'}
          </div>
        )
      },
    },
    {
      field: 'assignee',
      headerName: 'Assignee',
      flex: 1.5,
      renderHeader: (params) => (
        <div
          className="tableHeader"
          onClick={() => {
            setCurrentTarget(params.row);
            setParams(params.row);
            setShow(true);
          }}
        >
          Assignee
        </div>
      ),
      renderCell: (params) => (
        <div
          className="td"
          style={{
            fontSize: '1rem',
          }}
          onClick={() => {
            setCurrentTarget(params.row);
            setParams(params.row);
            setShow(true);
          }}
        >
          {params.value && Team
            ? Team.member
              .filter((membraan) =>
                Array.isArray(params.value) && params.value.length ? params.value.some(
                  (ele) => Number(ele.assignee_user) === Number(membraan.id)
                )
                  :
                  Number(params.value) === Number(membraan.id)
              )
              .map((mem, index) => index === 0 && (
                <div
                  className="dFlex"
                  style={{
                    gap: '0.5rem',
                    fontSize: '1rem',
                    fontWeight: '500',
                    fontFamily: 'Open Sans',
                  }}
                >
                  <Avatar
                    src={mem.avatar}
                    alt={mem.first_name}
                    sx={{
                      width: '25px',
                      height: '25px',

                      border: 'none',
                      '& .css-1ytufz-MuiAvatarGroup-root': {
                        border: 'none',
                      },
                    }}
                  />
                  {mem.first_name} {mem.last_name}
                </div>
              ))
            : '------'}

        </div>
      ),
    },
    {
      field: 'assinger',
      headerName: 'Assigner',
      flex: 1,
      renderHeader: (params) => (
        <div
          className="tableHeader"
          onClick={() => {
            setCurrentTarget(params.row);
            setParams(params.row);
            setShow(true);
          }}
        >
          Assignor
        </div>
      ),
      renderCell: (params) => (
        <div className="td">
          {params.value && Team
            ? Team.member
              .filter((membraan) => membraan.id === params.value)
              .map((mem) => (
                <div
                  className="dFlex"
                  style={{
                    gap: '0.5rem',
                    fontSize: '1rem',
                    fontWeight: '500',
                    fontFamily: 'Open Sans',
                  }}
                >
                  <Avatar
                    src={mem.avatar}
                    alt={mem.first_name}
                    sx={{
                      width: '25px',
                      height: '25px',
                      border: 'none',
                      '& .css-1ytufz-MuiAvatarGroup-root': {
                        border: 'none',
                      },
                    }}
                  />{' '}
                  {mem.first_name} {mem.last_name}
                </div>
              ))
            : '------'}
        </div>
      ),
    },
    {
      field: 'due_date',
      headerName: 'Due Date',
      flex: 1,
      renderHeader: (params) => (
        <div
          className="tableHeader"
          onClick={() => {
            setCurrentTarget(params.row);
            setParams(params.row);
            setShow(true);
          }}
        >
          Due Date
        </div>
      ),
      renderCell: (params) =>
        params.row.due_date ? (
          <div className="td" style={{ fontSize: '1rem', fontWeight: 600 }}>
            {`${params.row.due_date.split('T')[0]},${params.row.due_date.split(',')[1]
              }`}
          </div>
        ) : (
          <div className="td" style={{ fontSize: '1rem', fontWeight: 600 }}>
            -----
          </div>
        ),
    },
    {
      field: 'tracked_time',
      headerName: 'Time Tracked',
      // width: 200,
      flex: 1.5,
      renderHeader: (_) => <div className="tableHeader">Time Tracked</div>,
      renderCell: (params) => {
        const obtained = calculateTotalDurationObtained(
          params.row.time_tracking
        );


        const netTotal =
          Array.isArray(params.row.work_type) && params.row.work_type.length
            ? params.row.work_type.reduce(
              (acc, wk) => acc + Number(wk.hours),
              0
            )
            : 0;

        const fullTotal =
          !netTotal &&
            Array.isArray(params.row.assignee_task) &&
            params.row.assignee_task.length
            ? params.row.assignee_task.reduce(
              (acc, obj) => acc + Number(obj.hours),
              0
            )
            : 0;

        const total_tracked =
          Array.isArray(params.row.time_tracking) && params.row.time_tracking.length
            ? params.row.time_tracking.reduce((total, acc) => {
              const [hours, minutes] = acc.tracked_time_hours ? acc.tracked_time_hours?.split(':') : [0, 0]
              const hoursInMinutes = Number(hours) + Number(minutes) / 60; // Convert minutes to hours
              return total + hoursInMinutes;
            }, 0)
            : 0;

        return (
          <Tooltip title="Represents the total duration">
            <div className="td">
              <NetProgress
                status={params.row.status}
                obt={
                  total_tracked
                }
                eta={
                  fullTotal ? fullTotal : netTotal ? netTotal : params.row.eta
                }
              />
            </div>
          </Tooltip>
        );
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      align: 'right',
      flex: 1,
      renderHeader: (_) => (
        <div className="tableHeader" style={{ padding: '0 1rem 0 0' }}>
          Status
        </div>
      ),
      renderCell: (params) => (
        <div style={{ padding: '0 1rem 0 0' }}>
          <select
            className={
              params.row.status !== 'task_status'
                ? params.row.status
                : 'backlog'
            }
            onChange={async (e) => {
              setProjects((prev) => {
                const shallow_copy = [
                  ...prev.map((item) => Object.assign({}, item)),
                ];
                const int = shallow_copy.findIndex(
                  (ma) => Number(ma.id) === params.row.id
                );
                shallow_copy[int].status = e.target.value;
                return shallow_copy;
              });

              const res = await fetch(
                `${ENDPOINT}/api/project/task/update/${params.row.id}/`,
                {
                  method: 'PUT',
                  headers: {
                    'Content-Type': 'application/json',
                    Authorization: `JWT ${token}`,
                  },
                  body: JSON.stringify({
                    task_name: params.row.task_name,
                    status: e.target.value,
                  }),
                }
              );

              const data = await res.json();
              if (!res.ok) {
              } else {
                dispatch(
                  getQuickTask({
                    access_token: token,
                  })
                );
              }
            }}
            value={params.row.status}
          >
            <option value="backlog">Backlog</option>
            <option value="inprogress">In Progress</option>
            <option value="review">Submitted For Review</option>
            <option value="needs_more_work">Need more work</option>
            <option value="stuck">Stuck</option>
            <option value="completed">Completed</option>
            <option value="archive">Archived</option>
          </select>
        </div>
      ),
    },
  ];


  const row =
    projects?.length > 0
      ? projects?.map((item) => ({
        id: item.id,
        task_name: item.task_name,
        project: item.project,
        company: item.company,
        assignee: Team && Team.member ? (Array.isArray(item.assignee_task) && item.assignee_task.length ? item.assignee_task : item.assignee[0]) : null,
        assinger: Team && Team.member ? item.created_by.id : null,
        status: item.status,
        time_tracking_start: item.time_tracking_start,
        time_tracking_end: item.time_tracking_end,
        tracked_time: item.tracked_time,
        description: item.description,
        eta: item.estimated_hours,
        alt_description: item.alt_description,
        notes: item.notes,
        target_start: item.target_start,
        due_date: item.due_date
          ? `${months[item.due_date.split('-')[1]]} ${item.due_date.split('-')[2]
          }, ${item.due_date.split('-')[0]}`
          : 0,
        time_remaing: Math.floor(
          (new Date(item.finish_date) - new Date()) / 1000
        ),
        contact: item.contact,
        billable: item.billable,
        time_tracking: item.time_tracking,
        type: item.task_type,
        work_type: item.task_worktype && item.task_type !== "quick_task" ? item.task_worktype : item.quicktask_worktype ?? [],
        str_worktype: item.work_type ? item.work_type : '',
        task_project: item.task_project ? item.task_project : null
      }))
      : false;

  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const handleResize = () => {
    setScreenSize(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const member = useSelector((item) => item.userTeam.team.member);
  const [formData, setFormData] = useState({});
  const charShayo = [
    { dent: 'Backlog', identifier: 'backlog' },
    { dent: 'Inprogress', identifier: 'inprogress' },
    { dent: 'Stuck', identifier: 'stuck' },
    { dent: 'Completed', identifier: 'completed' },
    ,
  ];

  const handleCardMove = async (
    cardId,
    sourceLaneId,
    targetLaneId,
    position
  ) => {
    const find = projects.find((item) => Number(item.id) === Number(cardId));
    const newStatus = charShayo.find((ele) => ele.dent === targetLaneId);

    setProjects((prev) => {
      const shallow_copy = prev.map((item) => Object.assign({}, item));
      const int = shallow_copy.findIndex((ele) => Number(ele.id) === find.id);
      shallow_copy[int].status = newStatus.identifier;
      return [...shallow_copy];
    });
    const resP = await fetch(
      `${ENDPOINT}/api/project/task/update/${find.id}/`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `JWT ${token}`,
        },
        body: JSON.stringify({
          task_name: find.task_name,
          status: newStatus.identifier,
        }),
      }
    );
    const para = await resP.json();

    if (!resP.ok) {
      return;
    }
  };

  const realData =
    Array.isArray(row) && row.length
      ? charShayo.map((status, index) => ({
        id: status.dent,
        title: status.dent,
        label: index,
        cards: row
          .filter((ele) => ele.status === status.identifier)
          .map((ca, nt) => ({
            id: ca?.id,
            title: ca?.task_name ?? '',
            description: ca.description ? ca.description : '',
            label: ca.due_date
              ? 'Due On' +
              ' ' +
              ca.due_date.split('T')[0] +
              ' ' +
              ca.due_date.split(',')[1]
              : 'No due date available',
            otherText: ca.company
              ? currentCompanies.find(
                (sa) => Number(sa.id) === Number(ca.company)
              )?.name
              : '',
            assignee: Team.member.filter((membraan) => Array.isArray(ca.assignee) && ca.assignee.length ? ca.assignee.some((ele) => Number(ele.assignee_user) === Number(membraan.id)) : Number(ca.assignee) === Number(membraan.id)),
            taskType: ca?.type ? ca?.type : 'quick_task',
            task_project: realProjects.find(item => item.id === ca.task_project)?.title,
            netTotal: Array.isArray(ca?.work_type) && ca?.work_type.length ? ca?.work_type.reduce((acc, wk) => acc + Number(wk.hours), 0) : 0,
            fullTotal: Array.isArray(ca.assignee_task) && ca?.assignee_task.length ? ca?.assignee_task.reduce((acc, obj) => acc + Number(obj.hours), 0) : 0,
            total_tracked:
              Array.isArray(ca?.time_tracking) && ca?.time_tracking.length
                ? ca?.time_tracking.reduce((total, acc) => {
                  const [hours, minutes] = acc.tracked_time_hours
                    ? acc.tracked_time_hours?.split(':')
                    : [0, 0];
                  const hoursInMinutes =
                    Number(hours) + Number(minutes) / 60; // Convert minutes to hours
                  return total + hoursInMinutes;
                }, 0)
                : 0,
            eta: ca.eta,
            status: ca.status
          })),
      }))
      : [];


  const [checked, setChecked] = React.useState(false);
  const [showCanvas, setShowCanvas] = useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  return Array.isArray(myArr) && myArr.length || Array.isArray(archivedTasks) && archivedTasks.length ? (
    <>
      <Bounce left>
        <section className="oppor-section">
          <div className="oppor-row">
            <div className="oppor-col" >
              <h3 className="fstxtPro">Quick Tasks</h3>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  background: 'inherit',
                  border: 'none',
                  outline: 'none',
                  flexDirection: 'row',
                }}
              >
                <img src={helpVid} onClick={() => { setShowCanvas(true) }} style={{ color: '#a6a6a6', marginLeft: '10px', width: '28px', height: '28px', cursor: 'pointer' }} />
              </div>
            </div>
            {['bottom'].map((anchor) => (
              <>
                <button
                  className="create-btn"
                  id='cypress-task-createQuickTask'
                  onClick={toggleDrawer(anchor, true)}
                >
                  Create Quick Task
                </button>
                <Drawer
                  sx={{
                    height: '82vh',
                    overflow: 'auto',
                    '& .MuiDrawer-paper': {
                      maxHeight: '82vh !important',
                    },

                  }}
                  style={{
                    overflowY: 'none', // Enable vertical scrolling
                    scrollbarColor: 'transparent transparent', // For Firefox
                    '&::-webkit-scrollbar': {
                      display: "none",
                    },

                    // Styles for Microsoft Edge
                    '&::-ms-scrollbar': {
                      width: 0,
                      background: "transparent transparent"
                    },
                  }}
                  anchor={anchor}
                  open={state[anchor]}
                  onClose={toggleDrawer(anchor, false)}

                >
                  <CreateTask
                    anchor={anchor}
                    toggleDrawer={toggleDrawer}
                    state={state}
                    setState={setState}
                    counter={counter}
                    setCounter={setCounter}
                  />
                </Drawer >
              </>
            ))}
          </div>
          <Box
            sx={{
              width: '100%',
              minHeight: screenHeight - 0,
              maxHeight: screenHeight - 0,
              overflow: 'none',
            }}
          >
            <PrevBox
              args={args}
              setArgs={setArgs}
              timeParams={timeParams}
              setTimeParams={setTimeParams}
              projects={projects}
              setProjects={setProjects}
              member={member}
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              setKanBanShow={setKanbanShow}
              kanBanShow={kanbanShow}
              myArr={myArr}
              setSelectedPeople={setSelectedPeople}
              selectedPeople={selectedPeople}
              archivedTasks={archivedTasks}
              checked={checked}
              handleChange={handleChange}
            />

            {kanbanShow ? (
              <Box sx={{ width: '100%' }}>
                <Board
                  data={{ lanes: realData }}
                  draggable={true}
                  handleDragEnd={handleCardMove}
                  style={{
                    backgroundColor: '#F4F5F7',
                    width: '100%',
                    borderRadius: '4px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}

                  laneStyle={{
                    background: '#e3e3e3',
                    margin: '10px',
                    borderRadius: '8px',

                    width:
                      screenSize >= 1650 && screenSize <= 1749
                        ? '340px'
                        : screenSize >= 1750
                          ? '380px'
                          : screenSize < 1300
                            ? '220px'
                            : '260px',
                  }}
                  components={{
                    LaneHeader: (props) => <CustomLaneHeader {...props} realData={realData} />,
                    Card: (props) => <CustomCard  {...props} realProjects={realProjects} checked={checked} />,
                  }}
                />
              </Box>
            ) : (
              <DataGridPro rows={row} columns={columns} rowHeight={80} sx={{

                minHeight: screenHeight - 225,
                maxHeight: screenHeight - 225,
                overflow: "auto",
                borderRadius: "8px",
              }} />

            )}
            <QuickHelpVideos setShow={setShowCanvas} show={showCanvas} />
          </Box>
        </section>
      </Bounce >
      <TaskDetail
        formData={formData}
        currentTarget={currentTarget}
        show={show}
        setShow={setShow}
        setFormData={setFormData}
        setCurrentTarget={setCurrentTarget}
        token={token}
      />
    </>
  ) : (
    <section className="oppor-section">
      <div className="oppor-row">
        <div className="oppor-col">
          <h3 className="fstxtPro">Tasks</h3>
          <img className="icon-sm" src={Dots} alt="dots" />
          <img className="icon-sm" src={Gear} alt="gear" />
        </div>
      </div>
      <section className="myCenter" style={{ height: screenHeight - 155 }}>
        <article style={{
          display: 'flex',
          flexDirection: 'column',
          alignSelf: 'center',
          padding: '1rem 0.5rem',
          justifySelf: 'flex-start',
          width: '620px',
          margin: '0rem 2rem'
        }}>
          <h3 className="txtCenter">You Don't Have Any Project Or Quick Tasks Yet.</h3>
          <p className="txtCenter">Project Based Tasks Are Added In The Individual Projects.  Quick Tasks Are Added Here.  All Of Your Project Based And Quick Tasks Will Display Here Once You Have Some.!</p>
          {['bottom'].map((anchor) => (
            <>
              <button style={{
                width: '50%',
                height: '48px',
                border: 'none',
                borderRadius: '7px',
                fontFamily: '\'Open Sans\', sans-serif',
                fontSize: '1rem',
                fontWeight: '400',
                cursor: 'pointer',
                transition: 'all 0.3s ease-in-out',
                backgroundColor: '#711fff',
                alignSelf: 'center',
                color: '#fff',
                margin: '1rem 0'
              }} onClick={toggleDrawer(anchor, true)}>
                Click Here To Add A Quick Task
              </button>
              <Drawer
                anchor={anchor}
                open={state[anchor]}
                onClose={toggleDrawer(anchor, false)}
                sx={{
                  height: '82vh',
                  overflow: 'auto',
                }}
              >
                <CreateTask
                  anchor={anchor}
                  toggleDrawer={toggleDrawer}
                  state={state}
                  setState={setState}
                  counter={counter}
                  setCounter={setCounter}
                />
              </Drawer>
            </>
          ))}
        </article>
      </section>
    </section>
  );
};
export { calculateTotalDurationObtained };
export default QuickTasks;
