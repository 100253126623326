import { React, useEffect, useState } from 'react';
import { Alert } from '@mui/material';
import { Box } from '@mui/system';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Dots from '../assets/Dots.png';
import Gear from '../assets/Gear.png';
import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import { ENDPOINT } from '../Redux/oppor';
import { formatMoneyString } from '../estSteps/SixthStep';
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';
import goForward from '../assets/goForward.png';
import goBack from '../assets/goBack.png';
import {
  NetNetSwitch,
} from '../../../Componenets/Misc';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import DescriptionIcon from '@mui/icons-material/Description';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import Slider from '@mui/material/Slider';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import Typography from '@mui/material/Typography';
import CheckIcon from '@mui/icons-material/Check';
import TextsmsOutlinedIcon from '@mui/icons-material/TextsmsOutlined';
import ChatEst from '../ChatEst';
import { ViewMode } from 'gantt-task-react';
import ColabFirstStep from './ColabFirstStep';
import ColabSecond from './ColabSecond';
import ColabThirdStep from './ColabThirdStep';
import ColabFinalStep from './ColabFinalStep';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';

const step = [
  {
    label: 'Summary',
  },
  {
    label: 'Level of Efforts',
  },
  {
    label: 'Timeline',
  },
  {
    label: 'Net Net',
  },
];

const VerticalLinearSteppe = ({ setSteps, steps, isDetOpen, formData }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [show, setShow] = useState(true);
  const [showChatEst, setShowChatEst] = useState(false);
  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const handleResize = () => {
    setScreenSize(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return isDetOpen ? (
    <Box sx={{ zIndex: 9999 }}>
      {show ? (
        <Box
          sx={{
            backgroundColor: 'white',
            padding: '1rem 0rem',
            borderRadius: '10px',
            boxShadow: '1px 1px 5px rgba(0, 0, 0, 0.5);',
            display: show ? 'flex' : 'none',
            jusitfyContent: 'center',
            position: 'fixed',
            top: '19%',
            left: screenSize < 1200 ? 40 : 10,
            opacity: show ? 1 : 0,
            Zindex: show ? 9999 : 1,
            transition: 'opacity 0.3s ease-in-out',
            width: show
              ? { xl: '11%', lg: '15%', sm: '18%', md: '17%', sm: '22%' }
              : '0',
            height: show ? 'auto' : '0',
          }}
        >
          <Stepper
            activeStep={activeStep}
            orientation="vertical"
            sx={{ margin: '0 auto', width: '90%' }}
          >
            {step.map((step, index) => {
              if (formData.type !== 'project' && index === 2) {
                return;
              }
              return (
                <Step key={step.label}>
                  <button
                    onClick={() => {
                      if (index + 1 <= steps) {
                        setSteps(index + 1);
                      }
                    }}
                    style={{
                      gap: 10,
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      width: '100%',
                      borderRadius: '10px',
                      border: 'none',
                      backgroundColor:
                        steps === index + 1
                          ? '#cbb6fc'
                          : steps > index + 1
                            ? '#7323fa'
                            : '#f6f5f7',
                      height: '40px',
                      cursor: 'pointer',
                      paddingLeft: '8px',
                    }}
                  >
                    <CheckIcon
                      style={{
                        color:
                          steps === index + 1
                            ? '#af8dfc'
                            : steps > index + 1
                              ? 'white'
                              : '#efe8ff',
                      }}
                    />
                    <Typography
                      style={{
                        backgroundColor: 'white',
                        borderRadius: '20px',
                        padding: '1px 3px',
                        fontSize: '12px',
                      }}
                    >
                      {index + 1}
                    </Typography>
                    <Typography
                      style={{
                        color:
                          steps === index + 1
                            ? '#171717'
                            : steps > index + 1
                              ? 'white'
                              : '#575656',
                        fontSize: '12px',
                        fontWeight: '500',
                      }}
                    >
                      {step.label}
                    </Typography>
                  </button>
                </Step>
              )
            }
            )}
          </Stepper>
        </Box>
      ) : null}

      <Box
        sx={{
          marginLeft: '5px',
          position: 'fixed',
          top: { xl: '19%', lg: '20%', sm: '21%', md: '20.5%' },
          left:
            screenSize < 1200 ? '1px' : { xl: '12%', lg: '16%', sm: '18.5%' },
        }}
      >
        <p
          onClick={() => setShow(!show)}
          style={{
            cursor: 'pointer',
            writingMode: 'vertical-rl',
            textOrientation: 'revert-layer',
            padding: '15px 2px',
            backgroundColor: show ? '#ac7cfc' : '#c6a7fa',
            borderStartEndRadius: screenSize > 1200 && '12px',
            borderTopRightRadius: screenSize > 1200 && '12px',
            borderEndEndRadius: screenSize < 1200 && '12px',
            borderEndStartRadius: screenSize < 1200 && '12px',
            color: show ? 'white' : 'black',
            letterSpacing: '0.1rem',
          }}
        >
          STEPS
        </p>
        <Box
          sx={{
            padding: '15px 2px',
            fontSize: '10px',
            color: 'white',
            backgroundColor: '#ac7cfc',
            borderStartStartRadius: screenSize < 1200 && '12px',
            borderTopRightRadius: screenSize > 1200 && '12px',
            borderEndEndRadius: screenSize > 1200 && '12px',
            borderEndStartRadius: screenSize < 1200 && '12px',
            marginTop: '5px',
            width: screenSize < 1200 ? '23px' : '27px',
            cursor: 'pointer',
          }}
          onClick={() => setShowChatEst(!showChatEst)}
        >
          <TextsmsOutlinedIcon color="white" />
        </Box>
      </Box>
      {showChatEst ? <ChatEst /> : null}
    </Box>
  ) : null;
};

const EstHeader = ({ screenSize }) => (
  <Box
    style={{
      border: '1px solid #D9D9D9',
      padding: '0.7rem .5rem',
      borderRadius: '8px',
      width: { xl: '42%', lg: '50%', sm: '55%', md: '60%' },
      backgroundColor: '#e7deff',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-around',
      height: '100px',
      marginRight: '1.3rem',
      boxShadow: '1px 1px 1.5px rgba(0, 0, 0, 0.3)',
    }}
  >
    <Box
      style={{
        display: 'flex',
        flexDirection: 'row',
        marginTop: '10px',
        justifyContent: 'start',
        alignItems: 'center',
        gap: '.8rem',
      }}
    >
      <button className="netnetc-btn-act2">Deliverable Templates</button>
      <Box sx={{ marginLeft: '30px' }}>
        <button className="noBorder" type="button" role="button">
          <img
            style={{ marginLeft: '1rem', marginRight: '1rem' }}
            src={goBack}
            alt="btn"
          />
        </button>

        <button className="noBorder" type="button" role="button">
          <img src={goForward} alt="btn" />{' '}
        </button>
      </Box>
    </Box>{' '}
    <Box
      style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start' }}
    >
      <NetNetSwitch label={screenSize < 900 ? 'Tasks' : 'Show all Tasks'} />
      <NetNetSwitch
        label={screenSize < 900 ? 'Descriptions' : 'Show all Description '}
      />
    </Box>
  </Box>
);

const BudgetCheck = ({ dileverable, setGI, tarr }) => {
  const [totalCost, setTotalCost] = useState(0);

  const sumUserPrices = (deliverable) => {
    if (!deliverable || !Array.isArray(deliverable) || !deliverable.length) {
      return 0;
    }

    return deliverable.reduce((acc, item) => {
      const userPrice = Number(item.user_price);
      if (!isNaN(userPrice)) {
        acc += userPrice;
      }
      return acc;
    }, 0);
  };

  useEffect(() => {
    let cost = 0;

    if (dileverable && Array.isArray(dileverable) && dileverable.length) {
      const hasAllUserPriceDefined = dileverable.every((item) => {
        return Number(item.user_price) > 0;
      });

      if (!hasAllUserPriceDefined) {
        const result = dileverable.reduce((acc, curr) => {
          curr?.deliverable_worktype?.forEach((item) => {
            if (acc[item.id]) {
              acc[item.id].hours += Number(item.hours);
            } else {
              acc[item.id] = { id: item.id, hours: Number(item.hours) };
            }
          });
          return acc;
        }, {});

        const finalResult = Object.values(result);

        finalResult.forEach((item) => {
          const matchingType = tarr?.collaborator_worktype.find((type) => type.id === item.id);
       
          if (matchingType) {
            cost += item.hours * Number(matchingType.user_rate);
          }
        });
        setGI(cost);
        setTotalCost(cost);
      } else {
        const rawCost = sumUserPrices(dileverable);

        setGI(rawCost);
        setTotalCost(rawCost);
      }
    } else {
    }
  }, [dileverable]);

  return (
    <div
      style={{
        boxShadow: '1px 1px 3px  rgba(0, 0, 0, 0.3)',
        width: '30%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        padding: '10px',
        marginRight: '5px',
        borderRadius: '10px',
        alignItems: 'center',
        padding: '0.7rem .5rem',
      }}
    >
      <div>
        <p style={{ color: '#9257fa', fontSize: '13px' }}> Total</p>
        <p>{totalCost ? formatMoneyString(`${totalCost}`) : '$00'}</p>
      </div>
    </div>
  );
};

const ColabEst = () => {
  const [GI, setGI] = useState(0);
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [steps, setSteps] = useState(1);
  const [formData, setFormData] = useState({});
  const access_token = useSelector((state) => state.auth.user.access);
  const [pic, setPic] = useState(null);
  const [view, setView] = useState(ViewMode.Week);
  const [isDetOpen, setIsDetOpen] = useState(true);
  const { unique_id } = useParams();
  const estId = unique_id;
  const estimates = useSelector((state) => state.oppertunity.estimates);

  const user = useSelector((state) => state.userData.userData);
  useEffect(() => {
    const currentTarget =
      Array.isArray(estimates) && estimates.length
        ? estimates.find((est) => est.unique_id === estId)
        : false;
    if (!currentTarget) {
      return;
    }
    setFormData(currentTarget);
    
  }, []);

  const handleFirstStep = async () => {
    if (!formData.title) {
      setError(true);
      setErrorMessage('Estimate Name is Required.');
    } else if (!newData.collaborator_worktype) {
      setError(true);
      setErrorMessage('Please Specify Work Types.');
    } else {
      const target = formData.collaborator.find(
        (ele) => Number(ele.collab_detail) === Number(user.id)
      );
      const createData = [
        {
          estimate_id: newData.estimate_id,
          collab_id: newData.collab_id,
          financial_transparency: true,
          is_collab_ready: true,
          estimate_id: Number(formData.id),
          collab_id: Number(target.id),
          collaborator_members: [user.email],
        },
      ];
      if (!target.is_collab_ready) {
        const res = await fetch(
          `${ENDPOINT}/api/opportunity/estimate/collaborator/create/`,
          {
            method: 'PUT',
            headers: {
              Authorization: `JWT ${access_token}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(createData),
          }
        );

        const data = await res.json();
        if (!res.ok) {
          setErrorState({
            error: true,
            message: 'Something went wrong Updating Your estimate.',
          });
          return;
        } else {
          setSteps(2)
        }
      } else {
        const tres = await fetch(
          `${ENDPOINT}/api/opportunity/estimate/collaborator/update/`,
          {
            method: 'PUT',
            headers: {
              Authorization: `JWT ${access_token}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify([
              {
                estimate_id: newData.estimate_id,
                collab_id: newData.collab_id,
                financial_transparency: true,
                is_collab_ready: true,
                collaborator_delieverable: dileverable.map((item) => {
                  return {
                    deliver_id: item.id,
                    deliverable_name: item.deliverable_name,
                    deliverable_startdate: item.deliverable_startdate,
                    deliverable_enddate: item.deliverable_enddate,
                    total_hours: item.total_loe,
                    description: '',
                    user_price: Number(0),
                    our_price: Number(0),
                    loe_price: Number(0),
                    deliverable_dependency: null,
                    collaborator_worktype: item.deliverable_worktype.map(
                      (ti) => ({
                        id: `${ti.id}`,
                        hours: `${Number(ti.hours)}`,
                      })
                    ),
                  };
                }),
                estimate_id: Number(formData.id),
                collab_id: Number(target.id),
                collaborator_members: [user.email],
              },
            ]),
          }
        );

        const tdata = await tres.json();

        if (!tdata) {
          setErrorState({
            error: true,
            message: 'Something went wrong Updating Your estimate.',
          });
          return;
        } else {
          setSteps(2)
        }
      }
    }
  };

  const [temp, setTemp] = useState();
  const setErrorState = ({ error, message }) => {
    setError(error);
    setErrorMessage(message);
  };

  const [newData, setNewData] = useState({});
  const [tarr, setTarget] = useState();

  useEffect(() => {
    if (formData && 'id' in formData) {
      const target = formData.collaborator.find(
        (ele) => Number(ele.collab_detail) === Number(user.id)
      );
      setTarget(target);
      if (
        target &&
        Array.isArray(target.collaborator_deliverable) &&
        target.collaborator_deliverable.length
      ) {
        setNewData({
          ...newData,
          estimate_id: formData.id ? Number(formData.id) : '',
          collab_id: target.id ? Number(target.id) : 0,
          collaborator_members: [],
          collaborator_worktype:
            target.collaborator_deliverable[0].deliverable_worktype,
        });
        setDeliverable(target.collaborator_deliverable);
      }
    }
  }, [formData]);

  const handleSecondStep = () => {
    if (
      dileverable &&
      dileverable.length &&
      dileverable.every((delivery) => {
        return (
          Array.isArray(delivery.deliverable_worktype) &&
          delivery.deliverable_worktype.length > 0
        );
      })
    ) {
      formData.type === 'project' ? setSteps(3) : setSteps(4)
    } else {
      setErrorState({
        error: true,
        message:
          Array.isArray(formData.dileverable) && formData.dileverable.length
            ? 'Level of effort must be defined'
            : 'At least one Deliverable is required for an Estimate',
      });
    }
  };

  const handleThirdStep = async () => {
    setDeliverable((prev) => {
      const softCopy = [...prev];
      const realData = softCopy.map((item) => {
        const shallowCopy = { ...item };
        const ele = temp.find(
          (entry) => Number(entry.dentifier) === Number(item.id)
        );
        return {
          ...shallowCopy,
          deliverable_startdate: new Date(ele.start)
            .toISOString()
            .split('T')[0],
          deliverable_enddate: new Date(ele.end).toISOString().split('T')[0],
        };
      });
      return realData;
    });

    setSteps(4);
  };
  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setErrorState({ error: false, message: '' });
      }, 3000);
    }
  }, [error]);

  const handleFourthStep = async () => {
    const target = formData.collaborator.find(
      (ele) => Number(ele.collab_detail) === Number(user.id)
    );
    const createData = [
      {
        estimate_id: newData.estimate_id,
        collab_id: newData.collab_id,
        financial_transparency: true,
        is_collab_ready: true,
        estimate_id: Number(formData.id),
        collab_id: Number(target.id),
        collaborator_members: [user.email],
      },
    ];
    if (!target.is_collab_ready) {
      const res = await fetch(
        `${ENDPOINT}/api/opportunity/estimate/collaborator/create/`,
        {
          method: 'PUT',
          headers: {
            Authorization: `JWT ${access_token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(createData),
        }
      );

      const data = await res.json();
      if (!res.ok) {
        setErrorState({
          error: true,
          message: 'Something went wrong Updating Your estimate.',
        });
        return;
      } else {

        navigate('/Opportunities')
      }
    } else {
      const tres = await fetch(
        `${ENDPOINT}/api/opportunity/estimate/collaborator/update/`,
        {
          method: 'PUT',
          headers: {
            Authorization: `JWT ${access_token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify([
            {
              estimate_id: newData.estimate_id,
              collab_id: newData.collab_id,
              financial_transparency: true,
              is_collab_ready: true,
              estimate_id: Number(formData.id),
              collab_id: Number(target.id),
              collaborator_members: [user.email],
            },
          ]),
        }
      );

      const tdata = await tres.json();

      if (!tdata) {
        setErrorState({
          error: true,
          message: 'Something went wrong Updating Your estimate.',
        });
        return;
      } else {
       navigate('/Opportunities')
      }
    }
  };

  const handleSubmit = () => {
    if (steps === 1) {
      handleFirstStep();
    } else if (steps === 2) {
      handleSecondStep();
    } else if (steps === 3) {
      handleThirdStep();
    } else if (steps === 4) {
      handleFourthStep();
    }
  };

  const [dileverable, setDeliverable] = useState([]);

  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  const start =
    formData.est_startdate && formData.est_startdate.endsWith('Z')
      ? formData.est_startdate.split('T')
      : null;
  const end =
    formData.est_enddate && formData.est_enddate.endsWith('Z')
      ? formData.est_enddate.split('T')
      : null;
  const [value1, setValue1] = useState([null, null]);

  function handleRangeChange(event) {
    setValue1(event);
  }
  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const handleResize = () => {
    setScreenSize(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const [selectedWorkType, setSelectedWorkType] = useState([]);

  return (
    <section className="oppor-section5">
      <VerticalLinearSteppe
        setSteps={setSteps}
        steps={steps}
        isDetOpen={isDetOpen}
        formData={formData}
      />
      <div className="oppor-row" style={{ width: '100%' }}>
        <div className="oppor-col">
          <h3
            className="fstxtPro"
            style={{ fontSize: screenSize < 1300 ? '22px' : '25px' }}
          >
            {formData.title ? formData.title : 'Project Estimate'}
          </h3>
          <img className="icon-sm" src={Dots} alt="dots" />
          <img className="icon-sm" src={Gear} alt="gear" />
          <p style={{ fontSize: '13px', color: 'GrayText' }}>Filters</p>
          <VideocamOutlinedIcon sx={{ color: '#a6a6a6', marginLeft: '5px' }} />
        </div>
        <div style={{ width: '60%' }}>
          {steps === 2 ? (
            <div
              style={{ width: '100%', display: 'flex', justifyContent: 'end' }}
            >
              <EstHeader screenSize={screenSize} />
              <BudgetCheck
                dileverable={dileverable}
                formData={formData}
                setGI={setGI}
                tarr={tarr}
              />
            </div>
          ) : null}

          {steps === 3 ? (
            <div
              style={{ width: '100%', display: 'flex', justifyContent: 'end' }}
            >
              <Box
                sx={{
                  border: '1px solid #D9D9D9',
                  padding: '0.7rem .5rem',
                  borderRadius: '8px',
                  width: { xl: '50%', lg: '75%', md: '80%', sm: '85%' },
                  backgroundColor: '#e7deff',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  height: '100px',
                  marginRight: '1.3rem',
                  boxShadow: '1px 1px 1.5px rgba(0, 0, 0, 0.3)',
                }}
              >
                <Box
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginTop: '10px',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    color: 'gray',
                  }}
                >
                  <div>
                    <button className="noBorder" type="button" role="button">
                      <img
                        style={{
                          marginLeft: screenSize < 900 ? '.5rem' : '1rem',
                          marginRight: screenSize < 900 ? '.5rem' : '1rem',
                        }}
                        src={goBack}
                        alt="btn"
                      />
                    </button>

                    <button className="noBorder" type="button" role="button">
                      <img src={goForward} alt="btn" />{' '}
                    </button>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      fontSize: '16px',
                      alignItems: 'center',
                      width: '80%',
                    }}
                  >
                    {value1 ? (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '10px',
                          width: '100%',
                          justifyContent: 'space-around',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            gap: '5px',
                          }}
                        >
                          <CalendarMonthOutlinedIcon
                            sx={{
                              cursor: 'pointer',
                              fontSize: {
                                xl: '25px',
                                lg: '22px',
                                md: '20px',
                                sm: '20px',
                              },
                            }}
                            onClick={() => setValue1(null)}
                          />
                          <Typography
                            sx={{
                              fontSize: {
                                xl: '14px',
                                lg: '13px',
                                md: '12px',
                                sm: '11px',
                              },
                            }}
                          >
                            Start:{' '}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: {
                                xl: '14px',
                                lg: '13px',
                                md: '12px',
                                sm: '11px',
                              },
                            }}
                            style={{ marginTop: '0px', fontWeight: 'normal' }}
                          >
                            {value1 && value1[0]
                              ? value1[0].toLocaleString('en-US', options)
                              : start[0]}
                          </Typography>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            gap: '5px',
                          }}
                        >
                          <CalendarMonthOutlinedIcon
                            sx={{
                              cursor: 'pointer',
                              fontSize: {
                                xl: '25px',
                                lg: '22px',
                                md: '20px',
                                sm: '20px',
                              },
                            }}
                            onClick={() => setValue1(null)}
                          />
                          <Typography
                            sx={{
                              fontSize: {
                                xl: '14px',
                                lg: '13px',
                                md: '12px',
                                sm: '11px',
                              },
                            }}
                          >
                            End:{' '}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: {
                                xl: '14px',
                                lg: '13px',
                                md: '12px',
                                sm: '11px',
                              },
                            }}
                            style={{ marginTop: '0px', fontWeight: 'normal' }}
                          >
                            {value1 && value1[1]
                              ? value1[1].toLocaleString('en-US', options)
                              : end[0]}
                          </Typography>
                        </div>
                      </div>
                    ) : (
                      <div style={{ display: 'flex', gap: '10px' }}>
                        <DateRangePicker
                          style={{
                            width: '180px',
                            backgroundColor: 'none',
                            border: 'none',
                          }}
                          placeholder="Start "
                          onChange={handleRangeChange}
                          defaultOpen={true}
                        />

                        <DateRangePicker
                          style={{
                            width: '180px',
                            backgroundColor: 'none',
                            border: 'none',
                          }}
                          placeholder="End"
                          onChange={handleRangeChange}
                        ></DateRangePicker>
                      </div>
                    )}

                  </div>
                </Box>{' '}
                <Box
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-around',
                    alignItems: 'center',
                    color: 'gray',
                  }}
                >
                  <NetNetSwitch
                    label={
                      screenSize < 900 ? 'Dependencies' : 'Show Dependencies'
                    }
                  />
                  <p
                    style={{
                      fontSize: '25px',
                      marginTop: '0px',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      if (view === ViewMode.Week) {
                        setView(ViewMode.Day);
                      } else if (view === ViewMode.Month) {
                        setView(ViewMode.Week);
                      } else {
                        setView(ViewMode.Month);
                      }
                    }}
                  >
                    -
                  </p>
                  <Slider
                    aria-label="Temperature"
                    defaultValue={0}
                    value={
                      view === ViewMode.Day ? 0 : view === ViewMode.Week ? 1 : 2
                    }
                    valueLabelDisplay="auto"
                    step={1}
                    min={0}
                    max={2}
                    sx={{ color: 'gray', width: '40%' }}
                    onChange={(e) => {
                      if (e.target.value === 0) {
                        setView(ViewMode.Day);
                      }
                      if (e.target.value === 1) {
                        setView(ViewMode.Week);
                      }
                      if (e.target.value === 2) {
                        setView(ViewMode.Month);
                      }
                    }}
                  />{' '}
                  <p
                    style={{
                      fontSize: '25px',
                      marginTop: '0px',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      if (view === ViewMode.Week) {
                        setView(ViewMode.Month);
                      } else if (view === ViewMode.Month) {
                        setView(ViewMode.Day);
                      } else {
                        setView(ViewMode.Week);
                      }
                    }}
                  >
                    +
                  </p>
                </Box>
              </Box>
              <BudgetCheck
                formData={formData}
                dileverable={dileverable}
                setGI={setGI}
                tarr={tarr}
              />
            </div>
          ) : null}

          {steps === 5 ? (
            <div style={{ width: '100%', display: 'flex' }}>
              <div
                style={{
                  width: '70%',
                  justifyContent: 'end',
                  display: 'flex',
                  alignItems: 'end',
                  marginRight: '1rem',
                }}
              >
                <div
                  style={{
                    backgroundColor: '#e7deff',
                    padding: '.5rem 1.7rem',
                    boxShadow: '1px 1px 3px  rgba(0, 0, 0, 0.3)',
                    borderRadius: '8px',
                  }}
                >
                  <button className="noBorder" type="button" role="button">
                    <img
                      style={{ marginLeft: '1rem', marginRight: '1rem' }}
                      src={goBack}
                      alt="btn"
                    />
                  </button>

                  <button className="noBorder" type="button" role="button">
                    <img src={goForward} alt="btn" />{' '}
                  </button>
                </div>
              </div>
              <BudgetCheck
                formData={formData}
                dileverable={dileverable}
                setGI={setGI}
                tarr={tarr}
              />
            </div>
          ) : null}

          {steps === 6 ? (
            <div style={{ width: '100%', display: 'flex' }}>
              <div style={{ width: '70%', marginRight: '1rem' }}></div>
              <BudgetCheck
                formData={formData}
                dileverable={dileverable}
                setGI={setGI}
                tarr={tarr}
              />
            </div>
          ) : null}

          {steps === 7 ? (
            <div
              style={{ width: '100%', display: 'flex', alignItems: 'center' }}
            >
              <section
                style={{
                  border: '1px solid #D9D9D9',
                  borderRadius: '8px',
                  width: '70%',
                  backgroundColor: '#e7deff',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-around',
                  marginRight: '1.3rem',
                  boxShadow: '1px 1px 1.5px rgba(0, 0, 0, 0.3)',
                  padding: '1rem 1rem',
                }}
              >
                <Box
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginTop: '10px',
                    justifyContent: 'center',
                    color: 'gray',
                    gap: '.8rem',
                  }}
                >
                  <LocalPrintshopIcon />
                  <DescriptionIcon />
                  <div>
                    <button className="noBorder" type="button" role="button">
                      <img
                        style={{ marginLeft: '1rem', marginRight: '1rem' }}
                        src={goBack}
                        alt="btn"
                      />
                    </button>

                    <button className="noBorder" type="button" role="button">
                      <img src={goForward} alt="btn" />{' '}
                    </button>
                  </div>

                  <CalendarMonthIcon />

                  <button
                    style={{
                      color: '#00000',
                      padding: '0rem 1rem',
                      borderRadius: '10px',
                      backgroundColor: '#cbb6fc',
                      border: 'none',
                      fontSize: '15px',
                    }}
                  >
                    <FormatAlignLeftIcon
                      style={{ marginRight: '10px', fontSize: '18px' }}
                    />
                    ADD DESCRIPTION
                  </button>
                </Box>{' '}
              </section>
              <BudgetCheck formData={formData} steps={steps} setGI={setGI} tarr={tarr}/>
            </div>
          ) : null}
        </div>
      </div>
      <section
        className={steps < 6 ? 'container-psU-p5' : null}
        style={steps !== 6 ? { padding: '2rem 1.5rem' } : {}}
      >
        {steps === 1 && (
          <ColabFirstStep
            formData={formData}
            setFormData={setFormData}
            setNewData={setNewData}
            newData={newData}
            selectedWorkType={selectedWorkType}
            setSelectedWorkType={setSelectedWorkType}
            dileverable={dileverable}
            setDeliverable={setDeliverable}
          />
        )}

        {steps === 2 && (
          <ColabSecond
            formData={formData}
            setFormData={setFormData}
            dileverable={dileverable}
            setDeliverable={setDeliverable}
            newData={newData}
            setNewData={setNewData}
            setIsDetOpen={setIsDetOpen}
            target={tarr}
            setTarget={setTarget}
            setErrorState={setErrorState}
          />
        )}

        {steps === 3 && (
          <ColabThirdStep
            formData={formData}
            setFormData={setFormData}
            temp={temp}
            setTemp={setTemp}
            setPic={setPic}
            view={view}
            setView={setView}
            dileverable={dileverable}
            setDeliverable={setDeliverable}
          />
        )}

        {steps === 4 && (
          <ColabFinalStep
            formData={formData}
            setFormData={setFormData}
            GI={GI}
            newData={newData}
            tarr={tarr}
            dileverable={dileverable}
          />
        )}
      </section>
      {error && <Alert severity="info">{errorMessage}</Alert>}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '1rem 2.2rem',
          backgroundColor: '#fff',
          height: { xl: '90px', lg: '120px' },
          marginBottom: { xl: '.5rem', lg: '1rem' },
        }}
      >
        <button
          className="cancel-btn"
          onClick={() =>
            steps == 1
              ? navigate('/')
              : setSteps((prev) => {
                return prev - 1;
              })
          }
        >
          {steps == 1 ? 'Cancel' : 'Back'}
        </button>
        <Box>
          <button className="create-btn" onClick={handleSubmit}>
            {steps < 4 ? 'Next' : 'Finish'}
          </button>
        </Box>
      </Box>
    </section>
  );
};

export default ColabEst;
