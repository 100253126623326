
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import filterIcon from "./assets/sort 1.svg";
import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useDispatch, useSelector } from "react-redux";
import Box from '@mui/material/Box';
import { getUsers } from './usersReducer';

const dummyUsers = [
  {
    personName: "Ali",
    level: "AliTech",
    url: "//netnet.com",
    email: "thenetnet08@gmail.com",
    id: 1
  },
  {
    personName: "Petar",
    level: "SpaceX",
    url: "//spaceX.com",
    email: "thespacex08@gmail.com",
    id: 2
  },
  {
    personName: "Ronaldo",
    level: "Space2X",
    url: "//spaceX2.com",
    email: "thespacex0822@gmail.com",
    id: 4
  },
  {
    personName: "Ramos",
    level: "Hard",
    url: "//spaceX2.com",
    email: "thespacex0822@gmail.com",
    id: 5
  },
  {
    personName: "Elon Musk",
    level: "Space2X",
    url: "//spaceX2.com",
    email: "thespacex0822@gmail.com",
    id: 6
  }
]

const Header = () => {
  return (
    <div
      style={{
        position: "relative",
        backgroundColor: "white",
        width: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        padding: " 1% 1.38% 1% 1.38%",
        boxSizing: "border-box",
        textAlign: "center",
        fontSize: "29px",
        color: "#03071e",
        fontFamily: "'Open Sans'",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
          gap: "20px",
        }}
      >
        <b style={{ position: "relative", lineHeight: "100%" }}>
          Users
        </b>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            textAlign: "left",
            fontSize: "14px",
            color: "rgba(3, 7, 30, 0.4)",
          }}
        >
          <div
            style={{
              borderRadius: "4px",
              overflow: "hidden",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              gap: "3px",
            }}
          >
            <img
              style={{
                position: "relative",
                width: "18px",
                height: "18px",
                overflow: "hidden",
                flexShrink: "0",
              }}
              alt=""
              src={filterIcon}
            />
            <div
              style={{
                position: "relative",
                letterSpacing: "-0.3px",
                lineHeight: "19px",
              }}
            >
              Filters
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          padding: "5px 0 ",
          fontSize: "14px",
          color: "#fff",
        }}
      >
        <Link to="#">
          <Button
            sx={{
              width: 108,
              height: 35,
              textTransform: "capitalize",
              fontWeight: "300",
              backgroundColor: "#711FFF",
              lineHeight: "19px",
              fontSize: "14px",
              "&:hover": {
                backgroundColor: "#711DDD",
              },
            }}
            variant="contained"
          >
            + New
          </Button>
        </Link>
      </div>
    </div>
  );
};

const SystemAdminUsers = () => {

  const dispatch = useDispatch();
  const access_token = useSelector((state) => state?.auth?.user?.access);

  useEffect(() => {
    const meta = {
      access_token,
    };
    dispatch(getUsers(meta))
      .then(() =>{})
      .catch((error) => {
        console.error("Error fetching users:", error);
      });
  }, [dispatch, access_token]);


  const columns = [
    {
      field: "personName",
      headerName: "Person Name",
      width: 200,
      flex: 1,
      renderHeader: () => (
        <div className="tableHeader">
          Person Name
          <ArrowDropDownIcon style={{ marginLeft: "-12px" }} />
        </div>
      ),
      renderCell: (params) => (
        <div className="td breakSpace">
          <img src={params.row.picture} style={{ borderRadius: "50%", width: '24px', height: "24px", marginRight: "3px" }} />
          {params.row.personName}
        </div>
      ),
    },
    {
      field: "level",
      headerName: "level",
      width: 180,
      flex: 1,
      renderHeader: () => <div className="tableHeader">Level <ArrowDropDownIcon style={{ marginLeft: "-12px" }} /></div>,
      renderCell: (params) => <div className="td breakSpace">{params.value}</div>,
    },
    {
      field: "url",
      headerName: "URL",
      width: 200,
      flex: 1,
      renderHeader: () => <div className="tableHeader">URL <ArrowDropDownIcon style={{ marginLeft: "-12px" }} /></div>,
      renderCell: (params) => <div className="td breakSpace">{params.value}</div>,
    },
    {
      field: "email",
      headerName: "Email",
      width: 200,
      flex: 1,
      renderHeader: () => <div className="tableHeader">Email <ArrowDropDownIcon style={{ marginLeft: "-12px" }} /></div>,
      renderCell: (params) => <div className="td breakSpace">{params.value}</div>,
    },
  ];


  return (
    <>      
      <div
        style={{
          padding: "4px 18px"
        }}
      >

        <Header />

        <div className="authorsWrapper">

          <Box
            sx={{
              height: '100%',
              overflowY: "auto"
            }}>


            <DataGrid
              rows={Array.isArray(dummyUsers) && dummyUsers?.length ? dummyUsers : []}
              columns={columns}
              rowHeight={76}
              headerHeight={78}
              sx={{
                '.MuiDataGrid-row': {
                  color: '#000',
                  fontWeight: 500,
                  fontFamily: 'Open Sans, sans-serif',
                  fontSize: '14px',
                  letterSpacing: "0.5px",
                  backgroundColor: "#FDFDFE",
                  maxHeight: "76px"
                },
                '.MuiDataGrid-columnHeader': {
                  color: '#03071E99',
                  backgroundColor: "#F2F2F2",
                  fontFamily: 'Open Sans, sans-serif',
                },
                '.css-1q27lj3-MuiDataGrid-root': {
                  borderRadius: "10px"
                },
                '.MuiDataGrid-columnHeaders ': {
                  zIndex: 1,
                  backgroundColor: '#F2F2F2',
                  borderTopLeftRadius: "10px",
                  borderTopRightRadius: "10px",
                },
                '.tableHeader': {
                  color: ' #03071E99',
                },
                height: "100%",
                border: '1px solid rgba(224, 224, 224, 1)',
                borderRadius: "10px",
                margin: "1.38%"
              }}
              checkboxSelection
            />
          </Box>
        </div>

      </div>

    </>
  )
};

export default SystemAdminUsers;
