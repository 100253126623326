import {
  Grid,
  Box,
  Menu,
  MenuItem,
  Tooltip,
  TextField,
  Typography,
  Avatar,
  Alert,
} from '@mui/material';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { stateToHTML } from 'draft-js-export-html';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import MUIRichTextEditor from 'mui-rte';
import { EditorState, convertToRaw, RichUtils } from 'draft-js';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ENDPOINT } from '../../Opportunity/Redux/oppor';
import Duplicate from '../../../Assets/NavLink/Duplicate.svg';
import subTask from '../../../Assets/NavLink/SubTasks.svg';
import hideTask from '../../../Assets/NavLink/Hide-Tasks.svg';
import showTask from '../../../Assets/NavLink/Show-Tasks.svg';
import updateTask from '../../../Assets/NavLink/Update_Deliverable_Template.svg';
import newTask from '../../../Assets/NavLink/New_Deliverable_Template.svg';
import DeleteIcon from '../../../Assets/NavLink/Delete.svg';
import {
  DashedField2,
  DashedField,
} from '../../../Componenets/Misc';
import { getWorkTypes } from '../../User/redux';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import moment from 'moment';
import uuid from 'react-uuid';
import Test from '../../Test';
import CloseIcon from '@mui/icons-material/Close';

const colors = ['#cef6ff', '#ece7fb', '#8feeb2'];
const spanSx = {
  fontWeight: '600',
  fontFamily: 'Open Sans',
  fontSize: '1.1rem',
};
const HeadCell = ({ arg }) => (
  <TableCell align="center" style={{ textAlign: 'center' }}>
    <div className="tcell">{arg}</div>
  </TableCell>
);

const reduceTaskWorktypes = (taskWorktypes) => {
  const reducedArray = [];
  if (Array.isArray(taskWorktypes) && taskWorktypes.length) {
    taskWorktypes.forEach((item) => {
      if (
        item.task_worktype &&
        Array.isArray(item.task_worktype) &&
        item.task_worktype.length
      ) {
        item.task_worktype.forEach((worktype) => {
          const existingItem = reducedArray.find(
            (item) => item.name === worktype.name
          );

          if (existingItem) {
            existingItem.hours += parseInt(worktype.hours ? worktype.hours : 0);
          } else {
            reducedArray.push({
              name: worktype.name,
              hours: parseInt(worktype.hours ? worktype.hours : 0),
            });
          }
        });
      }
    });
  }
  return reducedArray;
};

const calculateTotalHours = (deliverables, worktypeId) => {
  return deliverables.reduce((total, deliverable) => {
    if (
      Array.isArray(deliverable.deliverable_worktype) &&
      deliverable.deliverable_worktype.length
    ) {
      return (
        total +
        deliverable.deliverable_worktype.reduce((subTotal, worktype) => {
          if (
            worktype.name === worktypeId.name &&
            worktype.hours !== ''
          ) {
   
            return subTotal + parseInt(worktype.hours ? worktype.hours : 0);
          } else {
            return subTotal;
          }
        }, 0)
      );
    } else {
      return total;
    }
  }, 0);
};


function calculateTotalLOE(array) {
  if (!array) {
    return false;
  }
  if (!Array.isArray(array) && !array.length) {
    return false;
  }
  return array.reduce((total, obj) => {
    return total + obj.total_loe;
  }, 0);
}

const LastRow = ({ formData, dileverable, counter }) => {
  const [totalHours, setTotalHours] = useState([]);


  useEffect(() => {
    if (Array.isArray(dileverable) && dileverable.length) {

      const hours = dileverable[0]?.deliverable_worktype?.map((worktypeId) => {
        return calculateTotalHours(dileverable, worktypeId);
      });
      setTotalHours(hours);
    }
  }, [formData, dileverable, counter]);
  const NetTotal = calculateTotalLOE(dileverable);
  const myTotal = NetTotal ?? false;

  const totalAll = () => {
    if (Array.isArray(formData?.deliverable_project) && formData?.deliverable_project.length) {
      return formData?.deliverable_project.reduce((total, newArr) => {
        return total + newArr?.deliverable_worktype?.reduce((newTotal, item) => newTotal + Number(item.hours), 0);
      }, 0);
    } else {
      return 0;
    }
  }

  return (
    <TableRow
      sx={{ backgroundColor: '#1e0052', color: '#fff', height: '110px' }}
    >
      <TableCell style={{ textAlign: 'left' }}>
        <div className="fstxt-wh">Total</div>
      </TableCell>
      {Array.isArray(formData?.collaborators) && formData.collaborators.length
        ? formData.collaborators.map((item) => (
          <TableCell align="left">
            <div className="fstxt-wh">0hr</div>
          </TableCell>
        ))
        : null}

      {formData && Array.isArray(formData?.deliverable_project) && formData?.deliverable_project.length ?
        formData.deliverable_project[0].deliverable_worktype.map((worktypeId, index) => (
          <TableCell align="center" key={index}>
            <div className="fstxt-wh">
              {totalHours[index] ? `${totalHours[index]}hr` : ' 0hr'}
            </div>
          </TableCell>
        ))
        :
        Array.isArray(formData.work_type) && formData.work_type.length && formData.work_type.map((worktypeId, index) => (
          <TableCell align="center" key={index}>
            <div className="fstxt-wh">
              {totalHours[index] ? `${totalHours[index]}hr` : ' 0hr'}
            </div>
          </TableCell>
        ))}

      <TableCell align="center">
        <div className="fstxt-wh">{totalAll() ? `${totalAll()}hr` : '0hr'}</div>
      </TableCell>
    </TableRow>
  );
};

const THead = ({ formData }) => {
  const userWorkTypes = useSelector((state) => state.Misc.workTypes);
  const colabs = useSelector((state) => state.Misc.collabs);
  return (
    <TableHead
      sx={{
        height: '110px',
      }}
    >
      <TableRow>
        <TableCell align="left">
          <div className="tcell">
            Deliverables{' '}
            <InfoOutlinedIcon sx={{ color: '#4dfad2', fontSize: '15px' }} />{' '}
          </div>
        </TableCell>
        {Array.isArray(formData.collaborators) && formData.collaborators.length
          ? formData.collaborators.map((item, index) => {
            const target =
              Array.isArray(colabs) && colabs.length
                ? colabs.find((ele) => Number(ele.id) === Number(item))
                : false;
            return (
              <TableCell align="left">
                <div
                  className="tcell"
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    padding: '1.44rem 0',
                    backgroundColor: colors[index],
                    borderRadius: '8px',
                  }}
                >
                  <Avatar
                    src={target ? target.collab.avatar : null}
                    alt={target ? target.collab.first_name : null}
                  />
                  <Typography>
                    {target ? target.collab.first_name : null}
                  </Typography>
                </div>
              </TableCell>
            );
          })
          : null}

        {formData && formData.deliverable_project && formData.deliverable_project[0]
          ? formData.deliverable_project[0]?.deliverable_worktype.map((item) => {
            const work = userWorkTypes?.find((w) => w.id === item.id) ?? {
              name: '...',
            };
            return <HeadCell arg={item.name} />;
          })
          : formData?.work_type?.map((item) => {
            const work = userWorkTypes?.find((w) => w.id === item.id) ?? {
              name: '...',
            };
            return <HeadCell arg={item.name} />;
          })}
        <TableCell style={{ textAlign: 'center', fontWeight: 'bold' }}>
          Total
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

const AddRows = ({ formData, dileverable, setDeliverable, setErrorState, access_token, setFormData }) => {
  const [tempState, setTempState] = useState('');
  return (
    <TableRow
      sx={{
        width: '100%',
        height: '100px',
      }}
    >
      <TableCell
        align="left"
        sx={{
          display: 'flex',
          alignItems: 'baseline',
          justifyContent: 'flex-start',
          gap: '1rem',
          borderBottom: 'none',
        }}
      >
        <DashedField
        id="Cypress-input-addDeliverable"
          label="Add Deliverable"
          value={tempState}
          
          onChange={(e) => setTempState(e.target.value)}
          onKeyPress={async (e) => {
            if (e.key === 'Enter') {
              const StateCheck =
                Array.isArray(dileverable) && dileverable.length
                  ? dileverable.find(
                    (item) => item.deliverable_name === tempState
                  )
                  : false;

              if (StateCheck) {
                setErrorState({
                  error: true,
                  message: 'A Deliverable with this name already exists',
                });
                return;
              }

              const response = await fetch(
                `${ENDPOINT}/api/project/deliverable/create/`,
                {
                  method: 'POST',
                  headers: {
                    Authorization: `JWT ${access_token}`,
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify([
                    {
                      deliverable_name: tempState,
                      deliverable_project: Number(formData?.id),
                      retain_check:false,
                      deliverable_startdate: new Date().toISOString(),
                      deliverable_enddate: new Date(new Date().setDate(new Date().getDate() + 7)).toISOString(),
                      description: '',
                      deliverable_worktype: Array.isArray(dileverable) && dileverable.length ? dileverable[0].deliverable_worktype.map((i, n) => {
                        return {
                          id: i?.id,
                          name: i?.name,
                          hours: null,
                        };
                      }) :
                        formData.work_type.map((i, n) => {
                          return {
                            id: i.id,
                            name: i.name,
                            hours: null,
                          };
                        }),
                    },
                  ]),
                }
              );
              const data = await response.json();

              if (!response.ok) {
                setErrorState({
                  error: true,
                  message: 'A Deliverable with this name already exists',
                });
                return;
              }
              else {
                const res = await fetch(`${ENDPOINT}/api/project/detail/${formData?.unique_id}/`, {
                  method: 'GET',
                  headers: {
                    'Content-Type': 'application/json',
                    Authorization: `JWT ${access_token}`,
                  },
                });
                const dataGet = await res.json();
                if (!res.ok) {
  
                }
                if (dataGet && Array.isArray(dataGet.deliverable_project) && dataGet.deliverable_project.length) {
                  setDeliverable(dataGet.deliverable_project);
                }
                setFormData(dataGet);
              }
              setTempState('');
            }
            return 0;
          }}
        />
      </TableCell>
    </TableRow>
  );
};

const DetailView = ({
  setShow,
  show,
  p,
  dileverable,
  setDeliverable,
  formData,
  access_token,
  setFormData,
  setIsTyping
}) => {
  const [html, setHtml] = useState(p?.internal_note ?? '<p></p>');

  const customOptions = dileverable.filter(
    (item) => item.deliverable_name !== p.deliverable_name
  );

  const val = moment(formData.est_start_date);
  const val2 = () => {
    const index = dileverable.findIndex(
      (ent) => Number(ent.id) === Number(p.id)
    );
    return moment(dileverable[index]?.deliverable_enddate) ?? moment();
  };

  const handleClose = () => {
    setShow(false)
    setIsTyping(false)
  };

  function calculateTotalWeeks(deliverableStartDate, deliverableEndDate) {
    const startDate = new Date(deliverableStartDate);
    const endDate = new Date(deliverableEndDate);

    // Calculate the time difference in milliseconds
    const timeDifference = endDate - startDate;

    // Convert milliseconds to weeks
    const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

    // Calculate the number of weeks (5-day weeks) based on the days difference
    const weeksDifference = Math.ceil(daysDifference / 7);

    return weeksDifference;
  }
  const [htmlDesc, setHtmlDesc] = useState(p?.description ?? '')
  const [object, setObject] = useState({})
  const [deliverName, setDeliverName] = useState()
  return (
    <Offcanvas show={show} onHide={handleClose} placement="bottom ">
      <Offcanvas.Header>
        <button className="close-modal" onClick={() => {
          setShow(false)
          setIsTyping(false)
        }} />
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Grid container direction="column" alignItems="flex-start">
          <Grid
            item
            container
            direction="column"
            alignItems="center"
            xl={12}
            lg={12}
            md={12}
            sm={12}
          >
            <Box
              sx={{
                width: '98%',
                fontSize: '1.5rem',
                fontWeight: '600',
                fontFamily: 'Open Sans',
                textAlign: 'left',
                padding: { xl: '0.50rem 4.5rem', lg: '0.50rem .2rem' },
              }}
            >
              <TextField
                variant="standard"
                value={deliverName ?? p?.deliverable_name}
                sx={{
                  width: { xl: 280, lg: 200, md: 150 },
                  fontSize: '1.5rem',
                  '& .MuiInputBase-input': {
                    fontSize: '1.5rem',
                    fontFamily: 'Open Sans',
                    fontWeight: '600',
                    color: '#000000',
                  },

                  '& .MuiInput-underline:before': {
                    borderBottom: '1px dashed #fff',
                  },
                  '& .MuiInput-underline:after': {
                    borderBottom: '1px dashed #000000',
                  },
                  '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                    borderBottom: '1px dashed #000000',
                  },
                }}
                inputProps={{ style: { fontSize: 26 } }} // font size of input text
                onChange={(e) => {
                  setDeliverName(e.target.value)
                }}
              />
            </Box>
            <Grid
              item
              container
              sx={{ width: '100%', gap: '1rem' }}
              justifyContent="center"
              alignItem="center"
            >
              <Grid item xl={5} lg={4.1} md={5.5}>
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    borderRadius: '8px',
                    border: '1px solid #d9d9d9',
                    padding: '0.44rem 0.88rem',
                    gap: '1rem',
                  }}
                >
                  <p
                    style={{
                      fontSize: '1rem',
                      fontWeight: '600',
                      fontFamily: 'Open Sans',
                    }}
                  >
                    Details
                  </p>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '0.5rem',
                      backgroundColor: '#f2ebff',
                      borderRadius: '8px',
                      padding: '0.44rem 0.88rem',
                    }}
                  >
                    <Box className="myJam">
                      <span className="modalSpan" style={{ color: 'GrayText' }}>
                        Duration{' '}
                        <InfoOutlinedIcon
                          sx={{ color: '#4dfad2', fontSize: '14px' }}
                        />
                      </span>
                      <Box
                        sx={{
                          width: '300px',
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                        }}
                      >
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          color="secondary"
                          placeholder="Enter Value"
                          defaultValue={calculateTotalWeeks(p.deliverable_startdate, p.deliverable_enddate)}
                          sx={{
                            width: '60%',
                            outline: 'none',
                            borderTopLeftRadius: '10px',
                            borderStartStartRadius: '10px',
                            backgroundColor: 'white',
                            textAlign: 'center',
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                border: '1px solid #f2ebff',
                              },
                              '&:hover fieldset': {
                                border: '1px solid #711fff',
                              },
                              '&.Mui-focused fieldset': {
                                border: '1px solid #5a0bdf',
                              },
                              '&.Mui-error fieldset': {
                                border: '1px solid #f2ebff',
                              },
                            },
                            '& .MuiFormLabel-root': {
                              color: '#03071e66',
                            },
                            '& .MuiFormLabel-root.Mui-focused': {
                              color: '#5a0bdf',
                            },
                            '& .MuiFormLabel-root.Mui-error': {
                              color: 'red',
                            },
                          }}
                          type={'number'}
                          InputProps={{
                            inputProps: { min: 1 },
                          }}
                          onChange={(e) => {
                            setDeliverable((prev) => {
                              const shallowCopy = prev.map((item) =>
                                Object.assign({}, item)
                              );

                              const index = shallowCopy.findIndex(
                                (int) => Number(int.id) === p.id
                              );
                              const start = moment(
                                p?.deliverable_startdate?.split('T')[0]
                              ).toDate();
                              const end = moment(start)
                                .add(Number(e.target.value) * 7, 'days')
                                .toDate();

                              shallowCopy[index].deliverable_enddate = end;
                              return shallowCopy;
                            })
                          }
                          }
                        />
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            backgroundColor: '#f2f2f2',
                            width: '40%',
                            color: 'GrayText',
                            borderTopRightRadius: '10px',
                            borderEndEndRadius: '10px',
                            height: '45px'
                          }}
                        >
                          <span
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            <ArrowDropUpIcon
                              sx={{ marginBottom: '-15px' }}
                            />
                            <ArrowDropDownIcon />
                          </span>
                          <Typography sx={{ fontSize: '14px' }}>Week</Typography>
                        </Box>
                      </Box>
                    </Box>
                    <Box className="myJam">
                      <span className="modalSpan" style={{ color: 'GrayText' }}>
                        Dependency{' '}
                        <InfoOutlinedIcon
                          sx={{ color: '#4dfad2', fontSize: '15px' }}
                        />
                      </span>
                      <Autocomplete
                        id="company"
                        options={customOptions}
                        defaultValue={p.deliverable_dependency && dileverable.find(item => item.id === p.deliverable_dependency)}
                        getOptionLabel={(option) => option.deliverable_name}
                        onChange={(_, value) => {
                          if (value) {
                            setDeliverable((prevDeliverables) => {
                              const updatedDeliverables = prevDeliverables.map(
                                (deliverable) => {
                                  if (Number(deliverable.id) === Number(p.id)) {
                                    return {
                                      ...deliverable,
                                      deliverable_dependency: value.id,
                                    };
                                  }
                                  return deliverable;
                                }
                              );
                              return updatedDeliverables;
                            });
                          }
                          else {
                            setDeliverable((prevDeliverables) => {
                              const updatedDeliverables = prevDeliverables.map(
                                (deliverable) => {
                                  if (Number(deliverable.id) === Number(p.id)) {
                                    return {
                                      ...deliverable,
                                      deliverable_dependency: null,
                                    };
                                  }
                                  return deliverable;
                                }
                              );
                              return updatedDeliverables;
                            });
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            color="secondary"
                            InputProps={{
                              ...params.InputProps,
                              style: { width: 250 },
                            }}
                          />
                        )}
                      />
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item xl={6} lg={7.7} md={5.5}>
                <Box
                  sx={{
                    width: '100%',
                    backgroundColor: 'white',
                    padding: '0.88rem 1rem',
                    borderRadius: '8px',
                    border: '1px solid #d9d9d9',
                    height: { xl: '170px', lg: '160px', md: '154px' },
                  }}
                >
                  <span
                    style={{
                      fontWeight: '600',
                      fontFamily: 'Open Sans',
                    }}
                  >
                    Description
                  </span>
                  <Box
                    sx={{ height: '85%', display: 'flex', flexDirection: 'column-reverse', }}
                  >
                    <Test bottom={true} html={htmlDesc} setHtml={setHtmlDesc} />
                  </Box>
                </Box>
              </Grid>

              <Grid item xl={12} lg={12} md={12}>
                <Box
                  sx={{
                    width: { xl: '93%', lg: '98.5%', md: '98%', sm: '90%' },
                    backgroundColor: '#fafafa',
                    padding: '0.88rem 1rem',
                    borderRadius: '8px',
                    margin: '0 auto',
                    height: '145px',
                  }}
                >
                  <span
                    style={{
                      fontWeight: '600',
                      fontFamily: 'Open Sans',
                    }}
                  >
                    Internal Notes
                  </span>
                  <Box
                    sx={{ height: '85%', display: 'flex', flexDirection: 'column-reverse' }}
                  >
                    {' '}
                    <Test bottom={true} html={html} setHtml={setHtml} />
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Grid item sx={{ width: { xl: '90%', lg: '98%', md: '98%' }, height: 'auto', padding: '10px' }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                }}
              >
                <button className="cancel-btn" onClick={() => setShow(false)}>
                  Cancel
                </button>
                <button
                  className="create-btn"
                  onClick={async () => {
                    const ind = dileverable.findIndex(item => item.id === p?.id)
                    const shallow_copy = dileverable.map((item) => Object.assign({}, item))


                    shallow_copy[ind].description = htmlDesc ?? shallow_copy[ind].description
                    shallow_copy[ind].deliverable_name = deliverName ? deliverName : dileverable[ind].deliverable_name
                    shallow_copy[ind].internal_note = html ?? shallow_copy[ind].internal_note
   
                    const shallowCopy = {
                      ...formData,
                    };

                    delete shallowCopy.work_type;
                    delete shallowCopy.estimate_hardcost;
                    const res = await fetch(
                      `${ENDPOINT}/api/project/update/${formData.id}/`,
                      {
                        method: 'PUT',
                        headers: {
                          Authorization: `JWT ${access_token}`,
                          'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                          ...shallowCopy,
                          type: 'project',
                          project_deliverable: shallow_copy?.map(
                            (item, index) => {
                              delete item.deliverable_multiplier
                              delete item.task_deliverable
                              const newDate = new Date(item.deliverable_enddate) - new Date(item.deliverable_startdate)
                              const durationInDays = newDate / (24 * 60 * 60 * 1000)
                              const startDateOfDil = item?.deliverable_dependency ? dileverable.find(dil => dil.id === item?.deliverable_dependency).deliverable_enddate : item.deliverable_startdate
                              const endDateOfDil = item?.deliverable_dependency ? new Date(new Date(startDateOfDil).setDate(new Date(startDateOfDil).getDate() + durationInDays)).toISOString() : item.deliverable_enddate
                              return {
                                ...item,
                                deliver_id: item.id,
                                deliverable_dependency: item.deliverable_dependency,
                                deliverable_enddate: endDateOfDil,
                                deliverable_startdate: startDateOfDil,
                                deliverable_worktype: item.deliverable_worktype.map(
                                  (wtt) => ({
                                    ...wtt,
                                    id: wtt.id,
                                    hours: wtt.hours ? Number(wtt.hours) : null,
                                  })
                                ),
                              };
                            }
                          ),

                        }),
                      }
                    );
                    const data = await res.json()
                    if (!res.ok) {
                      return
                    }
                    else {
                      const resGet = await fetch(`${ENDPOINT}/api/project/detail/${formData?.unique_id}/`, {
                        method: 'GET',
                        headers: {
                          'Content-Type': 'application/json',
                          Authorization: `JWT ${access_token}`,
                        },
                      });
                      const dataGet = await resGet.json();
                      if (!resGet.ok) {
                        return;
                      }
                      setFormData(dataGet)
                      if (
                        dataGet &&
                        Array.isArray(dataGet.deliverable_project) &&
                        dataGet.deliverable_project.length
                      ) {
                        setDeliverable(dataGet.deliverable_project);
                        setShow(false);
                        setIsTyping(false)
                      }
                    }
                    setShow(false)
                  }}
                >
                  Save
                </button>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

const CreateTemplate = ({ setOpenTemp, openTemp, p }) => {

  const work_types = useSelector(state => state.work_type.category)
  const newWork = Array.isArray(work_types) && work_types.length && work_types?.filter(item => item.id === p?.deliverable_worktype[0]?.id)
  const [html, setHtml] = useState('');
  const [editor, setEditor] = useState(() => {
    EditorState.createEmpty().getCurrentContent();
  });
  const [fieldEmpty, setFieldEmpty] = useState(editor);
  const fieldText = (e) => {
    setEditor(e);
    setHtml(stateToHTML(e.getCurrentContent()));
  };
  const access_token = useSelector((state) => state.auth.user.access);
  const [jamData, setJamData] = useState({
    deliverable_name: '',
    description: '',
    tags: '',
  });

  useEffect(() => {
    setJamData({
      ...jamData,
      description: html,
    });
  }, [html]);

  const [localErr, setLocalErr] = useState(false);
  const [localErrMsg, setLocalErrMsg] = useState('');
  const [localSucc, setLocalSucc] = useState(false);
  const myTheme = createTheme({});

  const handleSubmision = async (e) => {
    if (!jamData.deliverable_name) {
      setLocalErr(true);
      setLocalErrMsg('Please Add a Deliverable Name');
      return;
    }

    const res = await fetch(
      `${ENDPOINT}/api/project/deliverable/template/create/`,
      {
        method: 'POST',
        headers: {
          Authorization: `JWT ${access_token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: uuid(),
          deliverable: [jamData],
        }),
      }
    );
    const data = await res.json();
    if (!res.ok) {

      setLocalErr(true);
      setLocalErrMsg('Could not create Template');
      return;
    }
    setLocalSucc(true);
  };

  return (
    <Offcanvas
      show={openTemp}
      onHide={() => setOpenTemp(false)}
      placement="bottom "
    >
      <Offcanvas.Header>
        <button className="close-modal" onClick={() => setOpenTemp(false)} />
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Grid container direction="column" alignItems="flex-start">
          <Grid
            item
            container
            direction="column"
            alignItems="flex-start"
            xl={12}
            lg={12}
            md={12}
            sm={12}
          >
            <Box
              sx={{
                width: '100%',
                fontSize: '1.5rem',
                fontWeight: '600',
                textAlign: 'left',
                padding: { xl: '0.30rem 5.5rem', lg: '0.30rem 4.5rem', md: '0.30rem 3.5rem' },
                color: '#000'
              }}
            >
              Add New Delieverable Template
            </Box>
            <Box
              sx={{
                padding: { xl: '0.30rem 5.5rem', lg: '0.30rem 4.5rem', md: '0.30rem 3.5rem' },
                display: 'flex',
                alignItems: 'center',
                gap: '2rem',
                width: '100%'
              }}
            >
              <Typography
                sx={{
                  fontWeight: '600',

                  fontSize: '17px'
                }}
              >
                Current Delieverable
              </Typography>
              <Box
                sx={{
                  backgroundColor: '#f8f9fa',
                  borderRadius: '8px',
                  padding: '.5rem 1rem',
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: { xl: '30%', lg: '40%', md: '50%' }
                }}
              >
                <Box>
                  <Typography
                    sx={{
                      fontFamily: 'Open Sans',
                      fontWeight: '600',
                      color: '#000',

                    }}
                  >
                    {p.deliverable_name ? p.deliverable_name : ''}
                    <Typography sx={{ color: '#9c61ff', fontSize: '12px' }}>{Array.isArray(p.task_deliverable) && p.task_deliverable.length ? p.task_deliverable.length : '0'} TASKS</Typography>
                  </Typography>
                </Box>
                <Box style={{
                  fontWeight: '400',
                  display: 'flex',
                  alignItems: 'center',
                  backgroundColor: '#711FFF',
                  borderRadius: '5px',
                  color: 'var(--white-100)',
                  border: 'none',
                  textAlign: 'center',
                  fontSize: '13px',
                  padding: '0.3rem 1.3rem'
                }}>{newWork ? newWork[0]?.name : ''}</Box>
              </Box>
            </Box>
            <Grid
              item
              container
              sx={{
                width: { xl: '93%', lg: '93%', md: '95%' },
                gap: '1rem',
                borderRadius: '8px',
                border: '1px solid #d9d9d9',
                padding: '2rem 1.5rem',
                margin: '0 auto'
              }}
              justifyContent="center"
              alignItem="center"
              direction="column"
            >
              <Box sx={{ display: 'flex', width: '20%', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography
                  sx={{
                    color: '#000',
                    fontWeight: '600',
                    fontSize: '18px'
                  }}
                >
                  New Details
                </Typography>
                <InfoOutlinedIcon sx={{ color: '#4dfad2', fontSize: '15px' }} />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  padding: '0.88rem 1.44rem',
                  width: '100%',
                }}
              >
                <Box
                  sx={{
                    padding: '18px 24px 18px 24px',
                    borderRadius: '8px',
                    gap: '1rem',
                    boxShadow: '0px 8px 25px 0px #00000000',
                    backgroundColor: '#F1F3F5E5',
                    width: '48%',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      gap: '0.5rem',
                      width: '100%',

                    }}
                  >
                    <span style={spanSx}>Enter Your New Delieverable Name</span>

                    <TextField
                      placeholder="Delieverable Name"
                      variant="outlined"
                      value={jamData.deliverable_name}
                      onChange={(e) => {
                        setJamData({
                          ...jamData,
                          deliverable_name: e.target.value,
                        });
                      }}
                      color="secondary"
                      sx={{
                        backgroundColor: '#fff', width: '100%',
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: '#dddfe0e5',
                          },
                          '&:hover fieldset': {
                            borderColor: '#711fff',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: '#5a0bdf',
                          },
                        },
                      }
                      }
                    />
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    justifyContent: 'start',

                    gap: '1.5rem',
                    width: '48%',
                    height: '110px'
                  }}
                >
                  <Typography sx={{ fontSize: '17px' }}>Add More details to your Estimate</Typography>
                  <button className="create-btn">ADD TAGS</button>
                </Box>
              </Box>
            </Grid>

            <Grid item xl={12} lg={12} sx={{ width: '100%', marginTop: '1rem' }}>
              <Box
                sx={{
                  width: { xl: '93%', lg: '93%', md: '95%' },
                  backgroundColor: '#F1F3F5E5',
                  border: '1px solid #d9d9d9',
                  padding: '0.88rem 1rem',
                  borderRadius: '8px',
                  margin: '0 auto',
                  height: '145px',
                }}
              >
                <span
                  style={{
                    fontWeight: '600',
                    fontFamily: 'Open Sans',
                  
                  }}
                >
                  Description
                </span>
                <Box className="df-r-j-c-gp-5 Editor-para10">
                  {' '}
                  <ThemeProvider theme={myTheme}>
                    <MUIRichTextEditor
                      label="Enter Description"
                      inlineToolbar={false}
                      onChange={fieldText}
                      value={fieldEmpty}
                      controls={[
                        'bold',
                        'italic',
                        'underline',
                        'strikethrough',
                      ]}
                      customControls={[
                        {
                          name: 'stateLine',
                          icon: '|',
                          type: 'inline',
                        },
                        {
                          name: 'stateLine2',
                          icon: '|',
                          type: 'inline',
                        },
                      ]}
                    />
                  </ThemeProvider>
                </Box>
              </Box>
            </Grid>
            {localErr && <Alert x={{ width: '93%', margin: '0 auto' }} severity="error">{localErrMsg}</Alert>}
            {localSucc && (
              <Alert sx={{ width: '93%', margin: '0 auto' }} severity="success">Template created Successfully</Alert>
            )}
            <Grid item sx={{ width: '97%', height: 'auto', padding: '10px' }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                }}
              >
                <button
                  className="cancel-btn"
                  onClick={() => setOpenTemp(false)}
                >
                  Cancel
                </button>
                <button
                  className="create-btn"
                  onClick={() => {
                    handleSubmision();
                  }}
                >
                  Save
                </button>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

const CustomCell = ({
  p,
  setOpen,
  dileverable,
  setDeliverable,
  formData,
  setOpenTemp,
  open,
  setIsDetOpen,
  access_token,
  getProject,
  setFormData,
  setIsTyping
}) => {
  const [show, setShow] = useState(false);
  const [menuIconEl, setMenuIconEl] = useState(false);
  const [showStuff, setShowStuff] = useState(false);

  const disrapancy = p.deliverable_worktype.some((worktype) => {
    return Number(worktype.hours);
  });


  useEffect(() => {
    setIsDetOpen(!show);
  }, [show]);

  const handeUpdateDeliverableTemplete = async (Dil) => {
    const res = await fetch(`${ENDPOINT}/api/project/deliverable/template/update/${Dil?.deliverable_template}/`, {
      method: "PUT",
      headers: {
        'Content-Type': 'application/json',
        Authorization: `JWT ${access_token}`,
      },
      body: JSON.stringify({
        deliver_id: Dil?.id,
        deliverable_name: `${Dil.deliverable_name} - Updated`,
        description: `${Dil.description} - Updated`,
        loe_price: Dil?.loe_price,
        user_price: Dil?.user_price,
        deliverable_startdate: Dil?.deliverable_startdate,
        deliverable_enddate: Dil?.deliverable_startdate,
        change_reason: Dil?.change_reason,
        changed: Dil?.changed,
        internal_note: Dil?.internal_note,
        sort_order: Dil.sort_order,
        deliverable_worktype: Dil?.deliverable_worktype,
        task_deliverable: Dil?.task_deliverable
      })
    })

    const response = await res.json()
    if (!response) {

    }
    setIsTyping(false)
    setMenuIconEl(null)
  }

  const handleDublicateDelverable = async(dil) => {
    setMenuIconEl(null)

    let titleNum = 1
    let titleName = `${dil?.deliverable_name} - Duplicate ${titleNum}`
    while (dil.deliverable_name === titleName) {
      titleNum++
      titleName = `${dil?.deliverable_name} - Duplicate ${titleNum}`
    }

    const res = await fetch(
      `${ENDPOINT}/api/project/deliverable/create/`,
      {
        method: 'POST',
        headers: {
          Authorization: `JWT ${access_token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify([{
          deliverable_project: p?.deliverable_project,
          deliverable_name: titleName,
          description: p?.description ?? "",
          sort_order: p?.sort_order + 1,
          deliverable_worktype: Array.isArray(p?.deliverable_worktype) &&
            p?.deliverable_collaborator === null ? p?.deliverable_worktype?.map((i, n) => {
              return {
                id: i.id,
                hours: i.hours,
              };
            })
            : p?.work_type.map((i, n) => i.collab_worktype === null && ({
              id: i.id,
              hours: i.hours,
            })),
          deliverable_task: Array.isArray(p?.task_deliverable) &&
            p?.task_deliverable.map((task, index) => {
              return {
                name: task.task_name,
                task_worktypes: Array.isArray(task.task_worktype) &&
                  task.task_worktype.map((worktype, index) => {
                    return {
                      id: worktype.id,
                      hours: worktype.hours
                    }
                  })

              }
            }),
          deliverable_multiplier: [],
          deliverable_startdate: new Date(formData.est_startdate),
          deliverable_enddate: new Date(formData.est_enddate),
          internal_note: p?.internal_note,
          loe_price: p?.loe_price,
          total_hours: p?.total_hours,
          user_price: p?.user_price
        }]),
      }
    );

    const data = await res.json();
    if (!data) {
      console.log('failed to update', data);

      alert('Couldn’t Update');
      return;
    }
    setMenuIconEl(null)
    const resGet = await fetch(`${ENDPOINT}/api/opportunity/estimate/${formData?.unique_id}/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `JWT ${access_token}`,
      },
    });
    const dataGet = await resGet.json();
    if (!resGet.ok) {
      console.log('Error at Getting Estimates', data);
    }
    if (
      dataGet &&
      Array.isArray(dataGet.deliverable) &&
      dataGet.deliverable.length
    ) {
      const sortedDeliverable = dataGet.deliverable.map((deliverable) => ({
        ...deliverable,
        deliverable_worktype: deliverable.deliverable_worktype.sort((a, b) =>
          a.name.localeCompare(b.name)
        ),
      })).sort((a, b) => a.sort_order - b.sort_order);
      setDeliverable(sortedDeliverable);
    }
    return 0;
  }
  return (
    <>
      <TableCell
        align="left"
        component="th"
        scope="row"
        onMouseOver={() => setShowStuff(true)}
        onMouseLeave={() => setShowStuff(false)}
      >
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            gap: '1rem',
            color: '#03071E',
            fontFamily: 'Open Sans',
            fontSize: '1rem',
            fontWeight: '400',
          }}
        >
          <DragIndicatorIcon
            sx={{
              opacity: showStuff ? '1' : 0,
              transition: 'all 0.3s ease-in-out',
              fontSize: '1.60rem',
              fill: showStuff ? '#8D8F99' : '#fff',
            }}
          />
          <p
            className="fstxt"
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setIsTyping(true)
              setShow(true)
            }}
          >
            {p.deliverable_name}
          </p>

          <MoreHorizIcon
            sx={{
              opacity: showStuff ? '1' : 0,
              transition: 'all 0.3s ease-in-out',
              fontSize: '1.60rem',
              fill: showStuff ? '#8D8F99' : '#fff',
            }}
            id="company-icon"
            aria-haspopup="true"
            aria-controls={menuIconEl ? 'company-menu' : undefined}
            aria-expanded={menuIconEl ? 'true' : undefined}
            onClick={(e) => setMenuIconEl(e.currentTarget)}
          />
          <Menu
            id="company-icon-menu"
            sx={{
              width: 'auto',
              '& .MuiMenu-paper': {
                border: '1px solid #03071E33',
                borderRadius: '10px',
                width: 'auto'
              },
              '& .MuiMenu-list': {
                margin: '5px 5px 5px 5px',
                overflowY: 'hidden',
                maxHeight: '300px',
                width: 'auto',

              },

            }}
            anchorEl={menuIconEl}
            open={!!menuIconEl}
            onClose={() => setMenuIconEl(null)}
            autoFocus={false}
          >
            <Tooltip
              title={
                disrapancy
                  ? 'You must zero out all the hours before you can add Tasks'
                  : ''
              }
            >
              <MenuItem
                className="mb-2"
                onClick={() => {
                  if (disrapancy) {
                    return;
                  }
                  setOpen(true);
                }}
              >
                <img src={subTask} style={{ width: "22px", height: "24px" }} className="icon-sm" />
                {'Add Tasks'}
              </MenuItem>
            </Tooltip>
            {p.task_deliverable && Array.isArray(p.task_deliverable) && p.task_deliverable.length ? (
              <MenuItem
                className="mb-2"
                onClick={() =>  {
                  open ? setOpen(false) : setOpen(true)
                  setMenuIconEl(null);
                }}
              >
                <img src={open ? hideTask : showTask} style={{ width: "22px", height: "24px" }}  className="icon-sm" />{' '}
                {open ? 'Hide Tasks' : 'Show Tasks'}
              </MenuItem>
            ) : null}
            <MenuItem className="mb-2" onClick={() =>  {
              setMenuIconEl(null);
              setOpenTemp(true)
            }}>
              <img src={newTask} style={{ width: "22px", height: "24px" }} className="icon-sm" />
              New Deliverable Template
            </MenuItem>
            {
              p?.deliverable_template !== null ? (
                <>
                  <MenuItem className="mb-2" onClick={() => handeUpdateDeliverableTemplete(p)} >
                    <img src={updateTask} style={{ width: "22px", height: "24px" }} className="icon-sm" />
                    Update Deliverable Template
                  </MenuItem>
                </>
              ) : null
            }
            <MenuItem  className="mb-2" onClick={() => handleDublicateDelverable(p)} >
              <img src={Duplicate} style={{ width: "22px", height: "24px" }} className="icon-sm" />
              Duplicate
            </MenuItem>
            <MenuItem
              className="text-danger mb-2"
              onClick={async () => {
                setMenuIconEl(null)
                const response = await fetch(
                  `${ENDPOINT}/api/project/deliverable/delete/${p.id}/`,
                  {
                    method: 'DELETE',
                    headers: {
                      Authorization: `JWT ${access_token}`,
                      'Content-Type': 'application/json',
                    },
                  }
                );

                if (response.ok) {

                  getProject()
                }
                else {
                }
              }}
            >
              <img src={DeleteIcon} style={{ width: "22px", height: "24px" }} className="icon-sm" />
              Delete
            </MenuItem>
          </Menu>
        </div>
      </TableCell>
      <DetailView
        show={show}
        setShow={setShow}
        p={p}
        dileverable={dileverable}
        setDeliverable={setDeliverable}
        formData={formData}
        access_token={access_token}
        setIsTyping={setIsTyping}
        setFormData={setFormData}
      />
    </>
  );
};

const SubTaskCell = ({ c, setIsTyping, setDeliverable, i, p, formData, dileverable, access_token }) => (
  <TableCell align="right">
    <div
      id={`${c.id}`}
      style={{
        color: '#711fff',
        fontWeight: '600',
        fontFamily: 'Open Sans',
        fontSize: '1rem',
        display: 'flex',
        alignItems: 'center',
        gap: '1rem',
        padding: '0.88rem 1rem',
      }}
    >
      <img src={subTask}  style={{ width: "22px", height: "24px" }} className="icon-sm" />
      <DashedField value={c.task_name}
        onChange={(e) => {
          setIsTyping(true)
          setDeliverable((prevDil) => {
            const shallow_copy = prevDil.map((item) => ({ ...item }))
            const index = shallow_copy.findIndex((item) => item.id === p.id)
            shallow_copy[index].task_deliverable[i].task_name = e.target.value
            return shallow_copy
          })
        }}
        onBlur={async () => {
          const shallowCopy = { ...formData }
          delete shallowCopy.work_type;
          const res = await fetch(
            `${ENDPOINT}/api/project/update/${formData?.id}/`,
            {
              method: 'PUT',
              headers: {
                Authorization: `JWT ${access_token}`,
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                ...shallowCopy,
                project_deliverable: Array.isArray(dileverable) && dileverable.length
                  ? dileverable.map((item, index) => ({
                    ...item,
                    deliver_id: item.id,
                    deliverable_worktype: item.deliverable_worktype.map((wtt, ind) => ({
                      ...wtt,
                      id: wtt.id ? wtt.id : '',
                      hours: wtt.hours ? wtt.hours : null,
                    })),
                    task_deliverable: item.task_deliverable
                      ? item.task_deliverable.map((tk) => ({
                        ...tk,
                        task_id: tk.id,
                        task_name: tk.task_name,
                        task_worktypes: tk.task_worktype.map((twt) => ({
                          ...twt,
                          task_id: twt.id,
                          name: twt.task_name,
                          hours: twt.hours ? Number(twt.hours) : 0,
                        })),
                      }))
                      : [],
                  }))
                  : [],
              }),
            }
          );
          const data = await res.json()
          if (!res.ok) {

            return
          }
          else {
            setIsTyping(false)
          }
        }}

      />
    </div>
  </TableCell>
);
const AddTask = ({ setDeliverable, p, setOpen, formData, access_token, setFormData }) => {
  const [str, setStr] = useState('');
  const listWorktypes = useSelector((state) => state.Misc.workTypes);
  const dispatch = useDispatch()
  return (
    <Tooltip
      title="Press Enter to Add a New Task"
      arrow
      open={str.length > 2}
      placement="bottom"
    >
      <div
        style={{
          width: 'fit-content',
          padding: '1.44rem',
          display: 'flex',
          gap: '1rem',
          alignItems: 'center',
        }}
      >
        <img src={subTask} style={{ width: "22px", height: "24px" }} className="icon-sm" />
        <DashedField
          label="Add New Task"
          value={str}
          onChange={(e) => setStr(e.target.value)}
          onKeyPress={async (e) => {
            if (e.key === 'Enter' && str) {
              const response = await fetch(`${ENDPOINT}/api/project/task/create/`, {
                method: 'POST',
                headers: {
                  Authorization: `JWT ${access_token}`,
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  task_name: str,
                  task_deliverable: p.id,
                  task_project: formData.id,
                  description: '',
                  estimated_hours: 0,
                  task_type: "project_task",
                  task_worktypes: p.deliverable_worktype.map((item, index) => {
                    const id = listWorktypes.find((ele) => ele.name === item.name)?.id
                    return {
                      id: id,
                      hours: 0,
                    }
                  })
                }
                ),
              });
              const data = await response.json();
              if (!response.ok) {
                for (const key in data) {
       
                }
              } else {
                const res = await fetch(`${ENDPOINT}/api/project/detail/${formData?.unique_id}/`, {
                  method: 'GET',
                  headers: {
                    'Content-Type': 'application/json',
                    Authorization: `JWT ${access_token}`,
                  },
                });
                const dataGet = await res.json();
                if (!res.ok) {
 
                  return
                }
                setFormData(dataGet)

                if (
                  data &&
                  Array.isArray(dataGet.deliverable_project) &&
                  dataGet.deliverable_project.length
                ) {
                  setDeliverable(dataGet.deliverable_project);
                }
              }
              setStr('');
            }
          }}
        />
      </div>
    </Tooltip>
  );
};


function calculateTotalHoursAcumm2(task) {
  if (
    task &&
    Array.isArray(task.task_worktype) &&
    task.task_worktype.length
  ) {
    return task.task_worktype.reduce((total, worktype) => {
      const net = total + Number(worktype.hours);
      return net;
    }, 0);
  }
  return 0;
}

function NewCalcXaxis(deliverable) {
  return deliverable.deliverable_worktype.reduce((total, hour) => {
    return total + Number(hour?.hours ? hour.hours : 0);
  }, 0);
}

const CustomRow = ({
  provided,
  index,
  p,
  formData,
  setDeliverable,
  dileverable,
  counter,
  setCounter,
  setIsDetOpen,
  setFormData,
  setIsTyping,
  showAllTasks,
  showAllDescription
}) => {
  const [open, setOpen] = useState(false);
  const [openTemp, setOpenTemp] = useState(false);
  const access_token = useSelector((state) => state.auth.user.access);

  const dispatch = useDispatch();
  const work = useSelector((state) => state.Misc.workTypes);
  const [inputs, setInputs] = useState([]);
  useEffect(() => {
    dispatch(getWorkTypes({ access_token }));
  }, []);

  useEffect(() => {
    const prev = dileverable;
    const shallowCopy = [...prev.map((item) => Object.assign({}, item))];
    const index = shallowCopy.findIndex(
      (ele) => Number(ele.id) === Number(p.id)
    );
    const currentElement = shallowCopy[index];

    if (
      currentElement &&
      currentElement.task_deliverable &&
      Array.isArray(currentElement.task_deliverable) &&
      currentElement.task_deliverable.length
    ) {
      const trout = reduceTaskWorktypes(currentElement.task_deliverable);
      if (trout) {
        setDeliverable((zas) => {
          return zas.map((ma) => {
            if (Number(ma.id) === Number(p.id)) {
              ma.deliverable_worktype.forEach((worktype) => {
                const matchingTrout = trout.find((ele) => worktype.name === ele.name);
                if (matchingTrout) {
                  worktype.hours = matchingTrout.hours;
                }
              });
            }
            return ma;
          });
        });
      }
    }
  }, [counter]);

  const handleFieldChange = (id, value) => {
    const updatedDeliverable = p.deliverable_worktype
      ? [...p.deliverable_worktype]
      : [];
    const index = updatedDeliverable.findIndex((item) => item.id === id);
    if (index !== -1) {
      updatedDeliverable[index].hours = value;
    } else {
      updatedDeliverable.push({ id, hours: value });
    }

    p.deliverable_worktype = updatedDeliverable;

    const newInputs = [];
    p.deliverable_worktype.forEach((v) => {
      const hours = Number(v.hours);
      newInputs.push(hours);
    });
    setInputs(newInputs);
  };

  const netHours = NewCalcXaxis(p)

  useEffect(() => {
    setDeliverable((prev) => {
      const shallowCopy = [...prev];
      const index = shallowCopy.findIndex((dat) => dat.id === p.id);
      shallowCopy[index].total_loe = Number(netHours);
      return shallowCopy;
    });
  }, [netHours]);

  useEffect(() => {
    setIsDetOpen(!openTemp);
  }, [openTemp]);

  const getProject = async () => {
    const res = await fetch(`${ENDPOINT}/api/project/detail/${formData?.unique_id}/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `JWT ${access_token}`,
      },
    });
    const dataGet = await res.json();
    if (!res.ok) {

      return

    }

    setFormData(dataGet)

    if (
      dataGet &&
      Array.isArray(dataGet.deliverable_project) &&
      dataGet.deliverable_project.length
    ) {
      setDeliverable(dataGet.deliverable_project);
    }
  }


  return (
    <>
      <TableRow
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        sx={{
          width: '100%',
          height: '100px',
          borderBottom: 'none',
          '&:last-child td, &:last-child th': { border: 0 },
        }}
        hover
        key={index}
      >
        <CustomCell
          p={p}
          setDeliverable={setDeliverable}
          setOpen={setOpen}
          dileverable={dileverable}
          formData={formData}
          setOpenTemp={setOpenTemp}
          open={open}
          setIsDetOpen={setIsDetOpen}
          getProject={getProject}
          access_token={access_token}
          setFormData={setFormData}
          setIsTyping={setIsTyping}
        />
        {Array.isArray(formData.collaborators) && formData.collaborators.length
          ? formData.collaborators.map((item) => (
            <TableCell align="left"> </TableCell>
          ))
          : null}
        {p &&
          Array.isArray(p.deliverable_worktype) &&
          p.deliverable_worktype.length
          ? p.deliverable_worktype.map((i, n) => {
            const Objection =
              Array.isArray(p.task_deliverable) && p.task_deliverable.length ? true : false;

            return (
              <TableCell align="center" key={n}>
                <Tooltip
                  title={Objection ? 'You can only enter Task Hours' : ''}
                >
                  {
                    Array.isArray(p.task_deliverable) && p.task_deliverable.length ?
                      <p style={{ color: '#000', fontSize: '13px' }} >{i.hours ? i.hours : ''}</p>
                      :
                      <DashedField2
                        value={i?.hours}
                        onChange={(e) => {
                          if (!Objection) {
                            setIsTyping(true)
                            setDeliverable((prev) => {
                              const shallowCopy = [...prev];
                              const int = shallowCopy.findIndex(
                                (vil) => vil.id === p.id
                              );
                              const thisInt = shallowCopy[
                                int
                              ].deliverable_worktype.findIndex(
                                (num) => parseInt(num.id) === i.id
                              );
                              shallowCopy[int].deliverable_worktype[
                                thisInt
                              ].hours = e.target.value;

                              return shallowCopy;
                            });
                          }
                        }}
                        onBlur={async () => {

                          const shallowCopy = { ...formData }
                          delete shallowCopy.work_type;
                          const res = await fetch(
                            `${ENDPOINT}/api/project/update/${formData?.id}/`,
                            {
                              method: 'PUT',
                              headers: {
                                Authorization: `JWT ${access_token}`,
                                'Content-Type': 'application/json',
                              },
                              body: JSON.stringify({
                                ...shallowCopy,
                                total_loe : Array.isArray(dileverable) && dileverable.length ? dileverable.reduce(
                                      (acc, deliverable) => {
                                        const deliverableTotal = deliverable.deliverable_worktype.reduce(
                                          (subAcc, worktype) => subAcc + Number(worktype.hours),
                                          0
                                        );
                                        return acc + deliverableTotal;
                                      },
                                      0
                                    ) : 0,
                                project_deliverable: Array.isArray(dileverable) && dileverable.length
                                  ? dileverable.map((item, index) => ({
                                    ...item,
                                    deliver_id: item.id,
                                    deliverable_worktype: item.deliverable_worktype.map((wtt, ind) => ({
                                      ...wtt,
                                      id: wtt.id ? wtt.id : '',
                                      hours: wtt.hours ? wtt.hours : null,
                                    })),
                                  }))
                                  : [],
                              }),

                            }
                          );
                          const data = await res.json()
                          if (!res.ok) {

                  
                            return
                          }
                          else {
                            setIsTyping(false)
              
                          }
                        }}
                      />
                  }

                </Tooltip>
              </TableCell>
            );
          })
          : null}
        <TableCell style={{ fontWeight: 'bold', textAlign: 'center' }}>
          {netHours ? `${netHours}hr` : '0h'}
        </TableCell>
      </TableRow>
      {open || showAllTasks && p.task_deliverable && Array.isArray(p.task_deliverable) && p.task_deliverable.length
        ? p.task_deliverable.map((c, i) => (
          <TableRow style={{ height: '90px', background: '#f8f9fa' }}>
            <SubTaskCell access_token={access_token} c={c} setIsTyping={setIsTyping} setDeliverable={setDeliverable} p={p} i={i} formData={formData} dileverable={dileverable} />
            {Array.isArray(formData.collaborators) &&
              formData.collaborators.length
              ? formData.collaborators.map((_) => (
                <TableCell align="left"> </TableCell>
              ))
              : null}

            {c.task_worktype.map((i, n) => (
              <TableCell
                style={{ textAlign: 'center', background: '#f8f9fa' }}
                key={n}
              >
                <DashedField2
                  value={i.hours}
                  onChange={(e) => {
                    setCounter(counter + 1);
                    setIsTyping(true)
                    setDeliverable((prev) => {
                      const shallowCopy = [
                        ...prev.map((item) => Object.assign({}, item)),
                      ];
                      const FIndex = shallowCopy.findIndex(
                        (ent) => Number(ent.id) === Number(p.id)
                      );
                      const SIndex = shallowCopy[FIndex].task_deliverable.findIndex(
                        (dent) => dent.task_name === c.task_name
                      );
                      const FinalIndex = shallowCopy[FIndex].task_deliverable[
                        SIndex
                      ].task_worktype.findIndex(
                        (mle) => Number(mle.id) === Number(i.id)
                      );
                      shallowCopy[FIndex].task_deliverable[SIndex].task_worktype[
                        FinalIndex
                      ].hours = Number(e.target.value);
                      return shallowCopy;
                    });
                  }}
                  onBlur={async () => {
                    const shallowCopy = { ...formData }
                    delete shallowCopy.work_type;
                    const res = await fetch(
                      `${ENDPOINT}/api/project/update/${formData?.id}/`,
                      {
                        method: 'PUT',
                        headers: {
                          Authorization: `JWT ${access_token}`,
                          'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                          ...shallowCopy,
                          total_loe : Array.isArray(dileverable) && dileverable.length ? dileverable.reduce(
                            (acc, deliverable) => {
                              const deliverableTotal = deliverable.deliverable_worktype.reduce(
                                (subAcc, worktype) => subAcc + Number(worktype.hours),
                                0
                              );
                              return acc + deliverableTotal;
                            },
                            0
                          ) : 0,
                          project_deliverable: Array.isArray(dileverable) && dileverable.length
                            ? dileverable.map((item, index) => ({
                              ...item,
                              deliver_id: item.id,
                              deliverable_worktype: item.deliverable_worktype.map((wtt, ind) => ({
                                ...wtt,
                                id: wtt.id ? wtt.id : '',
                                hours: wtt.hours ? wtt.hours : null,
                              })),
                              task_deliverable: item.task_deliverable
                                ? item.task_deliverable.map((tk) => ({
                                  ...tk,
                                  task_id: tk.id,
                                  task_name: tk.task_name,
                                  task_worktypes: tk.task_worktype.map((twt) => ({
                                    ...twt,
                                    task_id: twt.id,
                                    name: twt.task_name,
                                    hours: twt.hours ? Number(twt.hours) : 0,
                                  })),
                                }))
                                : [],

                            }))
                            : [],
                        }),

                      }
                    );
                    const data = await res.json()
                    if (!res.ok) {
                      return
                    }
                    else {
                      setIsTyping(false)

                    }
                  }}
                />
              </TableCell>
            ))}
            <TableCell align="center">
              <div style={{ width: '100%' }}>
                {calculateTotalHoursAcumm2(c)}
              </div>
            </TableCell>
          </TableRow>
        ))
        : null}
      {open && (
        <AddTask
          setDeliverable={setDeliverable}
          setOpen={setOpen}
          p={p}
          formData={formData}
          access_token={access_token}
          setFormData={setFormData}
        />
      )}


      <CreateTemplate openTemp={openTemp} setOpenTemp={setOpenTemp} p={p} />
    </>
  );
};

const EstTable = ({
  formData,
  setFormData,
  steps,
  dileverable,
  setDeliverable,
  setErrorState,
  setIsDetOpen,
  access_token,
  setIsTyping,
  showAllTasks,
  showAllDescription
}) => {
  const [counter, setCounter] = useState(0);
  useEffect(() => {
    setFormData({
      ...formData,
      dileverable,
    });
  }, [dileverable, counter]);

  const handleOnDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const items = Array.from(dileverable);
    const [reorderediItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderediItem);
    setDeliverable(items);
  };
  function calculateTotalWeeks(deliverableStartDate, deliverableEndDate) {
    const startDate = new Date(deliverableStartDate);
    const endDate = new Date(deliverableEndDate);

    // Calculate the time difference in milliseconds
    const timeDifference = endDate - startDate;

    // Convert milliseconds to weeks
    const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

    // Calculate the number of weeks (5-day weeks) based on the days difference
    const weeksDifference = Math.ceil(daysDifference / 7);


    return weeksDifference;
  }
  function deliverableDependencey(id) {
    const depend = id ? dileverable.find((item) => item.id === id) : { deliverable_name: 'None' }
    return depend.deliverable_name
  }

  return !showAllDescription ? (
    dileverable.length ? (
      <>
        {formData.type === 'retainer' && (
          <Alert severity="info">
            PLEASE REMEMBER! THESER ARE TOTAL HOURS PER MONTH
          </Alert>
        )}
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="rows">
            {(provided) => (
              <TableContainer
                {...provided.droppableProps}
                ref={provided.innerRef}
                component={Paper}
                sx={{
                  width: '100%',
                  backgroundColor: '#fff',
                  maxHeight: '700px',
                  overflow: 'auto',
                }}
              >
                <Table aria-label="simple table" stickyHeader>
                  <THead formData={formData} />
                  <TableBody>
                    {dileverable.map((p, index) => (
                      <Draggable
                        key={p.id}
                        draggableId={p.deliverable_name}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <CustomRow
                            provided={provided}
                            snapshot={snapshot}
                            index={index}
                            p={p}
                            formData={formData}
                            setDeliverable={setDeliverable}
                            dileverable={dileverable}
                            counter={counter}
                            setCounter={setCounter}
                            setIsDetOpen={setIsDetOpen}
                            setFormData={setFormData}
                            setIsTyping={setIsTyping}
                            showAllTasks={showAllTasks}
                            showAllDescription={showAllDescription}
                          />
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                    <AddRows
                      formData={formData}
                      dileverable={dileverable}
                      setDeliverable={setDeliverable}
                      setErrorState={setErrorState}
                      access_token={access_token}
                      setFormData={setFormData}
                    />
                    <LastRow
                      formData={formData}
                      dileverable={dileverable}
                      counter={counter}
                      setCounter={setCounter}
                    />
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Droppable>
        </DragDropContext>
      </>
    ) : (
      <Table aria-label="simple table">
        <THead formData={formData} />
        <TableBody>
          <AddRows
            formData={formData}
            dileverable={dileverable}
            setDeliverable={setDeliverable}
            setErrorState={setErrorState}
            access_token={access_token}
            setFormData={setFormData}
          />
          <LastRow formData={formData} />
        </TableBody>
      </Table>
    )
  )
    :
    (
      <Grid>
        <Box sx={{ display: 'flex', gap: '.5rem', alignItems: 'center', height: '50px', borderBottom: '0.5px solid #d7d7d7', width: '100%', padding: '0px 10px' }}>
          <Typography sx={{ fontSize: '15px' }}>  Deliverables </Typography>
          <QueryBuilderIcon sx={{ color: '#4dfad2', fontSize: '15px' }} />
        </Box>
        <Box sx={{ padding: '10px 10px' }}>
          {
            Array.isArray(dileverable) && dileverable.length &&
            dileverable.map((item, index) => {
              return (
                <Box key={index} sx={{ display: 'flex', flexDirection: 'column', gap: '1rem', marginTop: '10px', borderBottom: '0.5px solid #d7d7d7' }}>
                  <Typography sx={{ fontSize: { xl: '16px', lg: '15px', md: '15px' }, fontWeight: '600' }}> {item.deliverable_name}</Typography>

                  <Box sx={{ display: 'flex', gap: '1.2rem' }}>
                    <Typography sx={{ fontSize: { xl: '16px', lg: '15px', md: '15px' } }}> Duration  <QueryBuilderIcon sx={{ color: '#4dfad2', fontSize: '15px' }} /></Typography>
                    <input defaultValue={calculateTotalWeeks(item.deliverable_startdate, item.deliverable_enddate)} type='number' style={{ border: 'none', outline: 'none' }} />
                  </Box>

                  <Box sx={{ display: 'flex', gap: '1.2rem' }}>
                    <Typography sx={{ fontSize: { xl: '16px', lg: '15px', md: '15px' } }}> Dependency  <QueryBuilderIcon sx={{ color: '#4dfad2', fontSize: '15px' }} /></Typography>
                    <Typography sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '.3rem', padding: '2px 7px', background: '#aef5e5', borderRadius: '5px', fontSize: '15px', }}> {deliverableDependencey(item.deliverable_dependency)}  <CloseIcon sx={{ fontSize: '14px', cursor: 'pointer' }} /></Typography>
                    <button className='hover_button' style={{ background: 'none', padding: '2px 15px', borderRadius: '2px', border: '1px solid #711fff', color: '#711fff' }}> + Add Dependency</button>
                  </Box>

                  <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Box sx={{ width: '48%', paddingBottom:'1rem'}}>
                      <Typography sx={{ fontWeight: 'bold', fontSize: '17px', color: '#000' }}>Description</Typography>
                      {
                        item.description ?
                          <p style={{padding:'1rem 0.2rem'}} dangerouslySetInnerHTML={{ __html: item.description }}>

                          </p>
                          :

                          <Typography style={{fontSize:'13px', color:'GrayText'}}>Your Description for the Deliverable Would Appear Here!</Typography>


                        
                      }
                    </Box>

                    <Box sx={{ width: '48%',paddingBottom:'1rem' }}>
                      <Typography sx={{ fontWeight: 'bold', fontSize: '17px', color: '#000' }}> Internal Notes</Typography>
                      {
                        item.internal_note ?
                          <p style={{padding:'1rem 0.2rem'}}  dangerouslySetInnerHTML={{ __html: item.internal_note }}></p>
                          :
                          <Typography style={{fontSize:'13px', color:'GrayText'}}>Your Internal Notes for the Deliverable Would Appear Here!</Typography>
                      }
                    </Box>
                  </Box>

                </Box>
              )
            })
          }
        </Box>
      </Grid>
    )
};

const PThird = ({
  formData,
  setFormData,
  steps,
  dileverable,
  setDeliverable,
  setErrorState,
  setIsDetOpen,
  access_token,
  showAllTasks,
  showAllDescription,
  screenHeight,
}) => {


  const [isTyping, setIsTyping] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      const res = await fetch(`${ENDPOINT}/api/project/detail/${formData?.unique_id}/`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `JWT ${access_token}`,
        },
      });
      const data = await res.json();
      if (!res.ok) {    
      }
      setFormData(data)
      if (
        data &&
        Array.isArray(data.deliverable_project) &&
        data.deliverable_project.length
      ) {
        setDeliverable(data.deliverable_project);
      }
    };

    // Clean up the interval when the component unmounts or when some condition is met
    const intervalId = setInterval(() => {
      if (isTyping === false && showAllDescription === false) {
        fetchData();

      }
    }, 8000);

    // Clean up the interval when the component unmounts or when some condition is met
    return () => {
      clearInterval(intervalId);
    };
  }, [isTyping, showAllDescription]);


  return (
    <Grid item container direction="column" >
      <EstTable
        formData={formData}
        setFormData={setFormData}
        steps={steps}
        dileverable={dileverable}
        setDeliverable={setDeliverable}
        setErrorState={setErrorState}
        setIsDetOpen={setIsDetOpen}
        access_token={access_token}
        setIsTyping={setIsTyping}
        showAllTasks={showAllTasks}
        showAllDescription={showAllDescription}
      />
    </Grid>
  );
}

export default PThird;
