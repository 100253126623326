import { React, useEffect, useState, useRef} from 'react';
import {
  Grid,
} from '@mui/material';
import { PreLoading } from '../../Contacts/People/EditContact';
import 'gantt-task-react/dist/index.css';
import moment from 'moment';
import { GanttComponent, Inject, Edit, Selection, EventMarkerDirective, EventMarkersDirective, EventMarker, DayMarkers, ColumnDirective, ColumnsDirective } from '@syncfusion/ej2-react-gantt';
import { ENDPOINT } from '../../Opportunity/Redux/oppor';
import { useSelector } from 'react-redux';
import uuid from 'react-uuid';



const Timeline = ({
  dileverable,
  setDeliverable,
  formData,
  setFormData,
  view,
  setView,
  temp,
  setTemp,
}) => {

  const [tasks, setTasks] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const gridRef = useRef(null);
  const access_token = useSelector((state)=> state.auth.user.access)
  useEffect(() => {
    const data = dileverable.map((item, index) => {
      const start = new Date(item.deliverable_startdate)
      const end = new Date(item.deliverable_enddate)
      const dependency = dileverable.find((ob) => ob.id === item.deliverable_dependency)

      const predecessor = dependency ? dependency.id : '';

      return {
        TaskID: item?.id ? item?.id : uuid(),
        TaskName: item.deliverable_name,
        StartDate: start,
        EndDate: end,
        Predecessor: predecessor,
        Hours: item.deliverable_worktype.reduce((total, acc) => {
          return total + Number(acc.hours ? acc.hours : 0)
        }, 0)
      };
    });

    setTasks(data);
  }, [dileverable]);

  const editOptions = {


    allowEditing: true,
    allowTaskbarEditing: true,

  }
  const toolbarOptions = ['ZoomToFit'];
  const taskFields = {
    id: 'TaskID',
    name: 'TaskName',
    startDate: 'StartDate',
    endDate: 'EndDate',
    duration: 'Duration',
    dependency: 'Predecessor',
    hours: 'Hours'
  };
 

  const timelineSettings = {
    timelineUnitSize: 80,
    timelineViewMode: view,
    workWeek: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Satudrday'],
    durationUnit: 'Day'
  };

  const finishdate = formData?.finish_date?.split('T')[0]
  const estimate_finshDate = new Date(finishdate)


  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
    setTemp(tasks);
  }, [tasks]);

  const handleTaskbar = async (args) => {
    const shallow_copy = dileverable.map((item) => Object.assign({}, item))
    shallow_copy[args.data.index].deliverable_startdate = args.data.StartDate.toISOString()
    shallow_copy[args.data.index].deliverable_enddate = args.data.EndDate.toISOString()
    const shallowCopy = {
      ...formData,
    };

    delete shallowCopy.work_type;
    delete shallowCopy.estimate_hardcost;
    const durationEst = new Date(formData.finsish_date) - new Date(formData.target_start)

    const totalDays = durationEst / (24 * 60 * 60 * 1000)
    const startDateOfDil = new Date('11/11/2080').toISOString();

    const leastDate = shallow_copy.reduce((minDate, dil) => {
      if (dil.deliverable_startdate < minDate) {
        return dil.deliverable_startdate;
      } else {
        return minDate;
      }
    }, startDateOfDil);


    const res = await fetch(
      `${ENDPOINT}/api/project/update/${formData.id}/`,
      {
        method: 'PUT',
        headers: {
          Authorization: `JWT ${access_token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
              ...shallowCopy,
              target_start: leastDate,
              finsish_date: formData.finsish_date ? new Date(new Date(leastDate).setDate(new Date(leastDate).getDate() + totalDays)) : null,
              type: 'project',
              project_deliverable: shallow_copy?.map(
                (item, index) => {
                  const startDate = moment(tasks[index].StartDate);
                  const formattedStartDate = startDate.utc().format("YYYY-MM-DDTHH:mm:ss");
                  const endDate = moment(tasks[index].EndDate);
                  const formattedEndDate = endDate.utc().format("YYYY-MM-DDTHH:mm:ss");

                  const newStartData = new Date(tasks[index].StartDate)
                  newStartData.setDate(newStartData.getDate() + 1);
                  const newEndDate = new Date(tasks[index].EndDate)
                  newEndDate.setDate(newEndDate.getDate() + 1);
                  const durationInMilliseconds = new Date(item.deliverable_enddate) - new Date(item.deliverable_startdate)
                  const deliverable_duration = durationInMilliseconds / (24 * 60 * 60 * 1000)
                  delete item.deliverable_multiplier
                  delete item.task_deliverable
                  return {
                    ...item,
                    deliver_id: item.id,
                    deliverable_enddate: item.deliverable_enddate,
                    deliverable_startdate: item.deliverable_startdate,
                    deliverable_worktype: item.deliverable_worktype.map(
                      (wtt) => ({
                        ...wtt,
                        id: wtt.id,
                        hours: wtt.hours ? Number(wtt.hours) : null,
                      })
                    ),

                  };
                }
              ),

            }),
      }
    );
    const data = await res.json()
    if (!res.ok) {
      return
    }
    else {
      const resGet = await fetch(`${ENDPOINT}/api/project/detail/${formData?.unique_id}/`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `JWT ${access_token}`,
        },
      });
      const dataGet = await resGet.json();
      if (!resGet.ok) {
        return;
      }
      setFormData(dataGet)

      if (
        dataGet &&
        Array.isArray(dataGet.deliverable_project) &&
        dataGet.deliverable_project.length
      ) {
        setDeliverable(dataGet.deliverable_project);
        const data2 = dataGet.deliverable_project.map((item, index) => {
          const start = new Date(item.deliverable_startdate)
          const end = new Date(item.deliverable_enddate)
          const dependency = dataGet.deliverable_project.find((ob) => ob.id === item.deliverable_dependency)


          // Determine the Predecessor field based on the dependency
          const predecessor = dependency ? dependency.id : '';

          return {
            TaskID:  item?.id ? item?.id : uuid(),
            TaskName: item.deliverable_name,
            StartDate: start,
            EndDate: end,
            Predecessor: predecessor,
            Hours: item.deliverable_worktype.reduce((total, acc) => {
              return total + Number(acc.hours ? acc.hours : 0)
            }, 0)
          };
        });
        setTasks(data2);
      }
    }
  }

  return Array.isArray(tasks) && tasks.length ? (
    <>
      <Grid
        className="gantt-target"
        ref={gridRef}
        item
        container
        direction="column"
        xl={12}
      >
        <GanttComponent
          taskbarEdited={handleTaskbar}
          rowUniqueId="TaskID"
          UpdateOffsetOnTaskbarEdit={false}
          toolbar={toolbarOptions}
          workWeek={['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']} rowHeight={60} timelineSettings={timelineSettings} allowParentDependency={true} editSettings={editOptions} dataSource={tasks} taskFields={taskFields}
          height='640px'>
          {formData?.finish_date ? <EventMarkersDirective>
            <EventMarkerDirective day={estimate_finshDate} label='Target End Date'></EventMarkerDirective>
          </EventMarkersDirective> : null}
          <ColumnsDirective>
            <ColumnDirective field='TaskID' headerText='' visible={false}></ColumnDirective>
            <ColumnDirective field='TaskName' headerText='Deliverables' width='auto'></ColumnDirective>
          </ColumnsDirective>
          <Inject services={[Edit, Selection, DayMarkers]} />
        </GanttComponent>
      </Grid>

    </>
  ) : (
    <PreLoading />
  );
};
export default Timeline;
