import { createAsyncThunk } from "@reduxjs/toolkit";
import { initialVideos } from './initialVideos'
import { ENDPOINT } from "../../Opportunity/Redux/oppor";

const GET_VIDEOS = "netnet/Redux/GET_VIDEOS";

// get videos from end point
export const getVideos = createAsyncThunk(
  GET_VIDEOS, 
  async (meta, thunkAPI) => {
  try {
    const response = await fetch(`${ENDPOINT}/api/netnetu/chapterlist/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${meta.access_token}`,
      },
    });

    const data = await response.json();
    console.log("data", data)

    if (!response.ok) {
      return thunkAPI.rejectWithValue(data);
    }

    return data;
  } catch (error) {
    console.error("Error fetching videos:", error);
    return thunkAPI.rejectWithValue({ error: "Failed to fetch videos" });
  }
});


const initialState = {
  helpVideo: initialVideos,
  loading: false,
  error: null,
};

const videoReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${GET_VIDEOS}/pending`:
      return {
        ...state,
        loading: true,
      };
    case `${GET_VIDEOS}/fulfilled`:
      return {
        ...state,
        loading: false,
        helpVideo: action.payload,
      };
    case `${GET_VIDEOS}/rejected`:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default videoReducer;
