import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Grid, Typography, Button } from '@mui/material';
import PauseIcon from '@mui/icons-material/Pause';
import StopIcon from '@mui/icons-material/Stop';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Alert from '@mui/material/Alert';
import MUIRichTextEditor from 'mui-rte';
import { EditorState, } from 'draft-js';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { stateToHTML } from 'draft-js-export-html';
import { stopTimer, getQuickTask } from '../../pages/Projects/proRedux';
import { darkTypography } from '../../pages/Projects/QuickTasks';
import { ENDPOINT, getTimeTracking } from '../../pages/Opportunity/Redux/oppor';

import moment from 'moment';
import { FaRegCalendarAlt } from "react-icons/fa";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs from 'dayjs';

const Culminate = ({ data, currentTarget, setShow, toggleDrawer, setState, state, setVal, setIsActive }) => {
  if (!currentTarget) {
    setShow(false);
    return;
  }
  const access_token = useSelector((state) => state.auth.user.access);
  const dispatch = useDispatch();
  const [succ, setSucc] = useState(false);
  const [warn, setWarn] = useState(false);
  const [html, setHtml] = useState('');
  const [editor, setEditor] = useState(() => {
    EditorState.createEmpty().getCurrentContent();
  });
  const [fieldEmpty, setFieldEmpty] = useState(editor);
  const fieldText = (e) => {
    setEditor(e);
    setHtml(stateToHTML(e.getCurrentContent()));
  };
  const myTheme = createTheme({});

  // this is time delete or edit manage

  const [timeFunc, setTimeFunc] = useState('')

  const [timeModifyTrackingStart, setModifyTimeTrackingStart] = useState(dayjs(data.time_tracking_start))
  const [timeModifyTrackingEnd, setModifyTimeTrackingEnd] = useState(dayjs(data.time_tracking_stop))

  return (
    <Grid
      item
      container
      alignItems="flex-start"
      direction="column"
      sx={{ gap: '1.5rem' }}
    >
      <Typography variant="h4" sx={{ ...darkTypography, m: '0 auto 0 0' }}>
        {currentTarget.task_name ? currentTarget.task_name : ''}
      </Typography>
      <Grid
        item
        xl={12}
        lg={12}
        md={12}
        container
        alignItems="flex-start"
        justifyContent="center"
        sx={{ gap: '1.4rem' }}
      >
        <Grid item container xl={8}>
          <Grid
            item
            xl={5.5}
            container
            direction="column"
            alignItems="flex-start"
            sx={{
              backgroundColor: '#E9ECEF',
              gap: '1rem',
              padding: '23px 13px',
              width: '52.6666%',
              borderRadius: '8px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                gap: '1rem',
                width: '100%',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <h3
                style={{
                  fontFamily: 'Open Sans',
                  fontWeight: '600',
                  color: '#03071ECC',
                  fontSize: '1.1rem',
                }}
              >
                PROJECT
              </h3>
              <span className="modal-span">No Project</span>
            </Box>
            <Box
              sx={{
                display: 'flex',
                gap: '1rem',
                width: '100%',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <h3
                style={{
                  fontFamily: 'Open Sans',
                  fontWeight: '600',
                  color: '#03071ECC',
                  fontSize: '1.1rem',
                }}
              >
                DELIEVERABLE
              </h3>
              <span className="modal-span">NO DELIVERABLE ASSIGNED</span>
            </Box>
          </Grid>
          <Grid item lg={5.5}>
            <Box
              sx={{
                backgroundColor: '#FBFBFB',
                padding: '0.88rem 1rem',
                borderRadius: '8px',
              }}
            >
              <span
                style={{
                  fontWeight: '600',
                  fontFamily: 'Open Sans',
                }}
              >
                Description
              </span>
              <Box className="df-r-j-c-gp-5 Editor-para5">
                {' '}
                <ThemeProvider theme={myTheme}>
                  <MUIRichTextEditor
                    label="Enter Description"
                    inlineToolbar={false}
                    onChange={fieldText}
                    value={fieldEmpty}
                    controls={['bold', 'italic', 'underline', 'strikethrough']}
                    customControls={[
                      {
                        name: 'stateLine',
                        icon: '|',
                        type: 'inline',
                      },
                      {
                        name: 'stateLine2',
                        icon: '|',
                        type: 'inline',
                      },
                    ]}
                  />
                </ThemeProvider>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Grid
          item
          container
          xl={3.8}
          direction="column"
          alignItems="flex-start"
        >
          <p
            style={{
              color: '#FF6827',
              fontFamily: 'Open Sans',
              fontSize: '1.1rem',
            }}
          >
            Is this Correct?
          </p>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              backgroundColor: '#F1F3F5',
              borderRadius: '6px',
            }}
          >
            <Box
              sx={{
                fontFamily: 'Open Sans',
                fontSize: '1.1rem',
                fontWeight: '600',
                textAlign: 'center',
                padding: '14px 10px',
                backgroundColor: '#B3FFEF',
                borderRadius: '8px',
              }}
            >
              {data.hours ? data.hours : 0}h{data.minutes ? data.minutes : 0}m{' '}
              {data.seconds ? data.seconds : 0}s
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Box sx={{ fontSize: '0.9rem' }}>
                {data.time_tracking_start &&
                  data.time_tracking_stop &&
                  `Start : ${new Date(timeModifyTrackingStart)}`}

              </Box>
              <Box sx={{ fontSize: '0.9rem' }}>
                {data.time_tracking_start &&
                  data.time_tracking_stop &&
                  `End :   ${new Date(timeModifyTrackingEnd)}`}
              </Box>
            </Box>
          </Box>

          <Box sx={{ display: 'flex', mt: 3 }}>
            {
              timeFunc === '' ?
                <Box sx={{ display: 'flex' }}>
                  <Typography onClick={() => {
                    setTimeFunc('Edit')
                  }}
                    sx={{ backgroundColor: "#F2EBFF", color: '#711FFF', borderRadius: 1, px: 2, cursor: 'pointer' }}>Modify Time</Typography>

                  <Typography onClick={() => { setTimeFunc('Delete') }}
                    sx={{ color: 'red', px: 2, cursor: 'pointer' }} >Delete</Typography>
                </Box>
                :
                timeFunc === 'Edit' ?
                  <Box align='centers'>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Box sx={{ display: 'flex', justifyContent: 'center', backgroundColor: '#EADEFF', width: '270px', alignItems: 'center', borderRadius: 2, py: 1 }}>
                        <FaRegCalendarAlt style={{ marginRight: '10px', fontSize: '20px', color: 'gray' }} />
                        <Typography>Task Date</Typography>
                      </Box>
                      <Typography onClick={() => { setTimeFunc('') }} sx={{ px: 3, cursor: 'pointer' }}>cancel</Typography>
                    </Box>

                    <Box sx={{ border: '1px solid lightgray', borderRadius: 2, display: 'inline-flex', p: 1, mt: 2 }}>
                      <Box align='center' sx={{ mr: 2 }}>
                        <Typography>Start Time</Typography>
                        <Box
                          sx={{
                            backgroundColor: '#f8f9fa',
                            borderRadius: '8px',
                            width: '120px',
                            marginTop: '10px',
                          }}
                        >
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <TimePicker
                              value={timeModifyTrackingStart}
                              onChange={(newValue) => setModifyTimeTrackingStart(newValue)}
                              maxTime={timeModifyTrackingEnd}

                              sx={{
                                
                                "& .MuiSvgIcon-root": {
                                  
                                  mr: 1,
                                },
                                "& .MuiInputBase-root": {
                                  width: '100%',
                              
                                },
                                "& .MuiOutlinedInput-input": {
                                  padding: '10px 5px',
                                 
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                  outline: 'none !important',
                                  border: 'none !important',
                              
                                },
                              }}
                            />
                          </LocalizationProvider>
                        </Box>

                      </Box>
                      <Box align='center'>
                        <Typography>End Time</Typography>
                        <Box
                          sx={{
                            backgroundColor: '#f8f9fa',
                            borderRadius: '8px',
                            width: '120px',
                            marginTop: '10px',
                          }}
                        >
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <TimePicker
                              value={timeModifyTrackingEnd}
                              onChange={(newValue) => setModifyTimeTrackingEnd(newValue)}
                              minTime={timeModifyTrackingStart}
                              sx={{
                                "& .MuiSvgIcon-root": {
                                  mr: 1,
                                },
                                "& .MuiInputBase-root": {
                                  width: '100%'
                                },
                                "& .MuiOutlinedInput-input": {
                                  padding: '10px 5px'
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                  outline: 'none !important',
                                  border: 'none !important'
                                },
                              }}
                            />
                          </LocalizationProvider>
                        </Box>

                      </Box>


                    </Box>

                  </Box>
                  :

                  <Box align='centers'>
                    <Button
                      variant='contained'
                      color='error'
                      onClick={async () => {
                        const res = await fetch(
                          `${ENDPOINT}/api/project/task/timetracking/delete/${data.id}/`,
                          {
                            method: 'DELETE',
                            headers: {
                              'Content-Type': 'application/json',
                              Authorization: `JWT ${access_token}`,
                            },
                          }
                        );
                        if (res.ok) {
                          setSucc(true);
                          dispatch(
                            getQuickTask({
                              access_token,
                            })
                          );
                          setState({ ...state, bottom: false })
                          dispatch(stopTimer(currentTarget));
                          setTimeout(() => {
                            setSucc(false);
                          }, 3000);
                        }
                        // }
                        setSucc(true);
                        dispatch(
                          getQuickTask({
                            access_token,
                          })
                        );
                        setTimeout(() => {
                          setSucc(false);
                        }, 3000);

                      }}
                    >Confirm</Button>
                    <Button onClick={() => setTimeFunc('')} sx={{ ml: 2 }}>cancel</Button>
                  </Box>
            }
          </Box>
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12}>
          <Box
            sx={{
              width: '13%',
              display: 'flex',
              alignItems: 'center',
              marginLeft: 'auto',
            }}
          >
            <button className="cancel-btn" onClick={() => {
              setVal('auto')
              setShow(false)
              setIsActive(true)
            }}>
              Resume Time
            </button>
            <button
              className="create-btn"
              onClick={async () => {
                  const difference = dayjs(timeModifyTrackingEnd).diff(dayjs(timeModifyTrackingStart), 'minute');
                  const hours = Math.floor(difference / 60);
                  const minutes = difference % 60;

             
                  if (difference < 0){

                    setWarn(true)
                    setTimeout(() => {
                      setWarn(false)
                    }, 3000);
                  }
                  else{
                const res = await fetch(
                  `${ENDPOINT}/api/project/task/timetracking/update/${data.id}/`,
                  {
                    method: 'PUT',
                    headers: {
                      'Content-Type': 'application/json',
                      Authorization: `JWT ${access_token}`,
                    },
                    body: JSON.stringify({
                      note: html,
                      time_tracking_start: timeModifyTrackingStart,
                      time_tracking_stop: timeModifyTrackingEnd,
                    }),
                  }
                );
                if (res.ok) {
                  setSucc(true);
                  dispatch(
                    getQuickTask({
                      access_token,
                    })
                  );
                  dispatch(getTimeTracking({access_token}))
                  setState({ ...state, bottom: false })
                  dispatch(stopTimer(currentTarget));
                  setTimeout(() => {
                    setSucc(false);
                  }, 3000);
                } else{

                  setSucc(true);
                  setTimeout(() => {
                    setSucc(false);
                  }, 3000);
                }
                 }

              }}
            >
              Save
            </button>
          </Box>
        </Grid>
        {warn && <Alert severity="error">End Time Can not be before start time.</Alert>}
        {succ && <Alert severity="success">Your time has been recorded</Alert>}
      </Grid>
    </Grid>
  );
};

export const Watch = ({ currentTarget, setCurrentTarget, toggleDrawer, state, setState }) => {
  if (!currentTarget) {
    setCurrentTarget(false);
    return;
  }
  const { time_tracking } = currentTarget;
  const [val, setVal] = useState(1);
  const [seconds, setSeconds] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [hours, setHours] = useState(0);
  const [isActive, setIsActive] = useState(false);
  const [tt, setTT] = useState(0);
  const [data, setData] = useState({});

  const [update, setUpdate] = useState({});
  const handleClose = () => setShow(false);
  const [show, setShow] = useState(false);
  useEffect(() => {
    if (Array.isArray(time_tracking) && time_tracking.length) {
      const {
        tracked_time_hours,
        time_tracking_start,
        id,
        time_tracking_stop,
      } = time_tracking[time_tracking.length - 1];
      setTT(id);
      const tracked_time = tracked_time_hours;
      const startedAt = time_tracking_start;
      /* First Condition */
      if (time_tracking_start && time_tracking_stop) {
        const newTracking = createNewTracking(params);
        setSeconds(0);
        setMinutes(0);
        setHours(0);
        setUpdate({
          ...update,
          time_tracking_start: newTracking.time_tracking_start,
        });
      } else if (startedAt && !time_tracking_stop) {
        setUpdate({
          ...update,
          time_tracking_start: startedAt,
        });
        const startAt = moment(startedAt);
        const currentTime = moment();
        const duration = moment.duration(currentTime.diff(startAt));
        const totalSeconds = duration.asSeconds();

        setHours(Math.floor(totalSeconds / 3600));
        setMinutes(Math.floor((totalSeconds % 3600) / 60));
        setSeconds(Math.floor(totalSeconds % 60));

        setIsActive(true);
      } else if (tracked_time !== '00:00:00' && startedAt !== null) {
        const startAt = new Date(startedAt);
        const currentTime = new Date();
        const totalSeconds = Math.floor((currentTime - startAt) / 1000);

        setSeconds(totalSeconds % 60);
        setMinutes(Math.floor(totalSeconds / 60));
        setHours(Math.floor(totalSeconds / 3600));
        setIsActive(true);
      } else {
        setSeconds(0);
        setMinutes(0);
        setHours(0);
      }
    } else {
      const myFetch = async () => {
        const res = await fetch(`${ENDPOINT}/api/project/task/timetracking/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `JWT ${access_token}`,
          },
          body: JSON.stringify({
            task_id: params.id,
            time_tracking_start: new Date().toISOString(),
          }),
        });
        const data = await res.json();
        if (res.ok) {
          setSeconds(0);
          setMinutes(0);
          setHours(0);
          return 1;
        }
      };
      myFetch();
    }
  }, [time_tracking]);
  useEffect(() => {
    let interval = null;
    if (isActive) {
      interval = setInterval(() => {
        if (seconds > 58) {
          setSeconds(0);
          setMinutes(minutes + 1);
        } else {
          setSeconds(seconds + 1);
        }
        if (minutes > 58) {
          setMinutes(0);
          setHours(hours + 1);
        }
      }, 1000);
    } else if (!isActive && seconds !== 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isActive, seconds, minutes, hours]);
  return (
    <>
      <Box
        sx={{
          backgroundColor: '#8945ff',
          padding: '0.44rem 0.88rem',
          display: 'flex',
          alignItems: 'center',
          gap: '0.5rem',
        }}
      >
        <div className="static-display">
          {hours === 0 ? (
            <span>00</span>
          ) : hours >= 10 ? (
            <span>{hours}</span>
          ) : (
            <span>0{hours}</span>
          )}
          :
          {minutes === 0 ? (
            <span>00</span>
          ) : minutes >= 10 ? (
            <span>{minutes}</span>
          ) : (
            <span>0{minutes}</span>
          )}
          :
          {seconds === 0 ? (
            <span>00</span>
          ) : seconds >= 10 ? (
            <span>{seconds}</span>
          ) : (
            <span>0{seconds}</span>
          )}
        </div>
        {isActive ? (
          <PauseIcon onClick={() => setIsActive(false)} />
        ) : (
          <PlayArrowIcon onClick={() => setIsActive(true)} />
        )}
        |
        <StopIcon
          onClick={() => {
            setData({
              id: tt,
              time_tracking_start: update.time_tracking_start,
              time_tracking_stop: new Date().toISOString(),
              hours,
              minutes,
              seconds,
            });
            setIsActive(false);

            setShow(true);
          }}
        />
      </Box>
      <Offcanvas show={show} onHide={handleClose} placement="bottom">
        <Offcanvas.Header>
          <button className="close-modal" onClick={() => setShow(false)} />
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Culminate
            data={data}
            currentTarget={currentTarget}
            setShow={setShow}
            toggleDrawer={toggleDrawer}
            state={state}
            setState={setState}
            setVal={setVal}
            setIsActive={setIsActive}
          />
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};
