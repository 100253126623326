import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useEffect } from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { formatMoneyString } from '../estSteps/SixthStep';
import { DashedField3 } from '../../../Componenets/Misc';
import { Box } from '@mui/system';
import { TextField, Tooltip, Typography } from '@mui/material';
import AddBoxIcon from '@mui/icons-material/AddBox';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { divide, multiply, subtract } from 'mathjs';
import uuid from 'react-uuid';
import { ENDPOINT } from '../Redux/oppor';
import moment from 'moment';
import DeleteIcon from '../../Contacts/assets/DeleteIcon.png'

const calculateLOERate = (deliverable, work) => {
  if (
    !deliverable ||
    !Array.isArray(deliverable.deliverable_worktype) ||
    !deliverable.deliverable_worktype.length ||
    !Array.isArray(work) ||
    !work.length
  ) {
    return 0;
  }

  let total = 0;
  deliverable.deliverable_worktype.forEach((worktype) => {
    const matchingWork = work.find((item) => item.id === worktype.id);
    if (matchingWork) {
      total += Number(matchingWork.user_rate) * Number(worktype.hours);
    }
  });
  return total;
};


function calculateReduction2(deliverableMultipliers, initialTotal) {
  let result = initialTotal;

  deliverableMultipliers.forEach((item) => {

    const { operation, rate, visible } = item;
    const value = Number(initialTotal) * (rate / 100)

    if (operation === '-') {
      result -= value;
    } else if (operation === '+') {
      result += value;
    }

  });

  return result;
}

function calculateReduction3(deliverableMultipliers, initialTotal) {
  let result = initialTotal;

  deliverableMultipliers.forEach((item) => {

    const { operation, rate, visible } = item;
    const value = Number(initialTotal) * (rate / 100)
    if (visible === false) {
      if (operation === '-') {
        result -= value;
      } else if (operation === '+') {
        result += value;
      }
    }

  });

  return result;
}


function calculateReduction(deliverableMultipliers, initialTotal) {
  let result = initialTotal;

  deliverableMultipliers.forEach((item) => {

    const { operation, rate, visible } = item;
    const value = Number(initialTotal) * (rate / 100)
    if (operation === '-') {
      result -= value;
    } else if (operation === '+') {
      result += value;
    }

  });

  return result;
}

function calculateTotalNet(array) {

  return Array.isArray(array) && array.length
    ? array.reduce((total, item) => {
      return total + Number(item.user_price);
    }, 0)
    : // .toFixed(2)
    0;
}

function calculatePercentage(dileverable) {
  let total = 0;

  dileverable.forEach((deliverable) => {
    if (deliverable.user_price !== null && deliverable.user_price > 0) {
      let subtotal = deliverable.deliverable_multiplier?.reduce((subAcc, subDeliverable) => {
        if (subDeliverable.operation === '+') {
          return subAcc + subDeliverable.rate;
        } else if (subDeliverable.operation === '-') {
          return subAcc - subDeliverable.rate;
        }
        return subAcc;
      }, 0);

      // Calculate the percentage of user_price
      const userPricePercentage = (deliverable.user_price / 100);

      // Apply the percentage to subtotal
      subtotal *= userPricePercentage;

      // Add or remove the subtotal from the total
      total += subtotal;
    }
  });

  return total;
}



function sumPriceToCustomer(arr) {
  return arr?.reduce((total, item) => {
    const priceToCustomer = item?.price_to_customer
      ? parseInt(item?.price_to_customer)
      : parseInt(item?.user_price);
    if (!isNaN(priceToCustomer)) {
      return total + priceToCustomer;
    }
    return total;
  }, 0);
}

export {
  calculateLOERate,
  calculateReduction,
  calculateTotalNet,
  sumPriceToCustomer,
  calculateReduction2,
  calculatePercentage,
  calculateReduction3
};

const DilRow = ({
  rowData,
  index,
  classes,
  addDisc,
  setAddDisc,
  dileverable,
  setDeliverable,
  access_token,
  currentTarget,
  formData,
  setIsTyping,
  leng,
  setcuttedValue,
  cuttedValue,
  globleTotalCost
}) => {
  const [editIndex, setEditIndex] = useState(-1);
  const [hoveredRowIndex, setHoveredRowIndex] = useState(null);
  const useWork = useSelector((state) => state.Misc.workTypes);
  const work = useWork?.find((ele) =>
    rowData?.deliverable_worktype?.some(
      (item) => Number(item.id) === Number(ele.id)
    )
  );
  const [val, setVal] = useState(rowData.user_price ? rowData.user_price : '');
  const allWorktypes = useSelector((item) => item.Misc.workTypes)

  const total33 = rowData?.deliverable_worktype?.reduce((accumulator, worktype) => {
    const user_rate = allWorktypes.find((item) => item.name === worktype.name)?.user_rate

    const hours = Number(worktype.hours);
    const userRate = parseFloat(user_rate ? user_rate : 0);
    const subtotal = hours * userRate;
    return accumulator + subtotal;
  }, 0);


  const [showInput, setShowInpout] = useState(false);

  const handleRowHover = (rowIndex) => {
    setHoveredRowIndex(rowIndex);
  };

  const handleRowUnhover = () => {
    if (showInput) {
      setHoveredRowIndex(hoveredRowIndex);
    } else {
      setHoveredRowIndex(null);
    }
  };



  const UpdateRequest = async () => {
    const shallowCopy = {
      ...formData,
    }
    delete shallowCopy.work_type;
    delete shallowCopy.estimate_hardcost
    const res = await fetch(
      `${ENDPOINT}/api/opportunity/update/estimate/${formData.id}/`,
      {
        method: 'PUT',
        headers: {
          Authorization: `JWT ${access_token}`,
          'Content-Type': 'application/json',
        },
        body:
          Array.isArray(formData.collaborator) && formData.collaborator.length
            ? JSON.stringify({
              ...shallowCopy,
              actual: globleTotalCost,
              estimate_deliverable: dileverable?.map(
                (item) => {
                  const start = moment();
                  const end = start.add(7, 'days').toISOString();
                  delete item.task_deliverable
                  if (item.deliverable_multiplier) {
                    return {
                      ...item,
                      deliver_id: item.id,
                      user_price: item.user_price,

                      deliverable_multiplier: item.deliverable_multiplier.map((multi, mindex) => ({
                        multiplier_id: multi.id,
                        label: multi.label,
                        rate: multi.rate,
                        operation: multi.operation
                      })),
                    };
                  }
                  else {
                    return {
                      ...item,
                      user_price: item.user_price,
                      deliver_id: item.id,
                    }
                  }
                }
              ),

              collaborator: formData?.collaborator?.map((item) => item?.id),
            })
            : JSON.stringify({
              ...shallowCopy,
              actual: globleTotalCost,

              estimate_deliverable: dileverable?.map(
                (item) => {
                  const start = moment();
                  const end = start.add(7, 'days').toISOString();
                  delete item.task_deliverable
                  if (Array.isArray(item.deliverable_multiplier) && item.deliverable_multiplier.length) {
                    return {
                      ...item,
                      deliver_id: item.id,
                      user_price: item.user_price,

                      deliverable_multiplier: item.deliverable_multiplier.map((multi, mindex) => ({
                        multiplier_id: multi.id,
                        label: multi.label,
                        rate: multi.rate,
                        operation: multi.operation
                      })),
          
                    };
                  }
                  else {
                    return {
                      ...item,
                      deliver_id: item.id,
                      user_price: item.user_price,

                    }
                  }
                }
              ),

            }),
      }
    );

    const data = await res.json();
    if (!res.ok) {
      setError(true);
      setErrorMessage('Couldn’t Update');
      return;
    }
    else {
    }
  }


  useEffect(() => {
 
    setDeliverable((prev) => {
      const shallowCopy = [...prev];
      const index = shallowCopy.findIndex(
        (ele) => Number(ele.id) === Number(rowData.id)
      );
      if (shallowCopy[index]) {
        shallowCopy[index].user_price = val;
      }

      return shallowCopy;
    });
  }, [val]);



  const [tat, setTat] = useState(0);

  useEffect(() => {
    if (
      Array.isArray(rowData.deliverable_multiplier) &&
      rowData.deliverable_multiplier.length
    ) {
      setTat(calculateReduction(rowData.deliverable_multiplier, Number(rowData.user_price)));

    }
  }, [rowData, dileverable]);
  useEffect(() => {
    setDeliverable((prev) => {
      const shallowCopy = prev.map((item) => Object.assign({}, item));
      const index = shallowCopy.findIndex(
        (ent) => Number(ent.id) === Number(rowData.id)
      );
      if (shallowCopy[index]) {
        shallowCopy[index].netTotal = tat
          ? Number(tat)
          : shallowCopy[index]?.loe_price;
      }
      return shallowCopy;
    });
  }, []);




  return (
    <TableRow
      key={rowData.id}
      className={classes.tableRow}
      onMouseEnter={() => handleRowHover(index)}
      onMouseLeave={handleRowUnhover}
      style={{
        backgroundColor: index % 2 === 0 ? '#e9ecef' : 'white',
        height: '120px',
      }}
      hover
    >
      <TableCell
        colSpan={2}
        style={{ borderBottom: 'none', fontFamily: 'Open Sans' }}
      >
        {rowData.deliverable}
      </TableCell>
      <TableCell
        style={{
          textAlign: 'center',
          alignContent: 'center',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          borderBottom: 'none',
          borderLeft: '1px solid black',
          borderRight: '1px solid black',
          width: '50%',
        }}
      >
        <TableRow
          style={{
            textAlign: 'center',
            width: '100%',
            display: 'flex',
            justifyContent: 'space-evenly',
            borderBottom: 'none',
          }}
        >
          <TableCell
            style={{
              borderBottom: 'none',
              fontFamily: 'Open Sans',
            }}
          >
            {formatMoneyString(`${total33}`)}
          </TableCell>

          <TableCell
            style={{
              borderBottom: 'none',
              fontFamily: 'Open Sans',

            }}
          >
            <DashedField3
              defaultValue={rowData.user_price}
              value={val}
              type={'number'}
              onChange={(e) => {
                setVal(Number(e.target.value))
                setIsTyping(true)
              }}
              onFocus={() => {
                setIsTyping(true);
              }}
              onBlur={() => {
                UpdateRequest()
                setIsTyping(false)
              }}
            />
          </TableCell>
        </TableRow>
      </TableCell>

      {rowData.deliverable_multiplier &&
        Array.isArray(rowData.deliverable_multiplier) &&
        rowData.deliverable_multiplier.length
        ? rowData.deliverable_multiplier.map((item) => {
          const multiplier =
            item.rate && val
              ? `${multiply(val, divide(parseFloat(item.rate), 100)).toFixed(2)
              }`
              : 0;


          return (
            <TableCell
              style={{
                borderBottom: 'none',
                fontFamily: 'Open Sans',
                fontSize: '0.8rem',
                textAlign: 'center',
              }}
            >
              {item ? (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    justifyContent: 'center',
                    width: 'max-content',

                  }}
                >
                  <TextField
                    value={Number(item.rate)}
                    type="number"
                    onChange={(e) => {
                      setIsTyping(true)
                      setDeliverable((prev) => {
                        const shallowCopy = [...prev];
                        const target = shallowCopy.findIndex(
                          (int) => Number(int.id) === Number(rowData.id)
                        );
                        const index = shallowCopy[
                          target
                        ].deliverable_multiplier.findIndex(
                          (entity) => entity.id === item.id
                        );

                        shallowCopy[target].deliverable_multiplier[
                          index
                        ].rate = Number(e.target.value);
                        return shallowCopy;
                      });
                      setIsTyping(true)
                    }}
                    variant="standard"
                    sx={{
                      '& .MuiInputBase-input': {
                        fontSize: '1rem',
                        fontFamily: 'Open Sans',
                        fontWeight: '500',
                        color: '#000000',
                        textAlign: 'center',
                      },
                      '& .MuiInput-underline:before': {
                        borderBottom: '1px dashed #babcb5',
                      },
                      '& .MuiInput-underline:after': {
                        borderBottom: '1px dashed #babcb5',
                      },
                      '& .MuiInput-underline:hover:not(.Mui-disabled):before':
                      {
                        borderBottom: '1px dashed #babcb5',
                      },
                    }}
                    InputProps={{
                      style: { width: 50 },
                    }}
                    onFocus={() => {
                      setIsTyping(true);
                    }}
                    onBlur={async () => {
                      const shallowCopy = {
                        ...formData,
                      };

                      delete shallowCopy.work_type;
                      delete shallowCopy.estimate_hardcost
                      const res = await fetch(
                        `${ENDPOINT}/api/opportunity/update/estimate/${formData.id}/`,
                        {
                          method: 'PUT',
                          headers: {
                            Authorization: `JWT ${access_token}`,
                            'Content-Type': 'application/json',
                          },
                          body:
                            Array.isArray(formData.collaborator) && formData.collaborator.length
                              ? JSON.stringify({
                                ...shallowCopy,
                                actual: globleTotalCost,
                                estimate_deliverable: dileverable?.map(
                                  (item) => {
                                    const start = moment();
                                    const end = start.add(7, 'days').toISOString();
                                    delete item.task_deliverable
                                    return {
                                      ...item,
                                      deliver_id: item.id,
                                      deliverable_multiplier: item.deliverable_multiplier.map((multi, mindex) => ({
                                        multiplier_id: multi.id,
                                        label: multi.label,
                                        rate: multi.rate,
                                        operation: multi.operation
                                      })),
                                    };
                                  }
                                ),

                                collaborator: formData?.collaborator?.map((item) => item?.id),
                              })
                              : JSON.stringify({
                                ...shallowCopy,
                                actual: globleTotalCost,
                                estimate_deliverable: dileverable?.map(
                                  (item) => {
                                    const start = moment();
                                    const end = start.add(7, 'days').toISOString();
                                    delete item.task_deliverable
                                    return {
                                      ...item,
                                      deliver_id: item.id,
                                      actual: globleTotalCost,
                                      deliverable_multiplier: item.deliverable_multiplier.map((multi, mindex) => ({
                                        multiplier_id: multi.id,
                                        label: multi.label,
                                        rate: multi.rate,
                                        operation: multi.operation
                                      })),
                                    };
                                  }
                                ),

                              }),


                        }
                      );

                      const data = await res.json();

                      if (!res.ok) {

                        setError(true);
                        setErrorMessage('Couldn’t Update');
                        return;
                      }
                      setIsTyping(false)
                    }}
                  />
                  <Typography
                    sx={{
                      color: '#babcb5',
                      fontSize: '1rem',
                      textAlign: 'center',
                      width: '100%',
                    }}
                  >
                    {item.operation + formatMoneyString(multiplier)}
                  </Typography>
                </Box>
              ) : null}
            </TableCell>
          );
        })
        : null}
      <TableCell
        style={{
          borderBottom: 'none',
          fontFamily: 'Open Sans',
          textAlign: 'right',
        }}
        align="right"
      >
        {rowData.user_price &&
          Array.isArray(rowData.deliverable_multiplier) &&
          rowData.deliverable_multiplier.length
          ? `$${tat.toFixed(2)}`
          : rowData.user_price
            ? formatMoneyString(`${rowData.user_price}`)
            : '$0'}
      </TableCell>
    </TableRow>
  );
};

const totalItem = {
  fontWeight: 'bold',
  color: 'white',
  fontFamily: 'Open Sans',
  borderBottom: 'none',
};

const AccumalatorRow = ({
  dileverable,

}) => {
  function sumLeoPrice(arr) {
    return arr.reduce((acc, curr) => acc + curr.loe_price, 0);
  }

  function sumUserPrice(arr) {
    return arr.reduce((acc, curr) => acc + Number(curr.user_price), 0);
  }

  const [loe, setLOE] = useState(0);
  const [user, setUserP] = useState(0);
  const [dilT, setDilT] = useState(0);

  useEffect(() => {
    setLOE(sumLeoPrice(dileverable));
    setUserP(sumUserPrice(dileverable));
    if (
      dileverable.every(
        (entity) =>
          Array.isArray(entity.deliverable_multiplier) &&
          entity.deliverable_multiplier.length
      )
    ) {
      setDilT(calculateTotalNet(dileverable));
    }
  }, [dileverable]);

  useEffect(() => {
    setTracker(Number(dilT));
  }, [dilT]);
  const sumUserPrices = (deliverable) => {
    if (!deliverable || !Array.isArray(deliverable) || !deliverable.length) {
      return 0;
    }

    return deliverable.reduce((acc, item) => {
      const userPrice = Number(item.user_price);
      if (!isNaN(userPrice)) {
        acc += userPrice;
      }
      return acc;
    }, 0);
  };



  const [tracker, setTracker] = useState(0);

  const allWorktypes = useSelector((item) => item.Misc.workTypes)




  const totalCo = dileverable.map((item, ind) => item.deliverable_worktype.reduce((accumulator, worktype) => {
    const hours = Number(worktype.hours);
    const user_rate = allWorktypes.find((item) => item.name === worktype.name)?.user_rate
    const userRate = parseFloat(user_rate ? user_rate : 0);
    const subtotal = hours * userRate;
    return accumulator + subtotal;
  }, 0))

  const sumTotalCo = totalCo.reduce((accumulator, currentValue) => accumulator + currentValue, 0);

  function totalWithMulti(deliverable) {
    let totalPrice = 0;

    deliverable.forEach((item) => {
      const userPrice = item.user_price;
      const multipliers = item.deliverable_multiplier;

      multipliers.forEach((multiplier) => {
        const { rate, operation } = multiplier;

        if (operation === '+') {
          totalPrice += userPrice + (userPrice * rate) / 100;
        } else if (operation === '-') {
          totalPrice += userPrice - (userPrice * rate) / 100;
        }
      });
    });

    return totalPrice;
  }


  return (
    <TableRow
      style={{
        height: '93px',
        backgroundColor: '#18075e',
        color: 'white',
      }}
    >
      <TableCell colSpan={2} align="left">
        <div className="fstxt-wh">Total</div>
      </TableCell>

      <TableCell
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          fontWeight: 'bold',
          color: 'gray',
          fontFamily: 'Open Sans',
          padding: '0px',
          margin: '0px',
          width: '50%',
          justifyContent: 'center',
          borderBottom: '1px solid #18075e',
          height: '100px',
        }}
      >
        <TableRow
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-evenly',
          }}
        >
          <TableCell style={{ borderBottom: 'none' }}>
            <div className="fstxt-wh">{formatMoneyString(`${sumTotalCo}`)}</div>
          </TableCell>
          <TableCell style={{ borderBottom: 'none' }}>
            <div className="fstxt-wh">{formatMoneyString(`${user}`)}</div>
          </TableCell>
        </TableRow>
      </TableCell>
      {dileverable[0] &&
        Array.isArray(dileverable[0].deliverable_multiplier) &&
        dileverable[0].deliverable_multiplier.length
        ? dileverable[0].deliverable_multiplier.map((item) => {
          return <TableCell>{''}</TableCell>;
        })
        : null}
      <TableCell align="right">
        <div className="fstxt-wh">
          {Array.isArray(dileverable[0]?.deliverable_multiplier) && dileverable[0]?.deliverable_multiplier.length ? `$${(calculatePercentage(dileverable) + dilT).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}` : formatMoneyString(dilT ? `${dilT}` : `${user}`)}
        </div>
      </TableCell>
    </TableRow>
  );
};

const CustomAddMultiplier = ({
  detect,
  addDisc,
  setAddDisc,
  dileverable,
  setDeliverable,
  formData,
  setIsTyping,
  setLeng,
  leng,
  UpdateRequest,
  setFormData,
}) => {
  const [set, setSet] = useState(false);
  const estimates = useSelector((state) => state.oppertunity.estimates);
  const dispatch = useDispatch()
  const currentTarget =
    Array.isArray(estimates) && estimates.length
      ? estimates.find((est) => est.title === formData?.title)
      : false;
  const [val, setVal] = useState({
    id: uuid(),
    label: '',
    rate: 0,
    operation: '-',
    value: '',
    visible: true,
  });
  const access_token = useSelector((state) => state.auth.user.access);

  const sumUserPrices = (deliverable) => {
    if (!deliverable || !Array.isArray(deliverable) || !deliverable.length) {
      return 0;
    }

    return deliverable.reduce((acc, item) => {
      const userPrice = Number(item.user_price);
      if (!isNaN(userPrice)) {
        acc += userPrice;
      }
      return acc;
    }, 0);
  };

  const calculateTotalPrice = (array) => {

    return array.reduce((accumulator, item) => {
      return accumulator + Number(item?.price_to_customer);
    }, 0);
  };

  return !set ? (
    <Box
      sx={{ opacity: !detect ? '0.5' : 1, cursor: 'pointer' }}
      onClick={() => setSet(true)}
    >
      {' '}
      + Add <br /> Multiplier
    </Box>
  ) : (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
      }}
    >
      <TextField
        sx={{
          '& .MuiInputBase-input': {
            fontSize: '1rem',
            fontFamily: 'Open Sans',
            fontWeight: '500',
            color: '#711fff',
            textAlign: 'left',
          },
          '& .MuiInput-underline:before': {
            borderBottom: '1px dashed #000',
          },
          '& .MuiInput-underline:after': {
            borderBottom: '1px dashed #000',
          },
          '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
            borderBottom: '1px dashed #000',
          },
        }}
        variant="standard"
        value={val.label}
        onChange={(e) =>
          setVal({
            ...val,
            label: e.target.value,
          })
        }
        label={'Your Label'}
        pre="%"
      />
      <Box
        sx={{
          display: 'flex',
          padding: '0.44rem 0.88rem',
          backgroundColor: '#F1F3F5',
          borderRadius: '4px',
          justifyContent: 'space-between',
          width: '100%',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '0.22rem 0.44rem',
          }}
        >
          <AddBoxIcon
            sx={{
              fill: val.operation === '+' ? '#711fff' : '#ADADAD',
              cursor: 'pointer',
            }}
            onClick={(e) =>
              setVal({
                ...val,
                operation: '+',
              })
            }
          />
          <IndeterminateCheckBoxIcon
            sx={{
              fill: val.operation === '-' ? '#711fff' : '#ADADAD',
              cursor: 'pointer',
            }}
            onClick={(e) =>
              setVal({
                ...val,
                operation: '-',
              })
            }
          />
        </Box>
        <TextField
          sx={{
            '& .MuiInputBase-input': {
              fontSize: '1rem',
              fontFamily: 'Open Sans',
              fontWeight: '500',
              color: '#711fff',
              textAlign: 'left',
            },
            '& .MuiInput-underline:before': {
              borderBottom: '1px dashed #f1f3f5',
            },
            '& .MuiInput-underline:after': {
              borderBottom: '1px dashed #f1f3f5',
            },
            '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
              borderBottom: '1px dashed #f1f3f5',
            },
          }}
          InputProps={{
            style: { width: 60 },
            inputProps: { min: '0', max: '100', step: '1' },
          }}
          type="number"
          value={val.rate}
          onChange={(e) =>
            setVal({
              ...val,
              rate: e.target.value,
            })
          }
          label="%"
          variant="standard"
        />

        <ContentCopyIcon
          sx={{
            fontSize: '1rem',
            transition: 'all 0.2s ease-in-out',
            '&:hover': { fill: '#711fff' },
            cursor: 'pointer',
          }}
          onClick={async (e) => {
            e.preventDefault();
            setSet(false);
            setVal({
              id: uuid(),
              label: '',
              rate: 0,
              operation: '-',
              value: '',
              visible: true,
            });
            const res = await fetch(`${ENDPOINT}/api/project/multiplier/create/`, {
              method: 'POST',
              headers: {
                Authorization: `JWT ${access_token}`,
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(dileverable.map((item, ind) => ({
                deliverable_id: item.id,
                deliverable_estimate: formData.id,
                deliverable_multiplier: [{
                  label: val.label,
                  rate: val.rate,
                  operation: val.operation,
                  total_duration: 0
                }]
              })))
            })

            const data = await res.json();

            if (!res.ok) {
              setError(true);
              setErrorMessage('Couldn’t Update');
              return;
            }

            const resGet = await fetch(`${ENDPOINT}/api/opportunity/estimate/${formData?.unique_id}/`, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `JWT ${access_token}`,
              },
            });
            const dataGet = await resGet.json();
            
            if (
              dataGet &&
              Array.isArray(dataGet.deliverable) &&
              dataGet.deliverable.length
            ) {
              setDeliverable(dataGet.deliverable);
              setFormData(dataGet)
              let finalPrice;
              let cost = 0;

              if (dataGet.deliverable && Array.isArray(dataGet.deliverable) && dataGet.deliverable.length) {
                const hasAllUserPriceDefined = dataGet.deliverable.every((item) => {
                  return Number(item.user_price) >= 0;
                });

                if (!hasAllUserPriceDefined) {
                  const result = dataGet.deliverable.reduce((acc, curr) => {
                    curr?.deliverable_worktype?.forEach((item) => {
                      if (acc[item.id]) {
                        acc[item.id].hours += Number(item.hours ? item.hours : 0);
                      } else {
                        acc[item.id] = { id: item.id, hours: Number(item.hours ? item.hours : 0) };
                      }
                    });
                    return acc;
                  }, {});

                  const finalResult = Object.values(result);
                  finalResult.forEach((item) => {
                    const matchingType = formData?.work_type.find((type) => type.id === item.id);
                    if (matchingType) {
                      cost += item.hours * matchingType.user_rate;
                    }
                  });

                  if (Array.isArray(dataGet.deliverable[0].deliverable_multiplier)) {
                    if (Array.isArray(dataGet.estimate_hardcost) && dataGet.estimate_hardcost.length) {
                      finalPrice = calculateTotalPrice(dataGet.estimate_hardcost) + calculatePercentage(dataGet.deliverable) + cost
                    }
                    else {

                      finalPrice = calculatePercentage(dataGet.deliverable) + cost
                    }
                  }
                  else {

                    finalPrice = cost;
                  }

                } else {
                  const rawCost = sumUserPrices(dataGet.deliverable);
                  if (Array.isArray(dataGet.deliverable[0]?.deliverable_multiplier)) {
                    if (Array.isArray(dataGet.estimate_hardcost) && dataGet.estimate_hardcost.length) {

                      finalPrice = calculateTotalPrice(dataGet.estimate_hardcost) + calculatePercentage(dataGet.deliverable) + rawCost
                    }
                    else {

                      finalPrice = calculatePercentage(dataGet.deliverable) + rawCost

                    }
                  }
                  else {
                    finalPrice = rawCost


                  }
                }
              }
              const check = true;
              UpdateRequest(check, finalPrice)
            }


          }}

        />
      </Box>
    </Box>
  );
};

const HardTHead = () => {
  const leoPrice = {
    borderBottom: 'none',
    color: 'white',
    fontFamily: 'Open Sans',
    width: '100%',
  };

  const hardCost = { borderBottom: 'none', fontFamily: 'Open Sans' };

  const lastTotal = {
    color: 'white',
    fontFamily: 'Open Sans',
    fontWeight: 'bold',
  };
  return (
    <TableRow style={{ height: '80px' }}>
      <TableCell style={hardCost}>Hard Cost</TableCell>

      <TableCell style={hardCost}>Our Cost</TableCell>
      <TableCell style={hardCost}>Price to Customer</TableCell>
      <TableCell style={hardCost}>
        <p
          style={{
            backgroundColor: '#f5f5f5',
            borderRadius: '10px',
            padding: '10px',
          }}
        >
          Margin
        </p>
      </TableCell>

      <TableCell style={hardCost} align='right'>Total</TableCell>
    </TableRow>
  );
};

const HardAccumalator = ({ estimate_hardcost }) => {
  const lastTotal = {
    color: 'white',
    fontFamily: 'Open Sans',
    fontWeight: 'bold',
  };

  const [tat, setTat] = useState(0);
  useEffect(() => {
    if (Array.isArray(estimate_hardcost) && estimate_hardcost.length) {
      const total = sumPriceToCustomer(estimate_hardcost);
      setTat(Number(total));
    }
  }, [estimate_hardcost]);

  return (
    <TableRow
      style={{
        backgroundColor: '#18075e',
        color: 'white',
        height: '93px',

      }}
    >
      <TableCell
        style={{
          color: 'white',
          fontFamily: 'Open Sans',
          fontWeight: 'bold',

        }}
      >
        Total
      </TableCell>
      <TableCell style={lastTotal} align="left">
        {' '}
      </TableCell>
      <TableCell style={lastTotal} align="left">
        {' '}
      </TableCell>
      <TableCell style={lastTotal} align="left">
        {' '}
      </TableCell>
      <TableCell align='right' style={lastTotal}>
        {tat ? formatMoneyString(`${tat}`) : '$0'}
      </TableCell>
    </TableRow>
  );
};

const AddRows = ({ estimate_hardcost, setEstHardCost, currentTarget, formData }) => {
  const hardCost = { borderBottom: 'none', fontFamily: 'Open Sans' };
  const [priceToCustomer, setPriceToCustomer] = useState(0);
  const [hardCostTotal, setHardConstTotal] = useState(0);
  const [ourPrice, setOurPrice] = useState(0);
  const [label, setLabel] = useState('');
  const access_token = useSelector(state => state.auth.user.access)
  const current_user = useSelector(state => state.userData.userData)
  const dispatch = useDispatch()

  return (
    <TableRow style={{ height: '100px', backgroundColor: '#e9ecef' }}>
      <TableCell style={hardCost}>
        <Tooltip title={'Press Enter to Add New Hard Cost'}>
          <input
            placeholder="Type to Add Hard Cost"
            style={{
              width: '100%',
              borderBottom: '2px dotted gray',
              borderTop: 'none',
              borderLeft: 'none',
              borderRight: 'none',
              outline: 'none',
              backgroundColor: '#e9ecef',
            }}
            value={label}
            onChange={(e) => {
              setLabel(e.target.value);
            }}
            onKeyPress={async (e) => {
              if (e.key === 'Enter') {
                setLabel('');

                const res = await fetch(
                  `${ENDPOINT}/api/opportunity/create/estimate/hardcost/`,
                  {
                    method: 'POST',
                    headers: {
                      Authorization: `JWT ${access_token}`,
                      'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                      hardcost_label: label,
                      our_price: 0,
                      price_to_customer: 0,
                      estimate: formData.id,
                      hardcost_margin: 0,
                    })
                  }
                );


                const data = await res.json();
                if (!res.ok) {
                  return;
                }
                const resGet = await fetch(`${ENDPOINT}/api/opportunity/estimate/${formData?.unique_id}/`, {
                  method: 'GET',
                  headers: {
                    'Content-Type': 'application/json',
                    Authorization: `JWT ${access_token}`,
                  },
                });
                const dataGet = await resGet.json();
                if (
                  dataGet &&
                  Array.isArray(dataGet.estimate_hardcost) &&
                  dataGet.estimate_hardcost.length
                ) {
                  setEstHardCost(dataGet.estimate_hardcost);
                }
              }
            }}
          />
        </Tooltip>
      </TableCell>

      <TableCell style={hardCost}> </TableCell>

      <TableCell style={hardCost}> </TableCell>

      <TableCell style={hardCost}> </TableCell>

      <TableCell style={hardCost}> </TableCell>
    </TableRow>
  );
};

const HardCostTable = ({ estimate_hardcost, setEstHardCost, setTic, currentTarget, access_token, formData, setIsTyping, globleTotalCost }) => {
  const leoPrice = {
    borderBottom: 'none',
    color: 'white',
    fontFamily: 'Open Sans',
    width: '100%',
  };

  const hardCost = { borderBottom: 'none', fontFamily: 'Open Sans' };


  return (
    <Table>
      <HardTHead
        estimate_hardcost={estimate_hardcost}
        setEstHardCost={setEstHardCost}
        currentTarget={currentTarget}
      />

      <TableBody>
        {Array.isArray(estimate_hardcost) && estimate_hardcost.length
          ? estimate_hardcost.map((item, i) => (
            <TableRow
              style={{ height: '100px', backgroundColor: '#e9ecef' }}
              key={item.dentity}
            >
              <TableCell style={hardCost}>
                <input
                  placeholder="Type to Add Hard Cost"
                  style={{
                    width: '100%',
                    borderBottom: '2px dotted gray',
                    borderTop: 'none',
                    borderLeft: 'none',
                    borderRight: 'none',
                    outline: 'none',
                    backgroundColor: '#e9ecef',
                  }}
                  value={item.hardcost_label}
                  onChange={(e) => {
                    setIsTyping(true)
                    setEstHardCost((prev) => {
                      const shallowCopy = prev.map((item) =>
                        Object.assign({}, item)
                      );
                      const index = shallowCopy.findIndex(
                        (ind) => ind.id === item.id
                      );
                      shallowCopy[index].hardcost_label = e.target.value;
                      return shallowCopy;
                    });
                  }}
                  onFocus={() => {
                    setIsTyping(true);
                  }}
                  onBlur={async (e) => {


                    const shallowCopy = {
                      ...formData,
                    };
                    delete shallowCopy.work_type;
                    const res = await fetch(
                      `${ENDPOINT}/api/opportunity/update/estimate/${formData.id}/`,
                      {
                        method: 'PUT',
                        headers: {
                          Authorization: `JWT ${access_token}`,
                          'Content-Type': 'application/json',
                        },
                        body:
                          Array.isArray(formData.collaborator) && formData.collaborator.length
                            ? JSON.stringify({
                              ...shallowCopy,
                              actual: globleTotalCost,
                              estimate_hardcost: estimate_hardcost.map((hard, ind) => ({
                                hardcost_id: hard.id,
                                hardcost_label: hard.hardcost_label,
                                our_price: hard.our_price,
                                price_to_customer: hard.price_to_customer,
                                hardcost_margin: Number(hard.price_to_customer) - Number(hard.our_price),
                              })),
                              collaborator: formData?.collaborator?.map((item) => item?.id),
                            })
                            : JSON.stringify({
                              ...shallowCopy,
                              actual: globleTotalCost,
                              estimate_hardcost: estimate_hardcost.map((hard, ind) => ({
                                hardcost_id: hard.id,
                                hardcost_label: hard.hardcost_label,
                                our_price: hard.our_price,
                                price_to_customer: hard.price_to_customer,
                                hardcost_margin: Number(hard.price_to_customer) - Number(hard.our_price),
                              })),

                            }),
                      }
                    );

                    const data = await res.json();
                    if (!res.ok) {
                      setError(true);
                      setErrorMessage('Couldn’t Update');
                      return;
                    }
                    setIsTyping(false)
                  }}
                />
              </TableCell>

              <TableCell style={hardCost}>
                <input
                  type="number"
                  placeholder="$0"
                  style={{
                    width: '20%',
                    borderBottom: '2px dotted gray',
                    borderTop: 'none',
                    borderLeft: 'none',
                    borderRight: 'none',
                    outline: 'none',
                    textAlign: 'center',
                    backgroundColor: '#e9ecef',
                  }}
                  value={item.our_price}
                  onChange={(e) => {
                    setIsTyping(true)
                    setEstHardCost((prev) => {
                      const shallowCopy = prev.map((item) =>
                        Object.assign({}, item)
                      );
                      const index = shallowCopy.findIndex(
                        (ind) => ind.id === item.id
                      );

                      shallowCopy[index].our_price = e.target.value;

                      return shallowCopy;
                    });

                  }}
                  onFocus={() => {
                    setIsTyping(true);
                  }}
                  onBlur={async (e) => {

                    const shallowCopy = {
                      ...formData,
                    };
                    delete shallowCopy.work_type;
                    const res = await fetch(
                      `${ENDPOINT}/api/opportunity/update/estimate/${formData.id}/`,
                      {
                        method: 'PUT',
                        headers: {
                          Authorization: `JWT ${access_token}`,
                          'Content-Type': 'application/json',
                        },
                        body:
                          Array.isArray(formData.collaborator) && formData.collaborator.length
                            ? JSON.stringify({
                              ...shallowCopy,
                              actual: globleTotalCost,
                              estimate_hardcost: estimate_hardcost.map((hard, ind) => ({
                                hardcost_id: hard.id,
                                hardcost_label: hard.hardcost_label,
                                our_price: hard.our_price,
                                price_to_customer: hard.price_to_customer,
                                hardcost_margin: Number(hard.price_to_customer) - Number(hard.our_price),
                              })),
                              collaborator: formData?.collaborator?.map((item) => item?.id),
                            })
                            : JSON.stringify({
                              ...shallowCopy,
                              estimate_hardcost: estimate_hardcost.map((hard, ind) => ({
                                hardcost_id: hard.id,
                                hardcost_label: hard.hardcost_label,
                                our_price: hard.our_price,
                                price_to_customer: hard.price_to_customer,
                                hardcost_margin: Number(hard.price_to_customer) - Number(hard.our_price),
                              })),
                            }),
                      }
                    );

                    const data = await res.json();
                    if (!res.ok) {

                      setError(true);
                      setErrorMessage('Couldn’t Update');
                      return;
                    }
                    setIsTyping(false)
                  }}

                />{' '}
              </TableCell>

              <TableCell
                style={{
                  borderBottom: 'none',
                  fontFamily: 'Open Sans',
                }}
              >
                <input
                  type="number"
                  placeholder="$0"
                  style={{
                    width: '20%',
                    borderBottom: '2px dotted gray',
                    borderTop: 'none',
                    borderLeft: 'none',
                    borderRight: 'none',
                    outline: 'none',
                    textAlign: 'center',
                    backgroundColor: '#e9ecef',
                  }}
                  value={item.price_to_customer}
                  onChange={(e) => {
                    setEstHardCost((prev) => {
                      const shallowCopy = prev.map((item) =>
                        Object.assign({}, item)
                      );
                      const index = shallowCopy.findIndex(
                        (ind) => ind.id === item.id
                      );

                      shallowCopy[index].price_to_customer = e.target.value;
                      return shallowCopy;
                    })
                    setIsTyping(true)
                  }

                  }

                  onFocus={() => {
                    setIsTyping(true);
                  }}

                  onBlur={async (e) => {

                    const shallowCopy = {
                      ...formData,
                    };
                    delete shallowCopy.work_type;
                    const res = await fetch(
                      `${ENDPOINT}/api/opportunity/update/estimate/${formData.id}/`,
                      {
                        method: 'PUT',
                        headers: {
                          Authorization: `JWT ${access_token}`,
                          'Content-Type': 'application/json',
                        },
                        body:
                          Array.isArray(formData.collaborator) && formData.collaborator.length
                            ? JSON.stringify({
                              ...shallowCopy,
                              actual: globleTotalCost,
                              estimate_hardcost: estimate_hardcost.map((hard, ind) => ({
                                hardcost_id: hard.id,
                                hardcost_label: hard.hardcost_label,
                                our_price: hard.our_price,
                                price_to_customer: hard.price_to_customer,
                                hardcost_margin: Number(item.our_price) - Number(hard.price_to_customer),
                              })),


                              collaborator: formData?.collaborator?.map((item) => item?.id),
                            })
                            : JSON.stringify({
                              ...shallowCopy,
                              actual: globleTotalCost,

                              estimate_hardcost: estimate_hardcost.map((hard, ind) => ({
                                hardcost_id: hard.id,
                                hardcost_label: hard.hardcost_label,
                                our_price: hard.our_price,
                                price_to_customer: hard.price_to_customer,
                                hardcost_margin: Number(hard.our_price) - Number(hard.price_to_customer),
                              })),

                            }),
                      }
                    );

                    const data = await res.json();
                    if (!res.ok) {
                      setError(true);
                      setErrorMessage('Couldn’t Update');
                      return;
                    }
                    setIsTyping(false)
                  }}
                />{' '}
              </TableCell>
              <TableCell
                style={{
                  borderBottom: 'none',
                  fontFamily: 'Open Sans',
                  textAlign: 'center',
                  fontWeight: 'bold',
                }}
              >
                <p
                  style={{
                    backgroundColor: '#daaafa',
                    borderRadius: '10px',
                    padding: '10px',
                  }}
                >
                  $
                  {item.price_to_customer && item.our_price
                    ? subtract(
                      Number(item.price_to_customer),
                      Number(item.our_price)
                    )
                    : 0}
                </p>
              </TableCell>
              <TableCell
                style={{ borderBottom: 'none', fontFamily: 'Open Sans' }}
                align='right'
              >
                {formatMoneyString(`${item.price_to_customer}`)}
              </TableCell>
            </TableRow>
          ))
          : null}

        <AddRows
          estimate_hardcost={estimate_hardcost}
          setEstHardCost={setEstHardCost}
          currentTarget={currentTarget}
          formData={formData}
        />
        <HardAccumalator estimate_hardcost={estimate_hardcost} />
      </TableBody>
    </Table>
  );
};

const MultiHead = ({ item, access_token, dileverable, formData, setDeliverable }) => {

  const [show, setShow] = useState(false);

  return (

    <TableCell
      key={item.id} // Don't forget to add a unique key
      onMouseOver={() => {
        setShow(true);
      }}
      onMouseOut={() => {
        setShow(false);
      }}
    >
      {item.label ? item.operation + ' ' + item.label : ''}
      <img src={DeleteIcon} className="icon-sm"
        onClick={async () => {
          const filteredNames = dileverable.map((dil) => {
            return dil.deliverable_multiplier.find((multi) => multi.label === item.label)?.id
          })

          if (Array.isArray(filteredNames) && filteredNames.length) {
            let i = 0;
            for (i; i < filteredNames.length; i++) {
              const response = await fetch(
                `${ENDPOINT}/api/project/multiplier/delete/${filteredNames[i]}/`,
                {
                  method: 'DELETE',
                  headers: {
                    Authorization: `JWT ${access_token}`,
                    'Content-Type': 'application/json',
                  },
                }
              );

              if (response.ok) {
              }
              else {
                const data = await response.json()
              }
            }

            const res = await fetch(`${ENDPOINT}/api/opportunity/estimate/${formData?.unique_id}/`, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `JWT ${access_token}`,
              },
            });
            const data = await res.json();
            if (!res.ok) {

            }
            if (
              data &&
              Array.isArray(data.deliverable) &&
              data.deliverable.length
            ) {
              setDeliverable(data.deliverable);
            }
          }

          else {
          }


        }}
        style={{
          opacity: show ? 1 : 0,
          transition: '0.5s ease-in-out',
          cursor: 'pointer',

        }}
      />
    </TableCell>

  );
};


function Fifth({
  formData,
  cache,
  dileverable,
  setDeliverable,
  estimate_hardcost,
  setEstHardCost,
  setTracker,
  setTic,
  totalCost,
  globleTotalCost,
  setGI,
  GI,
  UpdateRequest,
  setFormData,
}) {
  const estimates = useSelector((state) => state.oppertunity.estimates);
  const currentTarget =
    Array.isArray(estimates) && estimates.length
      ? estimates.find((est) => est.title === formData?.title)
      : false;
  const access_token = useSelector(state => state.auth.user.access)
  const [isTyping, setIsTyping] = useState(false);
  const [ourPrice, setOurPrice] = useState(0);


  useEffect(() => {
    const fetchData = async () => {
      const res = await fetch(`${ENDPOINT}/api/opportunity/estimate/${formData?.unique_id}/`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `JWT ${access_token}`,
        },
      });
      const data = await res.json();
      
      // return data;
      if (
        data &&
        Array.isArray(data.deliverable) &&
        data.deliverable.length
      ) {
        const sortedDeliverable = data.deliverable.sort((a, b) => a.sort_order - b.sort_order)
        setDeliverable(sortedDeliverable);
      }
      if (data && Array.isArray(data.estimate_hardcost) && data.estimate_hardcost.length) {
        setEstHardCost(data.estimate_hardcost);
      }

    };
    const intervalId = setInterval(() => {
      if (isTyping === false) {
        fetchData();
      }
    }, 3000);

    return () => {
      clearInterval(intervalId);
    };
  }, [currentTarget, isTyping]);


  const [priceToCustomer, setPriceToCustomer] = useState(0);
  const [hardCostTotal, setHardConstTotal] = useState(0);
  const [margin, setMargin] = useState(0);

  const useStyles = makeStyles({
    tableRow: {
      '&:hover': {
        backgroundColor: 'lightgray',
      },
    },
  });
  const classes = useStyles();

  const dil =
    Array.isArray(dileverable) && dileverable.length ? dileverable : false;

  const tableData = dil
    ? dil.map((item) => {
      return {
        id: item.id,
        deliverable: item.deliverable_name,
        leoPrice: '2,000',
        yourPrice: '8,000',
        add: '+',
        total: '30',
        Worktype: item.Worktype,
        total_loe: item.total_loe,
        total_hours: item.total_hours,
        deliverable_startdate: item.deliverable_startdate,
        deliverable_enddate: item.deliverable_enddate,
        deliverable_worktype: item.deliverable_worktype,
        deliverable_multiplier: item.deliverable_multiplier,
        loe_rate: item.loe_price,
        user_price: item.user_price ? item.user_price : calculateLOERate(item, formData.work_type),
      };
    })
    : [
      {
        deliverable: 'Reasearch and Dictionaory',
        leoPrice: '00',
        yourPrice: '00',
        add: '+',
        total: 30,
      },
    ];

  useEffect(() => {
    setHardConstTotal(Number(ourPrice + priceToCustomer));
    setMargin(Number(priceToCustomer - ourPrice));
  }, [ourPrice, priceToCustomer]);

  const leoPrice = {
    borderBottom: 'none',
    color: 'white',
    fontFamily: 'Open Sans',
    width: '100%',
  };

  const hardCost = { borderBottom: 'none', fontFamily: 'Open Sans' };

  const lastTotal = {
    color: 'white',
    fontFamily: 'Open Sans',
    fontWeight: 'bold',
  };

  const [loe, setLOE] = useState(0);
  useEffect(() => {
    let accumalator = 0;
    tableData.forEach((item) => {
      return (accumalator += calculateLOERate(item));
    });
    setLOE(accumalator);
  }, [tableData]);

  const [setUp, setSetUp] = useState([]);
  const [detect, setDectect] = useState(false);
  const [addDisc, setAddDisc] = useState([]);
  const [pliers, setPliers] = useState([]);

  useEffect(() => {
    Array.isArray(addDisc) && addDisc.length
      ? setPliers([...pliers, ...addDisc])
      : [];
  }, [addDisc]);
  const [leng, setLeng] = useState(dileverable[0]?.deliverable_multiplier?.length)

  const [cuttedValue, setcuttedValue] = useState(0)


  return (
    <div>
      <div style={{ width: '100%' }}>
        <Table style={{ width: '100%' }}>
          <TableHead>
            <TableRow>
              <TableCell
                style={{
                  fontWeight: 'normal',
                  color: 'gray',
                  fontFamily: 'Open Sans',
                }}
                colSpan={2}
              >
                {' '}
                Deliverable{' '}
                <InfoOutlinedIcon
                  sx={{ color: '#4dfad2', fontSize: '15px' }}
                />{' '}
              </TableCell>
              <TableCell
                style={{
                  textAlign: 'center',
                  alignContent: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  fontWeight: 'bold',
                  color: 'gray',
                  fontFamily: 'Open Sans',
                  padding: '0px',
                  margin: '0px',
                  width: '50%',
                  borderBottom: 'none',
                }}
              >
                <div style={{ width: '90%' }}>
                  Subtotal
                  <TableRow
                    style={{
                      borderTop: '1.5px solid gray',
                      padding: '0px',
                      display: 'flex',
                      justifyContent: 'space-evenly',
                    }}
                  >
                    <TableCell
                      style={{
                        borderBottom: 'none',
                        color: 'gray',
                        fontFamily: 'Open Sans',
                      }}
                    >
                      LOE Total
                    </TableCell>
                    <TableCell
                      style={{
                        borderBottom: 'none',
                        color: 'gray',
                        fontFamily: 'Open Sans',
                      }}
                    >
                      Price to Customer
                    </TableCell>
                  </TableRow>
                </div>
              </TableCell>
              {tableData[0].deliverable_multiplier &&
                Array.isArray(tableData[0].deliverable_multiplier) &&
                tableData[0].deliverable_multiplier.length ? (
                tableData[0].deliverable_multiplier.map((item) => {


                  return (
                    <MultiHead item={item} access_token={access_token} dileverable={dileverable} formData={formData} setDeliverable={setDeliverable} />
                  );
                })
              ) : null}
              <TableCell
                style={{
                  fontWeight: 'bold',
                  fontFamily: 'Open Sans',
                  textAlign: 'center',
                }}
                align="left"
                onMouseEnter={() => setDectect(true)}
                onMouseLeave={() => setDectect(false)}
              >
                <Box
                  sx={{
                    display: 'flex',
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <CustomAddMultiplier
                    detect={detect}
                    addDisc={addDisc}
                    setAddDisc={setAddDisc}
                    dileverable={dileverable}
                    setDeliverable={setDeliverable}
                    formData={formData}
                    setIsTyping={setIsTyping}
                    setLeng={setLeng}
                    leng={leng}
                    UpdateRequest={UpdateRequest}
                    setFormData={setFormData}
                  />
                </Box>
                <Typography sx={{ textAlign: 'right' }}>Total</Typography>
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {tableData.map((rowData, index) => {
              return (
                <DilRow
                  globleTotalCost={globleTotalCost}
                  rowData={rowData}
                  classes={classes}
                  index={index}
                  addDisc={addDisc}
                  setAddDisc={setAddDisc}
                  dileverable={dileverable}
                  setDeliverable={setDeliverable}
                  currentTarget={currentTarget}
                  access_token={access_token}
                  formData={formData}
                  setIsTyping={setIsTyping}
                  leng={leng}
                  setcuttedValue={setcuttedValue}
                  cuttedValue={cuttedValue}
                />
              );
            })}
          </TableBody>
          <AccumalatorRow
            loe={loe}
            pliers={pliers}
            tableData={tableData}
            dileverable={dileverable}
            setDeliverable={setDeliverable}
            setTracker={setTracker}
            totalCost={totalCost}
            formData={formData}
            leng={leng}
            cuttedValue={cuttedValue}
            setGI={setGI}
            GI={GI}
          />
        </Table>

        <HardCostTable
          globleTotalCost={globleTotalCost}
          estimate_hardcost={estimate_hardcost}
          setEstHardCost={setEstHardCost}
          setTic={setTic}
          currentTarget={currentTarget}
          access_token={access_token}
          formData={formData}
          setIsTyping={setIsTyping}
        />
      </div>
    </div>
  );
}
export default Fifth;
