 <svg width="31" height="36" viewBox="0 0 31 36" fill="none" xmlns="http://www.w3.org/2000/svg">
<path id="Vector" d="M27.3049 10.7068L27.0835 10.9282L27.279 11.1728C29.0515 13.39 30.1616 16.0628 30.4816 18.8833C30.8015 21.7038 30.3183 24.5573 29.0875 27.1152C27.8567 29.6731 25.9284 31.8313 23.5247 33.3413C21.1211 34.8512 18.3398 35.6515 15.5012 35.65H15.501C7.1574 35.65 0.422418 28.8984 0.422418 20.5714C0.422418 12.2447 7.17429 5.49286 15.501 5.49286H15.501C18.9258 5.49234 22.2477 6.66271 24.9158 8.80982L25.1606 9.00678L25.3828 8.78463L27.59 6.57735C28.2723 7.16292 28.9125 7.81295 29.5103 8.50147L27.3049 10.7068ZM10.7081 0.35H20.2938V3.07857H10.7081V0.35ZM16.8653 12.35V21.9357H14.1367V12.35H16.8653ZM3.15099 20.5714C3.15099 27.399 8.6734 32.9214 15.501 32.9214C22.3286 32.9214 27.851 27.399 27.851 20.5714C27.851 13.7438 22.3286 8.22143 15.501 8.22143C8.6734 8.22143 3.15099 13.7438 3.15099 20.5714Z" fill="#03071E" fill-opacity="0.8" stroke="white" stroke-width="0.7"/>
</svg> 


import React from 'react';

const Timer = ({ svgColor }) => (
  <svg
    width="31"
    height="36"
    viewBox="0 0 31 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      id="Vector"
      d="M27.3049 10.7068L27.0835 10.9282L27.279 11.1728C29.0515 13.39 30.1616 16.0628 30.4816 18.8833C30.8015 21.7038 30.3183 24.5573 29.0875 27.1152C27.8567 29.6731 25.9284 31.8313 23.5247 33.3413C21.1211 34.8512 18.3398 35.6515 15.5012 35.65H15.501C7.1574 35.65 0.422418 28.8984 0.422418 20.5714C0.422418 12.2447 7.17429 5.49286 15.501 5.49286H15.501C18.9258 5.49234 22.2477 6.66271 24.9158 8.80982L25.1606 9.00678L25.3828 8.78463L27.59 6.57735C28.2723 7.16292 28.9125 7.81295 29.5103 8.50147L27.3049 10.7068ZM10.7081 0.35H20.2938V3.07857H10.7081V0.35ZM16.8653 12.35V21.9357H14.1367V12.35H16.8653ZM3.15099 20.5714C3.15099 27.399 8.6734 32.9214 15.501 32.9214C22.3286 32.9214 27.851 27.399 27.851 20.5714C27.851 13.7438 22.3286 8.22143 15.501 8.22143C8.6734 8.22143 3.15099 13.7438 3.15099 20.5714Z"
      fill={svgColor}
      fillOpacity="0.8"
      stroke="white"
      strokeWidth="0.7"
    />
  </svg>
);


const People = ({ svgColor }) => (
  <svg width="31" height="30" viewBox="0 0 31 30" fill='none' xmlns="http://www.w3.org/2000/svg">
<g id="Contacts-Active 1" clip-path="url(#clip0_19155_5006)" >
<g id="Contacts_-_Active" >
<g id="d">
<g id="Group">
<path id="Vector" d="M15.4989 13.6391C18.2605 13.6391 20.4993 11.4003 20.4993 8.63868C20.4993 5.87705 18.2605 3.63831 15.4989 3.63831C12.7373 3.63831 10.4985 5.87705 10.4985 8.63868C10.4985 11.4003 12.7373 13.6391 15.4989 13.6391Z" stroke="#03071E" stroke-opacity="0.8" stroke-miterlimit="10"  />
<path id="Vector_2" d="M15.4989 14.867C19.5033 14.867 22.755 18.1187 22.755 22.1231V26.3754H8.24048V22.1231C8.24048 18.1187 11.4922 14.867 15.4966 14.867H15.4989V14.867Z" stroke="#03071E" stroke-opacity="0.8" stroke-miterlimit="10" />
</g>
<g id="Group_2">
<path id="Vector_3" d="M6.30539 16.9886C8.34239 16.9886 9.9937 15.3373 9.9937 13.3003C9.9937 11.2633 8.34239 9.61194 6.30539 9.61194C4.26838 9.61194 2.61707 11.2633 2.61707 13.3003C2.61707 15.3373 4.26838 16.9886 6.30539 16.9886Z" stroke="#03071E" stroke-opacity="0.8" stroke-miterlimit="10" />
<path id="Vector_4" d="M6.3054 17.8913C9.25697 17.8913 11.656 20.2881 11.656 23.2419V26.3754H0.954834V23.2419C0.954834 20.2903 3.35156 17.8913 6.3054 17.8913Z" stroke="#03071E" stroke-opacity="0.8" stroke-miterlimit="10"/>
<path id="Vector_5" d="M24.6947 16.9886C26.7317 16.9886 28.383 15.3373 28.383 13.3003C28.383 11.2633 26.7317 9.61194 24.6947 9.61194C22.6577 9.61194 21.0063 11.2633 21.0063 13.3003C21.0063 15.3373 22.6577 16.9886 24.6947 16.9886Z" stroke="#03071E" stroke-opacity="0.8" stroke-miterlimit="10" />
<path id="Vector_6" d="M24.6946 17.8913C27.6462 17.8913 30.0452 20.2881 30.0452 23.2419V26.3754H19.3486V23.2419C19.3486 20.2903 21.7454 17.8913 24.6992 17.8913H24.6969H24.6946Z" stroke="#03071E" stroke-opacity="0.8" stroke-miterlimit="10"  />
</g>
</g>
</g>
</g>
<defs>
<clipPath id="clip0_19155_5006">
<rect width="30" height="30" fill="white" transform="translate(0.5)"/>
</clipPath>
</defs>
</svg>
);

const PeopleActive = ({ svgColor }) => (
  <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g id="Contacts-Active 1" clip-path="url(#clip0_19155_1128)">
  <g id="Contacts_-_Active">
  <g id="d">
  <g id="Group">
  <path id="Vector" d="M15.4989 13.6389C18.2605 13.6389 20.4993 11.4002 20.4993 8.63856C20.4993 5.87693 18.2605 3.63818 15.4989 3.63818C12.7373 3.63818 10.4985 5.87693 10.4985 8.63856C10.4985 11.4002 12.7373 13.6389 15.4989 13.6389Z" stroke="#711FFF" stroke-miterlimit="10"/>
  <path id="Vector_2" d="M15.4989 14.8667C19.5033 14.8667 22.755 18.1184 22.755 22.1228V26.3751H8.24048V22.1228C8.24048 18.1184 11.4922 14.8667 15.4966 14.8667H15.4989V14.8667Z" stroke="#711FFF" stroke-miterlimit="10"/>
  </g>
  <g id="Group_2">
  <path id="Vector_3" d="M6.30551 16.9885C8.34251 16.9885 9.99383 15.3371 9.99383 13.3001C9.99383 11.2631 8.34251 9.61182 6.30551 9.61182C4.2685 9.61182 2.61719 11.2631 2.61719 13.3001C2.61719 15.3371 4.2685 16.9885 6.30551 16.9885Z" stroke="#711FFF" stroke-miterlimit="10"/>
  <path id="Vector_4" d="M6.3054 17.8911C9.25697 17.8911 11.656 20.2878 11.656 23.2417V26.3752H0.954834V23.2417C0.954834 20.2901 3.35156 17.8911 6.3054 17.8911Z" stroke="#711FFF" stroke-miterlimit="10"/>
  <path id="Vector_5" d="M24.6947 16.9885C26.7317 16.9885 28.383 15.3371 28.383 13.3001C28.383 11.2631 26.7317 9.61182 24.6947 9.61182C22.6577 9.61182 21.0063 11.2631 21.0063 13.3001C21.0063 15.3371 22.6577 16.9885 24.6947 16.9885Z" stroke="#711FFF" stroke-miterlimit="10"/>
  <path id="Vector_6" d="M24.6946 17.8911C27.6462 17.8911 30.0452 20.2878 30.0452 23.2417V26.3752H19.3486V23.2417C19.3486 20.2901 21.7454 17.8911 24.6992 17.8911H24.6969H24.6946Z" stroke="#711FFF" stroke-miterlimit="10"/>
  </g>
  </g>
  </g>
  </g>
  <defs>
  <clipPath id="clip0_19155_1128">
  <rect width="30" height="30" fill="white" transform="translate(0.5)"/>
  </clipPath>
  </defs>
  </svg>
  
);

export {People, PeopleActive, Timer }