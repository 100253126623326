import { createAsyncThunk } from '@reduxjs/toolkit';
import { ENDPOINT } from '../Opportunity/Redux/oppor';

const GET_WORKTYPES = 'netnet/Redux/GET_WORKTYPES';
const GET_COLLABS = 'netnet/Redux/GET_COLLABS';
const GET_TEMPLATES = 'netnet/Redux/GET_TEMPLATES';
const GET_NOTIFICATIONS = 'netnet/Redux/GET_NOTIFICATIONS';
const GET_SUBSCRIPTIONS = 'netnet/Redux/GET_SUBSCRIPTIONS';


const getNotifications = createAsyncThunk(
  GET_NOTIFICATIONS,
  async (meta, thunkAPI) => {
    const response = await fetch(`${ENDPOINT}/api/superadmin/notification/list/`, {
      method: 'GET',
      headers: {
        Authorization: `JWT ${meta.access_token}`,
        'Content-Type': 'application/json',
      },
    });
    const data = await response.json();
    if (!response.ok) {
      return thunkAPI.rejectWithValue(data);
    }
    return data;
  }
);

const getTemplates = createAsyncThunk(GET_TEMPLATES, async (meta, thunkAPI) => {

  const tempArr = [];
  const res = await fetch(
    `${ENDPOINT}/api/project/deliverable/template/list/`,
    {
      method: 'GET',
      headers: {
        Authorization: `JWT ${meta.access_token}`,
      },
    }
  );
  const data = await res.json();
  if (!res.ok) {
    return thunkAPI.rejectWithValue(data);
  }
  return data
});




const getCollabs = createAsyncThunk(GET_COLLABS, async (meta, thunkAPI) => {
  const res = await fetch(`${ENDPOINT}/api/opportunity/list/collaborator/`, {
    method: 'GET',
    headers: {
      Authorization: `JWT ${meta.access_token}`,
    },
  });
  const data = await res.json();
  if (!res.ok) {
    thunkAPI.rejectWithValue(data);
  }
  return data;
});

const getWorkTypes = createAsyncThunk(GET_WORKTYPES, async (meta, thunkAPI) => {
  const response = await fetch(`${ENDPOINT}/api/opportunity/worktype/list/`, {
    method: 'GET',
    headers: {
      Authorization: `JWT ${meta.access_token}`,
    },
  });
  const data = await response.json();
  if (!response.ok) {
    thunkAPI.rejectWithValue(data);
  }
  const filteredData = data.filter((item) => item.worktype_for_list);
  return filteredData;
});

const initialState = {
  workTypes: [],
  collabs: [],
  notifications: [],
  mainTemplates: [],
  loading: false,
};

export { getWorkTypes, getCollabs, getTemplates, getNotifications };

const MiscReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${GET_WORKTYPES}/pending`:
      return {
        ...state,
        loading: true,
      };

    case `${GET_WORKTYPES}/fulfilled`:
      return {
        ...state,
        workTypes: action.payload,
      };

    case `${GET_WORKTYPES}/rejected`:
      return {
        ...state,
        error: action.payload,
      };

    case `${GET_COLLABS}/pending`:
      return {
        ...state,
        loading: true,
      };

    case `${GET_COLLABS}/fulfilled`:
      return {
        ...state,
        collabs: action.payload,
      };

    case `${GET_COLLABS}/rejected`:
      return {
        ...state,
        error: action.payload,
      };

    case `${GET_TEMPLATES}/pending`:
      return {
        ...state,
        loading: true,
      };

    case `${GET_TEMPLATES}/fulfilled`:
      return {
        ...state,
        mainTemplates: action.payload,
      };

    case `${GET_TEMPLATES}/rejected`:
      return {
        ...state,
        error: action.payload,
      };

    case `${GET_NOTIFICATIONS}/rejected`:
      return {
        ...state,
        error: action.payload,
      };

    case `${GET_NOTIFICATIONS}/pending`:
      return {
        ...state,
        loading: true,
      };

    case `${GET_NOTIFICATIONS}/fulfilled`:
      return {
        ...state,
        loading: false,
        notifications: action.payload,
      };
    default:
      return state;
  }
};

export default MiscReducer;
