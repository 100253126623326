import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import filterIcon from '../assets/sort 1.svg'

const CoursesHeader = () => {
  return (
    <div
      style={{
        position: "relative",
        backgroundColor: "#e9ecef",
        width: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        padding: " 1% 1.38% 1% 1.38%",
        boxSizing: "border-box",
        textAlign: "center",
        fontSize: "29px",
        color: "#03071e",
        fontFamily: "'Open Sans'",
        marginBottom: "26px",
        backgroundColor: "white",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
          gap: "20px",
        }}
      >
        <b style={{ position: "relative", lineHeight: "100%" }}>
          Net Net U Courses
        </b>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            textAlign: "left",
            fontSize: "14px",
            color: "rgba(3, 7, 30, 0.4)",
          }}
        >
          <div
            style={{
              borderRadius: "4px",
              overflow: "hidden",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              gap: "3px",
            }}
          >
            <img
              style={{
                position: "relative",
                width: "18px",
                height: "18px",
                overflow: "hidden",
                flexShrink: "0",
              }}
              alt=""
              src={filterIcon}
            />
            <div
              style={{
                position: "relative",
                letterSpacing: "-0.3px",
                lineHeight: "19px",
              }}
            >
              Filters
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          padding: "10px",
          fontSize: "14px",
          color: "#fff",
        }}
      >
        <Link to="/SuperAdmin/NetNetU/Courses/New">
          <Button
            sx={{
              width: 108,
              height: 35,
              textTransform: "capitalize",
              fontWeight: "300",
              backgroundColor: "#711FFF",
              lineHeight: "19px",
              fontSize: "14px",
              "&:hover": {
                backgroundColor: "#711DDD",
              },
            }}
            variant="contained"
          >
            + New
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default CoursesHeader;
