import './MentionList.css'

import React, {
    forwardRef, useEffect, useImperativeHandle,
    useState,
} from 'react'

export default forwardRef((props, ref) => {
    const [selectedIndex, setSelectedIndex] = useState(0)
    const selectItem = index => {
        const item = `${props.text === '@' ? '@' : ''}${props.items[index]}`;
        const { state, dispatch } = props.editor.view;
        const { from } = state.selection;
        const { schema } = state;

        if (item) {
            if (props.command) {
                props.command({ id: item })
            }
            else {
                const tr = state.tr.insertText(`${props.dilName+' '+item}! `, from, from);
                dispatch(tr);
                const popups = document.querySelectorAll('.items');

                // Loop through each popup and hide it
                popups.forEach((popup) => {
                    // Set the display style to 'none' to hide the popup
                    popup.style.display = 'none';
                });
                props.editor.view.focus();
            }
        }
    }

    const upHandler = () => {
        setSelectedIndex((selectedIndex + props.items.length - 1) % props.items.length)
    }

    const downHandler = () => {
        setSelectedIndex((selectedIndex + 1) % props.items.length)
    }

    const enterHandler = () => {
        selectItem(selectedIndex)
    }

    useEffect(() => setSelectedIndex(0), [props.items])

    useImperativeHandle(ref, () => ({
        onKeyDown: ({ event }) => {
            if (event.key === 'ArrowUp') {
                upHandler()
                return true
            }

            if (event.key === 'ArrowDown') {
                downHandler()
                return true
            }

            if (event.key === 'Enter') {
                enterHandler()
                return true
            }

            return false
        },
    }))

    return (
        <div className="items">
            {props.items.length
                ? props.items.map((item, index) => (
                    <button
                        className={`item ${index === selectedIndex ? 'is-selected' : ''}`}
                        key={index}
                        onClick={() => selectItem(index)}
                    >
                        {item}
                    </button>
                ))
                : <div className="item">No result</div>
            }
        </div>
    )
})