import React, { useEffect, useRef, useState } from "react";
import "./addLesson.css";
import YouTubeLink2 from "./YouTubeLink2";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Quill from "quill";
import "quill/dist/quill.snow.css";


const AddLesson = () => {

  const [formData, setFormData] = useState({
    title: "",
    description: "",
    youtube_link: "",
    youtube_id: "",
    duration: "",
  });

  const quillRef = useRef(null);

  useEffect(() => {
if (!quillRef.current) {
  const editor = new Quill("#lesson-description-editor", {
    theme: "snow",
    placeholder: "Enter Your Video Description",
    modules: {
      toolbar: [
        ["bold", "italic", "underline"],
        ["link"],
        [{ list: "ordered" }, { list: "bullet" }, { align: [] }],
      ],
    },
  });

  editor.on("text-change", () => {
    setFormData({ ...formData, description: editor.root.innerHTML });
  });

  quillRef.current = editor;
}
}, []);

  return (
    <>
      <div className="lessonWrapper">
        <div className="lessonTitleNew">
          <span>1.1 Basic Information about Net Net as a Whole</span>
          <p style={{ marginTop: 7 }}>Please Add your Lessons</p>
        </div>
        <div
          className="addLesson"
          style={{
            width: "100%",
            position: "relative",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "7px",
            textAlign: "left",
            fontSize: "20px",
            color: "#000",
            fontFamily: "'Open Sans'",
          }}
        >
          <b style={{ position: "relative", lineHeight: "130%" }}>
            Add Your Lessons
          </b>
        </div>
        <div className="lessonForm">
          {" "}
          <div className="courseInfo courseInfoLesson">
          <div className="leftInfo lessonInputs">
              <TextField
                sx={{
                  borderRadius: "4px",
                  borderColor: "#d9d9d9",
                  marginBottom: "44px"
                }}
                value={formData.title}
                onChange={(e) =>
                  setFormData({ ...formData, title: e.target.value })
                }
                fullWidth
                label="Enter Your Lesson Title"
                variant="outlined"
              />
              <div
                  className="textEditor"
                  id="lesson-description-editor"
                  style={{
                    borderBottomLeftRadius: 10,
                    borderBottomRightRadius: 10,
                    height: "149px"
                  }}
                >
                  <div className="editor"></div>
                  </div>
            </div>
            <div className="rightInfo lessonRight">
              <YouTubeLink2 formData={formData} setFormData={setFormData} />
            </div>
          </div>
        </div>
        <div className="actionButtons">
          <Button
            sx={{
              color: "#000",
              fontSize: 14,
              textTransform: "capitalize",
              fontWeight: "bold",
              "&:hover": {
                backgroundColor: "#F2EBFF",
                color: "black",
              },
            }}
          >
            Downloads
          </Button>
          <Button
            sx={{
              color: "#711FFF",
              "&:hover": {
                backgroundColor: "#F2EBFD",
                color: "#711DDD",
              },
              backgroundColor: "#F2EBFF",
              width: "130",
              fontSize: 14,
              fontWeight: "bold",
            }}
          >
            + ADD Files
          </Button>
        </div>
      </div>
    </>
  );
};

export default AddLesson;
