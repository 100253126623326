import { createAsyncThunk } from '@reduxjs/toolkit';
import { ENDPOINT } from '../Opportunity/Redux/oppor';
const GET_COURSES = 'netnet/Redux/GET_COURSES';
const GET_CHAPTERS = 'netnet/Redux/GET_Chapters';

const initialState = {
  courses: [],
  loading: false,
  chapters: [],
  error: null,
};

export const getCourses = createAsyncThunk(GET_COURSES, async (meta) => {
  const res = await fetch(`${ENDPOINT}/api/netnetu/courselist/`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `JWT ${meta.access_token}`,
    },
  });
  const data = await res.json();
  if (!res.ok) {
  } else {
    return data;
  }
});

export const getchapters = createAsyncThunk(GET_CHAPTERS, async (meta) => {
  const res = await fetch(`${ENDPOINT}/api/netnetu/chapterlist/`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `JWT ${meta.access_token}`,
    },
  });
  const data = await res.json();
  if (!res.ok) {
  } else {
    return data;
  }
});

const netReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${GET_COURSES}/pending`:
      return {
        ...state,
        loading: true,
      };
    case `${GET_COURSES}/fulfilled`:
      return {
        ...state,
        loading: false,
        courses: action.payload,
      };
    case `${GET_COURSES}/rejected`:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case `${GET_CHAPTERS}/pending`:
      return {
        ...state,
        loading: true,
      };
    case `${GET_CHAPTERS}/fulfilled`:
      return {
        ...state,
        loading: false,
        chapters: action.payload,
      };
    case `${GET_CHAPTERS}/rejected`:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default netReducer;
