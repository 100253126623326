import React, { useState, useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import Header from './Header';
import { useSelector } from 'react-redux';
import { Box, TextField, InputAdornment } from '@mui/material';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getPersons } from '../../pages/Contacts/ContactRedux';
import { IoIosSearch } from "react-icons/io";
import { IoIosAddCircleOutline } from "react-icons/io";
import { useNavigate } from 'react-router-dom';

function ListPeople() {
  const [data, setData] = React.useState([]);
  const dispatch = useDispatch()
  const access_token = useSelector((state) => state.auth.user.access);

  useEffect(() => {
    const meta = {
      access_token,
    }
    dispatch(getPersons(meta))
  }, [])
  const realData = useSelector((state) => state.Contacts.persons);

  useEffect(() => {
    if (Array.isArray(realData) && realData.length) {
      const myData = realData.map((item) => {
        return {
          id: item.id,
          name: item.first_name + ' ' + item.last_name,
          Tittle: item.company,
          unique_id: item.unique_id
        };
      });
      setData(myData);
    }
  }, [realData]);

  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const handleResize = () => {
    setScreenSize(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const topTitleStyling = {
    backgroundColor: '#7b2eff',
    width: '100%',
    padding: '10px',
    color: 'white',
    fontWeight: 'bold',
    fontFamily: 'sans-serif',
    alignItems: 'center',
    marginBottom: '10px',
    borderRadius: '0px 0px 15px 15px'
  };

  const dataRenderingStyling = {
    allContentDiv: {
      display: 'flex',
      flexDirection: 'row',
      width: '95%',
      height: '70px',
      alignContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      justifyContent: 'space-between',
      margin: '0 auto',
      borderRadius: '8px',
      background: 'var(--White-White-100, #FFF)',
      boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.25)',
    },

    picAndTextDiv: {
      display: 'flex',
      flexDirection: 'row',
      alignContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
    },
  };

  /////////////////////////////////////////////////////////////////////

  const [tabs, setTabs] = useState('People')

  const handleChangeTabs = (e) => {
    setTabs(e)
  }

  const navigate = useNavigate();


  return (
    <div style={{ width: screenSize, backgroundColor: 'white', height: '100vh' }}>
      <div>
        <Header />
      </div>

      <div style={topTitleStyling}>
        <p style={{ alignSelf: 'center', textAlign: 'center' }}>CONTACTS</p>
        <Box sx={{ display: "flex", justifyContent: 'center', alignItems: 'center', mt: 1 }}>
          <Button size="large" onClick={() => handleChangeTabs('People')} sx={{
            color: 'black', // Custom text color
            background: tabs === 'People' ? 'white' : 'var(--Violet-Violet-900, #F2EBFF)',
            borderRadius: '10px 0px 0px 10px',
            width: "150px",
            fontFamily: 'open-sans',

            '&:hover': {
              backgroundColor: 'white', // Custom hover color
            },
            '&.Mui-focusVisible': {
              backgroundColor: 'darkblue', // Custom focus color
            },
            '&.Mui-selected': {
              backgroundColor: 'green', // Custom selected color
            },
          }}>
            PEOPLE
          </Button>
          <Button size="large" onClick={() => handleChangeTabs('Contact')} sx={{
            color: 'black', // Custom text color
            background: tabs === 'Contact' ? 'white' : 'var(--Violet-Violet-900, #F2EBFF)',
            borderRadius: '0px 10px 10px 0px',
            width: "150px",
            fontFamily: 'open-sans',

            '&:hover': {
              backgroundColor: 'white', // Custom hover color
            },
            '&.Mui-focusVisible': {
              backgroundColor: 'darkblue', // Custom focus color
            },
            '&.Mui-selected': {
              backgroundColor: 'green', // Custom selected color
            },
          }}>
            COMPANIES
          </Button>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

          <TextField
            id="outlined-basic"
            variant="outlined"
            sx={{
              width: "300px",
              backgroundColor: 'white',
              borderRadius: '10px',
              border: 'none !important',
              outline: 'none !important',
              mt: 2,
              fontFamily: 'open-sans',

              '& .css-1o9s3wi-MuiInputBase-input-MuiOutlinedInput-input': { padding: '0px', backgroundColor: "white" },
              '& .MuiInputAdornment-root': { marginLeft: '10px', fontSize: '20px' },
              '& .css-1q6at85-MuiInputBase-root-MuiOutlinedInput-root': { border: "2px solid red !important " },
              '& .MuiInputBase-root': {
                height: '36px',
                px: 2,
                outline: 'none !important',
                outlineStyle: 'none !important',
                border: 'none !important',
                '&:hover': {
                  borderColor: 'none !important', // Remove border on hover
                  outline: 'none !important',
                  outlineStyle: 'none !important',
                  // backgroundColor:'red',
                },
                '&.Mui-focused': {
                  borderColor: 'transparent !important', // Remove border on focus
                },
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IoIosSearch style={{}} />
                </InputAdornment>
              ),
              inputProps: {
                style: {
                  '&:hover': {
                    outline: 'none !important', // Remove outline on hover
                  },
                  '&:focus': {
                    outline: 'none !important', // Remove outline on focus
                  },
                },
              },
            }}
          />
        </Box>
      </div>

      {tabs === 'People' &&
        <Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mx: 2 }}>

            <Box sx={{ borderBottom: '2px solid black' }}>
              <Typography sx={{ fontSize: "14px", fontWeight: 600, fontFamily: 'open-sans' }}>All People</Typography>
            </Box>

            <Button
              size="large"
              variant='contant'
              startIcon={<IoIosAddCircleOutline style={{ color: '#711FFF' }} />} // Add the start icon
              sx={{
                color: 'black', // Custom text color
                borderRadius: '6px',
                border: 'var(--none, 1.029px) solid var(--Functions-Tab-Stroke, #D9D9D9)',
                background: 'var(--Violet-Violet-900, #F2EBFF)',
                fontFamily: 'open-sans',
                '&:hover': {
                  backgroundColor: '#F2EBFF', // Custom hover color
                },
                '&.Mui-focusVisible': {
                  backgroundColor: 'darkblue', // Custom focus color
                },
                '&.Mui-selected': {
                  backgroundColor: 'green', // Custom selected color
                },
              }}
            >
              ADD NEW
            </Button>
          </Box>
          <div className='ScrollShow' style={{ overflow: 'auto', height: '100vh' }}>
            <div style={{ marginBottom: '200px' }}>
              {
                Array.isArray(data) && data.length
                  ? data.map((v, i) => {
                    return (
                      <div style={{ marginTop: '15px', }}>
                        <div style={dataRenderingStyling.allContentDiv}>
                          <div style={dataRenderingStyling.picAndTextDiv}>
                            <div style={{ marginLeft: '25px' }}>
                              <Avatar
                                alt="Remy Sharp"
                                src="/static/images/avatar/1.jpg"
                                style={{
                                  width: '40px',
                                  height: '40px',
                                  marginRight: '20px',
                                }}
                              />
                            </div>

                            <div
                              style={{ textAlign: 'start', fontFamily: 'open-sans' }}
                            >
                              <p key={i}> {v.name}</p>

                              <p key={i} style={{ color: '#7b2eff', marginTop: '0px' }}>
                                {v.Tittle}
                              </p>
                            </div>
                          </div>
                          <div style={{ marginRight: '25px' }}>
                          </div>
                        </div>
                      </div>
                    );
                  })
                  :
                  <div style={{ width: '100%', height: '600px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', }}>
                    <Card sx={{ width: '90%', boxShadow: 'none' }}>
                      <CardContent>
                        <Typography variant="h5" component="div">
                          Whoopsy... You don't have any People Jut yet!
                          <br />
                        </Typography>
                        <Typography variant="body2" sx={{ fontSize: '14px', color: 'gray' }}>
                          That's okay, you can easily add one at a time or many through the import process. Let's get started, eh?.
                        </Typography>
                      </CardContent>
                      <Link to='/New_Person'>
                        <CardActions>
                          <Button size="large" sx={{
                            width: '80%',
                            margin: '0 auto',
                            backgroundColor: '#1c04bd',
                            color: 'whitesmoke',
                            borderRadius: '10px', fontWeight: 'bold', fontFamily: 'open Sans'
                          }}>
                            NEW PERSON
                          </Button>
                        </CardActions>
                      </Link>
                    </Card>
                  </div>
              }
            </div>
          </div>
        </Box>
      }
      {tabs === 'Contact' &&
        <Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mx: 2 }}>
            <Box sx={{ borderBottom: '2px solid black' }}>
              <Typography sx={{ fontSize: "14px", fontWeight: 600 }}>All Companies</Typography>
            </Box>
            <Button
              size="large"
              variant='contant'
              startIcon={<IoIosAddCircleOutline style={{ color: '#711FFF' }} />} // Add the start icon
              sx={{
                color: 'black', // Custom text color
                borderRadius: '6px',
                border: 'var(--none, 1.029px) solid var(--Functions-Tab-Stroke, #D9D9D9)',
                background: 'var(--Violet-Violet-900, #F2EBFF)',
                '&:hover': {
                  backgroundColor: '#F2EBFF', // Custom hover color
                },
                '&.Mui-focusVisible': {
                  backgroundColor: 'darkblue', // Custom focus color
                },
                '&.Mui-selected': {
                  backgroundColor: 'green', // Custom selected color
                },
              }}
            >
              ADD NEW
            </Button>
          </Box>
        </Box>
      }
    </div>
  );
}

export default ListPeople;
