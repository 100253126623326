import React from "react";
import "./netnetU.css";
import Gear from "./assets/Gear.png";
import Dots from "./assets/Dots.png";
import { Link } from "react-router-dom";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useSelector } from "react-redux";
import VideoThumbnail from "../../Componenets/VideoThumbnail";
const AllLesson = () => {

  const course = useSelector((state) => state.NetNetU.courses);
  return (
    <>
      <section className="oppor-section">
        <div className="oppor-row">
          <div className="oppor-col">
            <h3 className="fstxtPro">All Courses</h3>
            <img className="icon-sm" src={Dots} alt="dots" />
            <img className="icon-sm" src={Gear} alt="gear" />
          </div>
        </div>
        <div className="btn-group btn-group-deliverable">
          <Link to={"/netnet_U"}>NeNet U</Link>
          <ChevronRightIcon />
          <Link to={"/All_Courses"}>All Courses</Link>
        </div>
        <Box sx={{ flexGrow: 1, marginTop: "30px" }}>
          <Grid container gap="2rem">
            {course.map((item) => {
              return (
                <Grid item  sx={{
                }}>
                  <VideoThumbnail
                    thumbnail={item.chapters[0]?.lessons[0]?.thumbnail}
                    subTitle={item.title}
                    title={item.short_description}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </section>
    </>
  );
};

export default AllLesson;
