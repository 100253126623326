import { Grid, Box, TextField, Alert, Typography } from '@mui/material';
import { React, useEffect, useState, useRef } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useDispatch } from 'react-redux';
import { getWorkTypes } from './redux';
import { useSelector } from 'react-redux';
import { ENDPOINT } from '../Opportunity/Redux/oppor';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import MenuList from '@mui/material/MenuList';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import HelpVideo from '../../Componenets/TopNav/HelpVideo';
import helpVid from '../Opportunity/assets/Section_Help-idle.svg'
import Bounce from 'react-reveal/Bounce';

const IconComp = ({ index, workTypes, setWorkTypes, data, access_token }) => {
  const [open, setOpen] = useState(false);

  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);
  const dispatch = useDispatch()
  return (
    <>
      <MoreHorizIcon
        ref={anchorRef}
        id="composition-button"
        aria-controls={open ? 'composition-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        sx={{ color: 'GrayText', fontSize: '30px', cursor: 'pointer' }}
      />

      <div>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-start"
          transition
          disablePortal
          sx={{
            '& .css-1ps6pg7-MuiPaper-root': {
              backgroundColor: '#fff',
              color: 'rgba(0, 0, 0, 0.87)',
              boxShadow: 'none',
              borderRadius: '10px',
            },
          }}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom-start' ? 'left top' : 'left bottom',
              }}
            >
              <Paper sx={{zIndex: 'modal',borderRadius:'10px'}}>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                    onKeyDown={handleListKeyDown}
                    sx={{
                      width: {
                        xl: '180px',
                        lg: '120px',
                      },
                      boxShadow: 'none',
                      border: '0.5px solid #9f9c9cde',
                      borderRadius: '10px',
                    }}
                  >
                    <MenuItem
                      onClick={() => {
                        const shallowCopy = workTypes.map((item, i) => ({
                          ...item,
                          isEditing: i === index ? true : item.isEditing,
                        }));
                        setWorkTypes(shallowCopy);
                        handleClose()
                      }}
                      sx={{
                        color: 'gray',
                        display: 'flex',
                        gap: { xl: '1rem', lg: '.5rem' },
                        marginBottom: '1rem',
                      }}
                    >
                      <BorderColorIcon /> Edit
                    </MenuItem>
                    <MenuItem
                      onClick={async (e) => {
                        handleClose(e)
                        const res = fetch(`${ENDPOINT}/api/opportunity/worktype/delete/${data.id}/`, {
                          method: 'DELETE',
                          headers: {
                            'Content-Type': 'application/json',
                            Authorization: `JWT ${access_token}`,
                          }
                        })
                        const Response = await res
                        if (Response.ok) {
                          dispatch(getWorkTypes({
                            access_token
                          }))
                        }
                        else {
                        }
                      }}
                      sx={{
                        color: 'red',
                        display: 'flex',
                        gap: { xl: '1rem', lg: '.5rem' },
                      }}
                    >
                      {' '}
                      <DeleteOutlineIcon /> Delete
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </>
  );
};

const Switcher = ({ view, setView, setSearchQuery }) => {
  const act = {
    color: '#711fff',
    fontWeight: '500',
    fontSize: '1rem',
  };

  const inAct = {
    color: 'GrayText',
    fontWeight: '400',
    fontSize: '1rem',
  };
  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      sx={{ marginTop: '1.5rem' }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: { xl: '27%', lg: '35%' },
          padding: '0rem 1rem',
        }}
      >
        <button
          className="noBorder"
          style={view === 'my_work_type' ? act : inAct}
          onClick={() => setView('my_work_type')}
        >
          My Work Types
        </button>
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '0.5rem',
          width: '30%',
        }}
      >
        <SearchIcon
          style={{ curser: 'pointer' }}
          onClick={() => {
            setSearchField('button');
          }}
        />
        <TextField
          id="standard-basic"
          variant="standard"
          placeholder="Search"
          sx={{ padding: '0px', width: '100%' }}
          onChange={(e) => {
            setSearchQuery(e.target.value);
          }}
        />
      </Box>
    </Grid>
  );
};

const AddWorkType = ({ searchQuery,}) => {
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    name: '',
    customer_rate: '',
    worktype_id: ''
  });

  function clearFormData() {
    setFormData({
      name: '',
      user_rate: '',
      worktype_id: ''
    });
  }

  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (isError) {
      setTimeout(() => {
        setIsError(false);
        setErrorMessage('');
      }, 4000);
    }
  }, [isError]);

  const access_token = useSelector((state) => state.auth.user.access);

  const handleSubmittion = async (e) => {
    if (!formData.name || !formData.user_rate) {
      setIsError(true);
      setErrorMessage('Work type name and price is required');
      return;
    }
    e.preventDefault();
    const res = await fetch(`${ENDPOINT}/api/opportunity/worktype/create/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `JWT ${access_token}`,
      },
      body: JSON.stringify(formData),
    });
    const data = await res.json();

    if (!res.ok) {
      setIsError(true);
      setErrorMessage('Something went wrong creating your WorkType');
      return;
    }
    clearFormData();
    dispatch(getWorkTypes({ access_token }));
  };

  useEffect(() => {
    const meta = {
      access_token,
    };
    dispatch(getWorkTypes(meta));
  }, []);


  return (
    <>
      <Box
        sx={{
          backgroundColor: '#f8f9fa',
          padding: '0.88rem 1.44rem',
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          borderRadius: '8px',
          marginTop: "2rem",
          position: "relative"
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            width: '100%',
            flexDirection: 'column',
          }}
        >
          <Typography
            sx={{
              fontSize: { xl: '19px', lg: '16px' },
              color: '#000',
              fontWeight: 'bold',
            }}
            className="fstxt"
          >
            Add New
          </Typography>
          <Typography
            sx={{ fontSize: { xl: '15px', lg: '13px' }, color: 'gray' }}
          >
            Add Your Work Type and Rate
          </Typography>
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: { xl: '10rem', lg: '3rem' },
            width: '100%',
          }}
        >
          <Box sx={{ width: '150px' }}>
            <TextField
              placeholder="Work Type Name"
              variant="standard"
              value={formData.name}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  name: e.target.value,
                })
              }
              sx={{
                borderBottom: '1px dashed gray',
                '& .MuiInputBase-input': {
                  fontSize: '1rem',
                  fontFamily: 'Open Sans',
                  fontWeight: '500',
                  color: '#000000',
                  textAlign: 'center',
                },
                '& .MuiInput-underline:before': {
                  borderBottom: '1px dashed #fff',
                },
                '& .MuiInput-underline:after': {
                  borderBottom: '1px dashed #000000',
                },
                '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                  borderBottom: '1px dashed #000000',
                },
              }}
              InputProps={{
                style: { width: 150 },
              }}
            />
          </Box>
          <Box sx={{ width: '150px' }}>
            <TextField
              placeholder="Add ID SKU"
              variant="standard"
              value={formData.worktype_id}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  worktype_id: e.target.value
                })
              }}
              sx={{
                borderBottom: '1px dashed gray',
                '& .MuiInputBase-input': {
                  fontSize: '1rem',
                  fontFamily: 'Open Sans',
                  fontWeight: '500',
                  color: '#000000',
                  textAlign: 'center',
                },
                '& .MuiInput-underline:before': {
                  borderBottom: '1px dashed #fff',
                },
                '& .MuiInput-underline:after': {
                  borderBottom: '1px dashed #000000',
                },
                '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                  borderBottom: '1px dashed #000000',
                },
              }}
              InputProps={{
                style: { width: 150 },
              }}
            />
          </Box>

          <Box
            sx={{
              backgroundColor: '#fff',
              display: 'flex',
              alignItems: 'center',
              borderRadius: '8px',
              width: { xl: '18%', lg: '25%' },
              border: '.4px solid #e6ebf1',
            }}
          >
            <Box
              sx={{
                width: '50%',
                backgroundColor: '#fff',
                borderEndStartRadius: '8px',
                borderStartStartRadius: '8px',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <input
                placeholder="Enter Value"
                variant="standard"
                style={{
                  border: 'none',
                  outline: 'none',
                  width: '80%',
                  padding: '0.7rem 0rem',
                  '& .MuiInputBase-input': {
                    fontSize: '1rem',
                    fontFamily: 'Open Sans',
                    fontWeight: '500',
                    color: '#000000',
                    textAlign: 'center',
                  },
                  '& .MuiInput-underline:before': {
                    borderBottom: '1px dashed #fff',
                  },
                  '& .MuiInput-underline:after': {
                    borderBottom: '1px dashed #000000',
                  },
                  '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                    borderBottom: '1px dashed #000000',
                  },
                }}
                value={formData.user_rate}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    user_rate: e.target.value,
                  })
                }
              />
            </Box>
            <Box
              sx={{
                borderEndEndRadius: '8px',
                borderStartEndRadius: '8px',
                width: '50%',
                textAlign: 'center',
                fontWeight: 'bold',
                backgroundColor: '#e9ecef',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              USD / Hour
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            mt: '1rem',
          }}
        >
          <button
            className="noBorder"
            style={{
              textAlign: 'center',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: '#8f51f9',
              padding: '0.55rem 1.8rem',
              borderRadius: '6px',
              color: '#fff',
              fontWeight: '600',
              fontSize: '1rem',
            }}
            onClick={handleSubmittion}
          >
            Add
          </button>
        </Box>
        {isError && <Box sx={{position: "absolute", width: "100%", top: 0, left: 0}}> <Alert severity="error">{errorMessage}</Alert></Box>}
      </Box>
    </>
  );
};

const WorkTypeList = ({ searchQuery, screenHeight }) => {
  const dispatch = useDispatch();
  const access_token = useSelector((state) => state.auth.user.access);
  const data = useSelector((state) => state.Misc.workTypes);
  useEffect(() => {
    const meta = {
      access_token,
    };
    dispatch(getWorkTypes(meta));
  }, []);

  const [WorkTypes, setWorkTypes] = useState([]);
  const filtered =
    WorkTypes &&
    WorkTypes.filter((item) =>
      item.name.toLowerCase().startsWith(searchQuery.toLowerCase())
    );
  const realwork = searchQuery ? filtered : WorkTypes;

  realwork.sort((a, b) => {
    // Assuming 'created_at' is in ISO date string format
    return new Date(a.created_at) - new Date(b.created_at);
  }).reverse();

  useEffect(() => {
    const types = data.map((item) => ({
      ...item,
      isEditing: false
    }));
    setWorkTypes(types)
  }, [data]);


  return Array.isArray(realwork) && realwork.length ? (
    <TableContainer
      //  component={Paper}
      sx={{
        width: '100%',
        marginTop: '1rem',
        minHeight: screenHeight - 470,
        maxHeight: screenHeight - 470,
        borderRadius: '8px',
        overflow: 'auto', scrollbarWidth: "none",   border: '1.5px solid lightGray',
      }}
    >
      <Table
        stickyHeader
        aria-label="sticky table"
        sx={{
          borderRadius: "8px",
          backgroundColor: '#fff',
        }}

      >
        <TableHead sx={{
          backgroundColor: '#f2f2f2',
          zIndex:'-1 !important'

        }} >
          <TableRow
            sx={{
              height: '75px',
              backgroundColor: '#f2f2f2',
            }}
          >
            <TableCell
              sx={{
                color: 'GrayText',
                fontSize: '15px',
                backgroundColor: '#f2f2f2',

              }}
              className="fstxt"
            >
              Work Type ID
            </TableCell>
            <TableCell
              sx={{
                color: 'GrayText',
                fontSize: '15px',
                backgroundColor: '#f2f2f2',

              }}
              className="fstxt"
            >
              Work Type Name
            </TableCell>
            <TableCell
              sx={{
                color: 'GrayText',
                fontSize: '15px',
                backgroundColor: '#f2f2f2',
              }}
              className="fstxt"
            >
              Work Type Rates
            </TableCell>
            <TableCell sx={{
              backgroundColor: '#f2f2f2',
            }}></TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {Array.isArray(realwork) && realwork.length ? (
            realwork.map((item, index) => (
              <TableRow
                sx={{
                  height: '70px',
                }}
                hover
                key={index + 96}
              >
                <TableCell sx={{
                  fontSize: '15px', 
                }}>
                  {!item.isEditing ? (
                    item.worktype_id ? item.worktype_id : `NetWt${item.id}`)
                    :
                    <input onChange={(e) => {
                      if (item.worktype_id === null) {
                        return;
                      }
                      else {
                        setWorkTypes((prev) => {
                          const shallowCopy = prev.map((item) => Object.assign({}, item))
                          shallowCopy[index].worktype_id = e.target.value
                          return shallowCopy
                        })
                      }
                    }}
                      value={item.worktype_id ? item.worktype_id : item.id} style={{ border: 'none', outline: 'none', borderBottom: '1px dashed gray', color: '#000', fontSize: '15px', background: 'none', textAlign: 'center', width: '50%' }} />
                  }
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: '14px',
                    fontWeight: '500',
                    color: 'GrayText',
                  }}
                >
                  {' '}
                  {
                    !item.isEditing ?
                      (item.name ? item.name : '')
                      :
                      <input value={item.name ? item.name : ''} style={{ border: 'none', outline: 'none', borderBottom: '1px dashed gray', color: '#000', fontSize: '15px', background: 'none', textAlign: 'center', width: '50%' }}
                        onChange={(e) => {
                          setWorkTypes((prev) => {
                            const shallowCopy = prev.map((item) => Object.assign({}, item))
                            shallowCopy[index].name = e.target.value
                            return shallowCopy
                          })
                        }}
                      />
                  }
                </TableCell>
                <TableCell>
                  <Box
                    sx={{
                      display: 'flex',
                      width: { xl: '50%', lg: '70%' },
                      borderRadius: '8px',
                      textAlign: 'center',
                      border: '0.5px solid #d9dadb',
                    }}
                  >
                    <Box
                      sx={{
                        width: '50%',
                        padding: '10px 0px',
                        background: '#f5f7fa',
                      }}
                    >
                      {
                        !item.isEditing ?
                          <p style={{ color: '#000', fontSize: '15px' }}>
                            $ {item.user_rate ? item.user_rate : '0'}
                          </p>
                          :
                          <input value={item.user_rate ? item.user_rate : '0'} style={{ border: 'none', outline: 'none', borderBottom: '1px dashed gray', color: '#000', fontSize: '15px', background: 'none', textAlign: 'center', width: '50%' }}
                            onChange={(e) => {
                              setWorkTypes((prev) => {
                                const shallowCopy = prev.map((item) => Object.assign({}, item))
                                shallowCopy[index].user_rate = e.target.value
                                return shallowCopy
                              })
                            }}
                          />
                      }
                    </Box>
                    <Box
                      sx={{
                        width: '50%',
                        backgroundColor: '#F2EBFF',
                        padding: '10px 8px',
                        borderStartEndRadius: '5px',
                        borderEndEndRadius: '5px',
                      }}
                    >
                      <p style={{ color: 'gray', fontWeight: '500' }}>
                        USD / Hour
                      </p>
                    </Box>
                  </Box>
                </TableCell>
                <TableCell align="center">
                  {
                    item.isEditing ?
                      <button style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: '#711fff',
                        padding: '0.5rem 1rem',
                        borderRadius: '8px',
                        fontSize: '15px',
                        color: 'white',
                        width: '110px'

                      }}
                        onClick={async () => {
                       
                          const res = await fetch(`${ENDPOINT}/api/opportunity/worktype/update/${item.id}/`, {
                            method: 'PUT',
                            headers: {
                              'Content-Type': 'application/json',
                              Authorization: `JWT ${access_token}`,
                            },
                            body: JSON.stringify({
                              id: item.id,
                              name: item.name,
                              worktype_id: item.worktype_id,
                              user_rate: item.user_rate
                            }),
                          })
                          const data = await res.json()
                          if (!res.status) {
                           
                          }
                          else {
                            dispatch(getWorkTypes({ access_token }));
                          }
                          setWorkTypes((prev) => {
                            const shallowCopy = prev.map((item) => Object.assign({}, item))
                            shallowCopy[index].isEditing = false
                            return shallowCopy
                          })
                        }}
                      > Save </button>
                      :
                      <IconComp access_token={access_token} data={item} index={index} setWorkTypes={setWorkTypes} workTypes={WorkTypes} />
                  }
                </TableCell>
              </TableRow>
            ))
          ) : (
            <Alert severity="info">You do not have any Work Types...</Alert>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  ) : (
    <Box sx={{ width: '100%', marginTop: '2rem' }}>
      <Alert severity="info">You do not have any Work Types...</Alert>
    </Box>
  );
};

const MyWork = ({ searchQuery, screenHeight }) => {

  return (
    <>
      <AddWorkType searchQuery={searchQuery} screenHeight={screenHeight} />

      <WorkTypeList searchQuery={searchQuery} screenHeight={screenHeight} />
      </>
  );
};

const WorkTypes = ({ screenHeight }) => {
  const [view, setView] = useState('my_work_type');
  const [searchQuery, setSearchQuery] = useState('');
  const courses = useSelector((state) => state.NetNetU.courses).filter(item => item.course_type === 'help_videos')
  const welcomeChapters = courses.flatMap(course => {
    return course.chapters.filter(chapter => chapter.title === 'My Worktypes');
  });
  const [show, setShow] = useState(false)
  return (
    <Bounce left>
      <section className="oppor-section">
        <div className="oppor-row">
          <div className="oppor-col">
            <h3 className="fstxtPro">Work Types</h3>
            <img src={helpVid} onClick={() => { setShow(true) }} style={{ color: '#a6a6a6', marginLeft: '10px', width: '28px', height: '28px', cursor: 'pointer' }} />
          </div>
        </div>
        <Grid
          sx={{
            backgroundColor: '#fff',
            border: '1px solid #d9d9d9',
            padding: '1rem 2rem',
            borderRadius: '8px',
            height: screenHeight - 150,
            overflow: "auto"
          }}
          container
          direction="column"
        >
          <Switcher
            view={view}
            setView={setView}
            setSearchQuery={setSearchQuery}
          />
          {view === 'my_work_type' && <MyWork searchQuery={searchQuery} screenHeight={screenHeight} />}
        </Grid>
        <HelpVideo show={show} setShow={setShow} welcomeChapters={welcomeChapters} />
      </section>
    </Bounce>
  );
};
export default WorkTypes;
