import { TableBody, TableCell, TableRow, Typography } from "@mui/material";
import { Box } from "@mui/system";
import moment from "moment";

const DefaultGroupTableBody = ({tasksToBeRendered,Projects}) => {
    return (
        <TableBody>
            {tasksToBeRendered.map((item) => {
                const start = Array.isArray(item?.time_tracking) && item?.time_tracking?.length && item?.time_tracking[item.time_tracking.length - 1]?.time_tracking_start
                const end = Array.isArray(item?.time_tracking) && item?.time_tracking.length && item?.time_tracking[item?.time_tracking.length - 1]?.time_tracking_stop
                const startDateTime = new Date(start);
                const endDateTime = new Date(end);
                const timeDifference = endDateTime - startDateTime;
                const hours = Math.floor(timeDifference / (1000 * 60 * 60));
                const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
                const startTime = new Date(start).toLocaleTimeString('en-US', {
                    hour: 'numeric',
                    minute: 'numeric',
                    hour12: true,
                });
                const endTime = new Date(end).toLocaleTimeString('en-US', {
                    hour: 'numeric',
                    minute: 'numeric',
                    hour12: true,
                });

                let project_name = Array.isArray(Projects) && Projects.length && item?.task_project ? Projects.find((pro) => pro?.id === item?.task_project)?.title : '';

                return (
                    <TableRow
                        sx={{ height: '92px', '& > *': { borderBottom: 'unset' }, width: "100%" }}
                    >
                        <TableCell sx={{ width: "25%", fontSize: "16px", fontWeight: 500 }} >
                        <Box>
                                                <Typography>{item?.task_name}</Typography>
                                                <Box sx={{ display: 'flex', alignItems: 'center', ml: 1, }}>
                                                  {
                                                    project_name &&
                                                    <svg style={{ marginRight: '10px' }} width="9" height="7" viewBox="0 0 9 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                      <path id="Vector 4" d="M1 0V6H9" stroke="black" />
                                                    </svg>
                                                  }
                                                  <Typography sx={{ color: '#5600EB', fontSize: '12px', '&:hover': { color: 'black' } }}>{project_name}</Typography>
                                                </Box>
  
                                              </Box>
                        </TableCell>
                        <TableCell sx={{ width: "15%", fontSize: "16px", }} >
                            <div
                                dangerouslySetInnerHTML={{ __html: item?.time_tracking[0]?.note ?? '' }}
                            />
                        </TableCell>
                        <TableCell sx={{ width: "15%", fontSize: "16px" }} >{item?.time_tracking[0]?.created_by.first_name ?? ''}</TableCell>
                        <TableCell sx={{ width: "15%", fontSize: "16px" }} >{item?.task_worktype[0]?.name ?? ''}</TableCell>
                        <TableCell sx={{ width: "15%", fontSize: "16px" }} >
                            {
                                timeDifference === 0 ? (
                                    <>
                                        <Typography> No Time Tracked </Typography>
                                    </>
                                ) : (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'flex-start',
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                gap: '1rem',
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontWeight: '600',
                                                    fontSize: '0.98rem',
                                                }}
                                            >
                                                {startTime}
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontWeight: '600',
                                                    fontSize: '0.98rem',
                                                }}
                                            >
                                                {endTime}
                                            </Typography>
                                        </Box>
                                        <Typography
                                            sx={{
                                                fontWeight: '400',
                                                fontSize: '0.88rem',
                                                color: '#686a78',
                                            }}
                                        >
                                            {moment(item?.time_tracking[0]?.date || end).format('MMMM DD, yy') !== 'January 01, 1970' ? moment(item?.time_tracking[0]?.date ?? end ).format('MMMM DD, yy') : 'No Time Tracked'}
                                           
                                        </Typography>
                                    </Box>
                                )
                            }
                        </TableCell>
                        <TableCell sx={{ width: "15%", fontSize: "16px" }} >
                            <Box
                                sx={{
                                    width: '50%',
                                    backgroundColor: '#d2b8ff',
                                    borderRadius: '8px',
                                    p: '.54rem',
                                    textAlign: 'center',
                                    fontSize: '.9rem',
                                }}
                            >
                                {hours + ' : ' + minutes + ' Hr'}
                            </Box>
                        </TableCell>
                    </TableRow>
                );
            })}
        </TableBody>
    )
}

export default DefaultGroupTableBody;