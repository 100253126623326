
const dataOverView = () => {
    const data = [
        {
            title: 'Research and Discovery',
            estimitedTime: 20,
            elapsedTime: 13,
        },
        {
            title: 'Information Architecture',
            estimitedTime: 12,
            elapsedTime: 18,
        },

        {
            title: 'UI/UX Design',
            estimitedTime: 160,
            elapsedTime: 70,
        },
        {
            title: 'QA Testing',
            estimitedTime: 20,
            elapsedTime: 19,
        },
        {
            title: 'Launch and Release',
            estimitedTime: 10,
            elapsedTime: 9,
        },
    ];
    return data;
}




const kpiReportData = () => {
    const data = [
        {
            title: 'Research and Discovery',
            estimitedTime: 10,
            elapsedTime: 7,

        },
        {
            title: 'Information Architecture',
            estimitedTime: 20,
            elapsedTime: 14,
        },

        {
            title: 'UI/UX Design',
            estimitedTime: 15,
            elapsedTime: 12,
        },
        {
            title: 'Frontend Development',
            estimitedTime: 65,
            elapsedTime: 42,
        },
        {
            title: 'QA Testing',
            estimitedTime: 20,
            elapsedTime: 12,
        },
        {
            title: 'Launch and Release',
            estimitedTime: 10,
            elapsedTime: 9,
        },
    ];
    return data;
}

const repotrData = () => {
    const data = [
        {
            date: '2-15-2023',
            overTime: 0,
            elapsedTime: 5,
            isTarget: false
        },
        {
            date: '2-16-2023',
            overTime: 0,
            elapsedTime: 14,
            isTarget: false
        },
        {
            date: '2-17-2023',
            overTime: 0,
            elapsedTime: 0,
            isTarget: false
        },
        {
            date: '2-18-2023',
            overTime: 0,
            elapsedTime: 0,
            isTarget: false

        },
        {
            date: '2-19-2023',
            overTime: 0,
            elapsedTime: 14,
            isTarget: false
        },
        {
            date: '2-20-2023',
            overTime: 3,
            elapsedTime: 20,
            isTarget: false
        },
        {
            date: '2-21-2023',
            overTime: 0,
            elapsedTime: 0,
            isTarget: false
        },
        {
            date: '2-22-2023',
            overTime: 0,
            elapsedTime: 0,
            isTarget: false
        },
        {
            date: '2-23-2023',
            overTime: 0,
            elapsedTime: 0,
            isTarget: false
        },
        {
            date: '2-24-2023',
            overTime: 0,
            elapsedTime: 15,
            isTarget: true
        },
        {
            date: '2-25-2023',
            overTime: 0,
            elapsedTime: 0,
            isTarget: false
        },
        {
            date: '2-26-2023',
            overTime: 0,
            elapsedTime: 0,
            isTarget: false
        }
    ];
    return data;
}

const kpiTaskFIlter = () => {
    const data = ['Research and Discovery', 'UI/UX design'];

    return data;
}
const kpiDeliverableFilter = () => {
    const data = ['Check and Balance', 'Completed'];

    return data;
}

export { dataOverView, kpiReportData, kpiTaskFIlter, kpiDeliverableFilter, repotrData };