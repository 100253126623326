import { useState } from "react";
import { ProgressBar } from "react-bootstrap";
import { BsFillCircleFill } from "react-icons/bs";
import { getVariant } from "../../Projects/ProjectUtility";
import { kpiDeliverableFilter, kpiTaskFIlter, repotrData } from "./KPIData";
import ReportBar from "./ReportBar";

const TaskBar = (props) => {
    const { value, index } = props;

    const [task, setTask] = useState('');
    const [deliverable, setDeliverable] = useState('');
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
        >
            <div className="taskbar-top">
                <div className="taskbar-filter-container">
                    <label className="kpi-text taskbar-text">Deliverables</label>
                    <select className='kpi-text taskbar-filter kpi-report' value={task} onChange={(e) => setTask(e.target.value)} style={{ margin: '0', padding: '5px' }}>
                        {kpiTaskFIlter().map((item) => <option value={item}>{item}</option>)}
                    </select>
                </div>
                <div className="taskbar-filter-container">
                    <label className="kpi-text taskbar-text">Task</label>
                    <select className='kpi-text taskbar-filter kpi-report' value={deliverable} onChange={(e) => setDeliverable(e.target.value)} style={{ margin: '0', padding: '5px' }}>
                        {kpiDeliverableFilter().map((item) => <option value={item}>{item}</option>)}
                    </select>
                </div>
            </div>
            <ReportBar data={repotrData()} />
            <div className="taskbar-bottom">
                <div className="taskbar-bottom-left">
                    <div className="left-bottom-left">
                        <p className="kpi-text">WorkTypes</p>
                        <p className="kpi-text">Assigned Hours Remaining</p>
                    </div>

                    <div className="left-bottom-right">
                        <div className="right-bottom-top">
                            <BsFillCircleFill style={{ color: '#f59825' }} />
                            <p>Auditing</p>
                        </div>
                        <div className="right-bottom-top">
                            <div className="progress-container kpi-progress">
                                <ProgressBar sx now={6} className={getVariant(6, 60, 'date')} min={0} max={60} />
                                <span className="span">{2} h</span>
                            </div>
                            <span className="span">{6}<i>/</i>{20}</span>
                        </div>

                    </div>
                </div>
                <div className="taskbar-bottom-right">
                    <div className="taskbar-items">
                        <BsFillCircleFill style={{ color: '#f59825' }} />
                        <p>Marketing</p>
                    </div>
                    <div className="taskbar-items">
                        <p>Assigned Hours Remaining</p>
                        <div className="progress-container kpi-progress" >
                            <ProgressBar sx now={6} className={getVariant(6, 60, 'date')} min={0} max={60} />
                            <span className="span">{2} h</span>
                        </div>
                        <span className="span">{6}<i>/</i>{20}</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TaskBar;