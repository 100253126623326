import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import filterIcon from '../../NetNetU/Courses/assets/sort 1.svg'
import TagChips from "../../NetNetU/Courses/AddNewChapter/TagChips";
import { useState, useRef, useEffect } from "react";
import ScreenOneYouTubeLink from "./ScreenOneYouTubeLink";
import './screenOne1.css'
import { useSelector } from "react-redux";
import { ENDPOINT } from "../../../Opportunity/Redux/oppor";
import Quill from "quill";
import "quill/dist/quill.snow.css";
import { useNavigate } from "react-router-dom";
const Header = () => {
  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        padding: " 1% 1.38% 1% 1.38%",
        boxSizing: "border-box",
        textAlign: "center",
        fontSize: "29px",
        color: "#03071e",
        fontFamily: "'Open Sans'",
        backgroundColor: "white"
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
          padding: "5px 0",
          gap: "20px",
        }}
      >
        <b style={{ position: "relative", lineHeight: "100%" }}>
        Create Help Videos
        </b>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            textAlign: "left",
            fontSize: "14px",
            color: "rgba(3, 7, 30, 0.4)",
          }}
        >
          <div
            style={{
              borderRadius: "4px",
              overflow: "hidden",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              gap: "3px",
            }}
          >
            <img
              style={{
                position: "relative",
                width: "18px",
                height: "18px",
                overflow: "hidden",
                flexShrink: "0",
              }}
              alt=""
              src={filterIcon}
            />
            <div
              style={{
                position: "relative",
                letterSpacing: "-0.3px",
                lineHeight: "19px",
              }}
            >
              Filters
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          padding: "10px",
          fontSize: "14px",
          color: "#fff",
        }}
      >
      </div>
    </div>
  );
};
const ScreenOne1 = () => {
    const access_token = useSelector((state) => state.auth.user.access);
    const navigate = useNavigate()
    const [formData, setFormData] = useState({
        title: "",
        description: "",
        youtube_link: "",
        youtube_id: "",
        duration: "",
      });
 const quillRef = useRef(null);

    useEffect(() => {
  if (!quillRef.current) {
    const editor = new Quill("#lesson-description-editor", {
      theme: "snow",
      placeholder: "Enter Your Video Description",
      modules: {
        toolbar: [
          ["bold", "italic", "underline"],
          ["link"],
          [{ list: "ordered" }, { list: "bullet" }, { align: [] }],
          // ["#"], // Custom hashtag module
        ],
      },
    });

    editor.on("text-change", () => {
      setFormData({ ...formData, description: editor.root.innerHTML });
    });

    quillRef.current = editor;
  }
}, []);

      const handleSubmition = async (e) => {
        e.preventDefault();
    // change this api
          const response = await fetch(`${ENDPOINT}/api/netnetu/create-help-video/`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `JWT ${access_token}`,
            },
            body: JSON.stringify(formData),
          });
      
          if (!response.ok) {
            const errorData = await response.json();
            // alert(errorData)
          } else {
            setFormData({
                    title: "",
                    description: "",
                    youtube_link: "",
                    youtube_id: "",
                    duration: "",
            });
            navigate('/SuperAdmin/NetNetU/Courses'); 
          }  
      };
    const [tagData, setTagData] = useState([
        { key: 0, label: "Starting Up" },
        { key: 1, label: "Basic Information" },
        { key: 2, label: "Acount Information" },
      ]);

    return (
        <>
        
       <Header />
       <div className="createHelpWrapper">
      <div className="netUWrapperHelp">
      <div className="filledBox">
          <div className="courseTitle">Starting Up With Net Net</div>
          <div className="courseDesc">
            Learning the basics, introduction setting up your account and going
            along with the welcome screen
          </div>
          <div className="youTubeLink">
            <Link
              to="https://youtube.com/watch?v=a8fOAkGb8m0&ab_channel=MarcPitre"
              target="_blank"
            >
              youtube.com/watch?v=a8fOAkGb8m0&ab_channel=MarcPitre
            </Link>
          </div>
          <div className="chipTags">
            <TagChips tagData={tagData} setTagData={setTagData} />
          </div>
        </div>
        <div className="lessonWrapper">
        <div className="lessonTitleNew">
          <span>1.1 Basic Information about Net Net as a Whole</span>
          <p style={{ marginTop: 7 }}>Please Add your Lessons</p>
        </div>
         <div
          className="addLesson"
          style={{
            width: "100%",
            position: "relative",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "7px",
            textAlign: "left",
            fontSize: "20px",
            color: "#000",
            fontFamily: "'Open Sans'",
          }}
        >
          <b style={{ position: "relative", lineHeight: "130%" }}>
            Add Your Lessons
          </b>
        </div>
        <div className="lessonForm">
          {" "}
          <div className="courseInfo courseInfoLesson">
            <div className="leftInfo lessonInputs">
              <TextField
                sx={{
                  borderRadius: "4px",
                  borderColor: "#d9d9d9",
                  marginBottom: "44px"
                }}
                value={formData.title}
                onChange={(e) =>
                  setFormData({ ...formData, title: e.target.value })
                }
                fullWidth
                label="Enter Your Video Title"
                variant="outlined"
              />
              <div
                  className="textEditor textBorder"
                  id="lesson-description-editor"
                  style={{
                    borderBottomLeftRadius: 10,
                    borderBottomRightRadius: 10,
                    height: "149px"
                  }}
                >
                  <div className="editor"></div>
                  </div>
            </div>
            <div className="rightInfo lessonRight helpRight">
              <ScreenOneYouTubeLink formData={formData} setFormData={setFormData} />
            </div>
          </div>
        </div>
        <div className="actionButtons">
          <Button
            sx={{
              color: "#000",
              fontSize: 14,
              textTransform: "capitalize",
              fontWeight: "bold",
              "&:hover": {
                backgroundColor: "#F2EBFF",
                color: "black",
              },
            }}
          >
            Downloads
          </Button>
          <Button
            sx={{
              color: "#711FFF",
              "&:hover": {
                backgroundColor: "#F2EBFD",
                color: "#711DDD",
              },
              backgroundColor: "#F2EBFF",
              width: "130",
              fontSize: 14,
              fontWeight: "bold",
            }}
          >
            + ADD Files
          </Button>
        </div> 
      </div>
      <div className="backButtons">
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "rgba(0, 0, 0, 0.06)",
                  color: "black",
                  textTransform: "capitalize",
                  paddingX: 2,
                  "&:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.07)",
                    color: "black",
                  },
                }}
              >
                Go Back
              </Button>
              <Button
                onClick={handleSubmition}
                variant="contained"
                
                sx={{
                  backgroundColor: "#711FFF",
                  paddingX: 2,
                  textTransform: "capitalize",
                  "&:hover": {
                    backgroundColor: "#711FDD",
                    color: "white",
                  },
                }}
              >
                Save
              </Button>
            </div> 
            </div>
            </div>
      </>
    )

}

export default ScreenOne1