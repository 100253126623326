import { Country, State, City } from 'country-state-city';
import { Box, TextField } from '@mui/material';
import { useState } from 'react';
import { styled } from '@mui/material/styles';
import { ContentState, EditorState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { useSelector } from 'react-redux';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Checkbox from '@mui/material/Checkbox';
import { ENDPOINT } from '../pages/Opportunity/Redux/oppor';
import replay from '../pages/Stream/assets/reply.png';
import RichEditor from './RichEditor/RichEditor';
import InputAdornment from '@mui/material/InputAdornment';
import { useDispatch } from 'react-redux';
import { generalChat, getAllMsg, getMessages } from '../pages/Stream/streamRedux';
import cityNameOrCode from '../pages/User/Accountbilling/Tabs/CityNameorCode'
import Test from '../pages/Test';

export const myCountries = Country.getAllCountries().map(
  (country) => country.name
);

export const CityOptions = cityNameOrCode.map((item) => item.name)
export const CityCode = cityNameOrCode.map((item) => item.code)


export const myStates = State.getAllStates().map((state) => ({
  name: state.name,
  countryCode: state.countryCode,
}));

export const myCities = City.getAllCities().map((city) => city.name);

export const usa_states = State.getStatesOfCountry('US').map(
  (state) => state.name
);

const DashedField = (props) => {
  const { value, onChange, label, type, required, disabled, onKeyPress, id } =
    props;
  return (
    <TextField
      id={id}
      variant="standard"
      value={value}
      onChange={onChange}
      onKeyPress={onKeyPress}
      label={label}
      type={type}
      required={required}
      disabled={disabled}
      sx={{
        width: { xl: 280, lg: 200, md: 150 },
        '& .MuiInputBase-input': {
          fontSize: '1rem',
          fontFamily: 'Open Sans',
          fontWeight: '400',
          color: '#000000',
        },
        '& .MuiInput-underline:before': {
          borderBottom: '1px dashed #fff',
        },
        '& .MuiInput-underline:after': {
          borderBottom: '1px dashed #000000',
        },
        '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
          borderBottom: '1px dashed #000000',
        },
      }}
      InputProps={{
        style: {},
      }}
      {...props}
    />
  );
};

const DashedField2 = ({
  value,
  onChange,
  label,
  type,
  required,
  disabled,
  defaultValue,
  pre,
  onBlur,
  placeholder,
  onFocus,
}) => (
  <TextField
    variant="standard"
    value={value}
    onChange={onChange}
    label={label}
    type={type}
    required={required}
    disabled={disabled}
    defaultValue={defaultValue}
    onBlur={onBlur}
    onFocus={onFocus}
    placeholder={placeholder}
    sx={{
      '& .MuiInputBase-input': {
        fontSize: '1rem',
        fontFamily: 'Open Sans',
        fontWeight: '500',
        color: '#000000',
        textAlign: 'center',
      },
      '& .MuiInput-underline:before': {
        borderBottom: '1px dashed #000000',
      },
      '& .MuiInput-underline:after': {
        borderBottom: '1px dashed #000000',
      },
      '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
        borderBottom: '1px dashed #000000',
      },
    }}
    InputProps={{
      style: { width: 60 },
      startAdornment: (
        <InputAdornment position="start">{pre ?? ''}</InputAdornment>
      ),
    }}
  />
);

const DashedField3 = ({
  value,
  onChange,
  label,
  type,
  required,
  disabled,
  defaultValue,
  InputProps,
  onBlur,
  placeholder,
  onFocus,
}) => (
  <TextField
    variant="standard"
    value={value}
    onChange={onChange}
    label={label}
    type={type}
    required={required}
    disabled={disabled}
    defaultValue={defaultValue}
    InputProps={InputProps}
    onBlur={onBlur}
    placeholder={placeholder}
    onFocus={onFocus}
    sx={{
      width: '80px',
      '& .MuiInputBase-input': {
        fontSize: '1rem',
        fontFamily: 'Open Sans',
        fontWeight: '500',
        color: '#000000',
        textAlign: 'center',
      },
      '& .MuiInput-underline:before': {
        borderBottom: '1px dashed #000000',
      },
      '& .MuiInput-underline:after': {
        borderBottom: '1px dashed #000000',
      },
      '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
        borderBottom: '1px dashed #000000',
      },
    }}
  />
);
export { DashedField, DashedField2, DashedField3 };

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#711fff',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#d3d7d9' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: 3,
  width: 16,
  height: 16,
  boxShadow:
    theme.palette.mode === 'dark'
      ? '0 0 0 1px rgb(16 22 26 / 40%)'
      : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
  backgroundImage:
    theme.palette.mode === 'dark'
      ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
      : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background:
      theme.palette.mode === 'dark'
        ? 'rgba(57,75,89,.5)'
        : 'rgba(206,217,224,.5)',
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: '#7111ff',
  backgroundImage:
    'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#711fff',
  },
});

export function NetNetCheckBox({ onChange, checked, style }) {
  return (
    <Checkbox
      sx={{
        padding: "0px",
        '&:hover': { bgcolor: 'transparent' },
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      inputProps={{ 'aria-label': 'Checkbox demo' }}
      onChange={onChange}
      checked={checked}
    />
  );
}

export const NetNetSwitch = ({ label, onChange, checked }) => (
  <FormControlLabel
    onChange={onChange}
    checked={checked}
    control={<IOSSwitch sx={{ m: 1 }} />}
    label={label}
  />
);


export const DirectReplyButton = ({ currentTargetOfEstimate, setEstimateMessage, chats, setChats, channel_id, page, setCurrentTarget, currentTarget, projectTeam, userMsg, setUserMsg, userData, setLocalProjectState, localProjectState, }) => {
  const access_token = useSelector((state) => state.auth.user.access);
  const [btn, setbtn] = useState()
  const Projects = useSelector(state => state.projects.projects)

  const current = currentTarget && Projects.find((item) => item.id === currentTarget.project)

  const check = (e) => {
    setbtn(e)
  }

  const [allMsg, setAllMsg] = useState()
  const [notRun, setNotRun] = useState(false)
  const new_channals = useSelector((state) => state.Stream.new_channels)
  const [isOpen, setIsOpen] = useState(false);

  const dispatch = useDispatch();

  return (
    <>
      {isOpen == true ? (
        <>
          <section className="reply-Editor"
            onKeyDown={async (e) => {

              if (e.key === 'Enter') { // Check if the pressed key is Enter
                e.preventDefault();
                if (!notRun) {

                  btn?.editor?.commands.clearContent()

                  if (channel_id?.reply_to_dm) {
                    const response = await fetch(
                      `${ENDPOINT}/api/chat/dm/message/reply/create/`,
                      {
                        method: 'POST',
                        headers: {
                          'Content-Type': 'application/json',
                          Authorization: `JWT ${access_token}`,
                        },
                        body: JSON.stringify({
                          reply_to_dm: channel_id?.id ? channel_id?.id : channel_id.message_in_channel[0].id,
                          message: allMsg,
                        }),
                      }
                    )
                    const data = await response?.json()
                    if (!response.ok) {
                      console.log(data, "Rejected@DMmessage")
                    } else {


                      setAllMsg()
                      dispatch(getAllMsg({ access_token }))

                    }
                  } else {
                    const response = await fetch(
                      `${ENDPOINT}/api/chat/channel/message/reply/create/`,
                      {
                        method: 'POST',
                        headers: {
                          'Content-Type': 'application/json',
                          Authorization: `JWT ${access_token}`,
                        },
                        body: JSON.stringify({
                          reply_to_chatmesage: channel_id?.message_in_channel ? channel_id?.message_in_channel[0].id : channel_id.id,
                          message: allMsg,
                        }),
                      }
                    );
                    const data = await response.json();
                    if (!response.ok) {
                      console.log(data, 'Rejected@SendMessage');
                    } else {
                      const find = chats && chats.find((item) => item.id === data.reply_to_chatmesage)
                      if (find) {
                        const updatedChatMessage = {
                          ...find,
                          reply_to_chatmesage: [...find.reply_to_chatmesage, {
                            ...data,
                            sender: userData
                          }]
                        };

                        const updatedChats = chats.map((chat) =>
                          chat.id === data.reply_to_chatmesage ? updatedChatMessage : chat
                        );
                        setChats(updatedChats);
                      }
                      setAllMsg()
                      dispatch(getMessages({ access_token }))
                      dispatch(generalChat({ access_token }))

                      if (current) {
                        const res = await fetch(`${ENDPOINT}/api/chat/channel/detail/${channel_id?.channel}/`, {
                          method: 'GET',
                          headers: {
                            'Content-Type': 'application/json',
                            Authorization: `JWT${access_token}`,
                          },
                        });
                        const data = await res.json();
                        if (res.ok) {
                          setLocalProjectState(data?.message_in_channel)
                        }
                      }
                      const channalEst = new_channals?.filter((item) => !item?.deliverable && item?.estimate).find((item) => item?.estimate?.id === currentTargetOfEstimate?.id)

                      if (channalEst) {
                        const res = await fetch(`${ENDPOINT}/api/chat/channel/detail/${channalEst?.id}/`, {
                          method: 'GET',
                          headers: {
                            'Content-Type': 'application/json',
                            Authorization: `JWT${access_token}`,
                          },
                        });
                        const data = await res.json();
                        if (!res) {
                          return;
                        } else {
                          setEstimateMessage(data?.message_in_channel)
                        }
                      }
                    }
                  }
                }
              }
            }
            }
          >
            <Box  >
              <Test
                setNotRun={setNotRun}
                setHtml={setAllMsg}
                html={allMsg}
                check={check}
              />
            </Box>
          </section>
          <button
            className="response-btn"
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          >
            Hide Responses
          </button>
        </>
      ) : (
        <button
          className="is_responed"
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        >
          <img src={replay} alt="replay" />
          Reply
        </button>
      )}
    </>
  );
};


export const ReplyButton = ({ setChats, chats, estimateMsg, setEstimateMessage, currentTargetOfEstimate, channel_id, page, setCurrentTarget, currentTarget, projectTeam, userMsg, setUserMsg, userData, setLocalProjectState, localProjectState, }) => {
  const access_token = useSelector((state) => state.auth.user.access);
  const [btn, setbtn] = useState()
  const Projects = useSelector(state => state.projects.projects)
  const current = currentTarget && Projects.find((item) => item.id === currentTarget.project)

  const check = (e) => {
    setbtn(e)
  }

  const [allMsg, setAllMsg] = useState()
  const [notRun, setNotRun] = useState(false)
  const [isOpen, setIsOpen] = useState(false);
  const new_channals = useSelector((state) => state.Stream.new_channels)

  const dispatch = useDispatch();

  return (
    <>
      {isOpen == true ? (
        <section className="reply-Editor"
          onKeyDown={async (e) => {

            if (e.key === 'Enter') { // Check if the pressed key is Enter
              e.preventDefault();
              if (!notRun) {
                btn?.editor?.commands.clearContent()

                if (channel_id?.reply_to_dm) {
                  const response = await fetch(
                    `${ENDPOINT}/api/chat/dm/message/reply/create/`,
                    {
                      method: 'POST',
                      headers: {
                        'Content-Type': 'application/json',
                        Authorization: `JWT ${access_token}`,
                      },
                      body: JSON.stringify({
                        reply_to_dm: channel_id?.id ? channel_id?.id : channel_id.message_in_channel[0].id,
                        message: allMsg,
                      }),
                    }
                  )
                  const data = await response?.json()
                  if (!response.ok) {
                  } else {
                    setAllMsg()
                    dispatch(getAllMsg({ access_token }))

                  }
                } else {
                  const response = await fetch(
                    `${ENDPOINT}/api/chat/channel/message/reply/create/`,
                    {
                      method: 'POST',
                      headers: {
                        'Content-Type': 'application/json',
                        Authorization: `JWT ${access_token}`,
                      },
                      body: JSON.stringify({
                        reply_to_chatmesage: channel_id?.message_in_channel ? channel_id?.message_in_channel[0].id : channel_id.id,
                        message: allMsg,
                      }),
                    }
                  );
                  const data = await response.json();
                  if (!response.ok) {
                  } else {
                    const find = chats && chats.find((item) => item.id === data.reply_to_chatmesage)
                    if (find) {
                      const updatedChatMessage = {
                        ...find,
                        reply_to_chatmesage: [...find.reply_to_chatmesage, {
                          ...data,
                          sender: userData
                        }]
                      };

                      const updatedChats = chats.map((chat) =>
                        chat.id === data.reply_to_chatmesage ? updatedChatMessage : chat
                      );
                      setChats(updatedChats);
                    }

                    setAllMsg()
                    dispatch(getMessages({ access_token }))
                    dispatch(generalChat({ access_token }))

                    if (current) {
                      const res = await fetch(`${ENDPOINT}/api/chat/channel/detail/${channel_id?.channel}/`, {
                        method: 'GET',
                        headers: {
                          'Content-Type': 'application/json',
                          Authorization: `JWT${access_token}`,
                        },
                      });
                      const data = await res.json();
                      if (res.ok) {
                        setLocalProjectState(data?.message_in_channel)
                      }
                    }
                    const channalEst = new_channals.filter((item) => !item?.deliverable && item?.estimate).find((item) => item?.estimate?.id === currentTargetOfEstimate?.id)

                    if (channalEst) {
                      const res = await fetch(`${ENDPOINT}/api/chat/channel/detail/${channalEst?.id}/`, {
                        method: 'GET',
                        headers: {
                          'Content-Type': 'application/json',
                          Authorization: `JWT${access_token}`,
                        },
                      });
                      const data = await res.json();
                      if (!res) {
                        return;
                      } else {
                        setEstimateMessage(data?.message_in_channel)
                      }
                    }
                  }
                }
              }
            }
          }
          }

        >
          <Test
            setNotRun={setNotRun}
            setHtml={setAllMsg}
            html={allMsg}
            check={check}
          />
        </section>
      ) : (
        <button
          className="is_responed"
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        >
          <img src={replay} alt="replay" />
          Reply
        </button>
      )}
    </>
  );
};

export const ReplyButton2 = ({
  channel_id,
  page,
  setLocalGeneral,
  localGeneral,
}) => {
  const access_token = useSelector((state) => state.auth.user.access);
  const [isOpen, setIsOpen] = useState(false);
  const userData = useSelector((state) => state.userData.userData);
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const generalId = useSelector((state) => state.Stream.general);

  const handleGeneral = async (e) => {
    if (e.key === '@' && e.key === 'Enter') {
      setEditorState(EditorState.moveSelectionToEnd(editorState));
    }
    if (e.key === 'Enter' && !e.shiftKey) {
      const contentStates = editorState.getCurrentContent();
      const plain = contentStates.getPlainText();
      if (plain.trim().length === 0 && e.key != '@') {
        setEditorState(
          EditorState.createWithContent(ContentState.createFromText(' '))
        );
      } else {
        const options = {
          entityStyleFn: (entity) => {
            const entityType = entity.getType();
            const data = entity.getData();
            if (entityType.includes('mention')) {
              return {
                element: 'span',
                attributes: {
                  className:
                    data.mention.type === 'dileverable'
                      ? 'mention-dileverable'
                      : 'mention-person',
                },
              };
            }
          },
        };
        const html = stateToHTML(contentStates, options);
        const response = await fetch(
          `${ENDPOINT}/api/chat/channel/message/create/`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `JWT ${access_token}`,
            },
            body: JSON.stringify({
              channel: generalId && generalId[0].id,
              message: html,
            }),
          }
        );
        const data = await response.json();
        if (!response.ok) {
          console.log(data, 'Rejected@SendMessage');
        } else {
          setLocalGeneral([...localGeneral, { ...data, sender: userData }]);
          setEditorState(
            EditorState.createWithContent(ContentState.createFromText(' '))
          );
        }
      }
    }
  };

  return (
    <>
      {isOpen == true ? (
        <section className="reply-Editor" onKeyDown={handleGeneral}>
          <RichEditor
            myState={editorState}
            myOnChange={setEditorState}
            page={'people'}
          />
        </section>
      ) : (
        <button
          className="is_responed"
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        >
          <img src={replay} alt="replay" />
          Reply
        </button>
      )}
    </>
  );
};

const PreLoader1 = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 445 445">
    <style
      dangerouslySetInnerHTML={{
        __html:
          '\n        @keyframes a0_t { 0% { transform: translate(222.937px,223.192px) rotate(0deg) translate(-954.327px,-223.192px); } 18% { transform: translate(222.937px,223.192px) rotate(0deg) translate(-954.327px,-223.192px); animation-timing-function: cubic-bezier(0.42,0,0.58,1); } 26% { transform: translate(222.937px,223.192px) rotate(200deg) translate(-954.327px,-223.192px); animation-timing-function: cubic-bezier(0.42,0,0.58,1); } 30% { transform: translate(222.937px,223.192px) rotate(180deg) translate(-954.327px,-223.192px); } 100% { transform: translate(222.937px,223.192px) rotate(180deg) translate(-954.327px,-223.192px); } }\n        @keyframes a1_t { 0% { transform: translate(222.915px,222.905px) scale(1,1) translate(-162.697px,-162.697px); } 8% { transform: translate(222.915px,222.905px) scale(1,1) translate(-162.697px,-162.697px); animation-timing-function: cubic-bezier(0.42,0,0.58,1); } 12% { transform: translate(222.915px,222.905px) scale(1.1,1.099593) translate(-162.697px,-162.697px); animation-timing-function: cubic-bezier(0.42,0,0.58,1); } 20% { transform: translate(222.915px,222.905px) scale(0,0) translate(-162.697px,-162.697px); } 28% { transform: translate(222.915px,222.905px) scale(0,0) translate(-162.697px,-162.697px); animation-timing-function: cubic-bezier(0.42,0,0.58,1); } 36% { transform: translate(222.915px,222.905px) scale(1.1,1.099593) translate(-162.697px,-162.697px); animation-timing-function: cubic-bezier(0.42,0,0.58,1); } 40% { transform: translate(222.915px,222.905px) scale(1,1) translate(-162.697px,-162.697px); } 100% { transform: translate(222.915px,222.905px) scale(1,1) translate(-162.697px,-162.697px); } }\n    ',
      }}
    />
    <g transform="translate(222.5,222.5) translate(-222.5,-222.5)">
      <rect
        width={445}
        height={445}
        rx="78.15"
        fill="transparent"
        transform="translate(-731.39,0) translate(731.39,0)"
      />
      <path
        d="M888.7,76.83C878.46,66.59,863.8,60.72,848.46,60.72C833.12,60.72,818.46,66.6,808.23,76.83C802.96,82.1201,798.78,88.3943,795.929,95.2961C793.077,102.198,791.61,109.593,791.61,117.06C791.61,124.527,793.077,131.922,795.929,138.824C798.78,145.726,802.96,152,808.23,157.29L1019.92,369C1030.59,379.67,1045.06,385.664,1060.15,385.664C1075.24,385.664,1089.71,379.67,1100.38,369C1111.05,358.33,1117.04,343.859,1117.04,328.77C1117.04,313.681,1111.05,299.21,1100.38,288.54ZM1092.7,328.75C1092.71,330.885,1092.51,333.016,1092.1,335.112C1091.69,337.208,1091.07,339.258,1090.26,341.232C1089.45,343.207,1088.44,345.096,1087.26,346.872C1086.07,348.648,1084.71,350.302,1083.2,351.81C1080.11,354.722,1076.49,357.022,1072.55,358.589C1068.6,360.156,1064.39,360.96,1060.15,360.96C1055.9,360.96,1051.69,360.156,1047.74,358.589C1043.8,357.022,1040.18,354.722,1037.09,351.81L825.4,140.12C819.284,134.004,815.848,125.709,815.848,117.06C815.848,108.411,819.284,100.116,825.4,94C831.516,87.8841,839.811,84.4482,848.46,84.4482C857.109,84.4482,865.404,87.8841,871.52,94L1083.21,305.68C1084.72,307.189,1086.08,308.845,1087.27,310.621C1088.45,312.398,1089.46,314.288,1090.27,316.263C1091.08,318.238,1091.7,320.289,1092.11,322.386C1092.52,324.482,1092.72,326.614,1092.71,328.75Z"
        fill="#fff"
        transform="translate(222.937,223.192) translate(-954.327,-223.192)"
        style={{ animation: '5s linear infinite both a0_t' }}
      />
      <g
        transform="translate(222.915,222.905) translate(-162.697,-162.697)"
        style={{ animation: '5s linear infinite both a1_t' }}
      >
        <path
          d="M1059.27,175.65C1082.62,175.654,1103.67,161.593,1112.6,140.023C1121.54,118.454,1116.6,93.6249,1100.09,77.1158C1083.59,60.6067,1058.76,55.6691,1037.19,64.6056C1015.62,73.5421,1001.56,94.5926,1001.56,117.94C1001.57,125.516,1003.07,133.015,1005.97,140.012C1008.88,147.009,1013.13,153.367,1018.49,158.724C1023.84,164.081,1030.2,168.332,1037.2,171.236C1044.19,174.14,1051.69,175.64,1059.27,175.65ZM1059.27,84.51C1072.79,84.506,1084.98,92.6483,1090.16,105.14C1095.34,117.632,1092.48,132.012,1082.92,141.575C1073.36,151.138,1058.98,154,1046.49,148.826C1034,143.653,1025.85,131.462,1025.85,117.94C1025.86,113.552,1026.72,109.208,1028.4,105.155C1030.09,101.103,1032.55,97.4197,1035.65,94.3166C1038.75,91.2135,1042.43,88.7507,1046.49,87.0682C1050.54,85.3858,1054.88,84.5166,1059.27,84.51Z"
          fill="#fff"
          transform="translate(-791.608,-60.2083)"
        />
        <path
          d="M849.33,270.16C825.984,270.16,804.936,284.224,796.003,305.794C787.069,327.364,792.009,352.191,808.519,368.698C825.029,385.205,849.857,390.141,871.425,381.203C892.994,372.266,907.054,351.216,907.05,327.87C907.041,320.293,905.542,312.792,902.637,305.794C899.733,298.796,895.481,292.437,890.123,287.08C884.765,281.723,878.406,277.472,871.407,274.569C864.408,271.666,856.907,270.168,849.33,270.16ZM882.77,327.87C882.774,341.391,874.633,353.583,862.142,358.761C849.652,363.94,835.272,361.084,825.708,351.526C816.145,341.968,813.28,327.59,818.451,315.096C823.621,302.603,835.809,294.454,849.33,294.45C853.719,294.454,858.064,295.321,862.118,297.002C866.172,298.682,869.856,301.144,872.96,304.246C876.064,307.348,878.528,311.031,880.211,315.084C881.894,319.137,882.763,323.481,882.77,327.87Z"
          fill="#fff"
          transform="translate(-791.608,-60.2083)"
        />
      </g>
    </g>
  </svg>
);
export { PreLoader1 }

const PreLoader = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 445 445">
    <style
      dangerouslySetInnerHTML={{
        __html:
          '\n        @keyframes a0_t { 0% { transform: translate(222.937px,223.192px) rotate(0deg) translate(-954.327px,-223.192px); } 18% { transform: translate(222.937px,223.192px) rotate(0deg) translate(-954.327px,-223.192px); animation-timing-function: cubic-bezier(0.42,0,0.58,1); } 26% { transform: translate(222.937px,223.192px) rotate(200deg) translate(-954.327px,-223.192px); animation-timing-function: cubic-bezier(0.42,0,0.58,1); } 30% { transform: translate(222.937px,223.192px) rotate(180deg) translate(-954.327px,-223.192px); } 100% { transform: translate(222.937px,223.192px) rotate(180deg) translate(-954.327px,-223.192px); } }\n        @keyframes a1_t { 0% { transform: translate(222.915px,222.905px) scale(1,1) translate(-162.697px,-162.697px); } 8% { transform: translate(222.915px,222.905px) scale(1,1) translate(-162.697px,-162.697px); animation-timing-function: cubic-bezier(0.42,0,0.58,1); } 12% { transform: translate(222.915px,222.905px) scale(1.1,1.099593) translate(-162.697px,-162.697px); animation-timing-function: cubic-bezier(0.42,0,0.58,1); } 20% { transform: translate(222.915px,222.905px) scale(0,0) translate(-162.697px,-162.697px); } 28% { transform: translate(222.915px,222.905px) scale(0,0) translate(-162.697px,-162.697px); animation-timing-function: cubic-bezier(0.42,0,0.58,1); } 36% { transform: translate(222.915px,222.905px) scale(1.1,1.099593) translate(-162.697px,-162.697px); animation-timing-function: cubic-bezier(0.42,0,0.58,1); } 40% { transform: translate(222.915px,222.905px) scale(1,1) translate(-162.697px,-162.697px); } 100% { transform: translate(222.915px,222.905px) scale(1,1) translate(-162.697px,-162.697px); } }\n    ',
      }}
    />
    <g transform="translate(222.5,222.5) translate(-222.5,-222.5)">
      <rect
        width={445}
        height={445}
        rx="78.15"
        fill="#711fff"
        transform="translate(-731.39,0) translate(731.39,0)"
      />
      <path
        d="M888.7,76.83C878.46,66.59,863.8,60.72,848.46,60.72C833.12,60.72,818.46,66.6,808.23,76.83C802.96,82.1201,798.78,88.3943,795.929,95.2961C793.077,102.198,791.61,109.593,791.61,117.06C791.61,124.527,793.077,131.922,795.929,138.824C798.78,145.726,802.96,152,808.23,157.29L1019.92,369C1030.59,379.67,1045.06,385.664,1060.15,385.664C1075.24,385.664,1089.71,379.67,1100.38,369C1111.05,358.33,1117.04,343.859,1117.04,328.77C1117.04,313.681,1111.05,299.21,1100.38,288.54ZM1092.7,328.75C1092.71,330.885,1092.51,333.016,1092.1,335.112C1091.69,337.208,1091.07,339.258,1090.26,341.232C1089.45,343.207,1088.44,345.096,1087.26,346.872C1086.07,348.648,1084.71,350.302,1083.2,351.81C1080.11,354.722,1076.49,357.022,1072.55,358.589C1068.6,360.156,1064.39,360.96,1060.15,360.96C1055.9,360.96,1051.69,360.156,1047.74,358.589C1043.8,357.022,1040.18,354.722,1037.09,351.81L825.4,140.12C819.284,134.004,815.848,125.709,815.848,117.06C815.848,108.411,819.284,100.116,825.4,94C831.516,87.8841,839.811,84.4482,848.46,84.4482C857.109,84.4482,865.404,87.8841,871.52,94L1083.21,305.68C1084.72,307.189,1086.08,308.845,1087.27,310.621C1088.45,312.398,1089.46,314.288,1090.27,316.263C1091.08,318.238,1091.7,320.289,1092.11,322.386C1092.52,324.482,1092.72,326.614,1092.71,328.75Z"
        fill="#fff"
        transform="translate(222.937,223.192) translate(-954.327,-223.192)"
        style={{ animation: '5s linear infinite both a0_t' }}
      />
      <g
        transform="translate(222.915,222.905) translate(-162.697,-162.697)"
        style={{ animation: '5s linear infinite both a1_t' }}
      >
        <path
          d="M1059.27,175.65C1082.62,175.654,1103.67,161.593,1112.6,140.023C1121.54,118.454,1116.6,93.6249,1100.09,77.1158C1083.59,60.6067,1058.76,55.6691,1037.19,64.6056C1015.62,73.5421,1001.56,94.5926,1001.56,117.94C1001.57,125.516,1003.07,133.015,1005.97,140.012C1008.88,147.009,1013.13,153.367,1018.49,158.724C1023.84,164.081,1030.2,168.332,1037.2,171.236C1044.19,174.14,1051.69,175.64,1059.27,175.65ZM1059.27,84.51C1072.79,84.506,1084.98,92.6483,1090.16,105.14C1095.34,117.632,1092.48,132.012,1082.92,141.575C1073.36,151.138,1058.98,154,1046.49,148.826C1034,143.653,1025.85,131.462,1025.85,117.94C1025.86,113.552,1026.72,109.208,1028.4,105.155C1030.09,101.103,1032.55,97.4197,1035.65,94.3166C1038.75,91.2135,1042.43,88.7507,1046.49,87.0682C1050.54,85.3858,1054.88,84.5166,1059.27,84.51Z"
          fill="#fff"
          transform="translate(-791.608,-60.2083)"
        />
        <path
          d="M849.33,270.16C825.984,270.16,804.936,284.224,796.003,305.794C787.069,327.364,792.009,352.191,808.519,368.698C825.029,385.205,849.857,390.141,871.425,381.203C892.994,372.266,907.054,351.216,907.05,327.87C907.041,320.293,905.542,312.792,902.637,305.794C899.733,298.796,895.481,292.437,890.123,287.08C884.765,281.723,878.406,277.472,871.407,274.569C864.408,271.666,856.907,270.168,849.33,270.16ZM882.77,327.87C882.774,341.391,874.633,353.583,862.142,358.761C849.652,363.94,835.272,361.084,825.708,351.526C816.145,341.968,813.28,327.59,818.451,315.096C823.621,302.603,835.809,294.454,849.33,294.45C853.719,294.454,858.064,295.321,862.118,297.002C866.172,298.682,869.856,301.144,872.96,304.246C876.064,307.348,878.528,311.031,880.211,315.084C881.894,319.137,882.763,323.481,882.77,327.87Z"
          fill="#fff"
          transform="translate(-791.608,-60.2083)"
        />
      </g>
    </g>
  </svg>
);

export default PreLoader;
