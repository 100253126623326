import { createAsyncThunk } from '@reduxjs/toolkit';
import { ENDPOINT } from '../../../Opportunity/Redux/oppor';

const GET_COURSES = 'netnet/Redux/GET_COURSES';

export const getCourses = createAsyncThunk(
  GET_COURSES,
  async (meta, thunkAPI) => {
    const response = await fetch(`${ENDPOINT}/api/netnetu/courselist/`, {

      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `JWT ${meta.access_token}`,
      },
    });

    const data = await response.json();

    console.log(data, "REalDat")
    if (!response.ok) {
      return thunkAPI.rejectWithValue(data);
    }
    return data;
  },
);





const initialState = {
  courses: [],
  loading: false,
  error: null,
};

const coursesReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${GET_COURSES}/pending`:
      return {
        ...state,
        loading: true,
      };
    case `${GET_COURSES}/fulfilled`:
      return {
        ...state,
        loading: false,
        courses: action.payload ,
      };
    case `${GET_COURSES}/rejected`:
      return {
        ...state,
        loading: false,
        error: action.payload,
        courses: [] ,
      };
    default:
      return state;
  }
};

export default coursesReducer;
