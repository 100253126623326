import { Grid, Typography, Box } from '@mui/material';
import { useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import uuid from 'react-uuid';
import { useSelector } from 'react-redux';
import { ENDPOINT } from '../../Opportunity/Redux/oppor';
import Test from '../../Test';

const Notes = ({ notes, setNotes, id, unique_id, setIsTyping, setFormData, setDeliverable, screenHeight }) => {

  const access_token = useSelector((state) => state?.auth?.user?.access);
  const [html1, setHtml] = useState('')
  const [btn, setbtn] = useState()
  const check = (e) => {
    setbtn(e)
  }
 
  return (
    <Grid
      container
      direction="column"
      sx={{
        width: '100%',
        height: screenHeight - 220,
        backgroundColor: '#fff',
        borderRadius: '#8px',
        position:'relative',
        overflow:'hidden',
        justifyContent:'space-between'
      }}
    >
      {Array.isArray(notes) && notes.length ? (
        <List sx={{ width: '100%',minHeight:'70%', maxHeight:'70%', overflow:'auto' }}>
          {notes.map((item, idx) => (
            <Box key={item.id || uuid}>
              <li
                style={{
                  display: 'flex',
                  width: '100%',
                  alignItems: 'center',
                }}
              >
                <Divider
                  variant="inset"
                  component="li"
                  textAlign="left"
                  sx={{
                    width: { xs: '76%', sm: '76%', md: '100%' },
                    ml: 3,
                    margin: 'auto',
                  }}
                >
                  <Typography
                    variant="caption"
                    sx={{
                      color: 'rgba(3, 7, 30, 0.6)',
                      letterSpacing: '-0.3px',
                      fontSize: '14px',
                      fontWeight: '400',
                      fontFamily: 'Open Sans',
                    }}
                  >
                    {item.sender.first_name}
                  </Typography>
                </Divider>
              </li>

              <ListItem alignItems="flex-start" key={item.id}>
                <ListItemAvatar>
                  <Avatar
                    alt={item.sender.first_name}
                    src={item.sender.avatar ? item.sender.avatar : '/static/nmp'}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Typography
                      variant="subtitle2"
                      sx={{
                        color: '#03071E',
                        letterSpacing: '-0.3px',
                        fontWeight: 'bold',
                        fontSize: '1rem',
                        fontFamily: 'Open Sans',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '0.5rem',
                      }}
                    >
                      {item.sender.first_name}

                    </Typography>
                  }
                  secondary={
                    <div
                      style={{
                        fontWeight: '400',
                        fontSize: '14px',
                        lineHeight: '22px',
                        fontStyle: 'normal',
                        color: '#03071E',
                      }}
                      dangerouslySetInnerHTML={{
                        __html: item.note,
                      }}
                    />
                  }
                />
              </ListItem>
            </Box>
          ))}
        </List>
      ) : (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          direction="column"
          sx={{ width: '100%', height: '80%' , overflow:'auto'}}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
          >
            <Typography
              sx={{
                fontSize: '1.2rem',
                color: '#000',
                fontFamily: 'Open Sans',
                fontWeight: '600',
              }}
            >
              No Notes Yet
            </Typography>
            <Typography
              sx={{
                fontSize: '0.9rem',
                color: '#000',
                fontFamily: 'Open Sans',
                fontWeight: '500',
              }}
            >
              You can create one!
            </Typography>
          </Box>
        </Grid>
      )}
      <Grid
        item
        container
        justifyContent={'center'}
        alignItems="center"
        sx={{ m: 'auto 0' }}
      >
        {' '}
        <Box sx={{ width:'100%', position:'absolute', bottom:'1px', height:'30%', background:'#e9e9e9', borderTopRightRadius:'8px', borderTopLeftRadius:'8px'}}
          onKeyDown={async (e) => {
            if (e.key === 'Enter') {
              e.preventDefault()
              btn.editor.commands.clearContent()
              const res = await fetch(`${ENDPOINT}/api/project/note/create/`, {
                method: 'POST',
                headers: {
                  Authorization: `JWT ${access_token}`,
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  project_note: id,
                  note: html1,
                }),
              });
              const data = await res.json();
              if (!res.ok) {
                return;
              }
              const resGet = await fetch(`${ENDPOINT}/api/project/detail/${unique_id}/`, {
                method: 'GET',
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `JWT ${access_token}`,
                },
              });
              const dataGet = await resGet.json();
              if (!resGet.ok) {
                return;
              }
              setFormData(dataGet)

              if (
                dataGet &&
                Array.isArray(dataGet.deliverable_project) &&
                dataGet.deliverable_project.length
              ) {
                setDeliverable(dataGet.deliverable_project);

                setIsTyping(false)
              }
            }
          }}
        >
          <Test html={html1} setHtml={setHtml} check={check} />
        </Box>
      </Grid>
    </Grid>
  );
};
export default Notes;
