import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Auth from "./Componenets/Auth/Login/Auth";
import Signup from "./Componenets/Auth/Signup/Signup";
import RequireAuth from "./Componenets/ProtectedRoute/RequireAuth";
import Onbording from "./Componenets/Auth/Signup/Onbording";
import Verified from "./Componenets/Auth/Signup/Verified";
import ResetPassword from "./Componenets/Auth/ResetPassword";
import InnerApp from "./Screens/InnerApp/InnerApp";
import AllSet from "./Componenets/Auth/Signup/AllSet";
import Invitation from "./Componenets/Invitation/Invitation";
import "./Fonts/NeueHaasDisplay/NeueHaasDisplayBlack.ttf";
import ConfirmReset from "./Componenets/Auth/ConfirmReset";
import Favicon from "react-favicon";
import favi from "./Componenets/ms-icon-150x150.png";
import ResendEmail from "./Screens/InnerApp/ResendEmail";
import ErrorBoundary from "./ErrorBoundry";
import { useEffect, useState } from "react";

const App = () => {
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  const handleScreen = () => {
    setScreenHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener("resize", handleScreen);
    return () => {
      window.removeEventListener("resize", handleScreen);
    };
  });
  return (
    <>
      <Favicon url={favi} />
      <ErrorBoundary>
        <Router>
          <Routes>
            <Route
              exact
              path="/register"
              element={<Signup screenHeight={screenHeight} />}
            />
            <Route
              exact
              path="/Login"
              element={<Auth screenHeight={screenHeight} />}
            />
            <Route
              exact
              path="/check"
              element={<Onbording screenHeight={screenHeight} />}
            />
            <Route
              exact
              path="/success"
              element={<Verified screenHeight={screenHeight} />}
            />
            <Route
              exact
              path="/reset"
              element={<ResetPassword screenHeight={screenHeight} />}
            />
            <Route path="/teaminvitation" element={<Invitation />} />
            <Route path="/resetPassword" element={<ConfirmReset />} />
            <Route path="/resend_activation" element={<ResendEmail />} />
            <Route element={<RequireAuth />}>
              <Route exact path="/complete" element={<AllSet />} />
              <Route exact path="/*" element={<InnerApp />} />
            </Route>
          </Routes>
        </Router>
      </ErrorBoundary>
    </>
  );
};

export default App;
