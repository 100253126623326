import { createAsyncThunk } from '@reduxjs/toolkit';
import { ENDPOINT } from '../../../pages/Opportunity/Redux/oppor';

const RESET_PWD = 'netnet/Redux/RESET_PWD';

const resetEndpoint = `${ENDPOINT}/api/reset-password/`;

const initialState = {
  recievedEmail: false,
  error: {},
  status: '',
};

export const resetPassword = createAsyncThunk(
  RESET_PWD,
  async (email, thunkAPI) => {
    const response = await fetch(resetEndpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(email),
    });
    const data = await response.json();
    if (!response.ok) {
      return thunkAPI.rejectWithValue(data);
    }
    return data;
  },
);

// eslint-disable-next-line
const ResetPwdReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${RESET_PWD}/pending`:
      return {
        ...state,
        status: 'loading',
      };
    case `${RESET_PWD}/fulfilled`:
      return {
        ...state,
        status: 'success',
        recievedEmail: true,
      };
    case `${RESET_PWD}/rejected`:
      return {
        ...state,
        status: 'failed',
        error: action.payload,
      };
    default:
      return state;
  }
};

export default ResetPwdReducer;
