import {useState } from 'react';
import {Typography, Popover, Box} from "@mui/material";
import Bounce from 'react-reveal/Bounce';
import TermsData from './Terms&Condition';
import { MdOutlineContentCopy } from "react-icons/md";
import { FaRegFileLines } from "react-icons/fa6";

const TermsCondition = ({ screenHeight }) => {
  
    const [anchorElCopyMail, setAnchorElCopyMail] = useState(null);
  
    const handleClickCopyMail = (event) => {
      setAnchorElCopyMail(event.currentTarget);
    };
  
    const handleCloseCopyMail = () => {
      setAnchorElCopyMail(null);
    };
  
    const openCopyMail = Boolean(anchorElCopyMail);
    const id = openCopyMail ? 'simple-popover' : undefined;
  
    const handleCopyEmail = () => {
  
      let copy1 = 'legal@hellonetnet.com'
  
      navigator.clipboard.writeText(copy1)
      document.execCommand('copy');
      handleCloseCopyMail()
    }
  
    return (
      <Bounce bottom>
        <Box sx={{ display: 'flex', justifyContent: 'center', }}>
          <Box sx={{ width: '100%', borderRadius: 2 }}>
            <Box sx={{ backgroundColor: '#711fff', color: 'white', borderTopLeftRadius: '15px', borderTopRightRadius: '15px' }}>
              <Box sx={{ p: 4, display: 'flex', justifyContent: 'start', alignItems: 'center', }}>
                <Box>
                  <FaRegFileLines style={{ fontSize: '40px' }} />
                </Box>
                <Box sx={{ ml: 0.5 }}>
                  <Typography sx={{ textTransform: 'uppercase', fontWeight: 700, fontFamily: 'Open Sans, sans-serif', fontSize: { xs: 20, md: 30 } }}>Terms and CONDITIONS</Typography>
                </Box>
              </Box>
            </Box>
            <Box className='scrollShow' sx={{ overflow: 'auto', height: screenHeight - 350 }}>
              <ol style={{ margin: '20px', marginLeft: '10px' }}>
                {
                  TermsData.map((v, i) => {
  
                    return (
                      <li>
                        <Box key={i} sx={{}}>
                          <Typography fontSize={20} mt={3} sx={{ fontFamily: 'Open Sans, sans-serif', fontWeight: 500 }}>{v.heading}</Typography>
                          {
                            v.paragraph.map((item, ind) => {
                              return (
                                <Typography sx={{ mt: 2, fontFamily: 'Open Sans, sans-serif', }}>
                                  {item}
                                </Typography>
                              )
                            })
                          }
                        </Box>
                      </li>
                    )
  
                  })
                }
              </ol>
  
              <div>
                <Typography aria-describedby={id} variant="contained" sx={{ ml: '4%', color: 'blue', cursor: 'pointer' }} onClick={handleClickCopyMail}>
                  legal@hellonetnet.com
                </Typography>
                <Popover
                  id={id}
                  open={openCopyMail}
                  anchorEl={anchorElCopyMail}
                  onClose={handleCloseCopyMail}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                >
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 2 }}>
                    <Typography sx={{ mr: 3 }}>legal@hellonetnet.com</Typography>
                    <MdOutlineContentCopy onClick={handleCopyEmail} style={{ cursor: 'pointer' }} />
                  </Box>
                </Popover>
              </div>
            </Box>
          </Box>
        </Box>
      </Bounce>
    )
  }

  export default TermsCondition