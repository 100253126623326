import {TableHead,TableRow,TableCell} from '@mui/material'

const TimeTableHeader = ({name, worktype}) => {
    return (
        <TableHead>
            <TableRow
                sx={{
                    height: '72px',
                    backgroundColor: '#F1F3F5',
                }}
            >
                <TableCell>Task Name</TableCell>
                <TableCell>Description</TableCell>
                {name && <TableCell>Name</TableCell>}
                <TableCell>{worktype ? 'Name' : 'Worktype'}</TableCell>
                <TableCell>Time</TableCell>
                <TableCell>Duration</TableCell>

            </TableRow>
        </TableHead>
    )
}

export default TimeTableHeader