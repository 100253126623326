const getColor = (progress, total) => {
    let progressInPrecent = (progress * 100) / total;

    if (progressInPrecent <= 80 && progressInPrecent >= 0)
        return "#5EE690"
    else if (progressInPrecent < 100 && progressInPrecent >= 81)
        return "#F59825";
    else
        return '#DC241C';

}

const getBarColor = (overTime, isTarget) => {

    if (isTarget)
        return '#00cba2';

    if (overTime > 0)
        return '#a16bff';
    else
    return '#0b88f9';
}

export { getColor, getBarColor };