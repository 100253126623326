/* eslint-disable */

import React from 'react';
import { createRoot } from 'react-dom/client';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import App from './App';
import store, { persistor } from './Componenets/Auth/Redux/configureStore';
import { registerLicense } from '@syncfusion/ej2-base';
import { LicenseInfo } from '@mui/x-license-pro';
import "@fontsource/open-sans";

registerLicense('Ngo9BigBOggjHTQxAR8/V1NHaF5cWWBCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdgWH5ec3RcRmdYWUFzWUE=');
LicenseInfo.setLicenseKey("4b883495ae8c7c52a673214b99c825bdTz02Mzk4MixFPTE3NDQ4MDg3NzYwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y")



const root = createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    
    </PersistGate>
  </Provider>
);


// if ('serviceWorker' in navigator) {
//   window.addEventListener('load', () => {
//     navigator.serviceWorker.register('/sw.js')
//       .then(registration => {
//         console.log('Service Worker registered with scope:', registration.scope);
//       })
//       .catch(error => {
//         console.error('Service Worker registration failed:', error);
//       });
//   });
// }