import React, { useState, useEffect, Fragment } from "react";
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Box, Typography } from "@mui/material"
import SchoolIcon from '@mui/icons-material/School';
import { useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';
import { ENDPOINT } from "../../Opportunity/Redux/oppor";


export default function ProjectHelpVideos({ show, setShow, welcomeChapters, steps }) {

    const location = useLocation();
    const pathSegments = location.pathname.split('/');
    const lastSegment = pathSegments[pathSegments.length - 1];

    const access_token = useSelector((state) => state.auth.user.access);


    const [helpVideosScreen, setHelpVideosScreen] = useState()
    const [selectedVideoScreen, setselectedVideoScreen] = useState({})

    const getVideosScreen = async () => {
        const response = await fetch(`${ENDPOINT}/api/netnetu/help-video-screen-list/`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `JWT ${access_token}`
            },

        })

        const data = await response.json();
        if (!data) {
            console.log("Error While Fetching Data")
        } else {
            setHelpVideosScreen(data)
        }

        return data
    }

    useEffect(() => {
        getVideosScreen()
    }, [])


    const extractVideoId = (url) => {
        const match = url?.match(/(?:\?v=|\/embed\/|\/\d\d\/|\/vi\/|\/v\/|https:\/\/www.youtube.com\/watch\?v=|https:\/\/youtu.be\/)([^#\&\?]*).*/);
        return (match && match[1].length === 11) ? match[1] : false;
    };

    // Function to construct the thumbnail URL
    const constructThumbnailUrl = (videoId, quality = 'maxresdefault') => {
        return `https://img.youtube.com/vi/${videoId}/${quality}.jpg`;
    };


    const handleRenderThumbnail = (youtubeURL) => {
        const videoId = extractVideoId(youtubeURL);

        if (videoId) {
            const thumbnailUrl = constructThumbnailUrl(videoId, 'maxresdefault');
            return thumbnailUrl
        } else {
            console.error('Invalid YouTube URL');
        }
    };


    const courses = useSelector((state) => state.NetNetU.courses).filter(item => item.course_type === 'help_videos')
    const [updatedData, setGetUpdatedData] = useState()


    useEffect(() => {
        const projectsObject = helpVideosScreen?.find(item => item?.title === "Projects");



        if (steps === 1) {
            const subscreen = projectsObject?.help_video_subscreen.find((item) => item?.title === "Deliverables/Tasks")

            setGetUpdatedData(subscreen)
        } else if (steps === 2) {
            const subscreen = projectsObject?.help_video_subscreen.find((item) => item?.title === "Timeline")

            setGetUpdatedData(subscreen)
        } else if (steps === 3) {
            const subscreen = projectsObject?.help_video_subscreen.find((item) => item?.title === "KPI's")

            setGetUpdatedData(subscreen)
        } else if (steps === 4) {
            const subscreen = projectsObject?.help_video_subscreen.find((item) => item?.title === "Changes")

            setGetUpdatedData(subscreen)
        } else if (steps === 5) {
            const subscreen = projectsObject?.help_video_subscreen.find((item) => item?.title === "Notes")

            setGetUpdatedData(subscreen)
        } else if (steps === 6) {
            const subscreen = projectsObject?.help_video_subscreen.find((item) => item?.title === "Settings")

            setGetUpdatedData(subscreen)
        }

        const firstVideoInScreen = updatedData;
        setselectedVideoScreen(firstVideoInScreen && firstVideoInScreen?.subscreen_help_video[0]);
    }, [helpVideosScreen, lastSegment, steps]);



    const newEstimate = courses.flatMap(course => {
        return course.chapters.filter(chapter => chapter.title === 'New_Estimate');
    });


    const formatMinutes = (minutes) => {
        const hours = Math.floor(minutes / 60);
        const remainingMinutes = minutes % 60;
        const seconds = 0;

        return `${hours.toString().padStart(2, '0')}:${remainingMinutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };


    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    return (
        <Offcanvas
            show={show}
            onHide={() => setShow(false)}
            scroll={false}
            backdrop
            placement="bottom"
            style={{
                height: 'max-content',
                zIndex: 99999999,
                maxHeight: '700px'
            }}
        >
            <button className="close-modal" onClick={() => setShow(false)} />
            <Offcanvas.Header>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        width: '95%',
                        margin: '0 auto'
                    }}
                >
                    <Typography sx={{
                        fontFamily: '\'Open Sans\', sans-serif',
                        fontWeight: '600',
                        fontSize: '2rem'
                    }}>Lesson</Typography>
                </Box>
            </Offcanvas.Header>

            <Offcanvas.Body>
                <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', gap: '1rem' }}>

                    <Box sx={{ width: '65%', }}>
                        {selectedVideoScreen ? (

                            <Box>
                                <Box sx={{
                                    width: '100%', height: "100%", display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'relative', background: "black"
                                }} onMouseEnter={handleMouseEnter}
                                    onMouseLeave={handleMouseLeave}>
                                    {
                                        <iframe
                                            src={selectedVideoScreen.youtube_link}
                                            frameBorder="8"
                                            style={{
                                                borderRadius: '10px',
                                                width: '1200px',
                                                height: '450px',
                                            }}
                                        >
                                        </iframe>
                                    }
                                </Box>

                                <Box sx={{ width: '95%', margin: '0 auto' }}>
                                    <Typography sx={{
                                        fontFamily: '\'Open Sans\', sans-serif',
                                        fontWeight: '600',
                                        fontSize: '1.5rem',
                                        color: '#000',
                                        marginTop: '1.5rem'
                                    }}>{selectedVideoScreen.title}</Typography>

                                    <p dangerouslySetInnerHTML={{ __html: selectedVideoScreen?.description }}></p>
                                </Box>
                            </Box>
                        ) : null}
                    </Box>


                    <Box sx={{ width: '32%' }}>
                        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', background: '#f7f7f7', maxHeight: '420px', minHeight: '420px', overflowY: 'auto', borderRadius: '8px', gap: '1rem' }}>
                            <Box sx={{ width: '85%', margin: '0 auto' }}>
                                <Typography sx={{
                                    fontFamily: '\'Open Sans\', sans-serif',
                                    fontWeight: '600',
                                    fontSize: '1.5rem',
                                    color: '#711fff',
                                    marginTop: { xl: '1.5rem', lg: '1rem', md: '1rem' },

                                }}>SEE ALSO ON {updatedData?.title}</Typography>
                                {
                                    Array.isArray(updatedData?.subscreen_help_video) && updatedData?.subscreen_help_video?.length ? (
                                        updatedData?.subscreen_help_video?.filter((item) => item?.id !== selectedVideoScreen?.id)?.map((item, index) => (
                                            <React.Fragment key={index}>
                                                <Box

                                                    sx={{
                                                        width: '100%',
                                                        height: '120px',
                                                        position: 'relative',
                                                        borderStartEndRadius: '8px',
                                                        borderStartStartRadius: '8px',
                                                        position: 'relative',
                                                        backgroundColor: "black",
                                                        backgroundImage: `url(${handleRenderThumbnail(
                                                            item?.youtube_link
                                                        )})`,
                                                        backgroundSize: 'cover',
                                                        backgroundPosition: 'center',
                                                    }}
                                                >
                                                    <iframe
                                                        src={item.youtube_link}
                                                        frameBorder="8"
                                                        style={{
                                                            borderRadius: '10px',
                                                            width: '100%',
                                                            height: '120px'
                                                        }}
                                                    />
                                                    <p
                                                        style={{
                                                            position: 'absolute',
                                                            bottom: '1px',
                                                            right: '1px',
                                                            padding: '3px 8px',
                                                            backgroundColor: 'rgba(0,0,0,0.7)',
                                                            background: "black",
                                                            zIndex: 9999,
                                                            borderRadius: '5px',
                                                        }}
                                                    >
                                                        {formatMinutes(item.duration)}
                                                    </p>
                                                </Box>
                                                <Box

                                                    sx={{
                                                        width: '100%',
                                                        height: '90px',
                                                        padding: '10px 13px',
                                                        background: '#f2ebff',
                                                        borderEndEndRadius: '8px',
                                                        borderEndStartRadius: '8px',
                                                    }}
                                                >
                                                    <p
                                                        onClick={() => setselectedVideoScreen(item)}
                                                        style={{
                                                            fontWeight: 'bold',
                                                            fontSize: '18px',
                                                            color: '#000',
                                                        }}
                                                    >
                                                        {item.title}
                                                    </p>
                                                    <p
                                                        style={{
                                                            fontSize: '13px',
                                                            color: 'GrayText',
                                                        }}
                                                        dangerouslySetInnerHTML={{
                                                            __html: item.description,
                                                        }}
                                                    ></p>
                                                </Box>
                                            </React.Fragment>
                                        ))
                                    ) : null
                                }
                            </Box>
                        </Box>
                        <Box sx={{ padding: '1rem 1.5rem', display: 'flex', alignItems: 'center', background: '#f2ebff', marginTop: '1rem', width: 'fit-content', borderRadius: '8px', gap: '1rem' }}>
                            <SchoolIcon /> <Typography sx={{ fontSize: '16px', fontFamily: 'Open Sans' }} >Visit Lessons</Typography>
                        </Box>
                    </Box>
                </Box>

            </Offcanvas.Body>
        </Offcanvas>
    )
}