import React,{useState, useEffect, useRef} from 'react';
import Dialog from '@mui/material/Dialog';
import PropTypes from 'prop-types';


export default function SimpleDialog(props) {
  const { onClose, selectedValue, open, setLink, setOpen, setLinkUrl , linkUrl} = props;

  const handleClose = () => {
    setOpen(false)
  };


  return (
    <Dialog onClose={handleClose} open={open}>
      
      <div style={{ display:"flex", flexDirection:"column", justifyContent:"flex-start", alignItems:"flex-start",  
              background: '#eaeaea',
              padding: '20px',
              borderRadius: '10px',
              boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
              minWidth: "366px",
              zIndex: 9999999999999999999,
              overflow:"visible"
              }}>
              <p style={{ marginBottom: '30px', fontSize: '20px', fontWeight:700,  color:"black", zIndex:1 }}>Add Your URL</p>
              <div style={{marginTop:"16px", textAlign:"start", alignItems:"start", width:"100%" }}>
                <input
                  type="text"
                  id='linkUrlInput'
                  required
                  placeholder="Enter URL"
                  value={linkUrl}
                  style={{ color: '#000', padding: '12px 16px', marginRight: '10px', borderRadius: '5px', border: '1px solid #ccc',outline: 'none', width:"100%" }}
                  onChange={(e)=>{setLinkUrl(e.target.value)}}
                />
                <div style={{display:"flex", flexDirection:"row", width:"100%", justifyContent:"flex-start", marginTop:"0.5rem"}}>
                <button
                  style={{ backgroundColor: '#007bff', color: '#fff', padding: '7px 15px', border: 'none', borderRadius: '5px', marginRight:"1rem", cursor: 'pointer', marginTop:"12px" }}
                  onClick={() => {
                    setLink(linkUrl);
                    
                  }}
                >
                  Set Link
                </button>
                <button
                  style={{ backgroundColor: 'red', color: '#fff', padding: '7px 15px', border: 'none', borderRadius: '5px', cursor: 'pointer', marginTop:"12px" }}
                  onClick={() => {
                   setOpen(false)
                   
                  }}
                >
                  Close 
                </button>     
                </div>
             
              </div>
            </div>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};
