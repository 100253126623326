import { createAsyncThunk } from "@reduxjs/toolkit";
import { notification } from "./notificationData";
import { ENDPOINT } from "../../../../Opportunity/Redux/oppor";

const GET_NOTIFICATIONS_AND_ALERTS = "netnet/Redux/GET_NOTIFICATIONS_AND_ALERTS";
const CREATE_NOTIFICATIONS_AND_ALERTS = "netnet/Redux/CREATE_NOTIFICATIONS_AND_ALERTS";

export const getNotificationsAndAlerts = createAsyncThunk(
  GET_NOTIFICATIONS_AND_ALERTS,

  async (meta, thunkAPI) => {
    const response = await fetch(`${ENDPOINT}/api/superadmin/notificationsandalerts/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${meta.access_token}`,
      },
    });

    const data = await response.json();
    if (!response.ok) {
      return thunkAPI.rejectWithValue(data);
    }
    return data;
  }
);

export const CreateNotificationsAndAlerts = createAsyncThunk(
  CREATE_NOTIFICATIONS_AND_ALERTS,
  async (meta, thunkAPI) => {
    const response = await fetch(
      `${ENDPOINT}/api/superadmin/notificationsandalerts/create/`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${meta.access_token}`,
        },
        body: JSON.stringify(meta.data),
      }
    );
    const data = await response.json();
    if (!response.ok) {
      return thunkAPI.rejectWithValue(data);
    }
    return data;
  }
);

const initialState = {
  notifications: notification  ,
  loading: false,
  error: null,
};


const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${GET_NOTIFICATIONS_AND_ALERTS}/pending`:
      return {
        ...state,
        loading: true,
      };
    case `${GET_NOTIFICATIONS_AND_ALERTS}/fulfilled`:
      return {
        ...state,
        loading: false,
        notifications: action.payload,
      };
    case `${GET_NOTIFICATIONS_AND_ALERTS}/rejected`:
      return {
        ...state,
        loading: false,
        error: action.payload,
       notifications: notification,
      };
    default:
      return state;
  }
};

export default notificationReducer;
