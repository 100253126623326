import {
  Grid,
  Box,
  Menu,
  MenuItem,
  Collapse,
  Tooltip,
  TextField,
} from '@mui/material';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Autocomplete from '@mui/material/Autocomplete';
import { stateToHTML } from 'draft-js-export-html';
import { ENDPOINT } from '../Redux/oppor';
import MUIRichTextEditor from 'mui-rte';
import { EditorState } from 'draft-js';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
  DashedField2,
  NetNetSwitch,
  DashedField,
} from '../../../Componenets/Misc';
import Duplicate from '../assets/Duplicate.PNG';
import subTask from '../assets/subTasks.PNG';
import updateTask from '../assets/updataTask.PNG';
import newTask from '../assets/newTask.PNG';
import DeleteIcon from '../assets/DeleteIcon.png';
import { getWorkTypes } from '../../User/redux';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Typography from '@mui/material/Typography';
import moment from 'moment';

const HeadCell = ({ arg }) => (
  <TableCell align="left" style={{ textAlign: 'center' }}>
    <div
      className="tcell"
      style={{
        padding: '1.44rem 0.44rem',
        backgroundColor: '#f1f8ff',
        borderRadius: '8px',
      }}
    >
      {arg}
    </div>
  </TableCell>
);

const calculateTotalHours = (deliverables, worktypeId) => {
  return deliverables.reduce((total, deliverable) => {
    if (
      Array.isArray(deliverable.deliverable_worktype) &&
      deliverable.deliverable_worktype.length
    ) {
      return (
        total +
        deliverable.deliverable_worktype.reduce((subTotal, worktype) => {
          if (
            worktype.name === worktypeId.name &&
            worktype.hours !== ''
          ) {
            return subTotal + parseInt(worktype.hours);
          } else {
            return subTotal;
          }
        }, 0)
      );
    } else {
      return total;
    }
  }, 0);
};

function calculateTotalLOE(array) {
  if (!array) {
    return false;
  }
  if (!Array.isArray(array) && !array.length) {
    return false;
  }
  return array.reduce((total, obj) => {
    return total + obj.total_loe;
  }, 0);
}

const LastRow = ({ newData, dileverable, counter }) => {
  const [totalHours, setTotalHours] = useState([]);

  useEffect(() => {
    if (Array.isArray(dileverable) && dileverable.length) {
      const hours = newData.collaborator_worktype.map((worktypeId) => {
        return calculateTotalHours(dileverable, worktypeId);
      });
      setTotalHours(hours);

    }
  }, [newData, dileverable, counter]);

  const totalAll = () => {
    if (Array.isArray(dileverable) && dileverable.length) {
      return dileverable.reduce((total, newArr) => {
        return total + newArr?.deliverable_worktype?.reduce((newTotal, item) => newTotal + Number(item.hours), 0);
      }, 0);
    } else {
      return 0;
    }
  }
  return (
    <TableRow
      sx={{ backgroundColor: '#1e0052', color: '#fff', height: '110px' }}
    >
      <TableCell style={{ textAlign: 'left' }}>
        <div className="fstxt-wh">Total</div>
      </TableCell>
      {newData.collaborator_worktype.map((_, index) => (
        <TableCell align="center" key={index}>
          <div className="fstxt-wh">
            {totalHours[index] ? `${totalHours[index]}hr` : ' 0hr'}
          </div>
        </TableCell>
      ))}
      <TableCell align="center">
        <div className="fstxt-wh">{totalAll() ? `${totalAll()}hr` : '0hr'}</div>
      </TableCell>
    </TableRow>
  );
};

const THead = ({ newData }) => {
  const userWorkTypes = useSelector((state) => state.Misc.workTypes);
  return (
    <TableHead
      sx={{
        height: '110px',
      }}
    >
      <TableRow>
        <TableCell align="left">
          <div className="tcell">
            Deliverables{' '}
            <InfoOutlinedIcon sx={{ color: '#4dfad2', fontSize: '15px' }} />{' '}
          </div>
        </TableCell>
        {newData?.collaborator_worktype
          ? newData.collaborator_worktype.map((item) => {
            const work = userWorkTypes?.find(
              (w) => Number(w.id) === Number(item.id)
            ) ??
              item ?? {
              name: '...',
            };
            return <HeadCell arg={work.name} />;
          })
          : 'Pending Worktypes...'}
        <TableCell
          style={{
            textAlign: 'center',
            fontWeight: 'bold',
          }}
        >
          <Box
            sx={{
              padding: '1.44rem 0.44rem',
              backgroundColor: '#f1f8ff',
              borderRadius: '8px',
            }}
          >
            Total
          </Box>
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

const AddRows = ({ newData, dileverable, setDeliverable, formData, user, target, setTarget, setFormData, setNewData, setErrorState }) => {
  const [tempState, setTempState] = useState('');
  const access_token = useSelector((state) => state.auth.user.access);
  const start = moment();
  const end = start.add(7, 'days').toISOString();
  return (
    <TableRow
      sx={{
        width: '100%',
        height: '100px',
      }}
    >
      <Tooltip open={Array.isArray(formData.deliverable) && formData.deliverable.length ? true : false} title={Array.isArray(formData.deliverable) && formData.deliverable.length ? '' : "Sponsor Needs to add atleast one deliverable after that you can add your deliverables"}>
        <TableCell
          align="left"
          sx={{
            display: 'flex',
            alignItems: 'baseline',
            justifyContent: 'flex-start',
            gap: '1rem',
            borderBottom: 'none',
          }}
        >
          <DashedField
            id='Cypress-AddDeliverable'
            label="Add Deliverable"
            value={tempState}
            onChange={(e) => setTempState(e.target.value)}
            onKeyPress={async (e) => {
              if (e.key === 'Enter') {
                if (Array.isArray(formData.deliverable) && formData.deliverable.length) {
                  const target = formData.collaborator.find(
                    (ele) => Number(ele.collab_detail) === Number(user.id)
                  );
                  const createData = [
                    {
                      estimate_id: newData.estimate_id,
                      collab_id: newData.collab_id,
                      financial_transparency: true,
                      is_collab_ready: false,
                      collaborator_delieverable: [{
                        deliverable_name: tempState,
                        deliverable_startdate: moment().toISOString(),
                        deliverable_enddate: end,
                        total_hours: 0,
                        description: '',
                        user_price: '',
                        our_price: '',
                        loe_price: '',
                        deliverable_dependency: null,
                        collaborator_worktype: newData.collaborator_worktype.map((n, i) => ({
                          id: n.id,
                          hours: 0,
                        }))
                        ,
                        collaborator_deliverable_task: [
                        ],
                      }],

                      estimate_id: Number(formData.id),
                      collab_id: Number(target.id),
                      collaborator_members: [user.email],
                    },
                  ];

                  const res = await fetch(
                    `${ENDPOINT}/api/opportunity/estimate/collaborator/create/`,
                    {
                      method: 'PUT',
                      headers: {
                        Authorization: `JWT ${access_token}`,
                        'Content-Type': 'application/json',
                      },
                      body: JSON.stringify(createData),
                    }
                  );

                  const data = await res.json();
                  if (!res.ok) {
                    setErrorState({
                      error: true,
                      message: 'Something went wrong Creating Deliverable! try again.',
                    });
                  }

                  setTempState('');

                  const resGet = await fetch(`${ENDPOINT}/api/opportunity/estimate/${formData?.id}/`, {
                    method: 'GET',
                    headers: {
                      'Content-Type': 'application/json',
                      Authorization: `JWT ${access_token}`,
                    },
                  });
                  const dataGet = await resGet.json();
                  if (!resGet.ok) {
                  }
                  setFormData(dataGet)
                  if (dataGet && 'id' in dataGet) {
                    const target = dataGet.collaborator.find(
                      (ele) => Number(ele.collab_detail) === Number(user.id)
                    );
                    setTarget(target);
                    if (
                      target &&
                      Array.isArray(target.collaborator_deliverable) &&
                      target.collaborator_deliverable.length
                    ) {
                      setNewData({
                        ...newData,
                        estimate_id: formData.id ? Number(formData.id) : '',
                        collab_id: target.id ? Number(target.id) : 0,
                        collaborator_members: [],
                        collaborator_worktype:
                          target.collaborator_deliverable[0].deliverable_worktype,
                      });
                      setDeliverable(target.collaborator_deliverable);
                    }
                  }
                }
                else {
                  setErrorState({
                    error: true,
                    message: "Sponsor Needs to add atleast one deliverable after that you can add your deliverables"
                  })
                }
              }
              return 0;
            }}
          />
        </TableCell>
      </Tooltip>
    </TableRow>
  );
};

const CustomCell = ({
  p,
  setOpen,
  dileverable,
  setDeliverable,
  setIsDetOpen,
  formData,
  setIsTyping,
  access_token,
  newData
}) => {
  const [show, setShow] = useState(false);
  const [menuIconEl, setMenuIconEl] = useState(false);
  const [showStuff, setShowStuff] = useState(false);
  const handleClose = () => setShow(false);
  const [editor, setEditor] = useState(() => {
    EditorState.createEmpty().getCurrentContent();
  });
  const [fieldEmpty, setFieldEmpty] = useState(editor);
  const fieldText = (e) => {
    setEditor(e);
  };
  const user = useSelector((state) => state.userData.userData);
  const customOptions = dileverable.filter(
    (item) => item.deliverable_name !== p.deliverable_name
  );

  useEffect(() => {
    setIsDetOpen(!show);
  }, [show]);

  const myTheme = createTheme({});

  const updateWeeks = async () => {
    const target = formData.collaborator.find(
      (ele) => Number(ele.collab_detail) === Number(user.id)
    );


    const tres = await fetch(
      `${ENDPOINT}/api/opportunity/estimate/collaborator/update/`,
      {
        method: 'PUT',
        headers: {
          Authorization: `JWT ${access_token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify([
          {
            estimate_id: newData.estimate_id,
            collab_id: newData.collab_id,
            financial_transparency: true,
            is_collab_ready: true,
            collaborator_delieverable: dileverable.map((item) => {
              return {
                deliver_id: item.id,
                deliverable_name: item.deliverable_name,
                deliverable_startdate: item.deliverable_startdate,
                deliverable_enddate: item.deliverable_enddate,
                total_hours: item.total_loe,
                description: item.description ? item.description : '',
                user_price: item.user_price ? item.user_price : Number(0),
                our_price: item.our_price ? item.our_price : Number(0),
                loe_price: item.loe_price ? item.loe_price : Number(0),
                deliverable_dependency: item.deliverable_dependency ? item.deliverable_dependency.id : null,

              };
            }),
            estimate_id: Number(formData.id),
            collab_id: Number(target.id),
            collaborator_members: [user.email],
          },
        ]),
      }
    );

    const tdata = await tres.json();

    if (!tdata) {
      setErrorState({
        error: true,
        message: 'Something went wrong Updating Your estimate.',
      });
      return;
    }
    else {
      setIsTyping(false)
    }
  }

  return (
    <>
      <TableCell
        align="left"
        component="th"
        scope="row"
        onMouseOver={() => setShowStuff(true)}
        onMouseLeave={() => setShowStuff(false)}
      >
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            gap: '1rem',
            color: '#03071E',
            fontFamily: 'Open Sans',
            fontSize: '1rem',
            fontWeight: '400',
          }}
        >
          <DragIndicatorIcon
            sx={{
              opacity: showStuff ? '1' : 0,
              transition: 'all 0.3s ease-in-out',
              fontSize: '1.60rem',
              fill: showStuff ? '#8D8F99' : '#fff',
            }}
          />
          <p
            className="fstxt"
            style={{ cursor: 'pointer' }}
            onClick={() => {
              if (formData.type === 'project') {
                setShow(true)
                setIsTyping(true)
              }
            }}
          >
            {p.deliverable_name}
          </p>

          <MoreHorizIcon
            sx={{
              opacity: showStuff ? '1' : 0,
              transition: 'all 0.3s ease-in-out',
              fontSize: '1.60rem',
              fill: showStuff ? '#8D8F99' : '#fff',
            }}
            id="company-icon"
            aria-haspopup="true"
            aria-controls={menuIconEl ? 'company-menu' : undefined}
            aria-expanded={menuIconEl ? 'true' : undefined}
            onClick={(e) => setMenuIconEl(e.currentTarget)}
          />
          <Menu
            id="company-icon-menu"
            sx={{
              width: 'auto',
              '& .MuiMenu-paper': {
                border: '1px solid #03071E33',
                borderRadius: '10px',
                width: 'auto',
              },
              '& .MuiMenu-list': {
                margin: '5px 5px 5px 5px',
                overflowY: 'hidden',
                maxHeight: '300px',
                width: 'auto',
              },
            }}
            anchorEl={menuIconEl}
            open={!!menuIconEl}
            onClose={() => setMenuIconEl(null)}
            autoFocus={false}
          >
            <MenuItem
              className="mb-2"
              onClick={() => {
                setOpen(true);
                setMenuIconEl(null);
              }}
            >
              <img src={subTask} className="icon-sm" />
              {'sub_task' in p ? 'Show Tasks' : 'Add Tasks'}
            </MenuItem>
            <MenuItem
              className="mb-2"
              onClick={() => {
                setMenuIconEl(null);
              }}
            >
              <img src={newTask} className="icon-sm" />
              New Deliverable Template
            </MenuItem>
            <MenuItem
              className="mb-2"
              onClick={() => {
                setMenuIconEl(null);
              }}
            >
              <img src={updateTask} className="icon-sm" />
              Update Deliverable Template
            </MenuItem>
            <MenuItem
              className="mb-2"
              onClick={() => {
                setMenuIconEl(null);
              }}
            >
              <img src={Duplicate} className="icon-sm" />
              Duplicate
            </MenuItem>
            <MenuItem
              className="text-danger mb-2"
              onClick={() => {
                setDeliverable((prev) => {
                  const shallowCopy = [
                    ...prev.map((item) => Object.assign({}, item)),
                  ];
                  const index = shallowCopy.findIndex(
                    (ent) => Number(ent.id) === Number(p.id)
                  );
                  const updatedPrevs = shallowCopy.filter(
                    (_, dent) => dent !== index
                  );
                  return updatedPrevs;
                });
                setMenuIconEl(null);
              }}
            >
              <img src={DeleteIcon} className="icon-sm" />
              Delete
            </MenuItem>
          </Menu>
        </div>
      </TableCell>
      <Offcanvas show={show} onHide={handleClose} placement="bottom ">
        <Offcanvas.Header>
          <button className="close-modal" onClick={() => setShow(false)} />
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Grid container direction="column" alignItems="flex-start">
            <Grid
              item
              container
              direction="column"
              alignItems="center"
              xl={12}
              lg={12}
              md={12}
              sm={12}
            >
              <Box
                sx={{
                  width: '100%',
                  fontSize: '1.5rem',
                  fontWeight: '600',
                  fontFamily: 'Open Sans',
                  textAlign: 'left',
                  padding: '0.50rem 4.5rem',
                }}
              >
                {p.name}
              </Box>
              <Grid
                item
                container
                sx={{ width: '100%', gap: '1rem' }}
                justifyContent="center"
                alignItem="center"
              >
                <Grid item xl={5} lg={4.5} md={5.5}>
                  <Box
                    sx={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      borderRadius: '8px',
                      border: '1px solid #d9d9d9',
                      padding: '0.44rem 0.88rem',
                      gap: '1rem',
                    }}
                  >
                    <p
                      style={{
                        fontSize: '1rem',
                        fontWeight: '600',
                        fontFamily: 'Open Sans',
                      }}
                    >
                      Details
                    </p>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '0.5rem',
                        backgroundColor: '#f2ebff',
                        borderRadius: '8px',
                        padding: '0.44rem 0.88rem',
                      }}
                    >
                      <Box className="myJam">
                        <span
                          className="modalSpan"
                          style={{ color: 'GrayText' }}
                        >
                          Duration{' '}
                          <InfoOutlinedIcon
                            sx={{ color: '#4dfad2', fontSize: '14px' }}
                          />
                        </span>
                        <Box
                          sx={{
                            width: '300px',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                          }}
                        >
                          <TextField
                            id="outlined-basic"
                            variant="outlined"
                            color="secondary"
                            placeholder="Enter Value"
                            sx={{
                              width: '60%',
                              outline: 'none',
                              borderTopLeftRadius: '10px',
                              borderStartStartRadius: '10px',
                              backgroundColor: 'white',
                              textAlign: 'center',
                              '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                  border: '1px solid #f2ebff',
                                },
                                '&:hover fieldset': {
                                  border: '1px solid #711fff',
                                },
                                '&.Mui-focused fieldset': {
                                  border: '1px solid #5a0bdf',
                                },
                                '&.Mui-error fieldset': {
                                  border: '1px solid #f2ebff',
                                },
                              },
                              '& .MuiFormLabel-root': {
                                color: '#03071e66',
                              },
                              '& .MuiFormLabel-root.Mui-focused': {
                                color: '#5a0bdf',
                              },
                              '& .MuiFormLabel-root.Mui-error': {
                                color: 'red',
                              },
                            }}
                            type={'number'}
                            InputProps={{
                              inputProps: { min: 1 },
                            }}
                            onChange={(e) => {
                              const shallowCopy = dileverable.map((item) =>
                                Object.assign({}, item)
                              );
                             
                              const index = shallowCopy.findIndex(
                                (int) => Number(int.id) === p.id
                              );
                              const start = moment(
                                formData.est_startdate.split('T')[0]
                              ).toDate();
                              const end = moment(start).add(Number(e.target.value) * 7, 'days').toDate();

                              shallowCopy[index].deliverable_enddate = end;
                             
                             setDeliverable(shallowCopy)
                            
                            }
                            }

                          />
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                              backgroundColor: '#f2f2f2',
                              width: '40%',
                              color: 'GrayText',
                              borderTopRightRadius: '10px',
                              borderEndEndRadius: '10px',
                              height: '45px',
                            }}
                          >
                            <span
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              <ArrowDropUpIcon sx={{ marginBottom: '-15px' }} />
                              <ArrowDropDownIcon />
                            </span>
                            <Typography sx={{ fontSize: '14px' }}>
                              Week
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                      <Box className="myJam">
                        <span
                          className="modalSpan"
                          style={{ color: 'GrayText' }}
                        >
                          Dependency{' '}
                          <InfoOutlinedIcon
                            sx={{ color: '#4dfad2', fontSize: '15px' }}
                          />
                        </span>
                        <Autocomplete
                          id="company"
                          options={customOptions}
                          value={p.deliverable_dependency}
                          getOptionLabel={(option) => option.deliverable_name}
                          onChange={(_, value) => {
                            setDeliverable((prevDeliverables) => {
                              const updatedDeliverables = prevDeliverables.map(
                                (deliverable) => {
                                  if (Number(deliverable.id) === Number(p.id)) {
                                    return {
                                      ...deliverable,
                                      deliverable_dependency: value,
                                    };
                                  }
                                  return deliverable;
                                }
                              );

                              return updatedDeliverables;
                            });

                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="standard"
                              color="secondary"
                              InputProps={{
                                ...params.InputProps,
                                style: { width: 250 },
                              }}
                            />
                          )}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xl={6} lg={6} md={5.5}>
                  <Box
                    sx={{
                      width: '100%',
                      backgroundColor: 'white',
                      padding: '0.88rem 1rem',
                      borderRadius: '8px',
                      border: '1px solid #d9d9d9',
                      height: { xl: '170px', lg: '154px', md: '154px' },
                      overflowY: 'scroll',
                    }}
                  >
                    <span
                      style={{
                        fontWeight: '600',
                        fontFamily: 'Open Sans',
                      }}
                    >
                      Description
                    </span>
                    <Box className="df-r-j-c-gp-5 Editor-para5">
                      {' '}
                      <TextField
                        value={p.description}
                        onChange={(e) => {
                          setDeliverable((prev) => {
                            const shallowCopy = [
                              ...prev.map((item) => Object.assign({}, item)),
                            ];
                            const index = shallowCopy.findIndex(
                              (ment) => Number(ment.id) === Number(p.id)
                            );
                            shallowCopy[index].description = e.target.value;
                            return shallowCopy;
                          });
                        }}
                        sx={{
                          width: '100%',
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              borderColor: 'white',
                              padding: '10.6px 14px',
                            },
                            '&:hover fieldset': {
                              borderColor: '#fff',
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: '#fff',
                            },

                            '&.Mui-error fieldset': {
                              borderColor: '#fff',
                            },

                            '&.Mui-disabled fieldset': {
                              borderColor: 'grey',
                            },
                          },
                          '& .MuiFormLabel-root': {
                            color: '#000',
                          },
                          '& .MuiFormLabel-root.Mui-focused': {
                            color: '#fff',
                          },
                          '& .MuiFormLabel-root.Mui-error': {
                            color: '#000',
                          },
                        }}
                        multiline
                        rows={6}
                        columns={8}
                        placeholder="Enter your Description Here"
                      />
                    </Box>
                  </Box>
                </Grid>

                <Grid item xl={12} lg={12} md={12}>
                  <Box
                    sx={{
                      width: { xl: '93%', lg: '89%', md: '93%', sm: '90%' },
                      backgroundColor: '#fafafa',
                      padding: '0.88rem 1rem',
                      borderRadius: '8px',
                      margin: '0 auto',
                      height: '145px',
                    }}
                  >
                    <span
                      style={{
                        fontWeight: '600',
                        fontFamily: 'Open Sans',
                      }}
                    >
                      Internal Notes
                    </span>
                    <Box className="df-r-j-c-gp-5 Editor-para10">
                      {' '}
                      <ThemeProvider theme={myTheme}>
                        <MUIRichTextEditor
                          label="Enter Description"
                          inlineToolbar={false}
                          onChange={fieldText}
                          value={fieldEmpty}
                          controls={[
                            'bold',
                            'italic',
                            'underline',
                            'strikethrough',
                          ]}
                          customControls={[
                            {
                              name: 'stateLine',
                              icon: '|',
                              type: 'inline',
                            },
                            {
                              name: 'stateLine2',
                              icon: '|',
                              type: 'inline',
                            },
                          ]}
                        />
                      </ThemeProvider>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
              <Grid item sx={{ width: '90%', height: 'auto', padding: '10px' }}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                  }}
                >
                  <button className="cancel-btn" onClick={() => setShow(false)}>
                    Cancel
                  </button>
                  <button
                    className="create-btn"
                    onClick={() => {
                      updateWeeks();
                      setShow(false);
                      setIsTyping(false);
                    }}
                  >
                    Save
                  </button>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

const SubTaskCell = ({ c }) => (
  <TableCell align="right">
    <div className="fstxt" id={`${c.id}`}>
      {c.name}
    </div>
  </TableCell>
);
const AddTask = ({ setDeliverable, p, setOpen }) => {
  const [str, setStr] = useState('');
  return (
    <Tooltip
      title="Press Enter to Add a New Task"
      arrow
      open={str.length > 2}
      placement="bottom"
    >
      <div style={{ width: 'fit-content' }}>
        <DashedField
          label="Add New Task"
          value={str}
          onChange={(e) => setStr(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === 'Enter' && str) {
              setDeliverable((prev) => {
                const find = prev.find((ele) => ele.id === p.id);
                const int = prev.indexOf(find);
                if (int !== -1) {
                  if ('sub_task' in prev[int] && prev[int].sub_task.length) {
                    prev[int].sub_task = [
                      ...prev[int].sub_task,
                      {
                        id: prev[int].sub_task.length + 1,
                        name: e.target.value,
                      },
                    ];
                  } else {
                    prev[int].sub_task = [
                      {
                        id: 1,
                        name: e.target.value,
                      },
                    ];
                  }
                } else {
                }
                return prev;
              });
              setStr('');
              setOpen(false);
            }
          }}
        />
      </div>
    </Tooltip>
  );
};

const ShowTasks = ({ p, formData }) =>
  'sub_task' in p &&
  p.sub_task.length &&
  p.sub_task.map((c) => (
    <TableRow style={{}}>
      <SubTaskCell c={c} />
      {formData.worktype.map((i, n) => (
        <TableCell style={{ textAlign: 'center' }} key={n}>
          <DashedField2
            onChange={(e) => handleFieldChangeInside(i, e.target.value)}
            defaultValue="5"
          />
        </TableCell>
      ))}
      <TableCell align="center">
        <div style={{ width: '100%' }}> 0 </div>
      </TableCell>
    </TableRow>
  ));

function calculateTotalHoursAcumm(deliverable) {
  if (
    deliverable &&
    Array.isArray(deliverable.deliverable_worktype) &&
    deliverable.deliverable_worktype.length
  ) {
    return deliverable.deliverable_worktype.reduce((total, worktype) => {
      const net = total + Number(worktype.hours);

      return net;
    }, 0);
  }
  return 0;
}

const CustomRow = ({
  provided,
  index,
  p,
  formData,
  setDeliverable,
  dileverable,
  counter,
  setCounter,
  newData,
  setNewData,
  setIsDetOpen,
  isTyping,
  setIsTyping,
  user,
  setErrorState
}) => {
  const [open, setOpen] = useState(false);
  const access_token = useSelector((state) => state.auth.user.access);
  const dispatch = useDispatch();
  const [inputs, setInputs] = useState([]);
  useEffect(() => {
    dispatch(getWorkTypes({ access_token }));
  }, []);

  const netHours = calculateTotalHoursAcumm(p);

  useEffect(() => {
    setDeliverable((prev) => {
      const shallowCopy = [...prev];
      const index = shallowCopy.findIndex((dat) => dat.id === p.id);
      shallowCopy[index].total_loe = Number(netHours);
      return shallowCopy;
    });
  }, [netHours]);

  return (
    <>
      <TableRow
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        sx={{
          width: '100%',
          height: '100px',
          borderBottom: 'none',
          '&:last-child td, &:last-child th': { border: 0 },
        }}
        key={index}
      >
        <CustomCell
          p={p}
          setDeliverable={setDeliverable}
          setOpen={setOpen}
          dileverable={dileverable}
          setIsDetOpen={setIsDetOpen}
          formData={formData}
          setIsTyping={setIsTyping}
          access_token={access_token}
          newData={newData}
        />
        {p &&
          Array.isArray(p.deliverable_worktype) &&
          p.deliverable_worktype.length
          ? p.deliverable_worktype.map((i, n) => {
            return (
              <TableCell align="center" key={n}>
                <Box
                  sx={{
                    padding: '1.44rem 0.44rem',
                    backgroundColor: '#f1f8ff',
                    borderRadius: '8px',
                  }}
                >
                  <DashedField2
                    defaultValue={0}
                    value={i.hours}
                    onChange={(e) => {
                      setIsTyping(true)
                      setDeliverable((prev) => {
                        const shallowCopy = [...prev];
                        const int = shallowCopy.findIndex(
                          (vil) => vil.id === p.id
                        );
                        const thisInt = shallowCopy[
                          int
                        ].deliverable_worktype.findIndex(
                          (num) => parseInt(num.id, 10) === i.id
                        );
                        shallowCopy[int].deliverable_worktype[thisInt].hours =
                          e.target.value;

                        return shallowCopy;
                      });
                    }}
                    onBlur={async () => {
                      const target = formData.collaborator.find(
                        (ele) => Number(ele.collab_detail) === Number(user.id)
                      );
                      const tres = await fetch(
                        `${ENDPOINT}/api/opportunity/estimate/collaborator/update/`,
                        {
                          method: 'PUT',
                          headers: {
                            Authorization: `JWT ${access_token}`,
                            'Content-Type': 'application/json',
                          },
                          body: JSON.stringify([
                            {
                              estimate_id: newData.estimate_id,
                              collab_id: newData.collab_id,
                              financial_transparency: true,
                              is_collab_ready: true,
                              collaborator_delieverable: dileverable.map((item) => {
                                return {
                                  deliver_id: item.id,
                                  deliverable_name: item.deliverable_name,
                                  deliverable_startdate: item.deliverable_startdate,
                                  deliverable_enddate: item.deliverable_enddate,
                                  total_hours: item.total_loe,
                                  description: '',
                                  user_price: Number(0),
                                  our_price: Number(0),
                                  loe_price: Number(0),
                                  deliverable_dependency: null,
                                  collaborator_worktype: item.deliverable_worktype.map(
                                    (ti) => ({
                                      id: `${ti.id}`,
                                      hours: `${Number(ti.hours)}`,
                                    })
                                  ),
                                };
                              }),
                              estimate_id: Number(formData.id),
                              collab_id: Number(target.id),
                              collaborator_members: [user.email],
                            },
                          ]),
                        }
                      );

                      const tdata = await tres.json();

                      if (!tdata) {
                        setErrorState({
                          error: true,
                          message: 'Something went wrong Updating Your estimate.',
                        });
                        return;
                      }
                      else {
                        setIsTyping(false)
                      }

                    }}
                  />
                </Box>
              </TableCell>
            );
          })
          : null}
        <TableCell style={{ fontWeight: 'bold', textAlign: 'center' }}>
          <Box
            sx={{
              padding: '1.44rem 0.44rem',
              backgroundColor: '#f1f8ff',
              borderRadius: '8px',
            }}
          >
            {netHours ? `${netHours}hr` : '0h'}
          </Box>
        </TableCell>
      </TableRow>
      <TableRow>
        {open ? (
          <TableCell
            colSpan={12}
            sx={{ backgroundColor: open ? '#f8f9fb' : '#fff' }}
          >
            <Collapse
              in={open}
              sx={{
                width: '100%',
              }}
            >
              <TableContainer component="div">
                <Table>
                  <TableBody>
                    <ShowTasks p={p} newData={newData} />
                    <TableRow>
                      <TableCell align="left">
                        <AddTask
                          setDeliverable={setDeliverable}
                          p={p}
                          setOpen={setOpen}
                        />
                      </TableCell>
                      {newData.collaborator_worktype.map((l, m) => (
                        <TableCell
                          align="left"
                          key={Number(l.id) + Math.random() * 100}
                        >
                          <div style={{ width: '100%', height: '100%' }} />
                        </TableCell>
                      ))}
                      <TableCell align="right">
                        <div style={{ width: '100%', height: '100%' }} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Collapse>
          </TableCell>
        ) : null}
      </TableRow>
    </>
  );
};

const EstTable = ({
  formData,
  setFormData,
  steps,
  dileverable,
  setDeliverable,
  newData,
  setNewData,
  setIsDetOpen,
  target,
  isTyping,
  setIsTyping,
  user,
  setTarget,
  setErrorState
}) => {
  const [counter, setCounter] = useState(0);

  const handleOnDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const items = Array.from(dileverable);
    const [reorderediItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderediItem);
    setDeliverable(items);
  };


  return dileverable.length ? (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <Droppable droppableId="rows">
        {(provided) => (
          <TableContainer
            {...provided.droppableProps}
            ref={provided.innerRef}
            component={Paper}
            sx={{
              width: '100%',
              backgroundColor: '#fff',
            }}
          >
            <Table aria-label="simple table">
              <THead newData={newData} />
              <TableBody>
                {dileverable.map((p, index) => (
                  <Draggable
                    key={p.id}
                    draggableId={p.deliverable_name}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <CustomRow
                        provided={provided}
                        snapshot={snapshot}
                        index={index}
                        p={p}
                        formData={formData}
                        setDeliverable={setDeliverable}
                        dileverable={dileverable}
                        counter={counter}
                        setCounter={setCounter}
                        newData={newData}
                        setNewData={setNewData}
                        setIsDetOpen={setIsDetOpen}
                        isTyping={isTyping}
                        setIsTyping={setIsTyping}
                        user={user}
                        setErrorState={setErrorState}
                      />
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}

                <AddRows
                  newData={newData}
                  dileverable={dileverable}
                  setDeliverable={setDeliverable}
                  formData={formData}
                  user={user}
                  target={target}
                  setTarget={setTarget}
                  setFormData={setFormData}
                  setNewData={setNewData}
                  setErrorState={setErrorState}
                />

                <LastRow
                  newData={newData}
                  dileverable={dileverable}
                  counter={counter}
                  setCounter={setCounter}
                />
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Droppable>
    </DragDropContext>
  ) : (
    <Table aria-label="simple table">
      <THead newData={newData} />
      <TableBody>

        <AddRows
          newData={newData}
          dileverable={dileverable}
          setDeliverable={setDeliverable}
          formData={formData}
          user={user}
          target={target}
          setTarget={setTarget}
          setFormData={setFormData}
          setNewData={setNewData}
          setErrorState={setErrorState}
        />

        <LastRow newData={newData} />
      </TableBody>
    </Table>
  );
};

const ColabSecond = ({
  formData,
  setFormData,
  steps,
  dileverable,
  setDeliverable,
  newData,
  setNewData,
  setIsDetOpen,
  target,
  setTarget,
  setErrorState
}) => {
  const access_token = useSelector((state) => state.auth.user.access);
  const user = useSelector((state) => state.userData.userData);
  const [isTyping, setIsTyping] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      const res = await fetch(`${ENDPOINT}/api/opportunity/estimate/${formData?.id}/`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `JWT ${access_token}`,
        },
      });
      const data = await res.json();
      if (!res.ok) {
      }
      setFormData(data)
      if (data && 'id' in data) {
        const target = data.collaborator.find(
          (ele) => Number(ele.collab_detail) === Number(user.id)
        );
        setTarget(target);
        if (
          target &&
          Array.isArray(target.collaborator_deliverable) &&
          target.collaborator_deliverable.length
        ) {
          setNewData({
            ...newData,
            estimate_id: formData.id ? Number(formData.id) : '',
            collab_id: target.id ? Number(target.id) : 0,
            collaborator_members: [],
            collaborator_worktype:
              target.collaborator_deliverable[0].deliverable_worktype,
          });
          setDeliverable(target.collaborator_deliverable);
        }
      }
    };

    // Set up the interval
    const intervalId = setInterval(() => {
      if (isTyping === false) {
        fetchData();
      }
    }, 3000);

    // Clean up the interval when the component unmounts or when some condition is met
    return () => {
      clearInterval(intervalId);
    };
  }, [isTyping]);

  return (
    <Grid item container direction="column">
      <EstTable
        formData={formData}
        setFormData={setFormData}
        steps={steps}
        dileverable={dileverable}
        setDeliverable={setDeliverable}
        newData={newData}
        setNewData={setNewData}
        setIsDetOpen={setIsDetOpen}
        target={target}
        isTyping={isTyping}
        setIsTyping={setIsTyping}
        user={user}
        setTarget={setTarget}
        setErrorState={setErrorState}
      />
    </Grid>
  );
}


export default ColabSecond;
