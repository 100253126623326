import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Card from "@mui/material/Card";
import { Link } from "react-router-dom";
import TagChips from "./TagChips";
import { useState } from "react";
import SettingsIcon from "@mui/icons-material/Settings";
import AddChapterTable from "./AddChapterTable";
import AddLesson from "./AddLesson/AddLesson";


const NetNetUCourseView = () => {

  const [newChapter, setNewChapter] = useState(false);
  const [chapterName, setChapterName] = useState("");
  const [chapterNameAdded, setChapterNameAdded] = useState([]);
  const [allChapters, setAllChapters] = useState([]);
  const [expandLessonForm, setExpandLessonForm] = useState(false);

  const [tagData, setTagData] = useState([
    { key: 0, label: "Starting Up" },
    { key: 1, label: "Basic Information" },
    { key: 2, label: "Acount Information" },
  ]);


  const expand = () => {
    setNewChapter(true);
    const chapterCollection = [...chapterNameAdded, chapterName];
    setAllChapters(chapterCollection);
    setChapterNameAdded(chapterCollection);
    setChapterName("");
  };
 
  const goBack2 = () => {
    setNewChapter(false);
  };
  return (
    <>
      <div className="netUWrapper">
        <div className="filledBox">
          <div className="courseTitle">Starting Up With Net Net</div>
          <div className="courseDesc">
            Learning the basics, introduction setting up your account and going
            along with the welcome screen
          </div>
          <div className="youTubeLink">
            <Link
              to="https://youtube.com/watch?v=a8fOAkGb8m0&ab_channel=MarcPitre"
              target="_blank"
            >
              youtube.com/watch?v=a8fOAkGb8m0&ab_channel=MarcPitre
            </Link>
          </div>
          <div className="chipTags">
            <TagChips tagData={tagData} setTagData={setTagData} />
          </div>
        </div>
        {/*  */}
        {!expandLessonForm ? (
          <>
            <div className="addYourChapter">
              <div
                style={{
                  width: "100%",
                  position: "relative",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  gap: "7px",
                  textAlign: "left",
                  fontSize: "20px",
                  color: "#000",
                  fontFamily: "'Open Sans'",
                }}
              >
                <b style={{ position: "relative", lineHeight: "130%" }}>
                  Add Your Chapters
                </b>
                <div
                  style={{
                    position: "relative",
                    fontSize: "16px",
                    lineHeight: "100%",
                  }}
                >
                  Please Add your chapters and lessons
                </div>
              </div>
            </div>

            <div className="chapterName">
              <Card
                sx={{
                  padding: "32px",
                  display: "flex",
                  gap: "16px",
                  alignItems: "center",
                  justifyContent:"center"
                }}
              >
                <TextField
                  sx={{
                    maxWidth: "824px",
                    height: "51px",
                    borderRadius: "4px",
                    borderColor: "#d9d9d9",
                  }}
                  name="chapterName"
                  value={chapterName}
                  onChange={(e) => setChapterName(e.target.value)}
                  fullWidth
                  label="Chapter Name"
                  variant="outlined"
                  placeholder="Enter Name of Your Chapter"
                />
                <div className="settings">
                  <Button
                    onClick={expand}
                    sx={{
                      width: "125px",
                      height: 40,
                      borderRadius: "4px",
                      backgroundColor: "#711FFF",
                      lineHeight: "19px",
                      fontSize: "14px",
                      paddingX: 1,
                      "&:hover": {
                        backgroundColor: "#711DDD",
                      },
                    }}
                    variant="contained"
                  >
                    ADD CHAPTER
                  </Button>
                  <SettingsIcon
                    sx={{
                      width: 24,
                      height: 24,
                    }}
                  />
                </div>
              </Card>
            </div>
            {newChapter && (
              <div className="chapters">
                {/* <AddChapterCard /> */}
                <AddChapterTable
                  setExpandLessonForm={setExpandLessonForm}
                  chapterNameAdded={allChapters}
                />
              </div>
            )}
            {/* </Paper> */}
            {/* </div> */}
            {newChapter && (
              <div className="btn-go-back">
                <Button
                  onClick={goBack2}
                  variant="contained"
                  sx={{
                    backgroundColor: "rgba(0, 0, 0, 0.06)",
                    color: "black",
                    textTransform: "capitalize",
                    // width: "100%",
                    paddingX: 2,
                    '&:hover':{
                      backgroundColor: "rgba(0, 0, 0, 0.06)",
                      color: "black",
                      opacity: "0.8"
                      }
                  }}
                >
                  Go Back to All Courses
                </Button>
              </div>
            )}
          </>
        ) : (
          <>
            <AddLesson chapterNameAdded={allChapters} />
            <div className="backButtons">
              <Button
                onClick={() => {
                  setExpandLessonForm(false);
                }}
                variant="contained"
                sx={{
                  backgroundColor: "rgba(0, 0, 0, 0.06)",
                  color: "black",
                  textTransform: "capitalize",
                  paddingX: 2,
                  '&:hover':{
                    backgroundColor: "rgba(0, 0, 0, 0.06)",
                    color: "black",
                    opacity: "0.8"
                    }
                }}
              >
                Go Back
              </Button>
              <Button
                onClick={() => {
                  setExpandLessonForm(false);
                  expand();
                }}
                variant="contained"
                
                 sx={{
                  "&:hover": {
                    backgroundColor: "#711DDD",
                    color: "white"
                  },
                  backgroundColor: "#711FFF",
                  textTransform: "capitalize",
                  fontFamily: "Open Sans"

                }}
              >
                Save
              </Button>
            </div>
          </>
        )}
        {/*  */}
        {!newChapter && (
          <div className="backButtons">
            <Link to={"/SuperAdmin/NetNetU/Courses/New"} >
              <Button
                variant="contained"
                sx={{
                  "&:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.06)",
                  },
                  backgroundColor: "rgba(0, 0, 0, 0.06)",
                  color: "black",
                  textTransform: "capitalize",
                  width: "84px",
                  paddingX: 1,
                  fontFamily: "Open Sans"
                }}
              >
                Go Back
              </Button>
            </Link>
            <Link to="">
              <Button
                variant="contained"
                sx={{
                  "&:hover": {
                    backgroundColor: "#711DDD",
                    color: "white"
                  },
                  backgroundColor: "#711FFF",
                  textTransform: "capitalize",
                  width: "100%",
                  fontFamily: "Open Sans"

                }}
              >
                Continue
              </Button>
            </Link>
          </div>
        )}
      </div>
    </>
  );
};

export default NetNetUCourseView;
