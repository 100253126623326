import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Alert, Box } from '@mui/material';
import { useDispatch } from 'react-redux';
import { clearRecord } from '../ContactRedux';

const Head = () => (
  <TableHead
    sx={{
      height: '90.12px',
      backgroundColor: '#e7e7e7',
      borderRadius: '8px',
      borderBottom: '1.5px solid #c7c7c7',
    }}
  >
    <TableRow>
      <TableCell align="left">
        <div className="fstxt">Name</div>
      </TableCell>
      <TableCell align="left">
        <div className="fstxt">New Record</div>
      </TableCell>
      <TableCell align="left">
        <div className="fstxt">Updated Records</div>
      </TableCell>
      <TableCell align="left">
        <div className="fstxt">Error Count</div>
      </TableCell>
      <TableCell align="left">
        <div className="fstxt">Source</div>
      </TableCell>
      <TableCell align="left">
        <div className="fstxt">User</div>
      </TableCell>
      <TableCell align="right">
        <div className="fstxt">Date</div>
      </TableCell>
    </TableRow>
  </TableHead>
);

const Body = ({
  myFile,
  prevData,
  exceptionCount,
  constructor,
  beData,
  realTarget,
}) => {
  const currentUser = useSelector((state) => state.userData.userData);
  const dateToday = new Date();
  const date = `${dateToday.getFullYear()}-${dateToday.getMonth() + 1
    }-${dateToday.getDate()}`;

  return realTarget && 'Done' in realTarget ? (
    <TableBody>
      <TableRow
        sx={{
          height: '110px',
          backgroundColor: '#fff',
        }}
      >
        <TableCell align="left">
          <div className="fstxt">
            {myFile && myFile.name ? myFile.name : 'User CSV File'}
          </div>
        </TableCell>
        <TableCell align="left">
          <div className="fstxt">{realTarget.Done ? realTarget.Done : '0'}</div>
        </TableCell>

        <TableCell align="left">
          <div className="fstxt">
            0
          </div>
        </TableCell>

        <TableCell align="left">
          <div className="fstxt">
            {realTarget.Failed ? realTarget.Failed : exceptionCount || 0}
          </div>
        </TableCell>
        <TableCell align="left">
          <div className="fstxt">CSV</div>
        </TableCell>
        <TableCell align="left">
          <div className="fstxt">
            {currentUser.first_name} {'  '} {currentUser.last_name}
          </div>
        </TableCell>
        <TableCell align="left">
          <div className="fstxt">{date}</div>
        </TableCell>
      </TableRow>
    </TableBody>
  ) : (
    <>
      <Alert severity="error">{'Failed To Import' + realTarget?.failed_creations?.companies?.length + ' Companies and ' + realTarget?.failed_creations?.contacts?.length + ' Contacts'}</Alert>
    </>
  );
};
const FifthStep = ({
  step,
  setStep,
  myFile,
  prevData,
  exceptionCount,
  constructor,
  beData,
}) => {
  const navigate = useNavigate();
  const target = useSelector((state) => state.Contacts.target);
  const dispatch = useDispatch();
  const [realTarget, setRealTarget] = useState({});
  useEffect(() => {
    if (target && 'import_view_response' in target) {
      setRealTarget(target.import_view_response);
    }
  }, [target]);
  return (
    <section className="oppor-section">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '2rem',
          height: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: '90%',
            backgroundColor: '#f2ebff',
            borderRadius: '8px',
            padding: '0.5rem 0.80rem',
            border: '1px solid #d9d9d9',
          }}
        >
          <h3 style={{ color: '#000' }} className="txtCenter txtSM">Congratulations!</h3>
          <p className="txtCenter initialParagraph txtESM">
            Awesome Job. You&apos;re all done.
          </p>
        </Box>
        <Box sx={{ width: '100%' }}>
          <TableContainer
            component={Paper}
            sx={{
              width: '100%',
              backgroundColor: '#fff',
            }}
          >
            <Table aria-label="Data table">
              <Head />
              <Body
                myFile={myFile}
                prevData={prevData}
                exceptionCount={exceptionCount}
                constructor={constructor}
                beData={beData}
                realTarget={realTarget}
              />
            </Table>
          </TableContainer>
          {realTarget?.Failed > 0 && (
            <p className="error">
              {realTarget?.failed_creations?.contacts?.length} Contacts couldn&apos;t be imported. This may be result
              of Incorrect Formate.
              <Alert severity="error">
                {
                  realTarget?.failed_creations?.contacts?.map((contact, index) => (
                    <div key={index}>
                      {contact?.error && contact?.error?.email && contact?.error?.email?.error
                        ? `Error for contact ${index + 1}: ${contact?.error?.email?.error}`
                        : ''}
                    </div>
                  ))
                }
              </Alert>
            </p>
          )}
        </Box>
        <section className="AlBottom footer">
          <ul className="footer-list">
            <li className={step === 2 ? 'footer-item thisStep' : 'footer-item'}>
              Import Files
            </li>
            <li className={step === 3 ? 'footer-item thisStep' : 'footer-item'}>
              Map Fields
            </li>
            <li className={step === 4 ? 'footer-item thisStep' : 'footer-item'}>
              Type
            </li>
            <li className={step === 5 ? 'footer-item thisStep' : 'footer-item'}>
              Exceptions
            </li>
          </ul>
          <div className="AlRight dd-import-group">
            {' '}
            <button
              type="button"
              className="smBtn"
              onClick={(e) => {
                dispatch(clearRecord(true));
                navigate('/People');
              }}
              style={{
                marginBottom: '1rem',
              }}
            >
              Finish
            </button>
          </div>
        </section>
      </Box>
    </section>
  );
};

export default FifthStep;
