import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { TextField, Alert, Typography, Box, MenuItem} from "@mui/material";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import { ENDPOINT,} from '../../../Opportunity/Redux/oppor';
import { getTeam } from '../../../Stream/streamRedux';
import Bounce from 'react-reveal/Bounce';

const Company = ({ funcAlert }) => {
    const access_token = useSelector((state) => state.auth.user.access);
    
    const dispatch = useDispatch()
  
    useEffect(() => {
  
      // Get the current URL
      const url = new URL(window.location.href);
  
      // You can also get all query parameters as an object
      const queryParams = Object.fromEntries(url.searchParams.entries());
  
      async function apiIntent() {
        try {
  
          const response = await fetch(`${ENDPOINT}/api/sub/update-payment-method/`, {
            method: "POST",
            headers: {
              Authorization: `JWT ${access_token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ "payment_intent_id": queryParams?.payment_intent })
          });
  
          const data = await response.json();
          if (!response.ok) {
  
          } else {
            dispatch(getTeam({ access_token }))    
         }
        }
        catch (error) {
          console.error('error throw api ', error)
  
        }
      }
  
      if (queryParams?.redirect_status) {
  
        apiIntent()
      }
  
  
    }, [])
  
    const companies = [
      'Advertising (Branding, Design, PR, Marketing, etc.)',
      'Consulting Services',
      'Digital Promotions (PPC, SEO, Marketing Automation)',
      'Custom Software & App Developers',
      'IT Services',
      'Photography',
      'Video & Animation Production',
      'Web Design',
      'Other Professional Services',
      // 'Employee',
    ];
  
    const [editMode, setEditMode] = useState(false); // Track edit mode
  
    const toggleEditMode = () => {
      setEditMode(!editMode); // Toggle edit mode
      if (editMode) {
        handleUpdateCompanyInformation()
  
      }
    };
  
    const handleWebAddress = (v, i) => {
  
      let filterWebAddres = Array.isArray(inputs.company_url) && inputs.company_url.filter((val) => {
        return val.id !== v.id
      })
  
      setInputs(preState => ({
        ...preState,
        company_url: filterWebAddres
      }))
    }  
  
    useEffect(() => {
      
      const api = async () => {
        try {
          
          const dateApi = await fetch(`${ENDPOINT}/api/user/team/`, {
            method: "GET",
            headers: {
              Authorization: `JWT ${access_token}`,
              "Content-Type": "application/json",
            }
          }
          )
          let data = await dateApi.json()
          console.error( data, 'account billing')
          if (dateApi.ok) {
            
            let userCompany = data.member[0].user_company
            setInputs(prevInputs => ({
              ...prevInputs,
              id: userCompany?.id,
              name: userCompany?.name,
              office_num: userCompany?.office_num,
              street: userCompany?.street,
              email: userCompany?.email,
              Type: userCompany?.Type,
              company_url: userCompany?.company_url
            }));
          }
          else {
          }
        }
        catch (error) {
          console.error('failed to Expiry Date', error)
        }
      }
  
      api()
    }, []);
  
    const [inputs, setInputs] = useState({
      id: '',
      name: '',
      office_num: '',
      street: '',
      email: '',
      Type: '',
      company_url: [],
    })
    const handleUpdateCompanyInformation = async () => {
      try {
        const response = await fetch(`${ENDPOINT}/api/user/team/company/update/${inputs.id}/`, {
          method: "PUT",
          headers: {
            Authorization: `JWT ${access_token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(inputs)
        });
  
        const data = await response.json();
        if (!response.ok) {
  
        } else {
  
        }
      }
      catch (error) {
        console.error('error throw api ', error)
      }
    }
  
    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setInputs(prevInputs => ({
        ...prevInputs,
        [name]: value
      }));
    }
   
    const handleChangeWebAddress = (index) => (e) => {
      const newInputs = { ...inputs };
      newInputs.company_url[index].domain_name = e.target.value;
      setInputs(newInputs);
    };
  
    return (
      <Bounce bottom>
        <>
          <Box sx={{ display: "flex", marginBottom: "24px", justifyContent: "space-between" }} className='tw-flex w-100 tw-mb-6 tw-justify-between'>
            <Typography></Typography>
            <Typography
              sx={{ display: "flex", justifyContent: "center", alignItems: "center", color: "black", fontSize: "16px", cursor: "pointer" }}
              className='tw-flex tw-justify-center tw-items-center tw-text-black tw-text-lg tw-cursor-pointer tw-px-4'
              onClick={toggleEditMode} // Toggle edit mode when clicking "Edit" button
            >
              <CreateOutlinedIcon />
              <span style={{ marginRight: '20px' }} className=''>{editMode ? 'Save' : 'Edit'}</span> {/* Change button text based on edit mode */}
            </Typography>
          </Box>
          <Box sx={{ background: "#F8F9FA", borderRadius: "4px" }} className="">
            <Box sx={{ padding: "16px 16px" }} className="tw-px-4 tw-py-4 ">
              <Box sx={{ display: "flex", flexWrap: "wrap", width: { sm: '100%', md: '60%', lg: '50%' }, marginBottom: "8px", }} className="tw-flex flex-wrap lg:tw-w-4/5  sm:tw-mb-2 -mx-2">
                <Box sx={{ padding: "8px 8px", marginBottom: "12px", width: "50%" }} className="tw-p-2 tw-mb-3 sm:tw-w-1/2 tw-w-full">
                  <TextField
                    fullWidth
                    sx={{
                      width: { sm: '100%', md: '80%' },
                      "& .MuiInputLabel-root": {
                        color: "#711FFF", // Change this to the desired label color
                        fontSize: "16px",
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "#711FFF", // Change this to the desired bottom line color
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "#711FFF", // Change this to the desired bottom line color
                      },
                      "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                        borderBottomColor: "#711FFF", // Change the hover color
                      },
                      "& .MuiInput-underline.Mui-focused:after": {
                        borderBottomColor: "#711FFF", // Change the focused color
                      },
                      "& .MuiInputLabel-root.Mui-focused": {
                        color: "#711FFF", // Change this to the desired focused label color
                      },
                    }}
                    id="standard-required"
                    label="Company Name"
                    value={inputs?.name}
                    name='name'
                    onChange={(e) => handleInputChange(e)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="standard"
                    InputProps={{ readOnly: !editMode }}
                  />
                </Box>
                <Box sx={{ padding: "8px 8px", marginBottom: "12px", width: "50%" }} className="tw-p-2 tw-mb-3 tw-w-full">
                  <TextField
                    fullWidth
                    sx={{
                      width: { sm: '100%', md: '80%' },
                      "& .MuiInputLabel-root": {
                        color: "#711FFF", // Change this to the desired label color
                        fontSize: "16px",
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "#711FFF", // Change this to the desired bottom line color
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "#711FFF", // Change this to the desired bottom line color
                      },
                      "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                        borderBottomColor: "#711FFF", // Change the hover color
                      },
                      "& .MuiInput-underline.Mui-focused:after": {
                        borderBottomColor: "#711FFF", // Change the focused color
                      },
                      "& .MuiInputLabel-root.Mui-focused": {
                        color: "#711FFF", // Change this to the desired focused label color
                      },
                    }}
                    id="standard-number"
                    label="Phone Number"
                    type="number"
                    value={inputs?.office_num}
                    name='office_num'
                    onChange={handleInputChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="standard"
                    InputProps={{ readOnly: !editMode }}
                  />
                </Box>
                <Box sx={{ padding: "8px 8px", width: "50%" }} className="tw-p-2 tw-mb-3  tw-w-full">
                  <TextField
                    sx={{
                      width: { sm: '100%', md: '80%' },
                      display: "flex",
                      flexWrap: "wrap",
                      "& .MuiInputLabel-root": {
                        color: "#711FFF", // Change this to the desired label color
                        fontSize: "16px",
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "#711FFF", // Change this to the desired bottom line color
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "#711FFF", // Change this to the desired bottom line color
                      },
                      "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                        borderBottomColor: "#711FFF", // Change the hover color
                      },
                      "& .MuiInput-underline.Mui-focused:after": {
                        borderBottomColor: "#711FFF", // Change the focused color
                      },
                      "& .MuiInputLabel-root.Mui-focused": {
                        color: "#711FFF", // Change this to the desired focused label color
                      },
                    }}
                    id="standard-required"
                    label="Mailing Address"
                    value={inputs?.street}
                    name='street'
                    onChange={handleInputChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="standard"
                    InputProps={{ readOnly: !editMode }}
                  />
                </Box>
                <Box sx={{ padding: "0sspx 8px", marginTop: "0px", width: "50%" }} className="tw-p-2 tw-mb-3 sm:tw-w-1/2 tw-w-full">
                  <TextField
                    fullWidth
                    sx={{
                      width: { sm: '100%', md: '80%' },
                      border: "none",
                      "& .MuiFilledInput-root": {
                        backgroundColor: "#f2ebff",
                        borderRadius: "8px", // Set the desired !border radius value
                      },
                      "& .MuiInputLabel-root": {
                        color: "#711FFF", // Change this to the desired label color
                        fontSize: "13px",
                        fontWeight: 600,
                      },
                      "& .MuiFilledInput-underline:before": {
                        borderBottom: "none", // Set the borderBottom property to "none"
                      },
                      "& .MuiFilledInput-underline:hover:not(.Mui-disabled):before": {
                        borderBottom: "none", // Remove the hover line
                      },
                      "& .MuiFilledInput-underline:after": {
                        borderBottom: "none", // Set the borderBottom property to "none"
                      },
                      "& .MuiInput-underline.Mui-focused:after": {
                        borderBottomColor: "#711FFF", // Change the focused color
                      },
                      "& .MuiInputLabel-root.Mui-focused": {
                        color: "#711FFF", // Change this to the desired focused label color
                      },
                      '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: theme => theme.palette.secondary.main,
                      },
                    }}
                    id="standard-required"
                    label="EMAIL ID"
                    value={inputs?.email}
                    name='email'
                    onChange={handleInputChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="filled"
                    InputProps={{ readOnly: !editMode }}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
          <div className="text-gray-600 body-font ">
            <div className="px-4 py-4 h-100">
              <p style={{ color: "#711FFF" }} className='pb-4 text-[#711FFF]'>Company Web Address</p>
              <Box sx={{ width: { sm: '100%', md: '60%', lg: '50%' }, marginBottom: "8px" }} className="d-flex flex-wrap sm:tw-mb-2 -mx-2">
                <Box className="" sx={{ width: { sm: '100%', md: '50%' } }}>
  
                  {Array.isArray(inputs.company_url) && inputs.company_url.length ? (
                    inputs.company_url.map((v, i) => {
                      return <>
                        <Box key={i} sx={{ backgroundColor: '#f2ebff', display: 'flex', justifyContent: 'space-between', alignItems: "center", borderRadius: 2, p: 0.1, width: '78%', my: 1 }}>
                          <TextField
                            sx={{
                              width: { sm: '100%', md: '80%' },
                              "& .MuiFilledInput-root": {
                                backgroundColor: "#f2ebff",
                                borderRadius: "8px", // Set the desired !border radius value
                              },
                              "& .MuiInputLabel-root": {
                                color: "#711FFF", // Change this to the desired label color
                                fontWeight: 600,
                              },
                              "& .MuiFilledInput-underline:before": {
                                borderBottom: "none", // Set the borderBottom property to "none"
                              },
                              "& .MuiFilledInput-underline:hover:not(.Mui-disabled):before": {
                                borderBottom: "none", // Remove the hover line
                              },
                              "& .MuiFilledInput-underline:after": {
                                borderBottom: "none", // Set the borderBottom property to "none"
                              },
                              "&:hover": {
                                // borderBottom: "none", // Set the borderBottom property to "none"
                                backgroundColor: 'inherit'
                              },  
                            }}
                            id="standard-required"
                            label="Web Address"
                            value={v.domain_name}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            variant="filled"
                            InputProps={{ readOnly: !editMode }}
                            onChange={handleChangeWebAddress(i)}
                          />
                          {editMode &&
                            <DeleteOutlineIcon style={{ cursor: 'pointer', marginRight: '10px' }} onClick={() => handleWebAddress(v,)} />
                          }
                        </Box>
                      </>
                    })
                  ) : (
                    <Alert severity="info" style={{ width: '85%' }}>You do not have any Web Address...</Alert>
                  )}
                </Box>
                <Box className=" col-sm-6 col-12 " sx={{ width: { sm: '100%', md: '50%', marginLeft: "auto" } }}>
                  <label className='' style={{ color: '#711FFF', width: '80%' }}>What Choice most Describes your Company</label>
                  <TextField
                    fullWidth
                    sx={{
                      width: { xs: '80%', md: '80%' },
                      "& .MuiInputLabel-root": {
                        color: "#711FFF", // Change this to the desired label color
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "#711FFF", // Change this to the desired bottom line color
                      },
                      "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                        borderBottomColor: "#711FFF", // Change this to the desired hover color
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "#711FFF", // Change this to the desired bottom line color
                      },
                    }}
                    id="standard-select-currency"
                    select
                    // label="What Choice most Describes your Company"
                    value={inputs?.Type}
                    name='Type'
                    onChange={handleInputChange}
                    variant="standard"
                    InputProps={{ readOnly: !editMode }}
                  >
                    {companies.map((option, i) => (
                      <MenuItem key={i} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                </Box>
              </Box>
            </div>
          </div>
        </>
      </Bounce>
    );
  };


 export default Company  