import { Link, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import filterIcon from "./sort 1.svg";
import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import './authors.css'
import { useDispatch, useSelector } from "react-redux";
import { getAuthors } from "./authorsReducer";
import Box from '@mui/material/Box';

const dummyAuthor = [
  {
    authorName: "NetNet",
    company: "AliTech",
    url : "//netnet.com",
    email: "thenetnet08@gmail.com",
    id:1
  },
  {
    authorName: "Elon Musk",
    company: "SpaceX",
    url : "//spaceX.com",
    email: "thespacex08@gmail.com",
    id:2
  },
  {
    authorName: "Elon Musk2",
    company: "Space2X",
    url : "//spaceX2.com",
    email: "thespacex0822@gmail.com",
    id:3
  },
  {
    authorName: "Elon Musk2",
    company: "Space2X",
    url : "//spaceX2.com",
    email: "thespacex0822@gmail.com",
    id:3
  },
  {
    authorName: "Elon2",
    company: "Space2X",
    url : "//spaceX2.com",
    email: "thespacex0822@gmail.com",
    id:3
  }
]

const Header = () => {
  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        padding: " 1% 1.38% 1% 1.38%",
        boxSizing: "border-box",
        textAlign: "center",
        fontSize: "29px",
        color: "#03071e",
        fontFamily: "'Open Sans'",
        backgroundColor: "white",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
          gap: "20px",
        }}
      >
        <b style={{ position: "relative", lineHeight: "100%" }}>
          Net Net U Authors
        </b>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            textAlign: "left",
            fontSize: "14px",
            color: "rgba(3, 7, 30, 0.4)",
          }}
        >
          <div
            style={{
              borderRadius: "4px",
              overflow: "hidden",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              gap: "3px",
            }}
          >
            <img
              style={{
                position: "relative",
                width: "18px",
                height: "18px",
                overflow: "hidden",
                flexShrink: "0",
              }}
              alt=""
              src={filterIcon}
            />
            <div
              style={{
                position: "relative",
                letterSpacing: "-0.3px",
                lineHeight: "19px",
              }}
            >
              Filters
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          padding: "5px 0 ",
          fontSize: "14px",
          color: "#fff",
        }}
      >
        <Link to="/SuperAdmin/NetNetU/Authors/New">
          <Button
            sx={{
              width: 108,
              height: 35,
              textTransform: "capitalize",
              fontWeight: "300",
              backgroundColor: "#711FFF",
              lineHeight: "19px",
              fontSize: "14px",
              "&:hover": {
                backgroundColor: "#711DDD",
              },
            }}
            variant="contained"
          >
            + New
          </Button>
        </Link>
      </div>
    </div>
  );
};

const Authors = ({screenHeight}) => {


  const dispatch = useDispatch();
  const access_token = useSelector((state) => state.auth.user.access);

  useEffect(() => {
    const meta = {
      access_token,
    };
    dispatch(getAuthors(meta))
      .then(() =>{} )
      .catch((error) => {
        console.error("Error fetching authors:", error);
      });
  }, [dispatch, access_token]);

  const columns = [
    {
      field: "authorName",
      headerName: "author Name",
      width: 180,
      flex: 1,
      renderHeader: () => (
        <div className="tableHeader">
          Person Name
          <ArrowDropDownIcon style={{ marginLeft: "-12px" }} />
        </div>
      ),
      renderCell: (params) => (
        <div className="td breakSpace">
          <img src={params.value} style={{ borderRadius: "50%", width: '24px', height: "24px", marginRight: "3px" }} />
          {params.row.authorName}
        </div>
      ),
    },
    {
      field: "company",
      headerName: "Company",
      width: 160,
      flex: 1,
      renderHeader: () => <div className="tableHeader">Company <ArrowDropDownIcon style={{ marginLeft: "-12px" }} /></div>,
      renderCell: (params) => <div className="td breakSpace">{params.value}</div>,
    },
    {
      field: "url",
      headerName: "URL",
      minWidth: 120,
      flex: 1,
      renderHeader: () => <div className="tableHeader">URL <ArrowDropDownIcon style={{ marginLeft: "-12px" }} /></div>,
      renderCell: (params) => <div className="td breakSpace">{params.value}</div>,
    },
    {
      field: "email",
      headerName: "Email",
      width: 120,
      flex: 1,
      renderHeader: () => <div className="tableHeader">Email <ArrowDropDownIcon style={{ marginLeft: "-12px" }} /></div>,
      renderCell: (params) => <div className="td breakSpace">{params.value}</div>,
    },
  ];

  return (
    <>
      <section className="oppor-section">
      <div className="oppor-row">
            <div className="oppor-col">
            </div>
            <Header /> 

          </div>
      
      <div style={{
        padding:"4px 18px"
        }}
        className="authorsWrapper" >
       
        <Box
          sx={{
            height: screenHeight - 0,
            overflowY: "auto"
          }}>

          <DataGrid
            rows={Array.isArray(dummyAuthor) && dummyAuthor.length ? dummyAuthor : []}
            columns={columns}
            rowHeight={76}
            headerHeight={78}
            sx={{
              '.MuiDataGrid-row': {
                color: '#000',
                fontWeight: 500,
                fontFamily: 'Open Sans, sans-serif',
                fontSize: '14px',
                letterSpacing: "0.5px",
                backgroundColor: "#FDFDFE",
                maxHeight: "76px"
              },
              '.MuiDataGrid-columnHeader': {
                color: '#03071E99',
                backgroundColor: "#F2F2F2",
                fontFamily: 'Open Sans, sans-serif',
              },
              '.css-1q27lj3-MuiDataGrid-root': {
                borderRadius: "10px"
              },
              '.MuiDataGrid-columnHeaders ': {
                zIndex: 1,
                backgroundColor: '#F2F2F2',
                borderTopLeftRadius: "10px",
                borderTopRightRadius: "10px",
              },
              '.tableHeader': {
                color: ' #03071E99',
              },
              minHeight: screenHeight - 170,
              maxHeight: screenHeight - 170,
              border: '1px solid rgba(224, 224, 224, 1)',
              borderRadius: "10px",
              margin: "1.38%"
            }}
            checkboxSelection
          />
        </Box>

      </div>
      </section>
    </>
  );
};

export default Authors;
