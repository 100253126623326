import { CreateAccordion } from '../ProjectUtility';
import DesignOne, { DesignTwo } from '../../Deliverable/design';
import { useEffect, useState } from 'react';
import ListIco from '../assets/ListIco.png';
import kanBanIco from '../assets/KanBanIco.png';
import moment from 'moment';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import SearchIcon from '@mui/icons-material/Search';
import { motion, AnimatePresence } from 'framer-motion';
import Board from 'react-trello';
import Offcanvas from 'react-bootstrap/Offcanvas';
import {
  Box,
  Tooltip,
  Autocomplete,
  TextField,
  Alert,
  Button,
} from '@mui/material';
import { DashedField } from '../../../Componenets/Misc';
import { useSelector } from 'react-redux';
import { ENDPOINT } from '../../Opportunity/Redux/oppor';
import { CreateProDil } from '../../CreateProTask';
import dayjs from 'dayjs';
import { DateCalendar, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';

const FindIcon = ({
  searchQuery,
  setSearchQuery,
  myArr,
  setProjects,
  projects,
}) => {
  const [searchField, setSearchField] = useState('button');

  return searchField === 'button' ? (
    <Button
      className="search-btn"
      onClick={() => {
        setSearchField('input');
      }}
    >
      <ManageSearchIcon className="search-icon" />
    </Button>
  ) : (
    <AnimatePresence>
      <motion.div
        key="field"
        style={{
          background: '#FFFFFF',
          display: 'flex',
          alignItems: 'center',
          padding: '6px 8px',
          borderRadius: '6px',
          color: '#676B6B',
          cursor: 'pointer',
        }}
        initial={{ x: 10, opacity: 0, transition: { duration: 0.5 } }}
        animate={{ x: 0, opacity: 1, transition: { duration: 0.5 } }}
        exit={{ x: -10, opacity: 0, transition: { duration: 0.5 } }}
      >
        <SearchIcon
          style={{ curser: 'pointer' }}
          onClick={() => {
            setSearchField('button');
          }}
        />
        <DashedField
          id="standard-basic"
          variant="standard"
          sx={{ padding: '0px', width: '100%' }}
          value={searchQuery}
          onChange={(e) => {
            setSearchQuery(e.target.value);
            setProjects((prev) => {
              if (e.target.value) {
                const filteredSearch =
                  Array.isArray(prev) && prev.length > 0
                    ? prev.filter((item) =>
                      item.deliverable_name
                        .toLowerCase()
                        .startsWith(searchQuery.toLowerCase())
                    )
                    : [];
                return Array.isArray(filteredSearch) && filteredSearch.length
                  ? filteredSearch.map((ma) => Object.assign({}, ma))
                  : [];
              }
              return myArr;
            });
          }}
        />
      </motion.div>
    </AnimatePresence>
  );
};

const CustomCard = ({
  title,
  description,
  label,
  otherText,
  timeLeft,
  assignee,
}) => {
  const Team = useSelector((state) => state.userTeam.team);
  const realAssingee =
    assignee && Array.isArray(Team.member) && Team.member.length
      ? Team.member.find((ele) => Number(ele.id) === Number(assignee))
      : false;
  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const handleResize = () => {
    setScreenSize(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <div
      style={{
        backgroundColor: '#f2f2f2',
        borderRadius: '8px',
        padding: '10px 10px',
        margin: screenSize < 1300 ? '0 auto' : '5px',
        width:
          screenSize >= 1750
            ? '360px'
            : screenSize >= 1650 && screenSize <= 1749
              ? '320px'
              : screenSize >= 1300
                ? '240px'
                : screenSize < 1300
                  ? '190px'
                  : 'auto',
      }}
    >
      {title && (
        <div
          style={{
            backgroundColor: '#e0e0e0',
            borderStartStartRadius: '8px',
            borderStartEndRadius: '8px',
            padding: '5px 5px',
            fontWeight: 'bold',
            fontSize: '15px',
          }}
        >
          <p>{title}</p>
        </div>
      )}
      {description && (
        <div style={{ padding: '3px 3px', backgroundColor: '#f0f0f0' }}>
          <p dangerouslySetInnerHTML={{ __html: description }}></p>
        </div>
      )}

      {otherText && (
        <div
          style={{
            backgroundColor: '#e7deff',
            color: '#711fff',
            padding: '10px 10px',
            borderRadius: '8px',
            marginTop: '10px',
          }}
        >
          <p>{otherText}</p>
        </div>
      )}
      {label && (
        <div
          style={{
            padding: '8px 8px',
            backgroundColor: '#e0dede',
            width: '80%',
            borderRadius: '8px',
            marginTop: '10px',
            color: '#bb5805',
          }}
        >
          <span style={{ fontSize: '0.95rem', fontWeight: '600' }}>
            {' '}
            {label}
          </span>
        </div>
      )}
      {timeLeft && (
        <div
          style={{
            backgroundColor: '#e0dede',
            fontWeight: 'bold',
            width: '60%',
            borderRadius: '8px',
            marginTop: '10px',
            padding: '5px 5px',
          }}
        >
          {timeLeft}
        </div>
      )}
      {realAssingee ? (
        <Box
          sx={{
            cursor: 'pointer',
            position: 'absolute',
            bottom: '10px',
            right: '10px',
          }}
        >
          <Tooltip title={'Assinged to' + ' ' + realAssingee.first_name}>
            <Avatar src={realAssingee.avatar} alt={realAssingee.first_name}>
            </Avatar>
          </Tooltip>
        </Box>
      ) : null}
    </div>
  );
};

const CustomLaneHeader = ({ title }) => (
  <div
    style={{
      backgroundColor:
        title === 'Backlog'
          ? '#e0b4fa'
          : title === 'Need More Work'
            ? '#f0e4b9'
            : title === 'Inprogress'
              ? '#79d0ed'
              : title === 'Stuck'
                ? '#ffbd85'
                : title === 'Completed'
                  ? '#56d6a0'
                  : title === 'Submitted for Review'
                    ? '#e7deff'
                    : title === 'Archive'
                      ? '#51b7c9'
                      : null,
      padding: '10px 10px',
      borderStartStartRadius: '8px',
      borderStartEndRadius: '8px',
      borderBottom:
        title === 'Backlog'
          ? '4px solid #65099c'
          : title === 'Need More Work'
            ? '4px solid #c49a02'
            : title === 'Inprogress'
              ? '4px solid #0000FF'
              : title === 'Stuck'
                ? '4px solid #bb5805'
                : title === 'Completed'
                  ? '4px solid #075433'
                  : title === 'Submitted for Review'
                    ? '4px solid #711fff'
                    : title === 'Archive'
                      ? '2px solid #51b7c9'
                      : null,
    }}
  >
    <h5>{title}</h5>
  </div>
);



const StatusOptions = [
  {
    lebal: "Backlog",
    value: "backlog"
  },
  {
    lebal: "In Progress",
    value: "inprogress"
  },
  {
    lebal: "Submitted For Review",
    value: "review"
  },
  {
    lebal: "Need More Work",
    value: "needs_more_work"
  },
  {
    lebal: "Stuck",
    value: "stuck"
  },
  {
    lebal: "Completed",
    value: "completed"
  },
  {
    lebal: "Archived",
    value: "archive"
  },
]




const PrevBox = ({
  args,
  setArgs,
  timeParams,
  setTimeParams,
  dileverable,
  setStatus,
  status,
  setPeople,
  people,
  setSearchQuery,
  searchQuery,
  setKanBanShow,
  kanBanShow,
  setIsTyping,
  setDue,
  due,
  data,
  setProjects,
  projects,
  myArr,
}) => {
  const teamOptions = useSelector((state) => state.userTeam.team.member);

  const teamOpts =
    Array.isArray(teamOptions) && teamOptions.length
      ? teamOptions.map((item) => ({
        name: item.first_name,
        avatar: item.avatar,
        id: item.id,
      }))
      : [];

  return (
    <Box
      sx={{
        backgroundColor: '#f2f2f2',
        borderRadius: '8px 0px 0 0',
        borderTopRightRadius: '8px',
        width: '100%',
        border: '1px solid rgba(224, 224, 224, 1)',
        borderBottom: '1.5px solid  #d9d9d9',
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: "nowrap",
        alignItems: 'center',
        padding: '0.5rem 1rem',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'baseline',
          width: { xxl: '20%', xl: '40%', lg: '55%', md: '70%' },
          gap: '1rem',
        }}
      >

        <Autocomplete
          id="company"
          options={
            Array.isArray(dileverable) && dileverable.length
              ? dileverable.map((item) => ({
                name: item.deliverable_name,
                id: item.id,
              }))
              : []
          }
          includeInputInList
          multiple
          getOptionLabel={(option) => {
            return option.name;
          }}
          onChange={(_, value) => {
            setArgs(value);
          }}
          value={args}
          style={{
            width: 300,
            marginTop: '0.7rem',
            '& .MuiAutocomplete-tags': {
              maxHeight: '40px',
              margin: "10px",
              overflowY: 'auto',
              position: 'sticky',
              top: 0,  // Adjust the top value if needed
            },
            '& .MuiAutocomplete-inputRoot': {
              flexWrap: 'nowrap',
            },
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={'Filter By Deliverable'}
              color="secondary"
              variant="standard"
              sx={{
                '& .MuiInputBase-input': {
                  fontSize: '1rem',
                  fontFamily: 'Open Sans',
                  fontWeight: '500',
                  color: '#000000',
                  textAlign: 'left',
                },
                '& .MuiInput-underline:before': {
                  borderBottom: '2px solid #711fff',
                },
                '& .MuiInput-underline:after': {
                  borderBottom: '2px solid #711fff',
                },
                '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                  borderBottom: '2px solid #711fff',
                },
              }}
              InputProps={{
                ...params.InputProps,
                style: { width: 240, overflow: 'hidden' },
              }}
            />
          )}
    
        />

        <Autocomplete
          id="company"
          options={[
            {
              value: 'due_soon',
              name: 'Due Soon',
            },
            {
              value: 'month',
              name: 'Due This Month',
            },
            {
              value: 'due_month',
              name: 'Due In Next 30 Days',
            },
            {
              value: 'semester',
              name: 'Due In Next 6 Months',
            },
            { value: 'all', name: 'All' },
          ]}
          includeInputInList
          getOptionLabel={(option) => {
            return option.name;
          }}
          value={timeParams}
          onFocus={() => {
            setIsTyping(true)
          }}
          onChange={(e, value) => {
            setTimeParams(value);

            setProjects((_) => {
              const shallow_copy = myArr.map((item) => Object.assign({}, item));

              if (value?.value) {
                if (value.value === 'due_soon') {
                  const dueSoon = shallow_copy
                    .filter((tsk) => tsk.deliverable_enddate !== null)
                    .filter((obj) => {
                      const finishData = obj.deliverable_enddate;
                      return moment(finishData).isBefore(
                        moment().add(7, 'days')
                      );
                    });
                  return dueSoon;
                }

                if (value.value === 'month') {
                  const dueMonth = shallow_copy
                    .filter((et) => et.deliverable_enddate !== null)
                    .filter((ent) => {
                      const finishDate = ent.deliverable_enddate;
                      return moment(finishDate).isSame(moment(), 'month');
                    });
                  return dueMonth;
                }
                if (value.value === 'due_month') {
                  const semester = shallow_copy
                    .filter((iin) => iin.deliverable_enddate !== null)
                    .filter((nnn) => {
                      const culmination = nnn.deliverable_enddate;
                      return moment(culmination).isAfter(
                        moment().add(30, 'days'),
                        'month'
                      );
                    });
                  return semester;
                }
                if (value.value === 'semester') {
                  const semester = shallow_copy
                    .filter((iin) => iin.deliverable_enddate !== null)
                    .filter((nnn) => {
                      const culmination = nnn.deliverable_enddate;
                      return moment(culmination).isAfter(
                        moment().add(6, 'months'),
                        'month'
                      );
                    });
                  return semester;
                }
              }
              return myArr.map((pro) => Object.assign({}, pro));
            });
            if (value) {

            }
            else {
              setIsTyping(false)
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={'Filter By Time'}
              color="secondary"
              variant="standard"
              sx={{
                '& .MuiInputBase-input': {
                  fontSize: '1rem',
                  fontFamily: 'Open Sans',
                  fontWeight: '500',
                  color: '#000000',
                  textAlign: 'left',
                },
                '& .MuiInput-underline:before': {
                  borderBottom: '2px solid #711fff',
                },
                '& .MuiInput-underline:after': {
                  borderBottom: '2px solid #711fff',
                },
                '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                  borderBottom: '2px solid #711fff',
                },
              }}
              InputProps={{
                ...params.InputProps,
                style: { width: 240, overflow: 'hidden' },
              }}
              onChange={(_, value) => {
                setArgs(value);
              }}
              value={args}
            />
          )}
        />

        <Autocomplete
          id="tags-standard"
          options={teamOpts.filter((item) => item.name !== "First Name")}
          includeInputInList
          multiple
          getOptionLabel={(option) => {
            return option.name
          }} 
          onChange={(_, value) => {
            setPeople(value); // Ensure this updates the state correctly
          }}
          value={people} // Make sure this references the correct state
          style={{
            width: 300,
            marginTop: '0.7rem',
            '& .MuiAutocomplete-tags': {
              maxHeight: '40px',
              overflowY: 'auto',
              position: 'sticky',
              top: 0,
            },
            '& .MuiAutocomplete-inputRoot': {
              flexWrap: 'nowrap',
            },
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={'Filter By People'}
              color="secondary"
              variant="standard"
              sx={{
                '& .MuiInputBase-input': {
                  fontSize: '1rem',
                  fontFamily: 'Open Sans',
                  fontWeight: '500',
                  color: '#000000',
                  textAlign: 'left',
                },
                '& .MuiInput-underline:before': {
                  borderBottom: '2px solid #711fff',
                },
                '& .MuiInput-underline:after': {
                  borderBottom: '2px solid #711fff',
                },
                '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                  borderBottom: '2px solid #711fff',
                },
              }}
              InputProps={{
                ...params.InputProps,
                style: { width: 240, overflow: 'hidden' },
              }}
            />
          )}
        />

        <Autocomplete
          id="company"
          options={StatusOptions}
          includeInputInList
          multiple
          getOptionLabel={(option) => {
            return option.lebal;
          }}
          onChange={(_, value) => {
            setStatus(value);
          }}
          value={status?.value}
          style={{
            width: 500,
            marginTop: '0.7rem',
            '& .MuiAutocomplete-tags': {
              maxHeight: '40px',
              width: "40px",
              overflowY: 'auto',
              position: 'sticky',
              top: 0,  // Adjust the top value if needed
            },
            '& .MuiAutocomplete-inputRoot': {
              flexWrap: 'nowrap',
            },
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={'Filter By Status'}
              color="secondary"
              variant="standard"
              sx={{
                '& .MuiInputBase-input': {
                  fontSize: '1rem',
                  fontFamily: 'Open Sans',
                  fontWeight: '500',
                  color: '#000000',
                  textAlign: 'left',
                },
                '& .MuiInput-underline:before': {
                  borderBottom: '2px solid #711fff',
                },
                '& .MuiInput-underline:after': {
                  borderBottom: '2px solid #711fff',
                },
                '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                  borderBottom: '2px solid #711fff',
                },
              }}
              InputProps={{
                ...params.InputProps,
                style: { width: 280, overflow: 'hidden' },
              }}
            />
          )}
        />
      </Box>


      <Box
        sx={{

          width: { xxl: '20%', xl: '20%', lg: '35%', md: '40%' },
          display: 'flex',
          alignItems: 'center',
          gap: '1rem',
          justifyContent: 'flex-end',
        }}
      >
        <FindIcon
          setProjects={setProjects}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          projects={projects}
          myArr={myArr}
        />
        <img
          src={ListIco}
          onClick={() => {
            setIsTyping(false)
            setKanBanShow(false)
          }}
          style={{ cursor: 'pointer' }}
        />
        <img
          src={kanBanIco}
          onClick={() => {
            setIsTyping(true)
            setKanBanShow(true)
          }}
          style={{
            cursor: 'pointer',
          }}
        />
      </Box>
    </Box>
  );
};


const Deliverable = ({
  dileverable,
  setDeliverable,
  unique_id,
  formData,
  setDetOpen,
  setIsDetOpen,
  setFormData,
  setIsTyping,
  isTyping,
  SetDltingTask,
  dltingTask,
  getData,
  show,
  setShow,
  screenHeight,
  currentMonthIndex,
  setDelivChatShow,
  delivChatShow,
  deliverableChat,
  setDeliverableChat,
  ChannelList,
  setShowOfTask,
  showofTask,
  comments,
  setComments,
  setCurrentTargetTask,
  currentTargetTask,
}) => {

  const [addDil, setAddDil] = useState(false);
  const data = dileverable.map((item) => {
    return {
      id: item.id,
      title: item.deliverable_name,
      startdate: item.deliverable_startdate,
      due_date: item.deliverable_enddate

        ? moment(item.deliverable_enddate).format('MMMM DD, YYYY')
        : 'No Due Date',
      total_hour: item.total_hours
        ? item.total_hours
        : item.total_loe
          ? item.total_loe
          : 0,
      worked_hour: 0,
      number_of_task: item.deliverable_task ? item.deliverable_task.length : 0,
      data: item.task_deliverable,
      design: (
        <DesignOne
          dil={{
            ...item,
            worktype: item.deliverable_worktype,
            task_deliverable: item.task_deliverable,
          }}
        />
      ),
      worktype: item.deliverable_worktype,
      task_deliverable: item.task_deliverable,
      pro_id: unique_id,
    };
  });


  const maal = dileverable.flatMap((mal) =>
    Array.isArray(mal.task_deliverable) && mal.task_deliverable.length
      ? mal.task_deliverable
      : []
  );

  const [myDat, setMyDat] = useState([]);

  useEffect(() => {
    setMyDat(maal);
  }, [dileverable]);

  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const handleResize = () => {
    setScreenSize(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const [due, setDue] = useState([]);
  const charShayo = [
    { dent: 'Backlog', identifier: 'backlog' },
    { dent: 'Inprogress', identifier: 'inprogress' },
    { dent: 'Stuck', identifier: 'stuck' },
    { dent: 'Completed', identifier: 'completed' },
    ,
  ];

  const [kanbanShow, setKanbanShow] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const DataCopy = searchQuery
    ? data.filter((item) =>
      item.title.toLowerCase().includes(searchQuery.toLowerCase())
    )
    : data;

  const [people, setPeople] = useState();
  const [status, setStatus] = useState([]);

  const [args, setArgs] = useState();
  const [timeParams, setTimeParams] = useState({ value: 'all', name: 'All' });


  const newData =
    Array.isArray(args) && args.length > 0
      ? DataCopy.filter(
        (item) => args && args.some((m) => m.name === item.title)
      )
      : DataCopy;


  const filteredData =
    Array.isArray(people) && people.length > 0
      ? newData.filter((parent) =>
        parent.task_deliverable?.some((child) =>
          people.some((person) => person.id === child.assignee_task[0]?.assignee_user)
        )
      ).map((parent) => ({
        ...parent,
        data: parent.task_deliverable?.filter((child) =>
          people.some((person) => person.id === child.assignee_task[0]?.assignee_user)
        )
      }))
      : newData;


  const finalFilteredData =
    Array.isArray(status) && status.length > 0
      ? filteredData.filter((parent) =>
        parent.task_deliverable?.some((child) =>
          status.some((stat) => child.status === stat.value)
        )
      ).map((parent) => ({
        ...parent,
        data: parent.data?.filter((child) =>
          status.some((stat) => child.status === stat.value)
        )
      }))
      : filteredData;



  const handleCardMove = async (
    cardId,
    sourceLaneId,
    targetLaneId,
    position
  ) => {

    setIsTyping(true)
    const find = myDat.find((item) => Number(item.id) === Number(cardId));
    const newStatus = charShayo.find((ele) => ele.dent === targetLaneId);
    const resP = await fetch(
      `${ENDPOINT}/api/project/task/update/${find.id}/`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `JWT ${token}`,
        },
        body: JSON.stringify({
          task_name: find.task_name,
          status: newStatus.identifier,
        }),
      }
    );
    const para = await resP.json();

    if (!resP.ok) {
      return;
    }
  };
  const currentCompanies = useSelector((state) => state.Contacts.companies);

  const tasks = finalFilteredData?.map((task) => ({
    id: task.id,
    title: task.title,
    startdate: task.startdate,
    due_date: task.due_date,
    total_hour: task.total_hour,
    worked_hour: task.worked_hour,
    number_of_task: task.number_of_task,
    row: task.data,
    design: task.design,
    worktype: task.worktype,
    task_deliverable: task.data,
    pro_id: task.pro_id,
  }));

  const realData =
    Array.isArray(myDat) && myDat.length
      ? charShayo.map((status, index) => ({
        id: status.dent,
        title: status.dent,
        label: index,
        cards: myDat
          .filter((ele) => ele.status === status.identifier)
          .map((ca, nt) => ({
            id: ca?.id,
            title: ca?.task_name ?? '',
            description: ca.description ? ca.description : '',
            label: ca.due_date
              ? 'Due On' +
              ' ' +
              ca.due_date.split('T')[0] +
              ' ' +
              ca.due_date.split(',')[1]
              : 'No due date available',
            otherText: ca.company
              ? currentCompanies?.find(
                (sa) => Number(sa.id) === Number(ca.company)
              )?.name
              : '',
            assignee: ca.assignee ? ca.assignee : false,
          })),
      }))
      : [];
  const [id, setId] = useState()

  const [dateRange, setDateRange] = useState();

  const handleDateRange = (v) => {
    setDateRange(v)

  }


  const handleClose = () => {
    setId(null)
    setDateRange(false)
  };



  const token = useSelector((state) => state.auth.user.access)

  async function handleRangeChange(event) {
    const shallow_copy = {
      ...formData,
    };

    delete shallow_copy.work_type;
    delete shallow_copy.estimate_hardcost;
    delete shallow_copy.project_change

    const shallowCopy = dileverable.map((item) => ({ ...item }));
    const target = shallowCopy.findIndex(
      (ele) => Number(ele.id) === Number(id.id)
    );

    shallowCopy[target].deliverable_enddate = new Date(dateRange).toISOString()

    const durationEst = new Date(formData.finsish_date) - new Date(formData.target_start)

    const totalDays = durationEst / (24 * 60 * 60 * 1000)
    const startDateOfDil = new Date('11/11/2080').toISOString();

    const leastDate = shallowCopy.reduce((minDate, dil) => {
      if (dil.deliverable_startdate < minDate) {
        return dil.deliverable_startdate;
      } else {
        return minDate;
      }
    }, startDateOfDil);

    const res = await fetch(`${ENDPOINT}/api/project/update/${formData.id}/`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `JWT ${token}`,
      },
      body: JSON.stringify({
        ...shallow_copy,
        target_start: new Date(new Date(leastDate).setDate(new Date(leastDate).getDate() + 1)).toISOString(),
        finsish_date: formData.finsish_date ? new Date(new Date(leastDate).setDate(new Date(leastDate).getDate() + totalDays + 1)) : null,
        project_deliverable: shallowCopy.map((dil) => {
          delete dil?.internel_note
          return {
            deliver_id: dil.id,
            deliverable_name: dil.deliverable_name,
            deliverable_startdate: dil.deliverable_startdate,
            deliverable_enddate: dil.deliverable_enddate,
            description: dil.description,
            loe_price: 0,
            user_price: 0,
            total_hours: dil?.total_hours ?? 0,
            change_reason: dil.change_reason ? dil.change_reason : '',
            changed: dil.changed ? true : false
          };
        }),
      }),
    });

    const data = await res.json();
    if (!res.ok) {
      return;
    }
    const resGET = await fetch(`${ENDPOINT}/api/project/detail/${unique_id}/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `JWT ${token}`,
      },
    });
    const dataGET = await resGET.json();
    if (!resGET.ok) {

    }
    setFormData(dataGET)

    if (
      dataGET &&
      Array.isArray(dataGET.deliverable_project) &&
      dataGET.deliverable_project.length
    ) {
      setDeliverable(dataGET.deliverable_project);
    }
    setId(null)
    setIsTyping(false)
  }
  const [addTask, setAddTask] = useState(false);

  return (
    <>
      <PrevBox
        due={due}
        setDue={setDue}
        setKanBanShow={setKanbanShow}
        data={dileverable}
        kanBanShow={kanbanShow}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        people={people}
        setPeople={setPeople}
        status={status}
        setStatus={setStatus}
        setArgs={setArgs}
        timeParams={timeParams}
        setTimeParams={setTimeParams}
        args={args}
        dileverable={dileverable}
        setProjects={setDeliverable}
        projects={dileverable}
        myArr={formData?.deliverable_project}
        setIsTyping={setIsTyping}


      />
      {kanbanShow ? (
        <>
          {Array.isArray(realData) && realData.length ? (
            <Box sx={{ width: '100%' }}>
              <Board
                data={{ lanes: realData }}
                draggable={true}
                handleDragEnd={handleCardMove}
                style={{
                  backgroundColor: '#F4F5F7',
                  width: '100%',
                  borderRadius: '4px',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
                laneStyle={{
                  background: '#e3e3e3',
                  margin: '10px',
                  borderRadius: '8px',
                  width:
                    screenSize >= 1650 && screenSize <= 1749
                      ? '340px'
                      : screenSize >= 1750
                        ? '380px'
                        : screenSize < 1300
                          ? '220px'
                          : '260px',
                }}
                components={{
                  LaneHeader: CustomLaneHeader,
                  Card: CustomCard,
                }}
              />
            </Box>
          ) : (
            <Box
              sx={{
                height: '100%',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Alert sx={{ height: '100px', width: '80%' }} severity="info">
                You dont have any tasks yet.
              </Alert>
            </Box>
          )}
        </>
      ) : (
        <Box sx={{ width: '100%', minHeight: screenHeight - 0, maxHeight: screenHeight - 0 }}>
          {Array.isArray(tasks) && tasks.length ? (
            <CreateAccordion
              status={status}
              tasks={tasks}
              dileverable={dileverable}
              setDeliverable={setDeliverable}
              args={args}
              setDetOpen={setDetOpen}
              setIsDetOpen={setIsDetOpen}
              formData={formData}
              setFormData={setFormData}
              isTyping={isTyping}
              setIsTyping={setIsTyping}
              unique_id={unique_id}
              setId={setId}
              id={id}
              dltingTask={dltingTask}
              SetDltingTask={SetDltingTask}
              access_token={token}
              getData={getData}
              setAddTask={setAddTask}
              addTask={addTask}
              setShow={setShow}
              show={show}
              setDueDate={setDateRange}
              currentMonthIndex={currentMonthIndex}
              setDelivChatShow={setDelivChatShow}
              delivChatShow={delivChatShow}
              deliverableChat={deliverableChat}
              setDeliverableChat={setDeliverableChat}
              ChannelList={ChannelList}
              setShowOfTask={setShowOfTask}
              showofTask={showofTask}
              comments={comments}
              setComments={setComments}
              setCurrentTargetTask={setCurrentTargetTask}
              currentTargetTask={currentTargetTask}
            />
          ) : (
            <Alert sx={{ width: '100%' }} severity="info">
              No Delieverables
            </Alert>
          )}
        </Box>
      )}
      {id &&
        <Box sx={{ position: 'absolute', top: '32%', left: '20%', zIndex: '99999', background: '#fff' }}>
          <Box align='center' sx={{ width: 'auto', height: 'auto', px: '30px', display: { xs: 'block', md: 'flex' }, }}>
            <Box sx={{ width: '100%' }}>
              <LocalizationProvider dateAdapter={AdapterDayjs} >
                <DateCalendar
                  value={dayjs(dateRange)}
                  onChange={(newValue) => handleDateRange(newValue?.$d)}
                  sx={{
                    '& .css-10wpov9-MuiTypography-root ': {
                      fontWeight: '700 !important'
                    },
                    '& .css-cyzddl-MuiPickersSlideTransition-root-MuiDayCalendar-slideTransition:not(.MuiDateRangeCalendar-dayDragging) .MuiDateRangePickerDay-dayOutsideRangeInterval': {
                      color: '#03071E !important',
                      fontSize: '16px !important',
                      fontWeight: 500,
                    },
                    '& .css-1gbl7yn-MuiDateRangePickerDay-root': {
                      backgroundColor: '#310085 !important',
                      color: 'white !important',
                      borderRadius: '0px !important'
                    },
                    '& .css-1e841vg-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day.Mui-selected': {
                      backgroundColor: '#711FFF !important',
                      color: "white !important",
                      borderRadius: '0px !important',
                      fontSize: '16px !important',
                      fontWeight: 500
                    },
                    '& .css-1ckjanr-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day.Mui-selected': {
                      backgroundColor: '#711FFF !important',
                      color: "white !important",
                      borderRadius: '0px !important',
                      fontSize: '16px !important',
                      fontWeight: 500
                    },
                    '& .css-1u23akw-MuiButtonBase-root-MuiPickersDay-root.Mui-selected': {
                      backgroundColor: '#711FFF !important',
                      color: "white !important",
                      borderRadius: '0px !important',
                      fontSize: '16px !important',
                      fontWeight: 500
                    },
                    '& .css-2ko3hu-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day': {
                      backgroundColor: '#310085 !important',
                      color: "white !important",
                      opacity: 'inherit !important',
                      borderRadius: '0px',
                      border: 'none !important',
                      fontSize: '16px !important',
                      fontWeight: 500
                    },
                    '& .css-1ku4sqv-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day': {
                      backgroundColor: '#711FFF !important',
                      color: "white !important",
                      borderRadius: '0px !important',
                      fontSize: '16px !important',
                      fontWeight: 500
                    },
                    '& .css-ahwqre-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day:not(.Mui-selected)': {
                      backgroundColor: '#310085 !important',
                      color: "#e569db !important",
                      borderRadius: '0px !important',
                      fontSize: '16px !important',
                      fontWeight: 500
                    },
                    '& .css-jef1b6-MuiDateRangeCalendar-container:not(:last-of-type)': {
                      borderRight: 'none !important'
                    },
                    '& .css-3wduhr-Mu.iDateRangeCalendar-root': {
                      flexDirection: 'column !important',
                    },


                    '& .css-grqin-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day': {
                      color: 'inherit',
                      fontSize: '16px !important',
                      opacity: 'inherit',
                      fontWeight: 500
                    },
                    '& .css-1kex3oi-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day:not(.Mui-selected)': {
                      backgroundColor: 'inherit',
                      color: "black !important",
                      borderRadius: '50%',
                      border: "1px solid gray",
                      fontSize: '16px',
                      fontWeight: 500
                    },
                    '& .MuiTypography-subtitle1': {
                      color: " #03071E !important",
                      fontSize: '16px',
                      fontWeight: 700
                    },
                    '& .MuiDayCalendar-weekDayLabel': {
                      color: " #03071ECC !important",
                      fontSize: '16px',
                      fontWeight: 400
                    },
                    '& .MuiSvgIcon-fontSizeInherit': {
                      backgroundColor: 'black',
                      color: 'white',
                      borderRadius: '50%'
                    },
                    '& .MuiPickersDay-today': {
                      backgroundColor: !dateRange && '#711FFF',
                      color: !dateRange && 'white',
                      borderRadius: !dateRange && '0px',
                      fontSize: !dateRange && '16px',
                      fontWeight: !dateRange && 500

                    },
                    '& .css-grqin-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day.Mui-selected': {
                      backgroundColor: '#711FFF',
                      color: 'white',
                      borderRadius: '0px',
                      fontSize: '16px',
                      fontWeight: 500
                    },
                    '& .css-gtjfra-MuiDateRangePickerDay-root': {
                      backgroundColor: '#310085',
                      color: 'white !important',
                      borderRadius: '0px',
                      fontSize: '16px',
                      fontWeight: 500
                    },
                    '& .css-1i2r8k1-MuiDateRangePickerDay-root': {
                      backgroundColor: '#310085',
                      color: 'white !important',
                      borderRadius: '0px',
                      fontSize: '16px',
                      fontWeight: 500
                    },
                   
                  }}

                />
              </LocalizationProvider>

              <Box sx={{ display: 'flex', my: 2, alignItems: 'center', justifyContent: 'flex-end' }}>


                <Box align='right' sx={{ display: 'flex', gap: '10px' }}>
                  <Button variant='text' sx={{ textDecoration: 'inherit', color: 'black', width: { xs: 'auto', lg: '100px' }, mr: 1 }} onClick={handleClose}>Cancel</Button>
                  <Button variant='contained' sx={{
                    textDecoration: 'inherit', backgroundColor: "#711FFF", width: { xs: 'auto', lg: '100px' }, mr: 1,
                    '&:hover': {
                      backgroundColor: "#711FFF"
                    }
                  }}
                    onClick={() => {
                      handleRangeChange()
                    }}
                  >Update</Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      }
      <Offcanvas
        show={addDil}
        onHide={(_) => setAddDil(false)}
        backdrop
        placement="bottom"
        sx={{ zIndex: 99999 }}
      >
        <CreateProDil
          setAddDil={setAddDil}
          setIsDetOpen={setIsDetOpen}
          addDil={addDil}
          project={formData.id}
          setIsTyping={setIsTyping}
          unique_id={unique_id}
          setProjectFormData={setFormData}
          setDeliverables={setDeliverable}
        />
      </Offcanvas>
    </>
  );
};
export default Deliverable;
