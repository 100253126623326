import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Papa from 'papaparse';
import { Typography } from '@mui/material';
import {Box} from '@mui/material';
const SecondStep = ({
  step,
  setStep,
  setError,
  setImportData,
  setErrorMessage,
  error,
  errorMessage,
  setNewParser,
}) => {
  const types = ['text/csv'];
  const [file, setFile] = useState(null);

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
  };

  const handeDrop = (e) => {
    e.preventDefault();
    if (e.dataTransfer.files.length) {

      const selected = e.dataTransfer.files[0];
      if (selected && types.includes(selected.type)) {
        setFile(selected);
        setError('');
        Papa.parse(selected, {
          header: true,
          skipEmptyLines: true,
          complete: (result) => {
            setNewParser(result.data);
          },
        });
      } else {
        setFile(null);
        setError(true);
        setErrorMessage('Please select a csv file (text/csv)');
      }
    }
  };

  const changeHandler = (e) => {
    const selected = e.target.files[0];
    if (selected && types.includes(selected.type)) {
      setFile(selected);
      setError('');
      Papa.parse(selected, {
        header: true,
        skipEmptyLines: true,
        complete: (result) => {
          setNewParser(result.data);
        },
      });
    } else {
      setFile(null);
      setError(true);
      setErrorMessage('Please select a csv file (text/csv)');
    }
  };

  const handleCompanyCreation = () => {
    setStep(3);
  };
  const navigate = useNavigate()

  return (
    <>
      <section className="FileCheck">
        <section className="row" style={{width:'65%'}}>
        <Typography sx={{fontSize:'18px', fontWeight:'600', color:'#000', marginTop:'.7rem'}} className="txtCenter">Importing Contacts : Step 1</Typography>
          <p className="txtCenter initialParagraph" style={{fontSize:'15px', color:'#000', marginTop:'.7rem'}}>
            This first step is easy peasy. Simply click the select button and
            choose the .csv file of your peoples to import. People, Companies
            and any other information can be imported and mapped later.
          </p>
        </section>
        <section className="row" >
          <div
            className="file-upload"
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handeDrop}
          >
            <label className="file-input">
              <div className="grey-box" />
              <p className="txtCenter initialParagraph txtESM">
                {file
                  ? file.name
                  : 'Drag and Drop or choose a file to upload your peoples Supported Files include .csv, .xlsx and .xls files'}
              </p>
              <input
                type="file"
                id="file"
                onChange={changeHandler}
                title=""
                className="NoOpec"
              />
            </label>
            {error && <p className="error">{errorMessage}</p>}
          </div>
        </section>
      </section>
     
      <Box sx={{position:'fixed', bottom:'1px', width:{xl : '85%', lg:'80%', md:'98%'}}} className="AlBottom footer">
        <ul className="footer-list">
          <li className={step === 2 ? 'footer-item thisStep' : 'footer-item'}>
            Import Files
          </li>
          <li className={step === 3 ? 'footer-item thisStep' : 'footer-item'}>
            Map Fields
          </li>
          <li className={step === 4 ? 'footer-item thisStep' : 'footer-item'}>
            Type
          </li>
          <li className={step === 5 ? 'footer-item thisStep' : 'footer-item'}>
            Exceptions
          </li>
        </ul>

        <div className="AlRight dd-import-group">
          {' '}
          <button
            type="button"
            className="cancel-btn alRight fsESM border"
            onClick={() => navigate(-1)}
          >
            Cancel
          </button>
          <button
            type="button"
            className="smBtn alRight"
            onClick={() => {
              const reader = new FileReader();
              reader.readAsText(file);
              reader.onload = () => {
                if (reader.readyState === 2) {
                  const first_names = [];
                  const last_names = [];
                  const emails = [];
                  const companies = [];
                  const res = reader.result.split(',');
                  for (let i = 0; i < res.length; i++) {
                    if (i % 4 === 0) {
                      first_names.push(res[i]);
                    } else if (i % 4 === 1) {
                      last_names.push(res[i]);
                    } else if (i % 4 === 2) {
                      emails.push(res[i]);
                    } else if (i % 4 === 3) {
                      companies.push(res[i]);
                    }
                  }
                  const finalResult = {
                    first_name: first_names,
                    last_name: last_names,
                    emails,
                    preCompanies: companies,
                    company: [...new Set([...companies])],
                  };
                  setImportData(finalResult);
                  handleCompanyCreation();
                }
              };
            }}
            disabled={!file}
          >
            Next
          </button>
        </div>
      </Box>
    </>
  );
};

export default SecondStep;
