import { createAsyncThunk } from '@reduxjs/toolkit';
import { ENDPOINT } from '../Opportunity/Redux/oppor';

const GET_USER_TEAM = 'netnet/Redux/Team';

const getUserTeam = createAsyncThunk(GET_USER_TEAM, async (meta, thunkAPI) => {
  try {
    const response = await fetch(`${ENDPOINT}/api/user/team/`, {
      method: 'GET',
      headers: {
        Authorization: `JWT ${meta.access_token}`,
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      const data = await response.json();
      return thunkAPI.rejectWithValue(data); // Properly return the rejected value
    }

    const data = await response.json();
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message); // Handle other errors
  }
});

export { getUserTeam };

const initialState = {
  team: {},
  loading: false,
  error: null,
};

const teamReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${GET_USER_TEAM}/pending`:
      return {
        ...state,
        loading: true,
      };
    case `${GET_USER_TEAM}/fulfilled`:
      return {
        ...state,
        loading: false,
        // team: action.payload.member.filter(
        //   (mm) => mm.first_name !== 'First Name'
        // ),
        team: {
          ...action.payload,

          member: action.payload.member,
        },
      };
    case `${GET_USER_TEAM}/rejected`:
      return {
        ...state,
        loading: false,
        error: action.payload,
        team: {},
      };
    default:
      return state;
  }
};
export default teamReducer;
