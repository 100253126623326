/* configure the store */
import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import authenticateReducer from './auth';
import ResetPwdReducer from './Reset';
import opporReducer from '../../../pages/Opportunity/Redux/oppor';
import userDataReducer from '../../../Screens/InnerApp/userData';
import proReducer from '../../../pages/Projects/proRedux';
import contactReducer from '../../../pages/Contacts/ContactRedux';
import messagesReducer from '../../../pages/Stream/streamRedux';
import netReducer from '../../../pages/NetNetU/redux';
import teamReducer from '../../../pages/Team/team';
import WorkReducer from '../../../Screens/InnerApp/workTypes';
import MeReducer from '../../../pages/Me/redux/totalTime';
import MiscReducer from '../../../pages/User/redux';
import customerReducer from '../../../pages/SuperAdmin/Tools/Customers/CustomersRedux';
import discountReducer from '../../../pages/SuperAdmin/Tools/DiscountEngine/DiscountRedux';
import coursesReducer from '../../../pages/SuperAdmin/NetNetU/Courses/CoursesRedux';

const persistConfig = {
  key: 'root',
  storage,
};

const rootReducer = combineReducers({
  auth: authenticateReducer,
  reset: ResetPwdReducer,
  oppertunity: opporReducer,
  projects: proReducer,
  userData: userDataReducer,
  Contacts: contactReducer,
  Stream: messagesReducer,
  NetNetU: netReducer,
  userTeam: teamReducer,
  work_type: WorkReducer,
  My_Time: MeReducer,
  Misc: MiscReducer,
  superAdmin: customerReducer,
  superAdmin_disc: discountReducer,
  courses: coursesReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
});

const persistor = persistStore(store);
export { persistor };
export default store;
