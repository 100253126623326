import { ResponsiveContainer, BarChart, CartesianGrid, XAxis, YAxis, Legend, Bar, LabelList, Cell } from "recharts";
import { getBarColor } from "./helper";

const ReportBar = ({ data }) => {

    return <ResponsiveContainer width="100%" height={450} className="bar-chart" >
        <BarChart width={500} height={400} data={data} margin={{ top: 25 }}  >
            <CartesianGrid vertical={false} strokeDasharray="4" />
            <XAxis dataKey="date" allowDataOverflow={true} />
            <YAxis unit="Hours" axisLine={false} dataKey="elapsedTime" padding={{ top: 10, left: 10, right: 10 }} />
            <Bar dataKey="overTime" barSize={35} fill="#0b88f9" >
            </Bar>
            <Bar dataKey="elapsedTime" barSize={35} fill="#5ee690" maxBarSize={60}>
                {data.map((each, index) => {
                    return <Cell key={`cell-${index}`} fill={getBarColor(each.overTime, each.isTarget)} />
                })}
            </Bar>

        </BarChart>
    </ResponsiveContainer>
}
export default ReportBar;

