

const NotAvailable = () => {
return (
    <>
    <div className="" 
    style={{
        height: "110vh",
        width: "100vw",
        zIndex: "999"
    }}>

     <section className="oppor-section">
        <sections className="myCenter">
            <article className="box-txt">
            <p className="txtCenter h3 fw-bold mb-4 ">Super Admin is Not Available In Mobile Screen.</p>
            <p className="txtCenter h5 fw-bold ">You Should Use Desktop Screens!</p>
            </article>
        </sections>
        </section>
    </div>
     </>
    
    
    )
}

export default NotAvailable;