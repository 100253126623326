import { createAsyncThunk } from '@reduxjs/toolkit';
import { ENDPOINT } from '../Opportunity/Redux/oppor';

const GET_PROJECTS = 'netnet/Redux/GET_PROJECTS';
const GET_QUICK_TASK = 'netnet/Redux/GET_QUICK_TASK';
const START_TIMER = 'netnet/Redux/START_TIMER';
const CURRENT_TARGET = 'netnet/Redux/CURRENT_TARGET';
const STOP_TIMER = 'netnet/Redux/STOP_TIMER';

const getProjects = createAsyncThunk(GET_PROJECTS, async (meta) => {
  const response = await fetch(` ${ENDPOINT}/api/project/list/`, {
    method: 'GET',
    headers: {
      Authorization: `JWT ${meta.access_token}`,
    },
  });
  const data = await response.json();
  if (!response.ok) {
    console.log('rejected@getProjects', data);
  } else {
    const pro = data.sort((a, b) => {
      // Assuming 'created_at' is a property in your project objects
      const dateA = new Date(a.created_at);
      const dateB = new Date(b.created_at);
  
      // Sort in descending order (newest created first)
      return dateB - dateA;
    });

    return pro
  }
});

const getQuickTask = createAsyncThunk(
  GET_QUICK_TASK,
  async (meta, thunkAPI) => {
    const response = await fetch(` ${ENDPOINT}/api/project/task/list/`, {
      method: 'GET',
      headers: {
        Authorization: `JWT ${meta.access_token}`,
      },
    });
    const data = await response.json();
    if (!response.ok) {
      thunkAPI.rejectWithValue(data);
    }
    return data.filter(item => item.task_type !== 'estimate_task' ).sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
  }
);

export { getProjects, getQuickTask };

export const startTimer = createAsyncThunk(START_TIMER, async (meta) => {
  const res = await fetch(
    `${ENDPOINT}/api/project/task/update/${meta.params.id}/`,
    {
      method: 'PUT',
      headers: {
        'Content-type': 'application/json',
        Authorization: `JWT ${meta.access_token}`,
      },
      body: JSON.stringify({
        ...meta.params,
        time_tracking_start: new Date().toISOString(),
        status: 'inprogress',
      }),
    }
  );
  const data = await res.json();
  if (!res.ok) {
    console.log(data, 'Something went wrong');
  } else {
    console.log('Updated', data);
    return new Date().toISOString();
  }
});

export const addCurrentTarget = (payload) => ({
  type: CURRENT_TARGET,
  payload,
});

export const stopTimer = (payload) => ({
  type: STOP_TIMER,
  payload,
});

const initialState = {
  projects: [],
  tasks: [],
  timer: '',
  currentTarget: null,
  loading: false,
  error: null,
};

const proReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${GET_PROJECTS}/pending`:
      return {
        ...state,
        loading: true,
      };
    case `${GET_PROJECTS}/fulfilled`:
      return {
        ...state,
        projects: action.payload,
        loading: false,
      };
    case `${GET_PROJECTS}/rejected`:
      return {
        ...state,
        loading: false,
        error: action.payload,
        projects: [],
      };
    case `${GET_QUICK_TASK}/pending`:
      return {
        ...state,
        loading: true,
      };
    case `${GET_QUICK_TASK}/fulfilled`:
      return {
        ...state,
        tasks: action.payload,
        loading: false,
      };
    case `${GET_QUICK_TASK}/rejected`:
      return {
        ...state,
        loading: false,
        error: action.payload,
        tasks: [],
      };
    case `${START_TIMER}/pending`:
      return {
        ...state,
        loading: true,
      };
    case `${START_TIMER}/fulfilled`:
      return {
        ...state,
        timer: action.payload,
        loading: false,
      };
    case `${START_TIMER}/rejected`:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CURRENT_TARGET:
      return {
        ...state,
        currentTarget: action.payload,
      };
    case STOP_TIMER:
      return {
        ...state,
        currentTarget: null,
      };
    default:
      return state;
  }
};

export default proReducer;
