import React from 'react';
import {
  Grid,
  Box,
} from '@mui/material';
import { useEffect, useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import { Typography } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { estimate_channels } from '../Stream/streamRedux';
import { ENDPOINT } from './Redux/oppor';
import Test from '../Test';
import SimpleDialog from '../SimpleDialog';

function ChatEst({ formData, setShowChatEst, setIsDetOpen }) {
  const [show, setShow] = useState(true);
  const [estimate_channel, setEstimate_channel] = useState()
  const channels = useSelector((state) => state.Stream.estimate_channels)
  const new_channels = useSelector((state) => state.Stream.new_channels)

  const [html, setHtml] = useState('');
  const handleClose = () => {
    setShowChatEst(false)
    setShow(false)
    setIsDetOpen(true)
  };

  const [chats, setChats] = useState([])
  useEffect(() => {
    if (new_channels && formData) {
      const crtMessages = new_channels?.find((item) => item?.estimate?.unique_id === formData?.unique_id)
      const response = fetch(`${ENDPOINT}/api/chat/channel/detail/${crtMessages?.id}/`, {
        method: "GET",
        headers: {
          Authorization: `JWT ${access_token}`,
          'Content-Type': 'application/json'
        },
      }).then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
        .then(data => {
          setEstimate_channel(data.id)
          setChats(data.message_in_channel ?? [])
          // Process the response data here
        })
        .catch(error => {
        });

      setChats(crtMessages)
    }
  }, [channels])

  function formatDate(inputDate) {
    const date = new Date(inputDate);

    // Format time
    const timeString = date.toLocaleString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });

    // Format day of the week, day of the month, and month
    const dateString = date.toLocaleString('en-US', {
      weekday: 'short',
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    });

    return `${timeString}, ${dateString}`;
  }
  const access_token = useSelector((state) => state.auth.user.access)
  const userData = useSelector((item) => item.userData.userData)
  const dispatch = useDispatch()
  const [btn, setBtn] = useState()
  const check = (e) => {
    setBtn(e)
  }
  const [notRun, setNotRun] = useState(false)

  async function send_Message(e) {

    if (!notRun) {
      if (e.key === 'Enter') {
        e.preventDefault()
        if (e.key !== 'Enter' || e.shiftKey) {
          return;
        }
        // Handle the text here, you can log it to the console
        const replaceWordsWithSpan = (inputText) => {
          // Define a regular expression pattern to find words enclosed by ~
          const pattern = /~([^~!]+)!/g; // Matches ~ followed by one or more non-~ characters, ending with ~

          // Define the replacement function
          const replacement = (match, word) => {
            // Split the word using the delimiter '/'
            const parts = word.split('/');

            // Check if there are two parts
            if (parts.length === 2) {
              // Wrap the first part in a <span> tag with the specified class name
              // and include the second part inside with a new class name
              return `<span class="deliverable-mention">~${parts[0]}/<span class="fukrah">${parts[1]}</span></span>`;
            } else {
              // If there's only one part, wrap it in the main <span> tag
              return `<span class="deliverable-mention">${word}</span>`;
            }
          };

          // Replace the text using the regular expression and the replacement function
          const replacedText = inputText.replace(pattern, replacement);

          return replacedText;
        };
        btn?.editor?.commands.clearContent()
        setHtml('');
        const tempMessage = {
          created: new Date().toISOString(),
          channel: estimate_channel && estimate_channel,
          is_response: false,
          message: replaceWordsWithSpan(html),
          received: null,
          receiver_read: false,
          seen: null,
          sender_read: true, // Assuming the sender has already read their own message
          sender: userData,
          tempId: Math.random().toString(36).substring(7), // Unique temporary ID
        };
        setChats([...chats, tempMessage])
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, 'text/html');



        // Extract mentioned persons
        const mentions = doc.querySelectorAll('span[data-type="mention"]');


        const mentionedPersons = Array.from(mentions).map(mention => mention.textContent.replace('@', ''));
        if (Array.isArray(mentionedPersons) && mentionedPersons.length) {

          for (var i = 0; i < mentionedPersons.length; i++) {

            const res = await fetch(`${ENDPOINT}/api/superadmin/notification/create/`,
              {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `JWT ${access_token}`,
                },
                body: JSON.stringify({
                  notification_channel:estimate_channel,

                  notification_sender: userData.id,
                  notification_receiver_read: false,
                  notification_seen: null,
                  notification_type: "user_mention",
                  notification_receiver: formData && Array.isArray(formData?.subteam_estimate) && formData.subteam_estimate.length && Array.isArray(formData.subteam_estimate[0].members) && formData.subteam_estimate[0].members.length && formData.subteam_estimate[0].members.find(item => item.first_name === mentionedPersons[i])?.id
                }),
              }
            )
            if (!res.ok) {
              const data = await res.json();

              return;
            }
          }
        }

        const response = await fetch(
          `${ENDPOINT}/api/chat/channel/message/create/`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `JWT ${access_token}`,
            },
            body: JSON.stringify({
              channel: estimate_channel,
              message: replaceWordsWithSpan(html),
            }),
          }
        );

        if (!response.ok) {
          const data = await response.json();
          return;
        }

        const data = await response.json();
        setChats([...chats, {
          ...data,
          sender: userData
        }])
        dispatch(estimate_channels({ access_token }))

        setHtml('');
      }
    }
  }

  const [openLink, setOpenLink] = useState(false);
  const [linkUrl, setLinkUrl] = useState('');

  const setLink = () => {

    const url = linkUrl.trim(); // Trim whitespace from the input URL

    // Update link only if the URL is not empty
    if (url !== '') {
      btn.editor.chain().focus().extendMarkRange('link').setLink({ href: url }).run();
    }

    setOpenLink(false)
    setLinkUrl('')
  }
  

  return (
    <Offcanvas show={show} onHide={handleClose} placement="bottom ">
      <Offcanvas.Header>
        <button className="close-modal" onClick={() => {
          setShowChatEst(false)
          setShow(false)
          setIsDetOpen(true)
        }} />
      </Offcanvas.Header>
      <Offcanvas.Body style={{ overflowY: 'hidden' }}>
        <SimpleDialog
         open={openLink}
         setOpen={setOpenLink}
         linkUrl={linkUrl}
         setLinkUrl={setLinkUrl}
         setLink={setLink}
       />
        <Grid container direction="column" alignItems="flex-start">
          <Grid
            item
            container
            direction="column"
            alignItems="start"
            xl={12}
            lg={12}
            md={12}
            sm={12}
            sx={{ width: '90%', margin: '0 auto' }}
          >
            <Box>
              <h5>Chats: Estimates</h5>
              <h6
                style={{
                  backgroundColor: '#f5f5f5',
                  color: '#9d5cf2',
                  padding: '10px 5px',
                  borderRadius: '10px',
                }}
              >
                {formData ? formData.title : ''}
              </h6>
            </Box>

            <Box
              sx={{
                width: '100%',
                minHeight: '350px',
                border: '1px solid gray',
                borderRadius: '8px',
                padding: '0px 10px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'space-around',
              }}
            >
              <Box sx={{ minHeight: '220px', maxHeight: '400px', width: '99%', marginLeft: '10px', overflowY: 'auto' }}>
                <List sx={{ display: 'flex', flexDirection: 'column' }}>
                  {Array.isArray(chats) && chats.length ?

                    chats.map((item, index) => {
                      return (
                        <>
                          <List sx={{ width: '100%' }}>
                            <Box key={item.id}>
                              <ListItem alignItems="flex-start" key={item.id} id={`message-${item.id}`} >
                                <ListItemAvatar>
                                  <Avatar
                                    alt={item.sender.first_name}
                                    src={item.sender.avatar ? item.sender.avatar : "/static/images/avatar/1.jpg"}
                                  />
                                </ListItemAvatar>
                                <ListItemText
                                  primary={
                                    <Typography
                                      variant="subtitle2"
                                      sx={{
                                        color: '#03071E',
                                        letterSpacing: '-0.3px',
                                        fontWeight: 'bold',
                                        fontSize: '1rem',
                                        fontFamily: 'Open Sans',
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '0.5rem',
                                        opacity: item?.tempId ? 0.5 : 1,
                                      }}
                                    >
                                      {item?.sender?.first_name}

                                      <p
                                        style={{
                                          color: 'rgba(3, 7, 30, 0.4)',
                                          letterSpacing: '-0.3px',
                                          fontSize: '11px',
                                          fontWeight: '400',
                                          textAlign: 'left',
                                          fontFamily: 'Open Sans',
                                          marginBottom: '0',
                                        }}
                                      >
                                        {!item?.tempId ? `${new Date(
                                          item.created
                                        ).toLocaleTimeString([], {
                                          hour: '2-digit',
                                          minute: '2-digit',
                                          hour12: true,
                                        })}, ${new Date(
                                          item.created
                                        ).toDateString()}` : "Sending"}
                                      </p>
                                    </Typography>
                                  }
                                  secondary={
                                    <div
                                      style={{
                                        fontWeight: '400',
                                        fontSize: '14px',
                                        lineHeight: '22px',
                                        fontStyle: 'normal',
                                        color: '#03071E',
                                      }}
                                      dangerouslySetInnerHTML={{
                                        __html: item.message,
                                      }}
                                    />
                                  }
                                />
                              </ListItem>
                            </Box>
                          </List>
                        </>
                      );
                    })
                    :
                    <Box sx={{ minHeight: '220px', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: 'bold' }}>No Conversation in Current Project</Box>
                  }


                </List>
              </Box>
              <Box
                sx={{
                  backgroundColor: '#f5f5f5',
                  width: '100%',
                  borderRadius: '10px',
                  pb: 2
                }}
                onKeyDown={async (event) => {
                  send_Message(event)

                }}
              >
                {' '}
                <Test open={openLink} setOpen={setOpenLink} setNotRun={setNotRun} html={html} currentAllDil={formData.deliverable} setHtml={setHtml} check={check} deliverbales={formData.deliverable?.map((item) => item.deliverable_name)} mentions={formData && Array.isArray(formData?.subteam_estimate) && formData.subteam_estimate.length && Array.isArray(formData.subteam_estimate[0].members) && formData.subteam_estimate[0].members.length ? formData.subteam_estimate[0].members.map((item) => (item.first_name)) : []} />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Offcanvas.Body>
    </Offcanvas>
  );
}

export default ChatEst;
