import React, { useState } from "react";
import Gear from "./assets/Gear.png";
import Dots from "./assets/Dots.png";
import "./netnetU.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import SchoolIcon from "@mui/icons-material/School";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import SummaryImage from "./assets/Rectangle 196.png";
import { useSelector } from "react-redux";
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import { NetNetCheckBox } from "../../Componenets/Misc";
import { Box } from "@mui/material";

const LeftCourses = ({ chap }) => {
  const [showLessons, setShowLessons] = useState(false)
  const [checkboxChecked, setCheckboxChecked] = useState(false)
  const navigate = useNavigate();
  const formatMinutes = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    const seconds = 0;

    return `${hours.toString().padStart(2, '0')}:${remainingMinutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };
  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
        <h6 className="chapter-heading" >{chap.title}</h6>
        {showLessons ?
          <ExpandLessOutlinedIcon sx={{ cursor: 'pointer', marginLeft: '3rem' }} onClick={
            () => {
              setShowLessons(!showLessons)
            }
          } />
          :
          <KeyboardArrowDownOutlinedIcon sx={{ cursor: 'pointer', marginLeft: '3rem' }} onClick={
            () => {
              setShowLessons(!showLessons)
            }
          } />
        }

      </div>
      {showLessons ? <p style={{ color: '#964ed4' }} dangerouslySetInnerHTML={{ __html: chap?.description ?? '' }}></p> : null}
      {showLessons ?
        <ul className="chapter-list" style={{ width: '90%', }}>
          {chap.lessons.map((lesson) => {
            return (
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', }}>
                <li className="chapter-item">
                  <NetNetCheckBox checked={checkboxChecked}
                    onChange={() => setCheckboxChecked(!checkboxChecked)} />
                  <PlayCircleIcon
                    sx={{ width: '1.5rem', height: '1.5rem' }}
                    className="chapter-play"
                    onClick={() =>
                      navigate(
                        `/Lesson_Detail/${chap.related_course}/${chap.id}/${lesson.id}`
                      )
                    }
                  />
                  <span className="chapter-name" >{lesson.title}</span>

                </li>
                <p style={{ fontWeight: 'bold', color: '#000', }}>{formatMinutes(lesson.duration)}</p>
              </Box>
            );
          })}
        </ul>
        : null
      }
    </>
  )
}
// Always make sure you componot starts with capital letter
const courseDetail = () => {
  const { courseId } = useParams();
  const course = useSelector((state) => state.NetNetU.courses);
  const selectedCourse = course.filter((item) => item.id == courseId);
  const navigate = useNavigate();

    const extractVideoId = (url) => {
      const match = url?.match(/(?:\?v=|\/embed\/|\/\d\d\/|\/vi\/|\/v\/|https:\/\/www.youtube.com\/watch\?v=|https:\/\/youtu.be\/)([^#\&\?]*).*/);
      return (match && match[1].length === 11) ? match[1] : false;
    };

    const constructThumbnailUrl = (videoId) => {
      return `https://img.youtube.com/vi/${videoId}/default.jpg`;
    };

    // Handle rendering the thumbnail
    const handleRenderThumbnail = (youtubeURL) => {
      const videoId = extractVideoId(youtubeURL);

      if (videoId) {
        const thumbnailUrl = constructThumbnailUrl(videoId);
        return thumbnailUrl
      } else {
        console.error('Invalid YouTube URL');
      }
    };
    
  return (
    <>
      <div className="course-header">
        <div className="course-row">
          <div className="col-top">
            <h1 className="course-title">{selectedCourse[0].title}</h1>
            <img className="icon-sm" src={Dots} alt="dots" />
            <img className="icon-sm" src={Gear} alt="gear" />
          </div>
          <div className="col-bottom">
            <Link to={"/netnet_U"}>NeNet U</Link>
            <ChevronRightIcon />
            <Link to={""}>{selectedCourse[0].title}</Link>
          </div>
        </div>
      </div>
      <div className="course-body">
        <div style={{
          backgroundImage:`url(${handleRenderThumbnail(selectedCourse[0]?.chapters[0]?.lessons[0]?.video) ? handleRenderThumbnail(selectedCourse[0]?.chapters[0]?.lessons[0]?.video) : "./assets/Rectangle\ 187.png"})`,
          backgroundRepeat:"no-repeat",
          backgroundSize:"cover",
        }} className="course-pic">
        <div className="pic-left"  >

          <PlayCircleIcon sx={{ width: '6rem', height: '6rem', cursor: 'pointer' }} className="play-icon"
            onClick={() =>{
              if(selectedCourse[0]?.chapters[0]?.lessons[0]){
              navigate(
                `/Lesson_Detail/${selectedCourse[0]?.id}/${selectedCourse[0]?.chapters[0]?.id}/${selectedCourse[0]?.chapters[0]?.lessons[0]?.id}`
              )
              }else{
                consoe.log("You Dont Have Lessons in This Chapter")
              }
            }
            }
          />
        </div>
        <div className="pic-right" >
          <div className="detail-title">{selectedCourse[0].title}</div>
          <p className="detail-para" dangerouslySetInnerHTML={{ __html: selectedCourse[0]?.short_description ?? '' }}></p>
          <div className="span-group">
            <span>
              <SchoolIcon /> Beginner
            </span>
            <span>
              <AccessTimeIcon />
              00:00:00
            </span>
          </div>
          <p className="detail-para" dangerouslySetInnerHTML={{ __html: selectedCourse[0]?.short_description ?? '' }}></p>
          <button className="enroll" style={{ marginTop: '10px' }}>Enroll for Course</button>
        </div>
      </div>
      <div className="course-content">
        <div className="content-left">
          <h3>Summary</h3>
          <img src={SummaryImage} alt="" />
          <p className="summary" dangerouslySetInnerHTML={{ __html: selectedCourse[0]?.short_description ?? '' }}></p>
          <h3 style={{ marginBottom: '1.5rem', marginTop: '1.5rem' }}>Requirements</h3>
          <p className="summary" >No Requirement</p>
          <button className="enroll" style={{ marginBottom: '1.5rem', marginTop: '1.5rem' }}>Enroll for Course</button>
        </div>
        <div style={{ display:"flex", flexDirection:"column", borderRadius:"9px", padding:"16px",}} className="content-right">
          <h3 style={{paddingTop:"10px"}} >Course Outline</h3>
          <div className="chapter-loop">
            {selectedCourse[0].chapters.map((chap) => {
              return (
                <>
                  <LeftCourses chap={chap} />

                </>
              );
            })}
          </div>
          <div style={{display:"flex", justifyContent:"start", textAlign:"start"}} >
            <p className="summary" dangerouslySetInnerHTML={{ __html: selectedCourse[0]?.short_description ?? '' }}></p>
          </div>
        </div>
      </div>
    </div >
    </>
  );
};

export default courseDetail;
