import React from "react";
import { Link } from "react-router-dom";
import { Box } from "@mui/material";
import { MeInactive,MeActive,ProjectInactive, ProjectsActive, ChatActive } from "../../Componenets/SideNav/SideImages";
import chatInActive from '../../Assets/NavLink/Stream.png'
import { People, PeopleActive, Timer } from "./Assets/WatchIconBottomBar";

function BottomNavigation({setShowNav}){
    const bottomNavigation = {
        firstDiv : {
          width : '100%',
          height : '70px', 
          backgroundColor : '#fffff1', 
          position : 'fixed', 
          bottom : '0', 
          left : '0',
          backgroundColor:'white',
          borderTopColor : 'black',
          borderTopWidth : '1',
        
        },
        secondDiv : {
        display : 'flex',
        alignItems:'center',
        flexDirection:'row',
        backgroundColor:'white',
        justifyContent:'space-around',
        height:'100%',
        borderRadius: '10px 10px 0px 0px',
        borderTop: '1px solid var(--Functions-Tab-Stroke, #D9D9D9)',
        background: 'var(--White-White-95, rgba(255, 255, 255, 0.95))',
      },
      ThirdDiv :{
        display:"flex",
        justifyContent:'center',
        alignItems:"center",
        width:'25px'

      }
      }

      ////////////////////////////////////////////////////////////////////////////////


        // Get the current URL
        const currentUrl = window.location.href;

        
    return(
      <div style={bottomNavigation.firstDiv}>
      <div  style={bottomNavigation.secondDiv} >
      <Link to="/Dashboard"> {currentUrl?.includes('Dashboard') ? <Box sx={bottomNavigation.ThirdDiv}> <MeActive/> </Box> : <Box sx={bottomNavigation.ThirdDiv}> <MeInactive/> </Box>} </Link>
      <Link to='/Tasks'> { currentUrl?.includes('Tasks') ? <Box sx={bottomNavigation.ThirdDiv}> <ProjectsActive/> </Box> : <Box sx={bottomNavigation.ThirdDiv}> <ProjectInactive/> </Box> } </Link>
       <Link to='/Timer'>{ currentUrl?.includes('Timer') ? <Box sx={{width:"40px"}}> <Timer svgColor={'#711FFF'} /> </Box>  : <Box sx={{width:'40px'}}> <Timer svgColor={'#03071E'} /> </Box> }</Link>
        <Link to='/Chat'> { currentUrl?.includes('Chat') ? <Box sx={bottomNavigation.ThirdDiv}> <ChatActive/> </Box> : <Box sx={bottomNavigation.ThirdDiv}> <img src={chatInActive} width='20px' height='20px' alt='icon' /> </Box> } </Link>
        <Link to='/People'> { currentUrl?.includes('People') ? <Box sx={bottomNavigation.ThirdDiv}> <PeopleActive svgColor={'#711FFF'} /> </Box> : <Box sx={bottomNavigation.ThirdDiv}> <People svgColor={'#711FFF'} /> </Box>  } </Link>
        </div>
    </div>

    )
}

export default BottomNavigation