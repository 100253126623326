import { Alert, Avatar,} from '@mui/material';
import { React, useEffect, useState, useRef } from 'react';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import FilterListIcon from '@mui/icons-material/FilterList';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import { Drawer, Box, Typography } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import SendIcon from '@mui/icons-material/Send';
import {
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableHead,
  TableCell,
  Paper,
} from '@mui/material';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import MenuItem from '@mui/material/MenuItem';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { TextField,} from '@mui/material';
import { ENDPOINT } from '../Opportunity/Redux/oppor';
import { useSelector } from 'react-redux';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import MenuList from '@mui/material/MenuList';
import Popper from '@mui/material/Popper';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import { useDispatch } from 'react-redux';
import { getCollabs } from './redux';
import helpVid from '../Opportunity/assets/Section_Help-idle.svg'
import Bounce from 'react-reveal/Bounce';
import HelpVideo from '../../Componenets/TopNav/HelpVideo';

const IconComp = ({ row }) => {
  const [open, setOpen] = useState(false);

  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  return (
    <>
      <MoreHorizIcon
        ref={anchorRef}
        id="composition-button"
        aria-controls={open ? 'composition-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        sx={{ color: 'GrayText', fontSize: '30px', cursor: 'pointer' }}
      />

      <div>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-start"
          transition
          disablePortal
          sx={{
            borderRadius: '10px',
            '& .css-1ps6pg7-MuiPaper-root': {
              backgroundColor: '#fff',
              color: 'rgba(0, 0, 0, 0.87)',
              boxShadow: 'none',
            },
          }}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom-start' ? 'left top' : 'left bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                    onKeyDown={handleListKeyDown}
                    sx={{
                      width: {
                        xl: '180px',
                        lg: '110px',
                      },
                      boxShadow: 'none',
                      border: '0.5px solid #9f9c9cde',
                      borderRadius: '10px',
                    }}
                  >
                    {row.status.props.children === 'Pending Approval' && (
                      <MenuItem
                        onClick={handleClose}
                        sx={{
                          color: 'gray',
                          display: 'flex',
                          gap: { xl: '1rem', lg: '.5rem' },
                          marginTop: { xl: '.6rem', lg: '.3rem' },
                        }}
                      >
                        {' '}
                        <SendRoundedIcon /> Resend
                      </MenuItem>
                    )}
                    <MenuItem
                      onClick={handleClose}
                      sx={{
                        color: 'red',
                        display: 'flex',
                        gap: { xl: '1rem', lg: '.5rem' },
                        marginTop: { xl: '.6rem', lg: '.3rem' },
                        marginBottom: { xl: '.6rem', lg: '.3rem' },
                      }}
                    >
                      {' '}
                      <DeleteOutlineIcon /> Delete
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </>
  );
};

const Collab = ({ screenHeight }) => {
  const access_token = useSelector((state) => state.auth.user.access);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      getCollabs({
        access_token,
      })
    );
  }, []);

  const colabs = useSelector((state) => state.Misc.collabs);
  const [searchQuery, setSearchQuery] = useState('');
  const [tableData, setTableData] = useState([]);
  const [formData, setFormData] = useState({});
  useEffect(() => {
    if (Array.isArray(colabs) && colabs.length) {
      const toBeSet = colabs.map((item) => {
        return {
          id: item?.collab?.id,
          name:
            item?.collab?.first_name === 'first_name'
              ? item?.collab?.email.split('@')[0]
              : item?.collab?.first_name + ' ' + item?.collab?.last_name,
          email: item?.collab?.email,
          status:
            item?.collab?.first_name === 'first_name' ? (
              <p
                style={{
                  color: '#711fff',
                  marginBottom: '0px',
                  fontWeight: '500',
                  fontSize: '12px',
                }}
              >
                INVITED (WAITING FOR THE APPROVAL)
              </p>
            ) : (
              <p
                style={{
                  color: '#1553e5',
                  marginBottom: '0px',
                  fontWeight: '500',
                  fontSize: '12px',
                }}
              >
                CONNECTED
              </p>
            ),
          action: '',
          img: 'Logo',
        };
      });

      setTableData(toBeSet);
    }
  }, [colabs,]);

  const filteredTable =
    tableData &&
    tableData.filter((item) =>
      item.name.toLowerCase().startsWith(searchQuery.toLowerCase())
    );
  const tableData2 = searchQuery ? filteredTable : tableData;

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [text, setText] = useState('');

  const [succ, setSucc] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError(false);
        setErrorMessage('');
      }, 3000);
    }
  }, [error]);

  useEffect(() => {
    if (succ) {
      setTimeout(() => {
        setSucc(false);
      }, 3000);
    }
  }, [succ]);

  useEffect(() => {
    if (loading) {
      setTimeout(() => {
        setLoading(false);
      }, 20000);
    }
  }, [loading]);

  const handleException = (data) => {
    setError(true);
    for (const key in data) {
      setErrorMessage(data[key]);
    }
    return data;
  };

  const handleSucc = () => {
    setSucc(true);
    return;
  };

  const [text2, setText2] = useState('');
 
  const handleClose = () => {
    setIsDrawerOpen(false);
  };

  const handleSubmition = async (e) => {
    if (loading) {
      return;
    }
    setLoading(true);
    e.preventDefault();
    if (
      !formData.invitation ||
      !Array.isArray(formData.invitation) ||
      !formData.invitation.length
    ) {
      return;
    }
    setLoading(true);
    formData.invitation.forEach(async (text) => {
      if (!isValidEmail(text.inputValue)) {
        return;
      }
      try {
        const res = await fetch(
          `${ENDPOINT}/api/opportunity/create/collaborator/`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `JWT ${access_token}`,
            },
            body: JSON.stringify({
              email: text.inputValue,
              message: text2
            }),
          }
        );
        const data = await res.json();
        if (!res.ok) {
          setLoading(false);
          handleException(data);
          // return;
        }
      } catch (err) {
        setLoading(false);
        setError(true);
        setErrorMessage('Something went wrong connecting to the server');
      }
    });
    setLoading(false);
    dispatch(
      getCollabs({
        access_token,
      })
    );
    handleSucc();
    handleClose();
  };

  const currentCompanies = useSelector((state) => state.Contacts.persons);
  const companyOptions = currentCompanies
    .filter(Boolean)
    .filter((ele) => ele.email)
    .map((company) => ({
      name: company.email,
      id: company.id,
    }));

  const filter = createFilterOptions();


  const isValidEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailRegex.test(email);
  };


  const [show, setShow] = useState(false)

  return (
    <Bounce left>
      <>
        <div
          style={{
            display: 'block',
            justifyContent: 'space-between',
            alignItems: 'center',
            backgroundColor: '#f7f7fa',
            padding: '1rem',
            height: "100vh"
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: '1rem',
            }}
          >
            <ul
              style={{
                display: 'flex',
                justifyContent: 'start',
                alignItems: 'center',
                marginBottom: '0px',
                padding: '0px',
                gap: '1rem',
              }}
            >
              <li
                sx={{
                  fontSize: '2.2rem',
                }}
              >
                <h3
                  style={{
                    fontWeight: 'bold',
                    color: '#000',
                    paddingLeft: '1rem',
                  }}
                >
                  Collaborators
                </h3>
              </li>

              <li>
                <MoreHorizIcon
                  style={{
                    color: 'gray',
                  }}
                />
              </li>
              <li
                style={{
                  display: 'flex',
                }}
              >
                <FilterListIcon
                  style={{
                    color: 'gray',
                  }}
                />{' '}
                <span
                  style={{
                    color: 'gray',
                    marginLeft: '5px'
                  }}
                >
                  Filters
                </span>
                <img src={helpVid} onClick={() => { setShow(true) }} style={{ color: '#a6a6a6', marginLeft: '10px', width: '28px', height: '28px', cursor: 'pointer' }} />
              </li>
            </ul>

            <button
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-around',
                backgroundColor: '#9152ff',
                borderRadius: '6px',
                width: '8%',
                color: 'white',
                padding: '.5rem 0.5rem',
              }}
              onClick={() => setIsDrawerOpen(true)}
            >
              <AddCircleOutlineIcon
                style={{
                  fontSize: '1.5rem',
                }}
              />{' '}
              <span
                style={{
                  fontSize: '1rem',
                  fontWeight: '500',
                }}
              >
                Invite
              </span>
            </button>
          </div>
          <Box
            sx={{
              width: '100%',
              height: screenHeight - 145,
              backgroundColor: 'white',
              borderRadius: '8px',
              marginTop: '2rem',
              border: '1px solid lightGray',
              overflow: "auto",
              padding: '1rem',
              paddingRight: '2rem',
              paddingLeft: '2rem',
              borderBottom: 0,
            }}
          >
            <div>
              <Box
                sx={{
                  height: '5rem',
                  width: '100%',
                  borderRadius: '8px',
                  backgroundColor: '#d7ebfe',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  padding: '0rem 1.5rem',
                }}
              >
                <Box>
                  <Typography sx={{ fontWeight: 'bold' }}>
                    Add Collaborators
                  </Typography>
                  <Typography
                    sx={{ color: 'gray', fontSize: '13px', fontWeight: 'thin' }}
                  >
                    Add your Collaborator
                  </Typography>
                </Box>

                <Paper
                  component="form"
                  sx={{
                    p: ' 0px 2px',
                    display: 'flex',
                    alignItems: 'center',
                    width: '30%',
                    borderRadius: '8px',
                    boxShadow: 'none',
                  }}
                >
                  <IconButton sx={{ p: '10px' }} aria-label="menu">
                    <SearchIcon />
                  </IconButton>
                  <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    placeholder={'Search Collaborators'}
                    inputProps={{ 'aria-label': 'Search here ' }}
                    onChange={(e) => {
                      setSearchQuery(e.target.value);
                    }}
                  />
                </Paper>
              </Box>
            </div>
            <Box
              sx={{
                marginTop: '2rem',
                height: '100%',
                width: '100%',
                borderRadius: '8px',
              }}
            >
              <TableContainer
                sx={{
                  borderRadius: '8px', maxHeight: screenHeight - 250,
                  overflow: 'auto', scrollbarWidth: "none", border: '1.5px solid lightGray',
                }}
              >
                <Table
                  stickyHeader
                  aria-label="sticky table"
                  sx={{
                    borderRadius: "8px",
                  }}
                >
                  <TableHead sx={{
                    backgroundColor: '#f2f2f2',
                    borderRadius: "8px",
                  }} >
                    <TableRow sx={{ height: '70px' }}>
                      <TableCell
                        sx={{
                          color: 'GrayText',
                          fontSize: '15px',
                          backgroundColor: '#f2f2f2',

                        }}
                      >
                        Collaborator
                      </TableCell>
                      <TableCell
                        sx={{
                          color: 'GrayText',
                          fontSize: '15px',
                          backgroundColor: '#f2f2f2',
                        }}
                      >
                        Email
                      </TableCell>
                      <TableCell
                        sx={{
                          color: 'GrayText',
                          fontSize: '15px',
                          backgroundColor: '#f2f2f2',
                        }}
                      >
                        Status
                      </TableCell>
                      <TableCell
                        sx={{
                          color: 'GrayText',
                          fontSize: '15px',
                          backgroundColor: '#f2f2f2',
                        }}
                      ></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Array.isArray(tableData2) && tableData2.length ? (
                      tableData2.map((row) => (
                        <TableRow key={row.id} hover>
                          <TableCell>
                            {' '}
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '0.8rem',
                                fontSize: '16px',
                              }}
                            >
                              <Avatar
                                sx={{ height: '25px', width: '25px' }}
                                src={row.img ? row.img : '/sharp.png'}
                                alt={row.name}
                              />
                              {row.name}
                            </div>
                          </TableCell>
                          <TableCell
                            style={{
                              alignItems: 'center',
                              fontSize: '14px',
                              color: '#585151',
                            }}
                          >
                            {row.email}
                          </TableCell>
                          <TableCell sx={{ fontSize: '14px', fontWeight: '500' }}>
                            {row.status}
                          </TableCell>
                          <TableCell align="center">
                            <IconComp row={row} />
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={4}>
                          <Alert severity="info">
                            You dont have any Collaborators yet. You can still
                            Invite other peoples to be your Collaborator!
                          </Alert>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>

          <HelpVideo show={show} setShow={setShow} />
        </div>
        <Drawer
          anchor="bottom"
          open={isDrawerOpen}
          onClose={() => setIsDrawerOpen(false)}
        >
          <Box
            p={2}
            height="90%"
            width="100%"
            textAlign="center"
            role="presentartion"
          >
            <Typography variant="h6" component="div">
              <div
                style={{
                  display: 'Flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Box
                  style={{
                    border: '1px solid lightGray',
                    borderRadius: '6px',
                    width: '800px',
                    padding: '2rem',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      paddingBottom: '2rem',
                    }}
                  >
                    <span
                      style={{
                        fontWeight: 'bold',
                      }}
                    >
                      Invite people to Net Net
                    </span>
                    <CloseIcon
                      style={{
                        cursor: 'pointer',
                      }}
                      onClick={() => setIsDrawerOpen(false)}
                    />
                  </div>
                  <div
                    style={{
                      textAlign: 'start',
                    }}
                  >
                    <span
                      style={{
                        fontWeight: 'bold',
                        fontSize: '1rem',
                      }}
                    >
                      To:
                    </span>
                  </div>
                  <div
                    style={{
                      textAlign: 'start',
                    }}
                  >
                    <Autocomplete
                      id="company"
                      options={companyOptions}
                      multiple
                      includeInputInList
                      PopperComponent={(props) => (
                        <Popper {...props} sx={{
                          overflowY: 'none', // Enable vertical scrolling
                          scrollbarColor: 'transparent transparent', // For Firefox
                          '&::-webkit-scrollbar': {
                            display: "none",
                          },

                          // Styles for Microsoft Edge
                          '&::-ms-scrollbar': {
                            width: 0,
                            background: "transparent transparent"
                          },
                        }} />
                      )}
                      freeSolo
                      onChange={(e, value) => {
                        setFormData({
                          ...formData,
                          invitation: value,
                        });
                      }}
                      getOptionLabel={(option) => {
                        if (typeof option === 'string') {
                          return option;
                        }
                        if (option.inputValue) {
                          return option.inputValue;
                        }
                        return option.name;
                      }}
                      filterOptions={(options, params) => {
                        const filtered = filter(options, params);
                        if (params.inputValue !== '') {
                          if (isValidEmail(params.inputValue)) {
                            filtered.push({
                              inputValue: params.inputValue,
                              name: `"${params.inputValue}"`,
                            });
                          }
                        }
                        return filtered;
                      }}
                      sx={{
                        width: '100%',
                      }}
                      renderOption={(props, option) => (
                        <li {...props}>{option.name}</li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Enter Email"
                          variant="outlined"
                          color="secondary"
                          sx={{
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor: '#e7e7e7',
                              },
                              '&:hover fieldset': {
                                borderColor: '#711fff',
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: '#5a0bdf',
                              },

                              '&.Mui-error fieldset': {
                                borderColor: 'red',
                              },
                            },
                            '& .MuiFormLabel-root': {
                              color: '#03071e66',
                            },
                            '& .MuiFormLabel-root.Mui-focused': {
                              color: '#5a0bdf',
                            },
                            '& .MuiFormLabel-root.Mui-error': {
                              color: 'red',
                            },
                          }}
                          type="email"
                          InputProps={{
                            ...params.InputProps,
                            style: { width: '100%' },
                          }}
                        />
                      )}
                    />
                  </div>
                  <div
                    style={{
                      marginTop: '1rem',
                      display: 'flex',
                      alignItems: 'end',
                      justifyContent: 'end',
                    }}
                  >
                    <button
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor:
                          !formData.invitation ||
                            !Array.isArray(formData.invitation) ||
                            !formData.invitation.length
                            ? '#d2b8ff'
                            : '#711fff',
                        padding: '0.5rem 1rem',
                        borderRadius: '8px',
                        gap: '0.7rem',
                        fontSize: '15px',
                        color: 'white',
                        width: '110px',
                      }}
                      className="noBorder"
                      onClick={handleSubmition}
                    >
                      {loading ? (
                        <i className="bx bx-loader-alt bx-spin" />
                      ) : (
                        <>
                          <SendIcon
                            style={{
                              transform: 'rotate(-45deg)',
                              height: '20px',
                              width: '20px',
                            }}
                          />
                          Send
                        </>
                      )}{' '}
                    </button>
                  </div>
                </Box>
              </div>
            </Typography>
            {succ && (
              <Alert severity="success">{text + ' ' + 'Has been Invited'}</Alert>
            )}

            {error && <Alert severity="error">{errorMessage}</Alert>}
          </Box>
        </Drawer>
      </>
    </Bounce>
  );
};

export default Collab;
