import React from "react";
import "./netnetU.css";
import Gear from "./assets/Gear.png";
import Dots from "./assets/Dots.png";
import { Link } from "react-router-dom";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Grid from "@mui/material/Grid";
import { useSelector } from "react-redux";
import VideoDemo from "../../Componenets/VideoDemo";
const AllLesson = () => {
  const chapters = useSelector((state) => state.NetNetU.chapters);
  return (
    <>
      <section className="oppor-section">
        <div className="oppor-row">
          <div className="oppor-col">
            <h3 className="fstxtPro">All Lesson</h3>
            <img className="icon-sm" src={Dots} alt="dots" />
            <img className="icon-sm" src={Gear} alt="gear" />
          </div>
        </div>
        <div className="btn-group">
          <Link to={"/netnet_U"}>NetNetU</Link>
          <ChevronRightIcon />
          <Link to={"/All_Lessons"}>All Lessons</Link>
        </div>
        <Grid
          container
          direction="column"
          sx={{
            marginTop: "30px",
            gap: "2rem",
           
          }}
        >
          {chapters.map((codex) => (
            <>
              <h3 className="chapter-title">{codex.title}</h3>
              <Grid container spacing={1}>
                {codex.lessons.map((lesson) => (
                  <Grid
                    item
                    xs={3}
                    key={lesson.id}
                    sx={{
                      position: "relative",
                    }}
                  >
                    <VideoDemo
                      thumbnail={lesson.thumbnail}
                      duration={lesson.duration}
                      video={lesson.video}
                      lesson={lesson}
                      renderDet={true}
                    />
                  </Grid>
                ))}
              </Grid>
            </>
          ))}
        </Grid>
      </section>
    </>
  );
};

export default AllLesson;
