import { Box, Divider, Menu, MenuItem } from "@mui/material";
import React, {useState, useRef} from "react";
import ExpandMoreSharpIcon from '@mui/icons-material/ExpandMoreSharp';
import { useSelector } from "react-redux";

const ParamControl = ({ params, setParams }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    const allTasks = useSelector((state) => state.projects.tasks);
    const boxRef = useRef()
    
    return (
      <Box sx={{ width: 'fit-content' }}>
        <Box
          id="demo-customized-button"
          aria-controls={open ? 'demo-customized-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          variant="contained"
          disableElevation
          onClick={handleClick}
          ref={boxRef}
          sx={{
            p: '0.14rem 1.58rem',
            background: '#d1b9ff',
            borderRadius: '8px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '45px',
            color: '#000',
            cursor: 'pointer',
          }}
        >
          {params.value === 'time' ? 'TIME' : params.value === 'quick_task' ? 'QUICK TASK' : params.value === 'estimate' ? 'ESTIMATE vs ACTUAL' : params.value === 'sales' ? 'SALES' : params.value === 'team' ? 'TEAM' : ''} REPORTS
          <ExpandMoreSharpIcon sx={{ fontSize: '30px' }} />
        </Box>
        <Menu
          sx={{
            '& .MuiPaper-root MuiPaper-elevation MuiPaper-rounded': {
              width: boxRef.current ? `${boxRef.current.clientWidth}px` : 'auto', // Set the width based on the Box's client width
            },
  
            '& .css-6hp17o-MuiList-root-MuiMenu-list': {
              width: boxRef.current ? `${boxRef.current.clientWidth}px` : 'auto',
              color: '#000',
              maxHeight: '400px',
            },
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          <MenuItem
            onClick={() => {
              setParams({
                value: 'time',
                data: {
                  chartData: allTasks.map((item) => {
                    if (
                      !Array.isArray(item.time_tracking) ||
                      !item.time_tracking.length
                    ) {
                      return;
                    }
  
                    const extraction = [
                      ...item.time_tracking.map((tracking) => ({
                        ...tracking,
                        name: item.task_name,
                        project: item.task_project,
                      })),
                    ];
  
                    return extraction;
                  }),
                },
              })
              setAnchorEl(null)
            }
            }
            disableRipple
          >
            Time
          </MenuItem>
          <Divider sx={{ my: 0.5 }} />
          <MenuItem
            onClick={() => {
              console.log('yes this click')
              setAnchorEl(null)
              setParams({
                value: 'estimate',
                data: [],
              })
            }
            }
            disableRipple
          >
            Estimate vs Actual
          </MenuItem>
          <Divider sx={{ my: 0.5 }} />
          <MenuItem
            onClick={() => {
              setAnchorEl(null)
              setParams({
                value: 'quick_task',
                data: {
                  chartData: allTasks
                },
              })
            }
            }
            disableRipple
          >
            Quick Tasks
          </MenuItem>
          <Divider sx={{ my: 0.5 }} />
          <MenuItem
            onClick={() => {
              setAnchorEl(null)
              setParams({
                value: 'team',
                data: {
                  chartData: allTasks
                },
              })
            }
            }
            disableRipple
          >
            Team
          </MenuItem>
          <Divider sx={{ my: 0.5 }} />
          <MenuItem
            onClick={() => {
              setAnchorEl(null)
              setParams({
                value: 'sales',
                data: [],
              })
            }
            }
            disableRipple
          >
            Sales
          </MenuItem>
        </Menu>
      </Box>
    );
  };

export default ParamControl