import { Grid, Box, TextField, Typography } from '@mui/material';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ENDPOINT } from '../../pages/Opportunity/Redux/oppor';
import logo from './Assets/MobileLogo.png';
import { Link } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PreLoader from '../Misc';
import resetsvg from './Assets/verifymail.svg'
import NetNetLogo from '../Logo';


export function Header() {
  const LogoDiv = {
    bgDiv: {
      width: '100%',
      height: '40px',
      backgroundColor: '#1c04bd',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },

    netNetDiv: {
      width: '91px',
      height: '30px',
      backgroundColor: 'none',
      margin: '0 auto',
    },
  };

  return (
    <div style={LogoDiv.bgDiv}>
      <div style={LogoDiv.netNetDiv}>
        <NetNetLogo />
      </div>
    </div>
  );
}

const Intro = ({screenSize}) => {
  

  return (
    <>
      {screenSize < 900 && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            width: '200px',
            margin: '1rem 0',
          }}
        >
          <img src={logo} alt="logo" width="280px" height="auto" />
        </Box>
      )}

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          width: { lg: '60%', md: '70%', xs: '100%' },
          padding: '0 1.5rem',
        }}
      >
        <Link exact to="/Login">
          <ArrowBackIcon
            sx={{
              position: 'absolute',
              top: { xl: '10%', lg: '7%', md: '7%', sm: '7%', xs: '7%' },
              left: { xl: '12%', lg: '12%', md: '12%', sm: '2%', sx: '2%' },
              color: '#711fff',
              cursor: 'pointer',
            }}
          />
        </Link>
        <p className="sub-heading">Reset Password</p>
      </Box>
    </>
  );
};

const ResetForm = ({screenSize}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [password, setPassword] = useState('');
  const [rePassword, setRePassword] = useState('');
  const navigate = useNavigate();
  
  const url = new URL(window.location.href);
  const token = url.searchParams.get('token');
  const uid = url.searchParams.get('uid');

  const handleSubmittion = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (password !== rePassword) {
      setError(true);
      setErrorMessage('Passwords do not match');
      setLoading(false);
    } else {
      const response = await fetch(
        `${ENDPOINT}/auth/users/reset_password_confirm/`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            uid,
            token,
            new_password: password,
            re_new_password: password,
          }),
        }
      );
      if (!response.ok) {
        setError(true);
        setErrorMessage('Something wennt Wrong');
      }
      setLoading(false);
      navigate('/complete');
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: { lg: 'flex-start', md: 'flex-start', xs: 'center' },
        width: { lg: '60%', md: '70%', xs: '100%' },
        padding: '0 1.5rem',
      }}
      onSubmit={handleSubmittion}
    >
      <TextField
        type="password"
        variant="outlined"
        sx={{
          width: '100%',
          margin: '0.90rem 0rem',
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: '#ADADAD',
            },
            '&:hover fieldset': {
              borderColor: '#711fff',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#5a0bdf',
            },

            '&.Mui-error fieldset': {
              borderColor: 'red',
            },

            '&.Mui-disabled fieldset': {
              borderColor: 'grey',
            },
          },
          '& .MuiFormLabel-root': {
            color: '#03071e66',
          },
          '& .MuiFormLabel-root.Mui-focused': {
            color: '#5a0bdf',
          },
          '& .MuiFormLabel-root.Mui-error': {
            color: 'red',
          },
          '& .css-1d3z3hw-MuiOutlinedInput-notchedOutline': {
            top: '-1px',
          },
        }}
        label="New Password"
        onChange={(e) => setPassword(e.target.value)}
        onInvalid={(e) => {
          e.target.setCustomValidity(
            'Password must be at least 8 characters long '
          );
        }}
        onInput={(e) => {
          e.target.setCustomValidity('');
          e.target.reportValidity();
          setErrorMessage('');
          setError(false);
        }}
        minLength="8"
      />

      <TextField
        type="password"
        variant="outlined"
        sx={{
          width: '100%',
          margin: '0.90rem 0rem',
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: '#ADADAD',
            },
            '&:hover fieldset': {
              borderColor: '#711fff',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#5a0bdf',
            },

            '&.Mui-error fieldset': {
              borderColor: 'red',
            },

            '&.Mui-disabled fieldset': {
              borderColor: 'grey',
            },
          },
          '& .MuiFormLabel-root': {
            color: '#03071e66',
          },
          '& .MuiFormLabel-root.Mui-focused': {
            color: '#5a0bdf',
          },
          '& .MuiFormLabel-root.Mui-error': {
            color: 'red',
          },
          '& .css-1d3z3hw-MuiOutlinedInput-notchedOutline': {
            top: '-1px',
          },
        }}
        label="Confirm Password"
        onChange={(e) => setRePassword(e.target.value)}
        onInput={(e) => {
          e.target.setCustomValidity('');
          e.target.reportValidity();
          setErrorMessage('');
          setError(false);
        }}
      />

      {error && (
        <Alert severity="error" sx={{ width: '100%' }} className="error">
          {errorMessage}
        </Alert>
      )}
      <button style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} className="btn inline" type="submit" onClick={handleSubmittion}>
        {loading ? <Box sx={{ width: '40px', height: '40px' }}><PreLoader /></Box> : 'Confirm'}{' '}
      </button>
    </Box>
  );
};

const ConfirmReset = () => {

  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const handleResize = () => {
    setScreenSize(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
return (
  <Grid container component="main" sx={{ height: screenSize < 900 ? '78vh' : "100vh" }}>
    {screenSize < 900 && <Header />}

    {screenSize >= 900 &&
      <Grid
        item
        xs={0}
        sm={0}
        md={7}
        lg={7}
        xl={7}
        xxl={7}
        sx={{
          backgroundColor: '#47d1fc',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: "hidden"
        }}
      >
        <Box sx={{ width: { xs: "70%", lg: '60%', xl: '50%', xxl: '50%' }, }}>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <img src={logo} width={'100%'} height={'auto'} alt="Mobile Logo" />
          </Box>
          <Box sx={{ my: 3 }}>
            <Typography sx={{ fontSize: '20px', lineHeight: '26px', fontWeight: '400', fontFamily: 'open Sans', color: 'white' }}>
              Putting Profits Into Your Workflow Tools!
            </Typography>
            <Typography sx={{ fontSize: '20px', lineHeight: '26px', fontWeight: '400', fontFamily: 'open Sans', color: 'white', mt: 1 }}>
              - opportunities & sales
            </Typography>
            <Typography sx={{ fontSize: '20px', lineHeight: '26px', fontWeight: '400', fontFamily: 'open Sans', color: 'white', mt: 1 }}>
              - project, retainer & task management
            </Typography>
            <Typography sx={{ fontSize: '20px', lineHeight: '26px', fontWeight: '400', fontFamily: 'open Sans', color: 'white', mt: 1 }}>
              - time tracking
            </Typography>
            <Typography sx={{ fontSize: '20px', lineHeight: '26px', fontWeight: '400', fontFamily: 'open Sans', color: 'white', mt: 1 }}>
              - team communications
            </Typography>
          </Box>
          <img src={resetsvg} alt="Login SVG" width={'100%'} height={'auto'} />
        </Box>
      </Grid>
    }
    <Grid
      item
      container
      xs={12}
      sm={12}
      md={5}
      lg={5}
      xl={5}
      sx={{
        gap: '0.45rem',
        padding: '0.45rem 0',
        justifyContent: 'center',
        position: 'relative'
      }}
      direction="column"
      alignItems="center"
    >
      <Intro screenSize={screenSize} />
      <ResetForm screenSize={screenSize} />
    </Grid>
  </Grid>
);
}
export default ConfirmReset;
