import React, { useState, useEffect } from "react";
import "./addNewCourse.css";
import Avatar from "@mui/material/Avatar";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import YouTubeLink from "./YouTubeLink";
import ChipsArray from "./ChipsArray";
import { Link, useNavigate } from "react-router-dom";
import NotificationsNoneIcon from "../assets/notificationBell.svg";
import { useSelector } from "react-redux";
import { ENDPOINT } from "../../../../Opportunity/Redux/oppor";

const AddNewCourses = () => {

  const access_token = useSelector((state) => state.auth.user.access);
  const navigate = useNavigate()

  const [newChip, setNewChip] = useState({
    label: "",
  });
  const [chipData, setChipData] = useState([
    { key: 0, label: "Starting Up" },
    { key: 1, label: "Basic Information" },
    { key: 2, label: "Acount Information" },
  ]);

  const addChip = () => {
    if (newChip.label.trim() !== "") {
      const newKey = chipData.length;

      const newChipObject = { key: newKey, label: newChip.label };

      setChipData((prevChipData) => [...prevChipData, newChipObject]);
      setFormData((prevData) => ({
        ...prevData,
        tags: [...prevData.tags, newChipObject],
      }));
      setNewChip({ label: "" });
    }
  };

  const [formData, setFormData] = useState({
    title: "",
    description: "",
    video: "",
    youtube_id: "",
    duration: "",
    tags: chipData
  });

  const handleContinue = async () => {

    const response = await fetch(`${ENDPOINT}/api/netnetu/createcourse/`, {
      method:"POST",
      headers:{
        "Content-Type" :"application/json",
        Authorization: `JWT ${access_token}`
      },
      body:JSON.stringify(formData)
    })

    if(!response.ok){
    }else{
       const responseData = await response.json()
      navigate("/SuperAdmin/NetNetU/Courses/Chapter/New", {
        state:{responseData}
      })
    }
    
  };

  const currentUser = useSelector((state) => state.userData.userData);

  return (
    <>
      <div className="coursesWrapper">
        <div className="netNetU">
          <div className="title">
            <span>NET NET U</span>
          </div>
          <div className="icons">
            <div
              style={{
                width: 238,
                height: 40,
                color: "#711FFF",
                textAlign: "right",
                borderRight: "0.5px solid ",
                borderColor: "#BDBDBD",
                padding: 5,
                paddingRight: 38
              }}
            >
              <img style={{

              }} src={NotificationsNoneIcon} />
            </div>
            <Avatar
              style={{
                padding: 5,
                width: 30,
                height: 30,
                textTransform: "uppercase"
              }}
              alt="Avatar"
              variant="circular"
            >
              {currentUser ? currentUser.first_name.charAt(0) : "U"}

            </Avatar>
          </div>
        </div>

        <div className="courses">
          <div className="cor" style={{ alignItems: "center" }}>
            <h2>Add New Course</h2>
          </div>
        </div>

        <div className="courseNew">
          <div className="yourCourses">
            <h3>Course Detail</h3>
            <p>Please enter your course title, description and cover image</p>
          </div>
          <div className="courseInfo full">
            <div className="leftInfo field">
              <TextField
                sx={{
                  borderRadius: "4px",
                  marginBottom: "28px",
                  borderColor: "#d9d9d9",
                }}
                value={formData.title}
                onChange={(e) =>
                  setFormData({ ...formData, title: e.target.value })
                }
                fullWidth
                label="Course Title"
                variant="outlined"
                placeholder="Enter Your Course Title"
              />
              <TextField
                sx={{
                  borderColor: "#d9d9d9",
                  borderRadius: "4px",
                }}
                value={formData.description}
                onChange={(e) =>
                  setFormData({ ...formData, description: e.target.value })
                }
                multiline
                fullWidth
                rows={10}
                label="Enter Course Brief Description"
                variant="outlined"
              />
            </div>
            <div className="rightInfo">
              <YouTubeLink formData={formData} setFormData={setFormData} />
            </div>
          </div>
          <div className="videoTags">
            <div className="internalBox">
              <div
                style={{
                  width: "100%",
                  position: "relative",
                  fontSize: "16px",
                  lineHeight: "100%",
                  fontWeight: "600",
                  fontFamily: "'Open Sans'",
                  color: "rgba(0, 0, 0, 0.87)",
                  textAlign: "left",
                  display: "inline-block",
                }}
              >
                Add Your Video Tags
              </div>

              <div className="tagInput">
                <div
                  style={{
                    width: "100%",
                    position: "relative",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    padding: "9px 12px 8px",
                    boxSizing: "border-box",
                    gap: "3px",
                    textAlign: "left",
                    fontSize: "12px",
                    color: "#711fff",
                    fontFamily: "Roboto",
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      letterSpacing: "0.15px",
                      lineHeight: "12px",
                    }}
                  >
                    Tag Name
                  </div>
                  <div
                    style={{
                      alignSelf: "stretch",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      gap: "10px",
                      fontSize: "16px",
                      color: "rgba(0, 0, 0, 0.87)",
                    }}
                  >
                    <TextField
                      value={newChip.label}
                      name="new"
                      onChange={(e) =>
                        setNewChip({
                          ...newChip,
                          label: e.target.value,
                        })
                      }

                      id="filled-helperText"
                      variant="filled"
                      placeholder="Tag Name"
                      fullWidth

                    />

                    <div
                      style={{
                        position: "relative",
                        width: "0px",
                        height: "24px",
                      }}
                    />
                    <button
                      onClick={addChip}
                      style={{
                        outline: "none",
                        border: "none",
                        position: "absolute",
                        right: "10%",
                        cursor: "pointer",
                      }}
                    >
                      <div
                        style={{
                          position: "relative",
                          lineHeight: "100%",
                          fontWeight: "600",
                          fontFamily: "'Open Sans'",
                          color: "#4300b8",
                          textAlign: "right",
                        }}
                      >
                        ADD
                      </div>
                    </button>
                    <div
                      style={{
                        position: "relative",
                        width: "24px",
                        height: "0px",
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="chips">
                <ChipsArray setChipData={setChipData} chipData={chipData} setFormData={setFormData} />
              </div>
            </div>
          </div>
          <div className="bottomActionButtons">
            <Link to="/SuperAdmin/NetNetU/Courses">
              <Button
                variant="contained"
                sx={{
                  "&:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.06)",
                  },
                  backgroundColor: "rgba(0, 0, 0, 0.06)",
                  color: "black",
                  textTransform: "capitalize",
                  width: "84px",
                  paddingX: 1,
                  fontFamily: "Open Sans"
                }}
              >
                Go Back
              </Button>
            </Link>
            <Link
            >
              <Button
                onClick={handleContinue}
                variant="contained"
                sx={{
                  backgroundColor: "#711FFF",
                  textTransform: "capitalize",
                  width: "100%",
                  '&:hover': {
                    backgroundColor: "#711FFF",
                    opacity: "0.8",
                    color: "white"
                  }
                }}
              >
                Continue
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </>

  )
}


export default AddNewCourses;
