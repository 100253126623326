import avatar from './avatar.jpg'
import call from './Call.svg'
import Send_email from './Send_email.svg'
import sort from './sort.svg'
import vector from './Vector.svg'
import information_circle from './information_circle.svg'

const Icons = {
    avatar,
    call,
    Send_email,
    sort,
    vector,
    information_circle
}

export default Icons