import React, { useState, useEffect, useCallback, useRef } from "react";
import dayjs from "dayjs";
import { useSelector } from "react-redux";

import {
  Box,
  Typography,
  TableContainer,
  Paper,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Table,
  Popover,
  Button,
  Avatar,
} from '@mui/material';
import {
  CalendarMonthOutlined,
} from '@mui/icons-material';
import ExpandMoreSharpIcon from '@mui/icons-material/ExpandMoreSharp';
import html2pdf from 'html2pdf.js'
import moment from "moment";
import * as XLSX from 'xlsx';
import { ENDPOINT } from "../Opportunity/Redux/oppor";

import { HiMiniUserCircle } from "react-icons/hi2";
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DateRangeCalendar } from '@mui/x-date-pickers-pro/DateRangeCalendar';

import { IoCloseSharp } from "react-icons/io5";
import { IoIosCloseCircle, IoMdArrowDropup, IoMdArrowDropdown } from "react-icons/io";
import { FaAngleDown } from "react-icons/fa6";
import { FaStar } from "react-icons/fa";
import BarCharts from "../../Componenets/Reports/Time/BarChart";
import { PreLoading } from "../Contacts/People/EditContact";


const Team = () => {
  const allTasks = useSelector((state) => state.projects.tasks);
  const clients = useSelector((state) => state.Contacts.companies)
  const member = useSelector((state) => state.userTeam.team.member)


  const [Projects, setProjects] = useState([])
  const access_token = useSelector((state) => state.auth.user.access);

  const apiInitialProject = async () => {
    try {

      const response = await fetch(` ${ENDPOINT}/api/project/list/reports/`, {
        method: 'GET',
        headers: {
          Authorization: `JWT ${access_token}`,
        },
      });
      const data = await response.json();
      if (!response.ok) {
      } else {
        console.error(data, "Data")
        const pro = data.sort((a, b) => {
          // Assuming 'created_at' is a property in your project objects
          const dateA = new Date(a?.created_at);
          const dateB = new Date(b?.created_at);
          // Sort in descending order (newest created first)
          return dateB - dateA;
        });
        setProjects(pro)
      }
    } catch (error) {
      console.error('api error', error)
    }
  };

  useEffect(() => {
    apiInitialProject()
  }, [])

  // this is new popover
  const [clanderButton, setClanderButton] = useState('This Week')

  const buttonNames = [`${clanderButton}`, 'Group By', 'Billable', 'Export As'];

  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverIndex, setPopoverIndex] = useState(null);

  const handleClick = (event, index) => {
    setAnchorEl(event.currentTarget);
    setPopoverIndex(index);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setPopoverIndex(null);
  };

  // this is group by button

  const [groupBy, setGroupBy] = useState(0)
  const [groupName, setGroupName] = useState('Team Members')
  const handleClickGroupBy = (v, i) => {
    setGroupBy(i)
    setGroupName(v)
    handleClose()
  }
  // this is group by billable
  const [billable, setBillable] = useState(false)
  const [billableName, setBillableName] = useState(1)
  const handleClickBillable = (v, i) => {

    if (v === "Billable") {
      setBillable(true)
      setBillableName(0)
    } else if (v === "Non Billable") {
      setBillable(false)
      setBillableName(1)
    } else {
      setBillable(null)
      setBillableName(2)
    }
    handleClose()
  }
    const divRef = useRef()
    const printDocument = ()=>{
        const element = divRef.current
        const opt = {
          margin:1,
          filename: 'download.pdf',
          jspdf: {unit:'in', format:'letter', orientation: 'portrait'},
        }
        html2pdf()
        .from(element)
        .set(opt)
        .save()
    }

  const downloadCSV = () => {
      let data = withBillableValue.length> 0 && withBillableValue.map((task)=>{
        let taskType = task?.task_type ?? ''
        let taskProject = task?.task_project ?? ''
        let taskName = task?.task_name ?? ''
        let taskDescription = task?.description ? task?.description.replace(/<\/?[^>]+(>|$)/g, "") : ''
        let taskWorktype = task?.task_worktype[0]?.name ?? ''
        let taskMember = task?.time_tracking[0]?.created_by?.first_name + task?.time_tracking[0]?.created_by?.last_name ?? ''
        let billable = task?.billable ? 'Yes' : 'No'
        let startDate = new Date(task?.time_tracking[0]?.time_tracking_start).toISOString().split('T')[0] ?? ''
        let startTime = new Date(task?.time_tracking[0]?.time_tracking_start).toISOString().split('T')[1].split('Z')[0].split(':').slice(0, 2).join(':') ?? ''
        let endDate = new Date(task?.time_tracking[0]?.time_tracking_stop).toISOString().split('T')[0] ?? ''
        let endTime = new Date(task?.time_tracking[0]?.time_tracking_stop).toISOString().split('T')[1].split('Z')[0].split(':').slice(0, 2).join(':') ?? ''
        let note =  task.time_tracking[0]?.note ? task.time_tracking[0]?.note.replace(/<\/?[^>]+(>|$)/g, "") : ''
        let time =  task.time_tracking[0]?.tracked_time ?? ''
        let project_name = Array.isArray(Projects) && Projects.length && task?.task_project ? Projects.find((item) => item?.id === task?.task_project)?.title : '';
        let clientsName =  Array.isArray(clients) && clients?.length && task?.task_project ? clients.find((fnd)=>Projects.find(som=>som?.id=== task?.task_project)?.company === fnd?.id )?.name : '';

        function timeToDecimal(time) {
         // Split the time into hours, minutes, and seconds
         const [hours, minutes, seconds] = time.split(':').map(Number);
         
         // Calculate the total hours as a decimal, including minutes and seconds as fractions
         const totalHours = hours + (minutes / 60) + (seconds / 3600);
         
         // Format to two decimal places
         return totalHours.toFixed(2);
       }
       const decimalTime = timeToDecimal(time);
 
         return (
           [taskType+', '+taskProject+', '+project_name+', '+clientsName+', '+taskName+','+taskDescription+', '+note+', '+taskWorktype+', '+taskMember+', '+billable+', '+startDate+', '+startTime+', '+endDate+', '+endTime+', '+time+', '+decimalTime+', '+' '+', ']
         )
       })
      
    const headers = ["Type","Job#","Job Name", "Client Name", "Task Name","Task Description", "Time Entry Notes", "Work Type", "Team Member", "Billable", "Start Date","Start Time","End Date", "End Time", "Total Time (H)", "Total Time (decimal)","Rate (USD)", "Total"];

    const csvContent = [headers.join(","), ...data.map(e => e.join(","))].join("\n");

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'data.csv';
    link.click();
  };
  const downloadXLS = () => {
    let data = withBillableValue.length> 0 && withBillableValue.map((task)=>{
      let taskType = task?.task_type ?? ''
      let taskProject = task?.task_project ?? ''
      let taskName = task?.task_name ?? ''
      let taskDescription = task?.description ? task?.description.replace(/<\/?[^>]+(>|$)/g, "") : ''
      let taskWorktype = task?.task_worktype[0]?.name ?? ''
      let taskMember = task?.time_tracking[0]?.created_by?.first_name + task?.time_tracking[0]?.created_by?.last_name ?? ''
      let billable = task?.billable ? 'Yes' : 'No'
      let startDate = new Date(task?.time_tracking[0]?.time_tracking_start).toISOString().split('T')[0] ?? ''
      let startTime = new Date(task?.time_tracking[0]?.time_tracking_start).toISOString().split('T')[1].split('Z')[0].split(':').slice(0, 2).join(':') ?? ''
      let endDate = new Date(task?.time_tracking[0]?.time_tracking_stop).toISOString().split('T')[0] ?? ''
      let endTime = new Date(task?.time_tracking[0]?.time_tracking_stop).toISOString().split('T')[1].split('Z')[0].split(':').slice(0, 2).join(':') ?? ''
      let note =  task.time_tracking[0]?.note ? task.time_tracking[0]?.note.replace(/<\/?[^>]+(>|$)/g, "") : ''
      let time =  task.time_tracking[0]?.tracked_time ?? ''
      let project_name = Array.isArray(Projects) && Projects.length && task?.task_project ? Projects.find((item) => item?.id === task?.task_project)?.title : '';
      let clientsName =  Array.isArray(clients) && clients?.length && task?.task_project ? clients.find((fnd)=>Projects.find(som=>som?.id=== task?.task_project)?.company === fnd?.id )?.name : '';

      function timeToDecimal(time) {
       // Split the time into hours, minutes, and seconds
       const [hours, minutes, seconds] = time.split(':').map(Number);
       
       // Calculate the total hours as a decimal, including minutes and seconds as fractions
       const totalHours = hours + (minutes / 60) + (seconds / 3600);
       
       // Format to two decimal places
       return totalHours.toFixed(2);
     }
     const decimalTime = timeToDecimal(time);

       return (
        [taskType,taskProject,project_name,clientsName,taskName,taskDescription,note,taskWorktype,taskMember,billable,startDate,startTime,endDate,endTime,time,decimalTime,'','']
      )
     })
    
     const headers = ["Type","Job#","Job Name", "Client Name", "Task Name","Task Description", "Time Entry Notes", "Work Type", "Team Member", "Billable", "Start Date","Start Time","End Date", "End Time", "Total Time (H)", "Total Time (decimal)","Rate (USD)", "Total"];

     const worksheet = XLSX.utils.aoa_to_sheet([
       headers,
       ...data,
     ]);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sales');

    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });


    const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });

    saveAs(blob, "SalesCSV");
  };


  const [exportAs, setExportAs] = useState(0)
  const handleClickExportAs = (v, i) => {
    if (v === "PDF") {
      printDocument()
    }

    if (v === "CSV") {
      downloadCSV()
    }

    if (v === "XLS") {
      downloadXLS()
    }

    setExportAs(i)
    handleClose()
  }

  // this is clender popop

  const [day, setDay] = useState('This Week')

  const [dateRange, setDateRange] = useState([
    dayjs().startOf('week'),
    dayjs().endOf('week'),
  ]);

  const handleDateRange = (v) => {
    setDateRange(v)
  }

  const handleClickDay = (v, i) => {
    setClanderButton(v)
    setDay(v)
    if (v === 'Today') {
      const today = dayjs();
      setDateRange([today,])
    }
    else if (v === 'Yesterday') {
      const today = dayjs();
      setDateRange([today.add(1, 'day'),])
    }
    else if (v === 'This Week') {
      const today = dayjs();
      setDateRange([today.startOf('week'), today.endOf('week')])
    }
    else if (v === 'Last Week') {
      const today = dayjs();
      const prevWeek = today.subtract(7, 'day');
      setDateRange([prevWeek.startOf('week'), prevWeek.endOf('week')
    ]);

    }
    else if (v === 'This Month') {
      const today = dayjs();
      setDateRange([today.startOf('month'), today.endOf('month')])
    }
    else if (v === 'Last Month') {
      const today = dayjs();
      const startOfNextMonth = today.startOf('month').subtract(1, 'month');
      setDateRange([startOfNextMonth, startOfNextMonth.endOf('month')]);

    }
    else if (v === 'This Year') {
      const today = dayjs();
      setDateRange([today.startOf('year'), today.endOf('year')])

    }
    else if (v === 'Last Year') {
      const today = dayjs();
      setDateRange([today.startOf('year').subtract(1, 'year'), today.endOf('year').subtract(1, 'year')])
    }
  }

  const filteredTasks = allTasks.filter(task => {
    const start = Array.isArray(task.time_tracking) && task.time_tracking.length && task.time_tracking[task.time_tracking.length - 1].time_tracking_start;
    const end = Array.isArray(task.time_tracking) && task.time_tracking.length && task.time_tracking[task.time_tracking.length - 1].time_tracking_stop;

    const startDateTime = new Date(start);
    const endDateTime = new Date(end);

    const timeDifference = endDateTime - startDateTime;

    return timeDifference > 0;
  });


  const modifiedData = [];

  filteredTasks.forEach(parent => {
    parent.time_tracking.forEach(timeTrack => {
      const { time_tracking_start, time_tracking_stop } = timeTrack;
      // Check if the start time is before the stop time
      if (new Date(time_tracking_start) < new Date(time_tracking_stop)) {
        const clonedParent = { ...parent };
        clonedParent.time_tracking = [timeTrack];
        modifiedData.push(clonedParent);
      }
    });
  });
  
  const filteringTask = modifiedData?.filter((item) => {
    let date = item.time_tracking[0].date?? item.time_tracking[0].time_tracking_start
    let d = new Date(date)
    let startDate = new Date(dateRange[0])
    let endDate = new Date(dateRange[1])
    return d>= startDate && d<= endDate
})
  const finalBillable = filteringTask.filter(item => {
    return billable === null || item.billable === billable
  })

  const [billableHours, setBillableHours] = useState(0);
  const [nonBillableHours, setNonBillableHours] = useState(0);

  function TotalHours(timeTracking) {

    return timeTracking.reduce((subTotal, task) => {
      const trackedTimeParts = task.tracked_time.split(':');
      const hours = parseInt(trackedTimeParts[0]);
      const minutes = parseInt(trackedTimeParts[1]);

      subTotal += hours + minutes / 60;

      return subTotal;

    }, 0);
  }

  // this is filter project state

  const [filterProjectPopop, setFilterProjectPopop] = useState(false)
  const [projectValue, setProjectValue] = useState([])

  // this is filter Deliverable state

  const [filterDeliverablePopop, setFilterDeliverablePopop] = useState(false)
  const [deliverableValue, setDeliverableValue] = useState([])
  const [selectedTask, setSelectedTask] = useState([])

  // this is worktype state

  const [filterWorktypePopop, setFilterWorktypePopop] = useState(false)
  const [worktypeValue, setWorktypeValue] = useState([])

  // this is filter people state

  const [filterPeoplePopop, setFilterPeoplePopop] = useState(false)
  const [peopleValue, setPeopleValue] = useState([])

  // this is filter client

  const [filterClientPopop, setFilterClientPopop] = useState(false)

  const [clientValue, setClientValue] = useState([])

  const handleClientListSelect = (v, i) => {

    let arry = clientValue.find((val) => val.name === v.name)

    if (arry) {
      let arry = clientValue.filter((flt) => flt.name !== v.name)
      setClientValue(arry)
    }
    else {
      setClientValue([...clientValue, v])
    }
  }

  let clientmap = projectValue?.length > 0 ? clients?.filter((flt) => projectValue?.some((som) => som.company === flt.id))
    : peopleValue?.length > 0 ? clients?.filter((flt) => Projects?.some((som) => som?.company === flt?.id))?.filter(flt => peopleValue?.some(som => som?.id === flt?.created_by))
      : deliverableValue.length > 0 ? clients?.filter(flt => flt?.company_project?.some(som => deliverableValue?.some(som1 => som1?.deliverable_project === som.id)))
        : worktypeValue.length > 0 ? clients?.filter(flt => flt?.company_project?.some(som => Projects?.filter(flt => finalBillable?.filter(flt => flt?.task_worktype.length > 0 && flt?.task_worktype?.some(som => worktypeValue?.some(som1 => som1 === som.name)))?.some(som => som.task_project === flt.id))?.some(som1 => som1.id === som.id)))
          : clients.filter((flt) => Projects?.some((som) => som?.company === flt.id))

  // this is filter project

  const handleProjectListSelect = (v, i) => {

    let arry = projectValue.find((val) => val.title === v.title)
    setDeliverableValue([])

    if (arry) {
      let arry = projectValue.filter((flt) => flt.title !== v.title)
      setProjectValue(arry)
    }
    else {
      setProjectValue([...projectValue, v])
    }
  }

  let projectmap = clientValue.length > 0 ? Projects.filter((flt) => clientValue.some((som) => som.id === flt.company))
    : deliverableValue.length > 0 ? Projects.filter(flt => deliverableValue.some(som => som.deliverable_project === flt.id))
      : worktypeValue.length > 0 ? Projects.filter(flt => finalBillable.filter(flt => flt.task_worktype.length > 0 && flt.task_worktype.some(som => worktypeValue.some(som1 => som1 === som.name))).some(som => som.task_project === flt.id))
        : peopleValue.length > 0 ? Projects.filter(flt => peopleValue.some(som => som.id === flt.created_by))
          : Projects

  // this is Deliverable People

  const handleDeliverableListSelect = (v, i) => {

    let arry = deliverableValue.find((val) => val.id === v.id)

    if (arry) {
      let arry = deliverableValue.filter((flt) => flt.id !== v.id)
      setDeliverableValue(arry)
    }
    else {
      setDeliverableValue([...deliverableValue, v])
    }

  }

  const handleDeliverableTaskSelect = (v) => {

    let arry = selectedTask.some((som) => som.id === v.id)

    if (arry) {
      setSelectedTask(selectedTask.filter((flt) => flt.id !== v.id))
    }
    else {
      setSelectedTask(selectedTask.concat(v))
    }
  }

  let Deliverable = projectValue.length > 0 ? projectValue?.flatMap(obj => obj?.deliverable_project)
    : clientValue.length > 0 ? clientValue?.flatMap(map => map?.company_project).flatMap(map1 => map1?.deliverable_project)
      : peopleValue?.length > 0 ? Projects?.flatMap(obj => obj.deliverable_project).filter(flt => peopleValue?.some(som => som.id === flt.created_by))
        : worktypeValue?.length > 0 ? Projects?.filter(flt => finalBillable.filter(flt => flt?.task_worktype.length > 0 && flt.task_worktype.some(som => worktypeValue.some(som1 => som1 === som.name))).some(som => som.task_project === flt.id)).flatMap(map => map.deliverable_project)
          : Projects?.flatMap(obj => obj.deliverable_project)

  // ????????????????????????????????????????????????????????????????????????????????????????????????????????????????????

  const [accordian, setAccordian] = useState('')

  const handleAccordianOpen = (v, i) => {
    setAccordian(v.deliverable_name)
    if (accordian === v.deliverable_name) {
      setAccordian('')
    }
  }
  // this is Worktype People

  const handleWorktypeListSelect = (v, i) => {

    let WTvalue = worktypeValue.find((val) => val === v)

    if (WTvalue) {
      setWorktypeValue(worktypeValue.filter((flt1, ind1) => flt1 !== v))
    }
    else {
      setWorktypeValue([...worktypeValue, v])
    }
  }

  let finalFilterTask =
    deliverableValue.length > 0 ?
      selectedTask.length > 0 ? selectedTask :
        finalBillable.filter((flt) => deliverableValue.some((som) => som.id === flt.task_deliverable))

      : projectValue.length > 0 ? finalBillable.filter((fli) => projectValue.some((som) => som.id === fli.task_project))
        : clientValue.length > 0 ? finalBillable.filter(flt => Projects.filter(fltp => clientValue.some(som => som.id === fltp.company)).some(som1 => som1.id === flt.task_project))
          : peopleValue.length > 0 ? finalBillable.filter(flt => peopleValue.some(som => flt?.assignee_task[0]?.assignee_user === som?.id))
            : worktypeValue.length > 0 ? finalBillable.filter((flt => flt.task_worktype.length > 0 && worktypeValue.some(som => som === flt.task_worktype[0].name)))
              : finalBillable


  let withBillableValue = worktypeValue.length > 0 ? finalFilterTask.filter((flt => flt.task_worktype.length > 0 && worktypeValue.some(som => som === flt.task_worktype[0].name)))
    : finalFilterTask

withBillableValue.sort((a, b) => {
  if (a.date === "No Time Tracked") return 1;
  if (b.date === "No Time Tracked") return -1;
  return new Date(a.date) - new Date(b.date);
});

  const combineWorktype = []
  if (projectValue.length > 0 || clientValue.length > 0 || peopleValue.length > 0 || deliverableValue.length > 0) {
    Array.isArray(finalFilterTask) && finalFilterTask.map((val, ind) => {
      Array.isArray(val.task_worktype) && val.task_worktype.map((v, i) => {
        let find = combineWorktype.find((val) => val === v.name)
        if (find) {
        }
        else {
          combineWorktype.push(v.name)
        }
      })
    })
  }
  else {
    Array.isArray(finalBillable) && finalBillable.map((val, ind) => {
      Array.isArray(val.task_worktype) && val.task_worktype.map((v, i) => {
        let find = combineWorktype.find((val) => val === v.name)
        if (find) {
        }
        else {
          combineWorktype.push(v.name)
        }
      })
    })

  }


  const handlePeopleListSelect = (v, i) => {

    let arry = peopleValue.find((val) => val.first_name === v.first_name)

    if (arry) {
      let arry = peopleValue.filter((flt) => flt.first_name !== v.first_name)
      setPeopleValue(arry)
    }
    else {
      setPeopleValue([...peopleValue, v])
    }
  }

  const UserTeam = peopleValue?.length ?
    peopleValue.map(item => {
      const tasksWithAssignee = withBillableValue.reduce((acc, ele) => {
        // Extract all unique created_by user IDs from the time_tracking array
        const timeTrackingUsers = new Set(ele.time_tracking.map(track => track.created_by.id));
        // Check if the current item ID is one of the created_by users
        if (timeTrackingUsers.has(item.id)) {
          acc.push(ele);
        }

        return acc;
      }, []);

      return {
        ...item,
        tasks: tasksWithAssignee
      };
    })
    :
    Array.isArray(member) && member.map(item => {
      const tasksWithAssignee = withBillableValue.reduce((acc, ele) => {
        // Extract all unique created_by user IDs from the time_tracking array
        const timeTrackingUsers = new Set(ele.time_tracking.map(track => track.created_by.id));
        // Check if the current item ID is one of the created_by users
        if (timeTrackingUsers.has(item.id)) {
          acc.push(ele);
        }

        return acc;
      }, []);

      return {
        ...item,
        tasks: tasksWithAssignee
      };
    });  
  
  useEffect(() => {

    const filterBillable = withBillableValue.filter(item => item.billable === true)
    const NonfilterBillable = withBillableValue.filter(item => item.billable === false)

    let totalBillableHours = 0;
    let NonBillableHours = 0

    filterBillable.forEach((item) => {
      const totalHours = TotalHours(item.time_tracking);
      totalBillableHours += totalHours;
    });
    const BtotalWH = Math.floor(totalBillableHours);
    const BtotalWM = Math.round((totalBillableHours - BtotalWH) * 60);
    const BformattedTotalTime = `${BtotalWH.toString().padStart(2, '0')}:${BtotalWM.toString().padStart(2, '0')}`;
    setBillableHours(BformattedTotalTime);


    NonfilterBillable.forEach((item) => {
      const totalHours = TotalHours(item.time_tracking);
      NonBillableHours += totalHours;

    });
    const totalWH = Math.floor(NonBillableHours);
    const totalWM = Math.round((NonBillableHours - totalWH) * 60);
    const formattedTotalTime = `${totalWH.toString().padStart(2, '0')}:${totalWM.toString().padStart(2, '0')}`;
    setNonBillableHours(formattedTotalTime);


  }, [worktypeValue, projectValue, clientValue, peopleValue, deliverableValue, billable, anchorEl]);

  const groupWorktype = []

  let groupWorktypeTrackingFilter = withBillableValue.flatMap(map => map.time_tracking).filter(flt => flt.tracked_time.slice(0, 5) !== '00:00')
  let groupWorktypeTrackingFilter1 = withBillableValue.filter(flt => groupWorktypeTrackingFilter.some(som => som.task_id.task_name === flt.task_name))

  Array.isArray(groupWorktypeTrackingFilter1) && groupWorktypeTrackingFilter1.map((val, ind) => {
    if (val.time_tracking.length > 0) {

      Array.isArray(val.task_worktype) && val.task_worktype.map((v, i) => {
        let find = groupWorktype.find((val) => val === v.name)
        if (find) {
        }
        else {
          groupWorktype.push(v.name)
        }
      })
    }
  })

  const groupSelectedTask = []

  Array.isArray(withBillableValue) && withBillableValue.map((val, ind) => {
    Array.isArray(val.task_worktype) && val.task_worktype.map((v, i) => {
      let find = groupSelectedTask.find((val) => val === v.name)
      if (find) {
      }
      else {
        groupSelectedTask.push(v.name)
      }
    })
  })

  let arryworktype = selectedTask.length > 0 ? groupSelectedTask : groupWorktype

  const UserTeamPopOp = Array.isArray(member) && member?.map(item => {
    const tasksWithAssignee = withBillableValue?.reduce((acc, ele) => {
      const timeTrackingUsers = new Set(ele?.timeTracking?.map(track => track?.created_by));
      if (timeTrackingUsers.has(item?.id)) {
        acc.push({
          ...ele,
          assignees: Array.from(timeTrackingUsers)
        });
      }
      return acc;
    }, []);

    return {
      ...item,
      tasks: tasksWithAssignee
    };
  });

  const Team = UserTeam?.filter((item) => item?.first_name !== "First Name")

  let combineArray = []

  withBillableValue.filter((v, i) => {

    let find = combineArray.length > 0 && combineArray.find((fnd) => fnd.id === v.id)
    if (find) {
    }
    else {
      combineArray.push(v)
    }

  })

  const handleClientPopopToggle = useCallback(() => {
    setFilterClientPopop(!filterClientPopop);
    setFilterPeoplePopop(false); // Close the People popover when Worktype is opened
    setFilterWorktypePopop(false);
    setFilterDeliverablePopop(false);
    setFilterProjectPopop(false)
  }, [filterClientPopop, setFilterClientPopop, setFilterPeoplePopop]);

  const handlePeoplePopopToggle = useCallback(() => {
    setFilterPeoplePopop(!filterPeoplePopop);
    setFilterWorktypePopop(false); // Close the People popover when Worktype is opened
    setFilterClientPopop(false);
    setFilterDeliverablePopop(false);
    setFilterProjectPopop(false)
  }, [filterPeoplePopop, setFilterPeoplePopop, setFilterPeoplePopop]);

  const handleDeliverablePopopToggle = useCallback(() => {
    setFilterDeliverablePopop(!filterDeliverablePopop);
    setFilterPeoplePopop(false); // Close the People popover when Worktype is opened
    setFilterClientPopop(false);
    setFilterWorktypePopop(false);
    setFilterProjectPopop(false)
  }, [filterDeliverablePopop, setFilterDeliverablePopop, setFilterPeoplePopop]);

  const handleProjectPopopToggle = useCallback(() => {
    setFilterProjectPopop(!filterProjectPopop);
    setFilterPeoplePopop(false); // Close the People popover when Worktype is opened
    setFilterClientPopop(false);
    setFilterDeliverablePopop(false);
    setFilterWorktypePopop(false)
  }, [filterProjectPopop, setFilterProjectPopop, setFilterPeoplePopop]);

  const handleWorktypePopopToggle = useCallback(() => {
    setFilterWorktypePopop(!filterWorktypePopop);
    setFilterPeoplePopop(false); // Close the People popover when Worktype is opened
    setFilterClientPopop(false);
    setFilterDeliverablePopop(false);
    setFilterProjectPopop(false)
  }, [filterWorktypePopop, setFilterWorktypePopop, setFilterPeoplePopop]);

  const clientRef = useRef()
  const projectRef = useRef()
  const deliverableRef = useRef()
  const worktypeRef = useRef()
  const peopleRef = useRef()

  useEffect(() => {
    // Function to handle clicks outside of the popovers
    function handleClickOutside(event) {
      if (clientRef.current && !clientRef.current.contains(event.target)) {
        setFilterClientPopop(false);
      }
      if (projectRef.current && !projectRef.current.contains(event.target)) {
        setFilterProjectPopop(false);
      }
      if (deliverableRef.current && !deliverableRef.current.contains(event.target)) {
        setFilterDeliverablePopop(false);
      }
      if (worktypeRef.current && !worktypeRef.current.contains(event.target)) {
        setFilterWorktypePopop(false);
      }
      if (peopleRef.current && !peopleRef.current.contains(event.target)) {
        setFilterPeoplePopop(false);
      }
    }
    // Add event listener when component mounts
    document.addEventListener("mousedown", handleClickOutside);

    // Remove event listener when component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const totalHoursAndMinutes = (data) => {
    let totalTimeTracking = [];
    for (let i = 0; i < data.length; i++) {
      const timeTracking = data[i].time_tracking;
      totalTimeTracking.push(data[i].time_tracking[0]);
    }

    let totalHours = 0;
    let totalMinutes = 0;

    totalTimeTracking?.forEach((entry) => {
      if (entry.tracked_time) {
        const [hours, minutes, seconds] = entry.tracked_time
          .split(":")
          .map(Number);
        totalHours += hours;
        totalMinutes += minutes;
      }
    });

    totalHours += Math.floor(totalMinutes / 60);
    totalMinutes = totalMinutes % 60;

    return { totalHours, totalMinutes };
  };

  return (
    Array.isArray(allTasks) && allTasks?.length > 0 ?
      <>
        < Box sx={{ borderBottom: '1px solid #D9D9D9', backgroundColor: "#F8F9FA", p: 2, mb: 2 }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: '1.5rem',
              width: '95%',
              backgroundColor: '#F1F3F5',
              padding: '1rem 1rem',
              margin: '0 auto',
              borderRadius: '8px',
            }}
          >
  
            <div>
              {buttonNames.map((name, index) => (
                <>
                  <Button
                    variant="contained"
                    aria-describedby={index}
                    onClick={(event) => handleClick(event, index)}
                    startIcon={index === 0 && <CalendarMonthOutlined />}
                    endIcon={<ExpandMoreSharpIcon style={{ width: '18px', height: '18px', fontWeight: 200, color: '#03071E' }} />}
                    sx={{
                      mr: 2, backgroundColor: '#E0E0DF', color: 'black', textTransform: 'inherit', boxShadow: 'inherit', fontWeight: 400, fontSize: '14px',
                      '&:hover': { backgroundColor: '#E0E0DF', color: 'black', boxShadow: 'inherit' }
                    }}
                  >
               
                    {
                      index === 1 ?
                        name + ' ' + (groupName)
                        :
                        name
                    }
                  </Button>
                  <Popover
                    id={index}
                    open={popoverIndex === index}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    sx={{
                      '& .MuiPaper-root': {
                        boxShadow: 'inherit', border: '1px solid #E0E0DF', borderRadius: 2
                      }
                    }}
                  >
                    {
                      index === 0 &&
                      <>
                        <Box align='center' sx={{ width: 'auto', height: 'auto', px: '30px', display: { xs: 'block', md: 'flex' } }}>
                          <Box align='left' sx={{ backgroundColor: '#F8F9FA', width: { xs: '100%', md: '200px' }, p: 1, pb: 3, my: 4, borderRadius: 2 }}>
                            {
                              ['Today', 'Yesterday', 'This Week', 'Last Week', 'This Month', 'Last Month', 'This Year', 'Last Year'].map((v, i) => {
                                return (
                                  <Typography key={i} onClick={() => handleClickDay(v, i)} sx={day === v ?
                                    { fontSize: '16px', fontWeight: 400, cursor: 'pointer', color: '#711FFF', my: 3, ml: 2 }
                                    :
                                    { fontSize: '16px', fontWeight: 400, cursor: 'pointer', color: '#03071E', my: 3, ml: 2 }
                                  }>{v}</Typography>
                                )
                              })
                            }
  
                          </Box>
                          <Box sx={{ mx: 4, my: 4 }}>
  
                            <LocalizationProvider dateAdapter={AdapterDayjs} >
                              <DateRangeCalendar
                                value={dateRange}
                                onChange={(newValue) => handleDateRange(newValue)}
                                sx={{
                                  '& .css-10wpov9-MuiTypography-root ': {
                                    fontWeight: '700 !important'
                                  },
                                  '& .css-cyzddl-MuiPickersSlideTransition-root-MuiDayCalendar-slideTransition:not(.MuiDateRangeCalendar-dayDragging) .MuiDateRangePickerDay-dayOutsideRangeInterval': {
                                    color: '#03071E !important',
                                    fontSize: '16px !important',
                                    fontWeight: 500,
                                  },
                                  '& .css-1gbl7yn-MuiDateRangePickerDay-root': {
                                    backgroundColor: '#310085 !important',
                                    color: 'white !important',
                                    borderRadius: '0px !important'
                                  },
                                  '& .css-1e841vg-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day.Mui-selected': {
                                    backgroundColor: '#711FFF !important',
                                    color: "white !important",
                                    borderRadius: '0px !important',
                                    fontSize: '16px !important',
                                    fontWeight: 500
                                  },
                                  '& .css-1ckjanr-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day.Mui-selected': {
                                    backgroundColor: '#711FFF !important',
                                    color: "white !important",
                                    borderRadius: '0px !important',
                                    fontSize: '16px !important',
                                    fontWeight: 500
                                  },
                                  '& .css-1a4q4r2-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day.Mui-selected': {
                                    backgroundColor: '#711FFF !important',
                                    color: "white !important",
                                    borderRadius: '0px !important',
                                    fontSize: '16px !important',
                                    fontWeight: 500
                                  },
                                  '& .css-2ko3hu-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day': {
                                    backgroundColor: '#310085 !important',
                                    color: "white !important",
                                    opacity: 'inherit !important',
                                    borderRadius: '0px',
                                    border: 'none !important',
                                    fontSize: '16px !important',
                                    fontWeight: 500
                                  },
                                  '& .css-1ku4sqv-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day': {
                                    backgroundColor: '#711FFF !important',
                                    color: "white !important",
                                    borderRadius: '0px !important',
                                    fontSize: '16px !important',
                                    fontWeight: 500
                                  },
                                  '& .css-ahwqre-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day:not(.Mui-selected)': {
                                    backgroundColor: '#310085 !important',
                                    color: "#e569db !important",
                                    borderRadius: '0px !important',
                                    fontSize: '16px !important',
                                    fontWeight: 500
                                  },
                                  '& .css-jef1b6-MuiDateRangeCalendar-container:not(:last-of-type)': {
                                    borderRight: 'none !important'
                                  },
                                  '& .css-3wduhr-Mu.iDateRangeCalendar-root': {
                                    flexDirection: 'column !important',
                                  },
  
  
                                  '& .css-grqin-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day': {
                                    color: 'inherit',
                                    fontSize: '16px !important',
                                    opacity: 'inherit',
                                    fontWeight: 500
                                  },
                                  '& .css-1kex3oi-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day:not(.Mui-selected)': {
                                    backgroundColor: 'inherit',
                                    color: "black !important",
                                    borderRadius: '50%',
                                    border: "1px solid gray",
                                    fontSize: '16px',
                                    fontWeight: 500
                                  },
                                  '& .MuiTypography-subtitle1': {
                                    color: " #03071E !important",
                                    fontSize: '16px',
                                    fontWeight: 700
                                  },
                                  '& .MuiDayCalendar-weekDayLabel': {
                                    color: " #03071ECC !important",
                                    fontSize: '16px',
                                    fontWeight: 400
                                  },
                                  '& .MuiSvgIcon-fontSizeInherit': {
                                    backgroundColor: 'black',
                                    color: 'white',
                                    borderRadius: '50%'
                                  },
                                  '& .MuiPickersDay-today': {
                                    backgroundColor: '#711FFF',
                                    color: 'white',
                                    borderRadius: '0px',
                                    fontSize: '16px',
                                    fontWeight: 500
  
                                  },
                                  '& .css-grqin-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day.Mui-selected': {
                                    backgroundColor: '#711FFF',
                                    color: 'white',
                                    borderRadius: '0px',
                                    fontSize: '16px',
                                    fontWeight: 500
                                  },
                                  '& .css-gtjfra-MuiDateRangePickerDay-root': {
                                    backgroundColor: '#310085',
                                    color: 'white !important',
                                    borderRadius: '0px',
                                    fontSize: '16px',
                                    fontWeight: 500
                                  },
                                  '& .css-1i2r8k1-MuiDateRangePickerDay-root': {
                                    backgroundColor: '#310085',
                                    color: 'white !important',
                                    borderRadius: '0px',
                                    fontSize: '16px',
                                    fontWeight: 500
                                  },
                                  '& .MuiDateRangePickerDay-notSelectedDate': {
                                  },
                                }}
  
                              />
                            </LocalizationProvider>
  
                            <Box sx={{ display: 'flex', my: 4 }}>
                              <Box sx={{ display: 'flex', mr: 4 }}>
                                <Box sx={{ width: "20px", height: "20px", backgroundColor: "#711FFF", borderRadius: 2, mr: 2 }}></Box>
                                <Typography sx={{ fontWeight: 400, fontSize: '14px' }}>Start Date and End Date</Typography>
                              </Box>
                              <Box sx={{ display: 'flex' }}>
                                <Box sx={{ width: "20px", height: "20px", backgroundColor: "#310085", borderRadius: 2, mr: 2 }}></Box>
                                <Typography sx={{ fontWeight: 400, fontSize: '14px' }}>Date Range</Typography>
                              </Box>
                            </Box>
  
                          </Box>
                          <Box align='' my={3} sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "" }} >
                            <Box align='left' mt={4}>
                              <Box align='left'>
                                <Typography sx={{ color: '#03071E99', fontSize: '16px', fontWeight: 400 }}>Start Date</Typography>
                                <Typography sx={{ color: '#03071E', fontSize: '16px', fontWeight: 400 }}>{dateRange[0] && dateRange[0].format().slice(0, 10)}</Typography>
                              </Box>
                              <Box sx={{ mt: 2 }}>
                                <Typography sx={{ color: '#03071E99', fontSize: '16px', fontWeight: 400 }}>End Date</Typography>
                                <Typography sx={{ color: '#03071E', fontSize: '16px', fontWeight: 400 }}>{dateRange[1] && dateRange[1].format().slice(0, 10)}</Typography>
                              </Box>
                            </Box>
  
  
                            <Box align='right'>
                              <Button variant='text' sx={{ textDecoration: 'inherit', color: 'black', width: { xs: 'auto', lg: '100px' }, mr: 1 }} onClick={handleClose}>Cancel</Button>
                              <Button variant='contained' onClick={handleClose} sx={{
                                textDecoration: 'inherit', backgroundColor: "#711FFF", width: { xs: 'auto', lg: '100px' }, mr: 1,
                                '&:hover': {
                                  backgroundColor: "#711FFF"
                                }
                              }}>Save</Button>
                            </Box>
  
                          </Box>
  
                        </Box>
                      </>
                    }
                    {
                      index === 1 &&
                      <Box sx={{ pb: 2, width: '150px', textAlign: 'left' }}>
                        {
                          ['Team Members', 'Work Type'].map((v, i) => {
                            return (
                              <Typography key={i} fullWidth onClick={() => handleClickGroupBy(v, i)} sx={groupBy === i ?
                                { color: "white", backgroundColor: '#711FFF', borderRadius: 1, textTransform: 'inherit', fontSize: '14px', m: 2, px: 1, '&:hover': { backgroundColor: '#E0E0DF', cursor: 'pointer' } }
                                :
                                { color: "black", borderRadius: 1, textTransform: 'inherit', fontSize: '14px', m: 2, px: 1, '&:hover': { backgroundColor: '#E0E0DF', cursor: 'pointer' } }}>{v}</Typography>
                            )
                          })
                        }
                      </Box>
                    }
                    {
                      index === 2 &&
                      <Box sx={{ pb: 2, width: '150px', textAlign: 'left' }}>
                        {
                          ['Billable', 'Non Billable', 'Both'].map((v, i) => {
                            return (
                              <Typography key={i} fullWidth onClick={() => handleClickBillable(v, i)} sx={billableName === i ?
                                { color: "white", backgroundColor: '#711FFF', borderRadius: 1, textTransform: 'inherit', fontSize: '14px', m: 2, px: 1, '&:hover': { backgroundColor: '#E0E0DF' } }
                                :
                                { color: "black", borderRadius: 1, textTransform: 'inherit', fontSize: '14px', m: 2, px: 1, '&:hover': { backgroundColor: '#E0E0DF' } }}>{v}</Typography>
                            )
                          })
                        }
                      </Box>
                    }
                    {
                      index === 3 &&
                      <Box sx={{ pb: 2, width: '150px', textAlign: 'left' }}>
                        {
                          ['PDF', 'XLS', 'CSV'].map((v, i) => {
                            return (
                              <Typography key={i} fullWidth onClick={() => handleClickExportAs(v, i)} sx={exportAs === i ?
                                { color: "white", backgroundColor: '#711FFF', borderRadius: 1, textTransform: 'inherit', fontSize: '14px', m: 2, px: 1, '&:hover': { backgroundColor: '#E0E0DF' } }
                                :
                                { color: "black", borderRadius: 1, textTransform: 'inherit', fontSize: '14px', m: 2, px: 1, '&:hover': { backgroundColor: '#E0E0DF' } }}>{v}</Typography>
                            )
                          })
                        }
                      </Box>
                    }
                  </Popover>
                </>
              ))}
            </div>  
          </Box>
          <Box sx={{ mx: 3, mb: 1, display: 'flex', flexWrap: 'wrap' }}>
  
            {/* this is client Filter */}
            <Box sx={{
              width: '230px', mt: 2, mx: 1, borderBottom: clientValue.length > 0 ? '2px solid #711FFF' : '2px solid lightgray', position: 'relative',
              '&:hover': { borderBottom: '2px solid #711FFF' },
  
            }}
              ref={clientRef}
            >
              <Typography sx={{ color: clientValue.length > 0 ? '#711FFF' : '#00000099', fontWeight: '400' }}>Filter By Client</Typography>
  
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box className='customScrollBar' sx={{ display: 'flex', overflow: 'auto', width: '180px', height: '35px' }}
  
                >
                  {
                    clientValue.length > 0 ?
                      clientValue.map((v, i) => {
                        return (
                          <Box sx={{ backgroundColor: '#D2B8FF', borderRadius: '20px', px: 0.5, mb: 0.5, display: 'flex', alignItems: 'center', mr: 0.5, zIndex: 999 }}>
                            {false ?
                              <img src='https://www.google.com/url?sa=i&url=https%3A%2F%2Fstock.adobe.com%2Fsearch%2Fimages%3Fk%3Dadmin%2Bicon&psig=AOvVaw0nbHsIp7Zj0mSMlKD_DR2M&ust=1701256844633000&source=images&cd=vfe&ved=0CBIQjRxqFwoTCNiX0eLJ5oIDFQAAAAAdAAAAABAE' alt='im' style={{ marginRight: '5px' }} />
                              :
                              <HiMiniUserCircle style={{ fontSize: '24px' }} />
                            }
                            <Typography sx={{ width: '60px', color: 'black', overflow: 'hidden', whiteSpace: 'nowrap' }}>{v.name}</Typography>
                            <IoIosCloseCircle onClick={() => {
                              setClientValue(clientValue.filter((flt) => flt.name !== v.name))
  
                            }}
                              style={{ marginLeft: '5px', color: '#711FFF', cursor: 'pointer', fontSize: '15px', zIndex: 999 }} />
                          </Box>
                        )
                      })
                      :
                      <Typography sx={{ fontSize: '16px', fontWeight: '400', width: '100%', color: '#000000DE', mt: 1 }} onClick={handleClientPopopToggle}>Select</Typography>
                  }
                </Box>
  
                <Box>
                  {
                    clientValue.length > 0 &&
                    <IoCloseSharp style={{ marginRight: '10px', cursor: 'pointer', color: '#0000008F' }} onClick={() => {
                      setClientValue([])
  
                    }} />
                  }
                  {
                    clientValue.length > 0 ?
                      <IoMdArrowDropup style={{ cursor: 'pointer', fontSize: '24px', color: '#0000008F' }} onClick={handleClientPopopToggle} />
                      :
                      <IoMdArrowDropdown style={{ cursor: 'pointer', fontSize: '24px', color: '#0000008F' }} onClick={handleClientPopopToggle} />
                  }
                </Box>
              </Box>
  
              <Box className='customScrollBar' sx={
                !filterClientPopop ?
                  { display: 'none' }
                  :
                  {
                    position: 'absolute', p: 2, backgroundColor: 'white', zIndex: 10, mt: 0.5, width: '350px', height: '310px', overflow: 'auto',
                    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px'
                  }}>
                {
                  Array.isArray(clientmap) && clientmap.map((v, i) => {
  
                    let flt = clientValue.find((val) => val.name === v.name)
  
                    return (
                      <Box key={i} onClick={() => handleClientListSelect(v, i)} sx={{
                        display: 'flex', width: '98%', height: '65px', alignItems: 'center', borderRadius: 2, px: 3, py: 1, my: 1.5, cursor: 'pointer',
                        boxShadow: '2px 2px 6px 0px #D9D9D9', backgroundColor: flt ? '#9152FF' : 'inherit', color: 'white'
                      }}>
                        <HiMiniUserCircle style={{ fontSize: '34px', marginRight: '10px', width: '42px', height: '42px', color: 'lightgray' }} />
                        <Box sx={{ textTransform: 'uppercase' }}>
                          <Typography sx={{ color: flt ? 'white' : '#023FE3', fontSize: '12px', fontWeight: 600 }} >{v.name}</Typography>
                          <Typography sx={{ color: flt ? 'white' : '#8C8E8E', fontSize: '12px' }}>{v.city} {v.country}</Typography>
                        </Box>
                      </Box>
                    )
                  })
                }
              </Box>
            </Box>
  
            {/* this is Project Filter */}
            <Box sx={{
              width: '230px', mt: 2, mx: 1, borderBottom: projectValue.length > 0 ? '2px solid #711FFF' : '2px solid lightgray', position: 'relative',
              '&:hover': { borderBottom: '2px solid #711FFF' },
            }}
              ref={projectRef}
            >
              <Typography sx={{ color: projectValue.length > 0 ? '#711FFF' : '#00000099', fontWeight: '400' }}>Filter By Job</Typography>
  
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box className='customScrollBar' sx={{ display: 'flex', overflow: 'auto', width: '180px', height: '35px' }}>
                  {
                    projectValue.length > 0 ?
                      projectValue.map((v, i) => {
                        return (
                          <Box sx={{ backgroundColor: '#2196F3', borderRadius: '20px', px: 0.5, mb: 0.5, display: 'flex', alignItems: 'center', mr: 0.5 }}>
                            <Typography sx={{ marginLeft: '5px', color: 'white', width: '60px', overflow: 'hidden', whiteSpace: 'nowrap' }}>{v.title}</Typography>
                            <IoIosCloseCircle onClick={() => {
                              setProjectValue(projectValue.filter((flt) => flt.title !== v.title))
  
                            }}
                              style={{ marginLeft: '5px', color: '#86c4f7', cursor: 'pointer', fontSize: '15px' }} />
                          </Box>
                        )
                      })
                      :
                      <Typography sx={{ fontSize: '16px', fontWeight: '400', width: '100%', color: '#000000DE', mt: 1 }} onClick={handleProjectPopopToggle}>Select</Typography>
                  }
                </Box>
  
                <Box>
                  {
                    projectValue.length > 0 &&
                    <IoCloseSharp style={{ marginRight: '10px', cursor: 'pointer', color: '#0000008F' }} onClick={() => {
                      setProjectValue([])
  
                    }} />
                  }
                  {
                    projectValue.length > 0 ?
                      <IoMdArrowDropup style={{ cursor: 'pointer', fontSize: '24px', color: '#0000008F' }} onClick={handleProjectPopopToggle} />
                      :
                      <IoMdArrowDropdown style={{ cursor: 'pointer', fontSize: '24px', color: '#0000008F' }} onClick={handleProjectPopopToggle} />
                  }                    </Box>
              </Box>
  
              <Box className='customScrollBar' sx={
                !filterProjectPopop ?
                  { display: 'none' }
                  :
                  {
                    position: 'absolute', p: 2, borderRadius: '6px', backgroundColor: 'white', zIndex: 10, mt: 0.5, width: '250px', height: '400px', overflow: 'auto',
                    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px',
                    border: '1px solid var(--black-black-20, rgba(3, 7, 30, 0.20))'
                  }}>
                <Typography align='center' sx={{ backgroundColor: '#F8F9FA', borderRadius: '6px', fontSize: '14px', py: 1.5 }}> <span style={{ color: '#5600EB', fontSize: '14px' }}>Project</span> | Inactive</Typography>
                { 
                  Array.isArray(projectmap) && projectmap?.map((v, i) => {
                    let flt = projectValue.find((val) => val.title === v.title)
                    let company = clients.filter((val) => val.id === v.company)
  
                    return (
                      <Box key={i} onClick={() => handleProjectListSelect(v, i)} sx={{
                        display: 'flex', width: '100%', height: '65px', alignItems: 'center', borderRadius: 2, py: 1, my: 1.5, mt: 1, cursor: 'pointer',
                      }}>
                        <Box sx={{ textTransform: 'uppercase' }}>
                          <Typography sx={{ color: flt ? '#5600EB' : '#03071E', fontSize: '14px', fontWeight: 600 }} >{v?.title?.slice(0, 21)}{v?.title?.length > 20 ? '...' : ''}</Typography>
                          {
                            v?.company &&
  
                            <Box sx={{ display: 'flex', alignItems: 'center', ml: 1, }}>
                              {
                                <svg style={{ marginRight: '10px' }} width="9" height="7" viewBox="0 0 9 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path id="Vector 4" d="M1 0V6H9" stroke="black" />
                                </svg>
                              }
                              <Typography sx={{ color: flt ? '#5600EB' : '#0B88F9', fontSize: '12px', '&:hover': { color: '#5600EB' } }}>{company.length > 0 && company[0].name}</Typography>
                            </Box>
                          }
                        </Box>
                      </Box>
                    )
                  })
                }
              </Box>
            </Box>
  
            {/* this is deliverable/task Filter */}
            <Box sx={{
              width: '230px', mt: 2, mx: 1, borderBottom: deliverableValue.length > 0 ? '2px solid #711FFF' : '2px solid lightgray', position: 'relative',
              '&:hover': { borderBottom: '2px solid #711FFF' },
            }}
              ref={deliverableRef}
            >
              <Typography sx={{ color: deliverableValue.length > 0 ? '#711FFF' : '#00000099', fontWeight: '400' }}>Filter By Deliverable/Task</Typography>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box className='customScrollBar' sx={{ display: 'flex', overflow: 'auto', width: '180px', height: '35px' }}>
                  {
                    deliverableValue.length > 0 ?
                      deliverableValue.map((v, i) => {
                        return (
                          <Box sx={{ backgroundColor: '#00000014', borderRadius: '20px', px: 0.5, mb: 0.5, display: 'flex', alignItems: 'center', mr: 0.5 }}>
                            <Typography sx={{ marginLeft: '5px', width: '60px', color: 'black', overflow: 'hidden', whiteSpace: 'nowrap' }}>{v.deliverable_name}</Typography>
                            <IoIosCloseCircle onClick={() => {
                              setDeliverableValue(deliverableValue.filter((flt) => flt.id !== v.id))
                            }}
                              style={{ marginLeft: '5px', color: '#c2c3c4', cursor: 'pointer', fontSize: '15px' }} />
                          </Box>
                        )
                      })
                      :
                      <Typography sx={{ fontSize: '16px', fontWeight: '400', width: '100%', color: '#000000DE', mt: 1 }} onClick={handleDeliverablePopopToggle}>Select</Typography>
                  }
                </Box>
  
                <Box>
                  {
                    deliverableValue.length > 0 &&
                    <IoCloseSharp style={{ marginRight: '10px', cursor: 'pointer', color: '#0000008F' }} onClick={() => {
                      setDeliverableValue([])
                    }} />
                  }
                  {
                    deliverableValue.length > 0 ?
                      <IoMdArrowDropup style={{ cursor: 'pointer', fontSize: '24px', color: '#0000008F' }} onClick={handleDeliverablePopopToggle} />
                      :
                      <IoMdArrowDropdown style={{ cursor: 'pointer', fontSize: '24px', color: '#0000008F' }} onClick={handleDeliverablePopopToggle} />
                  }                    </Box>
              </Box>
  
              <Box className='customScrollBar' sx={
                !filterDeliverablePopop ?
                  { display: 'none' }
                  :
                  {
                    position: 'absolute', py: 2, backgroundColor: 'white', zIndex: 10, mt: 0.5, borderRadius: '6px', width: '250px', height: '400px', overflow: 'auto',
                    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px',
                    border: '1px solid var(--black-black-20, rgba(3, 7, 30, 0.20))', left: { xs: '-60px', md: '0px' }
                  }}>
  
                <Typography sx={{ textTransform: 'uppercase', fontSize: '10px', color: '#A3A9AB', mx: 1 }}>Deliverable</Typography>
                {
                  Array.isArray(Deliverable) && Deliverable.map((v, i) => {
                    let some = deliverableValue.some((som) => som.id === v.id)
                    return (
                      <Box key={i}>
                        <Box sx={{
                          display: 'flex', justifyContent: 'space-between', alignItems: 'center', cursor: 'pointer', width: "250px", backgroundColor: some ? '#D2B8FF' : 'inherit',
                          '&:hover': { backgroundColor: '#D2B8FF' }
                        }}>
                          <Box onClick={() => handleDeliverableListSelect(v, i)} sx={{ width: '100%', p: 2 }}>
                            <Typography sx={{ fontWeight: "bold" }}>{v?.deliverable_name}</Typography>
                          </Box>
                          <FaAngleDown onClick={() => handleAccordianOpen(v, i)} style={{ marginRight: '10px', marginLeft: '10px' }} />
                        </Box>
                        <Box sx={accordian === v?.deliverable_name ?
                          { display: 'block' }
                          :
                          { display: 'none' }
                        }>
                          {
                            Array.isArray(v?.task_deliverable) && v?.task_deliverable.length > 0 ?
                              v?.task_deliverable.map((value, index) => {
  
                                let start = selectedTask.some((val) => val.id === value.id)
                                return (
                                  <Box key={index} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', border: '1px solid lightgray', backgroundColor: '#F8F9FA', px: 3, py: 1, width: '250px' }}>
                                    <Typography sx={{ fontWeight: 'bold' }}>{value?.task_name}</Typography>
                                    <FaStar onClick={() => handleDeliverableTaskSelect(value)} style={{ cursor: 'pointer', color: start ? '#EDB901' : '#E0E0DF', fontSize: '16px' }} />
                                  </Box>
                                )
                              })
  
                              :
                              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', border: '1px solid lightgray', backgroundColor: '#F8F9FA', px: 3, py: 1, width: '250px' }}>
                                <Typography sx={{ fontWeight: 'bold' }}>Empty Task</Typography>
                              </Box>
                          }
  
                        </Box>
                      </Box>
                    )
                  })
                }
              </Box>
            </Box>
  
            {/* this is WorkType Filter */}
            <Box sx={{
              width: '230px', mt: 2, mx: 1, borderBottom: worktypeValue.length > 0 ? '2px solid #711FFF' : '2px solid lightgray', position: 'relative',
              '&:hover': { borderBottom: '2px solid #711FFF' },
            }}
              ref={worktypeRef}
            >
  
              <Typography sx={{ color: worktypeValue.length > 0 ? '#711FFF' : '#00000099', fontWeight: '400' }}>Filter By Worktype</Typography>
  
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box className='customScrollBar' sx={{ display: 'flex', overflow: 'auto', width: '180px', height: '35px' }}>
                  {
                    worktypeValue.length > 0 ?
  
                      worktypeValue.map((v, i) => {
                        return (
                          <Box key={i} sx={{ backgroundColor: '#00000014', borderRadius: '20px', px: 0.5, mb: 0.5, display: 'flex', alignItems: 'center', mr: 0.5 }}>
                            <Typography sx={{ marginLeft: '5px', width: '60px', color: 'black', overflow: 'hidden', whiteSpace: 'nowrap' }}>{v}</Typography>
                            <IoIosCloseCircle onClick={() => {
                              setWorktypeValue(worktypeValue.filter((flt) => flt !== v))
                            }}
                              style={{ marginLeft: '5px', color: '#c2c3c4', cursor: 'pointer', fontSize: '15px' }} />
                          </Box>
                        )
                      })
                      :
                      <Typography sx={{ fontSize: '16px', fontWeight: '400', width: '100%', color: '#000000DE', mt: 1 }} onClick={handleWorktypePopopToggle} >Select</Typography>
                  }
                </Box>
  
                <Box>
                  {
                    worktypeValue.length > 0 &&
                    <IoCloseSharp style={{ marginRight: '10px', cursor: 'pointer', color: '#0000008F' }} onClick={() => {
                      setWorktypeValue([])
                    }} />
                  }
                  {
                    worktypeValue.length > 0 ?
                      <IoMdArrowDropup style={{ cursor: 'pointer', fontSize: '24px', color: '#0000008F' }} onClick={handleWorktypePopopToggle} />
                      :
                      <IoMdArrowDropdown style={{ cursor: 'pointer', fontSize: '24px', color: '#0000008F' }} onClick={handleWorktypePopopToggle} />
                  }                    </Box>
              </Box>
  
              <Box className='customScrollBar' sx={
                !filterWorktypePopop ?
                  { display: 'none' }
                  :
                  {
                    position: 'absolute', py: 2, backgroundColor: 'white', zIndex: 10, mt: 0.5, borderRadius: '6px', width: '250px', height: '400px', overflow: 'auto',
                    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px',
                    border: '1px solid var(--black-black-20, rgba(3, 7, 30, 0.20))'
                  }}>
                {
                  Array.isArray(combineWorktype) && combineWorktype.map((v, i) => {
                    let flt = worktypeValue.find((flt) => flt === v)
                    return (
                      <Box key={i} onClick={() => handleWorktypeListSelect(v, i)} sx={{
                        display: 'flex', width: '100%', height: '22px', alignItems: 'center', borderRadius: 2, px: 3, py: 3, my: 1.5, cursor: 'pointer',
                        backgroundColor: flt ? '#9152FF' : 'inherit', color: 'white'
                      }}>
                        <Box sx={{ textTransform: 'uppercase' }}>
                          <Typography sx={{ color: flt ? 'white' : 'black', fontSize: '14px', fontWeight: 400 }} >{v}</Typography>
                        </Box>
                      </Box>
                    )
                  })
                }
              </Box>
            </Box>
  
            {/* this is People Filter */}
            <Box sx={{
              width: '230px', mt: 2, mx: 1, borderBottom: peopleValue.length > 0 ? '2px solid #711FFF' : '2px solid lightgray', position: 'relative',
              '&:hover': { borderBottom: '2px solid #711FFF' },
            }}
              ref={peopleRef}
            >
  
              <Typography sx={{ color: peopleValue.length > 0 ? '#711FFF' : '#00000099', fontWeight: '400' }}>Filter By People</Typography>
  
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box className='customScrollBar' sx={{ display: 'flex', overflow: 'auto', width: '180px', height: '35px' }}>
                  {
                    peopleValue?.length > 0 ?
                      peopleValue?.map((v, i) => {
                        return (
                          <Box sx={{ backgroundColor: '#00000014', borderRadius: '20px', px: 0.5, mb: 0.5, display: 'flex', alignItems: 'center', mr: 0.5 }}>
                            {false ?
                              <img src='https://www.google.com/url?sa=i&url=https%3A%2F%2Fstock.adobe.com%2Fsearch%2Fimages%3Fk%3Dadmin%2Bicon&psig=AOvVaw0nbHsIp7Zj0mSMlKD_DR2M&ust=1701256844633000&source=images&cd=vfe&ved=0CBIQjRxqFwoTCNiX0eLJ5oIDFQAAAAAdAAAAABAE' alt='im' style={{ marginRight: '5px' }} />
                              :
                              <HiMiniUserCircle style={{ fontSize: '24px' }} />
                            }
                            <Typography sx={{ width: '60px', color: 'black', overflow: 'hidden', whiteSpace: 'nowrap' }}>{v.first_name} {v.last_name}</Typography>
                            <IoIosCloseCircle onClick={() => {
                              setPeopleValue(peopleValue.filter((flt) => flt.first_name !== v.first_name))
                            }}
                              style={{ marginLeft: '5px', color: '#c2c3c4', cursor: 'pointer', fontSize: '15px' }} />
                          </Box>
                        )
                      })
                      :
                      <Typography sx={{ fontSize: '16px', fontWeight: '400', width: "100%", color: '#000000DE', mt: 1 }} onClick={handlePeoplePopopToggle} >Select</Typography>
                  }
                </Box>
  
                <Box>
                  {
                    peopleValue?.length > 0 &&
                    <IoCloseSharp style={{ marginRight: '10px', cursor: 'pointer', color: '#0000008F' }} onClick={() => {
                      setPeopleValue([])
                    }} />
                  }
                  {
                    peopleValue.length > 0 ?
                      <IoMdArrowDropup style={{ cursor: 'pointer', fontSize: '24px', color: '#0000008F' }} onClick={handlePeoplePopopToggle} />
                      :
                      <IoMdArrowDropdown style={{ cursor: 'pointer', fontSize: '24px', color: '#0000008F' }} onClick={handlePeoplePopopToggle} />
                  }                    </Box>
              </Box>
  
              <Box className='customScrollBar' sx={
                !filterPeoplePopop ?
                  { display: 'none' }
                  :
                  {
                    position: 'absolute', p: 2, backgroundColor: 'white', zIndex: 10, mt: 0.5, width: '350px', height: '310px', overflow: 'auto',
                    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px'
                  }}>
                {
                  Array.isArray(UserTeamPopOp) && UserTeamPopOp.filter((item) => item.first_name !== "First Name").map((v, i) => {
  
                    let flt = peopleValue.find((val) => val.first_name === v.first_name)
  
                    return (
                      <Box key={i} onClick={() => {
                        handlePeopleListSelect(v, i)
                      }
                      } sx={{
                        display: 'flex', width: '98%', height: '65px', alignItems: 'center', borderRadius: 2, px: 3, py: 1, my: 1.5, cursor: 'pointer',
                        boxShadow: '2px 2px 6px 0px #D9D9D9', backgroundColor: flt ? '#9152FF' : 'inherit', color: 'white'
                      }}>
                        <HiMiniUserCircle style={{ fontSize: '34px', marginRight: '10px', width: '42px', height: '42px', color: 'lightgray' }} />
                        <Box sx={{ textTransform: 'uppercase' }}>
                          <Typography sx={{ color: flt ? 'white' : 'black', fontSize: '12px', fontWeight: 600 }} >{v.first_name} {v.last_name}</Typography>
                          <Typography sx={{ color: flt ? 'white' : '#711FFF', fontSize: '12px' }}>{v.title}</Typography>
                        </Box>
                      </Box>
                    )
                  })
                }
            </Box>
            </Box>
          </Box>
        </Box >
  
        <div ref={divRef} id={"div-to-print"} >
  
          <Box sx={{
            width: '100%',
            display: { xs: 'block', sm: "flex" },
            alignItems: 'center',
          }}>
            <Typography
              sx={{
                color: 'black',
                fontWeight: '600',
                fontSize: '16',
                textTransform: 'uppercase',
                margin: '0px 10px',
  
              }}
            >
              OVERALL TASK REPORTS
            </Typography>
            <Typography sx={{
              backgroundColor: '#f2ebff',
              display: 'flex',
              alignItems: 'center',
              borderRadius: '8px',
              width: 'fit-content',
              p: '10px',
              fontWeight: '500',
              fontSize: '12px',
              color: 'black',
              mr: 2,
            }}>
              Bilable Hours
              <span style={{
                fontSize: '16px',
                color: '#DC241C',
                fontWeight: '700',
                marginLeft: '4px'
              }}>
                {billableHours}
                Hours
              </span>
  
            </Typography>
            |
            <Typography sx={{
              backgroundColor: '#e5fffa',
              display: 'flex',
              alignItems: 'center',
              borderRadius: '8px',
              width: 'fit-content',
              p: '10px',
              fontWeight: '600',
              fontSize: '12px',
              ml: { xs: 0, sm: 2 },
            }}>
              NON BILLABLE HOURS <span style={{
                fontSize: '16px',
                color: '#9152FF',
                fontWeight: '700',
                marginLeft: '4px'
              }}>
                {nonBillableHours}
                Hour
              </span>
            </Typography>
          </Box>
  
          {/* thsi is chart  components */}
    
          <BarCharts apiData={withBillableValue}  />

          <div id="div-to-csv">
            {
              groupName === 'Work Type' ?
                Array.isArray(arryworktype) && arryworktype.map((value, i) => {
                  let arryworktypeTask = withBillableValue.filter(flt => flt.task_worktype.length > 0 && flt.task_worktype[0].name === value)
  
                  const totalDuration = totalHoursAndMinutes(arryworktypeTask);
  
                  return (
                    <Box key={i} sx={{ backgroundColor: '#F1F3F5', p: 1, borderRadius: 2, mb: 10, mx: 3 }}>
                    <Box
                            sx={{
                              display: "flex",
                              direction: "row",
                              justifyContent: "space-between",
                              alignItems: "center",
                              my: 1.5,
                              mx: 2,
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "flex-start",
                              }}
                            >
                              <HiMiniUserCircle
                                style={{ fontSize: "24px", mr: 2 }}
                              />
                              <Typography sx={{ fontSize: "16px" }}>
                                {value}
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                direction: "row",
                                justifyContent: "flex-end",
                                alignItems: "flex-end",
                              }}
                            >
                              <Box
                                sx={{
                                  cursor: "pointer",
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  background: "#F1F3F5",
                                  borderRadius: "10px",
                                  padding: "8px 19px",
                                }}
                              >
                                <p
                                  style={{
                                    background: "#d2b8ff",
                                    borderRadius: "50%",
                                    padding: "3px 5px",
                                    marginRight: "6px",
                                    fontSize: "12px",
                                  }}
                                >
                                  {" "}
                                  {arryworktypeTask.length}
                                </p>
                                <Typography
                                  sx={{
                                    color: "#310085",
                                    fontSize: "13px",
                                    fontWeight: 600,
                                  }}
                                >
                                  {" "}
                                  TASKS{" "}
                                </Typography>
                              </Box>
                              <Box
                                sx={{
                                  cursor: "pointer",
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  background: "#F1F3F5",
                                  borderRadius: "10px",
                                  padding: "8px 19px",
                                  // marginLeft: "14px",
                                }}
                              >
                                <p
                                  style={{
                                    padding: "3px 5px",
                                    marginRight: "6px",
                                    fontSize: "14px",
                                    fontWeight: 400,
                                  }}
                                >
                                  {" "}
                                  Total Duration{" "}
                                </p>
                                <Typography
                                  sx={{
                                    background: "#D2B8FF",
                                    color: "#310085",
                                    fontSize: "13px",
                                    borderRadius: "6px",
                                    fontWeight: 600,
                                    padding: "2px 10px",
                                  }}
                                >
                                  {" "}
                                  {totalDuration.totalHours +
                                    "h" +
                                    ":" +
                                    totalDuration.totalMinutes +
                                    "m"}
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                      <TableContainer
                        component={Paper}
                        sx={{
                          width: '100%',
                          border: '1px solid #adadad',
                          borderRadius: '8px',
                        }}
                      >
                        <Table>
                          <TableHead>
                            <TableRow
                              sx={{
                                height: '72px',
                                backgroundColor: '#f8f9fa',
                              }}
                            >
  
                              <TableCell>Task Name</TableCell>
                              <TableCell >Description</TableCell>
                              <TableCell >Worktype</TableCell>
                              <TableCell sx={{ minWidth: '100px' }} >Time</TableCell>
                              <TableCell >Duration</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            { arryworktypeTask.length > 0 ?
                              Array.isArray(arryworktypeTask) && arryworktypeTask.map((v, i) => {
                                const project_name = Array.isArray(Projects) && Projects.length && v.task_project ? Projects.find((item) => item.id === v?.task_project)?.title : ''
  
                                let filter = v.time_tracking.filter((val, ind) => val.tracked_time.slice(0, 5) !== '00:00')
                                return (
                                  <>
                                    {
                                      filter.map((val, ind) => {
                                        return (
                                          <TableRow key={ind}>
                                            <TableCell sx={{ width: "30%" }}>
                                              <Box>
                                                <Typography>{v.task_name}</Typography>
                                                <Box sx={{ display: 'flex', alignItems: 'center', ml: 1, }}>
                                                  {
                                                    project_name &&
                                                    <svg style={{ marginRight: '10px' }} width="9" height="7" viewBox="0 0 9 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                      <path id="Vector 4" d="M1 0V6H9" stroke="black" />
                                                    </svg>
                                                  }
                                                  <Typography sx={{ color: '#5600EB', fontSize: '12px', '&:hover': { color: 'black' } }}>{project_name}</Typography>
                                                </Box>
  
                                              </Box>
                                            </TableCell>
                                            <TableCell >
                                              <Typography dangerouslySetInnerHTML={{ __html: val.note }}></Typography>
                                            </TableCell>
                                            <TableCell >
                                              <Typography>{v.task_worktype[0] && v.task_worktype[0].name}</Typography>
  
                                            </TableCell>
                                            <TableCell >
                                              {
                                                val && val?.tracked_time && val?.tracked_time.slice(0, 5) === '0:0' ? (
                                                  <>
                                                    <Typography> No Time Tracked </Typography>
                                                  </>
                                                ) : (
                                                  <>
                                                    <Typography sx={{ fontSize: "14px", color: "#03071E" }}>{val.time_tracking_start && dayjs(val.time_tracking_start).format('hh-mm-A')} - {val.time_tracking_stop && dayjs(val.time_tracking_stop).format('hh-mm-A')}</Typography>
                                                    <Typography sx={{ fontSize: "14px", color: "#03071E99" }}>{moment(val?.date || val?.created_at).format('MMMM DD, yy') !== 'January 01, 1970' ? moment(val?.date ?? val?.created_at ).format('MMMM DD, yy') : 'No Time Tracked'}</Typography>
                                                  </>
  
                                                )
                                              }
  
                                            </TableCell>
                                            <TableCell >
                                              <Typography sx={{ mx: 'auto', backgroundColor: "#D2B8FF", borderRadius: '6px', py: 0.5, px: 4, display: "inline-block", fontWeight: "bold" }}>{val.tracked_time.slice(0, 5)} <span style={{ fontWeight: 400 }}>Hr</span></Typography>
                                            </TableCell>
                                          </TableRow>
                                        )
                                      })
  
                                    }
                                  </>
                                )
                              })
                              :
                              <TableRow>
                                  <TableCell colSpan={5} align="center">
                                      <p style={{ width: '100%', textAlign: 'center', alignSelf: 'center' }}
                                      >NO TASkS</p>
                                  </TableCell>
                              </TableRow>
                            }
                          </TableBody>
                        </Table>
                      </TableContainer>
  
                    </Box>
  
                  )
                })
                :  
                Array.isArray(Team) && Team.map((value, i) => {
                  const tasks = value.tasks
                  const project_Name = []
  
                  tasks.map((task, taskIndex) => {
                    // Accessing data for each task
                    const project_name = Array.isArray(Projects) && Projects.length && task.task_project ? Projects.find((item) => item.id === task.task_project)?.title : '';

                    project_Name.push(project_name)
  
                  });
  
                  const totalDuration = totalHoursAndMinutes(tasks);
  
                  return (
                    <Box key={i} sx={{ backgroundColor: '#F1F3F5', p: 1, borderRadius: 2, mb: 10, mx: 3 }}>
                     
                      <Box
                            sx={{
                              display: "flex",
                              direction: "row",
                              justifyContent: "space-between",
                              alignItems: "center",
                              my: 1.5,
                              mx: 2,
                            }}
                          >
                             <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', my: 1.5, mx: 2, }}>
                        <Avatar src={value.avatar ? value.avatar : 'sharp/img.png'} alt={value.first_name} sx={{
                          border: '2px solid #D2B8FF',
                          height: '32px',
                          width: '32px',
                        }} />
                        <Typography sx={{ fontSize: '16px' }}>{value?.first_name} {value?.last_name}</Typography>

                      </Box>
                            <Box
                              sx={{
                                display: "flex",
                                direction: "row",
                                justifyContent: "flex-end",
                                alignItems: "flex-end",
                              }}
                            >
                              <Box
                                sx={{
                                  cursor: "pointer",
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  background: "#F1F3F5",
                                  borderRadius: "10px",
                                  padding: "8px 19px",
                                }}
                              >
                                <p
                                  style={{
                                    background: "#d2b8ff",
                                    borderRadius: "50%",
                                    padding: "3px 5px",
                                    marginRight: "6px",
                                    fontSize: "12px",
                                  }}
                                >
                                  {" "}
                                  {tasks.length}
                                </p>
                                <Typography
                                  sx={{
                                    color: "#310085",
                                    fontSize: "13px",
                                    fontWeight: 600,
                                  }}
                                >
                                  {" "}
                                  TASKS{" "}
                                </Typography>
                              </Box>
                              <Box
                                sx={{
                                  cursor: "pointer",
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  background: "#F1F3F5",
                                  borderRadius: "10px",
                                  padding: "8px 19px",
                                  // marginLeft: "14px",
                                }}
                              >
                                <p
                                  style={{
                                    padding: "3px 5px",
                                    marginRight: "6px",
                                    fontSize: "14px",
                                    fontWeight: 400,
                                  }}
                                >
                                  {" "}
                                  Total Duration{" "}
                                </p>
                                <Typography
                                  sx={{
                                    background: "#D2B8FF",
                                    color: "#310085",
                                    fontSize: "13px",
                                    borderRadius: "6px",
                                    fontWeight: 600,
                                    padding: "2px 10px",
                                  }}
                                >
                                  {" "}
                                  {totalDuration.totalHours +
                                    "h" +
                                    ":" +
                                    totalDuration.totalMinutes +
                                    "m"}
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                      <TableContainer
                        component={Paper}
                        sx={{
                          width: '100%',
                          border: '1px solid #adadad',
                          borderRadius: '8px',
                        }}
                      >
                        <Table>
                          <TableHead>
                            <TableRow
                              sx={{
                                height: '72px',
                                width: "100%",
                                backgroundColor: '#f8f9fa',
                              }}
                            >
  
                              <TableCell >Task Name</TableCell>
                              <TableCell  >Description</TableCell>
                              <TableCell >Worktype</TableCell>
                              <TableCell sx={{ minWidth: '100px' }} >Time</TableCell>
                              <TableCell >Duration</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody sx={{ width: "100%" }} >

                            { value?.tasks?.length > 0 ?
                              value.tasks?.map((val, ind) => {
  
                                const start = Array.isArray(val.time_tracking) && val.time_tracking.length && val.time_tracking[val.time_tracking.length - 1].time_tracking_start
                                const end = Array.isArray(val.time_tracking) && val.time_tracking.length && val.time_tracking[val.time_tracking.length - 1].time_tracking_stop
  
                                const startDateTime = new Date(start);
                                const endDateTime = new Date(end);
  
                                const timeDifference = endDateTime - startDateTime;
  
                                const hours = Math.floor(timeDifference / (1000 * 60 * 60));
                                const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
  
                                const startTime = new Date(start).toLocaleTimeString('en-US', {
                                  hour: 'numeric',
                                  minute: 'numeric',
                                  hour12: true,
                                });
  
  
                                const endTime = new Date(end).toLocaleTimeString('en-US', {
                                  hour: 'numeric',
                                  minute: 'numeric',
                                  hour12: true,
                                });
  
                                return (
                                  <TableRow sx={{ width: "100%" }} key={ind}>
                                    <TableCell sx={{ width: "20%" }} id={project_Name[0]} >
                                      <Box>
                                        <Typography>{val?.task_name}</Typography>
                                        <Box sx={{ display: 'flex', alignItems: 'center', ml: 1, }}>
                                          {
                                            project_Name[0] &&
                                            <svg style={{ marginRight: '10px' }} width="9" height="7" viewBox="0 0 9 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path id="Vector 4" d="M1 0V6H9" stroke="black" />
                                            </svg>
                                          }

                                          <Typography sx={{ color: '#5600EB', fontSize: '12px', '&:hover': { color: 'black' } }}>{project_Name[0]}</Typography>
                                        </Box>
  
                                      </Box>
                                    </TableCell>
                                    <TableCell sx={{ width: "20%" }} >

                                      <Typography dangerouslySetInnerHTML={{ __html: val?.time_tracking[0]?.note }}></Typography>
                                    </TableCell>
                                    <TableCell sx={{ width: "20%" }} >
                                    <span id='status'>{value.status}</span>

                                      <Typography>{val?.task_worktype[0] && val?.task_worktype[0].name}</Typography>
  
                                    </TableCell>
                                    <TableCell sx={{ width: "15%" }} >
                                    <span id='status'>{value.status}</span>

                                      {
                                        timeDifference === 0 ? (
                                          <>
                                            <Typography> No Time Tracked </Typography>
                                          </>
                                        ) : (
                                          <Box
                                            sx={{
                                              display: 'flex',
                                              flexDirection: 'column',
                                              alignItems: 'flex-start',
                                              // width: '30%',
                                            }}
                                          >
                                            <Box
                                              sx={{
                                                display: 'flex',
                                                gap: '1rem',
                                              }}
                                            >
                                              <Typography
                                                sx={{
                                                  fontWeight: '600',
                                                  fontSize: '0.98rem',
                                                }}
                                              >
                                                {startTime}
                                            
                                              </Typography>
                                              <Typography
                                                sx={{
                                                  fontWeight: '600',
                                                  fontSize: '0.98rem',
                                                }}
                                              >
                                                {endTime}
                                       
                                              </Typography>
                                            </Box>
                                            <Typography
                                              sx={{
                                                fontWeight: '400',
                                                fontSize: '0.88rem',
                                                color: '#686a78',
                                              }}
                                            >
                                              { moment(val?.time_tracking[0].date??val?.time_tracking[0].time_tracking_start).format('MMMM DD, yy') ?? 'No Time Tracked'}
                                          
                                            </Typography>
                                          </Box>
                                        )
                                      }
  
                                    </TableCell>
                                    <TableCell sx={{ width: "15%" }}>
                                    <span id='status'>{value.status}</span>

                                      <Box
                                        sx={{
                                          width: '50%',
                                          backgroundColor: '#d2b8ff',
                                          borderRadius: '8px',
                                          p: '.54rem',
                                          textAlign: 'center',
                                          fontSize: '.9rem',
                                        }}
                                      >
                                        {hours + ' : ' + minutes + ' Hr'}
                         
                                      </Box>
                                    </TableCell>
                                  </TableRow>
                                )
                              })
                              :
                              <TableRow>
                              <TableCell colSpan={5} align="center">
                                  <p style={{ width: '100%', textAlign: 'center', alignSelf: 'center' }}
                                  >NO TASkS</p>
                              </TableCell>
                          </TableRow>
  
                            }
                  
                          </TableBody>
                        </Table>
                      </TableContainer>
  
                    </Box>
  
                  )
                })
  
            }
          </div>
  
        </div>
  
      </>
      :
      <PreLoading/>
    
  );

};


export default Team