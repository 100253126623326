import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Box, Backdrop, Grid, Typography, MenuItem, TextField } from '@mui/material';
import { getProjects } from './proRedux';
import Dots from './assets/Dots.png';
import Gear from './assets/Gear.png';
import './project.css';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { getVariant } from './ProjectUtility';
import 'primereact/resources/primereact.min.css';
import { IoMdArrowDropdown, IoMdArrowDropup } from 'react-icons/io';
import icon1 from '../../Assets/icon/icon1.jpg';
import icon2 from '../../Assets/icon/icon2.jpg';
import icon3 from '../../Assets/icon/icon3.jpg';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { FindIcon } from './_module';
import { DataGridPro } from '@mui/x-data-grid-pro';
import moment from 'moment';
import HelpVideo from '../../Componenets/TopNav/HelpVideo';
import helpVid from '../Opportunity/assets/Section_Help-idle.svg'
import { MdControlPointDuplicate } from "react-icons/md";
import { LuServer } from "react-icons/lu";
import Popup from 'reactjs-popup';
import moreIco from '../Opportunity/assets/moreIco.png';
const fontFamily = 'Open Sans';
import DeleteIcon from '../Contacts/assets/DeleteIcon.png';
import SettingsIcon from '@mui/icons-material/Settings';
import { ENDPOINT } from '../Opportunity/Redux/oppor';
import { PreLoader1 } from '../../Componenets/Misc';
import Bounce from 'react-reveal/Bounce';
import Slide from 'react-reveal/Slide';
import { ActiveProjectAlert } from './NewProject/PFifth';
import { getWorkTypes } from '../User/redux';

const Delete = ({ setSureDelete, currentTarget }) => {
  const dispatch = useDispatch();
  const [finishDate, setFinishData] = useState(null);
  const navigate = useNavigate();
  const [val, setVal] = useState();
  const access_token = useSelector((state) => state.auth.user.access);
  const [loading, setLoading] = useState(false);
  const handleCompletion = async () => {
    if (!val) {
      return;
    }
    if (val.toLocaleLowerCase() !== 'delete') {
      return;
    }
    setLoading(true)
    const res = await fetch(
      `${ENDPOINT}/api/project/delete/${currentTarget.id}/`,
      {
        method: 'DELETE',
        headers: {
          Authorization: `JWT ${access_token}`,
          'Content-Type': 'application/json',
        },
      }
    );
    if (!res.ok) {
      return
    }
    else {
      dispatch(getProjects({ access_token }))
      setSureDelete(false)
      setVal('')
      setLoading(false)
    }
  };

  return (
    <section
      className="loading"
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100%',
        backgroundColor: 'rgba(255,255,255,0.5)',
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 999,
      }}
    >
      <Box
        sx={{
          width: '850px',
          height: '320px',
          background: 'rgba(241, 243, 245, 0.50)',
          padding: '2rem',
          borderRadius: '8px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            width: '95%',
            height: '95%',
            backgroundColor: 'rgba(255,255,255)',
            border: '1px solid #d9d9d9',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            borderRadius: '8px',
            padding: '0.68rem 1rem',
            justifyContent: 'space-around',
            boxShadow: '2px 2px 6px 0px #D9D9D9',
            gap: '10px'
          }}
        >
          <p
            style={{
              fontSize: '0.9rem',
              fontWeight: '600',
              fontFamily: 'Open Sans, sans-serif',
              color: 'red',
            }}
          >
            DELETE PROJECT
          </p>
          <p className="fstxt" style={{ width: '80%', textAlign: 'center', fontWeight: '400', }}>
            Are you sure you want to Delete this Project? Your Project will
            become inactive and the billing will also stop.
          </p>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <p className="fstxt" style={{ fontWeight: '400' }}>
              Please type the word "DELETE" and click the Delete button
            </p>
            <Box
              sx={{
                width: '40%',
                borderRadius: '8px',
                padding: '1rem 1.1rem',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                height: '65px',
              }}
            >
              <TextField
                value={val}
                variant='standard'
                onChange={(e) => setVal(e.target.value)}
                placeholder={"Delete"}
                sx={{
                  width: '100%',
                  '& .MuiInputBase-input': {
                    fontSize: '1rem',
                    fontFamily: 'Open Sans',
                    fontWeight: '500',
                    color: '#000000',
                    textAlign: 'center',
                  },
                  '& .MuiInput-underline:before': {
                    borderBottom: '1px dashed #000000',
                  },
                  '& .MuiInput-underline:after': {
                    borderBottom: '1px dashed #000000',
                  },
                  '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                    borderBottom: '1px dashed #000000',
                  },
                }}
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '50%',
              margin: '0 auto'
            }}
          >
            <button
              className="cancel-btn"
              onClick={() => setSureDelete(false)}
            >
              Cancel
            </button>
            <button
              style={{
                fontFamily: '\'Neue Haas Display Pro\', \'Open Sans\', sans-serif',
                fontWeight: '500',
                padding: '1rem 0.5rem',
                backgroundColor: val ? '#cd0b4c' : '#f99f9f',
                borderRadius: '5px',
                color: 'var(--white-100)',
                border: 'none',
                cursor: 'pointer',
                textAlign: 'center',
                fontSize: '1rem',
                padding: '0rem 2rem',
                height: '38px'
              }}
              onClick={handleCompletion}
              disabled={val ? false : true}
            >
              {loading ? <Box sx={{ width: '30px', height: '30px', margin: '0rem 0.1rem' }}><PreLoader1 /></Box> : 'Delete'}{' '}
            </button>
          </Box>
        </Box>
      </Box>
    </section>
  );
};

const Duplicate = ({ setSureDuplicate, currentTarget, myArr2 }) => {
  const allWorktypes = useSelector((item) => item.Misc.workTypes)
  const dispatch = useDispatch();
  const access_token = useSelector((state) => state.auth.user.access);
  const [loading, setLoading] = useState(false);

  const handleDelete = async () => {
    setLoading(true)
    const resGet = await fetch(`${ENDPOINT}/api/project/detail/${currentTarget.unique_id}/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `JWT ${access_token}`,
      },
    });
    const getData = await resGet.json()

    const formData = { ...getData }
    let duplicateNumber = 1;
    let newTitle = `${formData.title} - Duplicate ${duplicateNumber}`;

    // Check if the new title already exists
    while (myArr2.some(item => item.title === newTitle)) {
      duplicateNumber++;
      newTitle = `${formData.title} - Duplicate ${duplicateNumber}`;
    }

    delete formData.work_type
    delete formData.id
    delete formData.collaborator
    delete formData.created_at

    if (resGet.ok) {
      const res = await fetch(`${ENDPOINT}/api/project/create/`, {
        method: 'POST',
        headers: {
          Authorization: `JWT ${access_token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...formData,
          title: newTitle,
          budget: Number(parseFloat(formData?.budget).toLocaleString('en-US', { maximumFractionDigits: 0 }) || 0),
          est_start_date: formData.target_start || new Date(new Date().setDate(new Date().getDate() + 14)).toISOString(),
          est_finish_date: formData.finish_date || null,
          members: Array.isArray(formData.project_subteam) && formData.project_subteam.length ? formData.project_subteam[0].members.map((item) => (item.email)) : [],
          project_deliverable: Array.isArray(formData.deliverable_project) && formData.deliverable_project.length ? formData.deliverable_project.map(item => ({
            deliverable_name: item.deliverable_name,
            loe_price: item.loe_price ?? 0,
            description: item.description || '',
            task_deliverable: Array.isArray(item.task_deliverable) && item.task_deliverable.length ? item.task_deliverable.map(tsk => ({
              ...tsk,
              task_name: tsk.task_name,
              task_worktypes: Array.isArray(tsk.task_worktype) ? tsk.task_worktype.map(tskWtt => ({
                id: tskWtt.id,
                name: tskWtt.name,
                hours: tskWtt.hours || 0,
              })) : []
            })) : [],
            deliverable_multiplier: Array.isArray(item.deliverable_multiplier) ? item.deliverable_multiplier.map(multi => ({
              label: multi.label,
              rate: multi.rate,
              operation: multi.operation,
            })) : [],
            user_price: item.user_price || 0,
            deliverable_startdate: item.deliverable_startdate,
            deliverable_enddate: item.deliverable_enddate,
            deliverable_worktype: Array.isArray(item.deliverable_worktype) ? item.deliverable_worktype.map(wtt => ({
              name: wtt.name,
              hours: wtt.hours || 0,
              id: wtt.id,
            })) : []
          })) : [],
          estimate_hardcost: Array.isArray(formData.estimate_hardcost) ? formData.estimate_hardcost.map(est => ({
            hardcost_label: est.hardcost_label,
            our_price: est.our_price,
            price_to_customer: est.price_to_customer,
            hardcost_margin: est.hardcost_margin,
          })) : [],
          worktype: Array.isArray(formData.project_deliverable) && formData.project_deliverable.length ? formData.project_deliverable[0].deliverable_worktype.map(item => ({
            id: Number(item.id),
            hours: item.hours || 0,
          })) : Array.isArray(getData?.work_type) && getData?.work_type.length && getData?.work_type.map(item => ({
            id: Number(item.id),
            hours: 0,
          })),
        })

      });
      const data = await res.json();
      if (!res.ok) {

        return;
      }
      else {
        dispatch(getProjects({ access_token }))

        setSureDuplicate(false);
        setLoading(false)
      }
    }
  };

  return (
    <section
      className="loading"
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100%',
        backgroundColor: 'rgba(255,255,255,0.5)',
        position: 'fixed',
        top: 0,
        left: 0,
      }}
    >
      <Slide bottom>
        <Box
          sx={{
            width: '500px',
            height: '150px',
            backgroundColor: 'rgba(255,255,255)',
            border: '1px solid #d9d9d9',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: "space-between",
            alignItems: 'center',
            borderRadius: '8px',
            padding: '1.48rem 1rem',
          }}
        >
          <p className="fstxt">
            Are You Sure You want to Mark this Opportunity as Duplicate? It will still
            show up in your sales reporting.{' '}
          </p>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <button className="cancel-btn" onClick={() => setSureDuplicate(false)}>
              Cancel
            </button>
            <button style={{ background: loading && '#FF6827', height: '46px' }} className="netWarn-btn" onClick={() => handleDelete()}>
              {loading ? <Box sx={{ width: '30px', height: '30px', margin: '0rem 0.6rem' }}><PreLoader1 /></Box> : 'Duplicate'}{' '}
            </button>
          </Box>
        </Box>
      </Slide>
    </section>
  );
};


const InfoCreate = ({ menuRef, setShowModal }) => {
  const navigate = useNavigate();
  return (
    <Grid
      container
      sx={{
        backgroundColor: '#fff',
        borderRadius: '8px',
        padding: '2.44rem 2.88rem',
        gap: '2rem',
        width: 'fit-content',
      }}
      alignItems="center"
      justifyContent="center"
      ref={menuRef}
    >
      <Box
        sx={{
          background: '#310085',
          padding: '1.44rem 1.88rem',
          color: '#fff',
          listStyle: 'disc',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          fontStyle: '1rem',
          borderRadius: '8px',
          width: "27rem",
          cursor: 'pointer',
        }}
        onClick={() => {
          navigate('/New_Project')
          setShowModal(false)

        }}
      >
        <Typography
          sx={{
            fontFamily,
            color: '#fff',
            fontSize: '1.1rem',
            fontWeight: '600',
          }}
        >
          Set up a
        </Typography>
        <Typography
          sx={{
            fontFamily,
            color: '#fff',
            fontSize: '1.6rem',
            fontWeight: '600',
            marginBottom: '2rem',
          }}
        >
          JOB
        </Typography>
        <Box marginLeft="4px" fontSize="0.8rem" >
          <li>Does not include Pricing information</li>
          <li>Ideal for internal Jobs or client Jobs already in progress</li>
          <li>Reporting & KPI's will only be in hours</li>
        </Box>
      </Box>
      <Box
        sx={{ width: '1.5px', backgroundColor: '#d9d9d9', height: '150px' }}
      />
      <Box
        sx={{
          background: '#5fcea8',
          padding: '1.44rem 1.88rem',
          color: '#fff',
          listStyle: 'disc',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          fontStyle: '1rem',
          borderRadius: '8px',
          width: "27rem",
          cursor: 'pointer',
        }}
        onClick={() => {
          navigate('/New_Project_Estimate')
          setShowModal(false)
        }}
      >
        <Typography
          sx={{
            fontFamily,
            color: '#fff',
            fontSize: '1.1rem',
            fontWeight: '600',
          }}
        >
          Create an
        </Typography>
        <Typography
          sx={{
            fontFamily,
            color: '#fff',
            fontSize: '1.6rem',
            fontWeight: '600',
            marginBottom: '1.4rem',
          }}
        >
          ESTIMATE
        </Typography>
        <Box marginLeft="4px" fontSize="0.8rem" >
          <li>Great if you want Pricing information</li>
          <li>Made for client work</li>
          <li>Will display in your sales reporting</li>
        </Box>

      </Box>
    </Grid>
  );
};

const PrevBox = ({ setSearchQuery, searchQuery, setArgs, args }) => {
  return (
    <Box
      sx={{
        backgroundColor: '#f2f2f2',
        borderRadius: '8px 0px 0 0',
        borderTopRightRadius: '8px',
        height: '70px',
        width: '100%',
        border: '1px solid rgba(224, 224, 224, 1)',
        borderBottom: '1.5px solid  #d9d9d9',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0.5rem 1rem',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '40%',
        }}
      >
        <FormControl
          sx={{
            width: '300px',
          }}
        >
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            sx={{
              '& .MuiSelect-select:focus': {
                backgroundColor: '#711fff',
                color: '#fff',
                outline: '1px solid #711fff',
                border: '1px solid #711fff',
              },
              '& .MuiSelect-select:hover': {
                backgroundColor: '#711fff',
                color: '#fff',
                outline: '1px solid #711fff',
                border: '1px solid #711fff',
              },
              '& .MuiSelect-select': {
                backgroundColor: '#711fff',
                color: '#fff',
                outline: '1px solid #711fff',
                border: '1px solid #711fff',
                padding: '10px 32px 10px 10px',
              },
              '& .MuiSelect-icon': {
                color: '#fff',
              },
              '& .MuiSelect-selectMenu': {
                color: '#fff',
              },
            }}
            value={args}
            onChange={(e) => setArgs(e.target.value)}
          >
            <MenuItem value="all">All Projects & Retainers</MenuItem>
            <MenuItem value="active">Active Projects & Retainers</MenuItem>
            <MenuItem value="inactive">Pending Projects & Retainers</MenuItem>
            <MenuItem value="archived">Archived Projects & Retainers</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Box
        sx={{
          minWidth: '10%',
          maxWidth: '30%',
          display: 'flex',
          justifyContent: 'end',
          alignItems: 'center',
        }}
      >
        <FindIcon searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
      </Box>
    </Box>
  );
};

const Project = ({ showModal, setShowModal, setSettings, setProjectDetail, screenHeight }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.user.access);
  const meta = {
    access_token: token,
  };
  const currentCompanies = useSelector((state) => state.Contacts.companies);
  useEffect(() => {
    dispatch(getProjects(meta));
    setSettings(false)
    setProjectDetail(false)
    dispatch(getWorkTypes(meta));
  }, []);

  const [filterBase, setFilterBase] = useState(['active', 'archived']);
  const [filterOption, setFilterOption] = useState(false);

  useEffect(() => {
    dispatch(getProjects(meta));
  }, [showModal]);

  const myArr = useSelector((state) => state.projects.projects);
  const allTasks = useSelector((state) => state.projects.tasks);
  const [args, setArgs] = useState('all');
  const [searchQuery, setSearchQuery] = useState('');
  const [projects, setProjects] = useState([]);
  const filteredMyArr = myArr?.filter((item) => item.status === args);
  const myArr2 = args && args !== 'all' ? filteredMyArr : myArr;
  const filteredSearch = myArr2
    ? myArr2?.filter((item) =>
      item.title.toLowerCase().includes(searchQuery.toLowerCase())
    )
    : [];
  const myArr3 = searchQuery ? filteredSearch : myArr2;

  useEffect(() => {
    setProjects(myArr3);
  }, [args, searchQuery]);

  const menuRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    const arr = myArr?.filter((item) => item.status !== 'archived')
    setProjects(arr);
  }, [myArr]);

  const getToday = () => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return today;
  };


  const access_token = useSelector((state) => state.auth.user.access)
  const [sureDelete, setSureDelete] = useState(false)
  const [currentDlt, setCurrentDlt] = useState()
  const [sureDuplicate, setSureDuplicate] = useState(false)
  const columns = [
    {
      field: 'name',
      headerName: 'Project Name',

      flex: 2,
      renderHeader: (params) => (
        <div className="tableHeader" style={{ padding: '0 0 0 1rem' }}>
          Job Name
        </div>
      ),
      renderCell: (params) => (
        <div
          id="Cypress-row-test"
          className="td"
          onClick={() => {

            if (params.row.creation_wizard_completed) {
              navigate(`/Project_Detail/${params.row.unique_id}`)

            }
            else {
              setProjectDetail(params.row)
              navigate('/New_Project')
            }
          }}
          style={{ padding: '0 0 0 1rem' }}
        >
          {params.value}
        </div>
      ),
      align: 'left',
    },

    {
      field: 'company',
      headerName: 'Company Name',
      flex: 1.5,
      renderHeader: (params) => <div className="tableHeader">Company Name</div>,
      renderCell: (params) => {
        const currentTarget = currentCompanies.find(
          (ele) => Number(ele.id) === Number(params.value)
        );
        return (
          <div className="td">
            {currentTarget ? currentTarget.name : '-----'}
          </div>
        );
      },
    },
    {
      field: 'project_type',
      flex: 1.2,
      renderHeader: (params) => <div className="tableHeader">Type</div>,

      sortable: false,
      renderCell: (params) => (
        <div className="td" style={{ textTransform: 'capitalize' }}>
          {params.value}
        </div>
      ),
    },
    {
      field: 'status',
      flex: 1.2,
      renderHeader: (params) => <div className="tableHeader">Status</div>,

      sortable: false,
      renderCell: (params) => (
        <div className="td" style={{ textTransform: 'capitalize' }}>
          {params.value}
        </div>
      ),
    },
    {
      field: 'target_start',
      headerName: 'Timeline',
      sortable: false,
      flex: 2,
      renderHeader: (params) => <div className="tableHeader">Timeline</div>,
      renderCell: (params) => {
        const start = new Date(params?.row?.target_start?.split('T')[0]).getTime();
        const end = new Date(params?.row?.target_end?.split('T')[0]).getTime();
        const today = getToday().getTime();
        const progressDate = (today - start) / (1000 * 3600 * 24);
        const totalDate = (end - start) / (1000 * 3600 * 24);

        return (
          <div className="timeline">
            <ProgressBar
              now={progressDate}
              className={getVariant(progressDate, totalDate, 'date')}
              max={totalDate}
              min={0}
            />

            <div className="date_range">
              <span className="start">
                {moment(params?.row?.target_start?.split('T')[0]).format(
                  'DD MMMM YYYY'
                )}
              </span>
              <span className="end">
                {moment(params?.row?.target_end?.split('T')[0]).format(
                  'DD MMMM YYYY'
                )}
              </span>
            </div>
          </div>
        );
      },
    },
    {
      field: 'Level_of_effort',
      headerName: 'Level of Effort',
      sortable: false,
      flex: 2,
      renderHeader: (params) => (
        <div className="tableHeader">Level of Effort</div>
      ),
      renderCell: (params) => {
        let progressHour = 0;
        let totalHour = 0;
        const currentTask = Array.isArray(allTasks) && allTasks.length ? allTasks?.filter(it => it?.task_project === params?.row?.id) : []
        if (Array.isArray(currentTask) && currentTask.length) {
          progressHour = currentTask.reduce(
            (subAcc, task) =>
              subAcc +
              task.time_tracking.reduce(
                (tTime, hour) =>
                  tTime +
                  (hour.tracked_time_hours
                    ? Number(hour.tracked_time_hours.split(':')[0])
                    : 0),
                0
              ),
            0
          );
        }
        return (
          <div className="timeline">
            <ProgressBar
              sx
              now={progressHour}
              className={getVariant(progressHour, params?.row?.total_loe, 'hour')}
              min={0}
              max={params?.row?.total_loe}
            />
            <div className="date_range">
              <span></span>
              <span className="end">

                {Math.round(params?.row?.total_loe - progressHour) < 0 ? 0 : Math.round(params.row.total_loe - progressHour)} h left
              </span>
            </div>
          </div>
        );
      },

    },
    {
      field: 'menu',
      headerName: '',
      sortable: false,
      align: 'right',
      flex: 0.5,
      renderCell: (params) => (
        <div className="td" style={{ padding: '0 1rem 0 0' }}>
          <Popup
            open={isOpen === params.row.id ? true : false}
            trigger={
              <button type="button" className="noBorder">
                <img onClick={() => setIsOpen(params.row.id)} src={moreIco} alt="more" />
              </button>
            }
            onClose={() => setIsOpen(false)}
            position="right center"
          >
            <ul className="popup" id={params.id}>
              <li className="popupChildren" onClick={() => {

                navigate(`/Project_Detail/${params.row.unique_id}`)
                setSettings(true)
                setIsOpen('')
              }}><SettingsIcon style={{ marginRight: '5px' }}
                />
                Settings
              </li>
              {params.row.status === 'inactive' || params.row.status === 'archived' ? <li
                className="popupChildren"
                onClick={async () => {
                  const res = await fetch(
                    `${ENDPOINT}/api/project/update/${params.row.id}/`,
                    {
                      method: 'PUT',
                      headers: {
                        Authorization: `JWT ${access_token}`,
                        'Content-Type': 'application/json',
                      },
                      body: JSON.stringify({
                        status: 'active',
                        creation_wizard_completed: true,
                      }),
                    }
                  );
                  const data = await res.json()
                  if (!res.ok) {

                    if (data?.title?.['Title Error']) {
                      for (let i in data.title) {
                        let msg = data[i]
                        setErrorState({
                          error: true,
                          message: data && data?.title ? data?.title?.[i] : 'Could not Activate Your Project.',
                        });
                      }
                    }
                    else if (data?.['create project']) {

                      for (let i in data) {
                        let msg = data[i]
                        setAlertMessage(msg)
                      }
                      SetAlertShow(true)
                    }
                    else {
                      for (let i in data) {

                        let msg = data[i]
                        setErrorState({
                          error: true,
                          message: data && msg ? msg : 'Could not Activate Your Project.',
                        });
                      }
                    }
                    return
                  }
                  else {
                    dispatch(getProjects({ access_token }))
                    setIsOpen('')
                    setArgs('all')
                  }
                }}
              >
                <LuServer style={{ marginRight: '5px' }} />

                {params.row.project_type === 'project' ? (
                  'Activate Project'
                ) : (
                  'Activate Retainer'
                )}
              </li> : null}
              <li
                className="popupChildren"
                onClick={() => {
                  setCurrentDlt(params.row);
                  setSureDuplicate(true);
                  setIsOpen('')
                }}
              >
                <MdControlPointDuplicate style={{ marginRight: '5px', fontSize: '20px' }} /> Duplicate
              </li>

              <li
                className="popupChildren LastChild"
                onClick={async () => {
                  setCurrentDlt(params.row)
                  setSureDelete(true)
                  setIsOpen('')
                }}
                style={{ color: 'red' }}
              >
                <img src={DeleteIcon} className="icon-sm" style={{ marginLeft: '-5px' }} />
                Delete Selected
              </li>
            </ul>
          </Popup>
        </div>
      ),
    },
  ];

  const rows =
    Array.isArray(projects) && projects.length
      ? projects.map((project) => ({
        id: project.id,
        total_loe: project.total_loe,
        name: project.title,
        project_type: project.project_type,
        company: project.company,
        target_start: project.target_start,
        target_end: project.finish_date,
        current_hour: project.current_hour,
        total_hour: project.total_hour,
        teams: [icon1, icon2, icon3],
        deliverable_project: project.deliverable_project,
        status: project.status ? project.status : 'Active',
        unique_id: project.unique_id,
        creation_wizard_completed: project?.creation_wizard_completed
      }))
      : [];


  const courses = useSelector((state) => state.NetNetU.courses)?.filter(item => item.course_type === 'help_videos')
  const welcomeChapters = courses.flatMap(course => {
    return course.chapters?.filter(chapter => chapter.title === 'Project');
  });
  const [show, setShow] = useState(false)

  /////////////////////////////////////////////////////////////////////////

  const [AlertShow, SetAlertShow] = useState(false)
  const [AlertMessage, setAlertMessage] = useState('')

  return Array.isArray(myArr) && myArr.length ? (
    <>
      {
        AlertShow &&
        <ActiveProjectAlert SetAlertShow={SetAlertShow} AlertMessage={AlertMessage} setAlertMessage={setAlertMessage} />
      }
      <Bounce left>
        <section className="oppor-section">
          <div className="oppor-row">
            <div className="oppor-col">
              <h3 className="fstxtPro">Jobs</h3>
              <img className="icon-sm" src={Dots} alt="dots" />

              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  background: 'inherit',
                  border: 'none',
                  outline: 'none',
                  flexDirection: 'column',
                }}
              >
                <button
                  style={{
                    background: 'inherit',
                    border: 'none',
                    outline: 'none',
                  }}
                  onClick={() => {
                    setFilterOption(true);
                  }}
                  id="filter"
                >
                  <img id="filter" className="icon-sm" src={Gear} alt="gear" />
                </button>
              </div>
              <p>Filters</p>
              <img src={helpVid} onClick={() => { setShow(true) }} style={{ color: '#a6a6a6', marginLeft: '10px', width: '28px', height: '28px', cursor: 'pointer' }} />
            </div>
            <button className=" create-btn" onClick={() => setShowModal(true)}>
              + New
            </button>
          </div>
          <PrevBox
            setSearchQuery={setSearchQuery}
            searchQuery={searchQuery}
            args={args}
            setArgs={setArgs}
          />
          <Box
            sx={{
              width: '100%',
              minHeight: screenHeight - 0,
              maxHeight: screenHeight - 0,
              overflow: 'auto',
            }}
          >

            <DataGridPro
              rows={rows}
              columns={columns}
              disableColumnMenu
              components={{

                ColumnSortedAscendingIcon: IoMdArrowDropdown,
                ColumnSortedDescendingIcon: IoMdArrowDropup,
              }}
              rowHeight={60}
              sx={{

                "& .MuiDataGrid-root": {
                  borderRadius: "8px"
                },
                minHeight: screenHeight - 220,
                maxHeight: screenHeight - 220,
                overflow: "auto",
                borderRadius: "8px"
              }} />
          </Box>
        </section>
      </Bounce>
      <HelpVideo show={show} setShow={setShow} welcomeChapters={welcomeChapters} />
      {sureDelete && <Delete setSureDelete={setSureDelete} currentTarget={currentDlt} />}
      {sureDuplicate && <Duplicate setSureDuplicate={setSureDuplicate} currentTarget={currentDlt} myArr2={myArr} />}
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}

        onClick={() => setShowModal(false)} open={showModal}>
        <InfoCreate setShowModal={setShowModal} menuRef={menuRef} />
      </Backdrop>
      <Offcanvas
        show={filterOption}
        onHide={() => setFilterOption(false)}
        scroll={false}
        backdrop
        placement="bottom "
      >
        <Offcanvas.Header>
          <h4 className="sub-heading">Project Filters</h4>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <section className="filterBody">
            <FormGroup
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-around',
                width: '50%',
              }}
            >
              <div className="fstxt">Status</div>

              <FormControlLabel
                control={
                  <Switch
                    checked={filterBase.includes('active')}
                    color="secondary"
                    onChange={(e) => {
                      if (!e.target.checked) {
                        setFilterBase(
                          filterBase.filter((item) => item !== 'active')
                        );
                      } else {
                        setFilterBase([...filterBase, 'active']);
                      }
                    }}
                    sx={{
                      '&.Mui-checked': {
                        color: '#711fff',
                      },
                      '&.Mui-checked:hover': {
                        backgroundColor: '#711fff',
                      },
                    }}
                  />
                }
                label={<div className="fstxt">Active</div>}
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={false}
                    color="secondary"
                    onChange={(e) => {
                      if (!e.target.checked) {
                        setFilterBase(
                          filterBase.filter((item) => item !== 'archived')
                        );
                      } else {
                        setFilterBase([...filterBase, 'archived']);
                      }
                    }}
                    sx={{
                      '&.Mui-checked': {
                        color: '#711fff',
                      },
                      '&.Mui-checked:hover': {
                        backgroundColor: '#711fff',
                      },
                    }}
                  />
                }
                label={<div className="fstxt">Archived</div>}
              />
            </FormGroup>
          </section>
          <section className="dFlex">
            <button
              style={{ marginTop: '1rem' }}
              className="close-modal"
              onClick={() => setFilterOption(false)}
            />
            <section className="AlRight">
              <button
                className="cancel-btn"
                onClick={() => {
                  setFilterOption(false);
                }}
              >
                Cancel
              </button>
              <button
                className="create-btn"
                onClick={(e) => {
                  setProjects(
                    projects?.filter((item) =>
                      filterBase.includes(item.contact_type)
                    )
                  );
                }}
              >
                Apply
              </button>
            </section>
          </section>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  ) : (
    <>
      <section className="oppor-section">
        <div className="oppor-row">
          <div className="oppor-col">
            <h3 className="fstxtPro">Jobs</h3>
            <img className="icon-sm" src={Dots} alt="dots" />
            <img className="icon-sm" src={Gear} alt="gear" />
            <p>Filters</p>
            <img src={helpVid} onClick={() => { setShow(true) }} style={{ color: '#a6a6a6', marginLeft: '10px', width: '28px', height: '28px', cursor: 'pointer' }} />
          </div>
        </div>
        <section className="myCenter" style={{ height: screenHeight - 155 }}>
          <article className="box-txt">
            <h3 className="txtCenter">No Job yet</h3>
            <p className="txtCenter">All Your Job Would Appear Here!</p>
            <button className="btn" onClick={() => setShowModal(true)}>
              Create JOB
            </button>
          </article>
        </section>
      </section>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        onClick={() => setShowModal(false)} open={showModal}>
        <InfoCreate setShowModal={setShowModal} menuRef={menuRef} />
      </Backdrop>
      <HelpVideo show={show} setShow={setShow} welcomeChapters={welcomeChapters} />
    </>
  );
};
export default Project;
