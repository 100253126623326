import { Grid, Box, TextField, Alert } from '@mui/material';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ENDPOINT } from '../../pages/Opportunity/Redux/oppor';
import mailIco from './Assets/mailIco.png';
import MobileLogo from './Assets/MobileLogo.png';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link } from 'react-router-dom';
import { Header } from '../../Componenets/Auth/Login/Auth';

const Intro = () => {
  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const handleResize = () => {
    setScreenSize(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      {screenSize < 900 && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            width: '200px',
            margin: '1rem 0',
          }}
        >
          <img src={MobileLogo} alt="logo" width="280px" height="auto" />
        </Box>
      )}

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          width: { lg: '60%', md: '70%', xs: '100%' },
          padding: '0 1.5rem',
        }}
      >
        <Link exact to="/Login">
          <ArrowBackIcon
            sx={{
              position: 'absolute',
              top: { xl: '10%', lg: '7%', md: '7%', sm: '7%', xs: '7%' },
              left: { xl: '62%', lg: '62%', md: '60%', sm: '2%', sx: '2%' },
              color: '#711fff',
              cursor: 'pointer',
            }}
          />
        </Link>
        <p className="sub-heading">Resend Activation Link</p>
      </Box>
    </>
  );
};

const PwdForm = ({ setRecievedEmail }) => {
  const [Useremail, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [succ, setSucc] = useState(false);
  const handleEmail = (e) => {
    setEmail(e.target.value);
  };

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError(false);
      }, 3600);
    }

    if (succ) {
      setTimeout(() => {
        setSucc(false);
      }, 10000);
    }
  }, [error]);

  const anotherEmail = useSelector((state) => state.auth.prevEmail);

  useEffect(() => {
    if (anotherEmail) {
      setEmail(anotherEmail);
    }
  }, [anotherEmail]);

  const handleSubmittion = async (e) => {
    e.preventDefault();
    setLoading(true);
    const response = await fetch(`${ENDPOINT}/auth/users/resend_activation/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: Useremail,
      }),
    });

    if (!response.ok) {
      setLoading(false);
      setError(true);
      setErrorMessage('Something went wrong, please try again later');
    } else {
      setLoading(false);
      setSucc(true);
    }
  };
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: { lg: 'flex-start', md: 'flex-start', xs: 'center' },
        width: { lg: '60%', md: '70%', xs: '100%' },
        padding: '0 1.5rem',
      }}
    >
      <TextField
        type="email"
        variant="outlined"
        sx={{
          width: '100%',
          margin: '0.90rem 0rem',
          fontWeight: '600',
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: '#ADADAD',
            },
            '&:hover fieldset': {
              borderColor: '#711fff',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#5a0bdf',
            },

            '&.Mui-error fieldset': {
              borderColor: 'red',
            },

            '&.Mui-disabled fieldset': {
              borderColor: 'grey',
            },
          },
          '& .MuiFormLabel-root': {
            color: '#03071e66',
          },
          '& .MuiFormLabel-root.Mui-focused': {
            color: '#5a0bdf',
          },
          '& .MuiFormLabel-root.Mui-error': {
            color: 'red',
          },
          '& .css-1d3z3hw-MuiOutlinedInput-notchedOutline': {
            top: '-1px',
          },
        }}
        label="Email Address"
        onChange={handleEmail}
        value={Useremail}
        onInvalid={(e) => {
          e.target.setCustomValidity('Please Enter a Valid Email Address');
        }}
        onInput={(e) => {
          e.target.setCustomValidity('');
          e.target.reportValidity();
          setErrorMessage('');
          setError(false);
        }}
      />

      {error && (
        <Alert severity="error" sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      )}
      {succ && (
        <Alert severity="success" sx={{ width: '100%' }}>
          An Activation link has been successfully sent.
        </Alert>
      )}

      <button className="btn inline" type="submit" onClick={handleSubmittion}>
        {loading ? <i className="bx bx-loader-alt bx-spin" /> : 'RESET'}{' '}
      </button>
    </Box>
  );
};

const TextContent = () => {
  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const handleResize = () => {
    setScreenSize(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <>
      {screenSize < 900 && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            width: '200px',
            margin: '1rem 0',
          }}
        >
          <img src={MobileLogo} alt="logo" width="280px" height="auto" />
        </Box>
      )}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          width: { lg: '60%', md: '70%', xs: '100%' },
          padding: '0 1.5rem',
        }}
      >
        <Link exact to="/Login">
          <ArrowBackIcon
            sx={{
              position: 'absolute',
              top: { xl: '10%', lg: '7%', md: '7%', sm: '7%', xs: '7%' },
              left: { xl: '62%', lg: '62%', md: '60%', sm: '2%', sx: '2%' },
              color: '#711fff',
              cursor: 'pointer',
            }}
          />
        </Link>
        <img src={mailIco} alt="Vector" className="icon" />
        <p className="sub-heading">Check Your Email</p>
        <p className="fstxt">
          {' '}
          We Have sent you an email with further Instructions to reset your
          password.
        </p>
      </Box>
    </>
  );
};

const ResendEmail = () => {
  const [EmailRecieved, setRecievedEmail] = useState(false);
  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const handleResize = () => {
    setScreenSize(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return EmailRecieved ? (
    <Grid container alignItems="center" sx={{ height: '100vh' }}>
      {screenSize < 900 && <Header />}
      <Grid
        item
        className="register-illustration"
        xs={0}
        sm={0}
        md={7}
        lg={7}
        xl={7}
      />
      <Grid
        item
        container
        xs={12}
        sm={12}
        md={5}
        lg={5}
        xl={5}
        sx={{
          gap: '0.45rem',
          padding: '0.45rem 0',
        }}
        direction="column"
        alignItems="center"
      >
        <TextContent />
      </Grid>
    </Grid>
  ) : (
    <Grid container>
      {screenSize < 900 && <Header />}
      <Grid
        item
        className="register-illustration"
        xs={0}
        sm={0}
        md={7}
        lg={7}
        xl={7}
      />

      <Grid
        item
        container
        xs={12}
        sm={12}
        md={5}
        lg={5}
        xl={5}
        sx={{
          gap: '0.45rem',
          padding: '0.45rem 0',
          height: '100vh',
          justifyContent: 'center',
        }}
        direction="column"
        alignItems="center"
      >
        <Intro />
        <PwdForm setRecievedEmail={setRecievedEmail} />
      </Grid>
    </Grid>
  );
};

export default ResendEmail;
