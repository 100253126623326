import { useEffect, useState, useRef, version } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Grid, Alert, Button, Typography } from '@mui/material';
import FirstStep from './First';
import ThirdStep from '../estSteps/ThirdStep';
import FourthStep from './Fourth';
import Fifth, { calculatePercentage } from './Fifth';
import SixthStep from '../estSteps/SixthStep';
import { PreLoading } from '../../Contacts/People/EditContact';
import { ENDPOINT, getEstimates, getPaymentTerms, getTermAndCondtion } from '../Redux/oppor';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import CheckIcon from '@mui/icons-material/Check';
import TextsmsOutlinedIcon from '@mui/icons-material/TextsmsOutlined';
import ChatEst from '../ChatEst';
import PreLoader, { NetNetSwitch } from '../../../Componenets/Misc';
import { formatMoneyString } from '../estSteps/SixthStep';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import 'rsuite/dist/rsuite.min.css';
import Slider from '@mui/material/Slider';
import { ViewMode } from 'gantt-task-react';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import DescriptionIcon from '@mui/icons-material/Description';
import DescriptionEstSixthStep from '../DescriptionEstSixthStep';
import NotesIcon from '@mui/icons-material/Notes';
import Final from './Final';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import moment from 'moment';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import { storeStep } from '../Redux/oppor';
import helpVid from '../assets/Section_Help-idle.svg'
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Offcanvas from 'react-bootstrap/Offcanvas';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { getTemplates } from '../../User/redux';
import { useReactToPrint } from 'react-to-print';
import dayjs from 'dayjs';
import { DateCalendar, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import EstHelpVideos from './EstHelpVideos';
import axios from 'axios';



const step = [
  {
    label: 'Summary',
  },

  // {
  //   label: 'Collaboration',
  // },
  {
    label: 'Level of Efforts',
  },
  {
    label: 'Timeline',
  },
  {
    label: 'Pricing',
  },
  {
    label: 'Net Net',
  },
  {
    label: 'Estimate',
  },
];


const calculateTotalPrice = (array) => {

  return array.reduce((accumulator, item) => {
    return accumulator + Number(item?.price_to_customer);
  }, 0);
};

const EstHeader = ({ screenSize, setShowAllTasks, showAllTasks, setShowAllDescription, showAllDescription, setOpenCanva, setIsDetOpen }) => (
  <section
    style={{
      border: '1px solid #D9D9D9',
      padding: '0.7rem .5rem',
      borderRadius: '8px',
      width: { xl: '42%', lg: '50%', sm: '55%', md: '60%' },
      backgroundColor: '#e7deff',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-around',
      height: '100px',
      marginRight: '1.3rem',
      boxShadow: '1px 1px 1.5px rgba(0, 0, 0, 0.3)',
    }}
  >
    <Box
      style={{
        display: 'flex',
        flexDirection: 'row',
        marginTop: '10px',
        justifyContent: 'start',
        alignItems: 'center',
        gap: '.8rem',
      }}
    >
      <button className="netnetc-btn-act2" onClick={(e) => {
        e.preventDefault();
        setOpenCanva(true);
        setIsDetOpen(false)
      }}>Deliverable Templates</button>

    </Box>{' '}
    <Box
      style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start' }}
    >
      <NetNetSwitch checked={showAllTasks}
        onChange={(e) => {
          if (showAllTasks) {
            setShowAllTasks(false)

          }
          else {
            setShowAllTasks(true)

          }
        }}
        label={screenSize < 900 ? 'Tasks' : 'Show all Tasks'} />
      <NetNetSwitch
        onChange={(e) => {
          if (showAllDescription) {
            setShowAllDescription(false)

          }
          else {
            setShowAllDescription(true)

          }
        }}
        label={screenSize < 900 ? 'Descriptions' : 'Show all Description '}
      />
    </Box>
  </section>
);

const BudgetCheck = ({ dileverable, formData, setTracker, tracker, setGI, currentTarget, GI, estimate_hardcost, budgetLoading, setGlobleTotalCost }) => {


  const [totalCost, setTotalCost] = useState(0);

  const sumUserPrices = (deliverable) => {
    if (!deliverable || !Array.isArray(deliverable) || !deliverable.length) {
      return 0;
    }

    return deliverable.reduce((acc, item) => {
      const userPrice = Number(item.user_price);
      if (!isNaN(userPrice)) {
        acc += userPrice;
      }
      return acc;
    }, 0);
  };

  useEffect(() => {
    let cost = 0;

    if (!tracker) {
      if (dileverable && Array.isArray(dileverable) && dileverable.length) {
        const hasAllUserPriceDefined = dileverable.every((item) => {
          return Number(item.user_price) >= 0;
        });

        if (!hasAllUserPriceDefined) {
          const result = dileverable.reduce((acc, curr) => {
            curr?.deliverable_worktype?.forEach((item) => {
              if (acc[item.id]) {
                acc[item.id].hours += Number(item.hours ? item.hours : 0);
              } else {
                acc[item.id] = { id: item.id, hours: Number(item.hours ? item.hours : 0) };
              }
            });
            return acc;
          }, {});

          const finalResult = Object.values(result);
          finalResult.forEach((item) => {
            const matchingType = formData?.work_type.find((type) => type.id === item.id);
            if (matchingType) {
              cost += item.hours * matchingType.user_rate;
            }
          });

          if (Array.isArray(dileverable[0].deliverable_multiplier)) {
            if (Array.isArray(estimate_hardcost) && estimate_hardcost.length) {
              setGI(calculateTotalPrice(estimate_hardcost) + calculatePercentage(dileverable) + cost);
              setTotalCost(calculateTotalPrice(estimate_hardcost) + calculatePercentage(dileverable) + cost);
              setGlobleTotalCost(calculateTotalPrice(estimate_hardcost) + calculatePercentage(dileverable) + cost);
            }
            else {
              setGI(calculatePercentage(dileverable) + cost);
              setTotalCost(calculatePercentage(dileverable) + cost);
              setGlobleTotalCost(calculatePercentage(dileverable) + cost);
            }
          }
          else {
            setGI(cost);
            setTotalCost(cost);
            setGlobleTotalCost(cost)
          }

        } else {
          const rawCost = sumUserPrices(dileverable);
          if (Array.isArray(dileverable[0]?.deliverable_multiplier)) {
            if (Array.isArray(estimate_hardcost) && estimate_hardcost.length) {
              setGI(calculateTotalPrice(estimate_hardcost) + calculatePercentage(dileverable) + rawCost);
              setTotalCost(calculateTotalPrice(estimate_hardcost) + calculatePercentage(dileverable) + rawCost);
              setGlobleTotalCost(calculateTotalPrice(estimate_hardcost) + calculatePercentage(dileverable) + rawCost);
            }
            else {
              setGI(calculatePercentage(dileverable) + rawCost);
              setTotalCost(calculatePercentage(dileverable) + rawCost);
              setGlobleTotalCost(calculatePercentage(dileverable) + rawCost);

            }
          }
          else {
            setGI(rawCost);
            setTotalCost(rawCost);
            setGlobleTotalCost(rawCost)

          }
        }
      } 
    } else {
      setGI(tracker);
      setTotalCost(tracker);
      setGlobleTotalCost(tracker)
    }
  }, [dileverable, formData, estimate_hardcost]);

  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const handleResize = () => {
    setScreenSize(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  return formData && formData.type === 'project' ? (
    <Box
      sx={{
        boxShadow: '1px 1px 3px  rgba(0, 0, 0, 0.3)',
        width: { xl: '30%', lg: '31%', md: '32%', sm: '33%' },
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        padding: '10px',
        marginRight: '5px',
        borderRadius: '10px',
        alignItems: 'center',
        padding: '0.7rem .5rem',
        padding: {
          xl: '0.7rem .5rem',
          lg: '0.98rem .5rem',
          md: '0.60rem .5rem',
          sm: '0.60rem .5rem',
        },
      }}
    >
      <div>
        <Typography
          sx={{
            color: '#9257fa',
            fontSize: { xl: '13px', lg: '12px', md: '11px', sm: '10px' },
          }}
        >
          {' '}
          Total
        </Typography>
        <Typography
          sx={{
            fontWeight: 'bold',
            fontSize: { xl: '13px', lg: '12px', md: '11px', sm: '10px' },
          }}
        >
          {budgetLoading ? <Typography sx={{ fontWeight: '500', color: "green", fontStyle: 'italic' }}> Calculating... </Typography> : <Typography sx={{ fontWeight: '500', color: totalCost > formData.budget ? 'red' : 'black', fontStyle: 'italic' }}>{totalCost ? '$' + totalCost?.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : GI ? '$' + GI?.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '$0'}</Typography>}
        </Typography>
      </div>
      <div style={{ borderLeft: '1px solid gray', paddingLeft: '15px' }}>
        <Typography
          sx={{
            color: '#9257fa',
            fontSize: { xl: '13px', lg: '12px', md: '11px', sm: '10px' },
          }}
        >
          {screenSize < 1100 ? null : 'Target '}Budget
        </Typography>
        <Typography
          sx={{
            color: 'gray',
            fontWeight: 'bold',
            fontSize: { xl: '13px', lg: '12px', md: '11px', sm: '10px' },
          }}
        >
          {formData.budget ? formatMoneyString(`${formData.budget}`) : '0'}
        </Typography>
      </div>
    </Box>
  ) : (
    <Box
      sx={{
        boxShadow: '1px 1px 3px  rgba(0, 0, 0, 0.3)',
        width: { xl: '30%', lg: '33%', md: '33%', sm: '35%' },
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        padding: '10px',
        marginRight: '5px',
        borderRadius: '10px',
        alignItems: 'center',
        padding: '0.7rem .5rem',
        padding: {
          xl: '0.7rem .5rem',
          lg: '0.98rem .5rem',
          md: '0.60rem .5rem',
          sm: '0.60rem .5rem',
        },
      }}
    >
      <div>
        <Typography
          sx={{
            color: '#9257fa',
            fontSize: { xl: '13px', lg: '12px', md: '11px', sm: '10px' },
          }}
        >
          {' '}
          Total
        </Typography>
        <Typography
          sx={{ fontSize: { xl: '13px', lg: '12px', md: '10px', sm: '10px' } }}
        >
          {budgetLoading ? <Typography sx={{ fontWeight: '500', color: 'green', fontStyle: 'italic' }}> Calculating... </Typography> : <Typography sx={{ fontWeight: '500', color: totalCost > formData.budget ? 'red' : 'black', fontStyle: 'italic' }}>{totalCost ? formatMoneyString(`${totalCost}`) + '/month' : '$0'}  </Typography>}
        </Typography>
      </div>
      <div style={{ borderLeft: '1px solid gray', paddingLeft: '15px' }}>
        <Typography
          sx={{
            color: '#9257fa',
            fontSize: { xl: '13px', lg: '12px', md: '11px', sm: '10px' },
          }}
        >
          {screenSize < 1100 ? null : 'Target '}Budget
        </Typography>
        <Typography
          sx={{
            color: 'gray',
            fontWeight: 'bold',
            fontSize: { xl: '13px', lg: '12px', md: '11px', sm: '10px' },
          }}
        >
          {formData?.budget ? formatMoneyString(`${formData.budget}`) + '/month' : '0'}
        </Typography>
      </div>
    </Box>
  );
};

const VerticalLinearSteppe = ({ setSteps, steps, isColab, isDetOpen, formData, stepsRedux, UpdateRequest4THSTEP, dileverable, setIsDetOpen, captureChartSnapshot }) => {
  const [activeStep, setActiveStep] = useState(0);
  const dispatch = useDispatch()

  const [show, setShow] = useState(true);
  const [showChatEst, setShowChatEst] = useState(false);
  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const handleResize = () => {
    setScreenSize(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  let stepsNums = 0

  return isDetOpen ? (
    <Box sx={{ zIndex: 9999 }}>
      <Box
        className="firstMenu"
        sx={{
          backgroundColor: 'white',
          padding: '1rem 0rem',
          borderRadius: '10px',
          boxShadow: '1px 1px 5px rgba(0, 0, 0, 0.5);',
          display: show ? 'flex' : 'none',
          jusitfyContent: 'center',
          position: 'fixed',
          top: '19%',
          opacity: show ? 1 : 0,
          Zindex: show ? 9999 : 1,
          transition: 'opacity 0.3s ease-in-out',
          height: show ? 'auto' : '0',
        }}
      >
        <Stepper
          activeStep={activeStep}
          orientation="vertical"
          sx={{ margin: '0 auto', width: '90%' }}
        >
          {step.map((step, index) => {
            if (formData?.type !== 'project' && index === 2) {
              return;
            }
            return (
              <Step key={step.label}>
                <button
                  onClick={() => {
                    if (Array.isArray(dileverable) && dileverable.length) {
                      if (stepsRedux === 4) {
                        UpdateRequest4THSTEP(index + 1)
                        return;
                      }
                      dispatch(storeStep(index + 1))
                      captureChartSnapshot()
                    }
                    else {
                      if (index + 1 < stepsRedux) {
                        dispatch(storeStep(index + 1))
                      }
                      else {
                        return;
                      }
                    }
                  }}
                  style={{
                    gap: 10,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: '100%',
                    borderRadius: '10px',
                    border: 'none',
                    backgroundColor:
                      stepsRedux === index + 1
                        ? '#cbb6fc'
                        : stepsRedux > index + 1
                          ? '#7323fa'
                          : '#f6f5f7',
                    height: '40px',
                    cursor: 'pointer',
                    paddingLeft: '8px',
                  }}
                >
                  <CheckIcon
                    style={{
                      color:
                        stepsRedux === index + 1
                          ? '#af8dfc'
                          : stepsRedux > index + 1
                            ? 'white'
                            : '#efe8ff',
                    }}
                  />
                  <Typography
                    style={{
                      backgroundColor: 'white',
                      borderRadius: '20px',
                      padding: '1px 3px',
                      fontSize: '12px',
                    }}
                  >
                    {++stepsNums}
                  </Typography>
                  <Typography
                    style={{
                      color:
                        stepsRedux === index + 1
                          ? '#171717'
                          : stepsRedux > index + 1
                            ? 'white'
                            : '#575656',
                      fontSize: '12px',
                      fontWeight: '500',
                    }}
                  >
                    {step.label}
                  </Typography>
                </button>
              </Step>
            )
          })}
        </Stepper>
      </Box>


      <Box
        className="secondMenu"
        sx={{
          marginLeft: '5px',
          position: 'fixed',
          top: { xl: '19%', lg: '20%', sm: '21%', md: '20.5%' },
        }}
      >
        <p
          onClick={() => setShow(!show)}
          style={{
            cursor: 'pointer',
            writingMode: 'vertical-rl',
            textOrientation: 'revert-layer',
            padding: '15px 2px',
            backgroundColor: show ? '#ac7cfc' : '#c6a7fa',
            borderStartEndRadius: screenSize > 1200 && '12px',
            borderTopRightRadius: screenSize > 1200 && '12px',
            borderEndEndRadius: screenSize < 1200 && '12px',
            borderEndStartRadius: screenSize < 1200 && '12px',
            color: show ? 'white' : 'black',
            letterSpacing: '0.1rem',

          }}
        >
          STEPS
        </p>
        <Box
          sx={{
            padding: '15px 2px',
            fontSize: '10px',
            color: 'white',
            backgroundColor: '#ac7cfc',
            borderStartStartRadius: screenSize < 1200 && '12px',
            borderTopRightRadius: screenSize > 1200 && '12px',
            borderEndEndRadius: screenSize > 1200 && '12px',
            borderEndStartRadius: screenSize < 1200 && '12px',
            marginTop: '5px',
            width: screenSize < 1200 ? '23px' : '27px',
            cursor: 'pointer',
          }}
          onClick={() => {
            setShowChatEst(true)
            setIsDetOpen(false)
          }}
        >
          <TextsmsOutlinedIcon color="white" />
        </Box>
      </Box>

    </Box>
  ) : (showChatEst ? <ChatEst formData={formData} setShowChatEst={setShowChatEst} setIsDetOpen={setIsDetOpen} /> : null);
};

const DilTemplate = ({
  dileverable,
  setDeliverable,
  setOpenCanva,
  openCanva,
  formData,
  calculateLOERate,
  calculateLOERateName,
  allWorktypes
}) => {
  const [tableData, setTableData] = useState([]);
  const access_token = useSelector((state) => state.auth.user.access);
  const dispatch = useDispatch();
  const realTemplates = useSelector((state) => state.Misc.mainTemplates);

  useEffect(() => {
    dispatch(getTemplates({ access_token }));
  }, [access_token]);

  useEffect(() => {
    if (Array.isArray(realTemplates) && realTemplates.length) {
      setTableData(realTemplates);
    }
  }, [realTemplates]);

  const [selected, setSelected] = useState([]);

  function removeDuplicateNames(array) {
    const uniqueNamesMap = new Map();

    array.forEach(item => {
      const { name, hours, id } = item;

      if (!uniqueNamesMap.has(name)) {
        uniqueNamesMap.set(name, { name, hours, id });
      }
    });

    return Array.from(uniqueNamesMap.values());
  }


  return (
    <Offcanvas
      placement="bottom"
      show={openCanva}
      onHide={() => setOpenCanva(false)}

    >
      <Offcanvas.Header>


        <Grid item container justifyContent={'flex-end'} alignItems="center" sx={{ position: 'relative' }}>
          <button
            style={{
              background: '#c6a5ff',
              display: 'flex',
              alignItems: 'center',
              gap: '1rem',
              borderRadius: '8px',
              position: 'absolute',
              top: '7%',
              right: '20px',
              paddingRight: '20px',
              marginTop: "1rem"
            }}
            className="noBorder"
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '1rem',
                padding: '0.88rem 1.44rem',
                borderRight: '1px solid #000',
                color: 'black',
                margintop: "1rem"
              }}
              onClick={async () => {
                var i = 0;
                const allDeliverables = dileverable.concat(selected);

                const consolidated = allDeliverables.map((deliverable) => {
                  const existingWorktypeNames = deliverable.deliverable_worktype.map(
                    (worktype) => worktype.name
                  );

                  const missingWorktypes = allDeliverables
                    .filter((otherDeliverable) => otherDeliverable !== deliverable)
                    .flatMap((otherDeliverable) =>
                      otherDeliverable.deliverable_worktype
                        .filter((worktype) => !existingWorktypeNames.includes(worktype.name))
                        .map((missingWorktype) => ({
                          name: missingWorktype.name,
                          hours: 0, // Set hours to zero for missing worktypes,
                          id: missingWorktype.id
                        }))
                    );

                  const updatedWorktypes = [
                    ...deliverable.deliverable_worktype,
                    ...missingWorktypes,
                  ];

                  return { ...deliverable, deliverable_worktype: updatedWorktypes };
                });

                // Iterate through initialDeliverables and remove duplicate names
                const updatedDeliverables = consolidated.map(deliverable => ({
                  ...deliverable,
                  deliverable_worktype: removeDuplicateNames(deliverable.deliverable_worktype).sort(),

                }));


                updatedDeliverables.forEach(deliverable => {
                  const deliverableWorktypes = deliverable.deliverable_worktype;

                  deliverable.task_deliverable.forEach(taskDeliverable => {
                    const existingWorktypes = new Set(taskDeliverable.task_worktype.map(worktype => worktype.name));

                    deliverableWorktypes.forEach(deliverableWorktype => {
                      if (!existingWorktypes.has(deliverableWorktype.name)) {
                        const newTaskWorktype = {
                          name: deliverableWorktype.name,
                          id: deliverableWorktype.id,
                          // Other properties from deliverable_worktype that you want to include
                        };

                        // Add the new task worktype to the task worktypes array in the current task_deliverable
                        taskDeliverable.task_worktype.push(newTaskWorktype);

                        // Add the worktype name to the existing worktypes set
                        existingWorktypes.add(deliverableWorktype.name);
                      }
                    });
                  });
                });
                var i = 0;
                for (i; i < dileverable.length; i++) {
                  const response = await fetch(
                    `${ENDPOINT}/api/project/deliverable/delete/${dileverable[i].id}/`,
                    {
                      method: 'DELETE',
                      headers: {
                        Authorization: `JWT ${access_token}`,
                        'Content-Type': 'application/json',
                      },
                    }
                  );
                  if (response.ok) {
                  }
                  else {
                    const data = await response.json()
                  }
                }
                for (var j = 0; j < updatedDeliverables.length; j++) {
                  const res = await fetch(
                    `${ENDPOINT}/api/project/estimate/deliverable/create/`,
                    {
                      method: 'POST',
                      headers: {
                        Authorization: `JWT ${access_token}`,
                        'Content-Type': 'application/json',
                      },
                      body: JSON.stringify({
                        deliverable_estimate: formData.id,
                        deliverable_template: updatedDeliverables[j]?.deliverable_template,
                        deliverable_name: updatedDeliverables[j].deliverable_name,
                        description: updatedDeliverables[j].description ?? "",
                        sort_order: j + 1,
                        deliverable_worktype: updatedDeliverables[j].deliverable_worktype.map((i, n) => {
                          return {
                            name: i.name,
                            id: i.id,
                            hours: i.hours ? i.hours : null,
                          };
                        }),
                        deliverable_task: Array.isArray(updatedDeliverables[j].task_deliverable) && updatedDeliverables[j].task_deliverable.length ?
                          updatedDeliverables[j].task_deliverable.map((tsk) => {
                            return (
                              {
                                name: tsk.task_name,
                                task_worktypes: tsk.task_worktype.map((wt_tsk) => {
                                  return (
                                    {
                                      id: wt_tsk.id,
                                      hours: wt_tsk.hours ? wt_tsk.hours : 0,
                                    }
                                  )
                                })
                              }
                            )
                          }) : [],
                        deliverable_multiplier: [],

                        deliverable_startdate: formData.est_startdate,
                        deliverable_enddate: new Date(new Date(formData.est_startdate).setDate(new Date(formData.est_startdate).getDate() + 7)),
                        user_price: calculateLOERateName(updatedDeliverables[j], allWorktypes)
                      }),
                    }
                  );
                  const data = await res.json();
                  if (!res.ok) {

                    return;
                  }
                  setOpenCanva(false)
                }

                const resGet = await fetch(`${ENDPOINT}/api/opportunity/estimate/${formData?.unique_id}/`, {
                  method: 'GET',
                  headers: {
                    'Content-Type': 'application/json',
                    Authorization: `JWT ${access_token}`,
                  },
                });
                const dataGet = await resGet.json();
                if (
                  dataGet &&
                  Array.isArray(dataGet.deliverable) &&
                  dataGet.deliverable.length
                ) {
                  const sortedDeliverable = dataGet.deliverable.map((deliverable) => ({
                    ...deliverable,
                    deliverable_worktype: deliverable.deliverable_worktype.sort((a, b) =>
                      a.name.localeCompare(b.name)
                    ),
                  })).sort((a, b) => a.sort_order - b.sort_order);

                  setDeliverable(sortedDeliverable);
                }
                const deliverablePayloads = [];
                for (var j = 0; j < updatedDeliverables.length; j++) {
                  const payload = {
                    deliverable_estimate: formData.id,
                    deliverable_name: updatedDeliverables[j].deliverable_name,
                    description: updatedDeliverables[j].description ?? "",
                    sort_order: j + 1,
                    deliverable_worktype: updatedDeliverables[j].deliverable_worktype.map((i, n) => {
                      return {
                        name: i.name,
                        id: i.id,
                        hours: i.hours ? i.hours : null,
                      };
                    }),
                    deliverable_task: Array.isArray(updatedDeliverables[j].task_deliverable) && updatedDeliverables[j].task_deliverable.length ?
                      updatedDeliverables[j].task_deliverable.map((tsk) => {
                        return (
                          {
                            name: tsk.task_name,
                            task_worktypes: tsk.task_worktype.map((wt_tsk) => {
                              return (
                                {
                                  id: wt_tsk.id,
                                  hours: wt_tsk.hours ? wt_tsk.hours : 0,
                                }
                              )
                            })
                          }
                        )
                      }) : [],
                    deliverable_multiplier: [],
                    deliverable_startdate: formData.est_startdate,
                    deliverable_enddate: new Date(new Date(formData.est_startdate).setDate(new Date(formData.est_startdate).getDate() + 7)),
                    user_price: calculateLOERateName(updatedDeliverables[j], allWorktypes)
                  };
                  deliverablePayloads.push(payload);
                }

              }}
            >
              <ContentCopyIcon sx={{ marginRight: "4px " }} />
              Add To Estimate
            </Box>
            <Box>
              {Array.isArray(selected) && selected.length
                ? selected.length + ' ' + 'Selected'
                : null}
            </Box>
          </button>
        </Grid>
      </Offcanvas.Header>

      <Offcanvas.Body style={{ marginTop: "2rem" }} >
        <Grid container direction="column" >
          <Grid item container spacing={5}>
            {Array.isArray(tableData) && tableData.length ? (
              tableData.map((e) => {

                const disrapancy = selected.findIndex(
                  (ie) => Number(ie?.id) === Number(e?.deliverable[0]?.id)
                );
                return (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    xl={3}
                    xxl={2}
                    onClick={() => {
                      if (disrapancy !== -1) {
                        setSelected((prev) =>
                          prev.filter((item) => !prev[disrapancy])
                        );
                      } else {
                        setSelected([...selected, e.deliverable[0]]);
                      }
                    }}
                  >
                    <Box
                      sx={{
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'white',
                        borderRadius: '8px',
                        padding: '1rem',
                        alignItems: 'center',
                        border: '1px solid lightGray',
                        borderTop: 0,
                        borderLeft: 0,
                        cursor: 'pointer',
                      }}
                    >
                      <ul
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          padding: '0.6rem',
                          marginBottom: '1rem',
                          backgroundColor:
                            disrapancy !== -1 ? '#c6a5ff' : '#f1f3f5',
                          borderRadius: '8px',
                          padding: '0.5rem',
                        }}
                      >
                        <li
                          style={{
                            paddingLeft: '0px',
                            marginBottom: '0px',
                          }}
                        >
                          <h5
                            style={{
                              marginBottom: '0px',

                            }}
                            key={e.id}
                          >
                            {' '}
                            {e.name}
                          </h5>
                          <p
                            style={{
                              fontSize: '12px',
                              marginBottom: '0px',
                              color: '#8743fd',
                            }}
                          >
                            {' '}
                            {e?.deliverable[0]?.task} Tasks
                          </p>
                        </li>

                      </ul>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-around',
                          backgroundColor: '#e4ddf0',
                          borderRadius: ' 8px',
                          height: '2rem',
                          width: '7rem',
                          color: 'black',
                        }}
                      >
                        <AccessTimeOutlinedIcon
                          style={{
                            fontSize: '1.3rem',
                          }}
                        />
                        <div>
                          <span
                            style={{
                              fontSize: '0.9rem',
                            }}
                          >
                            {' '}
                            {e?.duration}
                          </span>{' '}
                          <span
                            style={{
                              fontSize: '0.9rem',
                            }}
                          >
                            Hours{' '}
                          </span>
                        </div>
                      </div>
                      <div
                        style={{
                          backgroundColor: '#f8f9fa',
                          borderRadius: '8px',
                          padding: '4px',
                          marginTop: '1rem',
                        }}
                      >
                        <h6
                          style={{
                            marginBottom: '0px',
                          }}
                        >
                          Description:
                        </h6>
                        <p
                          style={{
                            marginBottom: '0px',
                          }}
                          dangerouslySetInnerHTML={{
                            __html: e?.description ? e?.description : '<p> </p>',
                          }}
                        ></p>
                      </div>
                    </Box>
                  </Grid>
                );
              })
            ) : (
              <Grid
                container
                item
                sx={{ height: '100%', width: '100%', margin: '1rem 0' }}
                direction="column"
                alignItems={'center'}
                justifyContent="center"
              >
                <Alert severity="info">You do not have any Templates. </Alert>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Offcanvas.Body>
    </Offcanvas>
  );
};


const EstDetail = () => {
  const { id } = useParams();
  const stepsRedux = useSelector((item) => item.oppertunity.storedNumber)
  const estId = id;
  const [formData, setFormData] = useState();
  const [steps, setSteps] = useState(1);
  const [temp, setTemp] = useState();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const access_token = useSelector((state) => state.auth.user.access);
  const [GI, setGI] = useState(0);
  const [tracker, setTracker] = useState(0);
  const [isDetOpen, setIsDetOpen] = useState(true);
  const dispatch = useDispatch()
  const [tasks, setTasks] = useState([]);
  const [buttonLoading, setButtonLoading] = useState(false)
  const [budgetLoading, setBudgetLoading] = useState(false)
  const [openCanva, setOpenCanva] = useState(false);
  const allWorktypes = useSelector((item) => item.Misc.workTypes)
  const [currentImageVersion, setCurrentImageVersion] = useState(null)

  function dataURItoBlob(dataURI) {
    const binStr = atob(dataURI.split(',')[1]);
    const arr = new Uint8Array(binStr.length);
    for (let i = 0; i < binStr.length; i++) {
      arr[i] = binStr.charCodeAt(i);
    }
    const blob = new Blob([arr], { type: 'image/png' });
    return blob;
  }

  const [currentTarget, setCurrentTarget] = useState()


  useEffect(() => {
    const fetchData = async () => {

      const res = await fetch(`${ENDPOINT}/api/opportunity/estimate/${id}/`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `JWT${access_token}`,
        },
      });
      const data = await res.json();

      setCurrentTarget(data)
      setFormData(data)
    };

    fetchData()
    setTimeout(() => {
      if (!formData && !currentTarget) {
        fetchData()
      }
      setLoading(false);

    }, 1500);
  }, []);

  const [dileverable, setDeliverable] = useState([]);


  useEffect(() => {
    if (
      currentTarget &&
      Array.isArray(currentTarget.deliverable) &&
      currentTarget.deliverable.length
    ) {
      const sortedDeliverable = currentTarget.deliverable.map((deliverable) => ({
        ...deliverable,
        deliverable_worktype: deliverable.deliverable_worktype.sort((a, b) =>
          a.name.localeCompare(b.name)
        ),
      })).sort((a, b) => a.sort_order - b.sort_order);
      setDeliverable(sortedDeliverable);


      if (
        currentTarget &&
        Array.isArray(currentTarget.estimate_hardcost) &&
        currentTarget.estimate_hardcost.length
      ) {
        setEstHardCost(currentTarget.estimate_hardcost);
      }
    }
  }, [currentTarget]);


  const calculateLOERate = (deliverable, work) => {
    if (
      !deliverable ||
      !Array.isArray(deliverable.deliverable_worktype) ||
      !deliverable.deliverable_worktype.length ||
      !Array.isArray(work) ||
      !work.length
    ) {
      return 0;
    }

    let total = 0;
    deliverable.deliverable_worktype.forEach((worktype) => {
      const matchingWork = work.find((item) => item.name === worktype.name);

      if (matchingWork) {
        total += Number(matchingWork.user_rate) * Number(worktype.hours ? worktype.hours : 0);
      }
    });

    return total;
  };

  const calculateLOERateName = (deliverable, work) => {
    if (
      !deliverable ||
      !Array.isArray(deliverable.deliverable_worktype) ||
      !deliverable.deliverable_worktype.length ||
      !Array.isArray(work) ||
      !work.length
    ) {
      return 0;
    }

    let total = 0;
    deliverable.deliverable_worktype.forEach((worktype) => {

      const matchingWork = work.find((item) => item.name === worktype.name);

      if (matchingWork) {
        total += Number(matchingWork.user_rate) * Number(worktype.hours ? worktype.hours : 0);
      }
    });

    return total;
  };


  const UpdateRequest = async (check, finalPrice) => {

    if (Array.isArray(dileverable) && !dileverable.length) {
      setError(true)
      setErrorMessage('Atleast one Deliverable is Required to go ahead')
      return;
    }
    const shallowCopy = {
      ...formData,
    };

    delete shallowCopy.work_type;
    delete shallowCopy.estimate_hardcost;
    setButtonLoading(check ? false : true)
    const initially = new Date('11/11/2080').toISOString();

    const leastDate = dileverable.reduce((minDate, dil) => {
      if (dil.deliverable_startdate < minDate) {
        return dil.deliverable_startdate;
      } else {
        return minDate;
      }
    }, initially);
    const durationEst = new Date(formData.est_enddate) - new Date(formData.est_startdate)

    const totalDays = durationEst / (24 * 60 * 60 * 1000)
    const res = await fetch(
      `${ENDPOINT}/api/opportunity/update/estimate/${currentTarget.id}/`,
      {
        method: 'PUT',
        headers: {
          Authorization: `JWT ${access_token}`,
          'Content-Type': 'application/json',
        },
        body:
          Array.isArray(formData.collaborator) && formData.collaborator.length
            ? JSON.stringify({
              ...shallowCopy,
              actual: finalPrice ?? GI ?? globleTotalCost ?? actualCalculate(dileverable, allWorktypes),
              estimate_deliverable: dileverable?.map(
                (item) => {
                  delete item.deliverable_multiplier
                  delete item.task_deliverable
                  return {
                    ...item,
                    deliver_id: item.id,
                    user_price: item.user_price && item.user_price > calculateLOERate(item, allWorktypes) || item.user_price < calculateLOERate(item, allWorktypes) ? item.user_price : calculateLOERate(item, allWorktypes),
                    deliverable_enddate: item.deliverable_enddate,
                    deliverable_startdate: item.deliverable_startdate,
                    deliverable_worktype: item.deliverable_worktype.map(
                      (wtt) => ({
                        ...wtt,
                        hours: wtt.hours ? Number(wtt.hours) : null,
                      })
                    ),
                  };
                }
              ),

              collaborator: formData?.collaborator?.map((item) => item?.id),
            })
            : JSON.stringify({
              ...shallowCopy,
              actual: finalPrice ?? GI ?? globleTotalCost ?? actualCalculate(dileverable, allWorktypes),
              est_startdate: leastDate,
              est_enddate: formData.est_enddate ? new Date(new Date(leastDate).setDate(new Date(leastDate).getDate() + totalDays)) : null,
              estimate_deliverable: dileverable?.map(
                (item) => {

                  const start = moment();
                  const end = start.add(7, 'days').toISOString();
                  delete item.deliverable_multiplier
                  delete item.task_deliverable
                  const durationInSec = new Date(item.deliverable_enddate) - new Date(item.deliverable_startdate)
                  const durationInDays = durationInSec / (24 * 60 * 60 * 1000)
                  const startDateOfDil = item?.deliverable_dependency ? dileverable.find(dil => dil.id === item?.deliverable_dependency).deliverable_enddate : item.deliverable_startdate
                  const endDateOfDil = item?.deliverable_dependency ? new Date(new Date(startDateOfDil).setDate(new Date(startDateOfDil).getDate() + durationInDays)).toISOString() : item.deliverable_enddate
                  return {
                    ...item,
                    user_price: item.user_price && item.user_price > calculateLOERate(item, allWorktypes) || item.user_price < calculateLOERate(item, allWorktypes) ? item.user_price : calculateLOERate(item, allWorktypes),
                    deliver_id: item.id,
                    deliverable_enddate: item.deliverable_enddate,
                    deliverable_startdate: item.deliverable_startdate,
                    deliverable_worktype: item.deliverable_worktype.map(
                      (wtt) => ({
                        ...wtt,
                        hours: wtt.hours ? Number(wtt.hours) : null,
                      })
                    ),
                  };

                }
              ),

            }),
      }
    );

    const data = await res.json();
    if (!res.ok) {
      setError(true);
      setErrorMessage('Couldn’t Update');
      setButtonLoading(false)
      return;
    }
    else {
      const resGet = await fetch(`${ENDPOINT}/api/opportunity/estimate/${formData?.unique_id}/`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `JWT ${access_token}`,
        },
      });
      const dataGet = await resGet.json();
      setFormData(dataGet)
      if (
        dataGet &&
        Array.isArray(dataGet.deliverable) &&
        dataGet.deliverable.length
      ) {
        const sortedDeliverable = dataGet.deliverable.map((deliverable) => ({
          ...deliverable,
          deliverable_worktype: deliverable.deliverable_worktype.sort((a, b) =>
            a.name.localeCompare(b.name)
          ),
        })).sort((a, b) => a.sort_order - b.sort_order);

        setDeliverable(sortedDeliverable);
      }
      setButtonLoading(false)
      if (!check) {
        dispatch(storeStep(formData.type === 'project' ? 4 : 5))
      }
      dispatch(getEstimates({ access_token }))
    }
  }

  setTimeout(() => {
    setError(false)
    setErrorMessage('')
  }, 5000);

  const UpdateRequest4THSTEP = async (step) => {
    dispatch(storeStep(step))

  }

  function actualCalculate(deliverable, allWorktypes) {
    const total = deliverable.map((item) => {
      const subTotal = item.deliverable_worktype.reduce((subAcc, acc) => {
        const matchingWork = allWorktypes.find((ele) => ele.name === acc.name);

        subAcc += acc.hours ? Number(acc.hours) * Number(matchingWork?.user_rate) : 0;
        return subAcc; // Add this return statement
      }, 0);

      return subTotal; // Add this return statement
    });
    return total.reduce((sub, acc) => { return sub + acc }, 0);
  }



  const UpdateRequest1stStep = async () => {
    const shallowCopy = {
      ...formData,
    };
    const dileverable_copy = [...dileverable];
    delete shallowCopy.work_type;
    delete shallowCopy.estimate_hardcost;
    const res = await fetch(
      `${ENDPOINT}/api/opportunity/update/estimate/${currentTarget.id}/`,
      {
        method: 'PUT',
        headers: {
          Authorization: `JWT ${access_token}`,
          'Content-Type': 'application/json',
        },
        body:
          Array.isArray(formData.collaborator) && formData.collaborator.length
            ? JSON.stringify({
              ...shallowCopy,
              contact: formData.contact,
              company: formData.company,
              actual: formData?.actual ?? GI ?? actualCalculate(dileverable, allWorktypes),

              estimate_deliverable: dileverable?.map(
                (item) => {
                  const start = moment();
                  const end = start.add(7, 'days').toISOString();
                  delete item.task_deliverable
                  if (item.deliverable_multiplier) {
                    return {
                      ...item,
                      user_price: item.user_price && item.user_price > calculateLOERate(item, currentTarget.work_type) ? item.user_price : calculateLOERate(item, currentTarget.work_type),
                      deliverable_startdate: moment().toISOString(),
                      deliver_id: item.id,
                      deliverable_enddate: item.deliverable_enddate,
                      deliverable_multiplier: item.deliverable_multiplier.map((multi, mindex) => ({
                        multiplier_id: multi.id,
                        label: multi.label,
                        rate: multi.rate,
                        operation: multi.operation
                      })),
                      deliverable_worktype: item.deliverable_worktype.map(
                        (wtt) => ({
                          ...wtt,
                          hours: wtt.hours ? Number(wtt.hours) : null,
                        })
                      ),
                    };
                  }
                  else {
                    return {
                      ...item,
                      user_price: item.user_price && item.user_price > calculateLOERate(item, currentTarget.work_type) ? item.user_price : calculateLOERate(item, currentTarget.work_type),
                      deliverable_startdate: item.deliverable_startdate,

                      deliver_id: item.id,
                      deliverable_enddate: item.deliverable_enddate,

                      deliverable_worktype: item.deliverable_worktype.map(
                        (wtt) => ({
                          ...wtt,
                          hours: wtt.hours ? Number(wtt.hours) : null,
                        })
                      ),
                    };
                  }
                }
              ),

              collaborator: formData?.collaborator?.map((item) => item?.id),
            })
            : JSON.stringify({
              ...shallowCopy,

              contact: formData.contact ?? null,
              company: formData.company ?? null,
              actual: formData?.actual ?? GI ?? actualCalculate(dileverable, allWorktypes),
              estimate_deliverable: dileverable?.map(
                (item) => {
                  const start = moment();
                  const end = start.add(7, 'days').toISOString();
                  delete item.deliverable_multiplier
                  if (item.task_deliverable) {
                    return {
                      ...item,

                      user_price: item.user_price && item.user_price > calculateLOERate(item, currentTarget.work_type) ? item.user_price : calculateLOERate(item, currentTarget.work_type),
                      deliverable_startdate: item.deliverable_startdate,

                      deliver_id: item.id,
                      deliverable_enddate: item.deliverable_enddate,
                      task_deliverable: item.task_deliverable.map((tsk) => {
                        return {
                          ...tsk,
                          task_id: tsk.id,
                          task_name: tsk.task_name,
                          task_worktype: tsk.task_worktype.map((twt) => ({
                            ...twt,
                            hours: twt.hours ? twt.hours : null,
                          }))
                        }
                      }),
                      deliverable_worktype: item.deliverable_worktype.map(
                        (wtt) => ({
                          ...wtt,
                          hours: wtt.hours ? Number(wtt.hours) : 0,
                        })
                      ),
                    };
                  }
                  else {
                    return {
                      ...item,

                      user_price: item.user_price && item.user_price > calculateLOERate(item, currentTarget.work_type) ? item.user_price : calculateLOERate(item, currentTarget.work_type),
                      deliverable_startdate: item.deliverable_startdate,

                      deliver_id: item.id,
                      deliverable_enddate: item.deliverable_enddate,

                      deliverable_worktype: item.deliverable_worktype.map(
                        (wtt) => ({
                          ...wtt,
                          hours: wtt.hours ? Number(wtt.hours) : null,
                        })
                      ),
                    };
                  }
                }
              ),

            }),
      }
    );

    const data = await res.json();
    if (!res.ok) {
      setError(true);
      setErrorMessage('Couldn’t Update');
      return;
    }
    if (Array.isArray(formData.collaborator) && formData.collaborator.length) {
      dispatch(storeStep(2))
      return;
    }
    else {
      dispatch(storeStep(2))
    }
    dispatch(getEstimates({ access_token }))
  }


  const handleFirstStep = (e) => {
    e.preventDefault();

    UpdateRequest1stStep()



  };

  const handleSecondStep = () => {
    if (dileverable.length > 0) {
      if (formData?.type !== 'project') {
        dispatch(storeStep(4))
      } else {
        dispatch(storeStep(3))
      }
    }
  };

  const handleThirdStep = (e) => {
    // e.preventDefault();
    UpdateRequest();

  };

  const handleFourthStep = (e) => {
    e.preventDefault();
    const check = true;
    UpdateRequest(check);
    UpdateRequest4THSTEP(5)

  };

  const handleFifthStep = (e) => {
    e.preventDefault();

    dispatch(storeStep(6))
  };

  /*  

{
        
      }
  */
  const handleSixthStep = (e) => {
    e.preventDefault();

    dispatch(storeStep(7))
  };


  const handleUpdate = async (e) => {
    dispatch(storeStep(1))
    navigate('/Opportunities');
  };

  const handleFinalStep = (e) => {
    e.preventDefault();
    dispatch(storeStep(7))
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (stepsRedux === 1) {
      handleFirstStep(e);
    }
    if (stepsRedux === 2) {

      handleSecondStep(e);
    }

    if (stepsRedux === 3) {
      UpdateRequest();
      captureChartSnapshot();
    }
    if (stepsRedux === 4) {
      setButtonLoading(true)
      handleFourthStep(e);
      setButtonLoading(false)

      captureChartSnapshot();
    }
    if (stepsRedux === 5) {
      handleFifthStep(e);
    }
    if (stepsRedux === 6) {
      handleUpdate(e);
    }
  };



  function checkCollaborators(collaborators, user) {
    for (let i = 0; i < collaborators.length; i++) {
      const collaborator = collaborators[i];
      if (collaborator.collab_detail === user.id) {
        return true;
      }
    }
    return false;
  }


  function captureChartSnapshot() {
    // find the Gantt chart element by class name
    const chartElement = document.querySelector('.gantt-target');

    if (!chartElement) {
      return;
    }

    const fullHeight = chartElement.scrollHeight;
    const fullWidth = chartElement.scrollWidth;

    // create a canvas element with the full size of the table
    const canvas = document.createElement('canvas');
    canvas.width = fullWidth;
    canvas.height = fullHeight;
    const context = canvas.getContext('2d');

    // use html2canvas library to take a snapshot of the chart
    html2canvas(chartElement, { backgroundColor: null })
      .then(function (canvas) {
        context.drawImage(canvas, 0, 0);
        const snapshot = canvas.toDataURL('image/png');
        setPic(snapshot);
      })
      .catch(function (error) {
      });

    setButtonLoading(false);
  }


  const user = useSelector((state) => state.userData.userData);
  const [isColab, setIsColab] = useState(false);
  const [num, setNum] = useState(0)



  const [pic, setPic] = useState(null);

  const [estimate_hardcost, setEstHardCost] = useState([]);
  const componentRef = useRef();
  const printDocument = useReactToPrint({
    content: () => componentRef.current,

    pageStyle: `@media print {
      @page { size: A4; }
    } }`
    ,
  });

  const exportPdf = async () => {
    const content = document.getElementById('divToPrint');
    const dpi = 300;
    const canvas = await html2canvas(content, { scale: dpi / 96 });

    const imgData = canvas.toDataURL('image/png', 1.0);
    const doc = new jsPDF('p', 'mm', 'a4');
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();

    // Add the image to the document, splitting it into multiple pages
    doc.addImage(imgData, 'PNG', 0, 0, pageWidth, pageHeight, {
      pageWidth,
      pageHeight,
    });

    doc.save('filename.pdf');
  };


  const [showDescription, setShowDescription] = useState(false);
  const [view, setView] = useState('Month');
  const options = { month: 'short', day: '2-digit', year: '2-digit' };
  const start =
    formData && formData.est_startdate && formData.est_startdate.endsWith('Z')
      ? formData.est_startdate.split('T')
      : null;
  const end =
    formData && formData.est_enddate && formData.est_enddate.endsWith('Z')
      ? formData.est_enddate.split('T')
      : null;
  const [value1, setValue1] = useState([null, null]);

  const [day, setDay] = useState('This Month')

  const [dateRange, setDateRange] = useState(false);

  const handleDateRange = (v) => {
    setDateRange(v)
  }


  const handleClose5 = () => {
    setValue1([null, null])
    setDateRange(false)
  };

  const handleClickDay = (v, i) => {
    setDay(v)
    if (v === 'Today') {
      const today = dayjs();
      setDateRange([today,])
    }
    else if (v === 'Yesterday') {
      const today = dayjs();
      setDateRange([today.add(1, 'day'),])
    }
    else if (v === 'This Week') {
      const today = dayjs();
      setDateRange([today.startOf('week'), today.endOf('week')])
    }
     else if (v === 'Last Week') {
      const today = dayjs();
      const prevWeek = today.subtract(7, 'day');
      setDateRange([prevWeek.startOf('week'), prevWeek.endOf('week')
    ]);

    }
    else if (v === 'This Month') {
      const today = dayjs();
      setDateRange([today.startOf('month'), today.endOf('month')])
    }
    else if (v === 'Last Month') {
      const today = dayjs();
      const startOfNextMonth = today.startOf('month').subtract(1, 'month');
      setDateRange([startOfNextMonth, startOfNextMonth.endOf('month')]);

    }
    else if (v === 'This Year') {
      const today = dayjs();
      setDateRange([today.startOf('year'), today.endOf('year')])

    }
    else if (v === 'Last Year') {
      const today = dayjs();
      setDateRange([today.startOf('year').subtract(1, 'year'), today.endOf('year').subtract(1, 'year')])
    }
  }

  const handleClick = (event) => {

  };

  const handleRangeChange = async (event) => {
    const shallowCopy = {
      ...formData,
    };

    delete shallowCopy.work_type;
    delete shallowCopy.estimate_hardcost;


    const res = await fetch(
      `${ENDPOINT}/api/opportunity/update/estimate/${currentTarget.id}/`,
      {
        method: 'PUT',
        headers: {
          Authorization: `JWT ${access_token}`,
          'Content-Type': 'application/json',
        },
        body:
          Array.isArray(formData.collaborator) && formData.collaborator.length
            ? JSON.stringify({
              ...shallowCopy,
              est_startdate: new Date(new Date(dateRange[0]).setDate(new Date(dateRange[0]).getDate() + 1)).toISOString(),
              est_enddate: new Date(new Date(dateRange[1]).setDate(new Date(dateRange[1]).getDate() + 1)).toISOString(),
              collaborator: formData?.collaborator?.map((item) => item?.id),
            })
            : JSON.stringify({
              ...shallowCopy,
              est_startdate: endOrStart === 'start' ? new Date(new Date(dateRange)).toISOString() : formData?.est_startdate,
              est_enddate: endOrStart === 'end' ? new Date(new Date(dateRange)).toISOString() : formData.est_enddate,
              estimate_deliverable: dileverable?.map(
                (item, index) => {

                  const durationInMilliseconds = new Date(item.deliverable_enddate) - new Date(item.deliverable_startdate)
                  const deliverable_duration = durationInMilliseconds / (24 * 60 * 60 * 1000)

                  const dynamicChange = new Date(item.deliverable_startdate) - new Date(formData.est_startdate)
                  const dynamic_duration = dynamicChange / (24 * 60 * 60 * 1000)
                  delete item.deliverable_multiplier
                  delete item.task_deliverable
                  return {
                    ...item,
                    deliver_id: item.id,
                    deliverable_startdate: endOrStart === 'start' ? new Date(new Date(dateRange).setDate(new Date(dateRange).getDate() + dynamic_duration)).toISOString() : item.deliverable_startdate,
                    deliverable_enddate: endOrStart === 'start' ? new Date(new Date(dateRange).setDate(new Date(dateRange).getDate() + dynamic_duration + deliverable_duration)).toISOString() : item.deliverable_enddate,
                    deliverable_worktype: item.deliverable_worktype.map(
                      (wtt) => ({
                        ...wtt,
                        hours: wtt.hours ? Number(wtt.hours) : null,
                      })
                    ),
                  }
                }),

            }),
      }
    );
    const data = await res.json()
    if (!res.ok) {
      return;
    }
    else {
      const resGet = await fetch(`${ENDPOINT}/api/opportunity/estimate/${formData?.unique_id}/`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `JWT ${access_token}`,
        },
      });
      const dataGet = await resGet.json();
      if (!resGet.ok) {
        return;
      }
      setFormData(dataGet)
      setValue1([null, null]);
      if (
        dataGet &&
        Array.isArray(dataGet.deliverable) &&
        dataGet.deliverable.length
      ) {
        const sortedDeliverable = dataGet.deliverable.map((deliverable) => ({
          ...deliverable,
          deliverable_worktype: deliverable.deliverable_worktype.sort((a, b) =>
            a.name.localeCompare(b.name)
          ),
        })).sort((a, b) => a.sort_order - b.sort_order);
        setDeliverable(sortedDeliverable);
      }
    }

  }
  const [screenSize, setScreenSize] = useState(window.innerWidth);


  const [tat, setTat] = useState(0);

  const [showAllTasks, setShowAllTasks] = useState(false)
  const [showAllDescription, setShowAllDescription] = useState(false)
  const [realOpen, setrealOpen] = useState(false);
  const [versionOpen, setVersionOpen] = useState(false);
  const anchorRef = useRef(null);
  const anchorVRef = useRef(null);

  const handleToggle = () => {
    setrealOpen((prevOpen) => !prevOpen);
  };





  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setrealOpen(false);
  };


  const handleCloseVersion = () => {
    setVersionOpen(false)
  }

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setrealOpen(false);
    } else if (event.key === 'Escape') {
      setrealOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(realOpen);
  useEffect(() => {
    if (prevOpen.current === true && realOpen === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = realOpen;
  }, [realOpen]);


 
  const [editSection, setEditSection] = useState({})
  const [realstate, setRealState] = useState(null)
  const [show, setShow] = useState()
  const [globleTotalCost, setGlobleTotalCost] = useState()
 
  const [endOrStart, setEndOrStart] = useState()

  return !loading && currentTarget && formData ? (
    <section className="oppor-section5" >
      <VerticalLinearSteppe
        steps={steps}
        setSteps={setSteps}
        isColab={isColab}
        isDetOpen={isDetOpen}
        formData={formData}
        stepsRedux={stepsRedux}
        UpdateRequest4THSTEP={UpdateRequest4THSTEP}
        captureChartSnapshot={captureChartSnapshot}
        dileverable={dileverable}
        setIsDetOpen={setIsDetOpen}
      />
      <div className="oppor-row" style={{ width: '100%' }}>
        <div className="oppor-col" style={{ justifyContent: 'flex-start', gap: '10px' }}>
          <h3
            className="fstxtPro"
            style={{ fontSize: screenSize < 1400 ? '20px' : '25px', width: '80%' }}
          >
            {formData?.title ?? 'Estimate Detail'}
          </h3>

          <img src={helpVid} onClick={() => setShow(true)} style={{ color: '#a6a6a6', marginLeft: '10px', width: '28px', height: '28px', cursor: 'pointer' }} />
        </div>
        <div style={{ width: '70%' }}>
          {stepsRedux === 2 ? (
            <div
              style={{ width: '100%', display: 'flex', justifyContent: 'end' }}
            >
              <EstHeader setIsDetOpen={setIsDetOpen} setOpenCanva={setOpenCanva} showAllDescription={showAllDescription} setShowAllDescription={setShowAllDescription} screenSize={screenSize} showAllTasks={showAllTasks} setShowAllTasks={setShowAllTasks} />
              <BudgetCheck
                setGlobleTotalCost={setGlobleTotalCost}
                dileverable={dileverable}
                formData={formData}
                tracker={tracker}
                setTracker={setTracker}
                setGI={setGI}
                currentTarget={currentTarget}
                estimate_hardcost={estimate_hardcost}
                budgetLoading={budgetLoading}

              />
            </div>
          ) : null}
          {stepsRedux === 3 ? (
            <div
              style={{ width: '100%', display: 'flex', justifyContent: 'end' }}
            >
              <Box
                sx={{
                  border: '1px solid #D9D9D9',
                  padding: '0.7rem .5rem',
                  borderRadius: '8px',
                  width: { xl: screenSize < 1650 && screenSize > 1450 ? '50%' : '42%', lg: screenSize < 1320 ? '65%' : '57%', md: '80%', sm: '85%' },
                  backgroundColor: '#e7deff',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  height: '100px',
                  marginRight: '1.3rem',
                  boxShadow: '1px 1px 1.5px rgba(0, 0, 0, 0.3)',
                }}
              >
                <Box
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginTop: '10px',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    color: 'gray',
                  }}
                >

                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      fontSize: '16px',
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >

                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px',
                        width: '100%',
                        justifyContent: 'space-around',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          gap: '5px',
                        }}
                      >
                        <CalendarMonthOutlinedIcon
                          sx={{
                            cursor: 'pointer',
                            fontSize: {
                              xl: '25px',
                              lg: '22px',
                              md: '20px',
                              sm: '20px',
                            },
                          }}
                          onClick={(event) => {
                            handleClick(event)
                            setValue1(null)
                            setDateRange(formData.est_startdate ? dayjs(formData.est_startdate) : new Date())
                            setEndOrStart('start')
                          }}
                        />
                        <Typography
                          sx={{
                            fontSize: {
                              xl: '14px',
                              lg: '13px',
                              md: '12px',
                              sm: '11px',
                            },
                          }}
                        >
                          Start:{' '}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: {
                              xl: '14px',
                              lg: '13px',
                              md: '12px',
                              sm: '11px',
                            },
                          }}
                          style={{ marginTop: '0px', fontWeight: 'normal' }}
                        >
                          {start[0] && start[0]
                            ? new Date(start[0]).toLocaleDateString('en-US', options)
                            : ''}
                        </Typography>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          gap: '5px',
                        }}
                      >
                        <CalendarMonthOutlinedIcon
                          sx={{
                            cursor: 'pointer',
                            fontSize: {
                              xl: '25px',
                              lg: '22px',
                              md: '20px',
                              sm: '20px',
                            },
                          }}
                          onClick={(event) => {
                            handleClick(event)
                            setValue1(null)
                            setDateRange(formData.est_enddate ? dayjs(formData.est_enddate) : new Date())
                            setEndOrStart('end')
                          }}
                        />
                        <Typography
                          sx={{
                            fontSize: {
                              xl: '14px',
                              lg: '13px',
                              md: '12px',
                              sm: '11px',
                            },
                          }}
                        >
                          End:{' '}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: {
                              xl: '14px',
                              lg: '13px',
                              md: '12px',
                              sm: '11px',
                            },
                          }}
                          style={{ marginTop: '0px', fontWeight: 'normal' }}
                        >
                          {end && end[0]
                            ? new Date(end[0]).toLocaleDateString('en-US', options)
                            : ''}
                        </Typography>
                      </div>
                    </div>
                    {
                      !value1 && (
                        <Box sx={{ position: 'absolute', top: '90%', right: 0, zIndex: '99999', background: '#fff' }}>
                          <Box align='center' sx={{ width: 'auto', height: 'auto', px: '30px', display: { xs: 'block', md: 'flex' }, }}>
                            <Box align='left' sx={{ backgroundColor: '#F8F9FA', width: { xs: '100%', md: '200px' }, p: 1, pb: 3, my: 4, borderRadius: 2 }}>
                              {
                                ['Today', 'Yesterday', 'This Week', 'Last Week', 'This Month', 'Last Month', 'This Year', 'Last Year'].map((v, i) => {
                                  return (
                                    <Typography key={i} onClick={() => handleClickDay(v, i)} sx={day === v ?
                                      { fontSize: '16px', fontWeight: 400, cursor: 'pointer', color: '#711FFF', my: 3, ml: 2 }
                                      :
                                      { fontSize: '16px', fontWeight: 400, cursor: 'pointer', color: '#03071E', my: 3, ml: 2 }
                                    }>{v}</Typography>
                                  )
                                })
                              }

                            </Box>
                            <Box sx={{ mx: 4, my: 4 }}>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DateCalendar
                                  value={dayjs(dateRange)}
                                  onChange={(newValue) => handleDateRange(newValue?.$d)}
                                  sx={{
                                    '& .css-10wpov9-MuiTypography-root ': {
                                      fontWeight: '700 !important'
                                    },
                                    '& .css-cyzddl-MuiPickersSlideTransition-root-MuiDayCalendar-slideTransition:not(.MuiDateRangeCalendar-dayDragging) .MuiDateRangePickerDay-dayOutsideRangeInterval': {
                                      color: '#03071E !important',
                                      fontSize: '16px !important',
                                      fontWeight: 500,
                                    },
                                    '& .css-1gbl7yn-MuiDateRangePickerDay-root': {
                                      backgroundColor: '#310085 !important',
                                      color: 'white !important',
                                      borderRadius: '0px !important'
                                    },
                                    '& .css-1e841vg-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day.Mui-selected': {
                                      backgroundColor: '#711FFF !important',
                                      color: "white !important",
                                      borderRadius: '0px !important',
                                      fontSize: '16px !important',
                                      fontWeight: 500
                                    },
                                    '& .css-1ckjanr-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day.Mui-selected': {
                                      backgroundColor: '#711FFF !important',
                                      color: "white !important",
                                      borderRadius: '0px !important',
                                      fontSize: '16px !important',
                                      fontWeight: 500
                                    },
                                    '& .css-1u23akw-MuiButtonBase-root-MuiPickersDay-root.Mui-selected': {
                                      backgroundColor: '#711FFF !important',
                                      color: "white !important",
                                      borderRadius: '0px !important',
                                      fontSize: '16px !important',
                                      fontWeight: 500
                                    },
                                    '& .css-2ko3hu-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day': {
                                      backgroundColor: '#310085 !important',
                                      color: "white !important",
                                      opacity: 'inherit !important',
                                      borderRadius: '0px',
                                      border: 'none !important',
                                      fontSize: '16px !important',
                                      fontWeight: 500
                                    },
                                    '& .css-1ku4sqv-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day': {
                                      backgroundColor: '#711FFF !important',
                                      color: "white !important",
                                      borderRadius: '0px !important',
                                      fontSize: '16px !important',
                                      fontWeight: 500
                                    },
                                    '& .css-ahwqre-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day:not(.Mui-selected)': {
                                      backgroundColor: '#310085 !important',
                                      color: "#e569db !important",
                                      borderRadius: '0px !important',
                                      fontSize: '16px !important',
                                      fontWeight: 500
                                    },
                                    '& .css-jef1b6-MuiDateRangeCalendar-container:not(:last-of-type)': {
                                      borderRight: 'none !important'
                                    },
                                    '& .css-3wduhr-Mu.iDateRangeCalendar-root': {
                                      flexDirection: 'column !important',
                                    },


                                    '& .css-grqin-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day': {
                                      color: 'inherit',
                                      fontSize: '16px !important',
                                      opacity: 'inherit',
                                      fontWeight: 500
                                    },
                                    '& .css-1kex3oi-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day:not(.Mui-selected)': {
                                      backgroundColor: 'inherit',
                                      color: "black !important",
                                      borderRadius: '50%',
                                      border: "1px solid gray",
                                      fontSize: '16px',
                                      fontWeight: 500
                                    },
                                    '& .MuiTypography-subtitle1': {
                                      color: " #03071E !important",
                                      fontSize: '16px',
                                      fontWeight: 700
                                    },
                                    '& .MuiDayCalendar-weekDayLabel': {
                                      color: " #03071ECC !important",
                                      fontSize: '16px',
                                      fontWeight: 400
                                    },
                                    '& .MuiSvgIcon-fontSizeInherit': {
                                      backgroundColor: 'black',
                                      color: 'white',
                                      borderRadius: '50%'
                                    },
                                    '& .MuiPickersDay-today': {
                                      backgroundColor: !dateRange && '#711FFF',
                                      color: !dateRange && 'white',
                                      borderRadius: !dateRange && '0px',
                                      fontSize: !dateRange && '16px',
                                      fontWeight: !dateRange && 500

                                    },
                                    '& .css-grqin-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day.Mui-selected': {
                                      backgroundColor: '#711FFF',
                                      color: 'white',
                                      borderRadius: '0px',
                                      fontSize: '16px',
                                      fontWeight: 500
                                    },
                                    '& .css-gtjfra-MuiDateRangePickerDay-root': {
                                      backgroundColor: '#310085',
                                      color: 'white !important',
                                      borderRadius: '0px',
                                      fontSize: '16px',
                                      fontWeight: 500
                                    },
                                    '& .css-1i2r8k1-MuiDateRangePickerDay-root': {
                                      backgroundColor: '#310085',
                                      color: 'white !important',
                                      borderRadius: '0px',
                                      fontSize: '16px',
                                      fontWeight: 500
                                    },
                                  }}

                                />
                              </LocalizationProvider>

                              <Box sx={{ display: 'flex', my: 4 }}>
                                <Box sx={{ display: 'flex', mr: 4 }}>
                                  <Box sx={{ width: "20px", height: "20px", backgroundColor: "#711FFF", borderRadius: 2, mr: 2 }}></Box>
                                  <Typography sx={{ fontWeight: 400, fontSize: '14px' }}>Start Date and End Date</Typography>
                                </Box>
                                <Box sx={{ display: 'flex' }}>
                                  <Box sx={{ width: "20px", height: "20px", backgroundColor: "#310085", borderRadius: 2, mr: 2 }}></Box>
                                  <Typography sx={{ fontWeight: 400, fontSize: '14px' }}>Date Range</Typography>
                                </Box>
                              </Box>

                            </Box>
                            <Box align='' my={3} sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "" }} >
                              <Box align='left' mt={4}>
                                <Box align='left'>
                                  <Typography sx={{ color: '#03071E99', fontSize: '16px', fontWeight: 400 }}>Start Date</Typography>
                                  <Typography sx={{ color: '#03071E', fontSize: '16px', fontWeight: 400 }}> {endOrStart === 'start' ? new Date(dateRange).toLocaleDateString('en-US', options) : start && start[0]
                                    ? new Date(start[0]).toLocaleDateString('en-US', options)
                                    : ''}</Typography>
                                </Box>
                                <Box sx={{ mt: 2 }}>
                                  <Typography sx={{ color: '#03071E99', fontSize: '16px', fontWeight: 400 }}>End Date</Typography>
                                  <Typography sx={{ color: '#03071E', fontSize: '16px', fontWeight: 400 }}> {endOrStart === 'end' ? new Date(dateRange).toLocaleDateString('en-US', options) : end && end[0]
                                    ? new Date(end[0]).toLocaleDateString('en-US', options)
                                    : ''}</Typography>
                                </Box>
                              </Box>


                              <Box align='right'>
                                <Button variant='text' sx={{ textDecoration: 'inherit', color: 'black', width: { xs: 'auto', lg: '100px' }, mr: 1 }} onClick={handleClose5}>Cancel</Button>
                                <Button variant='contained' sx={{
                                  textDecoration: 'inherit', backgroundColor: "#711FFF", width: { xs: 'auto', lg: '100px' }, mr: 1,
                                  '&:hover': {
                                    backgroundColor: "#711FFF"
                                  }
                                }}
                                  onClick={() => {
                                    handleRangeChange()
                                  }}
                                >Update</Button>
                              </Box>

                            </Box>

                          </Box>
                        </Box>
                      )
                    }
                  </div>
                </Box>{' '}
                <Box
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    gap: '1rem',
                    alignItems: 'center',
                    color: 'gray',
                  }}
                >

                  <p
                    style={{
                      fontSize: '25px',
                      marginTop: '0px',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      if (view === 'Week') {
                        setView('Year');
                      } else if (view === 'Year') {
                        setView('Month');
                      } else {
                        setView('Week');
                      }
                    }}
                  >
                    -
                  </p>
                  <Slider
                    aria-label="Temperature"
                    defaultValue={0}
                    value={
                      view === 'Week' ? 0 : view === 'Month' ? 1 : view === 'Year' ? 2 : 4
                    }

                    valueLabelDisplay="auto"
                    valueLabelFormat={(value) => {
                      const label = ['Week', 'Month', 'Year']
                      return label[value]
                    }}
                    step={1}
                    min={0}
                    max={2}
                    sx={{
                      color: 'gray',
                      marginTop: "0.5rem",
                      width: "70%"
                    }}
                    onChange={(e) => {

                      if (e.target.value === 0) {
                        setView('Week');
                      }
                      if (e.target.value === 1) {
                        setView('Month');
                      }
                      if (e.target.value === 2) {
                        setView('Year');
                      }

                    }}
                  />{' '}
                  <p
                    style={{
                      fontSize: '25px',
                      marginTop: '0px',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      if (view === ViewMode.Week) {
                        setView(ViewMode.Month);
                      } else if (view === ViewMode.Year) {
                        setView(ViewMode.Day);
                      }
                      else if (view === ViewMode.Month) {
                        setView(ViewMode.Year);
                      }
                      else {
                        setView(ViewMode.Week);
                      }
                    }}
                  >
                    +
                  </p>
                </Box>
              </Box>
              <BudgetCheck
                setGlobleTotalCost={setGlobleTotalCost}
                formData={formData}
                dileverable={dileverable}
                tracker={tracker}
                setTracker={setTracker}
                setGI={setGI}
                estimate_hardcost={estimate_hardcost}
                budgetLoading={budgetLoading}
              />
            </div>
          ) : null}

          {stepsRedux === 4 ? (
            <div style={{ width: '100%', display: 'flex' }}>
              <div
                style={{
                  width: '70%',
                  justifyContent: 'end',
                  display: 'flex',
                  alignItems: 'end',
                  marginRight: '1rem',
                }}
              >
              </div>
              <BudgetCheck
                setGlobleTotalCost={setGlobleTotalCost}
                formData={formData}
                dileverable={dileverable}
                tracker={tracker}
                setTracker={setTracker}
                setGI={setGI}
                estimate_hardcost={estimate_hardcost}
                budgetLoading={budgetLoading}
              />
            </div>
          ) : null}

          {stepsRedux === 5 ? (
            <div style={{ width: '100%', display: 'flex' }}>
              <div style={{ width: '70%', marginRight: '1rem' }}></div>
              <BudgetCheck
                setGlobleTotalCost={setGlobleTotalCost}
                formData={formData}
                dileverable={dileverable}
                tracker={tracker}
                setTracker={setTracker}
                setGI={setGI}
                estimate_hardcost={estimate_hardcost}
                budgetLoading={budgetLoading}
              />
            </div>
          ) : null}

          {stepsRedux === 6 ? (
            <div
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'end',
              }}
            >
              <Box
                sx={{
                  border: '1px solid #D9D9D9',
                  borderRadius: '8px',
                  width: { xl: '35%', lg: '50%', md: '50%', sm: '70%' },
                  backgroundColor: '#e7deff',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-around',
                  marginRight: '1.3rem',
                  boxShadow: '1px 1px 1.5px rgba(0, 0, 0, 0.3)',
                  padding: {
                    xl: '1rem 1rem',
                    lg: '1rem 0.7rem',
                    md: '.5rem 0.2rem',
                    sm: '.4rem 0.2rem',
                  },
                  cursor: 'pointer',
                }}
              >
                <Box
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginTop: '10px',
                    justifyContent: 'center',
                    color: 'gray',
                    gap: '.8rem',
                  }}
                >
                  <LocalPrintshopIcon
                    sx={{
                      cursor: 'pointer',
                      fontSize: {
                        xl: '25px',
                        lg: '24px',
                        md: '22px',
                        sm: '20px',
                      },
                    }}
                    onClick={printDocument}
                  />
                  <DescriptionIcon
                    ref={anchorRef}
                    id="composition-button"
                    aria-controls={realOpen ? 'composition-menu' : undefined}
                    aria-expanded={realOpen ? 'true' : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}
                    sx={{
                      cursor: 'pointer',
                      fontSize: {
                        xl: '25px',
                        lg: '24px',
                        md: '22px',
                        sm: '20px',
                      },
                    }}
                  />
                  <Popper
                    open={realOpen}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    placement="bottom-start"
                    transition
                    disablePortal
                  >
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{
                          transformOrigin:
                            placement === 'bottom-start' ? 'left top' : 'left bottom',
                        }}
                      >
                        <Paper>
                          <ClickAwayListener onClickAway={handleClose}>
                            <MenuList
                              autoFocusItem={realOpen}
                              id="composition-menu"
                              aria-labelledby="composition-button"
                              onKeyDown={handleListKeyDown}
                            >
                              <MenuItem onClick={() => {
                                exportPdf();
                                handleClose(e)
                              }}>Export as PDF
                              </MenuItem>
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                  <Button
                    sx={{
                      color: 'black',
                      padding: {
                        xl: '0rem 1rem',
                        lg: '0rem 1rem',
                        md: '0rem .5rem',
                        sm: '0rem .5rem',
                      },
                      borderRadius: {
                        xl: '10px',
                        lg: '10px',
                        md: '8px',
                        sm: '5px',
                      },
                      backgroundColor: '#cbb6fc',
                      border: 'none',
                      fontSize: {
                        xl: '15px',
                        lg: '13px',
                        md: '11px',
                        sm: '9px',
                      },
                      fontWeight: '500',
                      letterSpacing: { xl: '1px', lg: '0.5px' },
                      display: 'flex',
                      alignItems: 'center',
                    }}
                    onClick={() => {
                      setShowDescription(true)
                      setIsDetOpen(false)
                    }}
                  >
                    <NotesIcon
                      sx={{
                        marginRight: '10px',
                        fontSize: {
                          xl: '18px',
                          lg: '15px',
                          md: '13px',
                          sm: '11px',
                        },
                      }}
                    />
                    ADD SECTION
                  </Button>
                </Box>{' '}
              </Box>
              {showDescription ? <DescriptionEstSixthStep editSection={editSection} setEditSection={setEditSection} setFormData={setFormData} setDeliverable={setDeliverable} access_token={access_token} formData={formData} setIsDetOpen={setIsDetOpen} showDescription={showDescription} setShowDescription={setShowDescription} /> : null}
              <BudgetCheck
                stepsRedux={stepsRedux}
                setGlobleTotalCost={setGlobleTotalCost}
                formData={formData}
                steps={steps}
                tracker={tracker}
                setTracker={setTracker}
                setGI={setGI}
                GI={GI}
                dileverable={dileverable}
                estimate_hardcost={estimate_hardcost}
                budgetLoading={budgetLoading}
              />
            </div>
          ) : null}
        </div>
      </div>

      <section
        className={steps < 6 ? 'container-psU-p5' : null}
        style={steps !== 6 ? { padding: '2rem 1.5rem' } : {}}
      >
        {stepsRedux === 1 && (
          <FirstStep
            dileverable={dileverable}
            setDeliverable={setDeliverable}
            formData={formData}
            allWorktypes={allWorktypes}
            calculateLOERateName={calculateLOERateName}
            setFormData={setFormData}

            amIColab={
              Array.isArray(formData.collaborator) &&
                formData.collaborator.length
                ? checkCollaborators(formData.collaborator, user)
                : false
            }
          />
        )}

        {/* if conditions is true render 2nd stepp (Collaboration feature)
        ThirdStep is not being rendered becouse Collaboration is not ready */}
        {stepsRedux === 2 && (
          <ThirdStep
            formData={formData}
            setFormData={setFormData}
            steps={steps}
            dileverable={dileverable}
            setDeliverable={setDeliverable}
            setIsDetOpen={setIsDetOpen}
            num={num}
            setNum={setNum}
            estId={estId}
            showAllTasks={showAllTasks}
            setShowAllDescription={setShowAllDescription}
            showAllDescription={showAllDescription}
            setBudgetLoading={setBudgetLoading}
            allWorktypes={allWorktypes}
          />
        )}


        {stepsRedux === 3 && (
          <FourthStep
            formData={formData}
            setFormData={setFormData}
            temp={temp}
            setTemp={setTemp}
            dileverable={dileverable}
            setDeliverable={setDeliverable}
            view={view}
            setView={setView}
            tasks={tasks}
            setTasks={setTasks}
          />
        )}

        {stepsRedux === 4 && (
          <Fifth
            globleTotalCost={globleTotalCost}

            formData={formData}
            setFormData={setFormData}
            dileverable={dileverable}
            setDeliverable={setDeliverable}
            tracker={tracker}
            setTracker={setTracker}
            estimate_hardcost={estimate_hardcost}
            setEstHardCost={setEstHardCost}
            currentTarget={currentTarget}
            setGI={setGI}
            GI={GI}
            UpdateRequest={UpdateRequest}
          />
        )}






        {stepsRedux === 5 && (
          <SixthStep
            formData={formData}
            dileverable={dileverable}
            GI={GI}
            estimate_hardcost={estimate_hardcost}
            setGI={setGI}
          />
        )}

        {!isColab && stepsRedux === 6 && (
          <Final
            imageVersion={currentImageVersion}
            realstate={realstate}
            setRealState={setRealState}
            globleTotalCost={globleTotalCost}
            access_token={access_token}
            pic={pic}
            formData={formData}
            dileverable={dileverable}
            GI={GI}
            estimate_hardcost={estimate_hardcost}
            setDeliverable={setDeliverable}
            currentTarget={currentTarget}
            setTat={setTat}
            tat={tat}
            setFormData={setFormData}
            setShowDescription={setShowDescription}
            setEditSection={setEditSection}
            editSection={editSection}
            showDescription={showDescription}
            componentRef={componentRef}
            setIsDetOpen={setIsDetOpen}
            isDetOpen={isDetOpen}
          />
        )}

        {/* {!isColab && stepsRedux === 2 && (
          <SecondStep
            formData={formData}
            setFormData={setFormData}
            uniqueID={estId}
          />
        )}

        {stepsRedux === 3 && (
          <ThirdStep
            formData={formData}
            setFormData={setFormData}
            steps={steps}
            dileverable={dileverable}
            setDeliverable={setDeliverable}
            setIsDetOpen={setIsDetOpen}
            num={num}
            setNum={setNum}
            estId={estId}
            showAllTasks={showAllTasks}
            setShowAllDescription={setShowAllDescription}
            showAllDescription={showAllDescription}
            setBudgetLoading={setBudgetLoading}
            allWorktypes={allWorktypes}
          />
        )}

        {stepsRedux === 4 && (
          <FourthStep
            formData={formData}
            setFormData={setFormData}
            temp={temp}
            setTemp={setTemp}
            dileverable={dileverable}
            setDeliverable={setDeliverable}
            view={view}
            setView={setView}
            tasks={tasks}
            setTasks={setTasks}
          />
        )}






        {stepsRedux === 5 && (
          <Fifth
            formData={formData}
            setFormData={setFormData}
            dileverable={dileverable}
            setDeliverable={setDeliverable}
            tracker={tracker}
            setTracker={setTracker}
            estimate_hardcost={estimate_hardcost}
            setEstHardCost={setEstHardCost}
            currentTarget={currentTarget}
            setGI={setGI}
            GI={GI}
          />
        )}

        {stepsRedux === 6 && (
          <SixthStep
            formData={formData}
            dileverable={dileverable}
            GI={GI}
            estimate_hardcost={estimate_hardcost}
            setGI={setGI}
          />
        )}

        {!isColab && stepsRedux === 7 && (
          <Final
            globleTotalCost={globleTotalCost}
            pic={pic}
            formData={formData}
            dileverable={dileverable}
            GI={GI}
            estimate_hardcost={estimate_hardcost}
            setDeliverable={setDeliverable}
            estimate_paymentterms={estimate_paymentterms}
            setEstimate_paymentterms={setEstimate_paymentterms}
            estimate_termsconditions={estimate_termsconditions}
            setEstimate_termsconditions={setEstimate_termsconditions}
            currentTarget={currentTarget}
            setTat={setTat}
            tat={tat}
            setFormData={setFormData}
            setShowDescription={setShowDescription}
            setEditSection={setEditSection}
            editSection={editSection}
            showDescription={showDescription}
            componentRef={componentRef}
          />
        )} */}
      </section>
      {error && <Alert severity="info">{errorMessage}</Alert>}

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '1rem 2.2rem',
          backgroundColor: '#fff',
          height: { xl: '90px', lg: '120px' },
          marginBottom: { xl: '.5rem', lg: '1rem' },
        }}
      >
        <button
          className="cancel-btn"
          onClick={() => {
            if (stepsRedux === 1) {
              navigate('/Opportunities')
            }
            else {
             
              if (formData.type === 'retainer' && stepsRedux === 4) {
                dispatch(storeStep(2))
                
              }
              else {
                if (stepsRedux === 4) {
                  UpdateRequest4THSTEP(3)
                  return;
                }
                else if (stepsRedux === 3 && !formData.collaborators) {
                  return dispatch(storeStep(1));
                }
                return dispatch(storeStep(stepsRedux - 1));

              }
            }
          }
          }
        >
          {stepsRedux == 1 ? 'Cancel' : 'Back'}
        </button>
        <Box>
          <button disabled={budgetLoading} className="create-btn" onClick={handleSubmit}>
            {stepsRedux < 6 ? (buttonLoading ? <Box sx={{ width: '30px', height: '30px', margin: '0rem 0.1rem' }}><PreLoader /></Box> : 'Next') : 'Finish'}
          </button>
        </Box>
      </Box>
      {
        openCanva && (
          <>
            <DilTemplate
              dileverable={dileverable}
              setDeliverable={setDeliverable}
              setOpenCanva={setOpenCanva}
              openCanva={openCanva}
              formData={formData}
              calculateLOERate={calculateLOERate}
              calculateLOERateName={calculateLOERateName}
              allWorktypes={allWorktypes}
            />
          </>
        )
      }


      <EstHelpVideos show={show} setShow={setShow} steps={stepsRedux} />
    </section>

  ) : (
    <PreLoading />
  );
};
export default EstDetail;
