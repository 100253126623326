import React, { useEffect } from "react";
import './courses.css'
import Avatar from "@mui/material/Avatar";
import NotificationsNoneIcon from "./assets/notificationBell.svg";
import Button from "@mui/material/Button";
import CoursesList from "./CoursesList";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { getCourses } from "./CoursesRedux";

const Header = () => {
  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        padding: " 1% 1.38% 1% 1.38%",
        boxSizing: "border-box",
        textAlign: "center",
        fontSize: "24px",
        fontWeight: "600",
        color: "#000",
        fontFamily: "'Open Sans'",
        backgroundColor: "white",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
          gap: "20px",
        }}
      >
        <span style={{ position: "relative", lineHeight: "100%" }}>
        Courses
        </span>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            textAlign: "left",
            fontSize: "14px",
            color: "rgba(3, 7, 30, 0.4)",
          }}
        >
   
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          padding: "5px 0",
          fontSize: "14px",
          color: "#fff",
        }}
      >
        <Link to="/SuperAdmin/NetNetU/Courses/New">
          <Button
            sx={{
              width: 108,
              height: 35,
              textTransform: "capitalize",
              fontWeight: "300",
              backgroundColor: "#711FFF",
              lineHeight: "19px",
              fontSize: "14px",
              "&:hover": {
                backgroundColor: "#711DDD",
              },
            }}
            variant="contained"
          >
            + New
          </Button>
        </Link>
      </div>
    </div>
  );
};

const Courses = ({screenHeight}) => {


const dispatch = useDispatch();
 const access_token = useSelector((state) => state?.auth?.user?.access);
  const courses = useSelector((state) => state?.courses?.courses); 
  

  useEffect(() => {
    const meta = {
      access_token,
    };
    dispatch(getCourses(meta));
  }, [dispatch, access_token]);

  const currentUser = useSelector((state) => state?.userData?.userData);

  return (
    <>
    <div className="netNetU">
          <div className="title">
            <span>NET NET U</span>
          </div>
          <div className="icons">
            <div
              style={{
                width: 238,
                height: 40,
                color: "#711FFF",
                textAlign: "right",
                borderRight: "0.5px solid ",
                borderColor: "#BDBDBD",
                padding: 5,
                paddingRight: 38
              }}
            >
              <img style={{

              }} src={NotificationsNoneIcon}/>
            </div>
            <Avatar
              style={{
                padding: 5,
                width: 30,
                height: 30,
                textTransform: "capitalize"
              }}
              alt="Avatar"
              variant="circular"
            >
            {currentUser ? currentUser.first_name.charAt(0) : "U"}

            </Avatar>
          </div>
        </div>
    <section className="oppor-section">
    
       <div className="coursesWrapper">
        

        <div className="courses">
        <Header />
       </div>
        <div className="courseList" style={{minHeight: screenHeight - 200, maxHeight: screenHeight - 200}}>
          <div className="yourCourses">
            <h3>Your Courses</h3>
            <p>Please enter your course title, description and cover image</p>
          </div>
          <div className="lists" style={{overflowY: "auto", height: screenHeight - 330}}>
            <CoursesList courses={courses} />
          </div>
        </div>
      </div> 
      </section>
    </>
  );
};

export default Courses;
