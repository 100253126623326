import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { divide, subtract } from 'mathjs';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { sumPriceToCustomer } from './MultiplierChange';

function calculateTotalMargin(items) {
  const totalMargin = items.reduce((sum, item) => {
    const margin = Number(item.price_to_customer) - Number(item.our_price);
    return sum + margin;
  }, 0);

  return totalMargin;
}

function formatMoneyString(numberString) {
  if (typeof numberString !== 'string') {
    console.warn('Input must be a string');
    return '$0';
  }
  if (!/^\d+(\.\d{1,2})?$/.test(numberString)) {
    console.warn('Input must be a valid number string');
    return '$0';
  }

  const num = parseFloat(numberString).toFixed(2);

  const parts = num.split('.');
  const integerPart = parts[0];
  const decimalPart = parts[1];

  const formattedIntegerPart = integerPart.replace(
    /\B(?=(\d{3})+(?!\d))/g,
    ','
  );
  const formattedMoneyString = `$${formattedIntegerPart}.${decimalPart}`;

  return formattedMoneyString;
}

function calculateDurationFromDil(deliverables) {
  if (!deliverables || !Array.isArray(deliverables) || !deliverables.length) {
    return { days: 0, weeks: 0, months: 0 };
  }

  let startDate = new Date(deliverables[0].deliverable_startdate);
  let endDate = new Date(deliverables[0].deliverable_enddate);

  for (let i = 1; i < deliverables.length; i++) {
    const start = new Date(deliverables[i].deliverable_startdate);
    const end = new Date(deliverables[i].deliverable_enddate);
    if (start < startDate) {
      startDate = start;
    }
    if (end > endDate) {
      endDate = end;
    }
  }

  const days = Math.floor((endDate - startDate) / (1000 * 60 * 60 * 24));
  const weeks = Math.floor(days / 7);
  const months = Math.floor(days / 30);

  return { days, weeks, months };
}

function getDurationFromHours(hours) {
  if (!Number.isInteger(hours) || hours < 0) {
    throw new Error('Input must be a non-negative integer');
  }

  const duration = moment.duration(hours, 'hours');

  const months = Math.floor(duration.asMonths());
  duration.subtract(moment.duration(months, 'months'));

  const weeks = Math.floor(duration.asWeeks());
  duration.subtract(moment.duration(weeks, 'weeks'));

  const days = Math.floor(duration.asDays());

  return {
    months,
    weeks,
    days,
  };
}
const calculateTotalPrice = (array) => {
  return array.reduce((accumulator, item) => {
    return accumulator + Number(item.price_to_customer);
  }, 0);
};

const NetTable = ({ formData, GI, estimate_hardcost }) => {
  const [sub, setSub] = useState(0);

  useEffect(() => {
    if (
      estimate_hardcost &&
      Array.isArray(estimate_hardcost) &&
      estimate_hardcost.length
    ) {
      setSub(sumPriceToCustomer(estimate_hardcost));
    }
  }, [estimate_hardcost]);

  const [gross, setGross] = useState(0);

  useEffect(() => {
    setGross(
      formData.deliverable_project.reduce((acc, p) => acc + p.total_loe, 0)
    );
  }, [formData]);

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell align="left">
            <div className="fstxt3">Gross Income</div>
          </TableCell>
          <TableCell align="right">
            <div className="fstxt3">
              {formData.type === 'project'
                ? formatMoneyString(gross ? gross.toString() : '0')
                : formatMoneyString(gross ? gross.toString() : '0') + '/month'}
            </div>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {Array.isArray(formData.collaborators) &&
        formData.collaborators.length ? (
          <TableRow>
            <TableCell align="left">
              <Typography sx={{ fontStyle: 'italic' }}>
                - Collaborator Costs
              </Typography>
            </TableCell>
            <TableCell align="right">
              <Typography sx={{ fontStyle: 'italic' }}>$0</Typography>
            </TableCell>
          </TableRow>
        ) : null}

        {Array.isArray(estimate_hardcost) && estimate_hardcost.length ? (
          <TableRow>
            <TableCell align="left">
              <Typography sx={{ fontStyle: 'italic' }}>- Hard Costs</Typography>
            </TableCell>
            <TableCell align="right">
              <Typography sx={{ fontStyle: 'italic' }}>
                {formatMoneyString(`${sub}`)}
              </Typography>
            </TableCell>
          </TableRow>
        ) : null}
        <TableRow
          sx={{
            height: '90px',
            backgroundColor: '#711fff',
          }}
        >
          <TableCell align="left">
            <Typography
              sx={{
                fontWeight: '600',
                fontSize: '1.1rem',
                fontFamily: 'Open Sans',
                color: '#fff',
              }}
            >
              Net Income
            </Typography>
          </TableCell>
          <TableCell align="right">
            <Typography
              sx={{
                fontWeight: '600',
                fontSize: '1.3rem',
                fontFamily: 'Open Sans',
                color: '#fff',
              }}
            >
              {formData.type === 'project'
                ? formatMoneyString(`${gross}`)
                : formatMoneyString(`${gross}`) + '/month'}
            </Typography>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export { formatMoneyString, getDurationFromHours };

const MarginTable = ({ formData, estimate_hardcost }) => {
  const [hard, setHard] = useState(0);

  useEffect(() => {
    if (Array.isArray(estimate_hardcost) && estimate_hardcost.length) {
      setHard(calculateTotalMargin(estimate_hardcost));
    }
  },[]);
  
  return (
    <Table>
      <TableHead>
        <TableRow
          sx={{
            height: '90px',
            backgroundColor: '#a16bff',
            borderTopLeftRadius: '8px',
            borderTopRightRadius: '8px',
          }}
        >
          <TableCell align="left">
            <Typography
              sx={{
                fontWeight: '600',
                fontSize: '1.1rem',
                fontFamily: 'Open Sans',
                color: '#fff',
              }}
            >
              Margins
            </Typography>
          </TableCell>
          <TableCell align="right">
            <Typography
              sx={{
                fontWeight: '600',
                fontSize: '1.3rem',
                fontFamily: 'Open Sans',
                color: '#fff',
                textAlign: 'right',
              }}
            >
              {formatMoneyString(hard ? `${hard}` : '0')}
            </Typography>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell align="left">
            <div className="fstxt">Margins On Collaborators</div>
          </TableCell>
          <TableCell align="right">
            <div className="fstxt" style={{ textAlign: 'right' }}>
              $0
            </div>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="left">
            <div className="fstxt">Margins On Hard Costs</div>
          </TableCell>
          <TableCell align="right">
            <div className="fstxt" style={{ textAlign: 'right' }}>
              {formatMoneyString(hard ? `${hard}` : '0')}
            </div>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

const TeamKpi = ({ formData, dileverable, GI }) => {
  const subSx = { fontSize: '0.9rem', color: '#626574' };
  const totalHours = formData.dileverable
    ? formData.dileverable.reduce((acc, curr) => {
        if (
          curr &&
          curr.deliverable_worktype &&
          Array.isArray(curr.deliverable_worktype)
        ) {
          curr.deliverable_worktype.forEach((workType) => {
            if (workType && workType.hours) {
              const hoursAsNumber = Number(workType.hours);
              if (!isNaN(hoursAsNumber)) {
                acc += hoursAsNumber;
              }
            }
          });
        }
        return acc;
      }, 0)
    : null;

  const totalDuration = calculateDurationFromDil(dileverable);
  const avg = Math.round(divide(Number(GI), Number(totalDuration.months)));
  const cashFlow = formatMoneyString(avg.toString());
  const blendedRate = divide(Number(GI), Number(totalHours));
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        width: '100%',
        borderRadius: '8px',
        padding: '0.44rem 0.88rem',
        border: '1px solid #a9a9a9',
        gap: '1rem',
        backgroundColor: '#fafafc',
      }}
    >
      <h5 className="fstxt3" style={{ marginTop: '1rem' }}>
        Your Team KPI&apos;s
      </h5>
      <Box
        sx={{
          display: 'flex',
          width: '95%',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '1rem',
            width: '50%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: '#f1f3f5',
              borderRadius: '8px',
              flexDirection: 'column',
              padding: '0.44rem 0.88rem',
              width: '100%',
            }}
          >
            <div className="fstxt3">{totalHours ? totalHours : '0'}</div>
            <Typography sx={subSx}>Total hours</Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: '#f2ebff',
              borderRadius: '8px',
              padding: '0.44rem 0.88rem',
              flexDirection: 'column',
              width: '100%',
            }}
          >
            <div className="fstxt3">{cashFlow ? cashFlow : '0'}</div>
            <Typography sx={subSx}>Average Cashflow per Month</Typography>
          </Box>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '1rem',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              gap: '1rem',
              backgroundColor: '#fff',
              borderRadius: '8px',
            }}
          >
            <Box
              sx={{
                padding: '0.44rem 0.88rem',
                borderRight: '1px solid #a9aaaa',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
              }}
            >
              <div className="fstxt3">
                {totalDuration.months ? totalDuration.months : '0'}
              </div>
              <Typography sx={subSx}>months</Typography>
            </Box>
            <Box
              sx={{
                padding: '0.88rem',
                borderRight: '1px solid #a9aaaa',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
              }}
            >
              <div className="fstxt3">
                {totalDuration.weeks ? totalDuration.weeks : '0'}
              </div>
              <Typography sx={subSx}>weeks</Typography>
            </Box>
            <Box
              sx={{
                padding: '0.88rem',
                borderRight: '1px solid #a9aaaa',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
              }}
            >
              <div className="fstxt3">
                {totalDuration.days ? totalDuration.days : '0'}
              </div>
              <Typography sx={subSx}>days</Typography>
            </Box>
          </Box>

          <Box
            sx={{
              backgroundColor: '#fff',
              borderRadius: '8px',
              textAlign: 'center',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '0.88rem 1.44rem',
              flexDirection: 'column',
            }}
          >
            <div className="fstxt3">
              {formatMoneyString(
                blendedRate ? Math.round(blendedRate).toString() : '0'
              )}
              /hr
            </div>
            <Typography sx={subSx}>Blended Rate on Net Income</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const WorkTypeLOE = ({ formData }) => {
  function getTotalHoursByWorkType(workTypes, deliverables) {
    const hoursByWorkType = {};
    workTypes.forEach((workType) => {
      hoursByWorkType[workType.id] = { name: workType.name, hours: 0 };
    });

    deliverables.forEach((deliverable) => {
      if (
        deliverable &&
        deliverable.deliverable_worktype &&
        Array.isArray(deliverable.deliverable_worktype)
      ) {
        deliverable.deliverable_worktype.forEach((workType) => {
          if (workType && workType.id) {
            const hours = Number(workType.hours);
            const workTypeId = Number(workType.id);

            if (hoursByWorkType[workTypeId] !== undefined) {
              hoursByWorkType[workTypeId].hours += hours;
            }
          }
        });
      }
    });

    const result = Object.values(hoursByWorkType).map(({ name, hours }) => ({
      name,
      hours,
    }));

    return result;
  }

  const userWorkTypes = useSelector((state) => state.Misc.workTypes);
  const dileverableWorkTypes = formData.dileverable ? formData.dileverable : [];

  const LOE =
    userWorkTypes && userWorkTypes.length
      ? getTotalHoursByWorkType(userWorkTypes, dileverableWorkTypes)
      : [];
  const NetHours = LOE.reduce((acc, { hours }) => acc + hours, 0);

  return (
    <Table>
      <TableHead>
        <TableRow
          sx={{
            background: '#e0e0df',
            borderRadius: '8px',
          }}
        >
          <TableCell align="left">
            <div className="fstxt" style={{ textAlign: 'left' }}>
              Team LOE Hours by Work Type
            </div>
          </TableCell>
          <TableCell align="left">
            <div className="fstxt" style={{ textAlign: 'left' }}>
              Total
            </div>
          </TableCell>
          <TableCell align="right">
            <div className="fstxt" style={{ textAlign: 'right' }}>
              %
            </div>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {LOE.map((item, i) => {
          if (!Math.round((Number(item.hours) / NetHours) * 100)) {
            return;
          }
          return (
            <TableRow key={i}>
              <TableCell align="left">{item.name}</TableCell>
              <TableCell align="left">{Number(item.hours)}</TableCell>
              <TableCell align="right">
                {Math.round((Number(item.hours) / NetHours) * 100)}%
              </TableCell>
            </TableRow>
          );
        })}
        <TableRow sx={{ backgroundColor: '#a8d6ff' }}>
          <TableCell align="left">{'  '}</TableCell>
          <TableCell align="left">{NetHours}</TableCell>
          <TableCell align="right">{'100%'}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

const NetChange = ({
  formData,
  setFormData,
  dileverable,
  GI,
  estimate_hardcost,
  setEstHardCost,
  setGI,
}) => {
  useEffect(() => {
    if (Array.isArray(estimate_hardcost) && estimate_hardcost.length && GI) {
      const netPrice = calculateTotalPrice(estimate_hardcost);
      setGI(Number(GI) + Number(netPrice));
    }
  }, [estimate_hardcost]);
  return (
    <Grid
      container
      justifyContent="space-between"
      sx={{ gap: '2rem', height: '700px' }}
    >
      <Grid item container alignItems="center" justifyContent="space-between">
        <h3 className="fstxtPro">Your Projected Net Net</h3>
        <Box
          sx={{
            height: '2px',
            width: { xl: '74%', lg: '60%' },
            backgroundColor: '#adadad',
          }}
        />
      </Grid>
      <Grid
        item
        container
        direction="column"
        sx={{ gap: '2rem' }}
        xl={5}
        lg={5}
        md={5}
      >
        <Grid item sx={{ backgroundColor: '#fff', borderRadius: '8px' }}>
          <NetTable
            formData={formData}
            GI={GI}
            estimate_hardcost={estimate_hardcost}
          />
        </Grid>

        <Grid item sx={{ backgroundColor: '#fff', borderRadius: '8px' }}>
          {formData.collaborators?.length > 0 ||
          estimate_hardcost?.length > 0 ? (
            <MarginTable
              formData={formData}
              estimate_hardcost={estimate_hardcost}
            />
          ) : null}
        </Grid>
      </Grid>
      <Grid
        item
        container
        direction="column"
        sx={{ gap: '2rem' }}
        xl={5}
        lg={6}
        md={6.5}
      >
        <TeamKpi formData={formData} dileverable={dileverable} GI={GI} />
        <WorkTypeLOE formData={formData} />
      </Grid>
    </Grid>
  );
};

export default NetChange;
