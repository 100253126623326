import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Grid, Box, Alert, TextField, Typography } from '@mui/material';
import { ENDPOINT } from '../../pages/Opportunity/Redux/oppor';
import { userData } from '../../Screens/InnerApp/userData';
import { setUser } from '../Auth/Redux/auth';
import { PreLoader1 } from '../Misc';

const IntroTxt = () => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: '0.5rem',
      width: { lg: '60%', md: '70%', xs: '100%' },
      padding: '0 1.5rem',
    }}
  >
    <h3 className="sub-heading">Update Account</h3>
  </Box>
);

const UpdateForm = ({ setInvitation }) => {
  const [loading, setLoading] = useState(false);
  const [ID, setID] = useState(NaN);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    repass: '',
  });

  const urlParams = new URLSearchParams(window.location.search);
  const mytoken = urlParams.get('access_token');
  const myRefreash = urlParams.get('refresh_token');
  const uid = urlParams.get('uid');
  const alt_token = urlParams.get('token');

  const settingData = {
    access: mytoken,
    refresh: myRefreash,
  };
  const dispatch = useDispatch();
  const checkUser = async () => {
    const response = await fetch(`${ENDPOINT}/api/user/userdetail/`, {
      method: 'GET',
      headers: {
        Authorization: `JWT ${mytoken}`,
        'Content-Type': 'application/json',
      },
    });
    const data = await response.json();
    if (!response.ok) {
    } else {
      setID(data.id);
      dispatch(userData(data));
      dispatch(setUser(settingData));
      if (data && data.email) {
        setFormData({
          ...formData,
          email: data.email
        })
      }
    }
  };

  useEffect(() => {
    checkUser();
  }, []);

  const [err, setErr] = useState('');

  useEffect(() => {
    if (err) {
      setTimeout(() => {
        setErr('');
      }, 3000);
    }
  }, [err]);
  const handleFinish = async (e) => {
    if (formData.repass === formData.password) {
      setLoading(true);
      e.preventDefault();
      const userUpdateData = new FormData();

      // Add fields to the formData object
      userUpdateData.append('profile_updated', 1);
      userUpdateData.append('personalization_updated', 1);
      userUpdateData.append('email', formData.email);
      userUpdateData.append('first_name', formData?.first_name);
      userUpdateData.append('last_name', formData?.last_name);
      userUpdateData.append('re_password', formData.password);
      userUpdateData.append('username', formData?.email);
      userUpdateData.append('avatar', null);
      userUpdateData.append('skiped_welcome', 0);
      userUpdateData.append('token', alt_token);
      userUpdateData.append('uid', uid);
      const res1 = await fetch(`${ENDPOINT}/api/user/update/${ID}/`, {
        method: 'PUT',
        headers: {
          Authorization: `JWT ${mytoken}`,
        },
        body: userUpdateData
      });
      const data1 = await res1.json();
      if (!res1.ok) {
        setErr('Something went wrong updating your profile.');
        return;
      } else {

        const response = await fetch(
          `${ENDPOINT}/auth/users/reset_password_confirm/`,
          {
            method: 'POST',
            headers: {
              Authorization: `JWT ${mytoken}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              ...formData,
              re_password: formData.password,
              username: formData.email,
              profile_updated: true,
              new_password: formData.password,
              re_new_password: formData.password,
              uid,
              token: alt_token,
            }),
          }
        );

        if (!response.ok) {
          const data = await response.json();
          setLoading(false);
          for (const key in data) {
            setErr(data[key]);
          }
          return;
        } else {
          const response = await fetch(`${ENDPOINT}/api/user/userdetail/`, {
            method: 'GET',
            headers: {
              Authorization: `JWT ${mytoken}`,
              'Content-Type': 'application/json',
            },
          });
          const data = await response.json();
          if (!response.ok) {
          } else {
            setID(data.id);
            dispatch(userData(data));
            dispatch(setUser(settingData));
          }
          setLoading(false);
          setInvitation(true);
          navigate('/');
        }
      }
    }
    else {
      setErr('Passwords are not matching')
    }
  };
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: { lg: 'flex-start', md: 'flex-start', xs: 'center' },
        gap: '1.1rem',
        width: { lg: '60%', md: '70%', xs: '100%' },
        padding: '0 1.5rem',
      }}
      onKeyPress={(e) => {
        if (e.key === 'Enter') {
          handleFinish(e);
        }
      }}
    >
      <TextField
        variant="outlined"
        type="text"
        sx={{
          width: '100%',
          fontWeight: '600',
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: '#ADADAD',
            },
            '&:hover fieldset': {
              borderColor: '#711fff',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#5a0bdf',
            },

            '&.Mui-error fieldset': {
              borderColor: 'red',
            },

            '&.Mui-disabled fieldset': {
              borderColor: 'grey',
            },
          },
          '& .MuiFormLabel-root': {
            color: '#03071e66',
          },
          '& .MuiFormLabel-root.Mui-focused': {
            color: '#5a0bdf',
          },
          '& .MuiFormLabel-root.Mui-error': {
            color: 'red',
          },
          '& .css-1d3z3hw-MuiOutlinedInput-notchedOutline': {
            top: '-1px',
          },
        }}

        label="First Name"
        onChange={(e) =>
          setFormData({ ...formData, first_name: e.target.value })
        }
      />

      <TextField
        variant="outlined"
        type="text"
        sx={{
          width: '100%',
          fontWeight: '600',
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: '#ADADAD',
            },
            '&:hover fieldset': {
              borderColor: '#711fff',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#5a0bdf',
            },

            '&.Mui-error fieldset': {
              borderColor: 'red',
            },

            '&.Mui-disabled fieldset': {
              borderColor: 'grey',
            },
          },
          '& .MuiFormLabel-root': {
            color: '#03071e66',
          },
          '& .MuiFormLabel-root.Mui-focused': {
            color: '#5a0bdf',
          },
          '& .MuiFormLabel-root.Mui-error': {
            color: 'red',
          },
          '& .css-1d3z3hw-MuiOutlinedInput-notchedOutline': {
            top: '-1px',
          },
        }}

        label="Last Name"
        onChange={(e) =>
          setFormData({ ...formData, last_name: e.target.value })
        }
      />

      <TextField
        variant="outlined"
        type="email"
        sx={{
          width: '100%',
          fontWeight: '600',
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: '#ADADAD',
            },
            '&:hover fieldset': {
              borderColor: '#711fff',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#5a0bdf',
            },

            '&.Mui-error fieldset': {
              borderColor: 'red',
            },

            '&.Mui-disabled fieldset': {
              borderColor: 'grey',
            },
          },
          '& .MuiFormLabel-root': {
            color: '#03071e66',
          },
          '& .MuiFormLabel-root.Mui-focused': {
            color: '#5a0bdf',
          },
          '& .MuiFormLabel-root.Mui-error': {
            color: 'red',
          },
          '& .css-1d3z3hw-MuiOutlinedInput-notchedOutline': {
            top: '-1px',
          },
        }}
        value={formData?.email}
        disabled={formData?.email ? true : false}
        label="Email"
      />

      <TextField
        variant="outlined"
        sx={{
          width: '100%',
          fontWeight: '600',
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: '#ADADAD',
            },
            '&:hover fieldset': {
              borderColor: '#711fff',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#5a0bdf',
            },

            '&.Mui-error fieldset': {
              borderColor: 'red',
            },

            '&.Mui-disabled fieldset': {
              borderColor: 'grey',
            },
          },
          '& .MuiFormLabel-root': {
            color: '#03071e66',
          },
          '& .MuiFormLabel-root.Mui-focused': {
            color: '#5a0bdf',
          },
          '& .MuiFormLabel-root.Mui-error': {
            color: 'red',
          },
          '& .css-1d3z3hw-MuiOutlinedInput-notchedOutline': {
            top: '-1px',
          },
        }}
        type="password"
        label="Password"
        onChange={(e) => setFormData({ ...formData, password: e.target.value })}
      />

      <TextField
        variant="outlined"
        sx={{
          width: '100%',
          fontWeight: '600',
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: '#ADADAD',
            },
            '&:hover fieldset': {
              borderColor: '#711fff',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#5a0bdf',
            },

            '&.Mui-error fieldset': {
              borderColor: 'red',
            },

            '&.Mui-disabled fieldset': {
              borderColor: 'grey',
            },
          },
          '& .MuiFormLabel-root': {
            color: '#03071e66',
          },
          '& .MuiFormLabel-root.Mui-focused': {
            color: '#5a0bdf',
          },
          '& .MuiFormLabel-root.Mui-error': {
            color: 'red',
          },
          '& .css-1d3z3hw-MuiOutlinedInput-notchedOutline': {
            top: '-1px',
          },
        }}
        type="password"
        label="Repeat Password"
        onChange={(e) => setFormData({ ...formData, repass: e.target.value })}
      />

      {err && (
        <Alert severity="info" sx={{ width: '100%' }}>
          {err}
        </Alert>
      )}
      <button type="submit" className="btn inline" onClick={handleFinish}>
        {loading ? <Box sx={{ width: '30px', height: '30px', margin: '0 auto' }}><PreLoader1 /></Box>: 'FINISH'}{' '}
      </button>
    </Box>
  );
};

const Invitation = () => {
  const [invitation, setInvitation] = useState(false);

  return !invitation ? (
    <section className="AxisCenter">
      <article className="box-txt">
        <Typography sx={{ fontSize: '2rem', fontWeight: 'bold' }}>
          Invitation
        </Typography>
        <p>You have been Invited To become a part of a Team</p>
        <button className="btn" onClick={() => setInvitation(true)}>
          Lets Roll
        </button>
      </article>
    </section>
  ) : (
    <Grid container alignItems="center" sx={{ height: '100vh' }}>
      <Grid
        item
        className="register-illustration"
        xs={0}
        sm={0}
        md={7}
        lg={7}
        xl={7}
      />
      <Grid
        item
        container
        xs={12}
        sm={12}
        md={5}
        lg={5}
        xl={5}
        sx={{
          gap: '0.45rem',
          padding: '0.45rem 0',
        }}
        direction="column"
        alignItems="center"
      >
        <IntroTxt />
        <UpdateForm setInvitation={setInvitation} />
      </Grid>
    </Grid>
  );
};

export default Invitation;
