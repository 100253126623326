/* eslint-disable jsx-a11y/label-has-associated-control */

import { Grid, Box, TextField, Alert } from '@mui/material';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ENDPOINT } from '../../pages/Opportunity/Redux/oppor';
import mailIco from './Assets/mailIco.png';
import MobileLogo from './Assets/MobileLogo.png';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link } from 'react-router-dom';
import { Header } from './Login/Auth';
import { Typography } from '@mui/material';
import { PreLoader1 } from '../Misc';
const Intro = () => {
  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const handleResize = () => {
    setScreenSize(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      {screenSize < 900 && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            width: '200px',
            margin: '1rem 0',
          }}
        >
          <img src={MobileLogo} alt="logo" width="280px" height="auto" />
        </Box>
      )}

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          width: { lg: '60%', md: '70%', xs: '100%' },
          padding: '0 1.5rem',
        }}
      >
        <Link exact to="/Login">
          <ArrowBackIcon
            sx={{
              position: 'absolute',
              top: { xl: '10%', lg: '7%', md: '7%', sm: '7%', xs: '7%' },
              left: { xl: '12%', lg: '12%', md: '10%', sm: '2%', sx: '2%' },
              color: '#711fff',
              cursor: 'pointer',
            }}
          />
        </Link>
        <p className="sub-heading">Reset Password</p>
      </Box>
    </>
  );
};

const PwdForm = ({ setRecievedEmail }) => {
  const [Useremail, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleEmail = (e) => {
    setEmail(e.target.value);
  };

  const anotherEmail = useSelector((state) => state.auth.prevEmail);

  useEffect(() => {
    if (anotherEmail) {
      setEmail(anotherEmail);
    }
  }, [anotherEmail]);

  const handleSubmittion = async (e) => {
    e.preventDefault();
    setLoading(true);
    const response = await fetch(`${ENDPOINT}/api/user/password/reset/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: Useremail,
      }),
    });
    const data = await response.json();
    if (!response.ok) {
      setLoading(false);
      setError(true);
      setErrorMessage(data.error);
    } else {
      setRecievedEmail(true);
      setLoading(false);
    }
  };
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: { lg: 'flex-start', md: 'flex-start', xs: 'center' },
        width: { lg: '60%', md: '70%', xs: '100%' },
        padding: '0 1.5rem',
      }}
    >
      <TextField
        type="email"
        variant="outlined"
        sx={{
          width: '100%',
          margin: '0.90rem 0rem',
          fontWeight: '600',
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: '#ADADAD',
            },
            '&:hover fieldset': {
              borderColor: '#711fff',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#5a0bdf',
            },

            '&.Mui-error fieldset': {
              borderColor: 'red',
            },

            '&.Mui-disabled fieldset': {
              borderColor: 'grey',
            },
          },
          '& .MuiFormLabel-root': {
            color: '#03071e66',
          },
          '& .MuiFormLabel-root.Mui-focused': {
            color: '#5a0bdf',
          },
          '& .MuiFormLabel-root.Mui-error': {
            color: 'red',
          },
          '& .css-1d3z3hw-MuiOutlinedInput-notchedOutline': {
            top: '-1px',
          },
        }}
        label="Email Address"
        onChange={handleEmail}
        value={Useremail}
        onInvalid={(e) => {
          e.target.setCustomValidity('Please Enter a Valid Email Address');
        }}
        onInput={(e) => {
          e.target.setCustomValidity('');
          e.target.reportValidity();
          setErrorMessage('');
          setError(false);
        }}
      />

      {error && (
        <Alert severity="error" sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      )}
      <button style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} className="btn inline" type="submit" onClick={handleSubmittion}>
        {loading ? <Box sx={{ width: '40px', height: '40px' }}><PreLoader1 /></Box> : 'RESET'}{' '}
      </button>
    </Box>
  );
};

const TextContent = () => {
  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const handleResize = () => {
    setScreenSize(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <>
      {screenSize < 900 && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            width: '200px',
            margin: '1rem 0',
          }}
        >
          <img src={MobileLogo} alt="logo" width="280px" height="auto" />
        </Box>
      )}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          padding: '0 1.5rem',
        }}
      >
        <Link exact to="/Login">
          <ArrowBackIcon
            sx={{
              position: 'absolute',
              top: { xl: '12%', lg: '12%', md: '12%', sm: '7%', xs: '7%' },
              left: { xl: '12%', lg: '12%', md: '10%', sm: '10%', sx: '2%' },
              color: '#711fff',
              cursor: 'pointer',
            }}
          />
        </Link>
        <img src={mailIco} alt="Vector" className="icon" />
        <p className="sub-heading">Check Your Email</p>
        <p className="fstxt">
          {' '}
          We Have sent you an email with further Instructions to reset your
          password.
        </p>
      </Box>
    </>
  );
};

import logo from './Assets/MobileLogo.png'
import resetsvg from './Assets/Forgot_Password_gi2d.svg'
import checkmail from './Assets/Check-email.svg'


const ResetPassword = ({ screenHeight }) => {
  const [EmailRecieved, setRecievedEmail] = useState(false);
  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const handleResize = () => {
    setScreenSize(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <>
      {EmailRecieved ? (
        <Grid container component="main" sx={{ height: screenSize < 900 ? '78vh' : "100vh" }}>
          {screenSize < 900 && <Header />}
          {screenSize >= 900 &&
            <Grid
              item
              xs={0}
              sm={0}
              md={7}
              lg={7}
              xl={7}
              xxl={7}
              sx={{
                backgroundColor: '#47d1fc',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: "hidden"
              }}
            >
              <Box sx={{ width: { xs: "70%", lg: '60%', xl: '50%', xxl: '50%' }, }}>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <img src={logo} width={'100%'} height={'auto'} alt="Mobile Logo" />
                </Box>
                <Box sx={{ my: 3 }}>
                  <Typography sx={{ fontSize: '20px', lineHeight: '26px', fontWeight: '400', fontFamily: 'open Sans', color: 'white' }}>
                    Putting Profits Into Your Workflow Tools!
                  </Typography>
                  <Typography sx={{ fontSize: '20px', lineHeight: '26px', fontWeight: '400', fontFamily: 'open Sans', color: 'white', mt: 1 }}>
                    - opportunities & sales
                  </Typography>
                  <Typography sx={{ fontSize: '20px', lineHeight: '26px', fontWeight: '400', fontFamily: 'open Sans', color: 'white', mt: 1 }}>
                    - project, retainer & task management
                  </Typography>
                  <Typography sx={{ fontSize: '20px', lineHeight: '26px', fontWeight: '400', fontFamily: 'open Sans', color: 'white', mt: 1 }}>
                    - time tracking
                  </Typography>
                  <Typography sx={{ fontSize: '20px', lineHeight: '26px', fontWeight: '400', fontFamily: 'open Sans', color: 'white', mt: 1 }}>
                    - team communications
                  </Typography>
                </Box>
                <img src={checkmail} alt="checkmail SVG" width={'100%'} height={'auto'} />
              </Box>
            </Grid>
          }

          <Grid
            item
            container
            xs={12}
            sm={12}
            md={5}
            lg={5}
            xl={5}
            sx={{
              gap: { xs: "4.45rem", sm: "4.45rem", md: "4.45rem", lg: "0.45" },
              padding: '0.45rem 0',
              justifyContent: 'center',
              position: 'relative'
            }}
            direction="column"
            alignItems="center"
          >
            <TextContent />
          </Grid>
        </Grid>
      ) : (
        <Grid container component="main" sx={{ height: screenSize < 900 ? '78vh' : "100vh" }}>
          {screenSize < 900 && <Header />}
          {screenSize >= 900 &&
            <Grid
              item
              xs={0}
              sm={0}
              md={7}
              lg={7}
              xl={7}
              xxl={7}
              sx={{
                backgroundColor: '#47d1fc',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: "hidden"
              }}
            >
              <Box sx={{ width: { xs: "70%", lg: '60%', xl: '50%', xxl: '50%' }, }}>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <img src={logo} width={'100%'} height={'auto'} alt="Mobile Logo" />
                </Box>
                <Box sx={{ my: 3 }}>
                  <Typography sx={{ fontSize: '20px', lineHeight: '26px', fontWeight: '400', fontFamily: 'open Sans', color: 'white' }}>
                    Putting Profits Into Your Workflow Tools!
                  </Typography>
                  <Typography sx={{ fontSize: '20px', lineHeight: '26px', fontWeight: '400', fontFamily: 'open Sans', color: 'white', mt: 1 }}>
                    - opportunities & sales
                  </Typography>
                  <Typography sx={{ fontSize: '20px', lineHeight: '26px', fontWeight: '400', fontFamily: 'open Sans', color: 'white', mt: 1 }}>
                    - project, retainer & task management
                  </Typography>
                  <Typography sx={{ fontSize: '20px', lineHeight: '26px', fontWeight: '400', fontFamily: 'open Sans', color: 'white', mt: 1 }}>
                    - time tracking
                  </Typography>
                  <Typography sx={{ fontSize: '20px', lineHeight: '26px', fontWeight: '400', fontFamily: 'open Sans', color: 'white', mt: 1 }}>
                    - team communications
                  </Typography>
                </Box>
                <img src={resetsvg} alt="Login SVG" width={'100%'} height={'auto'} />
              </Box>
            </Grid>
          }

          <Grid
            item
            container
            xs={12}
            sm={12}
            md={5}
            lg={5}
            xl={5}
            sx={{
              gap: '0.45rem',
              padding: '0.45rem 0',
              justifyContent: 'center',
              position: 'relative'
            }}
            direction="column"
            alignItems="center"
          >
            <Intro />
            <PwdForm setRecievedEmail={setRecievedEmail} />
          </Grid>
        </Grid>
      )
      }
    </>
  )
};

export default ResetPassword;
