/* eslint-disable camelcase */
import Box from '@mui/material/Box';
import Button from '@mui/material/Button'
import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { DataGrid } from '@mui/x-data-grid';
import Gear from './assets/Gear.png';
import './customDiscountEngine.css'
import { getDiscounts } from './DiscountRedux';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const Header = () => {
  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        padding: " 1% 1.38% 1% 1.38%",
        boxSizing: "border-box",
        textAlign: "center",
        fontSize: "29px",
        color: "#03071e",
        fontFamily: "'Open Sans'",
        backgroundColor: "white",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
          gap: "20px",
        }}
      >
        <b style={{ position: "relative", lineHeight: "100%" }}>
       Discount Engine
        </b>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            textAlign: "left",
            fontSize: "14px",
            color: "rgba(3, 7, 30, 0.4)",
          }}
        >
          <div
            style={{
              borderRadius: "4px",
              overflow: "hidden",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              gap: "3px",
            }}
          >
            <img
              style={{
                position: "relative",
                width: "18px",
                height: "18px",
                overflow: "hidden",
                flexShrink: "0",
              }}
              alt=""
              src={Gear}
            />
            <div
              style={{
                position: "relative",
                letterSpacing: "-0.3px",
                lineHeight: "19px",
              }}
            >
              Filters
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          padding: "5px 0",
          fontSize: "14px",
          color: "#fff",
        }}
      >
        <Link to="/SuperAdmin/Tools/DiscountEngine/New">
          <Button
            sx={{
              width: 108,
              height: 35,
              textTransform: "capitalize",
              fontWeight: "300",
              backgroundColor: "#711FFF",
              lineHeight: "19px",
              fontSize: "14px",
              "&:hover": {
                backgroundColor: "#711DDD",
              },
            }}
            variant="contained"
          >
            + New
          </Button>
        </Link>


      </div>
    </div>
  );
};

function Discount({screenHeight}) {
  const navigate = useNavigate();
 
  const dispatch = useDispatch();
  const access_token = useSelector((state) => state.auth.user.access);
  
  useEffect(() => {
    const meta = {
      access_token,
    };

    dispatch(getDiscounts(meta))
      .then(() => {})
      .catch((error) => {
        console.error("Error fetching customers:", error);
      });
  }, [dispatch]);
  
  const discounts = useSelector((state) => state.superAdmin_disc.discounts);

  const columns = [
    {
      field: 'discount_name',
      width: 200,
      flex: 1,
      headerName: 'Discount Name',
      renderHeader: () => <div className="tableHeader" style={{textAlign:"center",  paddingLeft: 22, minWidth: "280px"}}>Discount Name
      <ArrowDropDownIcon style={{ verticalAlign: "middle", marginLeft: "-5px", height: "60px" }} /></div>,
      renderCell: (params) => (
        <div className="td breakSpace" style={{textAlign:"center",  minWidth: "280px", paddingLeft: 22,}}>{params.value}</div>
      ),
    },
    {
      field: 'type',
      headerName: 'Type',
      width: 180,
      flex: 1,
      renderHeader: () => <div className="tableHeader" style={{textAlign:"center"}} >Type</div>,
      renderCell: (params) => <div className="td breakSpace"style={{textAlign:"center"}}>{params.value}</div>,
    },
    {
      field: 'issuance',
      headerName: 'Issuance',
      width: 180,
      flex: 1,
      renderHeader: () => <div className="tableHeader" style={{textAlign:"center"}}>Issuance</div>,
      renderCell: (params) => <div className="td breakSpace" style={{textAlign:"center"}}>{params.value}</div>,
    },
    {
      field: 'amount',
      headerName: 'Amount',
      width: 150,
      flex: 1,
      renderHeader: () => <div className="tableHeader" style={{textAlign:"center"}} >Amount</div>,
      renderCell: (params) => <div className="td breakSpace"style={{textAlign:"center"}}>{params.value}</div>,
    },
    {
      field: 'expiration',
      headerName: 'Expiration',
      width: 160,
      flex: 1,
      renderHeader: () => <div className="tableHeader" style={{textAlign:"center"}}>Expiration</div>,
      renderCell: (params) => (
        <div className="td breakSpace" style={{textAlign:"center"}}>{params.value ?? '---'}</div>
      ),
    },
    {
      field: 'code',
      headerName: 'Code',
      width: 150,
      flex: 1,
      renderHeader: () => <div className="tableHeader" style={{textAlign:"center"}}>Code</div>,
      renderCell: (params) => (
        <div className="td breakSpace" style={{textAlign:"center"}}>{params.value ?? '---'}</div>
      ),
    }
  ];

 

  const rows = discounts?.map((discount, index) => ({
    id: index,
    discount_name: discount.discount_name,
    type: discount.type,
    issuance: discount.issuance,
    amount: discount.amount,
    expiration: discount.expiration ? discount.expiration : "Life Time" ,
    code: discount.code,
  }));


  return !Array.isArray(discounts) || !discounts.length ? (
    <>
      <Header />
    <section className="oppor-section">
      <sections className="myCenter">
        <article className="box-txt">
          <h3 className="txtCenter">Loading ...</h3>

          <article className="buttonGroup">
            <button
              type="submit"
              className="btn"
              onClick={() => navigate('/SuperAdmin/Tools/DiscountEngine/New')}
            >
              New Discount
            </button>
          </article>
        </article>
      </sections>
    </section>
    </>
  ) : (
    <>
      <section style={{height: "100vh"}}>
        <Header />
          <Box
            sx={{
              height: screenHeight - 120,
            }}
          >
            <DataGrid
              rows={rows}
              columns={columns}
              rowHeight={77}
              headerHeight={78}
              sx={{
                '.MuiDataGrid-columnHeader': {
                  fontWeight: 'bold',
                },
                '.MuiDataGrid-row': {
                  color: '#000',
                  fontWeight: 500,
                  fontFamily: 'Open Sans, sans-serif',
                  fontSize: '14px',
                  lineHeight: "19px",
                  letterSpacing: "0.5px",
                  backgroundColor: "#FDFDFE"
                },
                '.MuiDataGrid-columnHeader': {
                color: '#03071E99',
                backgroundColor: "#F2F2F2",
                fontFamily: 'Open Sans, sans-serif',
            
                },
                 '.css-1q27lj3-MuiDataGrid-root': {
                    borderRadius: "12px",
                    
                  borderTopLeftRadius: 10,
                  borderTopRightRadius: 10,
                  },
                '.MuiDataGrid-columnHeaders ': {
                  zIndex: 1,
                  backgroundColor: '#F2F2F2',
                },
                '.tableHeader': {
                  color:' #03071E99',
                },
                height: "100%",
                border: '1px solid rgba(224, 224, 224, 1)',
                borderTopLeftRadius: 10,
                borderTopRightRadius: 10,
                margin: "1.38%"
              }}
            />
          </Box>
      </section>
    </>
  );
}

export default function DiscountEngine({screenHeight}) {
  return (
    <>
      <Discount screenHeight={screenHeight} />
    </>
  );
}
