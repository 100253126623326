import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import telegram from '../assets/telegram.png';
import Phone from '../assets/Phone.png';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PreLoader from '../../../Componenets/Misc';
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Avatar, Grid, Tooltip } from '@mui/material';
import { ENDPOINT, ORIGIN } from '../../Opportunity/Redux/oppor';
import { getPersons, getCompanies, getEmails } from '../ContactRedux';
import CompanyModal from '../Companies/Company_Model';
import Modal from '../../../Componenets/Modal/Modal';
import { DashedField } from '../../../Componenets/Misc';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import { Typography } from '@mui/material';
import MUICard from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import CreateTask from '../../Projects/CreateTask';
import Drawer from '@mui/material/Drawer';
import GoogleButton from 'react-google-button';
import RefreshIcon from '@mui/icons-material/Refresh';
import Test from '../../Test';

export const EmailCard = ({ title, body, date }) => {
  const currentUser = useSelector((state) => state.userData.userData);
  return (
    <MUICard
      sx={{
        margin: '-0.5rem',
        marginBottom: '1rem',
      }}
    >
      <CardHeader
        avatar={
          <Typography
            component="div"
            variant="subtitle1"
            sx={{
              fontWeight: 'bold',
              fontFamily: 'Open Sans',
              fontSize: '14px',
              color: '#310085',
            }}
          >
            {title ? title : 'Loading....'}
          </Typography>
        }
        action={
          <IconButton aria-label="settings">
            <Typography
              variant="subtitle1"
              sx={{
                fontWeight: 'bold',
                fontFamily: 'Open Sans',
                fontSize: '12px',
                color: '#03071E66',
              }}
            >
              On {date ? date : 'Loading Date...'}
            </Typography>
          </IconButton>
        }
      />

      <Typography
        sx={{
          fontWeight: 'bold',
          fontFamily: 'Open Sans',
          fontSize: '12px',
          color: '#A16BFF',
          margin: '1rem',
          marginTop: '-1rem',
        }}
      >
        to{' '}
        {currentUser
          ? currentUser.first_name + ' ' + currentUser.last_name
          : ''}
      </Typography>

      <CardContent>
        <Typography variant="body2" color="text.secondary">
          {body ? body : 'Loading Content....'}
        </Typography>
      </CardContent>
      <Collapse>
        <CardContent>
          <Typography paragraph>
            Heat oil in a paella pan or a large, deep skillet over medium-high
            heat. Add chicken, shrimp and chorizo, and cook, stirring
            occasionally until lightly browned, 6 to 8 minutes. Transfer shrimp
            to a large plate and set aside, leaving chicken and chorizo in the
            pan. Add pimentón, bay leaves, garlic, tomatoes, onion, salt and
            pepper, and cook, stirring often until thickened and fragrant, about
            10 minutes. Add saffron broth and remaining 4 1/2 cups chicken
            broth; bring to a boil.
          </Typography>
        </CardContent>
      </Collapse>
    </MUICard>
  );
};

const SocialScrap = ({ currentTarget }) => {
  if (!Array.isArray(currentTarget.contact_social)) {
    return false;
  }

  return (
    <List sx={{ width: '100%', height: '500px', overflowY: 'auto' }}>
      {currentTarget.contact_social.length > 0 ? (
        currentTarget.contact_social.map((item) => (
          <>
            <ListItem alignItems="flex-start" key={item.id}>
              <ListItemAvatar>
                <Avatar
                  src="https://th.bing.com/th/id/R.3f7189662f19f8318fc75252deee723a?rik=Qa956Np1tp8Zcg&riu=http%3a%2f%2f1000logos.net%2fwp-content%2fuploads%2f2017%2f06%2fTwitter-Logo.png&ehk=6ekNd2ZmhpvFDGRZF19QcumP9fb8pZRkwrbFbK%2bpULA%3d&risl=&pid=ImgRaw&r=0"
                  alt="Twitter Icon"
                />
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Typography
                    variant="subtitle1"
                    sx={{
                      fontWeight: 'bold',
                      fontFamily: 'Open Sans',
                      fontSize: '1rem',
                      color: '#310085',
                      textTransform: 'uppercase',
                    }}
                  >
                    Added A New Tweet
                    <p
                      style={{
                        color: 'rgba(3, 7, 30, 0.4)',
                        letterSpacing: '-0.3px',
                        fontSize: '11px',
                        textAlign: 'left',
                        fontFamily: 'Open Sans',
                        marginBottom: '0',
                      }}
                    >
                      {item.twitter_tweet_time ? item.twitter_tweet_time : ''}
                    </p>
                  </Typography>
                }
                secondary={
                  <Typography
                    variant="subtitle1"
                    sx={{
                      fontWeight: 'bold',
                      fontFamily: 'Open Sans',
                      fontSize: '16px',
                      color: '#A16BFF',
                      textTransform: 'none',
                    }}
                  >
                    {item.twitter_tweet_txt}
                  </Typography>
                }
              />
            </ListItem>
            <Divider
              component="li"
              sx={{
                marginLeft: '2.25%',
                marginRight: '2.25%',
              }}
            />
          </>
        ))
      ) : (
        <p className="fstxt">
          No Social Feed Avalible, Please add Social Links via Edit Contact.
        </p>
      )}
    </List>
  );
};

const ContactInfo = ({ formData, setFormData }) => {
  return (
    <Grid
      item
      container
      direction="column"
      alignItems="flex-start"
      sx={{
        width: '100%',
        borderRadius: '8px',
        backgroundColor: '#f8f9fa',
        border: '0.9px solid #d9d9d9',
      }}
    >
      <Grid
        item
        sx={{
          padding: '1rem 12px',
          width: '100%',
        }}
      >
        <div className="gs-10">
          <div className="colgs-10p90q" style={{ overflow: 'hidden' }}>
            <h3 className="sub-title">Contact Information</h3>
            <Box
              className="df-r-j-c-gp-5 MobileDFR autoWidth"
              sx={{
                paddingTop: '1.37%',
                paddingBottom: '1.37%',
              }}
            >
              <span>ID</span>
              <DashedField value={formData.unique_id} disabled={true} />
            </Box>
            <Box
              className="df-r-j-c-gp-5 MobileDFR autoWidth"
              sx={{
                paddingTop: '1.37%',
                paddingBottom: '1.37%',
              }}
            >
              <span>First Name</span>
              <Box sx={{ width: '44%', overflow: 'hidden' }} className="OppCreate"><Typography > {formData.first_name}</Typography></Box>
            </Box>
            <Box
              className="df-r-j-c-gp-5 MobileDFR autoWidth"
              sx={{
                paddingTop: '1.37%',
                paddingBottom: '1.37%',
              }}
            >
              <span>Last Name</span>
              <Box sx={{ width: '44%', overflow: 'hidden' }} className="OppCreate"><Typography > {formData.last_name}</Typography></Box>
            </Box>
            <Box
              className="df-r-j-c-gp-5 MobileDFR autoWidth"
              sx={{
                paddingTop: '1.37%',
                paddingBottom: '1.37%',
              }}
            >
              <span>Email</span>
              <Box sx={{ width: '44%', overflow: 'hidden' }} className="OppCreate"><Typography sx={{ cursor: 'pointer' }} onClick={() => {
                const mailtoURL = `mailto:${formData.email}`;
                window.open(mailtoURL);
              }} > {formData.email}</Typography></Box>
            </Box>
            <Box
              className="df-r-j-c-gp-5 MobileDFR autoWidth"
              sx={{
                paddingTop: '1.37%',
                paddingBottom: '1.37%',
              }}
            >
              <span>Mobile Number</span>
              <Box sx={{ width: '44%', overflow: 'hidden' }} className="OppCreate"><Typography > {formData.mobile_num}</Typography></Box>
            </Box>
          </div>
        </div>
      </Grid>
      <Grid
        item
        sx={{
          padding: '1rem 12px',
          width: '100%',
        }}
      >
        <div className="gs-10">
          <div className="colgs-10p90q">
            <h3 className="sub-title">Social Media</h3>
            <Box
              className="df-r-j-c-gp-5 MobileDFR autoWidth"
              sx={{
                paddingTop: '1.37%',
                paddingBottom: '1.37%',
              }}
            >
              <span>LinkedIn</span>
              <Box sx={{ width: '44%', overflow: 'hidden' }} className="OppCreate"><Typography sx={{ cursor: 'pointer' }} onClick={() => {
                window.open(formData.linkedin_url, '_blank');
              }} > {formData.linkedin_url}</Typography></Box>
            </Box>
            <Box
              className="df-r-j-c-gp-5 MobileDFR autoWidth"
              sx={{
                paddingTop: '1.37%',
                paddingBottom: '1.37%',
              }}
            >
              <span>X</span>
              <Box sx={{ width: '44%', overflow: 'hidden' }} className="OppCreate"><Typography sx={{ cursor: 'pointer' }} onClick={() => {
                window.open(formData.twitter_url, '_blank');
              }} > {formData.twitter_url}</Typography></Box>
            </Box>

            <Box
              className="df-r-j-c-gp-5 MobileDFR autoWidth"
              sx={{
                paddingTop: '1.37%',
                paddingBottom: '1.37%',
              }}
            >
              <span>Instagaram</span>
              <Box sx={{ width: '44%', overflow: 'hidden' }} className="OppCreate"><Typography sx={{ cursor: 'pointer' }} onClick={() => {
                window.open(formData.instagram_url, '_blank');
              }} > {formData.instagram_url}</Typography></Box>
            </Box>

            <Box
              className="df-r-j-c-gp-5 MobileDFR autoWidth"
              sx={{
                paddingTop: '1.37%',
                paddingBottom: '1.37%',
              }}
            >
              <span>Facebook</span>
              <Box sx={{ width: '44%', overflow: 'hidden' }} className="OppCreate"><Typography sx={{ cursor: 'pointer' }} onClick={() => {
                window.open(formData.facebook_url, '_blank');
              }} >  {formData.facebook_url}</Typography></Box>
            </Box>
          </div>
        </div>
      </Grid>
      <Grid
        item
        sx={{
          padding: '1rem 12px',
          width: '100%',
        }}
      >
        <div className="gs-10">
          <div className="colgs-10p90q">
            <h3 className="sub-title">Company Information</h3>
            <Box
              className="df-r-j-c-gp-5 MobileDFR autoWidth"
              sx={{
                paddingTop: '1.37%',
                paddingBottom: '1.37%',
              }}
            >
              <span>Company</span>
              <Box sx={{ width: '44%', overflow: 'hidden' }} className="OppCreate"><Typography > {formData.company ? formData.company : ''}</Typography></Box>
            </Box>
            <Box
              className="df-r-j-c-gp-5 MobileDFR autoWidth"
              sx={{
                paddingTop: '1.37%',
                paddingBottom: '1.37%',
              }}
            >
              <span>Title</span>
              <Box sx={{ width: '44%', overflow: 'hidden' }} className="OppCreate"><Typography > {formData.title ? formData.title : ''}</Typography></Box>
            </Box>
            <Box
              className="df-r-j-c-gp-5 MobileDFR autoWidth"
              sx={{
                paddingTop: '1.37%',
                paddingBottom: '1.37%',
              }}
            >
              <span>Type</span>
              <Box sx={{ width: '44%', overflow: 'hidden' }} className="OppCreate"><Typography > {formData.contact_type ? formData.contact_type : ''}</Typography></Box>
            </Box>
          </div>
        </div>
      </Grid>
      <Grid
        item
        sx={{
          padding: '1rem 12px',
          width: '100%',
        }}
      >
        <div className="gs-10">
          <div className="colgs-10p90q">
            <h3 className="sub-title">Address</h3>
            <Box
              className="df-r-j-c-gp-5 MobileDFR autoWidth"
              sx={{
                paddingTop: '1.37%',
                paddingBottom: '1.37%',
              }}
            >
              <span>Address 1</span>
              <Box sx={{ width: '44%', overflow: 'hidden' }} className="OppCreate"><Typography > {formData.street ? formData.street : ''}</Typography></Box>
            </Box>
            <Box
              className="df-r-j-c-gp-5 MobileDFR autoWidth"
              sx={{
                paddingTop: '1.37%',
                paddingBottom: '1.37%',
              }}
            >
              <span>Address 2</span>
              <Box sx={{ width: '44%', overflow: 'hidden' }} className="OppCreate"><Typography > {formData.street2 ? formData.street2 : ''}</Typography></Box>
            </Box>
            <Box
              className="df-r-j-c-gp-5 MobileDFR autoWidth"
              sx={{
                paddingTop: '1.37%',
                paddingBottom: '1.37%',
              }}
            >
              <span>City</span>
              <Box sx={{ width: '44%', overflow: 'hidden' }} className="OppCreate"><Typography > {formData.city ? formData.city : ''}</Typography></Box>
            </Box>
            <Box
              className="df-r-j-c-gp-5 MobileDFR autoWidth"
              sx={{
                paddingTop: '1.37%',
                paddingBottom: '1.37%',
              }}
            >
              <span>State</span>
              <Box sx={{ width: '44%', overflow: 'hidden' }} className="OppCreate"><Typography > {formData.state ? formData.state : ''}</Typography></Box>
            </Box>
            <Box
              className="df-r-j-c-gp-5 MobileDFR autoWidth"
              sx={{
                paddingTop: '1.37%',
                paddingBottom: '1.37%',
              }}
            >
              <span>Zip / Postal Code</span>
              <Box sx={{ width: '44%', overflow: 'hidden' }} className="OppCreate"><Typography > {formData.address_zip ? formData.address_zip : ''}</Typography></Box>
            </Box>
            <Box
              className="df-r-j-c-gp-5 MobileDFR autoWidth"
              sx={{
                paddingTop: '1.37%',
                paddingBottom: '1.37%',
              }}
            >
              <span>Country</span>
              <Box sx={{ width: '44%', overflow: 'hidden' }} className="OppCreate"><Typography > {formData.country ? formData.country : ''}</Typography></Box>
            </Box>
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

const Card = ({ currentTarget, setCurrentTarget, unique_id, id, setState, state, }) => {
  const [html, setHtml] = useState('<p></p>');
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    opportunity_type: 'project_opportunity',
    payment_terms: 'weekly',
    expected_value: '',
    company: '',
    contact: currentTarget ? currentTarget.id : '',
    target_start: '',
    finish_date: '',
    budget: '',
    period: '1',
    connverted_to_project: false,
    opportunity_status: 'active'
  });


  const closeModal = () => {
    setShowModal(false);
    setFormData({
      name: '',
      description: '',
      opportunity_type: 'project_opportunity',
      payment_terms: 'weekly',
      expected_value: '',
      company: '',
      contact: currentTarget ? currentTarget.id : '',
      target_start: '',
      finish_date: '',
      budget: '',
      period: '1',
      connverted_to_project: false,
      opportunity_status: 'active'
    })
    setHtml("")
  };




  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [counter, setCounter] = useState(0)
  return (
    <>
      <Grid
        item
        sx={{
          width: '100%',
        }}
      >
        <Box className="card">
          <div className="row">
            <div className="col card-row">
              <img src={telegram} alt="telegram" />
              <p
                onClick={() => {
                  if (currentTarget?.email) {
                    const mailtoURL = `mailto:${currentTarget.email}`;
                    window.open(mailtoURL);
                  }
                  else {
                    return
                  }
                }}
                style={{ cursor: 'pointer' }}
                className="cardTxt">
                {currentTarget?.email ? currentTarget.email : 'Not Available'}
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col card-row">
              <img src={Phone} alt="Phone" />
              <p className="cardTxt">
                {currentTarget?.mobile_num
                  ? currentTarget.mobile_num
                  : 'Not Available '}
              </p>
            </div>
          </div>

          <div className="row">
            <div className="btnGroups">
              <button
                className="det-btn"
                onClick={() => {
                  navigate(`/Edit_Contact/${currentTarget.unique_id}`);
                }}
              >
                Edit Contact
              </button>
              {['bottom'].map((anchor) => (
                <>
                  <button
                    className="det-btn"
                    onClick={toggleDrawer(anchor, true)}
                  >
                    Create Task
                  </button>
                  <Drawer
                    anchor={anchor}
                    open={state[anchor]}
                    onClose={toggleDrawer(anchor, false)}
                  >
                    <CreateTask
                      anchor={anchor}
                      toggleDrawer={toggleDrawer}
                      state={state}
                      setState={setState}
                      currentTarget={currentTarget}
                      setCounter={setCounter}
                    />
                  </Drawer>
                </>
              ))}

              <button
                className="det-btn"
                onClick={(e) => setShowModal(true)}
                id="opp-btn"
              >
                New Opportunity
              </button>
            </div>
          </div>
        </Box>
      </Grid>

      {
        showModal && (
          <>
            <Drawer
              sx={{
                '& .MuiDrawer-paper': {
                  maxHeight: '82vh !important',
                },

              }}
              open={showModal}
              onClose={closeModal}
            >
              <Modal
                html={html}
                setHtml={setHtml}
                formData={formData}
                setFormData={setFormData}
                showModal={showModal}
                setShowModal={setShowModal}
                currentTarget={currentTarget}
                setCurrentTarget={setCurrentTarget}
              />
            </Drawer>
          </>
        )
      }
    </>
  );
};

const AtGlance = ({ currentTarget }) => {
  const dateToday = new Date();
  const date =
    dateToday.getFullYear() +
    '-' +
    (dateToday.getMonth() + 1) +
    '-' +
    dateToday.getDate();

  return (
    <Grid
      item
      sx={{
        width: '100%',
      }}
    >
      <Box
        sx={{
          height: '158px',
          backgroundColor: '#fff',
          borderRadius: '6px',
          border: '0.9px solid #d9d9d9',
          backgroundColor: '#f8f9fa',
          display: 'flex',
          flexDirection: 'column',
          padding: '1rem 12px',
        }}
      >
        <h3
          sx={{
            fontSize: '20px',
            fontWeight: '600',
            fontFamily: 'Open Sans',
            color: '#000000',
          }}
        >
          At a glance
        </h3>
        <p
          className="fstxt"
          sx={{
            marginBottom: '0px',
          }}
        >
          {currentTarget.first_name} was created by you on{' '}
          {currentTarget.activated.slice(0, 10)}.
        </p>
      </Box>
    </Grid>
  );
};

const client_id =
  '363129992018-im0psh5nfhu04vj0cv5bfdj440m4c0tf.apps.googleusercontent.com';

const redirect_url = `${ORIGIN}/Google_Auth`;

const Tags = ({ currentTarget }) => {
  return (
    <Grid
      container
      sx={{
        padding: '1rem 12px',
        backgroundColor: '#f8f9fa',
        border: '0.9px solid #d9d9d9',
        borderRadius: '10px',
        height: '128px',
        width: '100%',
      }}
    >
      <Grid item xs={12}>
        <div className="gs-10">
          <div className="colgs-10p90q">
            <h3 className="sub-title">Tags</h3>
            <Box className="tagBox" sx={{ display: 'flex', gap: '1rem' }}>
              {currentTarget.tags
                ? currentTarget.tags
                  .split(',')
                  .map((item) => <span className="tag">{item}</span>)
                : null}
            </Box>
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

const TabSystem = ({ currentTarget, setCurrentTarget }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const access_token = useSelector((state) => state.auth.user.access);
  const [value, setValue] = useState('Notes');
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function DateForm(originalDateString) {
    const date = new Date(originalDateString);
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const formattedDate = `On ${months[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;
    return formattedDate
  }
  
  const [html, setHtml] = useState('');
  const [btn, setbtn] = useState()
  const check = (e) => {
    setbtn(e)
  }


  const send_Note = async (e) => {
    if (e.shiftKey) {
    }
    else if (e.key === 'Enter') {
      e.preventDefault();
      setHtml('')
      btn?.editor?.commands.clearContent()
      const res = await fetch(
        `${ENDPOINT}/api/contact/note/create/`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `JWT ${access_token}`,
          },
          body: JSON.stringify({
            contact_note: currentTarget.id,
            note: html,
          }),
        }
      );
      const data = await res.json();
      const resGet = await fetch(`${ENDPOINT}/api/contact/detail/${currentTarget.unique_id}/`, {
        method: 'GET',
        headers: {
          Authorization: `JWT ${access_token}`,
          'Content-Type': 'application/json',
        },
      });

      if (!resGet.ok) {
        const dataGet = await resGet.json();
      }
      else {
        const dataGet = await resGet.json();
        setCurrentTarget(dataGet);
      }
      dispatch(getPersons({ access_token }));
    }
  }

  return (
    <>
      <Box
        sx={{
          width: '100%',
          paddingRight: '10px',
          paddingLeft: '10px',
          paddingTop: '3.36%',
          paddingBottom: '3.36%',
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          textColor="secondary"
          indicatorColor="secondary"
          aria-label="secondary tabs example"
          sx={{
            borderBottom: '2px solid #A0A2AA',

            '& .MuiTabs-indicator': {
              backgroundColor: '#000',
            },

            '& .MuiTab-textColorSecondary.Mui-selected': {
              color: '#711fff',
              borderBottom: '2px solid #5B5A5A',

              fontWeight: 'bold',
              fontFamily: 'Open Sans',
            },

            '& .MuiTab-textColorSecondary': {
              color: 'rgba(3, 7, 30, 0.4)',
              fontFamily: 'Open Sans',
            },

            '& .MuiTabs-flexContainer': {
              justifyContent: 'space-between',
            },
          }}
        >
          <Tab
            value="Notes"
            label={
              currentTarget.contact_note.length > 0
                ? `Notes (${currentTarget.contact_note.length})`
                : 'Notes'
            }
          />
          <Tab
            value="Tasks"
            label={
              currentTarget.contact_task.length > 0
                ? `Tasks (${currentTarget.contact_task.length})`
                : 'Tasks'
            }
          />
          <Tab
            value="Opportunities"
            label={
              currentTarget?.contact_opportunity?.length > 0
                ? `Sales (${currentTarget.contact_opportunity.length})`
                : currentTarget.contact_estimate.length > 0
                  ? `Sales (${currentTarget.contact_estimate.length})`
                  : 'Sales'

            }
          />
          <Tab
            value="Projects"
            label={
              currentTarget?.contact_project?.length > 0
                ? `Projects (${currentTarget?.contact_project.length})`
                : 'Projects'
            }
          />
        </Tabs>
      </Box>
      <Grid
        item
        container
        direction="column"
        alignItems="flex-start"
      >
        {value === 'Notes' && (
          <Grid
            item
            container
            direction="column"
            xs={12}
            justifyContent="space-between"
          >
            <List
              sx={{
                width: '100%',
                height: '510px',
                overflowY: 'auto',
                marginTop: '-1rem',
              }}
            >
              {currentTarget.contact_note.length > 0 ? (
                currentTarget.contact_note.map((item) => (
                  <>
                    <ListItem alignItems="flex-start" sx={{ alignItems: 'center' }}>
                      <ListItemAvatar>
                        <Avatar
                          alt={item.created_by.first_name}
                          src={`${ENDPOINT}/${item.created_by.avatar}`}
                        />
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <Typography
                            variant="subtitle1"
                            sx={{
                              fontWeight: 'bold',
                              fontFamily: 'Open Sans',
                              fontSize: '1rem',
                            }}
                          >
                            {item.created_by.first_name + ' ' + item.created_by.last_name}
                          </Typography>
                        }
                      />
                      <Typography sx={{ color: 'rgba(3, 7, 30, 0.4)' }}>{DateForm(item.created_at)}</Typography>
                    </ListItem>
                    <Box sx={{ padding: '0rem 1.24rem' }}><Typography
                      sx={{
                        color: '#03071E',
                        fontSize: '14px',
                      }}
                      variant="subtitle2"
                      dangerouslySetInnerHTML={{ __html: item.note }}
                    >

                    </Typography></Box>
                    <Divider
                      component="li"
                      sx={{
                        marginLeft: '2.25%',
                        marginRight: '2.25%',
                      }}
                    />
                  </>
                ))
              ) : (
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontWeight: 'bold',
                    paddingLeft: '10px',
                    paddingRight: '10px',
                  }}
                >
                  Ooops... You don't have any notes for this person yet...
                </Typography>
              )
              }
            </List>
            <Grid item sx={{ width: '100%' }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                  alignItems: 'end',
                  px: '10px',
                }}
              >
                <section
                  className="editorNoteContacts px-2 pt-1 mb-2 rounded"
                  onKeyDown={send_Note}
                >
                  <Box sx={{ height: '150px', overflow: 'hidden' }}>
                    <Test setHtml={setHtml} html={html} check={check} auto_focus={false} />
                  </Box>
                </section>
                <button
                  className="create-btn"
                  id="c-btn"
                  style={{
                    marginLeft: '10px',
                    marginBottom: '10px'
                  }}
                  onClick={
                    async (e) => {
                      if (html.length > 0) {
                        setLoading(true);
                        e.preventDefault();
                        setHtml('')
                        btn?.editor?.commands.clearContent()
                        const res = await fetch(
                          `${ENDPOINT}/api/contact/note/create/`,
                          {
                            method: 'POST',
                            headers: {
                              'Content-Type': 'application/json',
                              Authorization: `JWT ${access_token}`,
                            },
                            body: JSON.stringify({
                              contact_note: currentTarget.id,
                              note: html,
                            }),
                          }
                        );
                        const data = await res.json();
                        setLoading(false);
                        const resGet = await fetch(`${ENDPOINT}/api/contact/detail/${currentTarget.unique_id}/`, {
                          method: 'GET',
                          headers: {
                            Authorization: `JWT ${access_token}`,
                            'Content-Type': 'application/json',
                          },
                        });

                        if (!resGet.ok) {
                          const dataGet = await resGet.json();
                        }
                        else {
                          const dataGet = await resGet.json();
                          setCurrentTarget(dataGet);
                        }
                        dispatch(getPersons({ access_token }));
                      }
                    }
                  }
                >
                  {loading ? (
                    <i className="bx bx-loader-alt bx-spin" />
                  ) : (
                    'Save'
                  )}
                </button>
              </Box>
            </Grid>
          </Grid>
        )}
      </Grid >

      {/* Tasks Tab Detail */}
      < Grid
        item
        container
        direction="column"
        alignItems="flex-start"
        sx={{
          padding: '0 2rem',
        }
        }
      >
        {value === 'Tasks' && (
          <Grid
            item
            container
            direction="column"
            xs={12}
            justifyContent="space-between"
          >
            <List
              sx={{
                width: '100%',
                height: '510px',
                overflowY: 'auto',
              }}
            >
              <ListItem alignItems="flex-start">
                <Box
                  className="autoWidth"
                  sx={{
                    border: '1px solid #d9d9d9',
                    paddingTop: '0.66%',
                    paddingBottom: '0.66%',
                    paddingLeft: '2.7%',
                    paddingRight: '2.7%',
                    marginTop: '-1rem',
                    marginBottom: '1rem',
                    borderRadius: '8px',
                    background: '#FCFCFC',
                  }}
                >
                  <Grid container justifyContent="space-between" spacing={1}>
                    <Grid item>
                      <Typography
                        variant="subtitle1"
                        sx={{
                          fontWeight: 'bold',
                          fontFamily: 'Open Sans',
                          fontSize: '1rem',
                          color: '#711FFF',
                          paddingLeft: '2.7%',
                          paddingRight: '2.7%',
                        }}
                      >
                        Tasks
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="subtitle1"
                        sx={{
                          fontWeight: 'bold',
                          fontFamily: 'Open Sans',
                          fontSize: '1rem',
                          color: '#711FFF',
                        }}
                      >
                        Status
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </ListItem>
              {currentTarget.contact_task &&
                currentTarget.contact_task.length > 0 ? (
                currentTarget.contact_task.map((item) => (
                  <>
                    <ListItem
                      alignItems="flex-start"
                      sx={{
                        marginTop: '2.25%',
                        marginBottom: '2.25%',
                        paddingLeft: '5%',
                        paddingRight: '5%',
                      }}
                    >
                      <Grid
                        container
                        justifyContent="space-between"
                        spacing={1}
                      >
                        <Grid item>
                          <Typography
                            variant="subtitle1"
                            sx={{
                              fontWeight: 'bold',
                              fontFamily: 'Open Sans',
                              fontSize: '1rem',
                              color: '#310085',
                            }}
                          >
                            {item.task_name}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography
                            variant="subtitle1"
                            sx={{
                              fontWeight: 'bold',
                              fontFamily: 'Open Sans',
                              fontSize: '1rem',
                              color: '#310085',
                              textTransform: 'uppercase',
                            }}
                          >
                            {item.status}
                          </Typography>
                        </Grid>
                      </Grid>
                    </ListItem>

                    <Divider
                      component="li"
                      sx={{
                        marginLeft: '2.25%',
                        marginRight: '2.25%',
                      }}
                    />
                  </>
                ))
              ) : (
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontWeight: 'bold',
                    paddingLeft: '5%',
                    paddingRight: '5%',
                  }}
                >
                  You don't have any tasks for this contact yet, you can always
                  use the "+ New Task" button to create one.
                </Typography>
              )}
            </List>
          </Grid>
        )}
      </Grid >

      {/* Email Tab Detail */}
      < Grid
        item
        container
        direction="column"
        alignItems="flex-start"
        sx={{
          padding: '0 2rem',
        }}
      >
        {value === 'Emails' && (
          <Grid
            item
            container
            direction="column"
            xs={12}
            justifyContent="space-between"
          >
            <List
              sx={{
                width: '100%',
                height: '510px',
                overflowY: 'auto',
                position: 'relative',
              }}
            >
              {Array.isArray(currentTarget.contact_email) &&
                currentTarget.contact_email.length > 0 && (
                  <Tooltip title="Fetch Latest Emails">
                    <RefreshIcon
                      sx={{
                        position: 'absolute',
                        zIndex: '999',
                        top: '-3px',
                        right: '1%',
                        cursor: 'pointer',
                        transition: 'all 0.3s ease-in-out',
                        '&:hover': {
                          fill: '#711fff',
                        },
                      }}
                      onClick={async (e) => {
                        e.preventDefault();
                        const res = await fetch(
                          `${ENDPOINT}/api/user/callback/`,
                          {
                            method: 'POST',
                            headers: {
                              Authorization: `JWT ${access_token}`,
                              'Content-Type': 'application/json',
                            },
                          }
                        );
                      }}
                    />
                  </Tooltip>
                )}
              {currentTarget.contact_email &&
                currentTarget.contact_email.length > 0 ? (
                currentTarget.contact_email.map((item) => (
                  <>
                    <ListItem alignItems="flex-start">
                      <EmailCard
                        title={item.subject}
                        body={item.body}
                        date={item.date}
                      />
                    </ListItem>
                  </>
                ))
              ) : (
                <Box
                  sx={{
                    width: '100%',
                    height: '60%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                    }}
                  >
                    <p className="fstxt">No Emails Yet, Sign In To continue</p>
                    <GoogleButton
                      onClick={() => {
                        window.open(
                          `https://accounts.google.com/o/oauth2/v2/auth?response_type=code&client_id=${client_id}&redirect_uri=${redirect_url}&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fgmail.readonly&prompt=consent&access_type=offline`,
                          '_blank'
                        );
                      }}
                    />
                  </Box>
                </Box>
              )}
            </List>
          </Grid>
        )}
      </Grid >

      {/* Opportunities Tab Detail */}
      < Grid
        item
        container
        direction="column"
        alignItems="flex-start"
        sx={{
          padding: '0 2rem',
        }}
      >
        {value === 'Opportunities' && (
          <Grid
            item
            container
            direction="column"
            xs={12}
            justifyContent="space-between"
          >
            <List
              sx={{
                width: '100%',
                height: '510px',
                overflowY: 'auto',
              }}
            >
              <ListItem alignItems="flex-start">
                <Box
                  className="autoWidth"
                  sx={{
                    border: '1px solid #d9d9d9',
                    paddingTop: '0.66%',
                    paddingBottom: '0.66%',
                    paddingLeft: '2.18%',
                    paddingRight: '2.18%',
                    marginTop: '-1rem',
                    marginBottom: '1rem',
                    borderRadius: '8px',
                    background: '#FCFCFC',
                  }}
                >
                  <Grid container justifyContent="space-between" spacing={1}>
                    <Grid item>
                      <Typography
                        variant="subtitle1"
                        sx={{
                          fontWeight: 'bold',
                          fontFamily: 'Open Sans',
                          fontSize: '1rem',
                          color: '#711FFF',
                        }}
                      >
                        Opportunities
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="subtitle1"
                        sx={{
                          fontWeight: 'bold',
                          fontFamily: 'Open Sans',
                          fontSize: '1rem',
                          color: '#711FFF',
                        }}
                      >
                        Status
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </ListItem>
              {currentTarget.contact_opportunity && currentTarget.contact_opportunity.length > 0 ? (
                currentTarget.contact_opportunity.map((item) => (
                  <>
                    <ListItem
                      alignItems="flex-start"
                      sx={{
                        marginTop: '2.25%',
                        marginBottom: '2.25%',
                      }}
                      key={item.id}
                    >
                      <Grid container justifyContent="space-between" spacing={1}>
                        <Grid item>
                          <Typography
                            variant="subtitle1"
                            sx={{
                              fontWeight: 'bold',
                              fontFamily: 'Open Sans',
                              fontSize: '1rem',
                              color: '#310085',
                            }}
                          >
                            {item.name}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography
                            variant="subtitle1"
                            sx={{
                              fontWeight: 'bold',
                              fontFamily: 'Open Sans',
                              fontSize: '1rem',
                              color: item.opportunity_status !== 'lost' ? '#310085' : 'red',
                              textTransform: 'capitalize',
                            }}
                          >
                            {item.opportunity_status !== 'lost'
                              ? item.opportunity_type
                              : item.opportunity_status}
                          </Typography>
                        </Grid>
                      </Grid>
                    </ListItem>
                    <Divider
                      component="li"
                      sx={{
                        marginLeft: '2.25%',
                        marginRight: '2.25%',
                      }}
                    />
                  </>
                ))
              ) : (
                currentTarget.contact_estimate && currentTarget.contact_estimate > 0 ? (
                  currentTarget.contact_estimate.map((item) => (
                    <>
                      <ListItem
                        alignItems="flex-start"
                        sx={{
                          marginTop: '2.25%',
                          marginBottom: '2.25%',
                        }}
                        key={item.id}
                      >
                        <Grid container justifyContent="space-between" spacing={1}>
                          <Grid item>
                            <Typography
                              variant="subtitle1"
                              sx={{
                                fontWeight: 'bold',
                                fontFamily: 'Open Sans',
                                fontSize: '1rem',
                                color: '#310085',
                              }}
                            >
                              {item.title}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography
                              variant="subtitle1"
                              sx={{
                                fontWeight: 'bold',
                                fontFamily: 'Open Sans',
                                fontSize: '1rem',
                                color: item.estimate_status !== 'lost' ? '#310085' : 'red',
                                textTransform: 'capitalize',
                              }}
                            >
                              {item.estimate_status !== 'lost' ? item.type : item.estimate_status}
                            </Typography>
                          </Grid>
                        </Grid>
                      </ListItem>
                      <Divider
                        component="li"
                        sx={{
                          marginLeft: '2.25%',
                          marginRight: '2.25%',
                        }}
                      />
                    </>
                  ))
                ) : (
                  <Typography
                    variant="subtitle1"
                    sx={{
                      fontWeight: 'bold',
                      paddingLeft: '5%',
                      paddingRight: '5%',
                    }}
                  >
                    Ooops... You don't have any Opportunities for this person yet...
                  </Typography>
                )
              )}

            </List>
          </Grid>
        )}
      </Grid >
      < Grid
        item
        container
        direction="column"
        alignItems="flex-start"
        sx={{
          padding: '0 2rem',
        }
        }
      >
        {value === 'Projects' && (
          <Grid
            item
            container
            direction="column"
            xs={12}
            justifyContent="space-between"
          >
            <List
              sx={{
                width: '100%',
                height: '510px',
                overflowY: 'auto',
              }}
            >
              <ListItem alignItems="flex-start">
                <Box
                  className="autoWidth"
                  sx={{
                    border: '1px solid #d9d9d9',
                    paddingTop: '0.66%',
                    paddingBottom: '0.66%',
                    paddingLeft: '2.7%',
                    paddingRight: '2.7%',
                    marginTop: '-1rem',
                    marginBottom: '1rem',
                    borderRadius: '8px',
                    background: '#FCFCFC',
                  }}
                >
                  <Grid container justifyContent="space-between" spacing={1}>
                    <Grid item>
                      <Typography
                        variant="subtitle1"
                        sx={{
                          fontWeight: 'bold',
                          fontFamily: 'Open Sans',
                          fontSize: '1rem',
                          color: '#711FFF',
                          paddingLeft: '2.7%',
                          paddingRight: '2.7%',
                        }}
                      >
                        Projects
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="subtitle1"
                        sx={{
                          fontWeight: 'bold',
                          fontFamily: 'Open Sans',
                          fontSize: '1rem',
                          color: '#711FFF',
                        }}
                      >
                        Status
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </ListItem>

              {currentTarget.contact_project &&
                currentTarget.contact_project.length > 0 ? (
                currentTarget.contact_project.map((item) => (
                  <>
                    <ListItem
                      alignItems="flex-start"
                      sx={{
                        marginTop: '2.25%',
                        marginBottom: '2.25%',
                        paddingLeft: '5%',
                        paddingRight: '5%',
                      }}
                    >
                      <Grid
                        container
                        justifyContent="space-between"
                        spacing={1}
                      >
                        <Grid item>
                          <Typography
                            variant="subtitle1"
                            sx={{
                              fontWeight: 'bold',
                              fontFamily: 'Open Sans',
                              fontSize: '1rem',
                              color: '#310085',
                            }}
                          >
                            {item.title}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography
                            variant="subtitle1"
                            sx={{
                              fontWeight: 'bold',
                              fontFamily: 'Open Sans',
                              fontSize: '1rem',
                              color: item.status !== 'lost' ? '#310085' : 'red',
                              textTransform: 'capitalize',
                            }}
                          >
                            {item.status !== 'lost' ? item.status : item.project_type}
                          </Typography>
                        </Grid>
                      </Grid>
                    </ListItem>

                    <Divider
                      component="li"
                      sx={{
                        marginLeft: '2.25%',
                        marginRight: '2.25%',
                      }}
                    />
                  </>
                ))
              ) : (
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontWeight: 'bold',
                    paddingLeft: '5%',
                    paddingRight: '5%',
                  }}
                >
                  You don't have any project for this contact yet, you can always
                  use the "+ New Task" button to create one.
                </Typography>
              )}
            </List>
          </Grid>
        )}
      </Grid >

      {/*  Social Feed Detail  */}
      < Grid
        item
        container
        direction="column"
        alignItems="flex-start"
        sx={{
          padding: '0 2rem',
        }}
      >
        {value === 'social' && <SocialScrap currentTarget={currentTarget} />}
      </Grid >
    </>
  );
};


const PersonDet = ({ screenHeight }) => {
  const currentCompanies = useSelector((state) => state.Contacts.companies);
  const [showCompany, setShowCompany] = useState(false);
  const [companyTarget, setCompanyTarget] = useState({
    id: 1,
    name: 'Company 1',
    address: 'Address 1',
    address_zip: '',
    linkedin_url: '',
    facebook_url: '',
    twitter_url: '',
    instagram_url: '',
    contact_type: '',
    tags: '',
    category: '',
    activated: '',
    created_by: 1,
    state: '',
    city: '',
    company_note: [],
    company_contacts: [],
  });

  const { id } = useParams();
  const navigate = useNavigate();

  const [isDataLoading, setIsDataLoading] = useState(true);

  const peopleList = useSelector((state) => state.Contacts.persons);
  const [ourList, setOurList] = useState([]);
  const [formData, setFormData] = useState({
    id: 1,
    note: '',
    company: {},
    opportunity: '',
    avatar: '',
    title: '',
    first_name: '',
    last_name: '',
    email: '',
    email2: '',
    office_tel: '',
    mobile_num: '',
    country: '',
    street: '',
    city: '',
    state: '',
    contact_note: [],
    address_zip: '',
    linkedin_url: '',
    facebook_url: '',
    twitter_url: '',
    instagram_url: '',
    contact_type: '',
    tags: '',
    category: '',
    activated: '',
    created_by: 1,
    project: '',
  });

  const [currentTarget, setCurrentTarget] = useState(formData);
  const access_token = useSelector((item) => item.auth.user.access)

  const [state, setState] = React.useState({
    bottom: false,
  });

  useEffect(() => {
    if (peopleList) {
      setOurList(peopleList);
    }
  }, [peopleList]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await fetch(`${ENDPOINT}/api/contact/detail/${id}/`, {
          method: 'GET',
          headers: {
            Authorization: `JWT ${access_token}`,
            'Content-Type': 'application/json',
          },
        });

        if (!res.ok) {
          const data = await res.json();
          navigate('/People')
        } else {
          const data = await res.json();
          if (data) {
            setFormData(data);
            setCurrentTarget(data);
            setIsDataLoading(false);

          }

        }
      } catch (error) {
      }
    };

    fetchData();
  }, [peopleList, id, state]);

  return isDataLoading ? (
    <section
      className="loading"
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100%',
        backgroundColor: 'rgba(255,255,255,0.5)',
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 999,
      }}
    >
      <Box
        sx={{
          width: '100px',
          height: '100px',
        }}
      >
        <PreLoader />
      </Box>
    </section>
  ) : (
    <>
      <section className={'oppor-section5'}>
        <div className="oppor-row fixed">
          <article
            className="Center"
            style={{
              position: 'realitve',
              marginBottom: '3.15%',
              marginBottom: '5.72%',
            }}
          >
            <ArrowBackIcon
              sx={{
                position: 'absolute',
                bottom: '32px',
                left: '0px',
                cursor: 'pointer',
                '&:hover': {
                  transform: 'scale(1.05)',
                },
              }}
              onClick={() => navigate('/People')}
            />
            <Avatar
              sx={{ width: 80, height: 80 }}
              src={currentTarget?.avatar ? currentTarget.avatar : ''}
              alt={currentTarget ? currentTarget?.first_name : ''}
            >
              {currentTarget && currentTarget?.first_name[0] && currentTarget?.first_name[0]}
            </Avatar>
            <div className="dFlex col-md-6">
              <h3 className="fstxtPro noPad">
                {currentTarget?.first_name} {currentTarget?.last_name}
              </h3>
              <div
                className="dFlex"
                style={{
                  gap: '0.1rem',
                }}
              >
                <h3 className="fstxt">
                  {currentTarget?.title ? currentTarget?.title + ' ' + 'at' : ''}
                </h3>{' '}
                <h3
                  className="specialTxT"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    const targetCompany = currentCompanies.filter(
                      (item) => item.name === currentTarget?.company
                    )[0];
                    setCompanyTarget(targetCompany ? targetCompany : null);
                    setShowCompany(targetCompany ? true : null);
                  }}
                >
                  {currentTarget?.company ? currentTarget?.company : ''}
                </h3>
              </div>
            </div>
          </article>
        </div>
        <Box
          sx={{
            maxHeight: screenHeight - 285,
            overflow: 'auto',
            border: '1px solid #d9d9d9',
            padding: '1.5rem 1.5rem',
            position: 'relative',
            borderRadius: '8px',
            display: 'flex',
            justifyContent: 'center',
            background: '#fff',
            marginBottom: '22px',
          }}
        >
          <Grid
            container
            spacing={0}
            sx={{
              width: '100%',
              gap: '1rem',
            }}
          >
            <Grid
              item
              container
              xs={12}
              sm={12}
              md={5.4}
              lg={5.4}
              xl={5.4}
              direction="column"
              alignItems="flex-start"
              sx={{
                gap: '1rem',
              }}
            >
              <Card
                currentTarget={currentTarget}
                setCurrentTarget={setCurrentTarget}
                id={id}
                setState={setState}
                state={state}

              />
              <AtGlance currentTarget={currentTarget} />
              <ContactInfo formData={formData} setFormData={setFormData} />

              <Tags currentTarget={currentTarget} />
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              md={6.4}
              lg={6.4}
              xl={6.4}
              container
              direction="column"
              sx={{
                backgroundColor: '#fff',
                borderRadius: '8px',
                border: '1px solid #d9d9d9',
                height: { xs: '780px', md: '760px', lg: '770px', xl: '810px' },
              }}
            >
              <TabSystem currentTarget={currentTarget} setCurrentTarget={setCurrentTarget} />
            </Grid>
          </Grid>
        </Box>
      </section>
      <CompanyModal
        companyTarget={companyTarget}
        show={showCompany}
        setShow={setShowCompany}
        setCompanyTarget={setCompanyTarget}
        currentTarget={currentTarget}
        setCurrentTarget={setCurrentTarget}
      />
    </>
  );
};

export default PersonDet;
