
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress';
import { Tooltip } from '@mui/material';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 8,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: 'rgba(3, 7, 30, 0.2)',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: '#5EE690',
  },
}));

const ProgressNetBar = (props) => (
  <Box sx={{ flexGrow: 1 }}>
    <BorderLinearProgress variant="determinate" {...props} />
  </Box>
);

export default ProgressNetBar;

const NetKanbanPrograss = (props) => {
  const { status, value, eta, obt } = props;
  const val = Math.round((obt / eta) * 100);
  const BoxProgress = styled(LinearProgress)(({ theme }) => ({
    height: 30,
    width: 144,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    }, //
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 0,
      backgroundColor:
        val <= 50
          ? '#5FCEA8'
          : val >= 80 && val <= 100
            ? '#F59825'
            : val >= 100
              ? '#FA2D23'
              : val <= 0
                ? '#5FCEA8'
                : '#f59825',
    },
  }));
  const hours = Math.floor(eta); // Extract hours (integer part)
  const minutes = Math.round((eta - hours) * 60); // Extract minutes

  // Format hours and minutes with leading zeros if necessary
  const formattedHours = hours < 10 ? `0${hours}` : hours;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

  // Create the formatted string
  const formattedEta = `${formattedHours}:${formattedMinutes}`;

  const hoursObt = Math.floor(obt); // Extract hours (integer part)
  const minutesObt = Math.round((obt - hoursObt) * 60); // Extract minutes

  // Format hours and minutes with leading zeros if necessary
  const formattedHoursObt = hoursObt < 10 ? `0${hoursObt}` : hoursObt;
  const formattedMinutesObt = minutesObt < 10 ? `0${minutesObt}` : minutesObt;

  // Create the formatted string
  const formattedEtaObt = `${formattedHoursObt}:${formattedMinutesObt}`;

  return (
    <Box sx={{ position: 'relative', padding: '0px 0px', width: "18rem" }}>
      <BoxProgress
        value={val >= 100 ? 100 : val === 0 ? 1 : val}
        variant="determinate"
        style={{ background: "#e0dede", width: "250px" }}
      />
      <Box
        sx={{
          position: 'absolute',

          marginLeft: 'auto',
          marginRight: 'auto',
          right: 0,
          top: 6,
          left: '17px',
          fontWeight: '700',
          fontFamily: 'Open Sans',
          display: 'flex',
          fontSize: '12px',
          padding: '0px 2px',

        }}
      >
        {formattedEtaObt}
      
        /{' '}
        <p style={{ fontWeight: 700 }}>
          {formattedEta} Hr
        </p>
      </Box>
    </Box>
  );
};


const NetProgress = (props) => {
  const { status, value, eta, obt } = props;
  const val = Math.round((obt / eta) * 100);
  const BoxProgress = styled(LinearProgress)(({ theme }) => ({
    height: 30,
    width: 144,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    }, //
    // ,
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 0,
      backgroundColor:
        val <= 50
          ? '#5FCEA8'
          : val >= 80 && val <= 100
            ? '#F59825'
            : val >= 100
              ? '#FA2D23'
              : val <= 0
                ? '#5FCEA8'
                : '#f59825',
    },
  }));
  const hours = Math.floor(eta); // Extract hours (integer part)
  const minutes = Math.round((eta - hours) * 60); // Extract minutes

  // Format hours and minutes with leading zeros if necessary
  const formattedHours = hours < 10 ? `0${hours}` : hours;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

  // Create the formatted string
  const formattedEta = `${formattedHours}:${formattedMinutes}`;

  const hoursObt = Math.floor(obt); // Extract hours (integer part)
  const minutesObt = Math.round((obt - hoursObt) * 60); // Extract minutes

  // Format hours and minutes with leading zeros if necessary
  const formattedHoursObt = hoursObt < 10 ? `0${hoursObt}` : hoursObt;
  const formattedMinutesObt = minutesObt < 10 ? `0${minutesObt}` : minutesObt;

  // Create the formatted string
  const formattedEtaObt = `${formattedHoursObt}:${formattedMinutesObt}`;

  return (
    <Box sx={{ position: 'relative', padding: '0px 0px' }}>
      <BoxProgress
        value={val >= 100 ? 100 : val === 0 ? 1 : val}
        variant="determinate"
      />
      <Box
        sx={{
          position: 'absolute',
          marginLeft: 'auto',
          marginRight: 'auto',
          right: 0,
          top: 5,
          left: '19px',
          fontWeight: '500',
          fontFamily: 'Open Sans',
          display: 'flex',
          fontSize: '15px',
          padding: '0px 0px',
        }}
      >
        {formattedEtaObt}
        /{' '}
        <p style={{ fontWeight: 600 }}>
          {formattedEta} Hr
        </p>
      </Box>
    </Box>
  );
};

const NetProgressProject = (props) => {
  const { status, value, eta, obt } = props;
  const val = Math.round((obt / eta) * 100);
  
  const BoxProgress = styled(LinearProgress)(({ theme }) => ({
    height: 11,
    width: 124,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    }, //
    // ,
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 0,
      backgroundColor:
        val <= 50
          ? '#5FCEA8'
          : val >= 80 && val <= 100
            ? '#F59825'
            : val >= 100
              ? '#FA2D23'
              : val <= 0
                ? '#5FCEA8'
                : '#f59825',
    },
  }));
  const hours = Math.floor(eta); // Extract hours (integer part)
  const minutes = Math.round((eta - hours) * 60); // Extract minutes

  // Format hours and minutes with leading zeros if necessary
  const formattedHours = hours < 10 ? `0${hours}` : hours;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

  // Create the formatted string
  const formattedEta = `${formattedHours}:${formattedMinutes}`;

  const hoursObt = Math.floor(obt); // Extract hours (integer part)
  const minutesObt = Math.round((obt - hoursObt) * 60); // Extract minutes

  // Format hours and minutes with leading zeros if necessary
  const formattedHoursObt = hoursObt < 10 ? `0${hoursObt}` : hoursObt;
  const formattedMinutesObt = minutesObt < 10 ? `0${minutesObt}` : minutesObt;

  // Create the formatted string
  const formattedEtaObt = `${formattedHoursObt}:${formattedMinutesObt}`;

  return (
    <Box sx={{ position: 'relative', padding: '0px 0px', }}>
      <Box
        sx={{
          position: 'absolute',

          left: '-40px',
          width: "-38%",
          top: "-2px",
          display: 'flex',
          alignItems: 'center',
          fontSize: '11px',
          fontWeight: 'bold',
          fontFamily: 'Open Sans',
          color: 'black',
        }}
      >

        <Tooltip title={"Actual"}>
          <span>{formattedEtaObt}</span>
        </Tooltip>
      </Box>

      {/* Progress Bar */}
      <Tooltip title={formattedEtaObt}>
        <BoxProgress
          value={val >= 100 ? 100 : val === 0 ? 1 : val}
          variant="determinate"
        />
      </Tooltip>
      <Box
        sx={{
          position: 'absolute',
          width: "20%",
          right: '-38px',
          top: "-2px",
          display: 'flex',
          alignItems: 'center',
          fontSize: '11px',
          fontWeight: 'bold',
          fontFamily: 'Open Sans',
          color: 'black',
        }}
      >
        <Tooltip title={"Assigned"}>
          <span>{formattedEta}</span>
        </Tooltip>
      </Box>
    </Box>
  );
};
const NetProgressProjectLOE = (props) => {
  const { status, value, eta, obt, startDate, endDate } = props;

  const val = Math.round((obt / eta) * 100);
  const BoxProgress = styled(LinearProgress)(({ theme }) => ({
    height: 15,
    width: 350,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    }, //
    // ,
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 0,
      backgroundColor:
        val <= 50
          ? '#5FCEA8'
          : val >= 80 && val <= 100
            ? '#F59825'
            : val >= 100
              ? '#FA2D23'
              : val <= 0
                ? '#5FCEA8'
                : '#f59825',
    },
  }));
  const hours = Math.floor(eta); // Extract hours (integer part)
  const minutes = Math.round((eta - hours) * 60); // Extract minutes

  // Format hours and minutes with leading zeros if necessary
  const formattedHours = hours < 10 ? `0${hours}` : hours;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

  // Create the formatted string
  const formattedEta = `${formattedHours}:${formattedMinutes}`;

  const hoursObt = Math.floor(obt); // Extract hours (integer part)
  const minutesObt = Math.round((obt - hoursObt) * 60); // Extract minutes

  // Format hours and minutes with leading zeros if necessary
  const formattedHoursObt = hoursObt < 10 ? `0${hoursObt}` : hoursObt;
  const formattedMinutesObt = minutesObt < 10 ? `0${minutesObt}` : minutesObt;

  // Create the formatted string
  const formattedEtaObt = `${formattedHoursObt}:${formattedMinutesObt}`;

  return (
    <Box sx={{ position: 'relative', padding: '0px 0px' }}>
      <BoxProgress
        value={val >= 100 ? 100 : val === 0 ? 1 : val}
        variant="determinate"
      />
      <Box
        sx={{
          position: 'absolute',
          marginLeft: 'auto',
          marginRight: 'auto',
          right: startDate ? '2px' : val <= 50 ? 20 : 2,
          top: 0,
          left: startDate ? '2px' : (val <= 30 ? '10px' : val <= 50 ? '30px' : val <= 70 ? '50px' : val <= 90 ? '70px' : '100px'),
          fontWeight: '500',
          fontFamily: 'Open Sans',
          display: 'flex',
          alignItems: 'center',
          fontSize: '11px',
          padding: '0px 0px',
          fontWeight: 'bold',
          justifyContent: 'space-between'
        }}
      >


        <p style={{ fontWeight: 'bold', marginTop: '0px' }} > {startDate ? startDate : formattedEtaObt}</p>
        <p style={{ fontWeight: 'bold', marginTop: '0px' }}>

          {endDate ? endDate : formattedEta}
        </p>
      </Box>
    </Box>
  );
};

export { NetProgress, NetProgressProject, NetKanbanPrograss, NetProgressProjectLOE };



