import React from "react";
import { useSelector } from "react-redux";
import { Grid, Box, Typography } from "@mui/material";
import initalConfig from "./config";


const InitalizeParam = ({ params, setParams, screenHeight }) => {
  const allTasks = useSelector((state) => state.projects.tasks);
  const Projects = useSelector(state => state.projects.projects)

  return (
    <>
      <Typography sx={{
        fontWeight: '600',
        color: '#000',
        fontSize: '1.2rem',
        mt: '3rem',
        ml: '2rem'
      }}>
        Select to see your Desired Task
      </Typography>
      <Grid container justifyContent='center' alignItems='center' sx={{ height: "auto", gap: "2rem" }}>
        {initalConfig.map((item) => {
          return (
            <Box sx={{
              backgroundColor: item.bg,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              height: '150px',
              width: '420px',
              borderRadius: '8px',
              p: '0.44rem 1.88rem',
              justifyContent: 'center',
              gap: '.5rem',
              my: '1rem',
              cursor: 'pointer'
            }}
              onClick={(_) => {
                console.log('line 1683', params)
                if (item.param === 'time') {
                  setParams({
                    value: 'time',
                    data: {
                      chartData: allTasks.map((item) => {
                        if (
                          !Array.isArray(item.time_tracking) ||
                          !item.time_tracking.length
                        ) {
                          return;
                        }

                        const extraction = [
                          ...item.time_tracking.map((tracking) => ({
                            ...tracking,
                            name: item.task_name,
                            project: item.task_project,
                          })),
                        ];

                        return extraction;
                      }),
                    },
                  })
                }


                if (item.param === 'tasks') {
                  setParams({
                    value: 'quick_task',
                    data: {
                      chartData: allTasks
                    },
                  })
                }
                if (item.param === 'team') {
                  setParams({
                    value: 'team',
                    data: {
                      chartData: allTasks
                    },
                    projects: Projects,
                    finalFilterArray: []
                  })
                }
                if (item.param === 'sales') {
                  setParams({
                    value: 'sales',
                    data: {}
                  })
                }
                if (item.param === 'est') {
                  setParams({
                    value: 'estimate',
                    data: {}
                  })
                }
              }
              }
            >
              <Typography sx={{
                color: '#fff',
                fontWeight: '600',
                fontSize: '1.1rem',

              }}>
                {item.title}
              </Typography>
              <Typography sx={{
                color: '#fff',
                fontSize: '1rem'
              }}>
                {item.desc}
              </Typography>
            </Box>
          )
        })}
      </Grid>
    </>
  )
}

export default InitalizeParam