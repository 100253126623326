import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useEffect } from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { getEstimates } from '../../Opportunity/Redux/oppor';
import { formatMoneyString } from '../NewProject/PFifth';
import {  DashedField3 } from '../../../Componenets/Misc';
import { Box } from '@mui/system';
import { TextField, Tooltip, Typography } from '@mui/material';
import {  divide, multiply, subtract } from 'mathjs';
import uuid from 'react-uuid';

const calculateLOERate = (deliverable, work) => {
  if (
    !deliverable ||
    !Array.isArray(deliverable.deliverable_worktype) ||
    !deliverable.deliverable_worktype.length ||
    !Array.isArray(work) ||
    !work.length
  ) {
    return 0;
  }

  let total = 0;
  deliverable.deliverable_worktype.forEach((worktype) => {
    const matchingWork = work.find((item) => item.id === worktype.id);
    if (matchingWork) {
      total += Number(matchingWork.user_rate) * Number(worktype.hours);
    }
  });

  return total;
};

function calculateReduction(deliverableMultipliers, initialTotal) {
  let result = initialTotal;

  deliverableMultipliers.forEach((item) => {
    const { operation, value, visible } = item;
    if (visible === true) {
      if (operation === '-') {
        result -= value;
      } else if (operation === '+') {
        result += value;
      }
    } else {
      return initialTotal;
    }
  });

  return result;
}

function calculateTotalNet(array) {
  return array
    ? array.reduce((total, item) => total + item.netTotal, 0).toFixed(2)
    : 0;
}

function sumPriceToCustomer(arr) {
  return arr.reduce((total, item) => {
    const priceToCustomer = parseInt(item.price_to_customer);
    if (!isNaN(priceToCustomer)) {
      return total + priceToCustomer;
    }
    return total;
  }, 0);
}

export {
  calculateLOERate,
  calculateReduction,
  calculateTotalNet,
  sumPriceToCustomer,
};

const DilRow = ({
  rowData,
  index,
  classes,
  addDisc,
  setAddDisc,
  dileverable,
  setDeliverable,
}) => {
  const [hoveredRowIndex, setHoveredRowIndex] = useState(null);
  const useWork = useSelector((state) => state.Misc.workTypes);
  const work = useWork.find((ele) =>
    rowData.deliverable_worktype.some(
      (item) => Number(item.id) === Number(ele.id)
    )
  );

  const [loe_rate, setRate] = useState();
  const [val, setVal] = useState(0);
  useEffect(() => {
    setRate(calculateLOERate(rowData, useWork));
  }, [work, useWork]);

  const [showInput, setShowInpout] = useState(false);

  const handleRowHover = (rowIndex) => {
    setHoveredRowIndex(rowIndex);
  };

  const handleRowUnhover = () => {
    if (showInput) {
      setHoveredRowIndex(hoveredRowIndex);
    } else {
      setHoveredRowIndex(null);
    }
  };

  useEffect(() => {
    if (val !== loe_rate) {
      setDeliverable((prev) => {
        const shallowCopy = [...prev];
        const index = shallowCopy.findIndex(
          (ele) => Number(ele.id) === Number(rowData.id)
        );
        shallowCopy[index].user_price = val;
        return shallowCopy;
      });
    }
  }, [val]);

  const [tat, setTat] = useState(0);

  useEffect(() => {
    if (
      Array.isArray(rowData.deliverable_multiplier) &&
      rowData.deliverable_multiplier.length
    ) {
      setTat(calculateReduction(rowData.deliverable_multiplier, Number(val)));
    }
  }, [rowData, dileverable]);

  return (
    <TableRow
      key={rowData.id}
      className={classes.tableRow}
      onMouseEnter={() => handleRowHover(index)}
      onMouseLeave={handleRowUnhover}
      style={{
        backgroundColor: index % 2 === 0 ? '#e9ecef' : 'white',
        height: '120px',
      }}
      hover
    >
      <TableCell
        colSpan={2}
        style={{ borderBottom: 'none', fontFamily: 'Open Sans' }}
      >
        {rowData.deliverable}
      </TableCell>
      <TableCell
        style={{
          textAlign: 'center',
          alignContent: 'center',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          borderBottom: 'none',
          borderLeft: '1px solid black',
          borderRight: '1px solid black',
          width: '50%',
        }}
      >
        <TableRow
          style={{
            textAlign: 'center',
            width: '100%',
            display: 'flex',
            justifyContent: 'space-evenly',
            borderBottom: 'none',
          }}
        >
          <TableCell
            style={{
              borderBottom: 'none',
              fontFamily: 'Open Sans',
            }}
          >
            {formatMoneyString(`${rowData.total_loe}`)}
          </TableCell>

          <TableCell
            style={{
              borderBottom: 'none',
              fontFamily: 'Open Sans',
            }}
          >
            <DashedField3
              value={rowData.user_price}
              onChange={(e) => (rowData.user_price = e.target.value)}
            />
          </TableCell>
        </TableRow>
      </TableCell>

      {rowData.deliverable_multiplier &&
      Array.isArray(rowData.deliverable_multiplier) &&
      rowData.deliverable_multiplier.length
        ? rowData.deliverable_multiplier.map((item) => {
            const multiplier =
              item.rate && val
                ? `${Math.round(
                    multiply(val, divide(parseInt(item.rate), 100))
                  )}`
                : 0;
            return (
              <TableCell
                style={{
                  borderBottom: 'none',
                  fontFamily: 'Open Sans',
                  fontSize: '0.8rem',
                  textAlign: 'center',
                }}
              >
                {item ? (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      justifyContent: 'center',
                      width: 'max-content',
                    }}
                  >
                    <TextField
                      value={Number(item.rate)}
                      type="number"
                      onChange={(e) => {
                        setDeliverable((prev) => {
                          const shallowCopy = [...prev];
                          const target = shallowCopy.findIndex(
                            (int) => Number(int.id) === Number(rowData.id)
                          );
                          const index = shallowCopy[
                            target
                          ].deliverable_multiplier.findIndex(
                            (entity) => entity.id === item.id
                          );

                          shallowCopy[target].deliverable_multiplier[
                            index
                          ].rate = Number(e.target.value);
                          return shallowCopy;
                        });
                      }}
                      variant="standard"
                      sx={{
                        '& .MuiInputBase-input': {
                          fontSize: '1rem',
                          fontFamily: 'Open Sans',
                          fontWeight: '500',
                          color: '#000000',
                          textAlign: 'center',
                        },
                        '& .MuiInput-underline:before': {
                          borderBottom: '1px dashed #babcb5',
                        },
                        '& .MuiInput-underline:after': {
                          borderBottom: '1px dashed #babcb5',
                        },
                        '& .MuiInput-underline:hover:not(.Mui-disabled):before':
                          {
                            borderBottom: '1px dashed #babcb5',
                          },
                      }}
                      InputProps={{
                        style: { width: 50 },
                      }}
                    />
                    <Typography
                      sx={{
                        color: '#babcb5',
                        fontSize: '1rem',
                        textAlign: 'center',
                        width: '100%',
                      }}
                    >
                      {item.operation + formatMoneyString(multiplier)}
                    </Typography>
                  </Box>
                ) : null}
              </TableCell>
            );
          })
        : null}

      <TableCell
        style={{
          borderBottom: 'none',
          fontFamily: 'Open Sans',
          textAlign: 'right',
        }}
        align="right"
      >
        {val &&
        Array.isArray(rowData.deliverable_multiplier) &&
        rowData.deliverable_multiplier.length
          ? formatMoneyString(`${tat}`)
          : rowData.user_price
          ? formatMoneyString(`${rowData.user_price}`)
          : '$0'}
      </TableCell>
    </TableRow>
  );
};



const AccumalatorRow = ({
  pliers,
  tableData,
  dileverable,
  setDeliverable,
  setTracker,
}) => {
  function sumLeoPrice(arr) {
    return arr.reduce((acc, curr) => acc + curr.loe_price, 0);
  }

  function sumUserPrice(arr) {
    return arr.reduce((acc, curr) => acc + Number(curr.user_price), 0);
  }

  const [loe, setLOE] = useState(0);
  const [user, setUserP] = useState(0);
  const [dilT, setDilT] = useState(0);

  useEffect(() => {
    setLOE(sumLeoPrice(dileverable));
    setUserP(sumUserPrice(dileverable));
    if (
      dileverable.every(
        (entity) =>
          Array.isArray(entity.deliverable_multiplier) &&
          entity.deliverable_multiplier.length
      )
    ) {
      setDilT(calculateTotalNet(dileverable));
    }
  }, [dileverable]);

  useEffect(() => {
    setTracker(Number(dilT));
  }, [dilT]);
  return (
    <TableRow
      style={{
        height: '93px',
        backgroundColor: '#18075e',
        color: 'white',
      }}
    >
      <TableCell colSpan={2} align="left">
        <div className="fstxt-wh">Total</div>
      </TableCell>

      <TableCell
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          fontWeight: 'bold',
          color: 'gray',
          fontFamily: 'Open Sans',
          padding: '0px',
          margin: '0px',
          width: '50%',
          justifyContent: 'center',
          borderBottom: '1px solid #18075e',
          height: '100px',
        }}
      >
        <TableRow
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-evenly',
          }}
        >
          <TableCell style={{ borderBottom: 'none' }}>
            <div className="fstxt-wh">{formatMoneyString(`${loe}`)}</div>
          </TableCell>
          <TableCell style={{ borderBottom: 'none' }}>
            <div className="fstxt-wh">{formatMoneyString(`${user}`)}</div>
          </TableCell>
        </TableRow>
      </TableCell>
      {dileverable[0] &&
      Array.isArray(dileverable[0].deliverable_multiplier) &&
      dileverable[0].deliverable_multiplier.length
        ? dileverable[0].deliverable_multiplier.map((item) => {
            return <TableCell>{''}</TableCell>;
          })
        : null}

      <TableCell align="right">
        <div className="fstxt-wh">
          {formatMoneyString(dilT ? `${dilT}` : `${user}`)}
        </div>
      </TableCell>
    </TableRow>
  );
};

const HardTHead = () => {

  const hardCost = { borderBottom: 'none', fontFamily: 'Open Sans' };

  return (
    <TableRow style={{ height: '80px' }}>
      <TableCell style={hardCost}>Hard Cost</TableCell>

      <TableCell style={hardCost}>Our Price</TableCell>
      <TableCell style={hardCost}>Price to Customer</TableCell>
      <TableCell style={hardCost}>
        <p
          style={{
            backgroundColor: '#f5f5f5',
            borderRadius: '10px',
            padding: '10px',
          }}
        >
          Margin
        </p>
      </TableCell>

      <TableCell style={hardCost}>Total</TableCell>
    </TableRow>
  );
};

const HardAccumalator = ({ estimate_hardcost }) => {

  const lastTotal = {
    color: 'white',
    fontFamily: 'Open Sans',
    fontWeight: 'bold',
  };

  const [tat, setTat] = useState(0);
  useEffect(() => {
    if (Array.isArray(estimate_hardcost) && estimate_hardcost.length) {
      const total = sumPriceToCustomer(estimate_hardcost);
      setTat(Number(total));
    }
  }, [estimate_hardcost]);

  return (
    <TableRow
      style={{
        backgroundColor: '#18075e',
        color: 'white',
        height: '93px',
      }}
    >
      <TableCell
        style={{
          color: 'white',
          fontFamily: 'Open Sans',
          fontWeight: 'bold',
        }}
      >
        Total
      </TableCell>
      <TableCell style={lastTotal} align="left">
        {' '}
      </TableCell>
      <TableCell style={lastTotal} align="left">
        {' '}
      </TableCell>
      <TableCell style={lastTotal} align="left">
        {' '}
      </TableCell>
      <TableCell style={lastTotal}>
        {tat ? formatMoneyString(`${tat}`) : '$0'}
      </TableCell>
    </TableRow>
  );
};

const AddRows = ({ estimate_hardcost, setEstHardCost }) => {
  const hardCost = { borderBottom: 'none', fontFamily: 'Open Sans' };
  const [label, setLabel] = useState('');

  return (
    <TableRow style={{ height: '100px', backgroundColor: '#e9ecef' }}>
      <TableCell style={hardCost}>
        <Tooltip title={'Press Enter to Add New Hard Cost'}>
          <input
            placeholder="Type to Add Hard Cost"
            style={{
              width: '100%',
              borderBottom: '2px dotted gray',
              borderTop: 'none',
              borderLeft: 'none',
              borderRight: 'none',
              outline: 'none',
              backgroundColor: '#e9ecef',
            }}
            value={label}
            onChange={(e) => {
              setLabel(e.target.value);
            }}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                setLabel('');
                setEstHardCost((prev) => {
                  if (Array.isArray(prev) && prev.length) {
                    const shallowCopy = prev.map((item) =>
                      Object.assign({}, item)
                    );

                    return [
                      ...shallowCopy,
                      {
                        dentity: uuid(),
                        hardcost_label: e.target.value,
                        our_price: 0,
                        price_to_customer: 0,
                        margin: 0,
                      },
                    ];
                  } else {
                    return [
                      {
                        dentity: uuid(),
                        hardcost_label: e.target.value,
                        our_price: 0,
                        price_to_customer: 0,
                        margin: 0,
                      },
                    ];
                  }
                });
              }
            }}
          />
        </Tooltip>
      </TableCell>

      <TableCell style={hardCost}> </TableCell>

      <TableCell style={hardCost}> </TableCell>

      <TableCell style={hardCost}> </TableCell>

      <TableCell style={hardCost}> </TableCell>
    </TableRow>
  );
};

const HardCostTable = ({ estimate_hardcost, setEstHardCost, setTic }) => {

  const hardCost = { borderBottom: 'none', fontFamily: 'Open Sans' };


  return (
    <Table>
      <HardTHead
        estimate_hardcost={estimate_hardcost}
        setEstHardCost={setEstHardCost}
      />

      <TableBody>
        {Array.isArray(estimate_hardcost) && estimate_hardcost.length
          ? estimate_hardcost.map((item, i) => (
              <TableRow
                style={{ height: '100px', backgroundColor: '#e9ecef' }}
                key={item.dentity}
              >
                <TableCell style={hardCost}>
                  <input
                    placeholder="Type to Add Hard Cost"
                    style={{
                      width: '100%',
                      borderBottom: '2px dotted gray',
                      borderTop: 'none',
                      borderLeft: 'none',
                      borderRight: 'none',
                      outline: 'none',
                      backgroundColor: '#e9ecef',
                    }}
                    value={item.hardcost_label}
                    onChange={(e) => {
                      setTic((prev) => Number(prev + 1));
                      setEstHardCost((prev) => {
                        const shallowCopy = prev.map((item) =>
                          Object.assign({}, item)
                        );
                        const index = shallowCopy.findIndex(
                          (ind) => ind.dentity === item.dentity
                        );
                        shallowCopy[index].hardcost_label = e.target.value;
                        return shallowCopy;
                      });
                    }}
                  />
                </TableCell>

                <TableCell style={hardCost}>
                  <input
                    type="number"
                    placeholder="$0"
                    style={{
                      width: '20%',
                      borderBottom: '2px dotted gray',
                      borderTop: 'none',
                      borderLeft: 'none',
                      borderRight: 'none',
                      outline: 'none',
                      textAlign: 'center',
                      backgroundColor: '#e9ecef',
                    }}
                    value={item.our_price}
                    onChange={(e) => {
                      setTic((prev) => Number(prev) + 1);
                      setEstHardCost((prev) => {
                        const shallowCopy = prev.map((item) =>
                          Object.assign({}, item)
                        );
                        const index = shallowCopy.findIndex(
                          (ind) => ind.dentity === item.dentity
                        );
                        shallowCopy[index].our_price = e.target.value;
                        return shallowCopy;
                      });
                    }}
                  />{' '}
                </TableCell>

                <TableCell
                  style={{
                    borderBottom: 'none',
                    fontFamily: 'Open Sans',
                  }}
                >
                  <input
                    type="number"
                    placeholder="$0"
                    style={{
                      width: '20%',
                      borderBottom: '2px dotted gray',
                      borderTop: 'none',
                      borderLeft: 'none',
                      borderRight: 'none',
                      outline: 'none',
                      textAlign: 'center',
                      backgroundColor: '#e9ecef',
                    }}
                    value={item.price_to_customer}
                    onChange={(e) =>
                      setEstHardCost((prev) => {
                        const shallowCopy = prev.map((item) =>
                          Object.assign({}, item)
                        );
                        const index = shallowCopy.findIndex(
                          (ind) => ind.dentity === item.dentity
                        );
                        shallowCopy[index].price_to_customer = e.target.value;
                        return shallowCopy;
                      })
                    }
                  />{' '}
                </TableCell>
                <TableCell
                  style={{
                    borderBottom: 'none',
                    fontFamily: 'Open Sans',
                    textAlign: 'center',
                    fontWeight: 'bold',
                  }}
                >
                  <p
                    style={{
                      backgroundColor: '#daaafa',
                      borderRadius: '10px',
                      padding: '10px',
                    }}
                  >
                    $
                    {item.price_to_customer && item.our_price
                      ? subtract(
                          Number(item.price_to_customer),
                          Number(item.our_price)
                        )
                      : 0}
                  </p>
                </TableCell>
                <TableCell
                  style={{ borderBottom: 'none', fontFamily: 'Open Sans' }}
                >
                  {formatMoneyString(`${item.price_to_customer}`)}
                </TableCell>
              </TableRow>
            ))
          : null}

        <AddRows
          estimate_hardcost={estimate_hardcost}
          setEstHardCost={setEstHardCost}
        />
        <HardAccumalator estimate_hardcost={estimate_hardcost} />
      </TableBody>
    </Table>
  );
};

function MultiplierChange({
  formData,
  cache,
  dileverable,
  setDeliverable,
  estimate_hardcost,
  setEstHardCost,
  setTracker,
  setTic,
}) {
  const [ourPrice, setOurPrice] = useState(0);
  const dispatch = useDispatch();

  const access_token = useSelector((state) => state.auth.user.access);
  useEffect(() => {
    dispatch(
      getEstimates({
        access_token,
      })
    );
  }, []);

  useEffect(() => {
    console.log(dileverable, 'Changed');
  }, [dileverable]);

  const [priceToCustomer, setPriceToCustomer] = useState(0);
  const [hardCostTotal, setHardConstTotal] = useState(0);
  const [margin, setMargin] = useState(0);

  const useStyles = makeStyles({
    tableRow: {
      '&:hover': {
        backgroundColor: 'lightgray',
      },
    },
  });
  const classes = useStyles();

  const dil =
    Array.isArray(dileverable) && dileverable.length ? dileverable : false;

  const tableData = dil
    ? dil.map((item) => {
        return {
          id: item.id,
          deliverable: item.deliverable_name,
          leoPrice: '2,000',
          yourPrice: '8,000',
          add: '+',
          total: '30',
          Worktype: item.Worktype,
          total_loe: item.total_loe,
          total_hours: item.total_hours,
          deliverable_startdate: item.deliverable_startdate,
          deliverable_enddate: item.deliverable_enddate,
          deliverable_worktype: item.deliverable_worktype,
          deliverable_multiplier: item.deliverable_multiplier,
          loe_rate: item.loe_price,
          user_price: item.user_price,
        };
      })
    : [
        {
          deliverable: 'Reasearch and Dictionaory',
          leoPrice: '00',
          yourPrice: '00',
          add: '+',
          total: 30,
        },
      ];

  useEffect(() => {
    setHardConstTotal(Number(ourPrice + priceToCustomer));
    setMargin(Number(priceToCustomer - ourPrice));
  }, [ourPrice, priceToCustomer]);


  const [loe, setLOE] = useState(0);
  useEffect(() => {
    let accumalator = 0;
    tableData.forEach((item) => {
      return (accumalator += calculateLOERate(item));
    });
    setLOE(accumalator);
  }, [tableData]);

  const [detect, setDectect] = useState(false);
  const [addDisc, setAddDisc] = useState([]);
  const [pliers, setPliers] = useState([]);

  useEffect(() => {
    Array.isArray(addDisc) && addDisc.length
      ? setPliers([...pliers, ...addDisc])
      : [];
  }, [addDisc]);

  return (
    <div>
      <div style={{ width: '100%' }}>
        <Table style={{ width: '100%' }}>
          <TableHead>
            <TableRow>
              <TableCell
                style={{
                  fontWeight: 'normal',
                  color: 'gray',
                  fontFamily: 'Open Sans',
                }}
                colSpan={2}
              >
                {' '}
                Deliverable{' '}
                <InfoOutlinedIcon
                  sx={{ color: '#4dfad2', fontSize: '15px' }}
                />{' '}
              </TableCell>
              <TableCell
                style={{
                  textAlign: 'center',
                  alignContent: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  fontWeight: 'bold',
                  color: 'gray',
                  fontFamily: 'Open Sans',
                  padding: '0px',
                  margin: '0px',
                  width: '50%',
                  borderBottom: 'none',
                }}
              >
                <div style={{ width: '90%' }}>
                  Subtotal
                  <TableRow
                    style={{
                      borderTop: '1.5px solid gray',
                      padding: '0px',
                      display: 'flex',
                      justifyContent: 'space-evenly',
                    }}
                  >
                    <TableCell
                      style={{
                        borderBottom: 'none',
                        color: 'gray',
                        fontFamily: 'Open Sans',
                      }}
                    >
                      LOE Total
                    </TableCell>
                    <TableCell
                      style={{
                        borderBottom: 'none',
                        color: 'gray',
                        fontFamily: 'Open Sans',
                      }}
                    >
                      Your Price
                    </TableCell>
                  </TableRow>
                </div>
              </TableCell>
              {tableData[0].deliverable_multiplier &&
              Array.isArray(tableData[0].deliverable_multiplier) &&
              tableData[0].deliverable_multiplier.length
                ? tableData[0].deliverable_multiplier.map((item) => (
                    <TableCell>{item.label ? item.label : ''}</TableCell>
                  ))
                : null}
              <TableCell
                style={{
                  fontWeight: 'bold',
                  fontFamily: 'Open Sans',
                  textAlign: 'center',
                }}
                align="left"
                onMouseEnter={() => setDectect(true)}
                onMouseLeave={() => setDectect(false)}
              >
                <Box
                  sx={{
                    display: 'flex',
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                </Box>
                <Typography sx={{ textAlign: 'right' }}>Total</Typography>
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {tableData.map((rowData, index) => {
              return (
                <DilRow
                  rowData={rowData}
                  classes={classes}
                  index={index}
                  addDisc={addDisc}
                  setAddDisc={setAddDisc}
                  dileverable={dileverable}
                  setDeliverable={setDeliverable}
                />
              );
            })}
          </TableBody>
          <AccumalatorRow
            loe={loe}
            pliers={pliers}
            tableData={tableData}
            dileverable={dileverable}
            setDeliverable={setDeliverable}
            setTracker={setTracker}
          />
        </Table>

        <HardCostTable
          estimate_hardcost={estimate_hardcost}
          setEstHardCost={setEstHardCost}
          setTic={setTic}
        />
      </div>
    </div>
  );
}
export default MultiplierChange;
