import React from 'react';
import {
  Grid,
  Box,
} from '@mui/material';
import { useEffect, useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import { Typography } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { ENDPOINT } from '../../Opportunity/Redux/oppor';
import Test from '../../Test';
import { getMessages, New_channels, projectChat } from '../../Stream/streamRedux';
import { DirectReplyButton, ReplyButton } from '../../../Componenets/Misc';
import Bounce from 'react-reveal/Bounce';


function ProjectChat({ comments, setComments, formData, setShowChatEst, setIsTyping }) {

  const [show, setShow] = useState(true);
  const new_channals = useSelector((state) => state.Stream.new_channels)

  const [html, setHtml] = useState('');
  const handleClose = () => {
    setShowChatEst(false)
    setShow(false)
    setIsTyping(false)
  };


  const [chats, setChats] = useState([])
  const [projectChannel, setProjectChannel] = useState()
  const [chatLoader, setChatLoader] = useState(false)
  const access_token = useSelector((state) => state.auth.user.access)
  const userData = useSelector((item) => item.userData.userData)
  const [responseBox, setResponseBox] = useState(null)
  const [search, setSearch] = useState('');
  const dispatch = useDispatch()



  useEffect(() => {
    const fetchData = async () => {
      try {
        setChatLoader(true);
        if (new_channals && formData) {
          const filtered = new_channals.filter(
            (item) => item?.project && !item?.task && !item?.deliverable
          );
          const crtMessages = filtered?.find(
            (item) => item?.project?.id === formData?.id
          );

          if (crtMessages && crtMessages.id) {
            const response = await fetch(
              `${ENDPOINT}/api/chat/channel/detail/${crtMessages?.id}/`,
              {
                method: "GET",
                headers: {
                  Authorization: `JWT ${access_token}`,
                  'Content-Type': 'application/json',
                },
              }
            );

            if (!response.ok) {
              throw new Error('Network response was not ok');
            }

            const data = await response.json();
            const sorted = data.message_in_channel.sort(
              (a, b) => new Date(a?.created) - new Date(b?.created)
            );


            setProjectChannel(data.id);
            setChats(sorted ?? []);
            setChatLoader(false);


            // Process the response data here
            for (const task of sorted) {
              try {
                const find = task?.read_message.some(
                  (readMessage) => readMessage.read_by === userData.id
                );
                if (!find) {
                  const response = await fetch(
                    `${ENDPOINT}/api/chat/channel/message/read/create/`,
                    {
                      method: 'POST',
                      headers: {
                        'Content-Type': 'application/json',
                        Authorization: `JWT ${access_token}`,
                      },
                      body: JSON.stringify({
                        message: task.id,
                        read_by: userData.id,
                      }),
                    }
                  );
                  if (!response.ok) {
                    const errorData = await response.json();
                    console.error("Error:", errorData);
                    return; // Optionally handle the error
                  }
                  const data = await response.json();
                  console.log("Success:", data);
                  dispatch(New_channels({ access_token }))
                }
              } catch (error) {
                console.error("Request failed:", error);
              }
            }
            const mapedDeliverables = formData?.deliverable_project.map((del) => {
              const crtMessages1 = new_channals
                ?.filter((item) => item?.project && !item?.task && item.deliverable)
                .find((item) => item.deliverable === del.id);


              const response1 = fetch(`${ENDPOINT}/api/chat/channel/detail/${crtMessages1?.id}/`, {
                method: "GET",
                headers: {
                  Authorization: `JWT ${access_token}`,
                  'Content-Type': 'application/json'
                },
              })
                .then(response => {
                  if (!response.ok) {
                    throw new Error('Network response was not ok');
                  }
                  return response.json();
                })
                .then(data => {
                  setComments(data?.message_in_channel);
                  // Process the response data here
                })
                .catch(error => {
                  console.error('Fetch error:', error);
                  return crtMessages1;
                });

              return response1;
            });
          }
        }
      } catch (error) {
        console.error("Fetch data failed:", error);
        setChatLoader(false);
      }
    };

    fetchData();
  }, [new_channals, formData]);



  const projectTeam =

    formData && formData?.project_subteam
      ? formData?.project_subteam[0].members.map((item) => {
        return ({ name: item.first_name, id: item.id })
      })
      : { name: 'No Subteam Yet' };


  async function send_Message(e) {

    if (e.key === 'Enter' && !e.shiftKey) { // Check if the pressed key is Enter
      e.preventDefault();


      if (!notRun) {
        const replaceWordsWithSpan = (inputText) => {
          // Define the primary pattern to match the span elements with the desired format
          const pattern = /<span data-type="mention" class="deliverable-mention" data-id="([^"]+)">~([^~]+)~\/([^~]+)<\/span>/g;

          // Define the fallback pattern to match ~text1/text2 format
          const fallBackPattern = /~([^~!]+)!/g;

          console.log(inputText, "InputText");

          // Define the replacement function for the primary pattern
          const replacement = (match, id, firstPart, taskPart) => {

            // Wrap the task part in a <span> tag with the fukrah class
            return `<span data-type="mention" class="deliverable-mention" data-id="${id}">~${firstPart}~/<span class="fukrah">${taskPart}</span></span>`;
          };

          // Replace the text using the primary pattern and the replacement function
          let replacedText = inputText.replace(pattern, replacement);

          // If no replacement was made by the primary pattern, try the fallback pattern
          if (replacedText === inputText) {
            // Define the replacement function for the fallback pattern
            const fallbackReplacement = (match, word) => {
              console.log(word, "Word");
              // Split the word using the delimiter '/'
              const parts = word.split('/');

              console.log(parts, "Parts");

              // Check if there are two parts
              if (parts.length === 2) {
                // Wrap the first part in a <span> tag with the specified class name
                // and include the second part inside with a new class name
                return `<span class="deliverable-mention">~${parts[0]}~/<span class="fukrah">${parts[1]}</span></span>`;
              } else {
                // If there's only one part, wrap it in the main <span> tag
                return `<span class="deliverable-mention">${word}</span>`;
              }
            };

            // Replace the text using the fallback pattern and the fallback replacement function
            replacedText = inputText.replace(fallBackPattern, fallbackReplacement);
          }

          // Clean up any malformed HTML or orphaned tags
          const cleanedText = replacedText.replace(/<span[^>]*><\/span>/g, '');
          // Optionally clean up text left after the replacement
          // Clean any leftover ~ or / characters
          const finalText = cleanedText.replace(/~\/~|~~/g, '');

          return finalText;
        };


        const tempMessage = {
          created: new Date().toISOString(),
          // channel: Array.isArray(currentTarget.project_subteam[0].subteam_channel) && currentTarget.project_subteam[0].subteam_channel.length ? currentTarget.project_subteam[0].subteam_channel[0].id : [],
          channel: Array.isArray(projectChannel) && projectChannel.length ? projectChannel : [],
          is_response: false,
          message: replaceWordsWithSpan(html),
          received: null,
          receiver_read: false,
          seen: null,
          sender_read: true, // Assuming the sender has already read their own message
          sender: userData,
          tempId: Math.random().toString(36).substring(7), // Unique temporary ID
        };
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, 'text/html');
        const doc2 = parser.parseFromString(replaceWordsWithSpan(html), 'text/html');
        // Extract mentioned persons
        const mentions = doc.querySelectorAll('span[data-type="mention"]');
        const mentionedPersons = Array.from(mentions).map(mention => mention.textContent.replace('@', ''));



        // Display the message immediately
        setChats([...chats, tempMessage])
        btn?.editor?.commands.clearContent()

        if (Array.isArray(mentionedPersons) && mentionedPersons.length) {

          for (let i = 0; i < mentionedPersons.length; i++) {


            const res = await fetch(`${ENDPOINT}/api/superadmin/notification/create/`,
              {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `JWT ${access_token}`,
                },
                body: JSON.stringify({
                  notification_channel: projectChannel,
                  notification_project: formData && formData.id,
                  notification_sender: userData.id,
                  notification_receiver_read: false,
                  notification_seen: null,
                  notification_type: "user_mention",
                  notification_receiver: projectTeam.find(item => item.name === mentionedPersons[i])?.id
                }),
              }
            )
            if (!res.ok) {
              const data = await res.json();
              return;
            }
            else {
            }
          }
        }
        const dilMentions = doc.querySelectorAll('span[class="deliverable-mention"]');
        const catchDil = Array.from(dilMentions).map(mention => mention.textContent.replace('~', ''));

        if (Array.isArray(catchDil) && catchDil.length) {
          for (var i = 0; i < catchDil.length; i++) {

            const id = formData?.deliverable_project?.find(it => it.deliverable_name === catchDil[i])?.id
            const crtMessages = new_channals?.find((item) => item?.deliverable === id)

            if (crtMessages) {
              const response = await fetch(
                `${ENDPOINT}/api/chat/channel/message/create/`,
                {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                    Authorization: `JWT ${access_token}`,
                  },
                  body: JSON.stringify({
                    channel: crtMessages?.id,
                    message: replaceWordsWithSpan(html),
                    sender: userData
                  }),
                }
              );

              if (!response.ok) {
                const data = await response.json();

                return;
              }

              const data = await response.json();
              dispatch(getMessages({ access_token }))
            }

          }
        }

        const taskMentions = doc2.querySelectorAll('span[class="fukrah"]');
        const catchTask = Array.from(taskMentions).map(mention => mention.textContent.replace('~', ''));

        if (Array.isArray(catchTask) && catchTask.length) {
          for (var i = 0; i < catchTask.length; i++) {
            const id = formData?.deliverable_project?.flatMap(it => it.task_deliverable.filter((wt) => wt.task_name === catchTask[i]));
            const crtMessages = new_channals?.find((item) => item?.task === id[0]?.id);

            if (crtMessages) {
              const taskResponse = await fetch(
                `${ENDPOINT}/api/chat/channel/message/create/`,
                {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                    Authorization: `JWT ${access_token}`,
                  },
                  body: JSON.stringify({
                    channel: crtMessages?.id,
                    message: replaceWordsWithSpan(html),
                  }),
                }
              );

              if (!taskResponse.ok) {
                const data = await taskResponse.json();
                return;
              }

              const data = await taskResponse.json();
              dispatch(getMessages({ access_token }));

              // Send to deliverable channel as well
              const deliverableId = formData?.deliverable_project?.find(it => it.task_deliverable.some(wt => wt.task_name === catchTask[i]))?.id;
              const deliverableMessages = new_channals?.find((item) => item?.deliverable === deliverableId);

              if (deliverableMessages) {
                const deliverableResponse = await fetch(
                  `${ENDPOINT}/api/chat/channel/message/create/`,
                  {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                      Authorization: `JWT ${access_token}`,
                    },
                    body: JSON.stringify({
                      channel: deliverableMessages?.id,
                      message: replaceWordsWithSpan(html),
                      sender: userData
                    }),
                  }
                );

                if (!deliverableResponse.ok) {
                  const data = await deliverableResponse.json();
                  return;
                }

                const data = await deliverableResponse.json();
                dispatch(getMessages({ access_token }));
              }
            }
          }
        }
        const response = await fetch(
          `${ENDPOINT}/api/chat/channel/message/create/`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `JWT ${access_token}`,
            },
            body: JSON.stringify({
              channel: projectChannel,
              message: replaceWordsWithSpan(html),
            }),
          }
        );

        if (!response.ok) {
          const data = await response.json();

          return;
        }

        const data = await response.json();

        setChats([...chats, {
          ...data,
          sender: userData
        }])
        dispatch(New_channels({ access_token }))
      }
      else {

      }

    }
    else if (e.key === 'Enter' && e.shiftKey) {
      let htmlEditor = btn.editor.getHTML()
      if (btn.editor.isActive('orderedList') || btn.editor.isActive('bulletList')) {
        // Replace only <br> tags without a class attribute with <span>
        htmlEditor = htmlEditor.replace(/<br(?![^>]*class\s*=\s*['"][^'"]*['"])[^>]*>/gi, '<span></span>');
        btn.editor.commands.setContent(htmlEditor)
      } else {
        // Add a class to <br> tags that do not already have one
        htmlEditor = htmlEditor.replace(/<br\s*\/?>/gi, '<p/>');
        btn.editor.commands.setContent(htmlEditor)
      }
      btn.editor.chain().focus().splitListItem('listItem').run()
    }
  }


  const [btn, setBtn] = useState()
  const check = (e) => {
    setBtn(e)
  }
  const [notRun, setNotRun] = useState(false)
  return (
    <Offcanvas show={show} onHide={handleClose} placement="bottom ">
      <Offcanvas.Header>
        <button className="close-modal" onClick={() => {
          setShowChatEst(false)
          setShow(false)
          setIsTyping(false)
        }} />
      </Offcanvas.Header>
      <Offcanvas.Body style={{ overflowY: 'hidden' }}>
        <Grid container direction="column" alignItems="flex-start">
          <Grid
            item
            container
            direction="column"
            alignItems="start"
            xl={12}
            lg={12}
            md={12}
            sm={12}
            sx={{ width: '90%', margin: '0 auto' }}
          >
            <Box>
              <h5>Chats: Project</h5>
              <h6
                style={{
                  backgroundColor: '#f5f5f5',
                  color: '#9d5cf2',
                  padding: '10px 5px',
                  borderRadius: '10px',
                }}
              >
                {formData ? formData.title : ''}
              </h6>
            </Box>

            <Box
              sx={{
                width: '100%',
                minHeight: '350px',
                border: '1px solid gray',
                borderRadius: '8px',
                padding: '0px 10px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'space-around',
              }}
            >
              <Box sx={{
                minHeight: '220px', maxHeight: '400px', width: '99%', marginLeft: '10px', overflowY: 'auto', display: 'flex',
                flexDirection: 'column-reverse'
              }}>
                <List sx={{ display: 'flex', flexDirection: 'column' }}>
                  {Array.isArray(chats) && chats.length ?

                    chats.map((item, index) => {

                      return (
                        <>
                          <Bounce bottom>
                            <List sx={{ width: '100%' }}>
                              <Box key={item.id}>
                                <ListItem alignItems="flex-start" key={item.id} id={`message-${item.id}`} >
                                  <ListItemAvatar>
                                    <Avatar
                                      alt={item.sender.first_name}
                                      src={item.sender.avatar ? item.sender.avatar : "/static/images/avatar/1.jpg"}
                                    />
                                  </ListItemAvatar>
                                  <ListItemText
                                    primary={
                                      <Typography
                                        variant="subtitle2"
                                        sx={{
                                          color: '#03071E',
                                          letterSpacing: '-0.3px',
                                          fontWeight: 'bold',
                                          fontSize: '1rem',
                                          fontFamily: 'Open Sans',
                                          display: 'flex',
                                          alignItems: 'center',
                                          gap: '0.5rem',
                                          opacity: item?.tempId ? 0.5 : 1,
                                        }}
                                      >
                                        {item?.sender?.first_name}

                                        <p
                                          style={{
                                            color: 'rgba(3, 7, 30, 0.4)',
                                            letterSpacing: '-0.3px',
                                            fontSize: '11px',
                                            fontWeight: '400',
                                            textAlign: 'left',
                                            fontFamily: 'Open Sans',
                                            marginBottom: '0',
                                          }}
                                        >
                                          {!item?.tempId ? `${new Date(
                                            item.created
                                          ).toLocaleTimeString([], {
                                            hour: '2-digit',
                                            minute: '2-digit',
                                            hour12: true,
                                          })}, ${new Date(
                                            item.created
                                          ).toDateString()}` : "Sending"}
                                        </p>
                                      </Typography>
                                    }
                                    secondary={
                                      <div
                                        style={{
                                          fontWeight: '400',
                                          fontSize: '14px',
                                          lineHeight: '22px',
                                          fontStyle: 'normal',
                                          color: '#03071E',
                                        }}
                                        dangerouslySetInnerHTML={{
                                          __html: item.message,
                                        }}
                                      />
                                    }
                                  />
                                </ListItem>
                                {search ? null : (
                                  <div className="ms-5 ps-1">
                                    {responseBox === item.id ? (
                                      <div className="response-dropdown">
                                        {item?.reply_to_chatmesage?.map((cv, int) => {
                                          return (
                                            <div className="ms-3 mb-3 d-flex align-items-start" key={cv.id}>
                                              <Avatar
                                                alt={cv?.sender?.first_name}
                                                src={cv?.sender?.avatar ? cv?.sender?.avatar : "/static/images/avatar/1.jpg"}
                                                className="me-2"
                                              />
                                              <div>
                                                <div className="d-flex align-items-center pt-2">
                                                  <p
                                                    className="fw-bold mb-0 me-2"
                                                    style={{
                                                      fontSize: '14px',
                                                    }}
                                                  >
                                                    {cv?.sender?.first_name ?? ''}
                                                  </p>
                                                  <p
                                                    style={{
                                                      color: 'rgba(3, 7, 30, 0.4)',
                                                      letterSpacing: '-0.3px',
                                                      fontSize: '11px',
                                                      textAlign: 'left',
                                                      fontFamily: 'Open Sans',
                                                    }}
                                                  >
                                                    {new Date().toLocaleTimeString([], {
                                                      hour: '2-digit',
                                                      minute: '2-digit',
                                                      hour12: true,
                                                    })}
                                                    , {new Date().toDateString()}
                                                  </p>
                                                </div>
                                                <p
                                                  style={{
                                                    fontWeight: '400',
                                                    fontSize: '14px',
                                                    lineHeight: '22px',
                                                    fontStyle: 'normal',
                                                    color: '#03071E',
                                                  }}
                                                  dangerouslySetInnerHTML={{
                                                    __html: cv?.message ?? '',
                                                  }}
                                                ></p>
                                              </div>
                                            </div>
                                          );
                                        })}

                                        <ReplyButton
                                          chats={chats}
                                          setChats={setChats}
                                          userData={userData}
                                          channel_id={item}
                                        />
                                        <button
                                          className="response-btn"
                                          onClick={() => {
                                            setResponseBox(false);
                                          }}
                                        >
                                          Hide Responses
                                        </button>
                                      </div>
                                    ) : (
                                      <>
                                        {item?.reply_to_chatmesage?.length && item?.reply_to_chatmesage?.length > 0 ? (
                                          <div className="respose">
                                            <AvatarGroup
                                              className="response-avatar"
                                              max={3}
                                              sx={{
                                                justifyContent: 'flex-end',
                                                paddingLeft: '15px',
                                              }}
                                            >
                                              {Array.isArray(item?.reply_to_chatmesage?.length) &&
                                                item?.reply_to_chatmesage?.length
                                                ? [
                                                  ...new Set(
                                                    item?.reply_to_chatmesage.map((va) =>
                                                      JSON.stringify(va.sender)
                                                    )
                                                  ),
                                                ]
                                                  .map((la) => JSON.parse(la))
                                                  .map((per) => {
                                                    return (
                                                      <Avatar
                                                        key={per.id}
                                                        src={per.avatar ? per.avatar : "/static/images/avatar/1.jpg"}
                                                        alt={per.first_name}
                                                      />
                                                    );
                                                  })
                                                : null}
                                            </AvatarGroup>

                                            <button
                                              className="response-btn"
                                              onClick={() => {
                                                setResponseBox(item.id);
                                              }}
                                            >
                                              <span className="me-1">
                                                {item?.reply_to_chatmesage?.length + ' ' + 'Responses'}
                                              </span>
                                            </button>
                                          </div>
                                        ) : (
                                          <div className="response-dropdown">
                                            <DirectReplyButton
                                              chats={chats}
                                              setChats={setChats}
                                              userData={userData}
                                              channel_id={item}
                                            />
                                          </div>
                                        )}
                                      </>
                                    )}
                                  </div>
                                )}
                              </Box>
                            </List>
                          </Bounce>
                        </>
                      );
                    })
                    :
                    <Box sx={{ minHeight: '220px', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: 'bold' }}>No Conversation in Current Project</Box>
                  }
                </List>
                {/* } */}
              </Box>
              <Box
                sx={{
                  backgroundColor: '#f5f5f5',
                  width: '100%',
                  borderRadius: '10px',
                  pb: 2
                }}
                onKeyDown={async (event) => {
                  send_Message(event)
                }}
              >
                {' '}
                <Test setNotRun={setNotRun} html={html} setHtml={setHtml} check={check} deliverbales={formData && Array.isArray(formData?.deliverable_project) && formData.deliverable_project.length ? formData.deliverable_project.map((item) => (item.deliverable_name)) : []} currentAllDil={formData?.deliverable_project} mentions={formData && Array.isArray(formData?.project_subteam) && formData.project_subteam.length && Array.isArray(formData.project_subteam[0].members) && formData.project_subteam[0].members.length ? formData.project_subteam[0].members.map((item) => (item.first_name)) : []} />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Offcanvas.Body>
    </Offcanvas >
  );
}

export default ProjectChat;
