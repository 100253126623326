import { createAsyncThunk } from '@reduxjs/toolkit';
import { ENDPOINT } from '../../../Opportunity/Redux/oppor';

const GET_CUSTOMERS = 'netnet/Redux/GET_CUSTOMERS';
const GET_PROJECTS = 'netnet/Redux/GET_PROJECTS';

// get customers from end point
export const getCustomers = createAsyncThunk(
  GET_CUSTOMERS,
  async (meta, thunkAPI) => {
    const response = await fetch(`${ENDPOINT}/api/superadmin/team/list/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `JWT ${meta.access_token}`,
      },
    });
    const data = await response.json();
    if (!response.ok) {
      return thunkAPI.rejectWithValue(data);
    }
    return data;
  },
);

export const getProjects = createAsyncThunk(
  GET_PROJECTS,
  async (meta, thunkAPI) => {
    const response = await fetch(`${ENDPOINT}/api/superadmin/project/list/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `JWT ${meta.access_token}`,
      },
    });
    const data = await response.json();
  
    if (!response.ok) {
      return thunkAPI.rejectWithValue(data);
    }
    return data;
  },
);


const initialState = {
  customersData: [],
  projects: [],
  loading: false,
  error: null,
};


const customerReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${GET_CUSTOMERS}/pending`:
      return {
        ...state,
        loading: true,
      };
    case `${GET_CUSTOMERS}/fulfilled`:
      return {
        ...state,
        loading: false,
        customersData: action.payload,
      };
    case `${GET_CUSTOMERS}/rejected`:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case `${GET_PROJECTS}/pending`:
      return {
        ...state,
        loading: true,
      };
    case `${GET_PROJECTS}/fulfilled`:
      return {
        ...state,
        loading: false,
        projects: action.payload,
      };
    case `${GET_PROJECTS}/rejected`:
      return {
        ...state,
        loading: false,
        error: action.payload,
        projects: [],
      };
    default:
      return state;
  }
};

export default customerReducer;
