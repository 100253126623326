import { Alert, Grid, TextField } from '@mui/material';
import { React, useEffect, useState } from 'react';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import FilterListIcon from '@mui/icons-material/FilterList';
import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import SearchIcon from '@mui/icons-material/Search';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { Drawer, Box, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { getTemplates } from './redux';
import helpVid from '../Opportunity/assets/Section_Help-idle.svg'
import Bounce from 'react-reveal/Bounce';
import HelpVideo from '../../Componenets/TopNav/HelpVideo';
import { ENDPOINT } from '../Opportunity/Redux/oppor';

const Switcher = ({ currentPage, setCurrentPage }) => {
  const act = {
    color: '#711fff',
    fontWeight: '500',
    fontFamily: 'Open Sans',
    fontSize: '0.95rem',
  };

  const inAct = {
    color: '#000',
    fontWeight: '400',
    fontFamily: 'Open Sans',
    fontSize: '0.95rem',
  };
  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
        <button
          className="noBorder"
          style={currentPage === 'all' ? act : inAct}
          onClick={() => setCurrentPage('all')}
        >
          All
        </button>
        <button
          className="noBorder"
          style={currentPage === 'my_templates' ? act : inAct}
          onClick={() => setCurrentPage('my_templates')}
        >
          My Templates
        </button>
        <button
          className="noBorder"
          style={currentPage === 'netnet' ? act : inAct}
          onClick={() => setCurrentPage('netnet')}
        >
          NetNet Templates
        </button>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
        <SearchIcon
          style={{ curser: 'pointer' }}
        />
        <TextField
          id="standard-basic"
          variant="standard"
          label="Search"
          sx={{ padding: '0px', width: '100%' }}
        />
      </Box>
    </Grid>
  );
};

const AllTemplates = ({ tableData, setSelectedTempleteID, selectedTempleteID }) => {
  
  return (
    <Grid container spacing={5}>
      {Array.isArray(tableData) && tableData.length ? (
        tableData.map((e) => (
          <Grid item xs={12} sm={12} md={6} lg={4} xl={3} xxl={2}>
            <Box
              sx={{
                width: '100%',
                height: '100%',
                backgroundColor: 'white',
                borderRadius: '8px',
                padding: '1rem',
                alignItems: 'center',
                border: '1px solid lightGray',
                borderTop: 0,
                borderLeft: 0,
              }}
            >
              <ul
                onClick={(() => { 
                  if(selectedTempleteID === e.id){
                    setSelectedTempleteID(null)
                  }else{
                    setSelectedTempleteID(e.id)
                  }
                  })}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: '0.6rem',
                  marginBottom: '1rem',
                  backgroundColor: selectedTempleteID === e?.id ? "#c6a5ff" : '#f1f3f5',
                  borderRadius: '8px',
                  padding: '0.5rem',
                  cursor:"pointer"
                }}
              >
                <li
                  style={{
                    paddingLeft: '0px',
                    marginBottom: '0px',
                  }}
                >
                  <h5
                    style={{
                      marginBottom: '0px',
                    }}
                    key={e.id}
                  >
                    {' '}
                    {e.name}
                  </h5>
                  <p
                    style={{
                      fontSize: '12px',
                      marginBottom: '0px',
                      color: '#8743fd',
                    }}
                  >
                    {' '}
                    { e?.deliverable && e?.deliverable[0]?.task} 
                    Tasks
                  </p>
                </li>
              </ul>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-around',
                  backgroundColor: '#e4ddf0',
                  borderRadius: ' 8px',
                  height: '2rem',
                  width: '7rem',
                  color: 'black',
                }}
              >
                <AccessTimeOutlinedIcon
                  style={{
                    fontSize: '1.3rem',
                  }}
                />
                <div>
                  <span
                    style={{
                      fontSize: '0.9rem',
                    }}
                  >
                    {' '}
                    {e?.duration}
                  </span>{' '}
                  <span
                    style={{
                      fontSize: '0.9rem',
                    }}
                  >
                    Hours{' '}
                  </span>
                </div>
              </div>
              <div
                style={{
                  backgroundColor: '#f8f9fa',
                  borderRadius: '8px',
                  padding: '4px',
                  marginTop: '1rem',
                }}
              >
                <h6
                  style={{
                    marginBottom: '0px',
                  }}
                >
                  Description:
                </h6>
                <p
                  style={{
                    marginBottom: '0px',
                  }}
                  dangerouslySetInnerHTML={{
                    __html: e?.description ? e?.description : '<p></p>',
                  }}
                ></p>
              </div>
            </Box>
          </Grid>
        ))
      ) : (
        <Grid
          container
          item
          sx={{ height: '100%', width: '100%', margin: '1rem 0' }}
          direction="column"
          alignItems={'center'}
          justifyContent="center"
        >
          <Alert
            severity="info"
            sx={{
              width: '100%',
            }}
          >
            You do not have any Templates.{' '}
          </Alert>
        </Grid>
      )}
    </Grid>
  );
};

const Action = ({ isDrawerOpen, setIsDrawerOpen }) => {
  const [text, setText] = useState('');
  const handleTextChange = (event) => {
    setText(event.target.value);
  };
  const [text2, setText2] = useState('');
  const handleTextChange2 = (event) => {
    setText2(event.target.value);
  };

  return (
    <Drawer
      anchor="bottom"
      open={isDrawerOpen}
      onClose={() => setIsDrawerOpen(false)}
    >
      <Box
        p={2}
        height="90%"
        width="100%"
        textAlign="center"
        role="presentartion"
      >
        <Typography variant="h6" component="div">
          <div
            style={{
              display: 'Flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Box
              style={{
                border: '1px solid lightGray',
                borderRadius: '6px',
                width: '800px',
                padding: '2rem',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  paddingBottom: '2rem',
                }}
              >
                <span
                  style={{
                    fontWeight: 'bold',
                    fontSize: '1.15rem',
                  }}
                >
                  Invite people to NET NET
                </span>
                <CloseIcon
                  style={{
                    cursor: 'pointer',
                  }}
                  onClick={() => setIsDrawerOpen(false)}
                />
              </div>
              <div
                style={{
                  textAlign: 'start',
                }}
              >
                <span
                  style={{
                    fontWeight: 'bold',
                    fontSize: '1rem',
                  }}
                >
                  To:
                </span>
              </div>
              <div
                style={{
                  textAlign: 'start',
                }}
              >
                <div>
                  <textarea
                    style={{
                      width: '100%',
                      border: '1px solid lightGray',
                      fontSize: '1rem',
                      padding: '0.5rem',
                    }}
                    rows={2}
                    cols={40}
                    value={text}
                    onChange={handleTextChange}
                  />
                </div>
              </div>
              <div
                style={{
                  textAlign: 'start',
                  fontSize: '1rem',
                  textDecoration: 'bold',
                }}
              >
                <span
                  style={{
                    fontWeight: 'bold',
                    fontSize: '1rem',
                  }}
                >
                  Add your Message
                </span>
              </div>
              <div>
                <textarea
                  style={{
                    width: '100%',
                    border: '1px solid lightGray',
                    fontSize: '1rem',
                    padding: '0.5rem',
                  }}
                  rows={4}
                  cols={40}
                  value={text2}
                  onChange={handleTextChange2}
                />
              </div>
              <div
                style={{
                  marginTop: '1rem',
                  display: 'flex',
                  alignItems: 'end',
                  justifyContent: 'end',
                }}
              >
                <button
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    backgroundColor: '#d2b8ff',
                    padding: '0.5rem',
                    borderRadius: '8px',
                    height: '2.2rem',
                    width: '6rem',
                    color: 'white',
                  }}
                >
                  <SendIcon
                    style={{
                      transform: 'rotate(-45deg)',
                      height: '20px',
                      width: '20px',
                    }}
                  />
                  <h6
                    style={{
                      marginTop: '0.6rem',
                      marginRight: '0.2rem',
                    }}
                  >
                    Send
                  </h6>
                </button>
              </div>
            </Box>
          </div>
        </Typography>
      </Box>
    </Drawer>
  );
};

const Template = ({screenHeight={screenHeight}}) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState('all');
  const [tableData, setTableData] = useState([]);
  const access_token = useSelector((state) => state.auth.user.access);
  const dispatch = useDispatch();
  const realTemplates = useSelector((state) => state.Misc.templates);
  const [selectedTempleteID, setSelectedTempleteID] = useState(null)

  useEffect(() => {
    dispatch(getTemplates({ access_token }));
  }, [realTemplates]);

  useEffect(() => {
    if (Array.isArray(realTemplates) && realTemplates.length) {
      setTableData(realTemplates);
    }
  }, [realTemplates, selectedTempleteID]);

  const [show, setShow] = useState(false)
  
  const handleDeleteTemplete = async () => {

    if(selectedTempleteID === null){
      return;
    }

    const res =  await fetch(`${ENDPOINT}/api/project/deliverable/template/delete/${selectedTempleteID}/`,
    {
      method: "DELETE",
      headers: {
        'Content-Type':'application/json',
        Authorization: `JWT ${access_token}`
      },
    })

    if(!res){
    
    }
    setSelectedTempleteID(null)

  }

  return (
    <Bounce left>
      <>
        <div
          style={{
            display: 'block',
            justifyContent: 'space-between',
            alignItems: 'center',
            backgroundColor: '#f7f7fa',
            padding: '1rem',
            height: '100vh',
          }}
        >
          <ul
            style={{
              display: 'flex',
              justifyContent: 'start',
              alignItems: 'center',
              marginBottom: '0px',
              padding: '0px',
              gap: '1rem',
            }}
          >
            <li
              sx={{
                fontSize: '2.2rem',
              }}
            >
              <h4
                style={{
                  fontWeight: 'bold',
                }}
              >
                Templates
              </h4>
            </li>
            <li>
              <MoreHorizIcon
                style={{
                  color: 'gray',
                  cursor: 'pointer',
                }}
              />
            </li>
            <li
              style={{
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <FilterListIcon
                style={{
                  color: 'gray',
                  cursor: 'pointer',
                }}
              />{' '}
              <span
                style={{
                  color: 'gray',
                  cursor: 'pointer',
                  marginLeft: '5px'
                }}
              >
                Filters
              </span>
              <img src={helpVid} onClick={() => { setShow(true) }} style={{ color: '#a6a6a6', marginLeft: '10px', width: '28px', height: '28px', cursor: 'pointer' }} />
            </li>
          </ul>
          <Box
            sx={{
              width: '100%',
              height: screenHeight - 135,
              backgroundColor: 'white',
              borderRadius: '8px',
              marginTop: '2rem',
              border: '1px solid lightGray',
              padding: '1rem',
              paddingRight: '2rem',
              paddingLeft: '2rem',
              borderBottom: 0,
              overflow: "auto"
            }}
          >
            <ul
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                paddingLeft: '0px',
                paddingBottom: '0px',
              }}
            >
              <li>
                <h5>Deliverable Templates</h5>
              </li>
              <li
                style={{
                  display: 'flex',
                  gap: '1.5rem',
                }}
              >
                <button
                  onClick={handleDeleteTemplete}
                  style={{
                    backgroundColor: '#f8f9fa',
                    borderRadius: ' 10px',
                    height: '2.2rem',
                    width: '7.7rem',
                    color: 'black',
                  }}
                >
                  <DeleteOutlinedIcon
                    style={{
                      color: 'red',
                      marginRight: '0.6rem',
                    }}
                  />{' '}
                  Delete
                </button>
              </li>
            </ul>
            <Switcher currentPage={currentPage} setCurrentPage={setCurrentPage} />
            <div
              style={{
                marginTop: '2rem',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {currentPage === 'all' || currentPage === 'my_templates'}
                <AllTemplates tableData={tableData} selectedTempleteID={selectedTempleteID} setSelectedTempleteID={setSelectedTempleteID} />
              </div>
            </div>
          </Box>
        </div>
        <HelpVideo show={show} setShow={setShow} /> 
        <Action isDrawerOpen={isDrawerOpen} setIsDrawerOpen={setIsDrawerOpen} />
      </>
    </Bounce>
  );
};
export default Template;
