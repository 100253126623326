import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Typography, Skeleton, Box } from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { ENDPOINT, } from '../../../Opportunity/Redux/oppor';
import Bounce from 'react-reveal/Bounce';



const BillingHistory = ({ funcAlert, screenHeight }) => {

    const access_token = useSelector((state) => state.auth.user.access);
  
    const [loading, setLoading] = useState(false)
  
    const [data, setData] = useState([])
  
    useEffect(() => {
      let api = async () => {
        setLoading(true)
        try {
          const res = await fetch(`${ENDPOINT}/api/sub/account-billing-history/`, {
            method: "GET",
            headers: {
              Authorization: `JWT ${access_token}`,
              "Content-Type": "application/json",
            },
            // body: JSON.stringify(updatedData),
          });
  
          const data = await res.json()
  
          if (res.ok) {
            setData(data.data)
            setLoading(false)
          }
          else {
  
          }
        }
        catch (error) {
          console.error('catch this error', error)
          setLoading(false)
        }
      }

      api()
    }, [])  
  
    const monthNames = ["January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
  
    return (
      <>
        {
          loading ?
            (
              <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', gap: '1rem', padding: '0rem 1rem' }}>
                <Skeleton sx={{ background: '#f0f0f0' }} variant="rounded" width={'100%'} height={80} animation={'wave'} />
                {
                  [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 2, 3, 3,].map((item, ind) => {
                    return <Skeleton sx={{ background: '#f0f0f0' }} variant="rounded" width={'100%'} height={'40px'} animation={'wave'} />
                  })
                }
              </Box>
            )
            :
            <Bounce bottom>
              <>
                <TableContainer
                  sx={{
                    marginTop: '1rem',
                    maxHeight: screenHeight - 260,
                    overflow: 'auto', scrollbarWidth: "none", border: '1.5px solid lightGray', borderRadius: '8px',
                  }}
                >
                  <Table stickyHeader
                    aria-label="sticky table"
                    sx={{
                      borderRadius: "8px",
  
                    }} >
                    <TableHead
                      sx={{
                        border: '1.5px solid lightGray',
                        backgroundColor: '#f2f2f2',
                      }}
                    >
                      <TableRow sx={{ height: '70px' }}>
                        <TableCell
  
                          sx={{
                            color: 'GrayText',
                            fontSize: '15px',
                            backgroundColor: '#f2f2f2',  
                          }}
                        >
                          Date
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            color: 'GrayText',
                            fontSize: '15px',
                            backgroundColor: '#f2f2f2',
                          }}
                        >
                          Invoice ID
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            color: 'GrayText',
                            fontSize: '15px',
                            width: "300px",
                            backgroundColor: '#f2f2f2',
                          }}
                        >Status
  
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            color: 'GrayText',
                            fontSize: '15px',
                            backgroundColor: '#f2f2f2',
                          }}
                        >Amount</TableCell>
                     
                        <TableCell
                          sx={{
                            color: 'GrayText',
                            fontSize: '15px',
                            backgroundColor: '#f2f2f2',
                          }}
                        ></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {
                        Array.isArray(data) && data.length > 0 &&
                        data.map((v, i) => {
  
                          let date = new Date(v.created * 1000)
  
                          const formattedDate = `${monthNames[date.getMonth()]}${' '}${date.getDate().toString().padStart(2, '0')}${', '}${date.getFullYear()}`;
  
                          return (
                            <>
                              <TableRow sx={{ border: "1px solid lightgray" }} hover>
                                <TableCell sx={{ width: "250px" }}>
                                  {formattedDate}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  sx={{
                                    alignItems: 'center',
                                    fontSize: '14px',
                                    color: '#585151',
                                    width: "200px"
                                  }}
                                >{v.id}
                                </TableCell>
                                <TableCell align="center" sx={{ fontSize: '14px', fontWeight: '600', color: "#29a56d" }}>
                                  {v.status.charAt(0).toUpperCase() + v.status.slice(1)}
                                </TableCell>
                                <TableCell sx={{ width: "300px" }} align="center">
                                  <div
                                    style={{
                                      margin: "auto",
                                      width: "100px",
                                      padding: '10px 10px',
                                      background: '#f7f7fa',
                                      borderRadius: "8px",
                                      border: '0.5px solid #d9dadb',
                                      alignItems: "center",
                                      textAlign: "center",
                                      justifyContent: "center",
  
                                    }}
                                  >
                                    <p style={{ color: '#000', fontSize: '15px' }}>
                                      $ {v.total / 100}
                                    </p>
                                  </div>
                                </TableCell>
                                <TableCell sx={{ width: "150px" }} align="left">
                                  <a href={v.invoice_pdf} target='_blank' >
                                    <span style={{ color: "#711fff", fontSize: "14px", fontWeight: 600, }} className=" tw-text-[#711fff]">View Invoice</span>
                                  </a>
                                </TableCell>
                              </TableRow>
                            </>
                          )
                        })
  
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
                {
                  data.length === 0 &&
                  <Typography sx={{ my: 4, display: 'flex', justifyContent: 'center', fontWeight: 700 }}>No Billing History Found</Typography>
                }
              </>
            </Bounce>
        }
      </>
    );;
  };

  export default BillingHistory