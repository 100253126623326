import moment from "moment";
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

const BarCharts = ({ apiData, data, isUnit = false, isLegend = false }) => {
  const modifiedData = apiData
    ?.map((item) => {
      const start =
        Array.isArray(item?.time_tracking) &&
        item?.time_tracking?.length &&
        item?.time_tracking[item.time_tracking.length - 1]?.time_tracking_start;
      const end =
        Array.isArray(item?.time_tracking) &&
        item?.time_tracking.length &&
        item?.time_tracking[item?.time_tracking.length - 1]?.time_tracking_stop;

      const duration = TotalHours(item?.time_tracking);

      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      let dateWithFormate = Array.isArray(item?.time_tracking) &&
      item?.time_tracking?.length &&
      item?.time_tracking[item.time_tracking.length - 1]?.date ?
      item?.time_tracking[item.time_tracking.length - 1]?.date
      :
      item?.time_tracking[item.time_tracking.length - 1]?.time_tracking_start
      let finalDate = new Date(dateWithFormate).toLocaleDateString('en-US', options)
      return {
        start: start,
        end:
          moment(end).format("MMMM DD, yy") !== "January 01, 1970"
            ? moment(end).format("MMMM DD, yy")
            : "No Time Tracked",
        duration: duration,
        date: finalDate ,
      };
    })
    .sort((a, b) => {
      if (a.date === "No Time Tracked") return 1;
      if (b.date === "No Time Tracked") return -1;
      return new Date(a.date) - new Date(b.date);
    });

  function TotalHours(timeTracking) {
    return timeTracking?.reduce((total, hours) => {
      return (
        total +
        (hours.tracked_time_hours
          ? Number(hours.tracked_time_hours.split(":")[0])
          : 0)
      );
    }, 0);
  }

  return (
    <>
      <ResponsiveContainer width="95%" height={450} className="bar-chart">
        <BarChart
          barGap={-55}
          width={500}
          height={400}
          data={modifiedData}
          margin={{ top: 25 }}
        >
          <CartesianGrid vertical={false} strokeDasharray="3 3" />
          <XAxis dataKey="date" allowDataOverflow={true} type="category" />
          <YAxis type="number" tickFormatter={(value) => `${value} hours`} />
          <Bar
            dataKey="duration"
            barSize={40}
            fill="#5ee690"
          >
          </Bar>

          <Tooltip cursor={false} />
        </BarChart>
       
      </ResponsiveContainer>

    </>
  );
};

export default BarCharts;