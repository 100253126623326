import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  ResponsiveContainer,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Bar,
  Tooltip,
} from 'recharts';
import {
  Box,
  Autocomplete,
  Grid,
  TextField,
  Typography,
  TableContainer,
  Paper,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Table,
  Popover,
  Button,
} from '@mui/material';
import moment from "moment";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateRangeCalendar } from "@mui/x-date-pickers-pro";
import { CalendarMonthOutlined,} from "@mui/icons-material";
import ExpandMoreSharpIcon from '@mui/icons-material/ExpandMoreSharp';
import { HiMiniUserCircle } from "react-icons/hi2";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';

const BarCharts = ({ data, isUnit = false, isLegend = false }) => {
  const modifiedData = data?.map(item => {
    console.error(item)
    const start = Array.isArray(item?.time_tracking) && item?.time_tracking?.length > 0 && item?.time_tracking[0]?.date ? item?.time_tracking[0]?.date : item?.time_tracking[0]?.time_tracking_start;
    const end = Array.isArray(item?.time_tracking) && item?.time_tracking.length && item?.time_tracking[item?.time_tracking.length - 1]?.time_tracking_stop;

    const duration = TotalHours(item?.time_tracking);

    return {
      ...item,
      start: moment(start).format('MMMM DD, yy') !== 'January 01, 1970' ? moment(start).format('MMMM DD, yy') : 'No Time Tracked',
      end: moment(end).format('MMMM DD, yy') !== 'January 01, 1970' ? moment(end).format('MMMM DD, yy') : 'No Time Tracked',
      duration: duration,
    };
  });

  function TotalHours(timeTracking) {
    return timeTracking?.reduce((total, hours) => {
      return total + (hours.tracked_time_hours ? Number(hours.tracked_time_hours.split(':')[0]) : 0);
    }, 0);
  }

  return (
    <>
      <ResponsiveContainer width="95%" height={450} className="bar-chart">
        <BarChart
          barGap={-55}
          width={500}
          height={500}
          data={modifiedData}
          margin={{ top: 25 }}
        >
          <CartesianGrid vertical={false} strokeDasharray="3 3" />
          <XAxis dataKey="start" allowDataOverflow={true} type="category" />
          <YAxis type="number" tickFormatter={(value) => `${value} hours`} />
          {/* {isLegend ? <Legend align="left" content={legened} /> : null} */}
          <Bar dataKey="duration" barSize={40} fill="#5ee690"></Bar>
          <Tooltip cursor={false} />
        </BarChart>

      </ResponsiveContainer>
    </>
  );
};




const QuickTasks = ({ params, setParams }) => {

  const companies = useSelector(state => state.Contacts.companies).filter((item) => params.data.chartData.filter((type) => type.task_type === 'quick_task').some(ele => ele.company === item.id))

  const [day, setDay] = useState('This Week')
  const buttonNames = [day, 'Group By', 'Billable', 'Export As'];


  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverIndex, setPopoverIndex] = useState(null);

  const handleClick = (event, index) => {
    setAnchorEl(event.currentTarget);
    setPopoverIndex(index);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setPopoverIndex(null);
  };

  const [groupBy, setGroupBy] = useState(0)
  const [groupName, setGroupName] = useState('none')
  const handleClickGroupBy = (v, i) => {
    setGroupBy(i)
    setGroupName(v)
    handleClose()
  }

  const [billable, setBillable] = useState(false)
  const handleClickBillable = (v, i) => {

    if (v === "Billable") {
      setBillable(true)
    } else if (v === "Non Billable") {
      setBillable(false)
    } else {
      setBillable(null)
    }
    handleClose()
  }

  const printDocument = () => {
    const input = document.getElementById('div-to-print');
    const pdf = new jsPDF({
      orientation: 'portrait',
      unit: 'mm',
      format: 'a4',
    });

    // Set margins (adjust as needed)
    const marginLeft = 10;
    const marginTop = 10;

    const usableWidth = pdf.internal.pageSize.width - 2 * marginLeft;
    const usableHeight = pdf.internal.pageSize.height - 2 * marginTop;

    html2canvas(input, { scale: 2 })
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');

        const aspectRatio = canvas.width / canvas.height;
        let imgWidth, imgHeight;

        if (aspectRatio > 1) {
          imgWidth = usableWidth;
          imgHeight = usableWidth / aspectRatio;
        } else {
          imgHeight = usableHeight;
          imgWidth = usableHeight * aspectRatio;
        }

        pdf.addImage(imgData, 'PNG', marginLeft, marginTop, imgWidth, imgHeight);
        pdf.save('QuickTask Report');
      });
  };

  const downloadCSV = () => {

    const table = document.getElementById("div-to-csv");
    // Extract rows from the table
    const rows = Array.from(table.getElementsByTagName('tr'));

    const csvData = rows.map(row => {
      const cells = Array.from(row.getElementsByTagName('td'));
      return cells.map(cell => cell.innerText.trim());
    });

    const csvContent = csvData.map(row => row.join(',')).join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv' });

    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'data.csv';
    link.click();
  };

  const downloadXLS = () => {
    const table = document.getElementById('div-to-csv');
    const rows = Array.from(table.getElementsByTagName('tr'));
    const data = rows.map(row => {
      const cells = Array.from(row.getElementsByTagName('td'));
      return cells.map(cell => cell.innerText.trim());
    });
    const worksheet = XLSX.utils.aoa_to_sheet([['Name', 'Type', 'Start Date - End Date', 'Total Amount'], ...data]);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sales');
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
    saveAs(blob, "SalesCSV");
  };


  const [exportAs, setExportAs] = useState(0)
  const handleClickExportAs = (v, i) => {
    if (v === "PDF") {
      printDocument()
    } else if (v === "CSV") {
      downloadCSV()
    } else if (v === "XLS") {
      downloadXLS()
    }
    setExportAs(i)
    handleClose()
  }

  const [dateRange, setDateRange] = useState([
    dayjs().startOf('week'),
    dayjs().endOf('week'),
  ]);

  const handleDateRange = (v) => {
    setDateRange(v)
  }


  const handleClickDay = (v, i) => {
    setDay(v)
    if (v === 'Today') {
      const today = dayjs();
      setDateRange([today,])
    }
    else if (v === 'Yesterday') {
      const today = dayjs();
      setDateRange([today.add(1, 'day'),])
    }
    else if (v === 'This Week') {
      const today = dayjs();
      setDateRange([today.startOf('week'), today.endOf('week')])
    }
    else if (v === 'Last Week') {
      const today = dayjs();
      const prevWeek = today.subtract(7, 'day');
      setDateRange([prevWeek.startOf('week'), prevWeek.endOf('week')
      ]);

    }
    else if (v === 'This Month') {
      const today = dayjs();
      setDateRange([today.startOf('month'), today.endOf('month')])
    }
    else if (v === 'Last Month') {
      const today = dayjs();
      const startOfNextMonth = today.startOf('month').subtract(1, 'month');
      setDateRange([startOfNextMonth, startOfNextMonth.endOf('month')]);

    }
    else if (v === 'This Year') {
      const today = dayjs();
      setDateRange([today.startOf('year'), today.endOf('year')])

    }
    else if (v === 'Last Year') {
      const today = dayjs();
      setDateRange([today.startOf('year').subtract(1, 'year'), today.endOf('year').subtract(1, 'year')])
    }
  }

  function TotalHours(timeTracking) {
    return timeTracking?.reduce((total, hours) => {
      return total + (hours.tracked_time_hours ? Number(hours.tracked_time_hours.split(':')[0]) : 0);
    }, 0);
  }

  const [filtering, setFiltering] = useState({
    people: [],
    client: [],
    tasks: [],
  })

  const filteredTasks = params.data.chartData.filter((type) => type.task_type === 'quick_task' && type.status !== "backlog");

  const filteredMainTask = [];

  filteredTasks.forEach((parent) => {
    parent.time_tracking.forEach((timeTrack) => {
      const { time_tracking_start, time_tracking_stop } = timeTrack;
      if (new Date(time_tracking_start) < new Date(time_tracking_stop)) {
        const clonedParent = { ...parent };
        clonedParent.time_tracking = [timeTrack];
        filteredMainTask.push(clonedParent);
      }
    });
  });

  const FilteredByClient = Array.isArray(filtering.client) && filtering.client.length
    ? filteredMainTask.filter((item) => filtering.client.some((ele) => item.company === ele))
    : filteredMainTask;

  const FilteredByTasks = Array.isArray(filtering.tasks) && filtering.tasks.length
    ? FilteredByClient.filter((item) => filtering.tasks.some((ele) => item.task_name === ele))
    : FilteredByClient;

  const FilteredByPeople = Array.isArray(filtering.people) && filtering.people.length
    ? FilteredByTasks.filter((item) =>
      item.assignee_task.some((mem) => filtering.people.includes(mem.assignee_user))
    )
    : FilteredByTasks;

  const Quick_Task = Array.isArray(filtering.people) && filtering.people.length ? FilteredByPeople : Array.isArray(filtering.client) && filtering.client.length ? FilteredByClient : Array.isArray(filtering.tasks) && filtering.tasks.length ? FilteredByTasks : filteredMainTask;

  const userTeam = useSelector((state) => state.userTeam.team.member)

  const filteringTask = Quick_Task?.filter((item) => {
    let date = item.time_tracking[0]?.date ? item.time_tracking[0]?.date : item.time_tracking[0]?.time_tracking_start
    let d = new Date(date)
    let startDate = new Date(dateRange[0])
    let endDate = new Date(dateRange[1])
    return d >= startDate && d <= endDate
  })

  const withBillableValue = filteringTask.filter(item => {
    return billable === null || item.billable === billable
  }).sort((a, b) => {
    const aDate = a.time_tracking[0]?.date;
    const bDate = b.time_tracking[0]?.date;

    if (aDate && bDate) {
      // Both dates exist, compare them
      return new Date(aDate) - new Date(bDate);
    } else if (aDate) {
      // Only a has a date, it comes first
      return -1;
    } else if (bDate) {
      // Only b has a date, it comes first
      return 1;
    } else {
      // Both dates are null, compare by created_by
      return a?.time_tracking[0]?.time_tracking_start.localeCompare(b?.time_tracking[0]?.time_tracking_start);
    }
  }).reverse();

  const workTypes = useSelector((state) => state.Misc.workTypes);

  const MergedWorkTypes = [];
  workTypes?.forEach((item) => {
    const exists = filteredMainTask.some(
      (task) => task.task_worktype[0]?.name === item.name
    );

    if (exists) {
      MergedWorkTypes.push(item);
    }
  });

  const Updatedworktype = MergedWorkTypes.map((worktype) => {
    const tasksWithWorktype = withBillableValue.filter(
      (task) => task.task_worktype[0]?.name === worktype.name
    );

    return {
      ...worktype,
      tasks: tasksWithWorktype,
    };
  });

  const [billableHours, setBillableHours] = useState(0);
  const [nonBillableHours, setNonBillableHours] = useState(0);

  useEffect(() => {
    const filterBillable = withBillableValue.filter(item => item.billable === true)
    const NonfilterBillable = withBillableValue.filter(item => item.billable === false)

    let totalBillableHours = 0;
    let NonBillableHours = 0

    filterBillable.forEach((item) => {
      const totalHours = TotalHours(item.time_tracking);
      totalBillableHours += totalHours;
    });

    setBillableHours(totalBillableHours);

    NonfilterBillable.forEach((item) => {
      const totalHours = TotalHours(item.time_tracking);
      NonBillableHours += totalHours;
    });

    setNonBillableHours(NonBillableHours);
  }, [billableHours, nonBillableHours, withBillableValue])


  const Team = userTeam.map(item => {
    const tasksWithAssignee = withBillableValue.filter(ele => ele.assignee_task.some(int => int.assignee_user === item.id));

    return {
      ...item,
      tasks: tasksWithAssignee
    };
  }).filter(flt=>flt.first_name !== 'First Name');

  const totalHoursAndMinutes = (data) => {
    let totalTimeTracking = [];
    for (let i = 0; i < data.length; i++) {
      const timeTracking = data[i].time_tracking;
      totalTimeTracking.push(data[i].time_tracking[0]);
    }

    let totalHours = 0;
    let totalMinutes = 0;

    totalTimeTracking?.forEach((entry) => {
      if (entry.tracked_time) {
        const [hours, minutes, seconds] = entry.tracked_time
          .split(":")
          .map(Number);
        totalHours += hours;
        totalMinutes += minutes;
      }
    });

    totalHours += Math.floor(totalMinutes / 60);
    totalMinutes = totalMinutes % 60;

    return { totalHours, totalMinutes };
  };

  return (
    <>
      <Grid
        sx={{
          backgroundColor: 'inherit',
          padding: '1rem 0rem',
          borderBottom: '1px solid #e2e2e2',

        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: '2.9rem',
            width: '95%',
            backgroundColor: '#F1F3F5',
            padding: '1rem 1rem',
            margin: '0 auto',
            borderRadius: '8px',
          }}
        >

          <div>
            {buttonNames.map((name, index) => (
              <>
                <Button
                  variant="contained"
                  aria-describedby={index}
                  onClick={(event) => handleClick(event, index)}
                  startIcon={index === 0 && <CalendarMonthOutlined />}
                  endIcon={<ExpandMoreSharpIcon style={{ width: '18px', height: '18px', fontWeight: 200, color: '#03071E' }} />}

                  style={{
                    marginRight: 10, backgroundColor: "#E0E0DF", color: "black", textTransform: "inherit", boxShadow: 'inherit', fontWeight: 400, fontSize: '14px',
                    '&:hover': { backgroundColor: '#E0E0DF', color: 'black', boxShadow: 'inherit' }
                  }}
                >
                  {
                    index === 1 ?
                      name + ' ' + (groupName === 'none' ? '' : groupName)
                      :
                      name
                  }
                </Button>
                <Popover
                  id={index}
                  open={popoverIndex === index}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  sx={{
                    '& .MuiPaper-root': {
                      boxShadow: 'inherit', border: '1px solid #E0E0DF', borderRadius: 2
                    }
                  }}

                >
                  {
                    index === 0 &&
                    <>
                      <Box align='center' sx={{ width: 'auto', height: 'auto', px: '30px', display: { xs: 'block', md: 'flex' } }}>
                        <Box align='left' sx={{ backgroundColor: '#F8F9FA', width: { xs: '100%', md: '200px' }, p: 1, pb: 3, my: 4, borderRadius: 2 }}>
                          {
                            ['Today', 'Yesterday', 'This Week', 'Last Week', 'This Month', 'Last Month', 'This Year', 'Last Year'].map((v, i) => {
                              return (
                                <Typography key={i} onClick={() => handleClickDay(v, i)} sx={day === v ?
                                  { fontSize: '16px', fontWeight: 400, cursor: 'pointer', color: '#711FFF', my: 3, ml: 2 }
                                  :
                                  { fontSize: '16px', fontWeight: 400, cursor: 'pointer', color: '#03071E', my: 3, ml: 2 }
                                }>{v}</Typography>
                              )
                            })
                          }

                        </Box>
                        <Box sx={{ mx: 4, my: 4 }}>

                          <LocalizationProvider dateAdapter={AdapterDayjs} >
                            <DateRangeCalendar
                              value={dateRange}
                              onChange={(newValue) => handleDateRange(newValue)}
                              sx={{
                                '& .css-10wpov9-MuiTypography-root ': {
                                  fontWeight: '700 !important'
                                },
                                '& .css-cyzddl-MuiPickersSlideTransition-root-MuiDayCalendar-slideTransition:not(.MuiDateRangeCalendar-dayDragging) .MuiDateRangePickerDay-dayOutsideRangeInterval': {
                                  color: '#03071E !important',
                                  fontSize: '16px !important',
                                  fontWeight: 500,
                                },
                                '& .css-1gbl7yn-MuiDateRangePickerDay-root': {
                                  backgroundColor: '#310085 !important',
                                  color: 'white !important',
                                  borderRadius: '0px !important'
                                },
                                '& .css-1e841vg-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day.Mui-selected': {
                                  backgroundColor: '#711FFF !important',
                                  color: "white !important",
                                  borderRadius: '0px !important',
                                  fontSize: '16px !important',
                                  fontWeight: 500
                                },
                                '& .css-1ckjanr-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day.Mui-selected': {
                                  backgroundColor: '#711FFF !important',
                                  color: "white !important",
                                  borderRadius: '0px !important',
                                  fontSize: '16px !important',
                                  fontWeight: 500
                                },
                                '& .css-1a4q4r2-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day.Mui-selected': {
                                  backgroundColor: '#711FFF !important',
                                  color: "white !important",
                                  borderRadius: '0px !important',
                                  fontSize: '16px !important',
                                  fontWeight: 500
                                },
                                '& .css-2ko3hu-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day': {
                                  backgroundColor: '#310085 !important',
                                  color: "white !important",
                                  opacity: 'inherit !important',
                                  borderRadius: '0px',
                                  border: 'none !important',
                                  fontSize: '16px !important',
                                  fontWeight: 500
                                },
                                '& .css-1ku4sqv-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day': {
                                  backgroundColor: '#711FFF !important',
                                  color: "white !important",
                                  borderRadius: '0px !important',
                                  fontSize: '16px !important',
                                  fontWeight: 500
                                },
                                '& .css-ahwqre-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day:not(.Mui-selected)': {
                                  backgroundColor: '#310085 !important',
                                  color: "#e569db !important",
                                  borderRadius: '0px !important',
                                  fontSize: '16px !important',
                                  fontWeight: 500
                                },
                                '& .css-jef1b6-MuiDateRangeCalendar-container:not(:last-of-type)': {
                                  borderRight: 'none !important'
                                },
                                '& .css-3wduhr-Mu.iDateRangeCalendar-root': {
                                  flexDirection: 'column !important',
                                },


                                '& .css-grqin-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day': {
                                  color: 'inherit',
                                  fontSize: '16px !important',
                                  opacity: 'inherit',
                                  fontWeight: 500
                                },
                                '& .css-1kex3oi-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day:not(.Mui-selected)': {
                                  backgroundColor: 'inherit',
                                  color: "black !important",
                                  borderRadius: '50%',
                                  border: "1px solid gray",
                                  fontSize: '16px',
                                  fontWeight: 500
                                },
                                '& .MuiTypography-subtitle1': {
                                  color: " #03071E !important",
                                  fontSize: '16px',
                                  fontWeight: 700
                                },
                                '& .MuiDayCalendar-weekDayLabel': {
                                  color: " #03071ECC !important",
                                  fontSize: '16px',
                                  fontWeight: 400
                                },
                                '& .MuiSvgIcon-fontSizeInherit': {
                                  backgroundColor: 'black',
                                  color: 'white',
                                  borderRadius: '50%'
                                },
                                '& .MuiPickersDay-today': {
                                  backgroundColor: '#711FFF',
                                  color: 'white',
                                  borderRadius: '0px',
                                  fontSize: '16px',
                                  fontWeight: 500

                                },
                                '& .css-grqin-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day.Mui-selected': {
                                  backgroundColor: '#711FFF',
                                  color: 'white',
                                  borderRadius: '0px',
                                  fontSize: '16px',
                                  fontWeight: 500
                                },
                                '& .css-gtjfra-MuiDateRangePickerDay-root': {
                                  backgroundColor: '#310085',
                                  color: 'white !important',
                                  borderRadius: '0px',
                                  fontSize: '16px',
                                  fontWeight: 500
                                },
                                '& .css-1i2r8k1-MuiDateRangePickerDay-root': {
                                  backgroundColor: '#310085',
                                  color: 'white !important',
                                  borderRadius: '0px',
                                  fontSize: '16px',
                                  fontWeight: 500
                                },
                                '& .MuiDateRangePickerDay-notSelectedDate': {
                                },
                              }}
                            />
                          </LocalizationProvider>

                          <Box sx={{ display: 'flex', my: 4 }}>
                            <Box sx={{ display: 'flex', mr: 4 }}>
                              <Box sx={{ width: "20px", height: "20px", backgroundColor: "#711FFF", borderRadius: 2, mr: 2 }}></Box>
                              <Typography sx={{ fontWeight: 400, fontSize: '14px' }}>Start Date and End Date</Typography>
                            </Box>
                            <Box sx={{ display: 'flex' }}>
                              <Box sx={{ width: "20px", height: "20px", backgroundColor: "#310085", borderRadius: 2, mr: 2 }}></Box>
                              <Typography sx={{ fontWeight: 400, fontSize: '14px' }}>Date Range</Typography>
                            </Box>
                          </Box>

                        </Box>
                        <Box align='' my={3} sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "" }} >
                          <Box align='left' mt={4}>
                            <Box align='left'>
                              <Typography sx={{ color: '#03071E99', fontSize: '16px', fontWeight: 400 }}>Start Date</Typography>
                              <Typography sx={{ color: '#03071E', fontSize: '16px', fontWeight: 400 }}>{dateRange[0] && dateRange[0].format().slice(0, 10)}</Typography>
                            </Box>
                            <Box sx={{ mt: 2 }}>
                              <Typography sx={{ color: '#03071E99', fontSize: '16px', fontWeight: 400 }}>End Date</Typography>
                              <Typography sx={{ color: '#03071E', fontSize: '16px', fontWeight: 400 }}>{dateRange[1] && dateRange[1].format().slice(0, 10)}</Typography>
                            </Box>
                          </Box>


                          <Box align='right'>
                            <Button variant='text' sx={{ textDecoration: 'inherit', color: 'black', width: { xs: 'auto', lg: '100px' }, mr: 1 }} onClick={handleClose}>Cancel</Button>
                            <Button variant='contained' sx={{
                              textDecoration: 'inherit', backgroundColor: "#711FFF", width: { xs: 'auto', lg: '100px' }, mr: 1,
                              '&:hover': {
                                backgroundColor: "#711FFF"
                              }
                            }} onClick={handleClose}>
                              Save
                            </Button>
                          </Box>

                        </Box>

                      </Box>
                    </>
                  }
                  {
                    index === 1 &&
                    <Box sx={{ pb: 2, width: '150px', textAlign: 'left' }}>
                      {
                        ['none', 'Team Members', 'Work Type'].map((v, i) => {
                          return (
                            <Typography key={i} fullWidth onClick={() => handleClickGroupBy(v, i)} sx={groupBy === i ?
                              { color: "white", backgroundColor: '#711FFF', borderRadius: 1, textTransform: 'inherit', fontSize: '14px', m: 2, px: 1, '&:hover': { backgroundColor: '#E0E0DF', cursor: 'pointer' } }
                              :
                              { color: "black", borderRadius: 1, textTransform: 'inherit', fontSize: '14px', m: 2, px: 1, '&:hover': { backgroundColor: '#E0E0DF', cursor: 'pointer' } }}>{v}</Typography>
                          )
                        })
                      }
                    </Box>
                  }

                  {
                    index === 2 &&
                    <Box sx={{ pb: 2, width: '150px', textAlign: 'left' }}>
                      {
                        ['Billable', 'Non Billable', 'Both'].map((v, i) => {
                          return (
                            <Typography key={i} fullWidth onClick={() => handleClickBillable(v, i)} sx={billable === i ?
                              { cursor: "pointer", color: "white", backgroundColor: '#711FFF', borderRadius: 1, textTransform: 'inherit', fontSize: '14px', m: 2, px: 1, '&:hover': { backgroundColor: '#E0E0DF' } }
                              :
                              { cursor: "pointer", color: "black", borderRadius: 1, textTransform: 'inherit', fontSize: '14px', m: 2, px: 1, '&:hover': { backgroundColor: '#E0E0DF' } }}>{v}</Typography>
                          )
                        })
                      }
                    </Box>
                  }

                  {
                    index === 3 &&
                    <Box sx={{ pb: 2, width: '150px', textAlign: 'left' }}>
                      {
                        ['PDF', 'XLS', 'CSV'].map((v, i) => {
                          return (
                            <Typography key={i} fullWidth onClick={() => handleClickExportAs(v, i)} sx={exportAs === i ?
                              { color: "white", backgroundColor: '#711FFF', borderRadius: 1, textTransform: 'inherit', fontSize: '14px', m: 2, px: 1, '&:hover': { backgroundColor: '#E0E0DF' } }
                              :
                              { color: "black", borderRadius: 1, textTransform: 'inherit', fontSize: '14px', m: 2, px: 1, '&:hover': { backgroundColor: '#E0E0DF' } }}>{v}</Typography>
                          )
                        })
                      }
                    </Box>
                  }
                </Popover>
              </>
            ))}
          </div>
        </Box>
        <Box
          sx={{
            display: 'flex',
            width: '95%',
            alignItems: 'center',
            margin: '0 auto',
          }}
        >
          <Autocomplete
            id="company"
            options={Array.isArray(companies) && companies.length ? companies : []}
            includeInputInList
            multiple
            getOptionLabel={(option) => {
              return option.name;
            }}
            onChange={(_, value) => {
              const selectedClientIds = value.map((option) => option.id);
              setFiltering({
                people: [],
                client: selectedClientIds, // Set the array of selected client IDs
                tasks: [],
              });

            }}
            style={{ width: 300, marginTop: '0.7rem' }}
            sx={{
              '& .MuiAutocomplete-inputRoot': {
                flexWrap: 'nowrap',
              },
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={'Filter By Client'}
                color="secondary"
                variant="standard"
                sx={{
                  '& .MuiInputBase-input': {
                    fontSize: '1rem',
                    fontFamily: 'Open Sans',
                    fontWeight: '500',
                    color: '#000000',
                    textAlign: 'left',
                  },
                  '& .MuiInput-underline:before': {
                    borderBottom: '2px solid #711fff',
                  },
                  '& .MuiInput-underline:after': {
                    borderBottom: '2px solid #711fff',
                  },
                  '& .MuiInput-underline:hover:not(.Mui-disabled):before':
                  {
                    borderBottom: '2px solid #711fff',
                  },
                }}
                InputProps={{
                  ...params.InputProps,
                  style: { width: 250 },
                }}
              />
            )}
          />
          <Autocomplete
            id="company"
            options={Array.isArray(params.data.chartData) && params.data.chartData.length ? params.data.chartData.filter((type) => type.task_type === 'quick_task') : []}
            includeInputInList
            multiple
            getOptionLabel={(option) => {
              return option.task_name;
            }}
            onChange={(_, value) => {
              const selectedTasks = value.map((option) => option.task_name);
              setFiltering({
                people: [],
                client: [], // Set the array of selected client IDs
                tasks: selectedTasks,
              });
            }}
            sx={{
              '& .MuiAutocomplete-inputRoot': {
                flexWrap: 'nowrap',
              },
            }}
            style={{ width: 300, marginTop: '0.7rem' }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={'Filter By Task'}
                color="secondary"
                variant="standard"
                sx={{
                  '& .MuiInputBase-input': {
                    fontSize: '1rem',
                    fontFamily: 'Open Sans',
                    fontWeight: '500',
                    color: '#000000',
                    textAlign: 'left',
                  },
                  '& .MuiInput-underline:before': {
                    borderBottom: '2px solid #711fff',
                  },
                  '& .MuiInput-underline:after': {
                    borderBottom: '2px solid #711fff',
                  },
                  '& .MuiInput-underline:hover:not(.Mui-disabled):before':
                  {
                    borderBottom: '2px solid #711fff',
                  },
                }}
                InputProps={{
                  ...params.InputProps,
                  style: { width: 250 },
                }}
              />
            )}
          />
          <Autocomplete
            id="company"
            options={Array.isArray(Team) && Team.length ? Team.filter((item) => item.first_name !== "First Name") : []}
            includeInputInList
            multiple
            getOptionLabel={(option) => {
              return option.first_name + ' ' + option.last_name;
            }}
            onChange={(_, value) => {
              const selectedPeople = value.map((option) => option.id);
              setFiltering({
                people: selectedPeople,
                client: [], // Set the array of selected client IDs
                tasks: [],
              });
            }}
            style={{ width: 300, marginTop: '0.7rem' }}
            sx={{
              '& .MuiAutocomplete-inputRoot': {
                flexWrap: 'nowrap',
              },
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={'Filter By People'}
                color="secondary"
                variant="standard"
                sx={{
                  '& .MuiInputBase-input': {
                    fontSize: '1rem',
                    fontFamily: 'Open Sans',
                    fontWeight: '500',
                    color: '#000000',
                    textAlign: 'left',
                  },
                  '& .MuiInput-underline:before': {
                    borderBottom: '2px solid #711fff',
                  },
                  '& .MuiInput-underline:after': {
                    borderBottom: '2px solid #711fff',
                  },
                  '& .MuiInput-underline:hover:not(.Mui-disabled):before':
                  {
                    borderBottom: '2px solid #711fff',
                  },
                }}
                InputProps={{
                  ...params.InputProps,
                  style: { width: 250 },
                }}
              />
            )}
          />
        </Box>
      </Grid>

      <div id="div-to-print" >
        <Box
          sx={{
            p: '0.14rem 1rem',
            display: 'flex',
            flexDirection: 'column',
            m: '1rem 0',
          }}
        >
          <Box sx={{
            width: '65%',
            display: 'flex',
            alignItems: 'center',
          }}>
            <Box sx={{ marginRight: "5rem" }}>
              <Typography
                sx={{
                  color: '#310085',
                  fontWeight: '600',
                  fontSize: '1rem',
                  textTransform: 'uppercase',
                  m: '0 1rem',
                }}
              >
                OVERALL TASK REPORTS
              </Typography>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center", textAlign: "center" }} >
              <Box sx={{
                backgroundColor: '#f2ebff',
                display: 'flex',
                justifyContent: "center",
                alignItems: 'center',
                textAlign: "center",
                borderRadius: '4px',
                width: 'fit-content',
                p: '0.45rem 0.85rem',
                fontWeight: '500',
                fontSize: '1rem',
                m: '0 1rem',
              }}>
                Bilable Hours
                <span style={{
                  paddingInline: "6px",
                  fontSize: '1.1rem',
                  color: '#dc241c',
                  fontWeight: '600'
                }}>
                  {billableHours} Hours
                </span>
              </Box>
              <Box sx={{
                backgroundColor: '#e5fffa',
                display: 'flex',
                alignItems: 'center',
                borderRadius: '4px',
                width: 'fit-content',
                p: '0.45rem 0.85rem',
                fontWeight: '500',
                fontSize: '1rem',
                m: '0 1rem',
              }}>
                NON BILLABLE HOURS
                <span style={{
                  paddingInline: "6px",
                  fontSize: '1.1rem',
                  color: '#7111fff',
                  fontWeight: '600'
                }}>
                  {nonBillableHours} Hour
                </span>
              </Box>
            </Box>
          </Box>
          <BarCharts data={withBillableValue} />
          <div id="div-to-csv" >
            {groupBy === 0 ? (
              <TableContainer
                component={Paper}
                sx={{
                  width: '100%',
                  border: '1px solid #adadad',
                  borderRadius: '8px',
                  m: '1rem 0',
                }}
              >
                <Table>
                  <TableHead>
                    <TableRow
                      sx={{
                        height: '72px',
                        backgroundColor: '#f8f9fa',
                      }}
                    >
                      <TableCell>Task Name</TableCell>
                      <TableCell>Description</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Worktype</TableCell>
                      <TableCell>Time</TableCell>
                      <TableCell>Duration</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>

                    { withBillableValue.length>0 ?
                    Array.isArray(withBillableValue) && withBillableValue.length && withBillableValue.map((item, index) => {
                      const start = Array.isArray(item?.time_tracking) && item?.time_tracking?.length && item?.time_tracking[item.time_tracking.length - 1]?.time_tracking_start
                      const end = Array.isArray(item?.time_tracking) && item?.time_tracking.length && item?.time_tracking[item?.time_tracking.length - 1]?.time_tracking_stop
                      const startDateTime = new Date(start);
                      const endDateTime = new Date(end);
                      const timeDifference = endDateTime - startDateTime;
                      const hours = Math.floor(timeDifference / (1000 * 60 * 60));
                      const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
                      const startTime = new Date(start).toLocaleTimeString('en-US', {
                        hour: 'numeric',
                        minute: 'numeric',
                        hour12: true,
                      });
                      const endTime = new Date(end).toLocaleTimeString('en-US', {
                        hour: 'numeric',
                        minute: 'numeric',
                        hour12: true,
                      });
                      let teamuser = userTeam.find(ele => item?.assignee_task.some(int => int.assignee_user === ele.id))
                      return (
                        <TableRow
                          sx={{ width: "100%", height: '92px', '& > *': { borderBottom: 'unset' } }}
                        >
                          <TableCell sx={{ width: "20%" }} >{item.task_name}</TableCell>
                          <TableCell sx={{ width: "20%" }}>
                            <div
                              dangerouslySetInnerHTML={{ __html: item?.time_tracking[0]?.note ?? '' }}
                            />
                          </TableCell>
                          <TableCell sx={{ width: "15%" }} >{teamuser?.first_name + ' ' + teamuser?.last_name}</TableCell>

                          <TableCell sx={{ width: "15%" }} >{item?.task_worktype[0]?.name ?? ''}</TableCell>
                          <TableCell sx={{ width: "15%" }} >

                            {
                              timeDifference === 0 ? (
                                <>
                                  <Typography> No Time Tracked </Typography>
                                </>
                              ) : (
                                <Box
                                  sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-start',
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      gap: '1rem',
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        fontWeight: '600',
                                        fontSize: '0.98rem',
                                      }}
                                    >
                                      {startTime}
                                    </Typography>
                                    <Typography
                                      sx={{
                                        fontWeight: '600',
                                        fontSize: '0.98rem',
                                      }}
                                    >
                                      {endTime}
                                    </Typography>
                                  </Box>
                                  <Typography
                                    sx={{
                                      fontWeight: '400',
                                      fontSize: '0.88rem',
                                      color: '#686a78',
                                    }}
                                  >
                                    {moment(item?.time_tracking[0]?.date || end).format('MMMM DD, yy') !== 'January 01, 1970' ? moment(item?.time_tracking[0]?.date ?? end).format('MMMM DD, yy') : 'No Time Tracked'}

                                  </Typography>
                                </Box>
                              )
                            }
                          </TableCell>
                          <TableCell sx={{ width: "10%" }} >
                            <Box
                              sx={{
                                width: '100%',
                                backgroundColor: '#d2b8ff',
                                borderRadius: '8px',
                                p: '.54rem',
                                textAlign: 'center',
                                fontSize: '.9rem',
                              }}
                            >
                              {hours + ' : ' + minutes + ' Hr'}
                            </Box>
                          </TableCell>
                        </TableRow>
                      );
                    })
                    :
                    <TableRow>
                        <TableCell colSpan={6} align="center">
                            <p style={{ width: '100%', textAlign: 'center', alignSelf: 'center' }}
                            >NO TASkS</p>
                        </TableCell>
                    </TableRow>
                    }
                  </TableBody>
                </Table>
              </TableContainer>
            ) : ("")}
            {groupBy === 1 ? (
              <>
                <Typography sx={{ fontSize: "17px", fontWeight: 900, p: 1 }} > Team Members </Typography>

                {Team?.length > 0 && Team?.map((item, i) => {

                  const totalDuration = totalHoursAndMinutes(item?.tasks);

                  return (
                    <Box key={i} sx={{ backgroundColor: '#F8F9FA', p: 1, borderRadius: 2, mb: 10 }}>
                      <Box
                        sx={{
                          display: "flex",
                          direction: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                          my: 1.5,
                          mx: 2,
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "flex-start",
                          }}
                        >
                          <HiMiniUserCircle
                            style={{ fontSize: "24px", mr: 2 }}
                          />
                          <Typography sx={{ fontSize: "16px" }}>
                            {item?.first_name + ' ' +item?.last_name}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            direction: "row",
                            justifyContent: "flex-end",
                            alignItems: "flex-end",
                          }}
                        >
                          <Box
                            sx={{
                              cursor: "pointer",
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              background: "#F1F3F5",
                              borderRadius: "10px",
                              padding: "8px 19px",
                            }}
                          >
                            <p
                              style={{
                                background: "#d2b8ff",
                                borderRadius: "50%",
                                padding: "3px 5px",
                                marginRight: "6px",
                                fontSize: "12px",
                              }}
                            >
                              {" "}
                              {item.tasks.length}{" "}
                            </p>
                            <Typography
                              sx={{
                                color: "#310085",
                                fontSize: "13px",
                                fontWeight: 600,
                              }}
                            >
                              {" "}
                              TASKS{" "}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              cursor: "pointer",
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              background: "#F1F3F5",
                              borderRadius: "10px",
                              padding: "8px 19px",
                              marginLeft: "14px",
                            }}
                          >
                            <p
                              style={{
                                padding: "3px 5px",
                                marginRight: "6px",
                                fontSize: "14px",
                                fontWeight: 400,
                              }}
                            >
                              {" "}
                              Total Duration{" "}
                            </p>
                            <Typography
                              sx={{
                                background: "#D2B8FF",
                                color: "#310085",
                                fontSize: "13px",
                                borderRadius: "6px",
                                fontWeight: 600,
                                padding: "2px 10px",
                              }}
                            >
                              {" "}
                              {totalDuration.totalHours +
                                "h" +
                                ":" +
                                totalDuration.totalMinutes +
                                "m"}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                      <TableContainer
                        component={Paper}
                        sx={{
                          width: '100%',
                          border: '1px solid #adadad',
                          borderRadius: '8px',
                          m: '1rem 0',
                        }}
                      >
                        <Table>
                          <TableHead>
                            <TableRow
                              sx={{
                                height: '72px',
                                backgroundColor: '#F1F3F5',
                              }}
                            >
                              <TableCell>Task Name</TableCell>
                              <TableCell>Description</TableCell>
                              <TableCell>Worktype</TableCell>
                              <TableCell>Time</TableCell>
                              <TableCell>Duration</TableCell>

                            </TableRow>
                          </TableHead>
                          <TableBody>
                            { item?.tasks?.length > 0 ?
                            Array.isArray(item?.tasks) && item?.tasks?.length && item?.tasks?.map((values, index) => {
                              const start = Array.isArray(values.time_tracking) && values.time_tracking.length && values.time_tracking[values.time_tracking.length - 1].time_tracking_start
                              const end = Array.isArray(values.time_tracking) && values.time_tracking.length && values.time_tracking[values.time_tracking.length - 1].time_tracking_stop

                              const startDateTime = new Date(start);
                              const endDateTime = new Date(end);

                              const timeDifference = endDateTime - startDateTime;
                              const hours = Math.floor(timeDifference / (1000 * 60 * 60));
                              const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));

                              const startTime = new Date(start).toLocaleTimeString('en-US', {
                                hour: 'numeric',
                                minute: 'numeric',
                                hour12: true,
                              });


                              const endTime = new Date(end).toLocaleTimeString('en-US', {
                                hour: 'numeric',
                                minute: 'numeric',
                                hour12: true,
                              });

                              return (
                                <TableRow
                                  sx={{ height: '92px', '& > *': { borderBottom: 'unset' } }}
                                >
                                  <TableCell>
                                    <Box>
                                      <Typography>{values?.task_name}</Typography>
                                    </Box>
                                  </TableCell>
                                  <TableCell>
                                    <div
                                      dangerouslySetInnerHTML={{ __html: values?.time_tracking[0]?.note ?? '' }}
                                    />
                                  </TableCell>
                                  <TableCell>{values?.task_worktype[0]?.name ?? ''}</TableCell>
                                  <TableCell>
                                    {
                                      timeDifference === 0 ? (
                                        <>
                                          <Typography> No Time Tracked </Typography>
                                        </>
                                      ) : (
                                        <Box
                                          sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'flex-start',
                                            // width: '30%',
                                          }}
                                        >
                                          <Box
                                            sx={{
                                              display: 'flex',
                                              gap: '1rem',
                                            }}
                                          >
                                            <Typography
                                              sx={{
                                                fontWeight: '600',
                                                fontSize: '0.98rem',
                                              }}
                                            >
                                              {startTime}
                                        
                                            </Typography>
                                            <Typography
                                              sx={{
                                                fontWeight: '600',
                                                fontSize: '0.98rem',
                                              }}
                                            >
                                              {endTime}
                            
                                            </Typography>
                                          </Box>
                                          <Typography
                                            sx={{
                                              fontWeight: '400',
                                              fontSize: '0.88rem',
                                              color: '#686a78',
                                            }}
                                          >
                                            {moment(values?.time_tracking[0]?.date || end).format('MMMM DD, yy') !== 'January 01, 1970' ? moment(values?.time_tracking[0]?.date ?? end).format('MMMM DD, yy') : 'No Time Tracked'}
                                        
                                          </Typography>
                                        </Box>
                                      )
                                    }
                                  </TableCell>
                                  <TableCell>
                                    <Box
                                      sx={{
                                        width: '50%',
                                        backgroundColor: '#d2b8ff',
                                        borderRadius: '8px',
                                        p: '.54rem',
                                        textAlign: 'center',
                                        fontSize: '.9rem',
                                      }}
                                    >
                                      {hours + ' : ' + minutes + ' Hr'}
                
                                    </Box>
                                  </TableCell>
                                </TableRow>
                              );
                            })
                            :
                            <TableRow>
                                <TableCell colSpan={5} align="center">
                                    <p style={{ width: '100%', textAlign: 'center', alignSelf: 'center' }}
                                    >NO TASkS</p>
                                </TableCell>
                            </TableRow>
                            }
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                  )
                })}
              </>
            ) : ("")}

            {groupBy === 2 ? (
              <>
                <Typography sx={{ fontSize: "17px", fontWeight: 900, p: 1 }} > WorkTypes </Typography>

                {Array?.isArray(Updatedworktype) && Updatedworktype?.length > 0 && Updatedworktype?.map((item, i) => {
                  const totalDuration = totalHoursAndMinutes(item?.tasks);
                  return (
                    <Box key={i} sx={{ backgroundColor: '#F8F9FA', p: 1, borderRadius: 2, mb: 10 }}>
                      <Box
                        sx={{
                          display: "flex",
                          direction: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                          my: 1.5,
                          mx: 2,
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "flex-start",
                          }}
                        >
                          <Typography sx={{ fontSize: "16px" }}>
                            {item?.name}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            direction: "row",
                            justifyContent: "flex-end",
                            alignItems: "flex-end",
                          }}
                        >
                          <Box
                            sx={{
                              cursor: "pointer",
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              background: "#F1F3F5",
                              borderRadius: "10px",
                              padding: "8px 19px",
                            }}
                          >
                            <p
                              style={{
                                background: "#d2b8ff",
                                borderRadius: "50%",
                                padding: "3px 5px",
                                marginRight: "6px",
                                fontSize: "12px",
                              }}
                            >
                              {" "}
                              {item.tasks.length}{" "}
                            </p>
                            <Typography
                              sx={{
                                color: "#310085",
                                fontSize: "13px",
                                fontWeight: 600,
                              }}
                            >
                              {" "}
                              TASKS{" "}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              cursor: "pointer",
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              background: "#F1F3F5",
                              borderRadius: "10px",
                              padding: "8px 19px",
                              marginLeft: "14px",
                            }}
                          >
                            <p
                              style={{
                                padding: "3px 5px",
                                marginRight: "6px",
                                fontSize: "14px",
                                fontWeight: 400,
                              }}
                            >
                              {" "}
                              Total Duration{" "}
                            </p>
                            <Typography
                              sx={{
                                background: "#D2B8FF",
                                color: "#310085",
                                fontSize: "13px",
                                borderRadius: "6px",
                                fontWeight: 600,
                                padding: "2px 10px",
                              }}
                            >
                              {" "}
                              {totalDuration.totalHours +
                                "h" +
                                ":" +
                                totalDuration.totalMinutes +
                                "m"}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                      <TableContainer
                        component={Paper}
                        sx={{
                          width: '100%',
                          border: '1px solid #adadad',
                          borderRadius: '8px',
                          m: '1rem 0',
                        }}
                      >
                        <Table>
                          <TableHead>
                            <TableRow
                              sx={{
                                height: '72px',
                                backgroundColor: '#F1F3F5',
                              }}
                            >
                              <TableCell>Task Name</TableCell>
                              <TableCell>Description</TableCell>
                              <TableCell>Name</TableCell>
                              <TableCell>Time</TableCell>
                              <TableCell>Duration</TableCell>

                            </TableRow>
                          </TableHead>
                          <TableBody>
                            { item?.tasks?.length > 0 ?
                            Array.isArray(item?.tasks) && item?.tasks?.length && item?.tasks?.map((values, index) => {
                              const start = Array.isArray(values.time_tracking) && values.time_tracking.length && values.time_tracking[values.time_tracking.length - 1].time_tracking_start
                              const end = Array.isArray(values.time_tracking) && values.time_tracking.length && values.time_tracking[values.time_tracking.length - 1].time_tracking_stop

                              const startDateTime = new Date(start);
                              const endDateTime = new Date(end);

                              const timeDifference = endDateTime - startDateTime;
                              const hours = Math.floor(timeDifference / (1000 * 60 * 60));
                              const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));

                              const startTime = new Date(start).toLocaleTimeString('en-US', {
                                hour: 'numeric',
                                minute: 'numeric',
                                hour12: true,
                              });
                              const endTime = new Date(end).toLocaleTimeString('en-US', {
                                hour: 'numeric',
                                minute: 'numeric',
                                hour12: true,
                              });
                              let teamuser = userTeam.find(ele => values?.assignee_task.some(int => int.assignee_user === ele.id))

                              return (
                                <TableRow
                                  sx={{ height: '92px', '& > *': { borderBottom: 'unset' } }}
                                >
                                  <TableCell>
                                    <Box>
                                      <Typography>{values?.task_name}</Typography>
                                    </Box>
                                  </TableCell>
                                  <TableCell>
                                    <div
                                      dangerouslySetInnerHTML={{ __html: values?.time_tracking[0]?.note ?? '' }}
                                    />
                                  </TableCell>

                                  <TableCell  >{teamuser?.first_name + ' ' + teamuser?.last_name}</TableCell>
                                  <TableCell>
                                    {
                                      timeDifference === 0 ? (
                                        <>
                                          <Typography> No Time Tracked </Typography>
                                        </>
                                      ) : (
                                        <Box
                                          sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'flex-start',
                                            // width: '30%',
                                          }}
                                        >
                                          <Box
                                            sx={{
                                              display: 'flex',
                                              gap: '1rem',
                                            }}
                                          >
                                            <Typography
                                              sx={{
                                                fontWeight: '600',
                                                fontSize: '0.98rem',
                                              }}
                                            >
                                              {startTime}
                                            </Typography>
                                            <Typography
                                              sx={{
                                                fontWeight: '600',
                                                fontSize: '0.98rem',
                                              }}
                                            >
                                              {endTime}
                                            </Typography>
                                          </Box>
                                          <Typography
                                            sx={{
                                              fontWeight: '400',
                                              fontSize: '0.88rem',
                                              color: '#686a78',
                                            }}
                                          >
                                            {moment(values?.time_tracking[0]?.date || end).format('MMMM DD, yy') !== 'January 01, 1970' ? moment(values?.time_tracking[0]?.date ?? end).format('MMMM DD, yy') : 'No Time Tracked'}
                                          </Typography>
                                        </Box>
                                      )
                                    }
                                  </TableCell>
                                  <TableCell>
                                    <Box
                                      sx={{
                                        width: '50%',
                                        backgroundColor: '#d2b8ff',
                                        borderRadius: '8px',
                                        p: '.54rem',
                                        textAlign: 'center',
                                        fontSize: '.9rem',
                                      }}
                                    >
                                      {hours + ' : ' + minutes + ' Hr'}
                                    </Box>
                                  </TableCell>
                                </TableRow>
                              );
                            })
                            :
                            <TableRow>
                                <TableCell colSpan={5} align="center">
                                    <p style={{ width: '100%', textAlign: 'center', alignSelf: 'center' }}
                                    >NO TASkS</p>
                                </TableCell>
                            </TableRow>
                            }
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                  )
                })}
              </>
            ) : ("")}

          </div>
        </Box>
      </div>
    </>
  );
};

export default QuickTasks