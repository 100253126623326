import Header from './Header';
import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom'
import React, { useEffect, useState } from 'react';
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { useSelector } from 'react-redux';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import TextField from '@mui/material/TextField';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTime } from 'luxon';
import { Grid, Box } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import MUIRichTextEditor from 'mui-rte';
import { EditorState, convertToRaw, RichUtils } from 'draft-js';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { stateToHTML } from 'draft-js-export-html';
import { ENDPOINT } from '../../pages/Opportunity/Redux/oppor';
import { NetnetField } from '../../Componenets/NetField';
import { NetNetCheckBox } from '../../Componenets/Misc';
import { Watch } from '../../Componenets/TopNav/Watch';


const status = [
    'Backlog',
    'In Progress',
    'Submitted For Review',
    'Needs More Work',
    'Stuck',
    'Completed',
    'Archived',
  ];

function CreateTask({
    currentTarget,
    state,
    currentCompany,
    setState,
    toggleDrawer,
    anchor,
    setCreate
}) {
    const navigate = useNavigate();
    const Team = useSelector((state) => state.userTeam.team);

    const TeamOptions =
        Team && Team.member
            ? Team.member.map((item) => ({
                id: item.id,
                name: `${item.first_name} ${item.last_name}`,
                avatar: item.avatar,
            }))
            : false;

    useEffect(() => {
        console.log(currentTarget);
    }, []);

    const [formData, setFormData] = useState({
        task_name: '',
        description: '',
        work_type: '',
        company: currentCompany ? currentCompany.id : '',
        contact: currentTarget ? currentTarget.id : '',
        assignee: '',
        estimated_hours: 1,
        budget: '',
        task_name: '',
        due_date: DateTime.local(),
        billable: false,
        task_status: 'backlog',
    });

    const [html, setHtml] = useState('');

    const filter = createFilterOptions();
    const [editor, setEditor] = useState(() => {
        EditorState.createEmpty().getCurrentContent();
    });
    const [fieldEmpty, setFieldEmpty] = useState(editor);

    const fieldText = (e) => {
        setEditor(e);
        setHtml(stateToHTML(e.getCurrentContent()));
    };

    const currentCompanies = useSelector((state) => state.Contacts.companies);
    const currentPeoples = useSelector((state) => state.Contacts.persons);
    const [loading, setLoading] = useState(false);
    const token = useSelector((state) => state.auth.user.access);
    const [isError, setIsError] = useState(false);
    const [error, setError] = useState('');
    const companyOptions = currentCompanies.map((company) => ({
        name: company.name,
        id: company.id,
    }));

    const comp = formData.company
        ? companyOptions.filter((item) => item.id === formData.company)
        : null;
    const peo = comp
        ? currentPeoples.filter((item) => item.company === comp[0].name)
        : currentPeoples;

    const peopleOptions = peo.map((people) => ({
        name: people.first_name,
        id: people.id,
    }));

    const myTheme = createTheme({});

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (formData.task_name === '') {
            setIsError(true);
            setError('Name Is Required');
        } else if (formData.assignee === '') {
            setIsError(true);
            setError('Assignee Is Required');
        } else if (formData.work_type === '') {
            setIsError(true);
            setError('Work Type Is Required');
        } else if (formData.status === '') {
            setIsError(true);
            setError('Status Is Required');
        } else {
            for (const key in formData) {
                if (!formData[key]) {
                    delete formData[key];
                }
            }
            const response = await fetch(`${ENDPOINT}/api/project/task/create/`, {
                method: 'POST',
                headers: {
                    Authorization: `JWT ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(
                    formData.due_date
                        ? {
                            ...formData,
                            due_date: `${formData.due_date.c.year}-${formData.due_date.c.month}-${formData.due_date.c.day}T00:00:00Z`,
                            description: html,
                        }
                        : formData
                ),
            });
            const data = await response.json();
            if (!response.ok) {
                setIsError(true);
                for (const key in data) {
                    setError(data[key]);
                }
                setLoading(false);
            } else {
                navigate('/Tasks');
                setLoading(false);
                setCreate(false)
            }
        }
    };

    const userWorkTypes = useSelector((state) => state.Misc.workTypes);

    const types =
        Array.isArray(userWorkTypes) && userWorkTypes.length
            ? userWorkTypes.map((item) => item.name)
            : [];

    return (
        <>
            <Grid container alignItems="center" direction="column" >

                <Grid
                    container
                    item
                    sx={{
                        backgroundColor: '#fff',
                        borderRadius: '8px',
                        direction: 'column',
                        padding: '1rem',
                        position: 'relative',
                        width: '90%',
                    }}
                >
                    <Box
                        sx={{
                            width: '100%',
                            padding: '18px 23px',
                            textAlign: 'left',
                            fontFamily: 'Open Sans',
                            backgroundColor: '#f8f9fa',
                            color: '#711fff',
                            fontWeight: '700',
                            height: '68px',
                            fontSize: '1.30rem',
                            borderRadius: '4px',
                            margin: '1rem 0',
                            textTransform: 'uppercase',
                            borderRadius: '8px',
                        }}
                    >
                        Add A New Quick Task
                    </Box>
                    <Grid item container sx={7} md={7} lg={7}>
                        <Box
                            sx={{
                                padding: '0rem 0rem ',
                                borderRadius: '8px',
                                display: 'flex',
                                gap: '1.4rem',
                                background: '#fff',
                                flexDirection: 'column',
                                width: '90%',
                            }}
                        >
                            <Grid
                                container
                                sx={{

                                    borderRadius: '8px',
                                    backgroundColor: '#fff',
                                    padding: '1rem 0rem',
                                }}
                            >
                                <Grid item container direction="column" xs={12} md={10} lg={12} >
                                    <Box
                                        sx={{
                                            padding: '3px 3px',
                                            borderRadius: '8px',
                                        }}
                                    >
                                        <Box >
                                            <p className="modalSpan" style={{ marginTop: '2rem !important', marginBottom: '2rem !important' }}> Name</p>
                                            <Box sx={{ mt: 2 }}>
                                                <NetnetField
                                                    label="Enter Task Name"
                                                    variant="outlined"
                                                    className="MUI-P6-M5"
                                                    value={formData.task_name}
                                                    onChange={(e) => {
                                                        setFormData({
                                                            ...formData,
                                                            task_name: e.target.value,
                                                        });
                                                    }}
                                                    color="secondary"
                                                    style={{ height: 30, marginBottom: '1rem', marginTop: '20px', }}
                                                />
                                            </Box>
                                        </Box>
                                        <Box
                                            className="Editor-para1"
                                            style={{ marginTop: '1rem', marginBottom: '1rem' }}
                                        >
                                            <span className="modalSpan" >Description</span>
                                            <ThemeProvider theme={myTheme}>
                                                <MUIRichTextEditor
                                                    label="Enter Description"
                                                    inlineToolbar={false}
                                                    onChange={fieldText}
                                                    value={fieldEmpty}
                                                    controls={[
                                                        'bold',
                                                        'italic',
                                                        'underline',
                                                        'strikethrough',
                                                    ]}
                                                    customControls={[
                                                        {
                                                            name: 'stateLine',
                                                            icon: '|',
                                                            type: 'inline',
                                                        },
                                                        {
                                                            name: 'stateLine2',
                                                            icon: '|',
                                                            type: 'inline',
                                                        },
                                                    ]}

                                                    sx={{
                                                        '& .DraftEditor-editorContainer': {
                                                            border: '3px solid red !important', // Set the border style here
                                                        },
                                                        '& .MUIRichTextEditor-editorContainer-61': {
                                                            border: '3px solid red !important', // Set the border style here
                                                        },
                                                    }}
                                                />
                                            </ThemeProvider>
                                        </Box>
                                        <Box className=" Editor-para1" style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                                            <span className='modalSpan' >Work Types</span>
                                            <Autocomplete
                                                options={
                                                    Array.isArray(types) && types.length ? [...types] : []
                                                }
                                                id="ssce2"
                                                onChange={(e, value) => {
                                                    setFormData({
                                                        ...formData,
                                                        work_type: value,
                                                    });
                                                }}
                                                noOptionsText={
                                                    <Link to="/My_Worktypes"> + Add New</Link>
                                                }
                                                style={{ width: 300, marginTop: '0.7rem' }}
                                                renderOption={(props, option) => (
                                                    <>
                                                        <li {...props}>{option}</li>
                                                    </>
                                                )}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Select Work Type"
                                                        variant="outlined"
                                                        // value={formData.work_type}
                                                        color="secondary"
                                                        sx={{
                                                            '& .MuiOutlinedInput-root': {
                                                                '& fieldset': {
                                                                    borderColor: '#f2ebff',
                                                                },
                                                                '&:hover fieldset': {
                                                                    borderColor: '#711fff',
                                                                },
                                                                '&.Mui-focused fieldset': {
                                                                    borderColor: '#5a0bdf',
                                                                },
                                                                '&.Mui-error fieldset': {
                                                                    borderColor: 'red',
                                                                },
                                                            },
                                                            '& .MuiFormLabel-root': {
                                                                color: '#03071e66',
                                                            },
                                                            '& .MuiFormLabel-root.Mui-focused': {
                                                                color: '#5a0bdf',
                                                            },
                                                            '& .MuiFormLabel-root.Mui-error': {
                                                                color: 'red',
                                                            },
                                                        }}
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            style: { width: 300, marginTop: '0.7rem' },
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Box>
                                    </Box>

                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'flex-start',
                                        }}
                                    >
                                        <Box style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                                            <span className="modalSpan" style={{ marginBottom: '1rem' }}>Company</span>
                                            <Autocomplete
                                                id="company"
                                                options={companyOptions}
                                                includeInputInList
                                                value={
                                                    currentCompany && {
                                                        name: currentCompany.name,
                                                        id: currentCompany.id,
                                                    }
                                                }
                                                onChange={(e, value) => {
                                                    setFormData({
                                                        ...formData,
                                                        company: value.id,
                                                    });
                                                }}
                                                getOptionLabel={(option) => {
                                                    if (typeof option === 'string') {
                                                        return option;
                                                    }
                                                    if (option.inputValue) {
                                                        return option.inputValue;
                                                    }
                                                    return option.name;
                                                }}
                                                style={{ width: 300, }}
                                                filterOptions={(options, params) => {
                                                    const filtered = filter(options, params);
                                                    if (params.inputValue !== '') {
                                                        filtered.push({
                                                            inputValue: params.inputValue,
                                                            name: `Add "${params.inputValue}"`,
                                                        });
                                                    }
                                                    return filtered;
                                                }}
                                                renderOption={(props, option) => (
                                                    <li {...props}>{option.name}</li>
                                                )}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Enter Company Name"
                                                        variant="outlined"
                                                        color="secondary"
                                                        sx={{
                                                            marginTop: '0.7rem',
                                                            '& .MuiOutlinedInput-root': {
                                                                '& fieldset': {
                                                                    borderColor: '#f2ebff',
                                                                },
                                                                '&:hover fieldset': {
                                                                    borderColor: '#711fff',
                                                                },
                                                                '&.Mui-focused fieldset': {
                                                                    borderColor: '#5a0bdf',
                                                                },

                                                                '&.Mui-error fieldset': {
                                                                    borderColor: 'red',
                                                                },
                                                            },
                                                            '& .MuiFormLabel-root': {
                                                                color: '#03071e66',
                                                            },
                                                            '& .MuiFormLabel-root.Mui-focused': {
                                                                color: '#5a0bdf',
                                                            },
                                                            '& .MuiFormLabel-root.Mui-error': {
                                                                color: 'red',
                                                            },
                                                        }}
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            style: { width: 300, marginTop: '0.7rem' },
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Box>
                                        <Box>
                                            <span className="modalSpan" style={{ marginTop: '1rem', marginBottom: '1rem' }}>Person</span>
                                            <Autocomplete
                                                id="company"
                                                options={peopleOptions}
                                                includeInputInList
                                                value={
                                                    currentTarget && {
                                                        name: currentTarget.first_name,
                                                        id: currentTarget.id,
                                                    }
                                                }
                                                onChange={(e, value) => {
                                                    setFormData({
                                                        ...formData,
                                                        contact: value.id,
                                                    });
                                                }}
                                                getOptionLabel={(option) => option.name}
                                                style={{ width: 300, marginTop: '0.7rem' }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Enter Person Name"
                                                        variant="outlined"
                                                        color="secondary"
                                                        sx={{
                                                            '& .MuiOutlinedInput-root': {
                                                                '& fieldset': {
                                                                    borderColor: '#f2ebff',
                                                                },
                                                                '&:hover fieldset': {
                                                                    borderColor: '#711fff',
                                                                },
                                                                '&.Mui-focused fieldset': {
                                                                    borderColor: '#5a0bdf',
                                                                },

                                                                '&.Mui-error fieldset': {
                                                                    borderColor: 'red',
                                                                },
                                                            },
                                                            '& .MuiFormLabel-root': {
                                                                color: '#03071e66',
                                                            },
                                                            '& .MuiFormLabel-root.Mui-focused': {
                                                                color: '#5a0bdf',
                                                            },
                                                            '& .MuiFormLabel-root.Mui-error': {
                                                                color: 'red',
                                                            },
                                                        }}
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            style: { width: 300, marginTop: '0.7rem' },
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Box>
                                        <Box
                                            className="dFlex"
                                            sx={{
                                                gap: '0.5rem',
                                                fontSize: '1.1rem',
                                                fontWeight: 600,
                                            }}
                                        >
                                            <NetNetCheckBox
                                                onChange={(e) =>
                                                    setFormData({
                                                        ...formData,
                                                        billable: e.target.checked,
                                                    })
                                                }
                                            />{' '}
                                            <span style={{ marginTop: '1rem', marginBottom: '1rem' }}>Billable</span>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid
                        item
                    >
                        <Box
                            sx={{
                                padding: '10px 0px',
                                borderRadius: '8px',
                                width: '100%',
                            }}
                        >
                            <Box >
                                <span className="modalSpan" style={{ marginTop: '1rem', marginBottom: '1rem' }}>Assign to </span>
                                <Autocomplete
                                    options={
                                        TeamOptions || [{ name: 'You dont have any team yet' }]
                                    }
                                    includeInputInList
                                    freeSolo
                                    onChange={(e, value) => {
                                        setFormData({
                                            ...formData,
                                            assignee: value.id,
                                        });
                                    }}
                                    getOptionLabel={(option) => option.name}
                                    style={{ width: 300, marginTop: '0.7rem', marginBottom: '0.7rem' }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Enter Asingee Name"
                                            variant="outlined"
                                            value={formData.assignee}
                                            color="secondary"
                                            sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    '& fieldset': {
                                                        borderColor: '#f2ebff',
                                                    },
                                                    '&:hover fieldset': {
                                                        borderColor: '#711fff',
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        borderColor: '#5a0bdf',
                                                    },

                                                    '&.Mui-error fieldset': {
                                                        borderColor: 'red',
                                                    },
                                                },
                                                '& .MuiFormLabel-root': {
                                                    color: '#03071e66',
                                                },
                                                '& .MuiFormLabel-root.Mui-focused': {
                                                    color: '#5a0bdf',
                                                },
                                                '& .MuiFormLabel-root.Mui-error': {
                                                    color: 'red',
                                                },
                                            }}
                                            InputProps={{
                                                ...params.InputProps,
                                                style: { width: 300, marginTop: '0.7rem' },
                                            }}
                                        />
                                    )}
                                />
                            </Box>
                            <Box style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                                <span className="modalSpan" >Status</span>
                                <Autocomplete
                                    id="country"
                                    includeInputInList
                                    onChange={(e, value) => {
                                        setFormData({ ...formData, task_status: value });
                                    }}
                                    options={status}
                                    getOptionLabel={(option) => option}
                                    style={{ width: 300 }}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderColor: '#fff',
                                            },
                                            '&.Mui-disabled fieldset': {
                                                borderColor: '#fff',
                                            },
                                        },
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Enter Status"
                                            variant="outlined"
                                            value={formData.task_status}
                                            color="secondary"
                                            sx={{
                                                marginTop: '1rem',
                                                '& .MuiOutlinedInput-root': {
                                                    '& fieldset': {
                                                        borderColor: '#f2ebff',
                                                    },
                                                    '&:hover fieldset': {
                                                        borderColor: '#711fff',
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        borderColor: '#5a0bdf',
                                                    },

                                                    '&.Mui-error fieldset': {
                                                        borderColor: 'red',
                                                    },
                                                },
                                                '& .MuiFormLabel-root': {
                                                    color: '#03071e66',
                                                },
                                                '& .MuiFormLabel-root.Mui-focused': {
                                                    color: '#5a0bdf',
                                                },
                                                '& .MuiFormLabel-root.Mui-error': {
                                                    color: 'red',
                                                },
                                            }}
                                            InputProps={{
                                                ...params.InputProps,
                                                style: { width: 300, marginBottom: '0.7rem' },
                                            }}
                                        />
                                    )}
                                />
                            </Box>
                            <Box style={{ marginTop: '1rem', marginBottom: '1rem', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <span style={{ marginRight: '20px' }} >Hours</span>
                                <TextField
                                    id="outlined-basic"
                                    variant="outlined"
                                    value={formData.estimated_hours}
                                    onChange={(e) =>
                                        setFormData({
                                            ...formData,
                                            estimated_hours: e.target.value,
                                        })
                                    }
                                    color="secondary"
                                    sx={{
                                        width: '50%',
                                        marginTop: '0.7rem',
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderColor: '#f2ebff',
                                            },
                                            '&:hover fieldset': {
                                                borderColor: '#711fff',
                                            },
                                            '&.Mui-focused fieldset': {
                                                borderColor: '#5a0bdf',
                                            },

                                            '&.Mui-error fieldset': {
                                                borderColor: 'red',
                                            },
                                        },
                                        '& .MuiFormLabel-root': {
                                            color: '#03071e66',
                                        },
                                        '& .MuiFormLabel-root.Mui-focused': {
                                            color: '#5a0bdf',
                                        },
                                        '& .MuiFormLabel-root.Mui-error': {
                                            color: 'red',
                                        },
                                    }}
                                    type="number"
                                    InputProps={{
                                        inputProps: { min: 1 },
                                    }}
                                />
                            </Box>
                            <Box sx={{ alignItems: 'center', display: 'flex', justifyContent: 'space-between' }} >
                                <span style={{ marginTop: '1rem', marginBottom: '1rem', marginRight: '20px' }}>Due Date</span>
                                <LocalizationProvider dateAdapter={AdapterLuxon}>
                                    <DesktopDatePicker

                                        inputFormat="MM-dd-yyyy"
                                        variant="outlined"
                                        value={formData.due_date}
                                        onChange={(newValue) => {
                                            setFormData({
                                                ...formData,
                                                due_date: newValue,
                                            });
                                        }}
                                        sx={{
                                            width: '50%',

                                            "& .MuiButtonBase-root MuiIconButton-root MuiIconButton-edgeEnd MuiIconButton-sizeMedium": {

                                                paddingRight: '20px !important'
                                            },
                                            "& .css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root": {

                                                paddingRight: '20px !important'
                                            },
                                            '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                    borderColor: '#f2ebff',
                                                },
                                                '&:hover fieldset': {
                                                    borderColor: '#711fff',
                                                },
                                                '&.Mui-focused fieldset': {
                                                    borderColor: '#5a0bdf',
                                                },

                                                '&.Mui-error fieldset': {
                                                    borderColor: 'red',
                                                },
                                            },
                                            '& .MuiFormLabel-root': {
                                                color: '#03071e66',
                                            },
                                            '& .MuiFormLabel-root.Mui-focused': {
                                                color: '#5a0bdf',
                                            },
                                            '& .MuiFormLabel-root.Mui-error': {
                                                color: 'red',
                                            },
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                {...params}
                                                sx={{
                                                    width: '100px',

                                                }}
                                                className="MUI-P6-M5"
                                                style={{ marginTop: '0.7rem' }}
                                            />
                                        )}
                                    />
                                </LocalizationProvider>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid
                        item
                        sx={{
                            marginLeft: 'auto',
                        }}
                    >
                        {isError && <p className="error">{error}</p>}
                        <div className="button-groups">
                            <button
                                className="cancel-btn"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setCreate(false)
                                }}
                            >
                                Cancel
                            </button>
                            <button className="create-btn" onClick={handleSubmit}>
                                {loading ? (
                                    <i className="bx bx-loader-alt bx-spin" />
                                ) : (
                                    'Create'
                                )}{' '}
                            </button>
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}


import { Step1Content, Step2Content } from '../../Componenets/TopNav/TopNav';


const List = ({ anchor, toggleDrawer, setState, state, setShow, setShowModal, access_token }) => {
    const tasks = useSelector((state) => state.projects.tasks);
    const months = {
        '01': 'Jan',
        '02': 'Feb',
        '03': 'Mar',
        '04': 'Apr',
        '05': 'May',
        '06': 'Jun',
        '07': 'Jul',
        '08': 'Aug',
        '09': 'Sep',
        10: 'Oct',
        11: 'Nov',
        12: 'Dec',
    };

    const [currentTarget, setCurrentTarget] = useState(false);
    const [currentStep, setCurrentStep] = useState(1);

    function createData(
        id,
        name,
        company,
        assinger,
        due_date,
        time,
        status,
        time_tracking,
        assignee_task,
        project_task
    ) {
        return {
            id,
            name,
            company,
            assinger,
            due_date,
            time,
            status,
            time_tracking,
            assignee_task,
            project_task
        };
    }

    const rows =
        tasks && tasks.length > 0
            ? tasks.map((task) =>
                createData(
                    task.id,
                    task.task_name,
                    task.company ? task.company : null,
                    task.created_by,
                    task.due_date &&
                    `${months[task.due_date.split('-')[1]]} ${task.due_date.split('-')[2]
                    }, ${task.due_date.split('-')[0]}`,
                    task.estimated_hours,
                    task.status,
                    task.time_tracking,
                    task.assignee_task,
                    task.task_project
                )
            )
            : false;
    return (
        <Box
            sx={{
                width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250,
                transition: 'all 0.3s ease-in-out',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
            role="presentation"
        >
            <button
                className="close-modal"
                onClick={() => setState({ ...state, bottom: false })}
            ></button>
            {currentStep === 1 && (
                <Step1Content
                    setCurrentTarget={setCurrentTarget}
                    setCurrentStep={setCurrentStep}
                    rows={rows}
                    access_token={access_token}
                />
            )}{' '}
            {currentStep === 2 && (
                <Step2Content setState={setState} state={state} setShow={setShow} currentTarget={currentTarget} setCurrentStep={setCurrentStep} />
            )}
        </Box>
    );
};




import watch from './Assets/Time-Active-white.svg'
import Drawer from '@mui/material/Drawer';
import { NetProgress } from '../../Componenets/ProgressBar';
import { IoIosAddCircleOutline } from "react-icons/io";




function AllTask({ setDetailTask, detailTask }) {

    const myArr = useSelector((state) => state.projects.tasks);
    const Companies = useSelector((state) => state.Contacts.companies)


    const [screenSize, setScreenSize] = useState(window.innerWidth);
    const handleResize = () => {
        setScreenSize(window.innerWidth);
    };
    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const topTitle = {
        backgroundColor: '#7b2eff',
        height: '40px',
        width: '100%',
        paddingLeft: '20px',
        paddingRight: '20px',
        color: 'white',
        fontWeight: 'bold',
        display: 'flex',
        fontFamily: 'sans-serif',
        alignItems: 'center',
        marginBottom: "10px",
        justifyContent: 'space-between'
    }

    const card = {
        taskAdded: {
            width: '95%',
            borderRadius: '20px',
            margin: '0 auto',
            boxShadow: "0 2px 5px rgba(0, 0, 0, 0.3)",
            paddingBottom: '10px',

        }
    }

    const [create, setCreate] = useState(false)

    ////////////////////////////////////////////////////////////////////////////

    // this is time tracking function

    const target = useSelector((state) => state.projects.currentTarget);
    const access_token = useSelector((state) => state.auth.user.access);



    const [currentTarget, setCurrentTarget] = useState(false);
    const [show, setShow] = useState(false);


    const [state, setState] = React.useState({
        bottom: false,
    });

    useEffect(() => {
        if (typeof target === 'object' && target !== null) {
            if ('task_name' in target) {
                setCurrentTarget(true);
            } else {
                setCurrentTarget(false);
            }
        } else {
            setCurrentTarget(false);
        }
    }, [target]);


    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };


    return (
        <div style={{
            width: screenSize,
            background: 'white',
            height: '100vh',
            display: 'flex',
            flexDirection: 'column'
        }}>

            <Header />
            <div style={topTitle}>
                <p>MY TASKS</p>
                <Box style={{ display: 'flex', alignItems: 'center' }}>

                    {!currentTarget ? (
                        <div style={{ marginLeft: 'auto', marginRight: '20px' }}>
                            {['bottom'].map((anchor) => (
                                <React.Fragment key={anchor}>
                                    <img
                                        style={{ cursor: 'pointer' }}
                                        src={watch}
                                        height={'22px'}
                                        width={'22px'}
                                        alt=""
                                    />

                                    <Drawer
                                        sx={{
                                            '& .MuiDrawer-paper': {
                                                maxHeight: '82vh !important',
                                              },
                                        }}
                                        anchor={anchor}
                                        open={state[anchor]}
                                        onClose={toggleDrawer(anchor, false)}
                                    >
                                        <List
                                            anchor={anchor}
                                            setState={setState}
                                            state={state}
                                            toggleDrawer={toggleDrawer}
                                            setShow={setShow}
                                            access_token={access_token}
                                        />
                                    </Drawer>
                                </React.Fragment>
                            ))}
                        </div>
                    ) : (
                        <Box
                            sx={{
                                color: '#fff',
                                fontFamily: 'Open Sans',
                                fontWeight: '600',
                                cursor: 'pointer',
                                transition: 'all 0.3s ease-in-out',
                            }}
                        >
                            <Watch
                                currentTarget={target}
                                setCurrentTarget={setCurrentTarget}
                                toggleDrawer={toggleDrawer}
                                state={state}
                                setState={setState}
                            />
                        </Box>
                    )}
                    <FilterListOutlinedIcon />
                    <p
                        onClick={() => setCreate(true)}
                        style={{ fontSize: '23px', color: '#fff', marginLeft: '10px', cursor: 'pointer' }}>+</p>
                </Box>
            </div>

            <div className='ScrollShow' style={{ overflow: "auto" }}>

                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                    <Button
                        size="large"
                        variant='contant'
                        startIcon={<IoIosAddCircleOutline style={{ color: '#711FFF' }} />} // Add the start icon
                        sx={{
                            color: 'black', // Custom text color
                            borderRadius: '6px',
                            border: 'var(--none, 1.029px) solid var(--Functions-Tab-Stroke, #D9D9D9)',
                            background: 'var(--Violet-Violet-900, #F2EBFF)',
                            fontFamily: 'open-sans',
                            '&:hover': {
                                backgroundColor: '#F2EBFF', // Custom hover color
                            },
                            '&.Mui-focusVisible': {
                                backgroundColor: 'darkblue', // Custom focus color
                            },
                            '&.Mui-selected': {
                                backgroundColor: 'green', // Custom selected color
                            },
                        }}
                    >
                        ADD QUICK TASK
                    </Button>
                </Box>

                {
                    create ?
                        <div style={{ height: '100vh', marginBottom: '5rem', overflow: 'auto' }}>
                            <CreateTask setCreate={setCreate} />
                        </div>
                        : (
                            <div style={{ marginBottom: '100px' }}>
                                {
                                    Array.isArray(myArr) && myArr.length
                                        ? myArr.map((v, i) => {
                                            let company = Array.isArray(Companies) && Companies.find(flt => flt.id === v.company)

                                            const netTotal =
                                                Array.isArray(v.work_type) && v.work_type.length
                                                    ? v.work_type.reduce(
                                                        (acc, wk) => acc + Number(wk.hours),
                                                        0
                                                    )
                                                    : 0;

                                            const fullTotal =
                                                !netTotal &&
                                                    Array.isArray(v.assignee_task) &&
                                                    v.assignee_task.length
                                                    ? v.assignee_task.reduce(
                                                        (acc, obj) => acc + Number(obj.hours),
                                                        0
                                                    )
                                                    : 0;

                                            const total_tracked =
                                                Array.isArray(v.time_tracking) && v.time_tracking.length
                                                    ? v.time_tracking.reduce((total, acc) => {
                                                        const [hours, minutes] = acc.tracked_time_hours ? acc.tracked_time_hours?.split(':') : [0, 0]
                                                        const hoursInMinutes = Number(hours) + Number(minutes) / 60; // Convert minutes to hours
                                                        return total + hoursInMinutes;
                                                    }, 0)
                                                    : 0;
                                            return (
                                                <Link to='taskDetails' onClick={() => setDetailTask(v)} >
                                                    <div style={{ marginTop: '20px' }}>
                                                        <div style={card.taskAdded}>
                                                            <div style={{
                                                                backgroundColor: v.project == '' ? '#f2d9fa' : '#5921db',
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                textAlign: 'center',
                                                                height: 'auto',
                                                                paddingTop: '20px',
                                                                paddingBottom: '20px',
                                                                borderTopRightRadius: '20px',
                                                                borderTopLeftRadius: '20px',
                                                                marginBottom: '10px',
                                                                paddingLeft: '30px',
                                                            }}>

                                                                <div>
                                                                    <p style={{
                                                                        fontFamily: 'sans-serif',
                                                                        fontWeight: 'bold',
                                                                        color: v.project == '' ? 'black' : 'white',
                                                                    }}>
                                                                        {v.task_name}
                                                                    </p>
                                                                    <div style={{
                                                                        textAlign: 'start',
                                                                        fontFamily: 'revert',
                                                                        color: 'white',
                                                                        fontSize: '13px',
                                                                    }}>
                                                                        {company &&
                                                                            <svg style={{ marginRight: '10px' }} width="9" height="7" viewBox="0 0 9 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path id="Vector 4" d="M1 0V6H9" stroke="white" />
                                                                            </svg>
                                                                        }
                                                                        {company?.name}
                                                                    </div>

                                                                </div>

                                                            </div>

                                                            <div style={{
                                                                width: '90%', borderRadius: '10px',
                                                                backgroundColor: '#fafafa', margin: '0 auto',
                                                                padding: '7px', marginBottom: '10px',
                                                                fontFamily: 'sans-serif', fontWeight: 'bold',
                                                                color: '#7b2eff', fontSize: '15px'
                                                            }}>
                                                                <p style={{ color: v.project == '' ? 'gray' : '#7b2eff', }}>{v.work_type == '' ? 'No Project Assigned' : v.work_type}</p>
                                                            </div>

                                                            <div>
                                                                <div style={{
                                                                    width: '90%',
                                                                    display: 'flex',
                                                                    flexDirection: 'row',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'space-between',
                                                                    margin: '0 auto',
                                                                }}>
                                                                    <div>
                                                                        <div style={{
                                                                            backgroundColor: '#b7e8f7',
                                                                            padding: '5px',
                                                                            borderRadius: '10px',
                                                                            fontFamily: 'sans-serif',
                                                                            color: 'black'
                                                                        }}>
                                                                            <p>Due on: {v.due_date ? v.due_date.split('T')[0] : null}</p>
                                                                        </div>
                                                                    </div>

                                                                    <div style={{
                                                                        borderRadius: '10px',
                                                                        fontFamily: 'sans-serif',
                                                                        color: 'black'
                                                                    }}>
                                                                        <NetProgress
                                                                            status={v.status}
                                                                            obt={
                                                                                total_tracked
                                                                            }
                                                                            eta={
                                                                                fullTotal ? fullTotal : netTotal ? netTotal : v.estimated_hours
                                                                            }
                                                                        />
                                                                    </div>
                                                                </div>
                                                               </div>
                                                        </div>
                                                    </div>
                                                </Link>
                                            )
                                        })
                                        :
                                        <div style={{ width: '100%', height: '600px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                                            <Card sx={{ width: '90%', boxShadow: 'none' }}>
                                                <CardContent>
                                                    <Typography variant="h3" component="div">
                                                        NO TASK YET!
                                                        <br />
                                                    </Typography>
                                                    <Typography variant="body2" sx={{ fontSize: '14px', color: 'gray' }}>
                                                        All Your Task Would Appear Here!
                                                    </Typography>
                                                </CardContent>
                                                <CardActions>
                                                    <Button onClick={() => setCreate(true)} size="large" sx={{
                                                        width: '80%',
                                                        margin: '0 auto',
                                                        backgroundColor: '#1c04bd',
                                                        color: 'whitesmoke',
                                                        borderRadius: '10px', fontWeight: 'bold', fontFamily: 'open Sans'
                                                    }}>
                                                        Create Task
                                                    </Button>
                                                </CardActions>
                                            </Card>
                                        </div>
                                }
                            </div>
                        )
                }
            </div>
        </div>
    )
}

export default AllTask;