import {
  Grid,
  Box,
  Menu,
  MenuItem,
  TextField,
  Typography,
  Alert,
  Tooltip,
} from '@mui/material';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Autocomplete from '@mui/material/Autocomplete';
import { stateToHTML } from 'draft-js-export-html';
import MUIRichTextEditor from 'mui-rte';
import { EditorState  } from 'draft-js';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ENDPOINT } from '../../Opportunity/Redux/oppor';
import {
  DashedField2,
  DashedField,
} from '../../../Componenets/Misc';
import Duplicate from '../assets/Duplicate.PNG';
import subTask from '../assets/subTasks.PNG';
import updateTask from '../assets/updataTask.PNG';
import newTask from '../assets/newTask.PNG';
import DeleteIcon from '../assets/DeleteIcon.png';
import { getWorkTypes } from '../../User/redux';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import moment from 'moment';
import uuid from 'react-uuid';
import AddIcon from '@mui/icons-material/Add';

const spanSx = {
  fontWeight: '600',
  fontFamily: 'Open Sans',
  fontSize: '1.1rem',
};
const HeadCell = ({ arg }) => (
  <TableCell align="center" style={{ textAlign: 'center' }}>
    <div className="tcell">{arg}</div>
  </TableCell>
);

const reduceTaskWorktypes = (taskWorktypes) => {
  const reducedArray = [];
  if (Array.isArray(taskWorktypes) && taskWorktypes.length) {
    taskWorktypes.forEach((item) => {
      if (
        item.task_worktypes &&
        Array.isArray(item.task_worktypes) &&
        item.task_worktypes.length
      ) {
        item.task_worktypes.forEach((worktype) => {
          const existingItem = reducedArray.find(
            (item) => Number(item.id) === Number(worktype.id)
          );

          if (existingItem) {
            existingItem.hours += parseInt(worktype.hours ? worktype.hours : 0);
          } else {
            reducedArray.push({
              id: Number(worktype.id),
              hours: parseInt(worktype.hours ? worktype.hours : 0),
            });
          }
        });
      }
    });
  }

  return reducedArray;
};

const calculateTotalHours = (deliverables, worktypeId) => {
  return deliverables.reduce((total, deliverable) => {
    if (
      Array.isArray(deliverable.deliverable_worktype) &&
      deliverable.deliverable_worktype.length
    ) {
      return (
        total +
        deliverable.deliverable_worktype.reduce((subTotal, worktype) => {
          if (
            parseInt(worktype.id) === parseInt(worktypeId) &&
            worktype.hours !== ''
          ) {
            return subTotal + parseInt(worktype.hours);
          } else {
            return subTotal;
          }
        }, 0)
      );
    } else {
      return total;
    }
  }, 0);
};

function calculateTotalLOE(array) {
  if (!array) {
    return false;
  }
  if (!Array.isArray(array) && !array.length) {
    return false;
  }
  return array.reduce((total, obj) => {
    return total + obj.total_loe;
  }, 0);
}

const LastRow = ({ formData, dileverable, counter }) => {
  const [totalHours, setTotalHours] = useState([]);

  useEffect(() => {
    if (Array.isArray(dileverable) && dileverable.length) {
      const hours = formData.worktype.map((worktypeId) => {
        return calculateTotalHours(dileverable, worktypeId);
      });
      setTotalHours(hours);
    }
  }, [formData, dileverable, counter]);
  const NetTotal = calculateTotalLOE(dileverable);
  const myTotal = NetTotal ?? false;


  const totalAll = () => {
    if (Array.isArray(dileverable) && dileverable.length) {
      return dileverable.reduce((total, newArr) => {
        return total + newArr?.deliverable_worktype?.reduce((newTotal, item) => newTotal + Number(item.new_hours ? item.new_hours : item.hours), 0);
      }, 0);
    } else {
      return 0;
    }
  }
  return (
    <TableRow
      sx={{ backgroundColor: '#1e0052', color: '#fff', height: '110px' }}
    >
      <TableCell style={{ textAlign: 'left' }}>
        <div className="fstxt-wh">Total</div>
      </TableCell>
      {formData.collaborators &&
        Array.isArray(formData.collaborators) &&
        formData.collaborators.length
        ? formData.collaborators.map((item) => (
          <TableCell align="left">
            <div className="fstxt-wh">0hr</div>
          </TableCell>
        ))
        : null}

      {formData.worktype.map((worktypeId, index) => (
        <TableCell align="center" key={index}>
          <div className="fstxt-wh">
            {totalHours[index] ? `${totalHours[index]}hr` : ' 0hr'}
          </div>
        </TableCell>
      ))}
      <TableCell> </TableCell>
      <TableCell align="center">
        <div className="fstxt-wh">{totalAll() ? `${totalAll()}hr` : '0hr'}</div>
      </TableCell>
    </TableRow>
  );
};

const THead = ({ formData, setAddWork }) => {
  const userWorkTypes = useSelector((state) => state.Misc.workTypes);
  const colabs = useSelector((state) => state.Misc.collabs);
  console.log('Recieveing', formData);
  return (
    <TableHead
      sx={{
        height: '110px',
      }}
    >
      <TableRow>
        <TableCell align="left">
          <div className="tcell">
            Deliverables{' '}
            <InfoOutlinedIcon sx={{ color: '#4dfad2', fontSize: '15px' }} />{' '}
          </div>
        </TableCell>
        {Array.isArray(formData.worktype) &&
          formData.worktype.length
          ? formData.worktype.map((item) => {
            const work = { ...item } ?? {
              name: '...',
            };
            return <HeadCell arg={item.name} />;
          })
          : 'Pending Worktypes...'}
        <TableCell>
          <Box
            sx={{
              backgroundColor: '#f8f9fa',
              borderRadius: '8px',
              p: '0.44rem',
              color: '#711fff',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
            }}
            onClick={() => {
              console.log('Working');
              setAddWork(true);
            }}
          >
            <AddIcon sx={{ fill: '#711fff' }} />
          </Box>
        </TableCell>
        <TableCell style={{ textAlign: 'center', fontWeight: 'bold' }}>
          Total
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

const AddRows = ({ formData, dileverable, setDeliverable, setErrorState }) => {
  const [tempState, setTempState] = useState('');
  return (
    <TableRow
      sx={{
        width: '100%',
        height: '100px',
      }}
    >
      <TableCell
        align="left"
        sx={{
          display: 'flex',
          alignItems: 'baseline',
          justifyContent: 'flex-start',
          gap: '1rem',
          borderBottom: 'none',
        }}
      >
        <DashedField
        id="Cypress-input-addDeliverable"
          label="Add Dileverable"
          value={tempState}
          onChange={(e) => setTempState(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              const StateCheck =
                Array.isArray(dileverable) && dileverable.length
                  ? dileverable.find(
                    (item) => item.deliverable_name === tempState
                  )
                  : false;

              if (StateCheck) {
                setErrorState({
                  error: true,
                  message: 'A Deliverable with this name already exists',
                });
                return;
              }
              setDeliverable((prev) => {
                if (prev.length) {
                  return [
                    ...dileverable,
                    {
                      id: prev.length + 1,
                      deliverable_name: tempState,
                      deliverable_worktype: formData.worktype.map((i, n) => {
                        return {
                          id: i.id,
                          hours: '',
                        };
                      }),
                      description: '',
                      collaborators:
                        Array.isArray(formData.collaborators) &&
                          formData.collaborators.length
                          ? formData.collaborators
                          : [],
                    },
                  ];
                }
                return [
                  {
                    id: prev.length + 1,
                    deliverable_name: tempState,
                    deliverable_worktype: formData.worktype.map((i, n) => {
                      return {
                        id: i.id,
                        hours: '',
                      };
                    }),
                    description: '',
                  },
                ];
              });
              setTempState('');
            }
            return 0;
          }}
        />
      </TableCell>
      <TableCell> </TableCell>
    </TableRow>
  );
};


const DetailView = ({
  setShow,
  show,
  p,
  dileverable,
  setDeliverable,
  formData,
  setIsDetOpen
}) => {
  const [html, setHtml] = useState('');
  const [editor, setEditor] = useState(() => {
    EditorState.createEmpty().getCurrentContent();
  });
  const [fieldEmpty, setFieldEmpty] = useState(editor);
  const fieldText = (e) => {
    setEditor(e);
    setHtml(stateToHTML(e.getCurrentContent()));
  };

  const customOptions = dileverable.filter(
    (item) => item.deliverable_name !== p.deliverable_name
  );

  const [mem, setMem] = useState({
    description: '',
    deliverable_dependency: '',
  });

  const val = moment(formData.est_start_date);
  const val2 = () => {
    const index = dileverable.findIndex(
      (ent) => Number(ent.id) === Number(p.id)
    );
    return moment(dileverable[index].deliverable_enddate) ?? moment();
  };

  const myTheme = createTheme({});
  const handleClose = () => setShow(false);
  // useEffect(() => {

  // }, [html]);

  return (
    <Offcanvas show={show} onHide={handleClose} placement="bottom ">
      <Offcanvas.Header>
        <button className="close-modal" onClick={() => setShow(false)} />
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Grid container direction="column" alignItems="flex-start">
          <Grid
            item
            container
            direction="column"
            alignItems="center"
            xl={12}
            lg={12}
            md={12}
            sm={12}
          >
            <Box
              sx={{
                width: '100%',
                fontSize: '1.5rem',
                fontWeight: '600',
                fontFamily: 'Open Sans',
                textAlign: 'left',
                padding: '0.50rem 4.5rem',
              }}
            >
              {p.name}
            </Box>
            <Grid
              item
              container
              sx={{ width: '100%', gap: '1rem' }}
              justifyContent="center"
              alignItem="center"
            >
              <Grid item xl={5} lg={4.5} md={5.5}>
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    borderRadius: '8px',
                    border: '1px solid #d9d9d9',
                    padding: '0.44rem 0.88rem',
                    gap: '1rem',
                  }}
                >
                  <p
                    style={{
                      fontSize: '1rem',
                      fontWeight: '600',
                      fontFamily: 'Open Sans',
                    }}
                  >
                    Details
                  </p>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '0.5rem',
                      backgroundColor: '#f2ebff',
                      borderRadius: '8px',
                      padding: '0.44rem 0.88rem',
                    }}
                  >
                    <Box className="myJam">
                      <span className="modalSpan" style={{ color: 'GrayText' }}>
                        Duration{' '}
                        <InfoOutlinedIcon
                          sx={{ color: '#4dfad2', fontSize: '14px' }}
                        />
                      </span>
                      <Box
                        sx={{
                          width: '300px',
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                        }}
                      >
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          color="secondary"
                          placeholder="Enter Value"
                          sx={{
                            width: '60%',
                            outline: 'none',
                            borderTopLeftRadius: '10px',
                            borderStartStartRadius: '10px',
                            backgroundColor: 'white',
                            textAlign: 'center',
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                border: '1px solid #f2ebff',
                              },
                              '&:hover fieldset': {
                                border: '1px solid #711fff',
                              },
                              '&.Mui-focused fieldset': {
                                border: '1px solid #5a0bdf',
                              },
                              '&.Mui-error fieldset': {
                                border: '1px solid #f2ebff',
                              },
                            },
                            '& .MuiFormLabel-root': {
                              color: '#03071e66',
                            },
                            '& .MuiFormLabel-root.Mui-focused': {
                              color: '#5a0bdf',
                            },
                            '& .MuiFormLabel-root.Mui-error': {
                              color: 'red',
                            },
                          }}
                          type={'number'}
                          InputProps={{
                            inputProps: { min: 1 },
                          }}
                          onChange={(e) =>
                            setDeliverable((prev) => {
                              const shallowCopy = prev.map((item) =>
                                Object.assign({}, item)
                              );

                              const index = shallowCopy.findIndex(
                                (int) => Number(int.id) === p.id
                              );
                              const start = moment(
                                formData.est_start_date.split('T')[0]
                              ).toDate();
                              const end = moment(start)
                                .add(Number(e.target.value) * 7, 'days')
                                .toDate();

                              shallowCopy[index].deliverable_enddate = end;
                              return shallowCopy;
                            })
                          }
                        />
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            backgroundColor: '#f2f2f2',
                            width: '40%',
                            color: 'GrayText',
                            borderTopRightRadius: '10px',
                            borderEndEndRadius: '10px',
                            height: '45px'
                          }}
                        >
                          <span
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            <ArrowDropUpIcon
                              sx={{ marginBottom: '-15px' }}
                            />
                            <ArrowDropDownIcon />
                          </span>
                          <Typography sx={{ fontSize: '14px' }}>Week</Typography>
                        </Box>
                      </Box>
                    </Box>
                    <Box className="myJam">
                      <span className="modalSpan" style={{ color: 'GrayText' }}>
                        Dependency{' '}
                        <InfoOutlinedIcon
                          sx={{ color: '#4dfad2', fontSize: '15px' }}
                        />
                      </span>
                      <Autocomplete
                        id="company"
                        options={customOptions}
                        value={p.deliverable_dependency}
                        getOptionLabel={(option) => option.deliverable_name}
                        onChange={(_, value) => {
                          setDeliverable((prevDeliverables) => {
                            const updatedDeliverables = prevDeliverables.map(
                              (deliverable) => {
                                if (Number(deliverable.id) === Number(p.id)) {
                                  return {
                                    ...deliverable,
                                    deliverable_dependency: value,
                                  };
                                }
                                return deliverable;
                              }
                            );

                            return updatedDeliverables;
                          });
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            color="secondary"
                            InputProps={{
                              ...params.InputProps,
                              style: { width: 250 },
                            }}
                          />
                        )}
                      />
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item xl={6} lg={6} md={5.5}>
                <Box
                  sx={{
                    width: '100%',
                    backgroundColor: 'white',
                    padding: '0.88rem 1rem',
                    borderRadius: '8px',
                    border: '1px solid #d9d9d9',
                    height: { xl: '170px', lg: '154px', md: '154px' },
                    overflowY: 'scroll',
                  }}
                >
                  <span
                    style={{
                      fontWeight: '600',
                      fontFamily: 'Open Sans',
                    }}
                  >
                    Description
                  </span>
                  <Box className="df-r-j-c-gp-5 Editor-para5">
                    {' '}
                    <TextField
                      value={p.description}
                      onChange={(e) => {
                        setDeliverable((prev) => {
                          const shallowCopy = [
                            ...prev.map((item) => Object.assign({}, item)),
                          ];
                          const index = shallowCopy.findIndex(
                            (ment) => Number(ment.id) === Number(p.id)
                          );
                          shallowCopy[index].description = e.target.value;
                          return shallowCopy;
                        });
                      }}
                      sx={{
                        width: '100%',
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: 'white',
                            padding: '10.6px 14px',
                          },
                          '&:hover fieldset': {
                            borderColor: '#fff',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: '#fff',
                          },

                          '&.Mui-error fieldset': {
                            borderColor: '#fff',
                          },

                          '&.Mui-disabled fieldset': {
                            borderColor: 'grey',
                          },
                        },
                        '& .MuiFormLabel-root': {
                          color: '#000',
                        },
                        '& .MuiFormLabel-root.Mui-focused': {
                          color: '#fff',
                        },
                        '& .MuiFormLabel-root.Mui-error': {
                          color: '#000',
                        },
                      }}
                      multiline
                      rows={6}
                      columns={8}
                      placeholder="Enter your Description Here"
                    />
                  </Box>
                </Box>
              </Grid>

              <Grid item xl={12} lg={12} md={12}>
                <Box
                  sx={{
                    width: { xl: '93%', lg: '89%', md: '93%', sm: '90%' },
                    backgroundColor: '#fafafa',
                    padding: '0.88rem 1rem',
                    borderRadius: '8px',
                    margin: '0 auto',
                    height: '145px',
                  }}
                >
                  <span
                    style={{
                      fontWeight: '600',
                      fontFamily: 'Open Sans',
                    }}
                  >
                    Internal Notes
                  </span>
                  <Box className="df-r-j-c-gp-5 Editor-para10">
                    {' '}
                    <ThemeProvider theme={myTheme}>
                      <MUIRichTextEditor
                        label="Enter Description"
                        inlineToolbar={false}
                        onChange={fieldText}
                        value={fieldEmpty}
                        controls={[
                          'bold',
                          'italic',
                          'underline',
                          'strikethrough',
                        ]}
                        customControls={[
                          {
                            name: 'stateLine',
                            icon: '|',
                            type: 'inline',
                          },
                          {
                            name: 'stateLine2',
                            icon: '|',
                            type: 'inline',
                          },
                        ]}
                      />
                    </ThemeProvider>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Grid item sx={{ width: '90%', height: 'auto', padding: '10px' }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                }}
              >
                <button className="cancel-btn" onClick={() => setShow(false)}>
                  Cancel
                </button>
                <button
                  className="create-btn"
                  onClick={() => {
                    setShow(false);
                  }}
                >
                  Save
                </button>
              </div>
            </Grid>
          </Grid>
        </Grid>

      </Offcanvas.Body>
    </Offcanvas>
  );

};

const CreateTemplate = ({ setOpenTemp, openTemp, p }) => {

  const work_types = useSelector(state => state.work_type.category)
  console.log(work_types, 'wath')
  console.log(p, '======>')
  const newWork = work_types && work_types.filter(item => item.id === p?.deliverable_worktype[0]?.id)
  console.log(newWork, '===>')

  const [html, setHtml] = useState('');
  const [editor, setEditor] = useState(() => {
    EditorState.createEmpty().getCurrentContent();
  });
  const [fieldEmpty, setFieldEmpty] = useState(editor);
  const fieldText = (e) => {
    setEditor(e);
    setHtml(stateToHTML(e.getCurrentContent()));
  };

  const access_token = useSelector((state) => state.auth.user.access);

  const [jamData, setJamData] = useState({
    deliverable_name: '',
    description: '',
    tags: '',
  });


  useEffect(() => {
    setJamData({
      ...jamData,
      description: html,
    });
  }, [html]);

 

  const [localErr, setLocalErr] = useState(false);
  const [localErrMsg, setLocalErrMsg] = useState('');
  const [localSucc, setLocalSucc] = useState(false);
  const myTheme = createTheme({});

  const handleSubmision = async (e) => {
    if (!jamData.deliverable_name) {
      setLocalErr(true);
      setLocalErrMsg('Please Add a Deliverable Name');
      return;
    }

    const res = await fetch(
      `${ENDPOINT}/api/project/deliverable/template/create/`,
      {
        method: 'POST',
        headers: {
          Authorization: `JWT ${access_token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: uuid(),
          deliverable: [jamData],
        }),
      }
    );
    const data = await res.json();
    if (!res.ok) {
      console.log(data, 'No deliverable Template created');
      setLocalErr(true);
      setLocalErrMsg('Could not create Template');
      return;
    }
    setLocalSucc(true);
  };

  return (
    <Offcanvas
      show={openTemp}
      onHide={() => setOpenTemp(false)}
      placement="bottom "
    >
      <Offcanvas.Header>
        <button className="close-modal" onClick={() => setOpenTemp(false)} />
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Grid container direction="column" alignItems="flex-start">
          <Grid
            item
            container
            direction="column"
            alignItems="flex-start"
            xl={12}
            lg={12}
            md={12}
            sm={12}
          >
            <Box
              sx={{
                width: '100%',
                fontSize: '1.5rem',
                fontWeight: '600',
                textAlign: 'left',
                padding: { xl: '0.30rem 5.5rem', lg: '0.30rem 4.5rem', md: '0.30rem 3.5rem' },
                color: '#000'
              }}
            >
              Add New Delieverable Template
            </Box>
            <Box
              sx={{
                padding: { xl: '0.30rem 5.5rem', lg: '0.30rem 4.5rem', md: '0.30rem 3.5rem' },
                display: 'flex',
                alignItems: 'center',
                gap: '2rem',
                width: '100%'
              }}
            >
              <Typography
                sx={{
                  fontWeight: '600',

                  fontSize: '17px'
                }}
              >
                Current Delieverable
              </Typography>

              <Box
                sx={{
                  backgroundColor: '#f8f9fa',
                  borderRadius: '8px',
                  padding: '.5rem 1rem',
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: { xl: '30%', lg: '40%', md: '50%' }
                }}
              >
                <Box>
                  <Typography
                    sx={{
                      fontFamily: 'Open Sans',
                      fontWeight: '600',
                      color: '#000',

                    }}
                  >
                    {p.deliverable_name ? p.deliverable_name : ''}
                    <Typography sx={{ color: '#9c61ff', fontSize: '12px' }}>{Array.isArray(p.task_deliverable) && p.task_deliverable.length ? p.task_deliverable.length : '0'} TASKS</Typography>
                  </Typography>
                </Box>
                <Box style={{
                  fontWeight: '400',
                  display: 'flex',
                  alignItems: 'center',
                  backgroundColor: '#711FFF',
                  borderRadius: '5px',
                  color: 'var(--white-100)',
                  border: 'none',
                  textAlign: 'center',
                  fontSize: '13px',
                  padding: '0.3rem 1.3rem'
                }}>{newWork ? newWork[0]?.name : ''}</Box>
              </Box>
            </Box>
            <Grid
              item
              container
              sx={{
                width: { xl: '93%', lg: '93%', md: '95%' },
                gap: '1rem',
                borderRadius: '8px',
                border: '1px solid #d9d9d9',
                padding: '2rem 1.5rem',
                margin: '0 auto'
              }}
              justifyContent="center"
              alignItem="center"
              direction="column"
            >

              <Box sx={{ display: 'flex', width: '20%', justifyContent: 'space-between', alignItems: 'center' }}>

                <Typography
                  sx={{
                    color: '#000',
                    fontWeight: '600',
                    fontSize: '18px'
                  }}
                >
                  New Details
                </Typography>
                <InfoOutlinedIcon sx={{ color: '#4dfad2', fontSize: '15px' }} />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  padding: '0.88rem 1.44rem',
                  width: '100%',
                }}
              >
                <Box
                  sx={{
                    padding: '18px 24px 18px 24px',
                    borderRadius: '8px',
                    gap: '1rem',
                    boxShadow: '0px 8px 25px 0px #00000000',
                    backgroundColor: '#F1F3F5E5',
                    width: '48%',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      gap: '0.5rem',
                      width: '100%',

                    }}
                  >
                    <span style={spanSx}>Enter Your New Delieverable Name</span>

                    <TextField
                      placeholder="Delieverable Name"
                      variant="outlined"
                      value={jamData.deliverable_name}
                      onChange={(e) => {
                        setJamData({
                          ...jamData,
                          deliverable_name: e.target.value,
                        });
                      }}
                      color="secondary"
                      sx={{
                        backgroundColor: '#fff', width: '100%',
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: '#dddfe0e5',
                          },
                          '&:hover fieldset': {
                            borderColor: '#711fff',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: '#5a0bdf',
                          },
                        },
                      }
                      }
                    />
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    justifyContent: 'start',

                    gap: '1.5rem',
                    width: '48%',
                    height: '110px'
                  }}
                >
                  <Typography sx={{ fontSize: '17px' }}>Add More details to your Estimate</Typography>
                  <button className="create-btn">ADD TAGS</button>
                </Box>
              </Box>
            </Grid>

            <Grid item xl={12} lg={12} sx={{ width: '100%', marginTop: '1rem' }}>
              <Box
                sx={{
                  width: { xl: '93%', lg: '93%', md: '95%' },
                  backgroundColor: '#F1F3F5E5',
                  border: '1px solid #d9d9d9',
                  padding: '0.88rem 1rem',
                  borderRadius: '8px',
                  margin: '0 auto',
                  height: '145px',
                }}
              >
                <span
                  style={{
                    fontWeight: '600',
                    fontFamily: 'Open Sans',
                  }}
                >
                  Description
                </span>
                <Box className="df-r-j-c-gp-5 Editor-para10">
                  {' '}
                  <ThemeProvider theme={myTheme}>
                    <MUIRichTextEditor
                      label="Enter Description"
                      inlineToolbar={false}
                      onChange={fieldText}
                      value={fieldEmpty}
                      controls={[
                        'bold',
                        'italic',
                        'underline',
                        'strikethrough',
                      ]}
                      customControls={[
                        {
                          name: 'stateLine',
                          icon: '|',
                          type: 'inline',
                        },
                        {
                          name: 'stateLine2',
                          icon: '|',
                          type: 'inline',
                        },
                      ]}
                    />
                  </ThemeProvider>
                </Box>
              </Box>
            </Grid>
            {localErr && <Alert x={{ width: '93%', margin: '0 auto' }} severity="error">{localErrMsg}</Alert>}
            {localSucc && (
              <Alert sx={{ width: '93%', margin: '0 auto' }} severity="success">Template created Successfully</Alert>
            )}

            <Grid item sx={{ width: '97%', height: 'auto', padding: '10px' }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                }}
              >
                <button
                  className="cancel-btn"
                  onClick={() => setOpenTemp(false)}
                >
                  Cancel
                </button>
                <button
                  className="create-btn"
                  onClick={() => {
                    handleSubmision();
                  }}
                >
                  Save
                </button>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

const CustomCell = ({
  p,
  setOpen,
  dileverable,
  setDeliverable,
  formData,
  setOpenTemp,
  open,
  setIsDetOpen
}) => {
  const [show, setShow] = useState(false);
  const [menuIconEl, setMenuIconEl] = useState(false);
  const [showStuff, setShowStuff] = useState(false);

  const [loading, setLoading] = useState(false);
  const disrapancy = p.deliverable_worktype.some((worktype) => {
    return Number(worktype.hours);
  });
  useEffect(() => {
    setIsDetOpen(!show);
  }, [show]);
  return (
    <>
      <TableCell
        align="left"
        component="th"
        scope="row"
        onMouseOver={() => setShowStuff(true)}
        onMouseLeave={() => setShowStuff(false)}
      >
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            gap: '1rem',
            color: '#03071E',
            fontFamily: 'Open Sans',
            fontSize: '1rem',
            fontWeight: '400',
          }}
        >
          <DragIndicatorIcon
            sx={{
              opacity: showStuff ? '1' : 0,
              transition: 'all 0.3s ease-in-out',
              fontSize: '1.60rem',
              fill: showStuff ? '#8D8F99' : '#fff',
            }}
          />
          <p
            className="fstxt"
            style={{ cursor: 'pointer' }}
            onClick={() => setShow(true)}
          >
            {p.deliverable_name}
          </p>

          <MoreHorizIcon
            sx={{
              opacity: showStuff ? '1' : 0,
              transition: 'all 0.3s ease-in-out',
              fontSize: '1.60rem',
              fill: showStuff ? '#8D8F99' : '#fff',
            }}
            id="company-icon"
            aria-haspopup="true"
            aria-controls={menuIconEl ? 'company-menu' : undefined}
            aria-expanded={menuIconEl ? 'true' : undefined}
            onClick={(e) => setMenuIconEl(e.currentTarget)}
          />
          <Menu
            id="company-icon-menu"
            sx={{
              width: 'auto',
              '& .MuiMenu-paper': {
                border: '1px solid #03071E33',
                borderRadius: '10px',
                width: 'auto'
              },
              '& .MuiMenu-list': {
                margin: '5px 5px 5px 5px',
                overflowY: 'hidden',
                maxHeight: '300px',
                width: 'auto',

              },

            }}
            anchorEl={menuIconEl}
            open={!!menuIconEl}
            onClose={() => setMenuIconEl(null)}
            autoFocus={false}
          >
            <Tooltip
              title={
                disrapancy
                  ? 'You must zero out all the hours before you can add Tasks'
                  : ''
              }
            >
              <MenuItem
                className="mb-2"
                onClick={() => {
                  if (disrapancy) {
                    return;
                  }
                  setOpen(true);
                }}
              >
                <img src={subTask} className="icon-sm" />
                {'Add Tasks'}
              </MenuItem>
            </Tooltip>
            {p.sub_task && Array.isArray(p.sub_task) && p.sub_task.length ? (
              <MenuItem
                className="mb-2"
                onClick={() => {
                  setOpen(open ? false : true);
                }}
              >
                <img src={subTask} className="icon-sm" />{' '}
                {open ? 'Hide Tasks' : 'Show Tasks'}
              </MenuItem>
            ) : null}
            <MenuItem className="mb-2" onClick={() => {
              setOpenTemp(true)
              setMenuIconEl(null)
            }}>
              <img src={newTask} className="icon-sm" />
              New Deliverable Template
            </MenuItem>
            <MenuItem className="mb-2" onClick={() => {
              setMenuIconEl(null)
            }}>
              <img src={updateTask} className="icon-sm" />
              Update Deliverable Template
            </MenuItem>
            <MenuItem className="mb-2" onClick={() => {
              setMenuIconEl(null)
            }}>
              <img src={Duplicate} className="icon-sm" />
              Duplicate
            </MenuItem>
            <MenuItem
              className="text-danger mb-2"
              onClick={() => {
                setMenuIconEl(null)
                setDeliverable((prev) => {
                  const shallowCopy = [
                    ...prev.map((item) => Object.assign({}, item)),
                  ];
                  const index = shallowCopy.findIndex(
                    (ent) => Number(ent.id) === Number(p.id)
                  );
                  const updatedPrevs = shallowCopy.filter(
                    (_, dent) => dent !== index
                  );
                  return updatedPrevs;
                });
              }}
            >
              <img src={DeleteIcon} className="icon-sm" />
              Delete
            </MenuItem>
          </Menu>
        </div>
      </TableCell>
      <DetailView
        show={show}
        setShow={setShow}
        p={p}
        dileverable={dileverable}
        setDeliverable={setDeliverable}
        formData={formData}
        setIsDetOpen={setIsDetOpen}
      />
    </>
  );
};

const SubTaskCell = ({ c }) => (
  <TableCell align="right">
    <div
      id={`${c.id}`}
      style={{
        color: '#711fff',
        fontWeight: '600',
        fontFamily: 'Open Sans',
        fontSize: '1rem',
        display: 'flex',
        alignItems: 'center',
        gap: '1rem',
        padding: '0.88rem 1rem',
      }}
    >
      <img src={subTask} className="icon-sm" />
      {c.name}
    </div>
  </TableCell>
);
const AddTask = ({ setDeliverable, p, setOpen, formData }) => {
  const [str, setStr] = useState('');
  return (
    <Tooltip
      title="Press Enter to Add a New Task"
      arrow
      open={str.length > 2}
      placement="bottom"
    >
      <div
        style={{
          width: 'fit-content',
          padding: '1.44rem',
          display: 'flex',
          gap: '1rem',
          alignItems: 'center',
        }}
      >
        <img src={subTask} className="icon-sm" />
        <DashedField
          label="Add New Task"
          value={str}
          onChange={(e) => setStr(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === 'Enter' && str) {
              setDeliverable((drev) => {
                const prev = [...drev.map((item) => Object.assign({}, item))];
                const find = prev.find((ele) => ele.id === p.id);
                const int = prev.indexOf(find);
                if (int !== -1) {
                  if ('sub_task' in prev[int] && prev[int].sub_task.length) {
                    prev[int].sub_task = [
                      ...prev[int].sub_task,
                      {
                        id: prev[int].sub_task.length + 1,
                        name: e.target.value,
                        task_worktypes: formData.worktype.map((i, n) => {
                          return {
                            id: i,
                            hours: '',
                          };
                        }),
                        collaborators:
                          Array.isArray(formData.collaborators) &&
                            formData.collaborators.length
                            ? formData.collaborators
                            : [],
                      },
                    ];
                  } else {
                    prev[int].sub_task = [
                      {
                        id: 1,
                        name: e.target.value,
                        task_worktypes: formData.worktype.map((i, n) => {
                          return {
                            id: i,
                            hours: '',
                          };
                        }),
                        collaborators:
                          Array.isArray(formData.collaborators) &&
                            formData.collaborators.length
                            ? formData.collaborators
                            : [],
                      },
                    ];
                  }
                } else {
                  console.warn(`Unable to find task${find}`);
                }
                return prev;
              });
              setStr('');
            }
          }}
        />
      </div>
    </Tooltip>
  );
};

function calculateTotalHoursAcumm(deliverable) {
  if (
    deliverable &&
    Array.isArray(deliverable.deliverable_worktype) &&
    deliverable.deliverable_worktype.length
  ) {
    return deliverable.deliverable_worktype.reduce((total, worktype) => {
      if (!worktype || !worktype.hours) {
        return 0;
      }
      const net = total + Number(worktype.hours ? worktype.hours : 0);
      return net;
    }, 0);
  }
  return 0;
}

function calculateTotalHoursAcumm3(deliverable) {
  if (
    deliverable &&
    Array.isArray(deliverable.deliverable_worktype) &&
    deliverable.deliverable_worktype.length
  ) {
    return deliverable.deliverable_worktype.reduce((total, worktype) => {
      if (!worktype || !worktype.new_hours) {
        return 0;
      }
      const net = total + Number(worktype.new_hours ? worktype.new_hours : 0);
      return net;
    }, 0);
  }
  return 0;
}

function calculateTotalHoursAcumm2(task) {
  if (
    task &&
    Array.isArray(task.task_worktypes) &&
    task.task_worktypes.length
  ) {
    return task.task_worktypes.reduce((total, worktype) => {
      const net = total + Number(worktype.hours);
      return net;
    }, 0);
  }
  return 0;
}

const CustomRow = ({
  provided,
  index,
  p,
  formData,
  setDeliverable,
  dileverable,
  counter,
  setCounter,
  setChangeCount,
  changeCount,
  altData,
  setAltData,
  newState,
  setIsDetOpen
}) => {
  const [open, setOpen] = useState(false);
  const [openTemp, setOpenTemp] = useState(false);
  const access_token = useSelector((state) => state.auth.user.access);
  const [newVal, setNewVal] = useState([]);
  const myTheme = createTheme({});
  const [html, setHtml] = useState('');

  const [editor, setEditor] = useState(() => {
    EditorState.createEmpty().getCurrentContent();
  });
  const [fieldEmpty, setFieldEmpty] = useState(editor);
  const fieldText = (e) => {
    setEditor(e);
    setHtml(stateToHTML(e.getCurrentContent()));
  };

  useEffect(() => {
    if (
      Array.isArray(p.deliverable_worktype) &&
      p.deliverable_worktype.length
    ) {
      const valll = p.deliverable_worktype.map((item) => {
        return {
          id: item.id,
          name: item.name,
          newHours: 0,
        };
      });

      setNewVal(valll.map((valueee) => ({ ...valueee })));
    }
  }, []);

  const [makingChange, setMakingChange] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getWorkTypes({ access_token }));
  }, []);

  const checker = Array.isArray(p.sub_task) ? p.sub_task : [];

  useEffect(() => {
    const prev = dileverable;
    const shallowCopy = [...prev.map((item) => Object.assign({}, item))];
    const index = shallowCopy.findIndex(
      (ele) => Number(ele.id) === Number(p.id)
    );
    const currentElement = shallowCopy[index];
    if (
      currentElement &&
      currentElement.sub_task &&
      Array.isArray(currentElement.sub_task) &&
      currentElement.sub_task.length
    ) {
      const trout = reduceTaskWorktypes(currentElement.sub_task);
      if (trout) {
        setDeliverable((zas) => {
          const anCopy = [...zas.map((ma) => Object.assign({}, ma))];
          const firstI = anCopy.findIndex(
            (mas) => Number(mas.id) === Number(p.id)
          );
          if (firstI !== -1) {
            anCopy[firstI].deliverable_worktype = trout;
          }
          return anCopy;
        });
      }
    }
  }, [checker, counter]);

  const netHours = calculateTotalHoursAcumm(p);

  useEffect(() => {
    setDeliverable((prev) => {
      const shallowCopy = [...prev];
      const index = shallowCopy.findIndex((dat) => dat.id === p.id);
      shallowCopy[index].total_loe = Number(netHours);
      return shallowCopy;
    });
  }, [netHours]);

  const Cancel = (_) => {
    setNewVal((prev) => prev.map((litem) => ({ ...litem, newHours: 0 })));
  };

  useEffect(() => {
    setIsDetOpen(!openTemp);
  }, [openTemp]);

  useEffect(() => {
    if(dileverable){
      setDeliverable((prev) => {
        const shallowCopy = [...prev];
        const index = shallowCopy.findIndex((dat) => dat.id === p.id);
        shallowCopy[index].change_reason = html;
        return shallowCopy;
      });
    }
    }, [html]);

  return (
    <>
      <TableRow
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        sx={{
          width: '100%',
          height: '100px',
          borderBottom: 'none',
          '&:last-child td, &:last-child th': { border: 0 },
        }}
        hover
        key={index}
      >
        <CustomCell
          p={p}
          setDeliverable={setDeliverable}
          setOpen={setOpen}
          dileverable={dileverable}
          formData={formData}
          setOpenTemp={setOpenTemp}
          open={open}
          setIsDetOpen={setIsDetOpen}
        />
        {Array.isArray(formData.collaborators) && formData.collaborators.length
          ? formData.collaborators.map((item) => (
            <TableCell align="left"> </TableCell>
          ))
          : null}
        {p &&
          Array.isArray(p.deliverable_worktype) &&
          p.deliverable_worktype.length
          ? p.deliverable_worktype.map((i, n) => {
            const Objection =
              Array.isArray(p.sub_task) && p.sub_task.length ? true : false;
            const SecondObjection = changeCount > 0;
            //   i.prevHours = i.hours;
            return (
              <TableCell align="center" key={n}>
                <Tooltip
                  title={
                    Objection
                      ? 'You can only enter Task Hours'
                      : SecondObjection
                        ? 'You have already commited a change.'
                        : ''
                  }
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      textAlign: 'center',
                    }}
                  >
                    <DashedField2
                      defaultValue="0h"
                      value={
                        i.new_hours ? i.new_hours : 0
                      }
                      onChange={(e) => {
                        if (Objection || SecondObjection) {
                          return;
                        }
                        setMakingChange(true);

                        setDeliverable((prev) => {
                          const shallowCopy = [...prev];
                          const int = shallowCopy.findIndex(
                            (vil) => vil.id === p.id
                          );
                          shallowCopy[int].changed = true
                          const thisInt = shallowCopy[
                            int
                          ].deliverable_worktype.findIndex(
                            (num) => Number(num.id) === i.id
                          );
                          shallowCopy[int].deliverable_worktype[
                            thisInt
                          ].new_hours = e.target.value;

                          return shallowCopy;
                        });
                      }}
                    />
                    <Typography
                      sx={{ color: '#8e9a97', marginLeft: '0.5rem' }}
                    >
                      {i.hours}
                    </Typography>
                  </Box>
                </Tooltip>
              </TableCell>
            );
          })
          : null}
        <TableCell> </TableCell>
        <TableCell style={{ fontWeight: 'bold', textAlign: 'center' }}>
          {p.changed &&
            <Box sx={{ width: '100%' }}>
              <Typography sx={{ borderBottom: '1px solid black', }}> {calculateTotalHoursAcumm3(p) ? `${calculateTotalHoursAcumm3(p)}hr` : '0h'}</Typography>
              <Typography>{netHours ? `${netHours}hr` : '0h'}</Typography>
            </Box>

          }
          {!p.changed ? (netHours ? `${netHours}hr` : '0h') : ''}
        </TableCell>
      </TableRow>
      {open && p.sub_task && Array.isArray(p.sub_task) && p.sub_task.length
        ? p.sub_task.map((c) => (
          <TableRow style={{ height: '90px', background: '#f8f9fa' }}>
            <SubTaskCell c={c} />
            {Array.isArray(formData.collaborators) &&
              formData.collaborators.length
              ? formData.collaborators.map((_) => (
                <TableCell align="left"> </TableCell>
              ))
              : null}

            {c.task_worktypes.map((i, n) => (
              <TableCell
                style={{ textAlign: 'center', background: '#f8f9fa' }}
                key={n}
              >
                <DashedField2
                  value={i.hours}
                  onChange={(e) => {
                    setCounter(counter + 1);
                    setDeliverable((prev) => {
                      const shallowCopy = [
                        ...prev.map((item) => Object.assign({}, item)),
                      ];
                      const FIndex = shallowCopy.findIndex(
                        (ent) => Number(ent.id) === Number(p.id)
                      );
                      const SIndex = shallowCopy[FIndex].sub_task.findIndex(
                        (dent) => Number(dent.id) === Number(c.id)
                      );
                      const FinalIndex = shallowCopy[FIndex].sub_task[
                        SIndex
                      ].task_worktypes.findIndex(
                        (mle) => Number(mle.id) === Number(i.id)
                      );
                      shallowCopy[FIndex].sub_task[SIndex].task_worktypes[
                        FinalIndex
                      ].hours = Number(e.target.value);
                      return shallowCopy;
                    });
                  }}
                />
              </TableCell>
            ))}
            <TableCell> </TableCell>
            <TableCell align="center">
              <div style={{ width: '100%' }}>
                {calculateTotalHoursAcumm2(c)}
              </div>
            </TableCell>
          </TableRow>
        ))
        : null}

      {open && (
        <AddTask
          setDeliverable={setDeliverable}
          setOpen={setOpen}
          p={p}
          formData={formData}
        />
      )}

      <CreateTemplate openTemp={openTemp} setOpenTemp={setOpenTemp} p={p} />
      {makingChange && !changeCount > 0 || newState ? (
        <TableRow>
          <TableCell colSpan={p.deliverable_worktype.length + 3}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
              }}
            >
              <Box
                className="df-r-j-c-gp-5 Editor-para5"
                sx={{
                  backgroundColor: '#f1f3f5',
                  width: '100%',
                  borderRadius: '10px',
                  border: '1px solid #ddd',
                  transition: 'all 0.4s ease-in-out',
                  opacity: makingChange && !changeCount > 0 || newState ? 1 : 0,
                }}
              >
                {' '}
                <ThemeProvider theme={myTheme}>
                  <MUIRichTextEditor
                    label={newState ? newState.change_reason : "Mention why are you making this change."}
                    placeholder={newState ? newState.change_reason : "Mention why are you making this change."}
                    inlineToolbar={false}

                    onChange={fieldText}
                    value={fieldEmpty}
                    controls={['bold', 'italic', 'underline', 'strikethrough']}
                    customControls={[
                      {
                        name: 'stateLine',
                        icon: '|',
                        type: 'inline',
                      },
                      {
                        name: 'stateLine2',
                        icon: '|',
                        type: 'inline',
                      },
                    ]}
                  />
                </ThemeProvider>
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'flex-end',
                  p: '0.44rem 0.88rem',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '1rem',
                  }}
                >
                  <button className="cancel-btn" onClick={Cancel}>
                    Cancel
                  </button>
                  <button
                    className="create-btn"
                    onClick={(e) => {
                      e.preventDefault();
                      setAltData({
                        ...altData,
                        change_reason: html,
                        new_deliverable_worktype: newVal,
                      });
                      setChangeCount((prev) => prev + 1);
                    }}
                  >
                    Save
                  </button>
                </Box>
              </Box>
            </Box>
          </TableCell>
        </TableRow>
      ) : null}
    </>
  );
};

const EstTable = ({
  formData,
  setFormData,
  steps,
  dileverable,
  setDeliverable,
  setErrorState,
  addWork,
  setAddWork,
  altData,
  setAltData,
  newState,
  setIsDetOpen
}) => {
  const [counter, setCounter] = useState(0);
  const [changeCount, setChangeCount] = useState(0);
  useEffect(() => {
    setFormData({
      ...formData,
      dileverable,
      real_workTypes: formData.deliverable_project[0].deliverable_worktype,
    });
  }, [dileverable, counter]);

  const handleOnDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const items = Array.from(dileverable);
    const [reorderediItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderediItem);
    setDeliverable(items);
  };

  return dileverable.length ? (
    <>
      {formData.type === 'retainer' && (
        <Alert severity="info">
          PLEASE REMEMBER! THESER ARE TOTAL HOURS PER MONTH
        </Alert>
      )}
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="rows">
          {(provided) => (
            <TableContainer
              {...provided.droppableProps}
              ref={provided.innerRef}
              component={Paper}
              sx={{
                width: '100%',
                backgroundColor: '#fff',
              }}
            >
              <Table aria-label="simple table">
                <THead formData={formData} setAddWork={setAddWork} />
                <TableBody>
                  {dileverable.map((p, index) => (
                    <Draggable key={p.id} draggableId={p.name} index={index}>
                      {(provided, snapshot) => (
                        <CustomRow
                          provided={provided}
                          snapshot={snapshot}
                          index={index}
                          p={p}
                          formData={formData}
                          setDeliverable={setDeliverable}
                          dileverable={dileverable}
                          counter={counter}
                          setCounter={setCounter}
                          changeCount={changeCount}
                          setChangeCount={setChangeCount}
                          altData={altData}
                          setAltData={setAltData}
                          newState={newState}
                          setIsDetOpen={setIsDetOpen}
                        />
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                  <AddRows
                    formData={formData}
                    dileverable={dileverable}
                    setDeliverable={setDeliverable}
                    setErrorState={setErrorState}
                  />
                  <LastRow
                    formData={formData}
                    dileverable={dileverable}
                    counter={counter}
                    setCounter={setCounter}
                  />
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Droppable>
      </DragDropContext>
    </>
  ) : (
    <Table aria-label="simple table">
      <THead formData={formData} setAddWork={setAddWork} />
      <TableBody>
        <AddRows
          formData={formData}
          dileverable={dileverable}
          setDeliverable={setDeliverable}
          setErrorState={setErrorState}
        />
        <LastRow formData={formData} />
      </TableBody>
    </Table>
  );
};

const DilChange = ({
  formData,
  setFormData,
  steps,
  dileverable,
  setDeliverable,
  setErrorState,
  altData,
  setAltData,
  newState,
  setNewState,
  setIsDetOpen
}) => {
  const [addWork, setAddWork] = useState(false);

  const userWorkTypes = useSelector((state) => state.Misc?.workTypes);
  const [newWork, setNewWork] = useState('');

  return (
    <>
      <Grid item container direction="column">
        <EstTable
          formData={formData}
          setFormData={setFormData}
          steps={steps}
          dileverable={dileverable}
          setDeliverable={setDeliverable}
          setErrorState={setErrorState}
          addWork={addWork}
          setAddWork={setAddWork}
          altData={altData}
          setAltData={setAltData}
          newState={newState}
          setIsDetOpen={setIsDetOpen}
        />
      </Grid>
      {addWork && (
        <section
          className="loading"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            width: '100%',
            backgroundColor: 'rgba(255,255,255,0.5)',
            position: 'fixed',
            top: 0,
            left: 0,
            zIndex: 999,
          }}
        >
          <Box
            sx={{
              width: '500px',
              height: '200px',
              backgroundColor: 'rgba(255,255,255)',
              border: '1px solid #d9d9d9',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              borderRadius: '8px',
              padding: '0.68rem 1rem',
              justifyContent: 'center',
              gap: '1rem',
            }}
          >
            <Typography
              sx={{ fontSize: '0.9rem', color: '#711fff', fontWeight: 'bold' }}
            >
              Add New Worktype
            </Typography>
            <Typography
              sx={{ fontSize: '0.9rem', color: '#000', fontWeight: 'bold' }}
            >
              Please select the worktype below for which you want to add.
            </Typography>

            <Select
              label="select your worktype"
              autoWidth
              sx={{ minWidth: '111px' }}
              onChange={(e) => setNewWork(e.target.value)}
              variant="standard"
            >
              {Array.isArray(userWorkTypes) && userWorkTypes.length ? (
                userWorkTypes
                  .map((item) => {
                    return (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    );
                  })
              ) : (
                <MenuItem>You do not have any worktypes</MenuItem>
              )}
            </Select>

            <Box
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '1rem',
              }}
            >
              <button className="cancel-btn">Cancel</button>
              <button
                className="create-btn"
                onClick={() => {
                  setDeliverable((prev) => {
                    if (newWork) {
                      const shallowCopy = prev.map((item) =>
                        Object.assign({}, item)
                      );
                      const find = userWorkTypes.find(
                        (wk) => Number(wk.id) === Number(newWork)
                      );
                      const newCopy = shallowCopy.map((tem) => {
                        const newWorktypes = [
                          ...tem.deliverable_worktype,
                          find,
                        ];
                        return {
                          ...tem,
                          deliverable_worktype: newWorktypes,
                        };
                      });
                      return newCopy;
                    } else {
                      return [...prev];
                    }
                  });
                  setAddWork(false);
                }}
              >
                Save
              </button>
            </Box>
          </Box>
        </section>
      )}
    </>
  );
};

export default DilChange;
