const GET_TOTAL_TIME = 'netnet/Redux/GET_TOTAL_TIME';

export const getTime = (payload) => ({
  type: GET_TOTAL_TIME,
  payload,
});

const initalState = {
  Tracking: null,
};

const MeReducer = (state = initalState, action) => {
  switch (action.type) {
    case GET_TOTAL_TIME:
      return {
        ...state,
        Tracking: action.payload,
      };
    default:
      return state;
  }
};

export default MeReducer;
