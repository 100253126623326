import React from 'react';
import { CSVLink } from 'react-csv';
import { Box } from '@mui/material';
const headers = [
  { label: 'First Name', key: 'First Name' },
  { label: 'Last Name', key: 'Last Name' },
  { label: 'Email', key: 'Email' },
  { label: 'Personal Description', key: 'Personal Description' },
  { label: 'Mobile Number', key: 'Mobile Number' },
  { label: 'Company Name', key: 'Company Name' },
  { label: 'Title', key: 'Title' },
  { label: 'Person Country', key: 'Person Country' },
  { label: 'Person State / Province', key: 'Person State / Province' },
  { label: 'Person City', key: 'Person City' },
  { label: 'Person Address Line 1', key: 'Person Address Line 1' },
  { label: 'Person Address Line 2', key: 'Person Address Line 2' },
  { label: 'Person Zip / Postal Code', key: 'Person Zip / Postal Code' },
  { label: 'Tags', key: 'Tags' },
  { label: 'Personal LinkedIn', key: 'Personal LinkedIn' },
  { label: 'Personal Instagram', key: 'Personal Instagram' },
  { label: 'Personal X', key: 'Personal X' },
  { label: 'Personal Facebook', key: 'Personal Facebook' },
  { label: 'Company Email 1', key: 'Company Email 1' },
  { label: 'Company Email 2', key: 'Company Email 2' },
  { label: 'Company Description', key: 'Company Description' },
  { label: 'Company Zip Code', key: 'Company Zip Code' },
  { label: 'Company Country', key: 'Company Country' },
  { label: 'Company City', key: 'Company City' },
  { label: 'Company State / Province', key: 'Company State / Province' },
  { label: 'Company Address 1', key: 'Company Address 1' },
  { label: 'Company Address 2', key: 'Company Address 2' },
  { label: 'Company Instagram', key: 'Company Instagram' },
  { label: 'Company X', key: 'Company X' },
  { label: 'Company Facebook', key: 'Company Facebook' },
  { label: 'Company Linkedin', key: 'Company Linkedin' },
  { label: 'Office Telephone', key: 'Office Telephone' },
  { label: 'NAICS Industry', key: 'NAICS Industry' },
  { label: 'Company Tags', key: 'Company Tags' },
  { label: 'Company Type', key: 'Company Type' },
  { label: 'Company Description', key: 'Company Description' },
  { label: 'Company URL', key: 'Company URL' },
];

const data = [];

const SampleCSV = () => (
  <Box
    sx={{
      height: '100%',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
    }}
  >
    <CSVLink
      data={data}
      headers={headers}
      filename="Sample.csv"
      enclosingCharacter={''}
    >
      Download Sample
    </CSVLink>
  </Box>
);

export default SampleCSV;
