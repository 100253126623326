import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Button from "@mui/material/Button";

const columns = [
  { id: "Chapters", label: "Chapters", maxWidth: 733, padding: "16px" },
  { id: "Lesson", label: "Lesson", minWidth: 90, padding: "16px 18px" },
  {
    id: "Status",
    label: "Status",
    minWidth: "auto",
    format: (value) => value.toLocaleString("en-US"),
  }
];

function createData(Chapters, lesson, status) {
  return { Chapters, lesson, status };
}

const rows = [
  createData("Basic Information about Net Net as Whole", "+ ADD LESSON", "PUBLISHED", <ArrowDropDownIcon />),
];

export default function AddChapterTable({ chapterNameAdded, setExpandLessonForm }) {
  return (
    <Paper sx={{ width: { xl: '1085px', lg: '992px', md: '100vw' }, padding: "16px", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{
                    minWidth: column.minWidth,
                    maxWidth: column.maxWidth,
                    fontSize: 14,
                    color: "rgba(0, 0, 0, 0.87)",
                    fontWeight: "500",
                    padding: column.padding,
                    alignItems: "start",
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {chapterNameAdded.map((chapter, index) => (
              <TableRow
                key={index}
                style={{
                  backgroundColor: "rgba(0,0,0,0.04)",
                  fontSize: "14px",
                  width: "1053px",
                }}
                hover
                tabIndex={-1}
              >
                <TableCell sx={{ width: 700 }}>{chapter}</TableCell>
                <TableCell>
                  <Button
                    onClick={() => setExpandLessonForm(true)}
                    sx={{ color: "#711FFF", width: "130" }}
                  >
                    +ADD LESSON
                  </Button>
                </TableCell>
                <TableCell>
                  <Button
                    sx={{
                      color: "#711FFF",
                      backgroundColor: "#F2EBFF",
                    }}
                    // variant="outlined"
                  >
                    PUBLISHED
                  </Button>
                </TableCell>
                <TableCell>
                  <ArrowDropDownIcon style={{ width: 24, height: 20 }} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
