 
import { Link, useParams } from "react-router-dom";
import filterIcon from "./sort 1.svg";
import Button from "@mui/material/Button";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import arrow1 from './arrow-1.svg'
import logo from "./NetNetSymbol.svg";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import ReactPlayer from 'react-player';
import { getAuthors } from "./authorsReducer";

const Header = () => {
  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        padding: " 1% 1.38% 1% 1.38%",
        boxSizing: "border-box",
        textAlign: "center",
        fontSize: "29px",
        color: "#03071e",
        fontFamily: "'Open Sans'",
        backgroundColor: "white",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
          gap: "20px",
        }}
      >
        <b style={{ position: "relative", lineHeight: "100%" }}>
         Net Net U Authors
        </b>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            textAlign: "left",
            fontSize: "14px",
            color: "rgba(3, 7, 30, 0.4)",
          }}
        >
          <div
            style={{
              borderRadius: "4px",
              overflow: "hidden",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              gap: "3px",
            }}
          >
            <img
              style={{
                position: "relative",
                width: "18px",
                height: "18px",
                overflow: "hidden",
                flexShrink: "0",
              }}
              alt=""
              src={filterIcon}
            />
            <div
              style={{
                position: "relative",
                letterSpacing: "-0.3px",
                lineHeight: "19px",
              }}
            >
              Filters
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          padding: "5px 0 ",
          fontSize: "14px",
          color: "#fff",
        }}
      >
        <Link to="/SuperAdmin/NetNetU/Authors/New">
          <Button
            sx={{
              width: 108,
              height: 35,
              textTransform: "capitalize",
              fontWeight: "300",
              backgroundColor: "#711FFF",
              lineHeight: "19px",
              fontSize: "14px",
              "&:hover": {
                backgroundColor: "#711DDD",
              },
            }}
            variant="contained"
          >
            + New
          </Button>
        </Link>
      </div>
    </div>
  );
};
 

 const AuthorsDetail = () =>{
  const { id } = useParams();

 
  const dispatch = useDispatch();
   const access_token = useSelector((state) => state.auth.user.access);

    useEffect(() => {
        const meta = {
            access_token,
          };
      dispatch(getAuthors(meta))
    
    }, [dispatch, access_token]);
   
  const authors = useSelector((state) => state.authors.authors);
  const authorId = parseInt(id);
  const author = authors.find((c) => c.id === authorId);

const [hoveredVideo, setHoveredVideo] = useState(null);

  const handleMouseEnter = (videoId) => {
    setHoveredVideo(videoId);
  };

  const handleMouseLeave = () => {
    setHoveredVideo(null);
  };

  const [overviewStates, setOverviewStates] = useState({});
  const [collapse, setCollapse] = useState(true)

  const handleCollapse = () =>{
   setCollapse(false)
  }
  const handleOverview = (videoId) => {
   setCollapse(true);
    setOverviewStates((prevState) => ({
      ...prevState,
      [videoId]: !prevState[videoId],
    }));
  };

  const isSmallScreen = window.innerWidth <= 767;

    return (
        <>
        <Header />
        <div className="authorDetailWrapper">

        <div className="authorCoursesList">

        <div className="authorProfile">
          <div className="profile">
          <img src={author.picture} alt="profile picture" style={{
            width: "107px",
            height: "107px",
            borderRadius: "50%",
            backgroundColor: "gray"
          }} />
          <div className="authName">
            <h3>{author.authorName}</h3>
            <p>{author.company}</p>
          </div>
          </div>

          <div className="yLinks">
              <Link  to=""><div className="Utube" style={{color: "#5600EB"}}>{author.details.youtubeUrl}</div></Link>
              <p>{author.email}</p>
          </div>
        </div>
        <div className="aboutSection">
          <div className="innerAbout">
        <p style={{
          paddingLeft: "20px",
        }}>
          About
        </p>

        <div className="aboutDesc" style={{
          overflowY: "auto", paddingLeft: "20px", 
          marginTop: "7px",
          paddingRight: "20px",
          lineHeight: 1.5

        }}>
            <p>
             {author.details.about}
            </p>
        </div>
          </div>

        </div>

          <div className="authorCourses">
            <h3>Author Courses</h3>
          </div>
          <div className="courseLists">
          {author?.details?.courses.map((video) => (
        <>
          <div key={video.id} className="box">
            <div className="innerBox" style={{width: "100%"}}
                  >
              <div className="videoWrapper" 
            onMouseEnter={() => handleMouseEnter(video.id)}
            onMouseLeave={handleMouseLeave}>
      
                        {hoveredVideo === video.id ? (
                        <ReactPlayer
                          url={video.url}
                          playing={true}
                          controls={true}
                          width={isSmallScreen ? 200 : 260}
                          volume={0}
                          height="auto"
                        />
                      ) : (
                        <img
                          src={video.thumbnail}
                          style={{ zIndex: "99", width: "260px", height: "142px" }}
                          alt={`thumbnail-${video.id}`}
                        />
                        )}  
              </div>
              <div className="vInfoWrapper3">
                <div className="lesson3" style={{ display: "flex" }}>
                  <p>LESSON |</p> <span>{video.level}</span>
                </div>
                <div className="lessonTitle3">
                  <b>{video.courseTitle}</b>
                </div>
                <div className="lessonDescripton3">
                  <div>{video.courseDescription}</div>
                </div>

                <div
                  className="more3"
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => handleOverview(video.id)}
                >
                  <div className="learn_more3">LEARN MORE</div>
                  <img src={arrow1} alt="learn_more" />
                </div>

                <div className="logoAndAuthor">
                  <img src={logo} alt="logo" />
                  <div className="author">
                    BY <span>{author.authorName}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
         
          {collapse && overviewStates[video.id] && (
            <div className="overview">
              <DialogContent className='colFlex' style={{
                display:"flex",
                padding: 0
              }}>
                <div className="overviewLeft">
                  {" "}
                  <h4>OVERVIEW</h4> <p>{video.courseOverview}</p>
                </div>
                <div className="overviewRight">

                  {video?.courseChapters?.map((chapter, index, array) => (
                      <React.Fragment key={index} className="chapterListStyle">
                        <div>
                          {index !== array.length - 1 ? `Chapter ${index + 1}. ` : ''}
                          {chapter}
                        </div>
                        {index !== array.length - 1 && chapter === 'Summarizing' && (
                          <ol className="">
                            <li>{chapter}</li>
                          </ol>
                        )}
                      </React.Fragment>
                    ))}

                </div>
              </DialogContent>
              <DialogActions className='collapseButton' sx={{padding: 0}}>
                <Button
                  fullWidth
                  variant="filled"
                  sx={{
                    backgroundColor: "#F4F4F4",
                    textTransform: "capitalize",
                    height: "31px",
                    padding: "10px",
                    justifyContent: "center",
                    borderRadius: "0px 0px 5px 5px",
                    border: "1px solid #D9D9D9",
                  }}
                  onClick={handleCollapse}
                >
                  Collapse
                </Button>
              </DialogActions>
            </div>
           )} 
        </>
      ))}
          </div>
        </div>



        </div>      
        </>
    )

}

export default AuthorsDetail